import { useEffect } from 'react'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { AssetCategoryType, AssetListParamsType } from 'store/asset/types'
import './AssetCategories.scss'
import TagManager from 'react-gtm-module'

type AssetCategoriesProps = {
  searchValue: string
  selectedCategory: AssetCategoryType
  setSelectedCategory: (category: AssetCategoryType) => void
}

const BRAND_CATEGORY_NAME = 'Brands'

const AssetCategories = observer((props: AssetCategoriesProps) => {
  const { assetsStore, tenantsStore, userStore } = store
  const { userId, tenantId, roleId, userName } = userStore
  const {
    fetchCategories,
    fetchAssetsList,
    assetsCategorties,
    paginationValues,
    setPaginationValues,
    corporateCommunicationBrandsView,
  } = assetsStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore
  const { searchValue, selectedCategory, setSelectedCategory } = props

  useEffect(() => {
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  }, [])

  const categoryHandler = (categoryId: string, categoryName: string) => {
    let requestParams: AssetListParamsType = {
      page: 1,
      per_page: paginationValues.pageSize,
      sort: 'name:asc',
      is_asset: true,
    }
    if (searchValue) {
      requestParams['q'] = requestParams['q']
        ? `${requestParams['q']},name:like:${searchValue}`
        : `name:like:${searchValue}`
    }
    if (categoryId !== '0') {
      requestParams['q'] = requestParams['q']
        ? `${requestParams['q']},category_id:eq:${categoryId}`
        : `category_id:eq:${categoryId}`
    }
    if (
      isCorporateCommunicationsTheme &&
      corporateCommunicationBrandsView === 'dashboard' &&
      categoryName === BRAND_CATEGORY_NAME
    ) {
      requestParams.is_active = true
    }
    setPaginationValues({ page: 1, pageSize: 10 })
    fetchAssetsList({ params: requestParams })
    TagManager.dataLayer({
      dataLayer: {
        event: 'navigate_assets',
        //@ts-ignore
        user_id: userId,
        tenantId: tenantId,
        roleId: roleId,
        user_name: userName,
        categoryId: categoryId,
        searchText: searchValue,
      },
    })
  }

  const brandCategory = assetsCategorties?.find((item) => item?.name === BRAND_CATEGORY_NAME)
  const otherCategories = assetsCategorties?.filter((item) => item?.category_id !== brandCategory?.category_id)

  const categories =
    customThemeLoading || assetsCategorties.length === 0
      ? []
      : isCorporateCommunicationsTheme
      ? ([brandCategory, ...otherCategories] as AssetCategoryType[])
      : [
          {
            category_id: '0',
            name: 'All Assets',
          },
          ...assetsCategorties,
        ]

  return (
    <div className='assets_categories_container'>
      <div className='acc_list'>
        {categories.map((category) => (
          <ButtonImage
            key={category.category_id}
            onClick={() => {
              setSelectedCategory(category)
              categoryHandler(category.category_id, category.name)
            }}
            className={`acc_l_button${
              category.category_id === selectedCategory?.category_id ? ' acc_l_button_active' : ''
            }`}>
            {category.name}
          </ButtonImage>
        ))}
      </div>
    </div>
  )
})

export default AssetCategories
