import { useEffectOnce, useUnmount } from 'react-use'

import { ListPageSection } from 'version2/components/common/ListPageSection/ListPageSection'
import { NavigationSettings } from 'version2/components/common/NavigationSettings/NavigationSettings'
import { Users } from 'version2/components/Settings/Users/Users'

import { store } from 'store'

export const UsersPage = () => {
  const { userStore } = store

  useEffectOnce(() => {
    userStore.getUsers()
    userStore.getRoles()
  })

  useUnmount(() => {
    userStore.setUsers([])
  })

  return (
    <div>
      <ListPageSection
        left={<NavigationSettings />}
        right={<Users />}
        columns={{
          col1: {
            sm: 4,
            md: 4,
            lg: 4,
          },
          col2: {
            sm: 20,
            md: 20,
            lg: 20,
          },
        }}
      />
    </div>
  )
}
