import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { ILightTag, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { AddTags } from './AddTags/AddTags'

import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'

import './Tags.scss'

interface IProps {
  mode: monitorMode
  itemData?: { type: 'narrative' | 'community' | 'channel'; id: string; name: string }
  tags: string[]
  allTags?: ILightTag[]
  createTag?: (tagLabel: string, id: string) => Promise<void>
  actionTag?: ({ action, tagId, id }: { action: 'apply' | 'remove'; tagId: string; id: string }) => Promise<void>
  setShowAddTagsPage: (state: boolean) => void
}
export const Tags = observer(({ mode, itemData, tags, allTags, actionTag, createTag, setShowAddTagsPage }: IProps) => {
  const onBackClick = () => {
    setShowAddTagsPage(false)
    navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
  }
  const navigate = useNavigate()
  return (
    <div className='tags'>
      <div className='tags__header' onClick={onBackClick}>
        <span className='tags__header__back-btn'>
          <BackBtnIcon />
        </span>
        <span className='tags__header__back-btn--title'>Back to Narrative</span>
      </div>

      <h3 className='tags__header__heading'>{`Add tag to ${itemData?.name}`}</h3>

      <AddTags
        mode={mode}
        allTags={allTags}
        usedTags={tags}
        contentId={itemData?.id}
        actionTag={actionTag}
        createTag={createTag}
        setShowAddTagsPage={setShowAddTagsPage}
      />
    </div>
  )
})
