import { memo } from 'react'
import GridCard from './GridCard/GridCard'
import { NarrativesResponseType, SelectedTabType } from 'store/monitorVersion2/types'
import { SubStore } from 'types/types'

import './GridViewSection.scss'

type GridViewSectionType = {
  gridData: NarrativesResponseType
  selectedTab: SelectedTabType
  subStore: SubStore
}

const GridViewSection = (props: GridViewSectionType) => {
  const { gridData, selectedTab, subStore } = props

  return (
    <div className='grid_view_section_container'>
      <div className='gvsc_card_container'>
        {gridData.items.map((narrative) => (
          <GridCard
            key={narrative.id}
            className='gvsc_cc_card'
            data={narrative}
            selectedTab={selectedTab}
            subStore={subStore}
          />
        ))}
      </div>
    </div>
  )
}

export default memo(GridViewSection)
