import { ReactComponent as NoResult } from 'assets/images/no-results.svg'
import { ReactNode } from 'react'

import './NoResultsIcon.scss'

export const NoResultsIcon = ({ children }: { children?: ReactNode }) => {
  return (
    <div className='c-no-results-container'>
      <div className='c-no-results-box'>
        <NoResult className='c-no-results-ilustration' />
        <p className='c-no-results-text--title'>No results</p>
        <p className='c-no-results-text'>
          {children ? (
            children
          ) : (
            <>
              Select a tag, narrative, or <br />
              community to view the feed
            </>
          )}
        </p>
      </div>
    </div>
  )
}
