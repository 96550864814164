import YoutubeIcon from 'assets/images/youtube-icon.svg'
import TwitterIcon from 'assets/images/twitter-x-icon-current-color.svg'
import PodcastIcon from 'assets/images/podcast-icon.svg'
import RumbleIcon from 'assets/images/rumble-icon.svg'
import BitchuteIcon from 'assets/images/bitchute-icon.svg'

import './Links.scss'

interface Props {
  url: string
}

export const Links = ({ url }: Props) => {
  const urlLink = url.length > 40 ? `${url.slice(0, 40)}...` : url

  const iconsObj = {
    YouTube: YoutubeIcon,
    Twitter: TwitterIcon,
    Podcast: PodcastIcon,
    Rumble: RumbleIcon,
    BitChute: BitchuteIcon,
  }

  //@ts-ignore
  const image = iconsObj['YouTube']

  return (
    <div className='links'>
      <img className='links__image' alt={''} src={image} />
      <a className='links__text' href={url} target='_blank' rel='noreferrer'>
        <p className='links__text-title'>{urlLink}</p>
        <p className='links__text-website'>{urlLink}</p>
        <p className='links__text-website-link'>{urlLink}</p>
      </a>
    </div>
  )
}
