import axios from 'axios'
import { NarrativeParamsType, URLType } from 'store/monitorVersion2/types'

export function getMonitors({ url, params }: { url: URLType; params: NarrativeParamsType }) {
  return axios({
    method: 'get',
    url,
    params: params,
  })
}
