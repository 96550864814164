import axios from 'axios'
import { API, tagAPI, alertAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import {
  ICommunity,
  ILightTag,
  INarrative,
  IPagination,
  ISorter,
  ShareUser,
  INarrativeDropdown,
  ICommunityDropdown,
  AlertUserData,
} from 'models/models'
import { UtilService } from 'services/Util/Util'
import { PAGINATION_SINGLE_PAGE } from 'settings/settings'
import dayjs from 'dayjs'

export class ManageStore {
  allDataTab = 1
  searchParams: { id?: string; querry?: string } = {}
  userID: string | null = null
  storeType: 'tag' | 'community' | 'narrative' | 'channel' | 'watchlist' = 'tag' //this variable should be set in every store that extends this one
  isViewModalOpen: boolean = false
  isShareModalOpen: string | number = 0
  isEditModalOpen: boolean = false
  isDeleteModalOpen: boolean = false
  isAlertModalOpen: boolean = false
  paginationTableTab1: IPagination = PAGINATION_SINGLE_PAGE
  paginationTableTab2: IPagination = PAGINATION_SINGLE_PAGE
  paginationTableTab3: IPagination = PAGINATION_SINGLE_PAGE

  sort: ISorter = {}

  dataTableTab1: any[] = []
  dataTableTab2: any[] = []
  dataTableTab3: any[] = []

  viewModalData: INarrative | ICommunity | null = null

  lightTags: ILightTag[] = []

  openedModalItemID: string | number = ''
  openedModalData: any
  shareUsers: ShareUser[] = []
  alertSharedUsers: AlertUserData[] = []

  narrativeFilterData: INarrativeDropdown[] = []
  communityFilterData: ICommunityDropdown[] = []

  constructor() {
    makeObservable(this, {
      isViewModalOpen: observable,
      isShareModalOpen: observable,

      paginationTableTab1: observable,
      paginationTableTab2: observable,
      paginationTableTab3: observable,

      sort: observable,

      dataTableTab1: observable,
      dataTableTab2: observable,
      dataTableTab3: observable,
      isEditModalOpen: observable,
      isDeleteModalOpen: observable,
      isAlertModalOpen: observable,

      shareUsers: observable,
      alertSharedUsers: observable,

      setIsViewModalOpen: action.bound,
      setIsShareModalOpen: action.bound,
      updatePagination: action.bound,
      updatePaginationTotal: action.bound,

      updateSorting: action.bound,

      fetchData: action.bound,

      saveTableData: action.bound,
      setIsEditModalOpen: action.bound,
      setIsDeleteModalOpen: action.bound,
      setIsAlertModalOpen: action.bound,

      setShareUsers: action.bound,

      saveItemData: action.bound,

      resetStore: action.bound,
    })
  }

  get sortTableTab1() {
    return this[`sort`].fieldName?.length && this[`sort`].direction?.length
      ? `${this[`sort`].fieldName}:${this[`sort`].direction.replace('end', '')}`
      : 'name'
  }

  get sortTableTab2() {
    return this[`sort`].fieldName?.length && this[`sort`].direction?.length
      ? `${this[`sort`].fieldName}:${this[`sort`].direction.replace('end', '')}`
      : 'create_time:desc'
  }

  get sortTableTab3() {
    return this[`sort`].fieldName?.length && this[`sort`].direction?.length
      ? `${this[`sort`].fieldName}:${this[`sort`].direction.replace('end', '')}`
      : 'name'
  }

  get filterTableTab1() {
    return ''
  }

  get filterTableTab2() {
    return 'is_followed:eq:true'
  }

  get filterTableTab3() {
    const date = dayjs().subtract(30, 'days').toISOString()
    return `create_time:gt:${date}`
  }

  get tableTabData1() {
    return UtilService.getTableData({ source: this.dataTableTab1, table: this.storeType })
  }

  get tableTabData2() {
    return UtilService.getTableData({ source: this.dataTableTab2, table: this.storeType })
  }

  get tableTabData3() {
    return UtilService.getTableData({ source: this.dataTableTab3, table: this.storeType })
  }

  get dataAlertSharedUsers() {
    return this.alertSharedUsers
  }

  setCreatorsFilter = (data: ICommunityDropdown[]) => {
    this.communityFilterData = data
  }

  setNarrativesFilter = (data: INarrativeDropdown[]) => {
    this.narrativeFilterData = data
  }

  fetchAuxData = async () => {
    try {
      const [narrativesDropdownData, communitiesDropdownData] = await Promise.all([
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'narrative',
        }),
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'community',
        }),
      ])
      this.setCreatorsFilter(communitiesDropdownData.data.items)
      this.setNarrativesFilter(narrativesDropdownData.data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchData = async ({ tab }: { tab: 1 | 2 | 3 }) => {
    try {
      const { data, total } = await API.get({
        route: this.storeType,
        page: this[`paginationTableTab${tab}`].current,
        pageSize: this[`paginationTableTab${tab}`].pageSize,
        filter:
          this.searchParams?.querry && tab === this.allDataTab
            ? `${this.storeType === 'tag' ? 'label' : 'name'}:like:${this.searchParams.querry}`
            : this[`filterTableTab${tab}`],
        sort: this[`sortTableTab${tab}`],
        getError: true,
        id: this.allDataTab === tab ? this.searchParams.id : undefined,
      })

      this.saveTableData({
        tab,
        data: this.formatData(this.searchParams.id && tab === this.allDataTab ? [data] : data.items),
      })
      this.updatePaginationTotal({ tab, total })
    } catch (e: any) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        this.saveTableData({ tab, data: this.formatData([]) })
        this.updatePaginationTotal({ tab, total: 0 })
      } else {
        const response = e.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      }
    }
  }

  fetchAlerts = async (alertId: string) => {
    const data = await alertAPI.getAlertUsers(alertId)
    if (data?.user_ids) {
      const users = data.user_ids.filter((user: AlertUserData) => user.user_id !== data.owner_id)
      this.setAlertSharedUsers(users)
    }
  }

  fetchItem = async (id: string) => {
    try {
      const [{ data }, { data: dataShare }] = await Promise.all([
        API.get({
          route: this.storeType,
          id,
          getError: true,
          isPromise: true,
        }),
        this.storeType === 'narrative' &&
          API.get({
            route: this.storeType,
            extraPath: 'share',
            id: id,
            isPromise: true,
          }),
      ])

      if (this.storeType === 'narrative') data.sharedWith = dataShare.shared_users

      this.saveItemData(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  saveTableData = ({ tab, data }: { tab: 1 | 2 | 3; data: any }) => {
    this[`dataTableTab${tab}`] = data
  }

  setShareUsers = (users: ShareUser[]) => {
    this.shareUsers = users
  }

  setAlertSharedUsers = (users: AlertUserData[]) => {
    this.alertSharedUsers = users
  }

  saveItemData = (data: any) => {
    if (!data) return

    const dateCreated = dayjs(data.created).format('YYYY-MM-DD')

    switch (this.storeType) {
      case 'community':
        return (this.viewModalData = {
          id: data.id,
          creatorImage: '/images/android-icon-96x96.png',
          creatorName: 'Pedulum',
          dateCreated,
          name: data.name,
          description: data.description,
          tags: data.tags,
          links: data.sample_creators,
        })
      case 'narrative':
        return (this.viewModalData = {
          id: data.id,
          creatorImage: '/images/android-icon-96x96.png',
          creatorName: 'Pedulum',
          dateCreated,
          name: data.name,
          description: data.description,
          tags: data.tags,
          links: data.related_links,
          keywords: data.boolean_query,
          lastUpdateTime: data.last_update_time,
          lastUpdatedAuthor: data.last_updated_by,
          sharedWith: data.sharedWith,
          parentNarratives: this.narrativeFilterData
            .filter((el) => data?.parent_narratives.includes(el.id))
            .map((el) => el.name),
          communities: this.communityFilterData.filter((el) => data?.communities.includes(el.id)).map((el) => el.name),
          isMLGenerated: data.narrative_type === 'NLP',
        })
    }
  }

  setIsViewModalOpen = (state: boolean) => {
    if (state === false) this.viewModalData = null
    this.isViewModalOpen = state
  }

  setIsShareModalOpen = (id: string | number) => {
    this.isShareModalOpen = id
  }

  setIsEditModalOpen = (state: boolean) => {
    this.isEditModalOpen = state
  }

  setIsDeleteModalOpen = (state: boolean) => {
    this.isDeleteModalOpen = state
  }

  setIsAlertModalOpen = (state: boolean) => {
    this.isAlertModalOpen = state
  }
  updatePagination = ({ tab, pagination }: { tab: 1 | 2 | 3; pagination: any }) => {
    return (this[`paginationTableTab${tab}`] = pagination)
  }

  updatePaginationTotal = ({ tab, total }: { tab: 1 | 2 | 3; total: number }) => {
    return (this[`paginationTableTab${tab}`].total = total)
  }

  updateSorting = ({ tab, sorter }: { tab: 1 | 2 | 3; sorter: ISorter }) => {
    return (this[`sort`] = sorter)
  }

  openView = (id: string) => {
    this.setIsViewModalOpen(true)
    this.fetchItem(id)
  }

  formatData = (data: any[]) => {
    switch (this.storeType) {
      case 'community':
        return data.map((community) => {
          //TODO: add the img and creator name : update , the name should be loaded from the users endpoint, and the image will be the default one
          const date = new Date(community.created.replace(/\s/, 'T'))
          const createdMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
          const createdDate = (date.getDate() < 10 ? '0' : '') + date.getDate()
          return {
            id: community.id,
            dateCreated: `${date.getFullYear()}-${createdMonth}-${createdDate}`,
            name: community.name,
            description: community.description,
            tags: community.tags,
            creatorImage: '/images/android-icon-96x96.png',
            creatorName: 'Pendulum',
          }
        })

      case 'narrative':
        return data.map((narrative) => {
          //TODO: add the img and creator name : update , the name should be loaded from the users endpoint, and the image will be the default one
          const date = new Date(narrative.last_update_time.replace(/\s/, 'T'))
          const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
          const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()
          return {
            id: narrative.id,
            dateLastModified: `${date.getFullYear()}-${lastModifiedMonth}-${lastModifiedDate}`,
            name: narrative.name,
            description: narrative.description,
            tags: narrative.tags,
            creatorImage: '/images/android-icon-96x96.png',
            creatorName: 'Pendulum',
            isMLGenerated: narrative.narrative_type === 'NLP',
            alertId: narrative.alert_id,
          }
        })

      case 'tag':
        return data.map((tag) => {
          return {
            id: tag.id,
            name: tag.label,
            narrativesCount: tag.narrative_count,
            communitiesCount: tag.community_count,
            creatorsCount: tag.creator_count,
            postsCount: tag.content_count,
            following: tag.is_followed,
          }
        })
      default:
        return []
    }
  }

  openModal = async (modal: string, id?: string | number, modalData?: any) => {
    try {
      if (id) this.setOpenedItemID(id)
      if (modalData) this.setOpenedModalData(modalData)

      switch (modal) {
        case 'share':
          const { data } = await API.get({
            route: this.storeType,
            extraPath: 'share',
            id: id,
          })

          if (data?.shared_users) this.setShareUsers([data.owner, ...data.shared_users])
          return this.setIsShareModalOpen(id ? id : 0)

        case 'edit':
          return this.setIsEditModalOpen(true)

        case 'delete':
          return this.setIsDeleteModalOpen(true)

        case 'alert':
          if (modalData?.alertId && modalData.alertId.length) await this.fetchAlerts(modalData.alertId)
          return this.setIsAlertModalOpen(true)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setOpenedItemID = (id: string | number) => {
    this.openedModalItemID = id
  }

  setOpenedModalData = (data: any) => {
    this.openedModalData = data
  }

  setUserID = (id: string | null) => {
    this.userID = id
  }

  setLightTags = (lightTags: ILightTag[]) => {
    this.lightTags = lightTags
  }

  getLightTags = async () => {
    try {
      const data = await tagAPI.getSummaryTags()
      this.setLightTags(data.tags)
    } catch (e) {}
  }

  createTag = async (tagLabel: string, id: string, type?: 'tag' | 'community' | 'narrative' | 'channel') => {
    try {
      const { data } = await tagAPI.createTag(tagLabel)
      this.lightTags.push({ label: data.label, id: data.id })

      this.actionTag({ action: 'apply', tagId: data.id, id, type })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  actionTag = async ({
    action,
    tagId,
    id,
    type,
  }: {
    action: 'apply' | 'remove'
    tagId: string
    id: string
    type?: 'tag' | 'community' | 'narrative' | 'channel'
  }) => {
    try {
      // if (this.storeType === 'tag') return

      await tagAPI.addTag({ tagId, action, type: type ? type : this.storeType, id })

      const tagLabel = this.lightTags.filter((el: any) => el.id === tagId)[0].label

      this.saveTableData({
        tab: 1,
        data: this.dataTableTab1.map((el: any) => {
          const item = el
          if (item.id !== id) return item
          if (action === 'apply') {
            item.tags.push(tagLabel)
          }
          if (action === 'remove') {
            item.tags = item.tags.filter((tag: string) => tag !== tagLabel)
          }
          return item
        }),
      })

      this.saveTableData({
        tab: 2,
        data: this.dataTableTab2.map((el: any) => {
          const item = el
          if (item.id !== id) return item
          if (action === 'apply') {
            item.tags.push(tagLabel)
          }
          if (action === 'remove') {
            item.tags = item.tags.filter((tag: string) => tag !== tagLabel)
          }
          return item
        }),
      })

      this.saveTableData({
        tab: 3,
        data: this.dataTableTab3.map((el: any) => {
          const item = el
          if (item.id !== id) return item
          if (action === 'apply') {
            item.tags.push(tagLabel)
          }
          if (action === 'remove') {
            item.tags = item.tags.filter((tag: string) => tag !== tagLabel)
          }
          return item
        }),
      })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  shareItem = async ({
    users,
    permission,
    action,
  }: {
    users: string[]
    permission: 'viewer' | 'editor'
    action: 'share' | 'unshare'
  }) => {
    try {
      await API.genericShare(
        this.isShareModalOpen,
        users,
        this.storeType,
        action,
        action === 'unshare' ? 'viewer' : permission, //sending a "random" role to the api when the user tries to remove another user
      )

      if (action === 'unshare') return this.setShareUsers(this.shareUsers.filter((user) => user.user_id !== users[0]))

      const changedRole = !!this.shareUsers.filter((user) => user.user_id === users[0]).length

      if (changedRole) {
        const usersWithUpdatedRole = this.shareUsers.map((user) => {
          if (user.user_id !== users[0]) return user
          return { ...user, permission_level: permission }
        })

        this.setShareUsers(usersWithUpdatedRole)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editItem = async (
    type: 'narrative' | 'community' | 'tag',
    id: string | number,
    label: string,
    afterAsyncOperation: (label: string) => void,
    handleCloseModel: (state: boolean) => void,
  ) => {
    try {
      if (!id && id !== 0) return

      await API.genericEdit(id, type, label)
      afterAsyncOperation(label)
      handleCloseModel(false)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  saveAlert = async ({
    entity_type,
    entity_id,
    user_ids,
  }: {
    entity_type: string
    entity_id: string
    user_ids: string[]
  }) => {
    try {
      const { data } = await alertAPI.saveAlert({ entity_type, entity_id, user_ids })
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editAlert = async ({
    action,
    alertId,
    userIds,
  }: {
    action: 'add' | 'remove'
    alertId?: string
    userIds: string[]
  }) => {
    try {
      await alertAPI.editAlert({ action, alertId, userIds })
      if (action === 'remove')
        return this.setAlertSharedUsers(this.alertSharedUsers.filter((user) => user.user_id !== userIds[0]))
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  deleteAlert = async (alertId: string) => {
    try {
      const { data } = await alertAPI.deleteAlert(alertId)
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setSearchParams = (params: { id?: string; querry?: string }) => {
    this.searchParams = params
  }

  deleteItem = async ({ type, id }: { type: 'narrative' | 'community' | 'tag'; id: string }) => {
    try {
      await API.genericDelete(id, type)

      UtilService.openNotification({ type: 'info', message: `The ${this.storeType} was deleted sucesfully.` })

      this.setIsDeleteModalOpen(false)

      return true
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  resetStore = () => {
    this.isViewModalOpen = false
    this.isShareModalOpen = 0
    this.isEditModalOpen = false
    this.isDeleteModalOpen = false
    this.paginationTableTab1 = PAGINATION_SINGLE_PAGE
    this.paginationTableTab2 = PAGINATION_SINGLE_PAGE
    this.paginationTableTab3 = PAGINATION_SINGLE_PAGE
    this.dataTableTab1 = []
    this.dataTableTab2 = []
    this.dataTableTab3 = []
    this.viewModalData = null
    this.lightTags = []
    this.openedModalItemID = ''
    this.shareUsers = []
    this.alertSharedUsers = []
  }
}
