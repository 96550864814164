import dayjs from 'dayjs'
import { useMemo } from 'react'
import { uniqBy } from 'remeda'
import { useMeasure } from 'react-use'
import { observer } from 'mobx-react-lite'
import { BeeChart, ComponentGlobalStyle, Tip, themes, useTip } from '@pendulumfn/pendulum-viz'

import { BeehiveChartPopup } from './BeehiveChartPopup/BeehiveChartPopup'
import { BeehiveChartXAxis } from './BeehiveChartXAxis/BeehiveChartXAxis'
import { BeehivePlatformLegends } from './BeehivePlatformLegends/BeehivePlatformLegends'

import { Unknown } from 'version2/utils/commonTypes'
import { IVideoBubble, monitorSnippetPage } from 'models/models'
import { convertMobXProxiesToJSObject } from 'version2/utils/helper'

import { store } from 'store'

import './MonitorBeehiveChart.scss'
interface beeSwarmProps {
  page: monitorSnippetPage
}

export const MonitorBeehiveChart: React.FC<beeSwarmProps> = observer(function MonitorBeehiveChart({ page }) {
  const tip = useTip<IVideoBubble>()
  const [measureRef, { width: beeChartWidth }] = useMeasure<HTMLDivElement>()

  const { beeSwarmVideoNodes } = page === 'define' ? store['defineNarrativeStoreV1'] : store.monitorNarratives
  const { powerInsightsData } = store.executiveIntelligence
  const beeSwarmData = page === 'executive-intelligence' ? powerInsightsData.beeSwarmNodesData : beeSwarmVideoNodes

  const xAxisData = useMemo(() => {
    return beeSwarmData.map((node) => ({ date: dayjs(node.date).format('MMM DD, YYYY') }))
  }, [beeSwarmData])

  const platforms = useMemo(() => {
    return uniqBy(beeSwarmData, (node) => node.data.platform).map((node) => node.data.platform)
  }, [beeSwarmData])

  const handleSelectAndOpen = (data: Record<string, Unknown>) => {
    const convertedData = convertMobXProxiesToJSObject(data)?.data
    const postUrl = convertedData?.post_url
    if (postUrl) {
      window.open(postUrl, '_blank')
    }
  }

  return (
    <div ref={measureRef} className='c-bubble-chart'>
      {/* Provides css variables that are used by all the components. Uses css variables that can switch light/dark according to browser settings */}
      <ComponentGlobalStyle theme={themes.pendulum} noDark />

      <div>
        <div className='c-bubble-chart__heading'>
          <span className='c-bubble-chart__heading-subtitle'>Hover on any content bubble to see details.</span>
        </div>
      </div>

      <div className='c-bubble-chart__platforms'>
        <BeehivePlatformLegends page={page} platforms={platforms} />
      </div>

      <div className='c-bubble-chart__bubbles'>
        <BeeChart
          className='c-bubble-chart__bee'
          nodes={beeSwarmData}
          width={beeChartWidth}
          tip={tip}
          onSelect={handleSelectAndOpen}
          maxBubbles={1000}
          widthFactor={0.5}
          bubbleSize={0.5}
          showBar={false}
        />

        <BeehiveChartXAxis data={xAxisData} maxWidth={beeChartWidth} />
      </div>

      <Tip {...tip.tipProps}>{tip.data && <BeehiveChartPopup data={tip.data} />}</Tip>
    </div>
  )
})
