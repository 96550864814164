import { Spin, Switch, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import AlertModal from 'version2/components/AlertModal/AlertModal'
import Table, { IColumnType } from 'version2/components/common/Table/Table'
import AssetHeader from 'version2/components/Asset/AssetHeader/AssetHeader'
import FlagPosts from 'version2/components/MonitorVersion2/FlagPosts/FlagPosts'
import ScuccessModal from 'version2/components/Flags/ScuccessModal/ScuccessModal'
import { DefaultPagination } from 'version2/components/common/Pagination/Pagination'
import CreateAssetModal from 'version2/components/Asset/CreateAssetModal/CreateAssetModal'
import AllPostSection from 'version2/components/MonitorVersion2/AllPostSection/AllPostSection'
import ActionButtons from 'version2/components/Investigate/Reports/ActionButtons/ActionButtons'
import ExpandListChunk from 'version2/components/MonitorVersion2/ExpandListChunk/ExpandListChunk'

import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { ReactComponent as NotificationIcon } from 'assets/images/icons/monitor/notification_icon.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'

import { store } from 'store'

import { flagsAPI } from 'api/api'

import usePermissions from 'version2/hooks/usePermissions'

import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'version2/utils/helper'
import { AssetCategoryType, AssetListObjType, AssetListParamsType } from 'store/asset/types'

import './Asset.scss'
import CorporateCommunicationsBrandsDashboard from 'version2/components/Asset/BrandsDashboard/Dashboard'
import TagManager from 'react-gtm-module'

const BRANDS = 'Brands'
const DASHBOARD = 'dashboard'

const Asset = observer(() => {
  const navigate = useNavigate()
  const { assetsStore, breadcrumbStore, tenantsStore, userStore } = store

  const {
    assetsLoading,
    assetsList,
    paginationValues,
    isFlagCreated,
    fetchAssetsList,
    setPaginationValues,
    setIsFlagCreated,
    setAssetsList,
    fetchConditionsPayload,
    corporateCommunicationBrandsView,
    assetsCategorties,
  } = assetsStore
  const { userId, userName, tenantId, roleId } = userStore

  const { rootPage, setBreadcrumbData } = breadcrumbStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore

  const [loader, setLoader] = useState('')
  const [entityId, setEntityId] = useState<string>('')
  const [alertId, setAlertId] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([])
  const [openEditSuccessModal, setOpenEditSuccessModal] = useState<boolean>(false)
  const [editAssetData, setEditAssetData] = useState<AssetListObjType | null>(null)

  const [selectedCategory, setSelectedCategory] = useState<AssetCategoryType>()

  const defaultCategory = useMemo(() => {
    if (!customThemeLoading && !isCorporateCommunicationsTheme) {
      return {
        category_id: '0',
        name: 'All Assets',
      }
    }

    if (customThemeLoading) {
      return { category_id: '', name: '' }
    }

    const brandCategory = assetsCategorties.find((item) => item.name === BRANDS)

    if (!brandCategory) {
      return { category_id: '', name: '' }
    }

    return {
      category_id: brandCategory.category_id,
      name: BRANDS,
    }
  }, [customThemeLoading, isCorporateCommunicationsTheme, assetsCategorties])

  const canActiveDeActive = usePermissions('assets') === 'turn_on_off' || usePermissions('assets') === 'create'
  const canCreate = usePermissions('assets') === 'create'

  const expandedRowsHandler = (item: AssetListObjType) => {
    setExpandedRows((prevState) =>
      prevState.includes(item.id) ? prevState.filter((res) => res !== item.id) : [...prevState, item.id],
    )
  }

  const expandedRowsCondition = (item: AssetListObjType) => {
    return expandedRows.includes(item.id)
  }

  const disabledRowCondition = (item: AssetListObjType) => {
    return !item.is_active
  }

  useEffect(() => {
    setSelectedCategory(defaultCategory)
    if (isFlagCreated) {
      setSelectedRows([])
      setPaginationValues({ page: 1, pageSize: 10 })
    }
  }, [isFlagCreated, customThemeLoading, defaultCategory])

  useEffect(() => {
    if (rootPage) {
      const newBreadcrumbData = [
        { id: UtilService.generateUuid(), title: rootPage },
        { id: UtilService.generateUuid(), title: selectedCategory?.name || '' },
      ]

      setBreadcrumbData(newBreadcrumbData)
    }
  }, [selectedCategory?.name, rootPage, customThemeLoading])

  useEffect(() => {
    if (!customThemeLoading) {
      if (isCorporateCommunicationsTheme && defaultCategory.category_id === '') return
      const requestParams: AssetListParamsType = {
        page: 1,
        per_page: paginationValues.pageSize,
        sort: 'name:asc',
        is_asset: true,
      }
      if (isCorporateCommunicationsTheme && corporateCommunicationBrandsView === DASHBOARD)
        requestParams.is_active = true
      const modifiedParams = isCorporateCommunicationsTheme
        ? { ...requestParams, q: `category_id:eq:${defaultCategory.category_id}` }
        : requestParams
      setPaginationValues({ page: 1, pageSize: 10 })
      fetchAssetsList({ params: modifiedParams })
      fetchConditionsPayload(undefined, false)
    }
  }, [customThemeLoading, isCorporateCommunicationsTheme, defaultCategory, corporateCommunicationBrandsView])

  const requestParams: AssetListParamsType = {
    page: paginationValues.page,
    per_page: paginationValues.pageSize,
    sort: 'name:asc',
    is_asset: true,
  }

  const activeHandler = async (id: string, checked: boolean) => {
    try {
      setLoader(id)
      await flagsAPI.toggleFlagStatus({ ids: [id], status: checked ? 'activate' : 'deactivate' })
      if (selectedCategory?.category_id) requestParams.q = `category_id:eq:${selectedCategory.category_id}`
      fetchAssetsList({ params: requestParams })
      const newItems = [...assetsList.items]
      const index = newItems.findIndex((item) => item.id === id)
      if (index > -1) {
        newItems[index].is_active = checked
        setAssetsList({ items: newItems, total_count: assetsList.total_count })
      }
      setLoader('')
    } catch (error: any) {
      setLoader('')
      const newItems = [...assetsList.items]
      const index = newItems.findIndex((item) => item.id === id)
      if (index > -1) {
        newItems[index].is_active = !checked
        setAssetsList({ items: newItems, total_count: assetsList.total_count })
      }
      displayApiError(error)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'toggle_asset',
        //@ts-ignore
        user_id: userId,
        user_name: userName,
        tenantId: tenantId,
        roleId: roleId,
        entityId: id,
        staus: checked ? 'activate' : 'deactivate',
      },
    })
  }
  /*  eslint-disable no-useless-computed-key */
  const columns: IColumnType<AssetListObjType>[] = useMemo(() => {
    return [
      {
        key: 'checkbox',
        title: '',
        width: 44,
        render: (column, item) => {
          const { id } = item
          return (
            <>
              {canCreate && (
                <Tooltip title='Select' placement='bottom'>
                  <div
                    className={classNames(
                      'amc_th_checkbox_container',
                      { ['amc_th_checkbox_container_disabled']: !item.is_active },
                      {
                        ['amc_th_checkbox_container_selected']: selectedRows.includes(id),
                      },
                    )}
                    aria-label='Select'
                    onClick={(e) => {
                      e.stopPropagation()
                      if (!item.is_active) return

                      setSelectedRows((prevState) =>
                        prevState.includes(id) ? prevState.filter((res) => res !== id) : [id],
                      )
                    }}>
                    {selectedRows.includes(id) && <CheckIcon aria-label='Select' />}
                  </div>
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        key: 'name',
        title: 'Name',
        //   width: 310,
        render: (column, item) => {
          const { display_label } = item
          return <span className='amc_t_flag_name'>{display_label}</span>
        },
      },
      {
        key: 'actions',
        title: '',
        width: 150,
        render: (column, item) => {
          return (
            <>
              {canCreate && (
                <ActionButtons
                  showTooltip={true}
                  className={classNames('action_button_container', { ['abc_disabled']: !item.is_active })}
                  hideExtraIcons={true}
                  hideDeteleIcon={true}
                  shareHandler={() => {}}
                  editHandler={() => item.is_active && setEditAssetData(item)}
                />
              )}
            </>
          )
        },
      },
      {
        key: 'all_posts',
        title: 'All Posts',
        width: 150,
        render: (column, item) => {
          return item.is_active ? <AllPostSection forAsset={true} itemData={item} subStore={'assets'} /> : '-'
        },
      },
      {
        key: 'flag_posts',
        title: 'Flagged Posts',
        render: (column, item) => {
          return item.is_active ? <FlagPosts forAsset={true} itemData={item} subStore={'assets'} /> : '-  '
        },
      },
      {
        key: 'notification',
        title: '',
        width: 70,
        onCell: (record) => ({
          onClick: (e: any) => {
            e.stopPropagation()
            if (!record.is_active) return
            setAlertId(record?.alert_id)
            setEntityId(record?.id)
          },
          style: {
            textAlign: 'center',
          },
        }),
        render: (_, record) =>
          record?.alert_id ? (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <FilledNotificationIcon className='amc_bell_icon' aria-label='Turn On/Off' />
            </Tooltip>
          ) : (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <NotificationIcon className='amc_bell_icon' aria-label='Turn On/Off' />
            </Tooltip>
          ),
      },
      {
        key: 'active',
        title: 'Active',
        width: 92,
        onCell: () => ({
          onClick: (e: any) => {
            e.stopPropagation()
          },
          style: {
            textAlign: 'center',
          },
        }),
        render: (column, item) => {
          return (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <Switch
                className='amc_switch'
                checked={item.is_active}
                disabled={!canActiveDeActive}
                aria-label='Turn On/Off'
                onChange={(value, e) => {
                  activeHandler(item.id, value)
                }}
                loading={item.id === loader}
              />
            </Tooltip>
          )
        },
      },
    ]
  }, [selectedRows, assetsList.items, loader])
  /* eslint-enable*/

  const renderRowSubComponent = useCallback((records: AssetListObjType) => {
    return <ExpandListChunk data={records} forAsset={true} subStore={'assets'} />
  }, [])

  const rowClickHandler = (item: AssetListObjType) => {
    const state = {
      name: item.name,
      conditions: item.conditions,
      mode: item.mode,
      id: item?.id,
      alert_id: item.alert_id,
    }

    navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
  }

  const isCorportateCommunicationBrandsCategory = isCorporateCommunicationsTheme && selectedCategory?.name === BRANDS
  const isCorportateBrandsDashboard =
    isCorportateCommunicationBrandsCategory && corporateCommunicationBrandsView === DASHBOARD

  return (
    <>
      <Spin spinning={assetsLoading} className='Assets_main_container_loader'>
        <div className='Assets_main_container'>
          <AssetHeader
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedCategory={selectedCategory as AssetCategoryType}
            setSelectedCategory={setSelectedCategory}
            addHandler={() => {
              setOpenModal(true)
            }}
            isCorportateBrandsDashboard={isCorportateBrandsDashboard}
            isCorportateCommunicationBrandsCategory={isCorportateCommunicationBrandsCategory}
          />
          {!customThemeLoading && !isCorportateBrandsDashboard && (
            <>
              <div className='amc_table_container'>
                <Table
                  data={assetsList.items}
                  columns={columns}
                  className='amc_table'
                  onRowClick={rowClickHandler}
                  disabledRow={disabledRowCondition}
                  expandable={{
                    expandedRowRender: renderRowSubComponent,
                    setExpandedRows: expandedRowsHandler,
                    expandedRowsCondition: expandedRowsCondition,
                    // rowExpandable: (records) => records.id !== 'a32a42df-888e-4b30-af03-a8b0851a8f59',
                  }}
                />
              </div>
              <div className='amc_pagination_container'>
                <DefaultPagination
                  className='amc_pagination'
                  totalCount={assetsList.total_count}
                  currentPage={paginationValues.page}
                  pageSize={paginationValues.pageSize}
                  showPageSizeChanger={false}
                  // pageSizeOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                  nextIcon={ArrowNextIcon}
                  prevIcon={ArrowNextIcon}
                  iconHeight={20}
                  iconWidth={20}
                  onChange={(page, pageSize) => {
                    let requestParams: AssetListParamsType = {
                      page,
                      per_page: pageSize,
                      sort: 'name:asc',
                      is_asset: true,
                    }
                    setPaginationValues({ page, pageSize })
                    fetchAssetsList({ params: requestParams })
                    setSelectedRows([])
                  }}
                  locale={{ items_per_page: '' }}
                />
              </div>
            </>
          )}
          {!customThemeLoading && isCorportateBrandsDashboard && <CorporateCommunicationsBrandsDashboard />}
        </div>
        {openModal && (
          <CreateAssetModal
            onClose={() => setOpenModal(false)}
            setOpenSuccessModal={() => setOpenSuccessModal(true)}
            selectedCategory={selectedCategory}
          />
        )}
        {editAssetData && (
          <CreateAssetModal
            isEditMode={true}
            onClose={() => setEditAssetData(null)}
            editData={editAssetData}
            setOpenSuccessModal={() => setOpenEditSuccessModal(true)}
          />
        )}
        {openSuccessModal && (
          <ScuccessModal
            title='Asset created successfully'
            desc='Your created asset will turned ON if your defined rule matches your search.'
            onClose={() => {
              setIsFlagCreated(false)
              setOpenSuccessModal(false)
            }}
          />
        )}
        {openEditSuccessModal && (
          <ScuccessModal
            title='All changes saved'
            desc='You successfully saved all the changes to the selected asset.'
            onClose={() => {
              setIsFlagCreated(false)
              setOpenEditSuccessModal(false)
            }}
          />
        )}

        {openAlertSuccessModal && (
          <ScuccessModal
            title='Alert Created Successfully'
            desc='You will start receive new alerts according to the selected preferences'
            onClose={() => setOpenAlertSuccessModal(false)}
            closeButtonText='Continue'
          />
        )}

        <AlertModal
          entity_id={entityId}
          entity_type='ASSET'
          open={!!entityId || !!alertId}
          alertId={alertId}
          onClose={() => {
            setEntityId('')
            setAlertId('')
          }}
          onSuccess={() => {
            setOpenAlertSuccessModal(true)
            fetchAssetsList({ params: requestParams })
          }}
          onDeleteSuccess={() => fetchAssetsList({ params: requestParams })}
        />
      </Spin>
    </>
  )
})

export default Asset
