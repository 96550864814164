import axios from 'axios'
import { monitorMode } from 'models/models'

interface Props {
  tagId: string
  type: monitorMode | 'channel'
  id: string
  action: 'apply' | 'remove'
}

export const addTag = async ({ tagId, type, id, action }: Props) => {
  const data = await axios.post(`/tag/${tagId}/action`, { entity_type: type, entity_id: id, action })
  return data
}
