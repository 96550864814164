import { Area, AreaChart, ResponsiveContainer } from 'recharts'

import './FlagStats.scss'
import { AssetListObjType } from 'store/asset/types'
import { store } from 'store'
import { ConditionsDataType, FlagsCountType } from 'store/monitorVersion2/types'
import { useEffect, useMemo, useState } from 'react'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { observer } from 'mobx-react-lite'
import { Spin } from 'antd'
import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'
import millify from 'millify'
import { FlagListObjType } from 'store/flags/types'
import { SnippetFilterPayload } from 'models/models'

type PropTypes = {
  selectedBrand: AssetListObjType | undefined
  flag: FlagListObjType
  hideThisRow: (id: string) => void
}

type chartDataObj = {
  value: number
}

const FlagStats = observer((props: PropTypes) => {
  const { selectedBrand, flag, hideThisRow = () => {} } = props
  const { fetchInsightsMetrics, getDate, snippetsFilter, getSnippetFilterPayload } = store['assetsStore']
  const [isLoading, setLoading] = useState<boolean>(false)
  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })

  const [chartData, setChartData] = useState<chartDataObj[]>([])

  const renderConditions = useMemo(() => {
    if (selectedBrand && Object.keys((selectedBrand as AssetListObjType).conditions).length) {
      return [(selectedBrand as AssetListObjType).conditions]
    }
    return []
  }, [selectedBrand])

  const insightsMetricsHandler = async (requestData: ConditionsDataType) => {
    setLoading(true)

    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    //use community,platform,sentiment and flags filter,
    const requestParams: { q: string; metric?: string } = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate},risk_score:gte:0,risk_score:lte:100${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }
    const requestParamsForPrev: { q: string; metric?: string } = {
      q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat},risk_score:gte:0,risk_score:lte:100${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }

    const [currentFlag, prevFlag] = await Promise.all([
      fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
      fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData, fullResponse: true }),
    ])
    const currentFlagCount = currentFlag === 'error' ? 0 : currentFlag.total_value
    const prevFlagCount = prevFlag === 'error' ? 0 : prevFlag.total_value

    setPostsCount({
      currentFlagCount: currentFlagCount,
      prevFlagCount: prevFlagCount,
    })
    if (currentFlag !== 'error') {
      const chartData = currentFlag.data_points.map((point: { metric_value: number }) => ({
        value: point.metric_value,
      }))
      if (chartData.length === 1) {
        chartData.unshift({ value: 0 })
      }
      setChartData(chartData)
    }
    setLoading(false)
    if (currentFlagCount === 0 && prevFlagCount === 0) {
      hideThisRow(flag.id)
    }
  }

  useEffect(() => {
    if (selectedBrand && renderConditions.length && flag.conditions) {
      const requestData = {
        conditions: [...renderConditions, flag.conditions],
      }
      insightsMetricsHandler(requestData)
    }
  }, [renderConditions, snippetsFilter, selectedBrand])

  const countDifference = useMemo((): number => {
    if (postsCount.currentFlagCount && postsCount.prevFlagCount) {
      return postsCount.currentFlagCount - postsCount.prevFlagCount
    }
    return 0
  }, [postsCount.currentFlagCount, postsCount.prevFlagCount])

  const renderUI = useMemo(() => {
    const riskValue = postsCount.currentFlagCount! - postsCount.prevFlagCount!
    if (riskValue === 0) {
      return
    }

    return (
      <>
        <UpArrowIcon className='apc_arrow_icon' />
        <span className='apc_trending_count'>{millify(Math.abs(riskValue))}</span>
      </>
    )
  }, [postsCount])

  const shadeColor = countDifference >= 0 ? '#D7F2E5' : '#F8DEDB'

  return (
    <Spin spinning={isLoading}>
      <div className='flag-stats'>
        <div className='flag-stats__numbers'>
          {postsCount.currentFlagCount === null || postsCount.prevFlagCount === null ? (
            <div className={`all_posts_container`}>
              <span className='apc_count'>-</span>
            </div>
          ) : (
            <div
              className={`all_posts_container${
                postsCount.currentFlagCount - postsCount.prevFlagCount < 0 ? ' all_posts_container_down' : ''
              }`}>
              <span className='apc_count'>
                {postsCount.currentFlagCount ? millify(postsCount.currentFlagCount) : '-'}
              </span>
              {renderUI}
            </div>
          )}
        </div>
        <div className='flag-stats__chart'>
          {chartData.length > 1 && (
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id={`colorValue-${countDifference >= 0 ? 'up' : 'down'}`} x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor={shadeColor} stopOpacity={0.8} />
                    <stop offset='95%' stopColor={shadeColor} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Area
                  type='monotone'
                  dataKey='value'
                  stroke={countDifference >= 0 ? '#17b26a' : '#f04438'}
                  strokeWidth={2}
                  fill={`url(#colorValue-${countDifference >= 0 ? 'up' : 'down'})`}
                  fillOpacity={1}
                  isAnimationActive={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </Spin>
  )
})

export default FlagStats
