import { Menu } from 'antd'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useLocation, Link } from 'react-router-dom'

import { ReactComponent as UsersIcon } from 'assets/images/users-icon.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/your-profile.svg'
import { ReactComponent as PowerInsightsIcon } from 'assets/images/power-insights-outlined-icon.svg'
import { ReactComponent as SpotlightIcon } from 'assets/images/icon-notification.svg'
import { ReactComponent as APIdocsIcon } from 'assets/images/api-docs-icon.svg'

import usePermissions from 'version2/hooks/usePermissions'

import './NavigationSettings.scss'

export const NavigationSettings = observer((): JSX.Element => {
  const location = useLocation()
  const { userStore } = store
  const { currentRole, tenantId } = userStore
  let selectedKeys: string[] = ['1']

  if (location.pathname.includes('/profile')) selectedKeys = ['1']
  else if (location.pathname.includes('/users')) selectedKeys = ['2']
  else if (location.pathname.includes('/spotlight')) selectedKeys = ['3']
  else if (location.pathname.includes('/power-insights')) selectedKeys = ['4']
  else if (location.pathname.includes('/api-docs')) selectedKeys = ['5']

  const canManageTenant = usePermissions('manageTenant') === 'no_access'

  return (
    <nav className='navigation-settings'>
      <Menu mode='vertical' selectedKeys={selectedKeys} triggerSubMenuAction='click'>
        <Menu.Item key='1' id='profile'>
          <Link to='/settings/profile'>
            <ProfileIcon />
            Profile
          </Link>
        </Menu.Item>

        {!canManageTenant && (
          <Menu.Item key='2' id='tenants-configuration'>
            <Link to={`/settings/tenants/configuration/${tenantId}`}>
              <UsersIcon />
              Tenant Configuration
            </Link>
          </Menu.Item>
        )}

        {currentRole === 'DEPLOYMENT_STRATEGIST' && (
          <Menu.Item key='3' id='spotlight'>
            <Link to='/settings/spotlight'>
              <SpotlightIcon />
              Spotlight
            </Link>
          </Menu.Item>
        )}

        {!canManageTenant && (
          <Menu.Item key='4' id='power-insights'>
            <Link to='/settings/power-insights'>
              <PowerInsightsIcon />
              Power Insights
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key='5' id='api-docs'>
          <Link to='/settings/api-docs'>
            <APIdocsIcon />
            API Docs
          </Link>
        </Menu.Item>
      </Menu>
    </nav>
  )
})
