import { Tooltip } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import millify from 'millify'

import { ExecutiveCategoryType, SnippetsFilter } from 'models/models'
import { store } from 'store'

import { ReactComponent as NegativeIcon } from 'assets/images/executive-intelligence/icons/language-risks/negative.svg'
import { ReactComponent as ObsceneIcon } from 'assets/images/executive-intelligence/icons/language-risks/obscene.svg'
import { ReactComponent as IdentityHateIcon } from 'assets/images/executive-intelligence/icons/language-risks/identity-hate.svg'
import { ReactComponent as ToxicIcon } from 'assets/images/executive-intelligence/icons/language-risks/toxic.svg'
import { ReactComponent as ThreatIcon } from 'assets/images/executive-intelligence/icons/language-risks/threat.svg'
import { ReactComponent as SourceRiskIcon } from 'assets/images/executive-intelligence/icons/language-risks/source.svg'
import { ReactComponent as WatchListIcon } from 'assets/images/executive-intelligence/icons/language-risks/watchlist.svg'
import { ReactComponent as PendulumLogo } from 'assets/images/LogoiconMobile.svg'
import AlertIcon from 'assets/images/alert-icon.svg'
import AlertIconWhite from 'assets/images/alert-icon-white.svg'

import TagManager from 'react-gtm-module'
import { SubStore } from 'types/types'

import styles from './LanguageRiskItem.module.scss'

const TOOLTIP_TEXT_LOOKUP = {
  Toxic: 'Posts containing toxic, angry & hate speeches',
  Obscene: 'Offensive or disgusting by accepted standards of morality and decency',
  'Identity Hate': 'Posts containing verbal hate speeches',
  Negative: 'Spreading negative remarks',
  Threat: 'A statement of an intention to inflict pain, injury, damage, or other hostile action',
  Recommended: 'Pendulum suggested posts',
  Source: 'High risk',
  Watchlist: '',
  All: '',
  NarrativeAll: '',
  NarrativeMainstream: '',
  NarrativeRisk: '',
  NarrativeNegative: '',
}

const RISK_ICONS_MAP = {
  Toxic: <ToxicIcon className={classNames(styles.withFill, styles.withStroke, styles.toxic)} />,
  Obscene: <ObsceneIcon className={styles.withFill} />,
  'Identity Hate': <IdentityHateIcon className={classNames(styles.withFill, styles.withStroke, styles.identityHate)} />,
  Negative: <NegativeIcon className={styles.withFill} />,
  Threat: <ThreatIcon className={styles.withFill} />,
  Recommended: <PendulumLogo />,
  Source: <SourceRiskIcon className={styles.withStroke} />,
  Watchlist: <WatchListIcon />,
  All: '',
  NarrativeAll: '',
  NarrativeMainstream: '',
  NarrativeRisk: '',
  NarrativeNegative: '',
}

interface RiskItemProps {
  name: ExecutiveCategoryType
  data: {
    total_value: number
    data_points: {
      metric_value: number
    }[]
  }
  isPowerInsightsMode?: boolean
  subStore: SubStore
}

export const LanguageRiskItem: React.FC<RiskItemProps> = observer(function LanguageRiskItem({
  name,
  data,
  isPowerInsightsMode,
  subStore,
}) {
  const navigate = useNavigate()
  const { category: currentCategoryParam } = useParams()
  const { userStore, executiveIntelligence } = store

  const { userId, userName, tenantId, roleId } = userStore
  const {
    snippetsFilter,
    selectedExecutiveCategory,
    setSelectedExecutiveCategory,
    setSnippetsFilter,
    fetchPowerInsights,
    filterChips,
    setFilterChips,
    fetchSnippetsInfo,
    activeItem,
  } = store[`${subStore}Store`]

  let { singlePersonData } = executiveIntelligence

  const navigateToCategory = () => {
    if (name === currentCategoryParam) {
      navigate(`/monitor/executive-intelligence/details/${singlePersonData?.id}/All`)
    } else {
      navigate(`/monitor/executive-intelligence/details/${singlePersonData?.id}/${name}`)
    }
  }

  const applyLanguageRiskFilter = () => {
    if (name === selectedExecutiveCategory) {
      setFilterChips({
        ...filterChips,
        languageRisk: {
          ...filterChips.languageRisk,
          value: '',
        },
      })
      setSelectedExecutiveCategory('All')

      switch (name) {
        case 'Negative': {
          const { filter_sentiment, ...rest } = snippetsFilter
          setSnippetsFilter(rest)
          break
        }
        case 'Source': {
          const { filter_risk, ...rest } = snippetsFilter
          setSnippetsFilter(rest)
          break
        }
      }
    } else {
      let filters: SnippetsFilter = {
        ...snippetsFilter,
        filter_risk: [0, 100] as [number, number],
        filter_sentiment: '',
      }

      setFilterChips({
        ...filterChips,
        languageRisk: {
          ...filterChips.languageRisk,
          value: name,
        },
      })

      switch (name) {
        case 'Negative':
          filters = { ...filters, filter_sentiment: ['Negative'] }
          break
        case 'Source':
          filters = { ...filters, filter_risk: [50, 100] }
          break
      }
      setSnippetsFilter(filters)
      setSelectedExecutiveCategory(name)
    }

    fetchPowerInsights(activeItem!)
    fetchSnippetsInfo(activeItem!)
  }

  const onLanguageRiskClickHandler = () => {
    if (isPowerInsightsMode) {
      applyLanguageRiskFilter()
    } else {
      navigateToCategory()

      // google analytics executive details risk category
      TagManager.dataLayer({
        dataLayer: {
          event: 'executive_details_risk_category',
          riskCategory: name,
          executiveName: singlePersonData?.name,
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
        },
      })
    }
  }

  return (
    <Tooltip overlayClassName='pendulum-themed-tooltip' title={TOOLTIP_TEXT_LOOKUP[name]}>
      <div
        onClick={onLanguageRiskClickHandler}
        className={classNames(styles.riskItemWrapper, {
          [styles.selected]: name === currentCategoryParam || name === selectedExecutiveCategory,
          [styles.noRisk]: !data?.total_value || data?.total_value === 0,
        })}>
        <div className={styles.riskItem}>
          <div className={styles.riskIcon}>{RISK_ICONS_MAP[name]}</div>

          <div className={styles.riskData}>
            <p className={styles.riskName}>{name}</p>
            <span className={styles.postsCount}>{!!data?.total_value ? millify(data.total_value) : '0'} posts</span>
          </div>
        </div>

        {!isPowerInsightsMode && (
          <div className={styles.bellIcon}>
            <img src={name === currentCategoryParam ? AlertIconWhite : AlertIcon} alt='alert icon' />
          </div>
        )}
      </div>
    </Tooltip>
  )
})
