import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { CreateAndUpdateFolderDataType, FolderMethod, FolderParamsType } from 'store/monitorVersion2/types'

export function folderCRUD({
  method,
  id,
  data,
  params,
}: {
  method: FolderMethod
  id?: string
  data?: CreateAndUpdateFolderDataType
  params?: FolderParamsType
}) {
  return axios({
    method,
    url: id ? `${ROUTES.folder}/${id}` : ROUTES.folder,
    params,
    data,
  })
}
