import { ResponsiveTreeMap } from '@nivo/treemap'
import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { store } from 'store'
import { monitorMode, monitorSnippetPage } from 'models/models'
import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import './MonitorHeatMap.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
  selectMode?: boolean
  size?: 'small' | 'large'
}

export const MonitorHeatMap = observer(({ mode, page, selectMode = false, size = 'small' }: Props) => {
  let currentStore, monitorStore
  if (page === 'define' && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  }

  const {
    snippetsFilter,
    powerInsightsFilterChips,
    fetchPowerInsights,
    setSnippetsFilter,
    liteCommunities,
    powerInsightsData,
    setPowerInsightsFilterChips,
  } = currentStore

  const { activeItem, selectedCommunityInfo, setSelectedCommunityInfo } = monitorStore
  const data = powerInsightsData.heatMapData

  let [processedData, setProcessedData] = useState({
    name: 'Communities',
    color: '#cca55a',
    children: data?.data_points.map((el) => {
      const prEl: any = el
      prEl.name = el.community_id
      return prEl
    }),
  })

  let [colorArr, setColorArr] = useState<string[]>([])

  const onSelect = useCallback((community?: number) => {
    setSnippetsFilter({ ...snippetsFilter, community })
    fetchPowerInsights(activeItem?.id ?? '', '')
  }, [])

  useEffect(() => {
    let profisoryData: { name: string; color: string; children: any[] } = {
      name: 'Communities',
      color: '#cca55a',
      children: [],
    }

    data?.data_points.forEach((point) => {
      let communityName: any = liteCommunities.filter((comm) => comm.id === parseInt(point.community_id))

      communityName =
        communityName.length > 0 ? (communityName[0].name ? communityName[0].name : '') : point.community_id

      profisoryData.children.push({ ...point, name: communityName })
    })

    setProcessedData(profisoryData)
  }, [data, liteCommunities])

  useEffect(() => {
    let color = '#cca55a'
    let provColorArr = []

    for (let i = 255; i > 41; i -= Math.round((255 - 41) / (data?.data_points.length || 1))) {
      provColorArr.push(`${color}${i.toString(16)}`)
    }
    if (selectedCommunityInfo) {
      provColorArr = provColorArr.map((el, index) => {
        if (index !== selectedCommunityInfo.index) {
          let element = el
          let opacity = element.slice(element.length - 2, element.length)
          element = element.slice(0, element.length - 2)

          let opacityNum = parseInt(opacity, 16) - 128

          if (opacityNum <= 41) opacityNum = 41

          opacity = opacityNum.toString(16).split('.')[0]

          return `${element}${opacity}`
        }
        return '#cca55a'
      })
    }
    setColorArr(provColorArr)
  }, [data, selectedCommunityInfo, snippetsFilter.community, liteCommunities])

  return (
    <div className={classNames('c-monitor-heat-map', { 'large-heat-map': size === 'large' })}>
      <div className='section-header'>
        <div className='section-description'>Group of people with particular mutual perspective.</div>
      </div>

      <ResponsiveTreeMap
        onClick={(tile) => {
          if (!selectMode) return

          // nothing will happen if the user clicks on any tile for the executive intelligence page
          if (page === 'executive-intelligence') return

          let clickedIndex: number = -1
          let id: number = -1

          if (!processedData?.children) return
          processedData.children.forEach((el, index) => {
            if (el?.name === tile.id) clickedIndex = index
          })
          clickedIndex = processedData.children.findIndex((el) => el.name === tile.id)

          if (onSelect) {
            if (selectedCommunityInfo?.index !== clickedIndex && clickedIndex >= 0) {
              const community = processedData.children.filter((el) => el.name === tile.id)[0]
              id = community.community_id
              onSelect(community.community_id)
            } else {
              if (clickedIndex < 0) {
                setPowerInsightsFilterChips({
                  ...powerInsightsFilterChips,
                  community: {
                    ...powerInsightsFilterChips.community,
                    value: '',
                  },
                })
                setSelectedCommunityInfo(undefined)
                return setSnippetsFilter({ ...snippetsFilter, community: undefined })
              }
              onSelect()
            }
          }
          if (selectedCommunityInfo?.index !== clickedIndex) {
            setPowerInsightsFilterChips({
              ...powerInsightsFilterChips,
              community: {
                ...powerInsightsFilterChips.community,
                value: tile.id,
              },
            })
            setSelectedCommunityInfo({ index: clickedIndex, name: tile.id, id: id.toString() })
            setSnippetsFilter({ ...snippetsFilter, community: id })
          } else {
            setPowerInsightsFilterChips({
              ...powerInsightsFilterChips,
              community: {
                ...powerInsightsFilterChips.community,
                value: '',
              },
            })
            setSelectedCommunityInfo(undefined)
            setSnippetsFilter({ ...snippetsFilter, community: undefined })
          }
        }}
        colors={colorArr}
        data={processedData}
        tile='binary'
        identity='name'
        value='metric_value'
        labelSkipSize={55}
        leavesOnly={true}
        innerPadding={5}
        label='id'
        orientLabel={true}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1000]],
        }}
        parentLabelPosition='left'
        nodeOpacity={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0]],
        }}
      />
    </div>
  )
})
