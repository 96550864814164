import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { store } from 'store'
import { Dropdown, Spin } from 'antd'
import * as d3 from 'd3'

import { ChordChartDataset, INarrativeInfoCategory } from 'models/models'
import { NarrativeInfo } from '../NarrativeInfo/NarrativeInfo'

import useDelayedLoading from 'version2/utils/useDelayedLoading'

import './ChordGraph.scss'

interface Props {
  root: d3.HierarchyPointNode<unknown> | null
  dataset: ChordChartDataset[]
  setDataset: (data: any) => void
}

export const ChordGraph = observer(({ root, dataset, setDataset }: Props) => {
  const { anticipatoryIntelligenceStore, loaderStore } = store
  const {
    isSideDetailsOpen,
    threatTopics,
    risks,
    riskThreatDataListView,
    recentRiskFetchedName,
    attentionFlags,
    attentionFlagsThreatDataListView,
    recentAttentionFlagFetchedName,
    recentRiskAttentionFlagsFetchedName,
    riskAttentionFlagData,
    setIsSideDetailsOpen,
    setSideDetails,
    setRecentRiskFetchedName,
    setRecentAttentionFlagFetchedName,
    setRecentRiskAttentionFlagsFetchedName,
    fetchRisksDetailsListView,
    fetchAttentionFlagsDetailsListView,
    fetchRiskAttentionFlagsDetails,
  } = anticipatoryIntelligenceStore

  const { isLoadingRisks, isLoadingAttentionFlags, isLoadingNoOfPosts, isLoadingFlags } = loaderStore

  const isLoading = useDelayedLoading([isLoadingRisks, isLoadingAttentionFlags, isLoadingNoOfPosts, isLoadingFlags])

  const width = isSideDetailsOpen.length > 0 ? 200 : 310
  const radius = width / 2
  const range = ['C1', 'C2', 'C3', 'C4', 'C5', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6']
  const rangeCodes: { [x: string]: string } = {
    boycott: 'topic.range.midRange.M4.boycott',
    kinetic_action: 'topic.range.midRange.M2.kinetic_action',
    legal_action: 'topic.range.midRange.M3.legal_action',
    legislative_regulation: 'topic.range.midRange.M1.legislative_regulation',
    social_unrest: 'topic.range.midRange.M5.social_unrest',
    taxation: 'topic.range.midRange.M6.taxation',
    Brand: 'topic.range.closeRange.C1.Brand',
    Board: 'topic.range.closeRange.C2.Board',
    Executive: 'topic.range.closeRange.C3.Executive',
    Supplier: 'topic.range.closeRange.C4.Supplier',
    Industry: 'topic.range.closeRange.C5.Industry',
  }
  const arcClasses: {
    General: string
    midRange: string
    closeRange: string
    highlighted: string
  } = {
    General: 'chord-graph__chart__arcs__general',
    midRange: 'chord-graph__chart__arcs__midRange',
    closeRange: 'chord-graph__chart__arcs__closeRange',
    highlighted: 'chord-graph__chart__arcs--highlighted',
  }

  let values: { [x: string]: number } = {}
  const linkageLine = d3
    .lineRadial()
    .curve(d3.curveBundle.beta(0.75))
    .radius((d: any) => d.y)
    .angle((d: any) => {
      let value = d.x + (d?.data?.size || 0) + (values[d.data.name] || 0)
      const random = Math.floor(Math.random() * (5 - -3)) + -3
      values[d.data.name] = ((values[d.data.name] || 0) + random) / 100
      return value
    })
  const arc = d3
    .arc()
    .innerRadius((d: any) => d.innerRadius)
    .outerRadius((d: any) => d.outerRadius)
    .startAngle((d: any) => d.startAngle)
    .endAngle((d: any) => d.endAngle)
  const arcWidth = 0.2
  const leavesArcInnerRadius = radius - (isSideDetailsOpen.length > 0 ? 44.5 : 99.5)
  const parentsArcInnerRadius = radius - (isSideDetailsOpen.length > 0 ? 43.3 : 98.3)
  const leavesArcOuterRadius = leavesArcInnerRadius + arcWidth
  const parentsArcOuterRadius = parentsArcInnerRadius + arcWidth
  const viewBox = isSideDetailsOpen
    ? [-width / 2, -width / 2.8, width, width / 1.2]
    : [-width / 2, -width / 4.2, width, width / 1.9]
  let extraSpaceForRange = 0.08
  let extraSpaceForThreats = 0.05
  let selectedLink = ''

  const [isNodeClicked, setIsNodeClicked] = useState(false)
  const [narrativeInfoDropdown, setNarrativeInfoDropdown] = useState<JSX.Element>(<></>)

  useEffect(() => {
    fetchRisksDetailsListView()
  }, [risks])

  useEffect(() => {
    fetchAttentionFlagsDetailsListView()
  }, [attentionFlags])

  useEffect(() => {
    if (Object.keys(risks).length && Object.keys(attentionFlags).length) {
      fetchRiskAttentionFlagsDetails()
    }
  }, [risks, attentionFlags])

  useEffect(() => {
    if (recentRiskFetchedName.length > 0) {
      const namesArray = [...recentRiskFetchedName]
      const recentRisk = namesArray.pop()
      setRecentRiskFetchedName(namesArray, 'remove')

      if (root && root.children && root.children.length > 0) {
        if (recentRisk?.length) {
          const threatName = recentRisk.split(',,')[0]
          const categoryName = recentRisk.split(',,')[1]

          const riskThreatTopic = riskThreatDataListView[threatName][categoryName] || {}
          const riskThreatTopicKeys = Object.keys(riskThreatTopic)
          const newDataset = [...dataset]

          for (const key of riskThreatTopicKeys) {
            const datasetNodeIndex = newDataset.findIndex((data) => data.name.includes(categoryName))

            if (datasetNodeIndex > -1) {
              const datasetNode = newDataset[datasetNodeIndex]
              const threatRisk = riskThreatTopic[key]
              datasetNode.noOfPosts = threatRisk.noOfPosts

              if ((datasetNode.noOfPosts || 0) > 0) {
                const flag = rangeCodes[key as keyof { [x: string]: string }]
                if (flag && flag.length > 0 && !datasetNode.imports.includes(flag)) {
                  datasetNode.imports.push(flag)
                }
              }
            }
          }
          setDataset(newDataset)
        }
      }
    }
  }, [JSON.stringify(recentRiskFetchedName)])

  useEffect(() => {
    if (recentAttentionFlagFetchedName.length > 0) {
      const namesArray = [...recentAttentionFlagFetchedName]
      const recentAttentionFlag = namesArray.pop()
      setRecentAttentionFlagFetchedName(namesArray, 'remove')

      if (root && root.children && root.children.length > 0) {
        if (recentAttentionFlag?.length) {
          const threatName = recentAttentionFlag.split(',,')[0]
          const categoryName = recentAttentionFlag.split(',,')[1]
          const attentionFlagThreatTopic = attentionFlagsThreatDataListView[threatName][categoryName] || {}
          const attentionFlagThreatTopicKeys = Object.keys(attentionFlagThreatTopic)
          const newDataset = [...dataset]

          for (const key of attentionFlagThreatTopicKeys) {
            const datasetNodeIndex = newDataset.findIndex((data) => data.name.includes(categoryName))

            if (datasetNodeIndex > -1) {
              const datasetNode = newDataset[datasetNodeIndex]
              const threatAttentionFlag = attentionFlagThreatTopic[key]
              datasetNode.noOfPosts = threatAttentionFlag.noOfPosts

              if ((datasetNode.noOfPosts || 0) > 0) {
                const flag = rangeCodes[key as keyof { [x: string]: string }]
                if (flag && flag.length > 0 && !datasetNode.imports.includes(flag)) {
                  datasetNode.imports.push(flag)
                }
              }
            }
          }
          setDataset(newDataset)
        }
      }
    }
  }, [JSON.stringify(recentAttentionFlagFetchedName)])

  useEffect(() => {
    if (recentRiskAttentionFlagsFetchedName.length > 0) {
      const namesArray = [...recentRiskAttentionFlagsFetchedName]
      const recentRiskAttentionFlag = namesArray.pop()
      setRecentRiskAttentionFlagsFetchedName(namesArray, 'remove')

      if (root && root.children && root.children.length > 0) {
        if (recentRiskAttentionFlag?.length) {
          const attentionFlagName = recentRiskAttentionFlag.split(',,')[0]
          const riskName = recentRiskAttentionFlag.split(',,')[1]
          const riskAttentionFlag = riskAttentionFlagData[attentionFlagName][riskName] || {}
          const newDataset = [...dataset]

          const datasetNodeIndex = newDataset.findIndex((data) => data.name.includes(riskName))

          if (datasetNodeIndex > -1) {
            const datasetNode = newDataset[datasetNodeIndex]
            datasetNode.noOfPosts = riskAttentionFlag.noOfPosts

            if ((datasetNode.noOfPosts || 0) > 0) {
              const flag = rangeCodes[attentionFlagName as keyof { [x: string]: string }]
              if (flag && flag.length > 0 && !datasetNode.imports.includes(flag)) {
                datasetNode.imports.push(flag)
              }
            }
          }
          setDataset(newDataset)
        }
      }
    }
  }, [JSON.stringify(recentRiskAttentionFlagsFetchedName)])

  useEffect(() => {
    setIsNodeClicked(false)
    if (root) {
      const threatDataset = root?.leaves().filter((d: any) => !d.data.name.includes('TITLE')) || []
      const titleDataset = root?.leaves().filter((d: any) => d.data.name.includes('TITLE')) || []
      const rangeDataset = root?.leaves().filter((d: any) => range.includes(d.parent.data.name)) || []
      const leafGroups = d3.groups(root.leaves(), (d: any) => d.parent?.data.name)
      const arcAngles = leafGroups.map((g) => {
        let addition = range.includes(g[0]) ? extraSpaceForRange : extraSpaceForThreats
        const node: any = g[1][0].data
        let label = ''
        if (node?.name) label = node?.name
        const parent: any = g[1][0].parent?.parent
        let check = false
        if (parent) check = parent.data.name === 'midRange' || parent.data.name === 'closeRange'

        return {
          name: g[0],
          label: label,
          start: d3.min(g[1], (d) => d.x - addition),
          end: d3.max(g[1], (d) => d.x + addition),
          class:
            isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(label) && check
              ? arcClasses.highlighted
              : arcClasses.General,
        }
      })
      const rangeData = root.children?.filter((d: any) => d.data.name === 'range')[0].children || []
      const parentGroups = d3.groups(rangeData, (d: any) => d.data.name)
      const parentGroupsArcAngles = parentGroups.map((g: any) => ({
        name: g[0],
        start: d3.min(g[1][0], (d: any) => d.x),
        end: d3.max(g[1][0], (d: any) => d.x),
        class:
          arcClasses[
            g[0] as keyof {
              General: string
              midRange: string
              closeRange: string
              highlighted: string
            }
          ],
      }))

      const svg = d3.select('#chart')
      svg.selectChildren('svg').remove()

      d3.select('#chart').append('svg').attr('id', 'chord').attr('viewBox', viewBox)

      addLeavesArc(arcAngles)
      addRangeArc(parentGroupsArcAngles)
      addTopicSvgs(root)
      addSubThreat(threatDataset)
      addTitles(titleDataset)
      addRange(rangeDataset)
      const outgoing = root?.leaves().flatMap((leaf: any) => leaf.outgoing)
      if (outgoing) addLinks(root?.leaves().flatMap((leaf: any) => leaf.outgoing))
    }
  }, [dataset, root, isSideDetailsOpen])

  useEffect(() => {
    setNarrativeInfoDropdown(<></>)
    if (!isNodeClicked) changeGraphToOriginal()
  }, [isNodeClicked])

  useEffect(() => {
    if (isSideDetailsOpen.length > 0) makeGraphFadeOut()
  }, [isSideDetailsOpen])

  const addTitles = (titleDataset: d3.HierarchyPointNode<unknown>[]) => {
    const titleSvgs = d3.selectAll('.chord-graph_svg_text_title').data(titleDataset)

    titleSvgs
      .append('text')
      .attr('dy', '0.31em')
      .attr('y', (d: any) => (d.x < Math.PI ? 2.5 : -2.5))
      .attr('x', (d: any) => (d.x < Math.PI ? 4 : -4))
      .attr('text-anchor', (d: any) => (d.x < Math.PI ? 'start' : 'end'))
      .attr('transform', (d: any) => (d.x >= Math.PI ? 'rotate(180)' : null))
      .attr('id', (d: any) => d.data.name.replaceAll(' ', '_'))
      .text((d: any) => d.data.name.replaceAll('TITLE', '').replaceAll('_', ' '))
      .attr('class', (d: any) => 'chord-graph__chart__title')

    titleSvgs
      .insert('rect', 'text')
      .attr('width', (d: any) => {
        const width =
          (d3.selectAll('#' + d.data.name.replaceAll(' ', '_')).node() as SVGSVGElement)?.getBBox().width || 10
        return width + 4.5
      })
      .attr('height', 5)
      .attr('dy', '0.31em')
      .attr('x', 1)
      .attr('class', 'chord-graph__chart__title__background')
  }

  const addSubThreat = (threatDataset: d3.HierarchyPointNode<unknown>[]) => {
    const threatSvgs = d3.selectAll('.chord-graph_svg_text').data(threatDataset)

    threatSvgs
      .append('text')
      .attr('dy', '0.31em')
      .attr('x', (d: any) => (d.x <= Math.PI ? 2 : -2))
      .attr('text-anchor', (d: any) => (d.x <= Math.PI ? 'start' : 'end'))
      .attr('transform', (d: any) => (d.x > Math.PI ? 'rotate(180)' : null))
      .text((d: any) => d.data.name.replaceAll('_', ' '))
      .attr('class', 'chord-graph__chart__labels')
      .attr('class', (d: any) => {
        return isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(d.data.name)
          ? 'sample_text chord-graph__chart__labels--highlighted'
          : 'chord-graph__chart__labels'
      })
      .each(function (d: any) {
        d.text = this
      })
      .on('click', onClickLink)
  }

  const addRange = (rangeDataset: d3.HierarchyPointNode<unknown>[]) => {
    const rangeSvgs = d3.selectAll('.chord-graph_svg_text_range').data(rangeDataset)

    rangeSvgs
      .append('text')
      .attr('dy', '0.31em')
      .attr('y', (d: any) => (d.x < Math.PI ? 2.5 : -2.5))
      .attr('x', (d: any) => (d.x < Math.PI ? 4 : -4))
      .attr('text-anchor', (d: any) => (d.x < Math.PI ? 'start' : 'end'))
      .attr('transform', (d: any) => (d.x >= Math.PI ? 'rotate(180)' : null))
      .attr('id', (d: any) => d.data.name.replaceAll(' ', '_'))
      .text((d: any) => d.data.name.replaceAll('_', ' '))
      .attr('class', (d: any) => {
        return isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(d.data.name)
          ? 'sample_text chord-graph__chart__title--highlighted'
          : 'chord-graph__chart__title'
      })
      .each(function (d: any) {
        d.text = this
      })
      .on('click', onClickLink)

    rangeSvgs
      .insert('rect', 'text')
      .attr('width', (d: any) => {
        const width =
          (d3.selectAll('#' + d.data.name.replaceAll(' ', '_')).node() as SVGSVGElement)?.getBBox().width || 10
        return width + 4.5
      })
      .attr('id', (d: any) => 'rect_' + d.data.name.replaceAll(' ', '_'))
      .attr('height', 5)
      .attr('dy', '0.31em')
      .attr('x', 1)
      .attr('class', (d: any) => {
        return isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(d.data.name)
          ? 'chord-graph__chart__rectangle--highlighted'
          : 'chord-graph__chart__title__background'
      })
      .each(function (d: any) {
        d.rect = this
      })
      .on('click', onClickLink)
  }

  const addLeavesArc = (arcAngles: Iterable<unknown>) => {
    d3.select('#chord')
      .selectAll('.arc')
      .data(arcAngles)
      .enter()
      .append('path')
      .attr('id', (d: any) => `arc_${d.label}`)
      .attr('d', (d: any) =>
        arc({
          startAngle: d.start,
          endAngle: d.end,
          innerRadius: leavesArcInnerRadius,
          outerRadius: leavesArcOuterRadius,
        }),
      )
      .attr('class', (d: any) => d.class)
  }

  const addRangeArc = (parentGroupsArcAngles: Iterable<unknown>) => {
    d3.select('#chord')
      .selectAll('.arc')
      .data(parentGroupsArcAngles)
      .enter()
      .append('path')
      .attr('id', (d, i) => `parc_${i}`)
      .attr('d', (d: any) =>
        arc({
          startAngle: d.start - extraSpaceForRange,
          endAngle: d.end + extraSpaceForRange,
          innerRadius: parentsArcInnerRadius,
          outerRadius: parentsArcOuterRadius,
        }),
      )
      .attr('class', (d: any) => d.class)
  }

  const addTopicSvgs = (root: d3.HierarchyPointNode<unknown>) => {
    d3.select('#chord')
      .append('g')
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', (d: any) => {
        if (range.includes(d.parent.data.name)) return `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},-2.5)`
        else return `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`
      })
      .attr('class', 'chord-graph_svg_class')
      .append('g')
      .attr('class', (d: any) => {
        if (d.data.name.includes('TITLE')) return 'chord-graph_svg_text_title'
        else if (range.includes(d.parent.data.name)) return 'chord-graph_svg_text_range'
        else return 'chord-graph_svg_text'
      })
  }

  const addLinks = (linksData: any) => {
    d3.select('#chord')
      .append('g')
      .selectAll('path')
      .data(linksData)
      .join('path')
      .attr('id', ([node1, node2]: any) => `link_${node1.data.name}_${node2.data.name}`)
      .attr('d', ([i, o]: any) => linkageLine(i.path(o)))
      .attr('class', ([node1, node2]: any) => {
        const linkKey = `${node1.parent.data.name}_${node1.data.name}_${node2.data.name}`
        return isSideDetailsOpen.length > 0 && isSideDetailsOpen === linkKey
          ? 'sample_link chord-graph__links--highlighted'
          : 'chord-graph__links'
      })
      .each(function (d: any) {
        d.path = this
      })
      .on('click', showNarrativeInfoPopup)
  }

  const onClickLink = (event: any, d: any) => {
    setNarrativeInfoDropdown(<></>)
    setIsSideDetailsOpen('')
    setSideDetails({
      topic: '',
      category: '',
      riskOrFlagName: '',
      tab: '',
      url: '',
    })
    d3.selectAll('.sample_link').attr('class', 'chord-graph__links')
    unSelectNodes()

    if (d.data.name === selectedLink) {
      selectedLink = ''
      setIsNodeClicked(false)
      changeGraphToOriginal()
    } else {
      selectedLink = d.data.name

      if (!isNodeClicked) makeGraphFadeOut()

      highlightSelectedNodes(d)

      d3.selectAll(d.incoming.map((d: any) => d.path))
        .attr('class', 'sample_link chord-graph__links--highlighted')
        .raise()

      d3.selectAll(d.incoming.map(([d]: any) => d.text)).attr(
        'class',
        'sample_text chord-graph__chart__labels--highlighted',
      )

      d3.selectAll(d.outgoing.map((d: any) => d.path))
        .attr('class', 'sample_link chord-graph__links--highlighted')
        .raise()

      d3.selectAll(d.outgoing.map(([, d]: any) => d.text))
        .attr('class', 'sample_text chord-graph__chart__title--highlighted')
        .attr('font-weight', 'bold')
    }
  }

  const showNarrativeInfoPopup = (event: any, [node1, node2]: any) => {
    const { pageX: x, pageY: y } = event
    d3.select('#info')
      .style('position', 'absolute')
      .style('left', `${x}px`)
      .style('top', `${y}px`)
      .style('display', 'block')

    const isRiskAttentionFlagNodes =
      range.includes(node1?.parent?.data?.name) && range.includes(node2?.parent?.data?.name)

    if (!isRiskAttentionFlagNodes) {
      const isMidRange = node2?.parent?.parent?.data?.name === 'midRange' ? true : false
      const topic = threatTopics[node1?.parent?.data?.name]
      const topicCategory = topic?.categories.find((cat) => cat.name === node1?.data?.name)
      const risk = risks[node2?.data?.name]
      const attentionFlag = attentionFlags[node2?.data?.name]
      const threatRiskFlag = isMidRange
        ? riskThreatDataListView[node1?.parent?.data?.name][node1?.data?.name]
        : attentionFlagsThreatDataListView[node1?.parent?.data?.name][node1?.data?.name]

      const threatRiskFlagCategory: any = threatRiskFlag[node2?.data?.name]
      const category: INarrativeInfoCategory = isMidRange
        ? {
            id: topicCategory?.id,
            label: topicCategory?.label,
            name: topicCategory?.name,
            subItemName: risk?.label,
            noOfPosts: threatRiskFlagCategory.noOfPosts,
            metricValues: threatRiskFlagCategory.metricValues,
            conditions: threatRiskFlagCategory?.conditions,
            description: topicCategory?.description || '',
            narrative_number: threatRiskFlagCategory?.ids || -1,
            narrative_id: topicCategory?.narrative_id || '',
            flags: threatRiskFlagCategory.flags,
          }
        : {
            id: topicCategory?.id,
            label: topicCategory?.label,
            name: topicCategory?.name,
            subItemName: attentionFlag?.displayLabel,
            noOfPosts: threatRiskFlagCategory.noOfPosts,
            metricValues: threatRiskFlagCategory.metricValues,
            description: topicCategory?.description || '',
            narrative_number: topicCategory?.narrative_number || -1,
            narrative_id: topicCategory?.narrative_id || '',
            flags: threatRiskFlagCategory.flags,
            conditions: threatRiskFlagCategory.conditions,
          }
      const url = isMidRange
        ? `tab=mid-range&topic=${encodeURIComponent(node1.parent.data.name)}&risk=${encodeURIComponent(
            node2.data.name,
          )}&category=${encodeURIComponent(category.name || '')}`
        : `tab=close-range&topic=${encodeURIComponent(node1.parent.data.name)}&attention-flag=${encodeURIComponent(
            node2.data.name,
          )}&category=${encodeURIComponent(category.name || '')}`
      const tab = isMidRange ? 'mid-range' : 'close-range'
      const info = (
        <Dropdown
          open
          overlayStyle={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
          overlay={
            <NarrativeInfo
              name={node1.parent.data.name}
              category={category}
              url={url}
              tab={tab}
              isChordView={true}
              sideDetailsInfo={{
                topic: node1.parent.data.name,
                category: category.name || '',
                riskOrFlagName: node2.data.name,
                tab: tab,
                url: url,
              }}
              setNarrativeInfoDropdown={setNarrativeInfoDropdown}
            />
          }>
          <span></span>
        </Dropdown>
      )

      setNarrativeInfoDropdown(info)
    } else {
      const riskObj = risks[node1?.data?.name]
      const attentionFlagObj = attentionFlags[node2?.data?.name]

      if (riskAttentionFlagData[`${node2?.data?.name}`]) {
        const threatRiskFlag = riskAttentionFlagData[`${node2?.data?.name}`][`${node1?.data?.name}`]

        const category: INarrativeInfoCategory = {
          id: riskObj?.id,
          label: riskObj?.label,
          name: node1?.parent?.data?.name,
          subItemName: attentionFlagObj?.displayLabel,
          noOfPosts: threatRiskFlag.noOfPosts,
          metricValues: threatRiskFlag.metricValues,
          description: riskObj?.description || '',
          narrative_number: riskObj?.narrative_number || -1,
          narrative_id: riskObj?.narrative_id || '',
          flags: threatRiskFlag.flags,
          conditions: threatRiskFlag.conditions,
        }
        const url = `tab=mid-close-range&risk=${encodeURIComponent(
          node1.data.name,
        )}&attention-flag=${encodeURIComponent(node2.data.name)}`
        const tab = 'mid-close-range'
        const info = (
          <Dropdown
            open
            overlayStyle={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
            overlay={
              <NarrativeInfo
                name={node1.parent.data.name}
                category={category}
                url={url}
                tab={tab}
                isChordView={true}
                sideDetailsInfo={{
                  topic: node1.parent.data.name,
                  category: category.name || '',
                  riskOrFlagName: `${node2.data.name},${node1.data.name}`,
                  tab: tab,
                  url: url,
                }}
                setNarrativeInfoDropdown={setNarrativeInfoDropdown}
              />
            }>
            <span></span>
          </Dropdown>
        )

        setNarrativeInfoDropdown(info)
      } else {
        const riskObj = risks[node1?.data?.name]
        const attentionFlagObj = attentionFlags[node2?.data?.name]

        if (riskAttentionFlagData[`${node2?.data?.name}`]) {
          const threatRiskFlag = riskAttentionFlagData[`${node2?.data?.name}`][`${node1?.data?.name}`]

          const category: INarrativeInfoCategory = {
            id: riskObj?.id,
            label: riskObj?.label,
            name: node1?.parent?.data?.name,
            subItemName: attentionFlagObj?.displayLabel,
            noOfPosts: threatRiskFlag.noOfPosts,
            metricValues: threatRiskFlag.metricValues,
            description: riskObj?.description || '',
            narrative_number: riskObj?.narrative_number || -1,
            narrative_id: riskObj?.narrative_id || '',
            flags: threatRiskFlag.flags,
            conditions: threatRiskFlag.conditions,
          }
          const url = `tab=mid-close-range&risk=${encodeURIComponent(
            node1.data.name,
          )}&attention-flag=${encodeURIComponent(node2.data.name)}`
          const tab = 'mid-close-range'
          const info = (
            <Dropdown
              open
              overlayStyle={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
              overlay={
                <NarrativeInfo
                  name={node1.parent.data.name}
                  category={category}
                  url={url}
                  tab={tab}
                  isChordView={true}
                  sideDetailsInfo={{
                    topic: node1.parent.data.name,
                    category: category.name || '',
                    riskOrFlagName: `${node2.data.name},${node1.data.name}`,
                    tab: tab,
                    url: url,
                  }}
                  setNarrativeInfoDropdown={setNarrativeInfoDropdown}
                />
              }>
              <span></span>
            </Dropdown>
          )

          setNarrativeInfoDropdown(info)
        }
      }
    }
  }

  const makeGraphFadeOut = () => {
    setIsNodeClicked(true)
    d3.selectAll('.chord-graph__chart__labels').attr(
      'class',
      'chord-graph__chart__labels chord-graph__chart__labels__opacity',
    )
    d3.selectAll('.chord-graph__chart__title').attr(
      'class',
      'chord-graph__chart__title chord-graph__chart__title__opacity',
    )

    d3.selectAll('.chord-graph__chart__title__background').attr(
      'class',
      'chord-graph__chart__title__background chord-graph__chart__title__background__opacity',
    )

    d3.selectAll('.chord-graph__chart__arcs__general').attr('class', 'chord-graph__chart__arcs__opacity')
    d3.selectAll('.chord-graph__chart__arcs__midRange').attr('class', 'chord-graph__chart__arcs__opacity midRange')
    d3.selectAll('.chord-graph__chart__arcs__closeRange').attr('class', 'chord-graph__chart__arcs__opacity closeRange')
  }

  const highlightSelectedNodes = (d: any) => {
    if (d?.outgoing.length > 0) {
      d3.select(d.text).attr('class', 'chord-graph__chart__labels chord-graph__selected-node')
      d?.outgoing.forEach(([, riskFlag]: any) => {
        d3.selectAll('#rect_' + riskFlag.data.name).attr('class', 'chord-graph__chart__rectangle--highlighted')
      })
      d?.outgoing.forEach(([, riskFlag]: any) => {
        d3.selectAll('#arc_' + riskFlag.data.name).attr('class', 'chord-graph__chart__arcs--highlighted')
      })
    }
    if (d?.incoming.length > 0) {
      d3.select(d.text).attr('class', 'chord-graph__chart__title chord-graph__selected-title-node')
      d?.incoming.forEach(([, riskFlag]: any) => {
        d3.selectAll('#rect_' + riskFlag.data.name).attr('class', 'chord-graph__chart__rectangle--highlighted')
      })
      d?.incoming.forEach(([, riskFlag]: any) => {
        d3.selectAll('#arc_' + riskFlag.data.name).attr('class', 'chord-graph__chart__arcs--highlighted')
      })
    }
  }

  const unSelectNodes = () => {
    d3.selectAll('.chord-graph__selected-node').attr('class', 'chord-graph__chart__labels')
    d3.selectAll('.chord-graph__selected-title-node').attr('class', 'chord-graph__chart__title')
    d3.selectAll('.chord-graph__chart__rectangle--highlighted').attr('class', 'chord-graph__chart__title__background')
    d3.selectAll('.chord-graph__chart__arcs--highlighted').attr('class', 'chord-graph__chart__arcs__general')
    d3.selectAll('.midRange').attr('class', 'chord-graph__chart__arcs__midRange')
    d3.selectAll('.closeRange').attr('class', 'chord-graph__chart__arcs__closeRange')
    d3.selectAll('.chord-graph__chart__title--highlighted').attr('class', 'chord-graph__chart__title')
    d3.selectAll('.chord-graph__chart__labels--highlighted').attr('class', 'chord-graph__chart__labels')
  }

  const changeGraphToOriginal = () => {
    d3.selectAll('.chord-graph__chart__labels__opacity').attr('class', 'chord-graph__chart__labels')
    d3.selectAll('.chord-graph__chart__title__opacity').attr('class', 'chord-graph__chart__title')
    d3.selectAll('.chord-graph__chart__title__background__opacity').attr(
      'class',
      'chord-graph__chart__title__background',
    )
  }

  return (
    <Spin spinning={isLoading}>
      <div>
        <div className='chord-graph__content-div'>
          <div className='chord-graph__description'>
            <span>{'Hover over any chord of the chart to see threat details.'}</span>
          </div>
          <div id='chart'></div>
          <div id='info'>{narrativeInfoDropdown}</div>
        </div>
      </div>
    </Spin>
  )
})
