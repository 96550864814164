import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Dropdown, Input, Menu, Spin, Switch } from 'antd'

import { AlertUserData, IMonitorNarrative, IUsers, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { store } from 'store'

import ProfileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'
import { CheckOutlined } from '@ant-design/icons'

import './Alert.scss'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

interface Props {
  mode: monitorMode
  isLoading: boolean
  itemData: { type: 'narrative' | 'community' | 'channel'; id: string; name: string }
  data?: IMonitorNarrative
  users: IUsers[]
  shareUsers: AlertUserData[]
  loggedInUser: { userID: string | null; email: string | null; name: string }
  saveAlert: ({
    entity_type,
    entity_id,
    user_ids,
  }: {
    entity_type: string
    entity_id: string
    user_ids: string[]
  }) => Promise<string>
  editAlert: ({ action, alertId, userIds }: { action: 'add' | 'remove'; alertId?: string; userIds: string[] }) => void
  deleteAlert: (alertId: string) => Promise<void>
  setShowAddAlertPage: (state: boolean) => void
}

export const AddAlerts = observer(
  ({
    mode,
    isLoading,
    itemData,
    data,
    users,
    shareUsers,
    loggedInUser,
    saveAlert,
    editAlert,
    deleteAlert,
    setShowAddAlertPage,
  }: Props) => {
    const navigate = useNavigate()

    const [isFirstRender, setFirstRender] = useState(true)
    const [inputValue, updateInputValue] = useState('')
    const [selectedUsers, setSelectedUsers] = useState<AlertUserData[]>([])
    const [inputDropdownState, setInputDropdownState] = useState(false)
    const [isAlertsOn, setIsAlertsOn] = useState(false)

    const currentUser = users.filter((user: IUsers) => user.id === loggedInUser.userID)
    const cleanedUsers = users.filter((user: IUsers) => user.id !== loggedInUser.userID)
    const usersWithRoleIDS = shareUsers.map((user) => user.user_id)
    const { tenantId, roleId } = store.userStore

    useEffect(() => {
      if (data && isFirstRender) {
        setIsAlertsOn(!!data?.alert_id?.length)
        setFirstRender(false)
      }
    }, [data])

    const usersWithNoRoles = cleanedUsers.filter((user) => {
      const userID = user.id
      return !usersWithRoleIDS.includes(userID)
    })

    const filteredUsers = usersWithNoRoles
      .filter((user: IUsers) => {
        const username = user.username?.toLowerCase()
        const realName = `${user.given_name} 
        ${user.family_name}`?.toLowerCase()
        return username.includes(inputValue.toLowerCase()) || realName.includes(inputValue.toLowerCase())
      })
      .slice(0, 4)

    let usersFiltered = !inputValue ? [...currentUser, ...usersWithNoRoles] : filteredUsers
    usersFiltered = usersFiltered.filter((user) => {
      return user.id !== loggedInUser.userID
    })

    const onAlertsChange = () => {
      let isOn = false
      setIsAlertsOn((state) => {
        isOn = !state
        return !state
      })

      if (!isOn && data?.alert_id) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'cancel_alert',
            entityName: itemData.name,
            entityType: itemData.type,
            user_id: loggedInUser.userID,
            user_name: loggedInUser.email,
            tenantId: tenantId,
            roleId: roleId,
          },
        })
        deleteAlert(data?.alert_id)
      }
    }

    const removeSharedUser = (userId: string) =>
      editAlert({ action: 'remove', alertId: data?.alert_id, userIds: [userId] })

    const removeSelectedUser = (email: string) =>
      setSelectedUsers((users) => users.filter((user) => user.email !== email))

    const inputDropdownMenu = (
      <Menu className='alerts__users__input--dropdown'>
        {usersFiltered?.map((user: IUsers, index) => {
          const realName = `${user.given_name} 
      ${user.family_name}`

          const check =
            selectedUsers.findIndex((selectedUser) => user.username === selectedUser.email) > -1 ||
            shareUsers.findIndex((selectedUser) => user.username === selectedUser.email) > -1

          return (
            <div
              onClick={() => {
                setSelectedUsers((users: AlertUserData[]) => {
                  const userEmail = user.username
                  if (!users.some((usr) => usr.email === userEmail)) {
                    return [
                      ...users,
                      {
                        user_id: user.id,
                        email: user.email,
                        given_name: user.given_name,
                        family_name: user.family_name,
                      },
                    ]
                  } else return users.filter((usr) => usr.email !== userEmail)
                })
                setInputDropdownState(false)
              }}
              className='alerts__users__info-container'
              key={uuidv4()}>
              <div className='alerts__users__info-container__user-container'>
                <div>
                  <div
                    className={classNames('alerts__users__details--name', {
                      'alerts__users__info-container__details--name__selected': check,
                    })}>
                    {realName}
                  </div>
                  <div
                    className={classNames('alerts__users__details--email', {
                      'alerts__users__info-container__details--email__selected': check,
                    })}>
                    {user.username}
                  </div>
                </div>
                {check && <CheckOutlined />}
              </div>
            </div>
          )
        })}
      </Menu>
    )

    const onBackClick = () => {
      setShowAddAlertPage(false)

      if (mode === 'bookmark') {
        navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      } else {
        navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      }
    }

    const onSaveClick = async () => {
      const usersFiltered = users
        .filter((user) => selectedUsers.some((usr) => usr.email === user.username))
        .map((user) => user.id)

      if (loggedInUser?.userID) usersFiltered.push(loggedInUser.userID)
      else return

      saveAlert({ entity_type: 'NARRATIVE', entity_id: data?.id || '', user_ids: usersFiltered })
      TagManager.dataLayer({
        dataLayer: {
          event: 'turn_on_alert',
          entityName: itemData.name,
          entityType: itemData.type,
          sharedUsers: usersFiltered,
          user_id: loggedInUser.userID,
          user_name: loggedInUser.email,
          tenantId: tenantId,
          roleId: roleId,
        },
      })
      setShowAddAlertPage(false)

      if (mode === 'bookmark') {
        navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      } else {
        navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      }
    }

    const onUpdateClick = async () => {
      const usersFiltered = users
        .filter((user) => selectedUsers.some((usr) => usr.email === user.username))
        .map((user) => user.id)

      if (!usersFiltered?.length) return
      editAlert({ action: 'add', alertId: data?.alert_id || '', userIds: usersFiltered })
      setShowAddAlertPage(false)

      if (mode === 'bookmark') {
        navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      } else {
        navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      }
    }

    return (
      <div className='alerts'>
        <div className='alerts__header'>
          <div className='alerts__header__back-btn' onClick={onBackClick}>
            <span>
              <BackBtnIcon />
            </span>
            <span className='alerts__header__back-btn--title'>
              Back to {`${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`}
            </span>
          </div>
          <h3 className='alerts__header__heading'>{`Turn on alerts for "${itemData?.name}"`}</h3>
        </div>
        <div className='alerts__switch__container'>
          <span className='alerts__switch__heading'>Alerts</span>
          <div className='alerts__switch__body'>
            <span>Turn on alerts</span>
            <Switch size='small' checked={isAlertsOn} onChange={onAlertsChange} />
          </div>
        </div>

        {isAlertsOn && (
          <>
            <div className='alerts__body'>
              <p className='alerts__body__heading'>Alerts Frequency</p>
              <span className='alerts__body__schedule-details'>
                This alert will run daily and send an email with any matching content.
              </span>
            </div>
            <div className='alerts__body--margin'>
              <span className='alerts__body__heading'>Who will receive alerts</span>
            </div>
            <Spin spinning={isLoading}>
              <Dropdown
                trigger={['click']}
                overlay={inputDropdownMenu}
                open={inputDropdownState}
                onOpenChange={() => {
                  setInputDropdownState((state) => !state)
                }}>
                <Input
                  className='alerts__users__search'
                  placeholder='Search by name or email'
                  onChange={(el) => updateInputValue(el.target.value)}
                  value={inputValue}
                />
              </Dropdown>
              <div className='alerts__users__list'>
                {
                  <div className='alerts__users__info-container' key={uuidv4()}>
                    <div className='alerts__users__info-container___details--img'>
                      <img src={ProfileImage} alt='' />
                    </div>
                    <div>
                      <div className='alerts__users__info-container__details--name'>{loggedInUser.name}</div>
                      <div className='alerts__users__info-container__details--email'>{loggedInUser.email}</div>
                    </div>
                    <div className='alerts__users__info-container__details--role--owner'>Owner</div>
                  </div>
                }
                {shareUsers &&
                  shareUsers?.map((user) => {
                    return (
                      <div className='alerts__users__info-container' key={uuidv4()}>
                        <div className='alerts__users__info-container___details--img'>
                          <img src={ProfileImage} alt='' />
                        </div>
                        <div>
                          <div className='alerts__users__info-container__details--name'>
                            {user.given_name + ' ' + user.family_name}
                          </div>
                          <div className='alerts__users__info-container__details--email'>{user.email}</div>
                        </div>
                        <div className='alerts__users__info-container__details--role' style={{ marginRight: '20px' }}>
                          <button className='alerts__users__remove-btn' onClick={() => removeSharedUser(user.user_id)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    )
                  })}
                {selectedUsers &&
                  selectedUsers?.map((user) => {
                    return (
                      <div className='alerts__users__info-container' key={uuidv4()}>
                        <div className='alerts__users__info-container___details--img'>
                          <img src={ProfileImage} alt='' />
                        </div>
                        <div>
                          <div className='alerts__users__info-container__details--name'>
                            {user.given_name + ' ' + user.family_name}
                          </div>
                          <div className='alerts__users__info-container__details--email'>{user.email}</div>
                        </div>
                        <div className='alerts__users__info-container__details--role' style={{ marginRight: '20px' }}>
                          <button className='alerts__users__remove-btn' onClick={() => removeSelectedUser(user.email)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </Spin>
            {!data?.alert_id && (
              <button className={classNames('alerts__save-btn', 'alerts__save-btn--primary')} onClick={onSaveClick}>
                Save Alert
              </button>
            )}

            {data?.alert_id && selectedUsers.length > 0 && (
              <button className={classNames('alerts__save-btn', 'alerts__save-btn--primary')} onClick={onUpdateClick}>
                Edit Alert
              </button>
            )}
          </>
        )}
      </div>
    )
  },
)
