import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as NarrativeIcon } from 'assets/images/icons/monitor/create_narrative_icon.svg'
import { ReactComponent as WatchlistIcon } from 'assets/images/icons/monitor/create_watchlist_icon.svg'
import { ReactComponent as BackIcon } from 'assets/images/icons/monitor/back_arrow_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import './CreateNarrativeAndWatchList.scss'
import { Link } from 'react-router-dom'
import { UtilService } from 'services/Util/Util'

const CancelBreadCrumb = () => {
  return (
    <div className='cbc_container'>
      <Link className='cbc_c_link' to='/vectors' replace={true}>
        <CloseIcon className='cbc_c_l_close_icon' />
        Cancel
      </Link>
      <p className='cbc_c_create_text'>Create new</p>
    </div>
  )
}

const CreateNarrativeAndWatchList = observer(() => {
  const { breadcrumbStore } = store
  const { setBreadcrumbData } = breadcrumbStore
  const navigate = useNavigate()

  useEffect(() => {
    setBreadcrumbData([{ id: UtilService.generateUuid(), title: <CancelBreadCrumb /> }])
  }, [])

  return (
    <div className='cnaw_container'>
      <div className='cnaw_c_content_container'>
        <div className='cnaw_c_content'>
          <div className='cnaw_c_c_block'>
            <NarrativeIcon className='cnaw_c_c_b_icon' />
            <p className='cnaw_c_c_b_heading'>Narrative</p>
            <p className='cnaw_c_c_b_desc'>
              Create a cross-platform collection of content that focuses on a specific topic of interest
            </p>
            <ButtonImage
              className='cnaw_c_c_b_button'
              onClick={() => {
                navigate(`narrative_builder`)
              }}>
              Create Narrative
            </ButtonImage>
          </div>
          <div className='cnaw_c_c_block'>
            <WatchlistIcon className='cnaw_c_c_b_icon' />
            <p className='cnaw_c_c_b_heading'>Watchlist</p>
            <p className='cnaw_c_c_b_desc'>
              Monitor channels and creators of interest on any platform to ensure nothing is missed{' '}
            </p>
            <ButtonImage
              className='cnaw_c_c_b_button'
              onClick={() => {
                navigate(`watchlist_builder`)
              }}>
              Create Watchlist
            </ButtonImage>
          </div>
        </div>
      </div>
      <ButtonImage
        icon={BackIcon}
        className='cnaw_c_button'
        onClick={() => {
          navigate('../', { replace: true })
        }}>
        Go back
      </ButtonImage>
    </div>
  )
})

export default CreateNarrativeAndWatchList
