import { SetStateAction, useCallback, useMemo, useState } from 'react'
import { store } from 'store'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import 'chart.js/auto'
import TagManager from 'react-gtm-module'
import { useEffectOnce } from 'react-use'
import { PowerInsightsSummary } from './Summary/PowerInsightsSummary'
import { MonitorHeatMap } from './MonitorHeatMap/MonitorHeatMap'
import { MonitorGraph } from './MonitorGraph/MonitorGraph'
import { MonitorBeehiveChart } from './MonitorBeehiveChart/MonitorBeehiveChart'
import { ExecutiveLanguageRisks } from './ExecutiveLanguageRisks/ExecutiveLanguageRisks'
import { MonitorMapChart } from './Map/MonitorMapChart'
import { MonitorBarChart } from './MonitorBarChart/MonitorBarChart'
import Topics from './Topics/Topics'
import { ReactComponent as RightArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'
import styles from './PowerInsightsVersion2.module.scss'
import { MonitorAssets } from './MonitorAssets/MonitorAssets'
import { MonitorFlags } from './MonitorFlags/MonitorFlags'
import { Page, SubStore } from 'types/types'
import { GenericDispatch } from 'version2/utils/commonTypes'
import { DetailsObjType } from 'store/monitorVersion2/types'
import { PowerInsightsNoDataMessage } from './NoDataMessages/NoDataMessages'
import { Creators } from './Creators/Creators'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface Props {
  detailObj: DetailsObjType
  page: Page
  subStore: SubStore
  activePowerInsightTab: string
  isWatchListCreateMode?: boolean
  setActivePowerInsightTab: GenericDispatch<string>
}

export const PowerInsightsVersion2 = observer(
  ({
    detailObj,
    page,
    subStore,
    setActivePowerInsightTab,
    activePowerInsightTab,
    isWatchListCreateMode = false,
  }: Props) => {
    const { loaderStore, userStore, tenantsStore } = store
    const { activeItem, snippetsTotal, powerInsightsData, setPowerInsightsData, fetchTopicsRequestId } =
      store[`${subStore}Store`]
    const {
      isLoadinFeed,
      statsPeopleLoading,
      isLoadingWatchlist,
      statsCompanyLoading,
      statsTreeDataLoading,
      statsLanguageLoading,
      statsBeeSwarmLoading,
      statsLineChartLoading,
      statsPoliticianLoading,
    } = loaderStore
    const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
    const { currentRole, userId, userName, tenantId, authToken } = userStore
    const [listRef, setListRef] = useState<HTMLElement | null>(null)

    const POWER_INSIGHT_TABS = useMemo(() => {
      return [
        {
          key: 'summary',
          label: 'Summary',
          show: true,
        },
        {
          key: 'flags',
          label: changeLabelTextBasedOnTheme('Flags', isCorporateCommunicationsTheme),
          show: true,
        },
        {
          key: 'assets',
          label: changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme),
          show: true,
        },
        {
          key: 'trends-timeline',
          label: 'Trends',
          show: true,
        },
        {
          key: 'creators',
          label: 'Creators',
          show: currentRole === 'PENDULUM_ADMIN',
        },
        {
          key: 'watchlist',
          label: changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme),
          show: detailObj?.mode !== 'watchlist',
        },
        {
          key: 'communities',
          label: changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme),
          show: detailObj?.mode !== 'community',
        },
        {
          key: 'creator-hive',
          label: changeLabelTextBasedOnTheme('Creator Hive', isCorporateCommunicationsTheme),
          show: detailObj?.mode === 'narrative' || detailObj?.mode === 'asset',
        },
        {
          key: 'map',
          label: changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme),
          show: true,
        },
        {
          key: 'language-risks',
          label: changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme),
          show: true,
        },
        {
          key: 'companies',
          label: 'Companies',
          show: true,
        },
        {
          key: 'people',
          label: 'People',
          show: true,
        },
        {
          key: 'politician',
          label: 'Politicians',
          show: true,
        },
        {
          key: 'languages',
          label: 'Languages',
          show: true,
        },
        // {
        //   key: 'topics',
        //   label: 'Topics',
        //   show: true,
        // },
      ]
    }, [detailObj?.mode, page, currentRole, isCorporateCommunicationsTheme])

    useEffectOnce(() => {
      setActivePowerInsightTab('summary')
    })
    const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
      setListRef(node)
    }, [])

    const scrollHandler = (scrollOffset: number) => {
      if (listRef) {
        listRef.scrollLeft += scrollOffset
      }
    }

    const setBarChartData = () => {
      let companyData = {}
      let peopleData = {}
      let flag = false

      if (
        powerInsightsData.companyData &&
        powerInsightsData.companyData?.data.length < powerInsightsData.companyData?.completeData.length
      ) {
        companyData = {
          companyData: {
            ...powerInsightsData.companyData,
            data: powerInsightsData.companyData?.completeData.slice(0, 8),
          },
        }
        flag = true
      }
      if (
        powerInsightsData.peopleData &&
        powerInsightsData.peopleData?.data.length < powerInsightsData.peopleData?.completeData.length
      ) {
        peopleData = {
          peopleData: {
            ...powerInsightsData.peopleData,
            data: powerInsightsData.peopleData?.completeData.slice(0, 8),
          },
        }
        flag = true
      }
      if (flag) setPowerInsightsData({ ...powerInsightsData, ...companyData, ...peopleData })
    }

    const isCreateEditWatchList = detailObj?.mode === 'watchlist' && isWatchListCreateMode && !snippetsTotal

    return (
      <div className={styles.powerInsightsContainer}>
        <nav className={styles.tabsMenuWrapper}>
          {listRef && listRef.scrollWidth > 0 && (
            <RightArrowIcon
              className={styles.leftArrowIcon}
              onClick={() => {
                if (listRef) {
                  listRef.scrollLeft -= 50
                }
              }}
            />
          )}
          <div className={styles.tabsMenu}>
            <ul ref={listRefCallback} className={styles.menuList}>
              {POWER_INSIGHT_TABS.filter((tab) => tab.show).map((tab, index) => (
                <li
                  key={`${tab.key}-${index}`}
                  className={classNames(styles.listItem, {
                    [styles.listItemActive]: activePowerInsightTab === tab.key,
                  })}
                  onClick={() => {
                    setBarChartData()
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'navigate_power_insights',
                        entityName: activeItem?.name,
                        entityType: detailObj?.mode,
                        navigation: tab.key,
                        user_id: userId,
                        user_name: userName,
                        tenantId: tenantId,
                        roleId: currentRole,
                      },
                    })
                    return setActivePowerInsightTab(tab.key)
                  }}>
                  <span>{tab.label}</span>
                </li>
              ))}
            </ul>
          </div>

          {listRef && listRef.scrollWidth > listRef.clientWidth && (
            <RightArrowIcon
              className={styles.rightArrowIcon}
              onClick={() => {
                scrollHandler(50)
              }}
            />
          )}
        </nav>

        {isCreateEditWatchList && <PowerInsightsNoDataMessage />}
        {!isCreateEditWatchList && (
          <>
            {activePowerInsightTab === 'summary' && <PowerInsightsSummary subStore={subStore} />}
            {activePowerInsightTab === 'trends-timeline' && (
              <MonitorGraph subStore={subStore} dataLoading={statsLineChartLoading || isLoadinFeed} />
            )}
            {activePowerInsightTab === 'communities' && (
              <MonitorHeatMap subStore={subStore} selectMode dataLoading={statsTreeDataLoading || isLoadinFeed} />
            )}
            {activePowerInsightTab === 'creator-hive' && (
              <MonitorBeehiveChart subStore={subStore} dataLoading={statsBeeSwarmLoading || isLoadinFeed} />
            )}
            {activePowerInsightTab === 'language-risks' && (
              <ExecutiveLanguageRisks
                subStore={subStore}
                isPowerInsightsMode
                dataLoading={statsLineChartLoading || isLoadinFeed}
              />
            )}
            {activePowerInsightTab === 'people' && (
              <MonitorBarChart
                tab={'people'}
                subStore={subStore}
                indexName='peopleData'
                dataLoading={statsPeopleLoading || isLoadinFeed}
              />
            )}
            {activePowerInsightTab === 'companies' && (
              <MonitorBarChart
                tab={'company'}
                subStore={subStore}
                indexName='companyData'
                dataLoading={statsCompanyLoading || isLoadinFeed}
              />
            )}
            {activePowerInsightTab === 'map' && <MonitorMapChart subStore={subStore} />}
            {activePowerInsightTab === 'languages' && (
              <MonitorBarChart
                tab={'language'}
                subStore={subStore}
                indexName='languagesData'
                dataLoading={statsLanguageLoading || isLoadinFeed}
              />
            )}
            {activePowerInsightTab === 'watchlist' && (
              <MonitorBarChart
                tab={'watchlist'}
                subStore={subStore}
                indexName='watchlistData'
                dataLoading={isLoadingWatchlist || isLoadinFeed}
              />
            )}
            {activePowerInsightTab === 'topics' && (
              <Topics authToken={authToken} fetchTopicsRequestId={fetchTopicsRequestId} />
            )}
            {activePowerInsightTab === 'flags' && <MonitorFlags subStore={subStore} />}
            {activePowerInsightTab === 'assets' && <MonitorAssets subStore={subStore} />}
            {activePowerInsightTab === 'politician' && (
              <MonitorBarChart
                tab={'politician'}
                subStore={subStore}
                indexName='politicianData'
                dataLoading={statsPoliticianLoading || isLoadinFeed}
              />
            )}
            {activePowerInsightTab === 'creators' && <Creators subStore={subStore} />}
          </>
        )}
      </div>
    )
  },
)
