import { observer } from 'mobx-react-lite'
import { Dropdown, Input, Select, Table, Tooltip } from 'antd'
import { useState } from 'react'
import { store } from 'store'
import { useEffectOnce, useUnmount } from 'react-use'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'

import { ReactComponent as CardViewIcon } from 'assets/images/card-view-icon.svg'
import { ReactComponent as ListViewIcon } from 'assets/images/list-view-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as DotsMenu } from 'assets/images/dots-vertical-small.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/executive-intelligence/icons/add-person/dropdown-arrow.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icon-trash.svg'
import ResetPassword from 'assets/images/reset-password-icon.png'
import { ReactComponent as SuccessIcon } from 'assets/images/tick-colored-icon.svg'

import { CardView } from 'version2/components/common/CardView/CardView'
import { Card } from 'version2/components/common/CardView/Card/Card'
import { IUsers } from 'models/models'
import { ROLES } from 'settings/roles'
import { AddUserFormModal } from '../Modals/AddUserForm/AddUserForm'
import { DeleteModal } from '../Modals/DeleteModal/DeleteModal'
import { DefaultPagination, TablePagination } from 'version2/components/common/Pagination/Pagination'
import { UtilService } from 'services/Util/Util'

import '../TenantDetails.scss'
import './TenantUsers.scss'

export const TenantUsers = observer(() => {
  const { id: tenantId } = useParams()
  const columns = [
    {
      title: 'username',
      dataIndex: 'username',
      key: 'username',
      width: '30%',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      width: '25%',
    },
    {
      title: 'Role',
      key: 'role',
      width: '25%',
      render: (col: any) => {
        return getRolesDropdown(col)
      },
    },

    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      render: (col: any) => {
        return (
          <div className='tenant-users__actions'>
            <Tooltip
              overlayClassName='tenant-details__content__header__right__view-div__tooltip'
              title={'Reset password'}
              placement='bottom'>
              <div className='tenant-users__actions-div  tenant-users__margin'>
                <img
                  alt=''
                  src={ResetPassword}
                  className='tenant-users__actions__icon tenant-users__icon'
                  onClick={(e) => {
                    e.stopPropagation()
                    resetPassword(col.email)
                  }}
                />
              </div>
            </Tooltip>

            <Tooltip
              overlayClassName='tenant-details__content__header__right__view-div__tooltip'
              title={'Delete user'}
              placement='bottom'>
              <div className='tenant-users__actions-div'>
                <DeleteIcon
                  height={18}
                  className='tenant-users__actions__icon tenant-users__icon'
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsDeleteModalOpen(col.email)
                  }}
                />
              </div>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const [currentView, setCurrentView] = useState<'card' | 'list'>('card')
  const [clickedCard, setClickedCard] = useState<string>('')
  const [isAddFormModalOpen, setIsAddFormModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<string>('')

  const { tenantsStore, usersStore, navViewStore } = store
  const { updateUserRole, deleteUser, resetUserPassword } = usersStore
  const { tenantUsers, userSearchParam, setTenantUsers, setUserSearchParam, fetchTenantUsers, resetStore } =
    tenantsStore
  const { isNavOpen } = navViewStore

  useEffectOnce(() => {
    if (!tenantUsers.data.length && tenantId?.length) {
      fetchTenantUsers({ id: tenantId, page: 1, pageSize: tenantUsers.pageSize })
    }
  })

  useUnmount(() => {
    resetStore()
  })

  const getCardList = () => {
    return tenantUsers.data.map((data) => (
      <Card
        variant={'0'}
        children={getCardBody(data)}
        header={{
          title: `${data.given_name} ${data.family_name}`,
          menu: (
            <Dropdown
              overlayClassName='tenant-users__body__card__menu'
              menu={{ items: getCardMenu(data) }}
              trigger={['click']}
              placement='bottomRight'
              onOpenChange={(open) => {
                if (open) setClickedCard(data.id)
                else setClickedCard('')
              }}>
              <Tooltip
                overlayClassName='tenant-details__content__header__right__view-div__tooltip'
                title={'Options'}
                placement='bottom'>
                <DotsMenu
                  className={classNames('tenant-users__body__card__icon', {
                    'tenant-users__body__card__icon--visible': clickedCard === data.id,
                  })}
                />
              </Tooltip>
            </Dropdown>
          ),
        }}
      />
    ))
  }

  const getCardBody = (data: IUsers) => {
    return (
      <div className='tenant-users__body__info'>
        <div className='tenant-users__body__info__email'>
          <span className='tenant-users__body__info__email--label'>Email:</span>
          <span className='tenant-users__body__info__email--text'>{data.email}</span>
        </div>
        <div className='tenant-users__body__info__role'>
          <span className='tenant-users__body__info__email--label'>Role:</span>
          {getRolesDropdown(data)}
        </div>
      </div>
    )
  }

  const getCardMenu = (data: IUsers) => {
    return [
      {
        key: 'password',
        label: 'Reset Password',
        onClick: () => {
          resetPassword(data.email)
        },
      },

      {
        key: 'delete',
        label: 'Delete user',
        onClick: () => {
          setIsDeleteModalOpen(data.email)
        },
      },
    ]
  }

  const getRolesDropdown = (data: IUsers) => {
    return (
      <Select
        showSearch={false}
        value={data.role}
        suffixIcon={<DropdownIcon className='tenant-users__body__info__role__icon' />}
        popupClassName='tenant-users__body__info__role__dropdown'
        onChange={async (newId: string) => {
          const response = await updateUserRole(data, newId)
          if (response) {
            const allUsers = [...tenantUsers.data]
            const updatedUserIndex = allUsers.findIndex((item) => item.username === data.username)
            if (updatedUserIndex > -1) {
              allUsers[updatedUserIndex] = {
                ...data,
                role: newId,
              }
              setTenantUsers({
                data: allUsers,
                total: tenantUsers.total,
                pageSize: tenantUsers.pageSize,
                currentPageNumber: tenantUsers.currentPageNumber,
              })
            }
          }
        }}>
        {ROLES?.map((role) => {
          return (
            <Select.Option key={role.id} value={role.id}>
              <span className='tenant-users__body__info__role__option'>
                {role.role.replaceAll('_', ' ').toLowerCase()}
              </span>
            </Select.Option>
          )
        })}
      </Select>
    )
  }

  const resetPassword = async (username: string) => {
    const response = await resetUserPassword(username)

    if (response) {
      UtilService.openNotification({
        type: 'info',
        placement: 'bottomLeft',
        description: <span className='tenant-modal__notification__description'>{'Changes saved successfully'}</span>,
        className: classNames('tenant-modal__notification', { 'tenant-modal__notification__margin': isNavOpen }),
        icon: <SuccessIcon />,
      })
    }
  }

  const onDelete = (id: string) => {
    deleteUser(id).then(() => {
      fetchTenantUsers({ id: tenantId || '', pageSize: tenantUsers.pageSize, page: 1 })
    })
  }

  return (
    <div>
      <div className='tenant-users'>
        <div className='tenant-details__content__header'>
          <span className='tenant-details__content__header__title'>Manage tenant users and modify their details.</span>
          <div className='tenant-details__content__header__right'>
            <div className='tenant-details__content__header__right__view-div'>
              <Tooltip
                overlayClassName='tenant-details__content__header__right__view-div__tooltip'
                title={'Card View'}
                placement='bottom'>
                <CardViewIcon
                  className={classNames('tenant-details__content__header__right__view-div__card-icon', {
                    'tenant-details__content__header__right__view-div__card-icon--active': currentView === 'card',
                  })}
                  onClick={() => setCurrentView('card')}
                />
              </Tooltip>

              <Tooltip
                overlayClassName='tenant-details__content__header__right__view-div__tooltip'
                title={'List View'}
                placement='bottom'>
                <ListViewIcon
                  className={classNames('tenant-details__content__header__right__view-div__list-icon', {
                    'tenant-details__content__header__right__view-div__list-icon--active': currentView === 'list',
                  })}
                  onClick={() => setCurrentView('list')}
                />
              </Tooltip>
            </div>
            <Input
              placeholder='Search with username, email or position'
              prefix={<SearchIcon />}
              onPressEnter={async (e) => {
                if (tenantId?.length) {
                  let obj: { pageSize: number; page: number; searchParam?: string; id: string } = {
                    page: 1,
                    pageSize: tenantUsers.pageSize,
                    id: tenantId,
                  }
                  fetchTenantUsers(obj)
                }
              }}
              onChange={(e) => {
                setUserSearchParam(e.target.value)
              }}
              value={userSearchParam || ''}
            />
            <button
              className='tenant-details__content__header__right__add-btn'
              onClick={() => setIsAddFormModalOpen(true)}>
              + Add new user
            </button>
          </div>
        </div>
        {currentView === 'card' ? (
          <div className='tenant-users__body__card'>
            <CardView children={getCardList()} />
            <DefaultPagination
              totalCount={tenantUsers.total}
              currentPage={tenantUsers.currentPageNumber}
              pageSize={tenantUsers.pageSize}
              showPageSizeChanger={true}
              pageSizeOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              onChange={(page, pageSize) => {
                if (tenantId?.length) {
                  let obj: { pageSize: number; page: number; searchParam?: string; id: string } = {
                    page,
                    pageSize,
                    id: tenantId,
                  }
                  fetchTenantUsers(obj)
                }
              }}
              locale={{ items_per_page: '' }}
            />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={tenantUsers.data}
            pagination={TablePagination({
              totalCount: tenantUsers.total,
              currentPage: tenantUsers.currentPageNumber,
              pageSize: tenantUsers.pageSize,
              position: ['bottomCenter'],
              showPageSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              onChange(page, pageSize) {
                if (tenantId?.length) {
                  let obj: { pageSize: number; page: number; searchParam?: string; id: string } = {
                    page,
                    pageSize,
                    id: tenantId,
                  }
                  if (userSearchParam && userSearchParam.length > 0) obj['searchParam'] = userSearchParam
                  fetchTenantUsers(obj)
                }
              },
              locale: { items_per_page: '' },
            })}
          />
        )}
      </div>
      <AddUserFormModal modalOpen={isAddFormModalOpen} setModalOpen={setIsAddFormModalOpen} />
      <DeleteModal
        content={{
          title: 'Are you sure to delete user?',
          description: 'Once a user is deleted, you will not able to restore the user.',
        }}
        isModalVisible={isDeleteModalOpen}
        setIsModalVisible={setIsDeleteModalOpen}
        onDelete={onDelete}
      />
    </div>
  )
})
