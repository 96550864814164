import { useEffectOnce, useUnmount } from 'react-use'
import { useRef, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { List } from 'antd'
import VirtualList from 'rc-virtual-list'
import millify from 'millify'

import { DataFetching } from 'components/common/DataFetching/DataFetching'
import { MonitorSnippetItem } from './MonitorSnippetItem/SnippetItem'

import { ReactComponent as BackToTopButton } from 'assets/images/backToTopScroll.svg'
import { Mode, SubStore } from 'types/types'

import './SnippetListVersion2.scss'

interface Props {
  mode: Mode
  subStore: SubStore
  showSearchChip: boolean
  searchedDataResults: number
}

export const SnippetListVersion2 = observer(({ mode, subStore, showSearchChip, searchedDataResults }: Props) => {
  const ContainerHeight = 660
  const ItemHeight = 170
  const BackToTopSnippetLimit = 30
  const listRef = useRef<any>(null)

  const [showButton, setShowButton] = useState(false)

  const { loaderStore } = store
  const {
    snippetsFilter,
    watchlists,
    communities,
    snippetsWithSurprises,
    snippets,
    getWatchlists,
    showSurprise,
    fetchSnippets,
    setIsFeedEmpty,
  } = store[`${subStore}Store`]
  const { isLoadinFeed } = loaderStore

  useEffectOnce(() => {
    getWatchlists()
  })

  useUnmount(() => {
    setIsFeedEmpty(true)
  })

  const checkFeed = () => {
    if (!isLoadinFeed && !snippets.length) {
      return <DataFetching title={'title'} page={'notFound'} daysFilters={snippetsFilter.days} />
    } else {
      return (
        <VirtualList
          ref={listRef}
          className='c-snippets-list__list'
          data={snippetsWithSurprises}
          height={isLoadinFeed ? 580 : ContainerHeight}
          itemHeight={ItemHeight}
          itemKey='id'
          onScroll={(e) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) fetchSnippets()

            if (ItemHeight * BackToTopSnippetLimit < e.currentTarget.scrollTop) setShowButton(true)
            else setShowButton(false)
          }}>
          {(item) => {
            return (
              <div key={item.id}>
                <MonitorSnippetItem
                  mode={mode}
                  subStore={subStore}
                  snippet={item}
                  allWatchlist={watchlists}
                  allCommunities={communities}
                  showSurprise={showSurprise}
                />
              </div>
            )
          }}
        </VirtualList>
      )
    }
  }
  return (
    <>
      <div className='snippet-list__wrapper'>
        <div className='snippet-list__container'>
          {showSearchChip && searchedDataResults > 0 && (
            <>
              <span className='snippet-list__search-result'>{millify(searchedDataResults) + ' results'}</span>
            </>
          )}
        </div>
        <List>{checkFeed()}</List>
        {!isLoadinFeed && showButton && (
          <BackToTopButton
            className='snippet-list__backToTop'
            onClick={() => {
              listRef.current.scrollTo(0)
            }}
          />
        )}
      </div>
    </>
  )
})
