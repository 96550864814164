import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import dayjs from 'dayjs'
import millify from 'millify'
import SearchInput from 'version2/components/common/Inputs/SearchInput/SearchInput'
import DetailPanelFeeds from 'version2/components/Investigate/Reports/DetailPanelFeeds/DetailPanelFeeds'
import DetailViewEmptyData from 'version2/components/Investigate/Reports/DetailViewEmptyData/DetailViewEmptyData'
import { ReportFieldParams, ReportSectionFeedsObj } from 'store/report/valueTypes'
import { getPastDate } from 'services/Util/getPastDate'
import { FlagListParamsType } from 'store/flags/types'
import './CreateFlagModalStep4.scss'

const PER_PAGE = 50
const DATE_FILTER_FORMATE = 'YYYY-MM-DD'

type CreateFlagModalStep4Props = {
  booleanSearch: string
}

const CreateFlagModalStep4 = observer((props: CreateFlagModalStep4Props) => {
  const { reportMainViewStore } = store
  const { reportSectionFeeds, feedsPayload, fetchReportFeedsHandler } = reportMainViewStore
  const { booleanSearch } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [prevSearchValue, setPrevSearchValue] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(2)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const searchHandler = (value: string, prevValue: string) => {
    if (value || prevValue) {
      setPrevSearchValue(value)

      let qParam = `start_date:gte:${getPastDate(90)},end_date:lte:${dayjs().format(
        DATE_FILTER_FORMATE,
      )},risk_score:gte:0,risk_score:lte:100`

      let requestParams: FlagListParamsType = {
        q: qParam,
        page: 1,
        per_page: PER_PAGE,
        sort: 'upload_date:desc',
      }

      let requestPayload = JSON.parse(JSON.stringify(feedsPayload))

      requestPayload.conditions = [
        ...JSON.parse(JSON.stringify(requestPayload.conditions)),
        {
          keywords_expression: booleanSearch,
        },
      ]
      if (value) {
        requestPayload.conditions = [
          ...JSON.parse(JSON.stringify(requestPayload.conditions)),
          {
            keywords_expression: value,
          },
        ]
      }
      setCurrentPage(2)
      fetchReportFeedsHandler(requestParams, requestPayload)
    }
  }

  const fetchReportFeedsMethod = () => {
    let qParam = `start_date:gte:${getPastDate(90)},end_date:lte:${dayjs().format(
      DATE_FILTER_FORMATE,
    )},risk_score:gte:0,risk_score:lte:100`

    const params: ReportFieldParams = {
      q: qParam,
      per_page: PER_PAGE,
      page: 1,
      sort: 'upload_date:desc',
    }

    let requestPayload = JSON.parse(JSON.stringify(feedsPayload))

    requestPayload.conditions = [
      ...JSON.parse(JSON.stringify(requestPayload.conditions)),
      {
        keywords_expression: booleanSearch,
      },
    ]

    fetchReportFeedsHandler(params, requestPayload)
  }
  useEffect(() => {
    fetchReportFeedsMethod()
  }, [booleanSearch])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight

    if (sumScroll === scrollHeight || Math.ceil(sumScroll) === scrollHeight || Math.floor(sumScroll) === scrollHeight) {
      if (reportSectionFeeds.total_count !== reportSectionFeeds.items.length && !isFetching) {
        setIsFetching(true)
        let qParam = `start_date:gte:${getPastDate(90)},end_date:lte:${dayjs().format(
          DATE_FILTER_FORMATE,
        )},risk_score:gte:0,risk_score:lte:100`

        let requestParams: FlagListParamsType = {
          q: qParam,
          page: currentPage,
          per_page: PER_PAGE,
          sort: 'upload_date:desc',
        }

        let requestPayload = JSON.parse(JSON.stringify(feedsPayload))

        requestPayload.conditions = [
          ...JSON.parse(JSON.stringify(requestPayload.conditions)),
          {
            keywords_expression: booleanSearch,
          },
        ]
        if (searchValue) {
          requestPayload.conditions = [
            ...JSON.parse(JSON.stringify(requestPayload.conditions)),
            {
              keywords_expression: searchValue,
            },
          ]
        }

        fetchReportFeedsHandler(requestParams, requestPayload, true).then((res: ReportSectionFeedsObj | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...reportSectionFeeds.items, ...res.items].length !== res.total_count) {
            setCurrentPage((prevPage) => prevPage + 1)
          }
        })
      }
    }
  }

  return (
    <div className='create_flag_modal_step4_container'>
      <div className='clms4c_header'>
        <p className='clms4c_h_total_results'>
          {millify(reportSectionFeeds.total_count)} <span className='clms4c_h_tr_part'>results found</span>
        </p>
        <SearchInput
          className='clms4c_h_input_container'
          placeholder='Search'
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
          onPressEnter={() => {
            searchHandler(searchValue, prevSearchValue)
          }}
          onClear={() => {
            setSearchValue('')
            searchHandler('', prevSearchValue)
          }}
        />
      </div>
      <div className='clms4c_snippets_container' onScroll={handleScroll}>
        {reportSectionFeeds.items.length ? (
          reportSectionFeeds.items.map((feed) => (
            <DetailPanelFeeds key={feed.document_id} author={feed?.author} {...feed} data={feed} />
          ))
        ) : (
          <DetailViewEmptyData
            className='dprsc_tab_cc_feeds_empty_container'
            title='No Data available for this text chunk'
            desc='Try to select any other text chunk. For the moment there is no data available for this chunk.'
          />
        )}
      </div>
    </div>
  )
})

export default CreateFlagModalStep4
