import { useState } from 'react'
import { Divider, Dropdown, Menu, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'

import { ReactComponent as SortIcon } from 'assets/images/sort-icon.svg'
import './ListFilters.scss'
import classNames from 'classnames'

interface Props {
  elements: { [key: string]: string[] }
  setFilter: (element: any, pageSize: number) => void
  selectedFilter: { [key: string]: string }
}

export const ListFilter = observer(({ selectedFilter, setFilter, elements }: Props) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const keys = Object.keys(elements).filter((el) => !el.includes('filter_'))

  const elementList: any[] = []

  keys.forEach((group, grupIndex) => {
    elementList.push(
      <Menu.Item key={group + 'g'} className='feed-filters__title disabled-item'>
        Sort by {group}
      </Menu.Item>,
    )

    elements[group].forEach((el, index) => {
      elementList.push(
        <Menu.Item
          key={group + index}
          className='list-filter__dropdown-item'
          onClick={() => {
            if (grupIndex > 0 && group !== 'mode') return
            const modFilter = { ...selectedFilter }

            modFilter[group] = el
            setFilter(modFilter, 6)
            setMenuVisible(false)
          }}>
          <span
            className={classNames('list-filter__dropdown-item__text', {
              'list-filter__dropdown-item--selected': selectedFilter[group] === el,
            })}>
            {el}
          </span>
        </Menu.Item>,
      )
    })

    if (grupIndex < keys.length - 1)
      elementList.push(
        <Menu.Item key={group}>
          <Divider className='list-filter__divider' />
        </Menu.Item>,
      )
  })

  const sortDropdown = <Menu>{elementList}</Menu>

  return (
    <div className={`list-filter__container`}>
      <Dropdown
        className='list-filter'
        overlayClassName='list-filter__dropdown'
        trigger={['click']}
        overlay={sortDropdown}
        open={menuVisible}
        onOpenChange={(state) => {
          setMenuVisible(state)
        }}>
        <Tooltip title={'Sort'} placement='bottom'>
          <div className='list-filter__sort-btn'>
            <SortIcon stroke='#F6F6F7' aria-label='Sort' />
          </div>
        </Tooltip>
      </Dropdown>
    </div>
  )
})
