import { Form, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'

import './RequestModal.scss'

interface Props {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
  submit: (info: string) => void
  mode: 'narrative' | 'community' | 'executive'
}

export const RequestModal = ({ mode, isVisible, setIsModalVisible, submit }: Props) => {
  const [form] = Form.useForm()

  const handleOk = async () => {
    await form.validateFields()
    submit(form.getFieldValue('description'))
    form.resetFields()
    setIsModalVisible(false)
  }

  const Title = {
    narrative: 'Request a new narrative',
    community: 'Request new community',
    executive: 'Request a person',
  }

  // const Description = {
  //   narrative: 'The Pendulum team can help you create additional narratives to better fit your analytical process.',
  //   community: 'The Pendulum team can help you create additional communities to better fit your analytical process.',
  //   executive: 'The Pendulum team can help you add a new person to the report.',
  // }

  const PlaceHolder = {
    narrative: 'What are you looking for in a new narrative?',
    community: 'What are you looking for in a community',
    executive: 'Who are you looking for?',
  }

  return (
    <Modal
      className='c-request-modal'
      title={<div className='c-request-modal__title'>{Title[mode]}</div>}
      visible={isVisible}
      footer={
        <div className='c-request-modal__footer'>
          <button
            className='c-request-modal__footer__cancel'
            onClick={() => {
              form.resetFields()
              setIsModalVisible(false)
            }}>
            Cancel
          </button>
          <button className='c-request-modal__footer__send' onClick={handleOk}>
            Submit
          </button>
        </div>
      }
      onCancel={() => setIsModalVisible(false)}>
      <Form form={form}>
        <div>
          <div className='c-request-modal__container'>
            <span className='c-request-modal__subtitle'>{'Briefly describe your request'}</span>
            <Form.Item
              name='description'
              className='c-define__item c-define__item--1'
              rules={[{ required: true, message: 'Please add a description!' }]}>
              <Input.TextArea placeholder={PlaceHolder[mode]} autoSize={{ minRows: 5, maxRows: 5 }} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

interface Props2 {
  isVisible: boolean
  setIsModalVisible: (state: boolean) => void
}

export const PostRequestModal = ({ isVisible, setIsModalVisible }: Props2) => {
  return (
    <Modal
      width={800}
      bodyStyle={{ overflow: 'auto' }}
      title={'Your request has been received!'}
      wrapClassName='c-post-request-modal'
      visible={isVisible}
      closable
      centered
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={() => setIsModalVisible(false)}>
      <p>Our Customer lead will contact you within 48 hours.</p>
    </Modal>
  )
}
