import { observer } from 'mobx-react-lite'
import { InputNumber, Popover, Slider } from 'antd'
import { useState } from 'react'

import { ReactComponent as FilterIcon } from 'assets/images/filter-icon.svg'
import { ReactComponent as AscendingIcon } from 'assets/images/ascending-zigzag-icon.svg'
import { ReactComponent as DescendingIcon } from 'assets/images/descending-zigzag-icon.svg'

import './FilterAndSorterDropdown.scss'

type Filters = {
  followers: [number, number]
  snippets: [number, number]
  impressions: [number, number]
}
// type SortOrder = 'asc' | 'desc'

export const FilterAndSorterDropdown = observer(() => {
  const options = ['followers', 'snippets', 'impressions']
  const [filters, setFilters] = useState<Filters>({
    followers: [0, 0],
    snippets: [0, 0],
    impressions: [0, 0],
  })
  // const [sort, setSort] = useState<SortOrder>('asc')

  const onChangeSlider = (value: number | number[], key: string) => {
    setFilters((prev) => {
      return { ...prev, [key]: value }
    })
  }

  const onChangeInput = (value: number | string, key: string, type: 'start' | 'end') => {
    if (Number.isInteger(value)) {
      let obj = filters[key as keyof Filters]
      if (type === 'start') obj = [Number(value || 0), obj[1]]
      else obj = [obj[0], Number(value || 0)]

      setFilters((prev) => {
        return { ...prev, [key]: obj }
      })
    }
  }

  const content = (
    <div className='filter-sorter-dropdown__content'>
      {options.map((option) => (
        <div className='filter-sorter-dropdown__content__optionContainer'>
          <span className='filter-sorter-dropdown__content__title'>{option}</span>
          <div className='filter-sorter-dropdown__content__sliderContainer'>
            <InputNumber
              className='filter-sorter-dropdown__content__input'
              min={1}
              max={20}
              value={filters[option as keyof Filters][0]}
              onChange={(value) => onChangeInput(value || 0, option, 'start')}
              controls={false}
            />
            <Slider
              className='filter-sorter-dropdown__content__slider'
              onChange={(value) => onChangeSlider(value, option)}
              value={filters[option as keyof Filters]}
              range
            />
            <InputNumber
              className='filter-sorter-dropdown__content__input'
              min={1}
              max={999999}
              value={filters[option as keyof Filters][1]}
              onChange={(value) => onChangeInput(value || 0, option, 'end')}
              controls={false}
            />
          </div>
        </div>
      ))}
      <div className='filter-sorter-dropdown__content__optionContainer'>
        <span className='filter-sorter-dropdown__content__title'>{'sorting'}</span>
        <div className='filter-sorter-dropdown__sorting'>
          <button className='filter-sorter-dropdown__sorting__buttons'>
            <AscendingIcon />
            Ascending
          </button>
          <button className='filter-sorter-dropdown__sorting__buttons'>
            <DescendingIcon />
            Descending
          </button>
        </div>
      </div>
    </div>
  )
  return (
    <Popover content={content} trigger='click' overlayClassName='filter-sorter-dropdown'>
      <div className='filter-sorter-dropdown__container'>
        <FilterIcon className='filter-sorter-dropdown__icon' />
        <span className='filter-sorter-dropdown__title'>Filter & Sort</span>
      </div>
    </Popover>
  )
})
