import { SetStateAction, useCallback, useMemo, useState } from 'react'
import { Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import 'chart.js/auto'
import { store } from 'store'

import { PowerInsightsHeader } from './PowerInsightsHeader/PowerInsightsHeader'
import { SnippetFilter } from '../../Filters/Snippet/SnippetFilters'
import { PowerInsightAttentionFlags } from './PowerInsightAttentionFlags/PowerInsightAttentionFlags'
import { PowerInsightsSummary } from 'version2/components/Monitor/PowerInsights/Summary/PowerInsightsSummary'
import { MonitorHeatMap } from 'version2/components/Monitor/PowerInsights/MonitorHeatMap/MonitorHeatMap'
import { MonitorGraph } from 'version2/components/Monitor/PowerInsights/MonitorGraph/MonitorGraph'
import { MonitorBeehiveChart } from 'version2/components/Monitor/PowerInsights/MonitorBeehiveChart/MonitorBeehiveChart'
import { ExecutiveLanguageRisks } from 'version2/components/Monitor/ExecutiveIntelligence/ExecutiveDetails/ExecutiveLanguageRisks/ExecutiveLanguageRisks'
import { MonitorMapChart } from 'version2/components/Monitor/PowerInsights/Map/MonitorMapChart'
import { MonitorBarChart } from 'version2/components/Monitor/PowerInsights/MonitorBarChart/MonitorBarChart'
import { monitorMode, monitorSnippetPage } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import { ReactComponent as RightArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'

import styles from './powerInsights.module.scss'
import TagManager from 'react-gtm-module'
import { useEffectOnce } from 'react-use'
import Topics from 'version2/components/Monitor/PowerInsights/Topics/Topics'

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const PowerInsights = observer(({ mode, page }: Props) => {
  const { loaderStore, userStore } = store
  const { activeItem, powerInsightsData, setPowerInsightsData, fetchTopicsRequestId } =
    page === 'define' ? store['defineNarrativeStoreV1'] : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { isLoadingStats, isLoadinFeed } = loaderStore
  const { currentRole, userId, userName, tenantId, authToken } = userStore
  const [activePowerInsightTab, setActivePowerInsightTab] = useState('')
  const [listRef, setListRef] = useState<HTMLElement | null>(null)

  const POWER_INSIGHT_TABS = useMemo(() => {
    return [
      {
        key: 'attention-flags',
        label: 'Attention Flags',
        show: page !== 'executive-intelligence',
      },
      {
        key: 'summary',
        label: 'Summary',
        show: page !== 'executive-intelligence',
      },
      {
        key: 'trends-timeline',
        label: 'Trends',
        show: true,
      },
      {
        key: 'watchlist',
        label: 'Watchlist',
        show: mode !== 'watchlist' && page !== 'executive-intelligence',
      },
      {
        key: 'communities',
        label: 'Communities',
        show: mode !== 'community',
      },
      {
        key: 'creator-hive',
        label: 'Creator Hive',
        show: mode === 'narrative',
      },
      {
        key: 'map',
        label: 'Map',
        show: true,
      },
      {
        key: 'language-risks',
        label: 'Language Risk',
        show: true,
      },
      {
        key: 'companies',
        label: 'Companies',
        show: true,
      },
      {
        key: 'people',
        label: 'People',
        show: true,
      },
      {
        key: 'languages',
        label: 'Languages',
        show: true,
      },
      {
        key: 'topics',
        label: 'Topics',
        show: true,
      },
    ]
  }, [mode, page, currentRole])

  useEffectOnce(() => {
    if (page !== 'executive-intelligence') setActivePowerInsightTab('attention-flags')
    else setActivePowerInsightTab('trends-timeline')
  })
  const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
    setListRef(node)
  }, [])

  const scrollHandler = (scrollOffset: number) => {
    if (listRef) {
      listRef.scrollLeft += scrollOffset
    }
  }

  const setBarChartData = () => {
    let companyData = {}
    let peopleData = {}
    let flag = false

    if (
      powerInsightsData.companyData &&
      powerInsightsData.companyData?.data.length < powerInsightsData.companyData?.completeData.length
    ) {
      companyData = {
        companyData: {
          ...powerInsightsData.companyData,
          data: powerInsightsData.companyData?.completeData.slice(0, 8),
        },
      }
      flag = true
    }
    if (
      powerInsightsData.peopleData &&
      powerInsightsData.peopleData?.data.length < powerInsightsData.peopleData?.completeData.length
    ) {
      peopleData = {
        peopleData: {
          ...powerInsightsData.peopleData,
          data: powerInsightsData.peopleData?.completeData.slice(0, 8),
        },
      }
      flag = true
    }
    if (flag) setPowerInsightsData({ ...powerInsightsData, ...companyData, ...peopleData })
  }

  return (
    <div className={styles.powerInsightsContainer}>
      <Spin spinning={activeItem?.id?.length === 0 || isLoadingStats || isLoadinFeed}>
        <PowerInsightsHeader mode={mode} page={page} activePowerInsightTab={activePowerInsightTab} />

        <SnippetFilter mode={mode} page={page} />

        <nav className={styles.tabsMenuWrapper}>
          <div className={styles.tabsMenu}>
            <ul ref={listRefCallback} className={styles.menuList}>
              {POWER_INSIGHT_TABS.filter((tab) => tab.show).map((tab, index) => (
                <li
                  key={`${tab.key}-${index}`}
                  className={classNames(styles.listItem, {
                    [styles.listItemActive]: activePowerInsightTab === tab.key,
                  })}
                  onClick={() => {
                    setBarChartData()
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'navigate_power_insights',
                        entityName: activeItem?.name,
                        entityType: mode,
                        navigation: tab.key,
                        user_id: userId,
                        user_name: userName,
                        tenantId: tenantId,
                        roleId: currentRole,
                      },
                    })
                    return setActivePowerInsightTab(tab.key)
                  }}>
                  <span>{tab.label}</span>
                </li>
              ))}
            </ul>
          </div>

          {listRef && listRef.scrollWidth > listRef.clientWidth && (
            <RightArrowIcon
              className={styles.rightArrowIcon}
              onClick={() => {
                scrollHandler(50)
              }}
            />
          )}
        </nav>

        {activePowerInsightTab === 'attention-flags' && <PowerInsightAttentionFlags mode={mode} page={page} />}
        {activePowerInsightTab === 'summary' && <PowerInsightsSummary mode={mode} page={page} />}
        {activePowerInsightTab === 'trends-timeline' && <MonitorGraph mode={mode} page={page} />}
        {activePowerInsightTab === 'communities' && <MonitorHeatMap mode={mode} page={page} selectMode />}
        {activePowerInsightTab === 'creator-hive' && <MonitorBeehiveChart page={page} />}
        {activePowerInsightTab === 'language-risks' && (
          <ExecutiveLanguageRisks mode={mode} page={page} isPowerInsightsMode />
        )}
        {activePowerInsightTab === 'people' && (
          <MonitorBarChart mode={mode} tab={'people'} page={page} indexName='peopleData' />
        )}
        {activePowerInsightTab === 'companies' && (
          <MonitorBarChart mode={mode} tab={'company'} page={page} indexName='companyData' />
        )}
        {activePowerInsightTab === 'map' && <MonitorMapChart mode={mode} page={page} />}
        {activePowerInsightTab === 'languages' && (
          <MonitorBarChart mode={mode} tab={'language'} page={page} indexName='languagesData' />
        )}
        {activePowerInsightTab === 'watchlist' && (
          <MonitorBarChart mode={mode} tab={'watchlist'} page={page} indexName='watchlistData' />
        )}
        {activePowerInsightTab === 'topics' && (
          <Topics authToken={authToken} fetchTopicsRequestId={fetchTopicsRequestId} />
        )}
      </Spin>
    </div>
  )
})
