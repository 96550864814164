import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'

import { SubnavSearch } from 'version2/components/common/SubnavSearch/SubnavSearch'
import { ListFilter } from 'version2/components/common/Filters/List/ListFilter'
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg'

import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { monitorMode } from 'models/models'

import './ListHeader.scss'

type Props = {
  mode: monitorMode
}

const CTA_LINK = {
  tag: {
    to: '/monitor/tags/add',
    text: 'Apply Tags',
  },
  narrative: {
    to: '/monitor/narratives/narrative-builder',
    text: 'Create',
  },
  watchlist: {
    to: '/monitor/watchlist/watchlist-builder',
    text: 'Create',
  },
  community: {
    to: '',
    text: 'Create',
  },
}

export const ListHeader = observer(({ mode }: Props) => {
  const { manageCommunitiesStore } = store
  const { listFilter, avableFilters, setListFilter } = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { setIsRequestModalOpen } = manageCommunitiesStore

  return (
    <div className='list-header'>
      <div className='list-header__top-section__search-section'>
        <SubnavSearch page={mode === 'bookmark' ? 'investigate' : 'monitor'} mode={mode} />
        <div className='list-header__top-section__search-section__container'>
          <ListFilter selectedFilter={listFilter} setFilter={setListFilter} elements={avableFilters} />
          {mode !== 'community' && mode !== 'bookmark' && (
            <Link className='list-header__top-section__search-section__btn' to={CTA_LINK[mode].to}>
              <AddIcon />
              {CTA_LINK[mode].text}
            </Link>
          )}
          {mode === 'community' && (
            <button
              className='list-header__top-section__search-section__request-btn'
              onClick={() => {
                setIsRequestModalOpen(true)
              }}>
              Request Community
            </button>
          )}
        </div>
      </div>
    </div>
  )
})
