import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { store } from 'store'
import { Dropdown, Spin } from 'antd'
import * as d3 from 'd3'

import { ReactComponent as ElectionLogo } from 'assets/images/election-2024-icon.svg'

import { ChordChartDataset, INarrativeInfoCategory } from 'models/models'
import { NarrativeInfo } from '../../NarrativeInfo/NarrativeInfo'

import '../ChordGraph.scss'

interface Props {
  root: d3.HierarchyPointNode<unknown> | null
  dataset: ChordChartDataset[]
  setDataset: (data: any) => void
}

export const ElectionChordGraph = observer(({ root, dataset, setDataset }: Props) => {
  const { anticipatoryIntelligenceStore, loaderStore } = store
  const {
    isSideDetailsOpen,
    electionIssues,
    electionRaces,
    attentionFlags,
    electionRacesData,
    electionAttentionFlagsData,
    electionRacesAttentionFlagData,
    recentElectionIssuesRacesFetchedName,
    recentElectionIssuesAttentionFlagsFetchedName,
    recentElectionRacesAttentionFlagFetchedName,
    setIsSideDetailsOpen,
    setSideDetails,
    setRecentElectionIssuesRacesFetchedName,
    setRecentElectionIssuesAttentionFlagsFetchedName,
    setRecentElectionRacesAttentionFlagFetchedName,
    fetchIssuesRacesDetails,
    fetchIssuesAttentionFlagsDetails,
    fetchRacesAttentionFlagsDetails,
  } = anticipatoryIntelligenceStore

  const { isLoadingElectionRaces, isLoadingAttentionFlags, isLoadingNoOfPosts, isLoadingFlags } = loaderStore

  const width = isSideDetailsOpen.length > 0 ? 200 : 310
  const radius = width / 2
  const range = ['C1', 'C2', 'C3', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9']

  const rangeCodes: { [x: string]: string } = {
    presidential: 'election.range.races.R1.presidential',
    north_east_d: 'election.range.races.R2.north_east_d',
    north_east_r: 'election.range.races.R3.north_east_r',
    midwest_d: 'election.range.races.R4.midwest_d',
    midwest_r: 'election.range.races.R5.midwest_r',
    south_d: 'election.range.races.R6.south_d',
    south_r: 'election.range.races.R7.south_r',
    west_d: 'election.range.races.R8.west_d',
    west_r: 'election.range.races.R9.west_r',
    Brand: 'election.range.company.C1.Brand',
    Executive: 'election.range.company.C2.Executive',
    Industry: 'election.range.company.C3.Industry',
  }
  const arcClasses: {
    General: string
    races: string
    'races-red': string
    'races-blue': string
    'races-purple': string
    company: string
    highlighted: string
  } = {
    General: 'chord-graph__chart__arcs__general',
    races: 'chord-graph__chart__arcs__races',
    'races-red': 'chord-graph__chart__arcs__races--red',
    'races-blue': 'chord-graph__chart__arcs__races--blue',
    'races-purple': 'chord-graph__chart__arcs__races--purple',
    company: 'chord-graph__chart__arcs__company',
    highlighted: 'chord-graph__chart__arcs--highlighted',
  }

  let values: { [x: string]: number } = {}
  const linkageLine = d3
    .lineRadial()
    .curve(d3.curveBundle.beta(0.75))
    .radius((d: any) => d.y)
    .angle((d: any) => {
      let value = d.x + (d?.data?.size || 0) + (values[d.data.name] || 0)
      const random = Math.floor(Math.random() * (5 - -3)) + -3
      values[d.data.name] = ((values[d.data.name] || 0) + random) / 100
      return value
    })
  const arc = d3
    .arc()
    .innerRadius((d: any) => d.innerRadius)
    .outerRadius((d: any) => d.outerRadius)
    .startAngle((d: any) => d.startAngle)
    .endAngle((d: any) => d.endAngle)
  const arcWidth = 0.2
  const leavesArcInnerRadius = radius - (isSideDetailsOpen.length > 0 ? 44.5 : 99.5)
  const parentsArcInnerRadius = radius - (isSideDetailsOpen.length > 0 ? 43.3 : 98.3)
  const leavesArcOuterRadius = leavesArcInnerRadius + arcWidth
  const parentsArcOuterRadius = parentsArcInnerRadius + arcWidth
  const viewBox = isSideDetailsOpen
    ? [-width / 2, -width / 2.8, width, width / 1.2]
    : [-width / 2, -width / 4.2, width, width / 1.9]
  let extraSpaceForRange = 0.08
  let extraSpaceForIssues = 0.05
  let selectedLink = ''

  const [isNodeClicked, setIsNodeClicked] = useState(false)
  const [narrativeInfoDropdown, setNarrativeInfoDropdown] = useState<JSX.Element>(<></>)

  useEffect(() => {
    fetchIssuesRacesDetails()
  }, [electionRaces])

  useEffect(() => {
    fetchIssuesAttentionFlagsDetails()
  }, [attentionFlags])

  useEffect(() => {
    if (Object.keys(electionRaces).length && Object.keys(attentionFlags).length) {
      fetchRacesAttentionFlagsDetails()
    }
  }, [electionRaces, attentionFlags])

  useEffect(() => {
    if (recentElectionIssuesRacesFetchedName.length > 0) {
      const namesArray = [...recentElectionIssuesRacesFetchedName]
      const recentRace = namesArray.pop()
      setRecentElectionIssuesRacesFetchedName(namesArray, 'remove')

      if (root && root.children && root.children.length > 0) {
        if (recentRace?.length) {
          const issueName = recentRace.split(',,')[0]
          const categoryName = recentRace.split(',,')[1]

          const electionRaceIssue = electionRacesData[issueName][categoryName] || {}
          const electionIssueRaceKeys = Object.keys(electionRaceIssue)
          const newDataset = [...dataset]

          for (const key of electionIssueRaceKeys) {
            const datasetNodeIndex = newDataset.findIndex((data) => data.name.includes(categoryName))

            if (datasetNodeIndex > -1) {
              const datasetNode = newDataset[datasetNodeIndex]
              const issueRace = electionRaceIssue[key]
              datasetNode.noOfPosts = issueRace.noOfPosts

              if ((datasetNode.noOfPosts || 0) > 0) {
                const flag = rangeCodes[key as keyof { [x: string]: string }]
                if (flag && flag.length > 0 && !datasetNode.imports.includes(flag)) {
                  datasetNode.imports.push(flag)
                }
              }
            }
          }
          setDataset(newDataset)
        }
      }
    }
  }, [JSON.stringify(recentElectionIssuesRacesFetchedName)])

  useEffect(() => {
    if (recentElectionIssuesAttentionFlagsFetchedName.length > 0) {
      const namesArray = [...recentElectionIssuesAttentionFlagsFetchedName]
      const recentAttentionFlag = namesArray.pop()
      setRecentElectionIssuesAttentionFlagsFetchedName(namesArray, 'remove')

      if (root && root.children && root.children.length > 0) {
        if (recentAttentionFlag?.length) {
          const issueName = recentAttentionFlag.split(',,')[0]
          const categoryName = recentAttentionFlag.split(',,')[1]
          const electionAttentionFlagIssue = electionAttentionFlagsData[issueName][categoryName] || {}
          const electionIssueAttentionFlagKeys = Object.keys(electionAttentionFlagIssue)
          const newDataset = [...dataset]

          for (const key of electionIssueAttentionFlagKeys) {
            const datasetNodeIndex = newDataset.findIndex((data) => data.name.includes(categoryName))

            if (datasetNodeIndex > -1) {
              const datasetNode = newDataset[datasetNodeIndex]
              const issueAttentionFlag = electionAttentionFlagIssue[key]
              datasetNode.noOfPosts = issueAttentionFlag.noOfPosts

              if ((datasetNode.noOfPosts || 0) > 0) {
                const flag = rangeCodes[key as keyof { [x: string]: string }]
                if (flag && flag.length > 0 && !datasetNode.imports.includes(flag)) {
                  datasetNode.imports.push(flag)
                }
              }
            }
          }
          setDataset(newDataset)
        }
      }
    }
  }, [JSON.stringify(recentElectionIssuesAttentionFlagsFetchedName)])

  useEffect(() => {
    if (recentElectionRacesAttentionFlagFetchedName.length > 0) {
      const namesArray = [...recentElectionRacesAttentionFlagFetchedName]
      const recentRaceAttentionFlag = namesArray.pop()
      setRecentElectionRacesAttentionFlagFetchedName(namesArray, 'remove')

      if (root && root.children && root.children.length > 0) {
        if (recentRaceAttentionFlag?.length) {
          const attentionFlagName = recentRaceAttentionFlag.split(',,')[0]
          const raceName = recentRaceAttentionFlag.split(',,')[1]
          const raceAttentionFlag = electionRacesAttentionFlagData[attentionFlagName][raceName] || {}
          const newDataset = [...dataset]

          const datasetNodeIndex = newDataset.findIndex((data) => data.name.includes(raceName))

          if (datasetNodeIndex > -1) {
            const datasetNode = newDataset[datasetNodeIndex]
            datasetNode.noOfPosts = raceAttentionFlag.noOfPosts

            if ((datasetNode.noOfPosts || 0) > 0) {
              const flag = rangeCodes[attentionFlagName as keyof { [x: string]: string }]
              if (flag && flag.length > 0 && !datasetNode.imports.includes(flag)) {
                datasetNode.imports.push(flag)
              }
            }
          }
          setDataset(newDataset)
        }
      }
    }
  }, [JSON.stringify(recentElectionRacesAttentionFlagFetchedName)])

  useEffect(() => {
    setIsNodeClicked(false)
    if (root) {
      const issuesDataset = root?.leaves().filter((d: any) => !d.data.name.includes('TITLE')) || []
      const titleDataset = root?.leaves().filter((d: any) => d.data.name.includes('TITLE')) || []
      const rangeDataset = root?.leaves().filter((d: any) => range.includes(d.parent.data.name)) || []
      const leafGroups = d3.groups(root.leaves(), (d: any) => d.parent?.data.name)
      const arcAngles = leafGroups.map((g) => {
        let addition = range.includes(g[0]) ? extraSpaceForRange : extraSpaceForIssues
        const node: any = g[1][0].data
        let label = ''
        if (node?.name) label = node?.name
        const parent: any = g[1][0].parent?.parent
        let check = false

        if (parent) check = parent.data.name === 'races' || parent.data.name === 'company'

        const isRaces = parent?.data?.name === 'races'
        let className = arcClasses.General
        if (isRaces) {
          const color: 'red' | 'purple' | 'blue' = node?.color || 'red'
          className = arcClasses[`races-${color}`]
        } else {
          className =
            isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(label) && check
              ? arcClasses.highlighted
              : arcClasses.General
        }
        return {
          name: g[0],
          label: label,
          start: d3.min(g[1], (d) => d.x - addition),
          end: d3.max(g[1], (d) => d.x + addition),
          class: className,
        }
      })
      const rangeData = root.children?.filter((d: any) => d.data.name === 'range')[0].children || []
      const parentGroups = d3.groups(rangeData, (d: any) => d.data.name)
      const parentGroupsArcAngles = parentGroups.map((g: any) => ({
        name: g[0],
        start: d3.min(g[1][0], (d: any) => d.x),
        end: d3.max(g[1][0], (d: any) => d.x),
        class:
          arcClasses[
            g[0] as keyof {
              General: string
              races: string
              'races-red': string
              'races-blue': string
              'races-purple': string
              company: string
              highlighted: string
            }
          ],
      }))

      const svg = d3.select('#chart')
      svg.selectChildren('svg').remove()

      d3.select('#chart').append('svg').attr('id', 'chord').attr('viewBox', viewBox)

      addLeavesArc(arcAngles)
      addRangeArc(parentGroupsArcAngles)
      addIssuesSvgs(root)
      addSubIssues(issuesDataset)
      addTitles(titleDataset)
      addRange(rangeDataset)
      const outgoing = root?.leaves().flatMap((leaf: any) => leaf.outgoing)
      if (outgoing) addLinks(root?.leaves().flatMap((leaf: any) => leaf.outgoing))
    }
  }, [dataset, root, isSideDetailsOpen])

  useEffect(() => {
    setNarrativeInfoDropdown(<></>)
    if (!isNodeClicked) changeGraphToOriginal()
  }, [isNodeClicked])

  useEffect(() => {
    if (isSideDetailsOpen.length > 0) makeGraphFadeOut()
  }, [isSideDetailsOpen])

  const addTitles = (titleDataset: d3.HierarchyPointNode<unknown>[]) => {
    const titleSvgs = d3.selectAll('.chord-graph_svg_text_title').data(titleDataset)

    titleSvgs
      .append('text')
      .attr('dy', '0.31em')
      .attr('y', (d: any) => (d.x < Math.PI ? 2.5 : -2.5))
      .attr('x', (d: any) => (d.x < Math.PI ? 4 : -4))
      .attr('text-anchor', (d: any) => (d.x < Math.PI ? 'start' : 'end'))
      .attr('transform', (d: any) => (d.x >= Math.PI ? 'rotate(180)' : null))
      .attr('id', (d: any) => d.data.name.replaceAll(' ', '_'))
      .text((d: any) => d.data.name.replaceAll('TITLE', '').replaceAll('_', ' '))
      .attr('class', (d: any) => 'chord-graph__chart__title')

    titleSvgs
      .insert('rect', 'text')
      .attr('width', (d: any) => {
        const width =
          (d3.selectAll('#' + d.data.name.replaceAll(' ', '_')).node() as SVGSVGElement)?.getBBox().width || 10
        return width + 4.5
      })
      .attr('height', 5)
      .attr('dy', '0.31em')
      .attr('x', 1)
      .attr('class', 'chord-graph__chart__title__background')
  }

  const addSubIssues = (issueDataset: d3.HierarchyPointNode<unknown>[]) => {
    const issueSvgs = d3.selectAll('.chord-graph_svg_text').data(issueDataset)

    issueSvgs
      .append('text')
      .attr('dy', '0.31em')
      .attr('x', (d: any) => (d.x <= Math.PI ? 2 : -2))
      .attr('text-anchor', (d: any) => (d.x <= Math.PI ? 'start' : 'end'))
      .attr('transform', (d: any) => (d.x > Math.PI ? 'rotate(180)' : null))
      .text((d: any) => d.data.name.replaceAll('_', ' '))
      .attr('class', 'chord-graph__chart__labels')
      .attr('class', (d: any) => {
        return isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(d.data.name)
          ? 'sample_text chord-graph__chart__labels--highlighted'
          : 'chord-graph__chart__labels'
      })
      .each(function (d: any) {
        d.text = this
      })
      .on('click', onClickLink)
  }

  const addRange = (rangeDataset: d3.HierarchyPointNode<unknown>[]) => {
    const rangeSvgs = d3.selectAll('.chord-graph_svg_text_range').data(rangeDataset)

    rangeSvgs
      .append('text')
      .attr('dy', '0.31em')
      .attr('y', (d: any) => (d.x < Math.PI ? 2.5 : -2.5))
      .attr('x', (d: any) => (d.x < Math.PI ? 4 : -4))
      .attr('text-anchor', (d: any) => (d.x < Math.PI ? 'start' : 'end'))
      .attr('transform', (d: any) => (d.x >= Math.PI ? 'rotate(180)' : null))
      .attr('id', (d: any) => d.data.name.replaceAll(' ', '_'))
      .text((d: any) => d.data.name.replaceAll('_', ' '))
      .attr('class', (d: any) => {
        const isRaces = d?.parent?.parent?.data?.name === 'races'
        if (isRaces) {
          return `chord-graph__elections__title`
        } else {
          return isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(d.data.name)
            ? 'sample_text chord-graph__chart__title--highlighted'
            : 'chord-graph__chart__title'
        }
      })
      .each(function (d: any) {
        d.text = this
      })
      .on('click', onClickLink)

    rangeSvgs
      .insert('rect', 'text')
      .attr('width', (d: any) => {
        const width =
          (d3.selectAll('#' + d.data.name.replaceAll(' ', '_')).node() as SVGSVGElement)?.getBBox().width || 10
        return width + 4.5
      })
      .attr('id', (d: any) => 'rect_' + d.data.name.replaceAll(' ', '_'))
      .attr('height', 5)
      .attr('dy', '0.31em')
      .attr('x', 1)
      .attr('class', (d: any) => {
        const isRaces = d?.parent?.parent?.data?.name === 'races'
        if (isRaces) {
          return `chord-graph__elections__races--${d.data?.color || 'red'}`
        } else {
          return isSideDetailsOpen.length > 0 && isSideDetailsOpen.includes(d.data.name)
            ? 'chord-graph__chart__rectangle--highlighted'
            : 'chord-graph__chart__title__background'
        }
      })
      .each(function (d: any) {
        d.rect = this
      })
      .on('click', onClickLink)
  }

  const addLeavesArc = (arcAngles: Iterable<unknown>) => {
    d3.select('#chord')
      .selectAll('.arc')
      .data(arcAngles)
      .enter()
      .append('path')
      .attr('id', (d: any) => `arc_${d.label}`)
      .attr('d', (d: any) =>
        arc({
          startAngle: d.start,
          endAngle: d.end,
          innerRadius: leavesArcInnerRadius,
          outerRadius: leavesArcOuterRadius,
        }),
      )
      .attr('class', (d: any) => d.class)
  }

  const addRangeArc = (parentGroupsArcAngles: Iterable<unknown>) => {
    d3.select('#chord')
      .selectAll('.arc')
      .data(parentGroupsArcAngles)
      .enter()
      .append('path')
      .attr('id', (d, i) => `parc_${i}`)
      .attr('d', (d: any) =>
        arc({
          startAngle: d.start - extraSpaceForRange,
          endAngle: d.end + extraSpaceForRange,
          innerRadius: parentsArcInnerRadius,
          outerRadius: parentsArcOuterRadius,
        }),
      )
      .attr('class', (d: any) => d.class)
  }

  const addIssuesSvgs = (root: d3.HierarchyPointNode<unknown>) => {
    d3.select('#chord')
      .append('g')
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', (d: any) => {
        if (range.includes(d.parent.data.name)) return `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},-2.5)`
        else return `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`
      })
      .attr('class', 'chord-graph_svg_class')
      .append('g')
      .attr('class', (d: any) => {
        if (d.data.name.includes('TITLE')) return 'chord-graph_svg_text_title'
        else if (range.includes(d.parent.data.name)) return 'chord-graph_svg_text_range'
        else return 'chord-graph_svg_text'
      })
  }

  const addLinks = (linksData: any) => {
    d3.select('#chord')
      .append('g')
      .selectAll('path')
      .data(linksData)
      .join('path')
      .attr('id', ([node1, node2]: any) => {
        return `link_${node1?.data?.name}_${node2?.data?.name}`
      })
      .attr('d', ([i, o]: any) => linkageLine(i.path(o)))
      .attr('class', ([node1, node2]: any) => {
        const isRacesAttentionFlagNodes =
          range.includes(node1?.parent?.data?.name) && range.includes(node2?.parent?.data?.name)

        const linkKey = isRacesAttentionFlagNodes
          ? `${node1.parent.data.name}_${node1.parent.data.name}_${node2.data.name},${node1.data.name}`
          : `${node1.parent.data.name}_${node1.data.name}_${node2.data.name}`
        return isSideDetailsOpen.length > 0 && isSideDetailsOpen === linkKey
          ? 'sample_link chord-graph__links--highlighted'
          : 'chord-graph__links'
      })
      .each(function (d: any) {
        d.path = this
      })
      .on('click', showNarrativeInfoPopup)
  }

  const onClickLink = (event: any, d: any) => {
    setNarrativeInfoDropdown(<></>)
    setIsSideDetailsOpen('')
    setSideDetails({
      topic: '',
      category: '',
      riskOrFlagName: '',
      tab: '',
      url: '',
    })
    d3.selectAll('.sample_link').attr('class', 'chord-graph__links')
    unSelectNodes()

    if (d.data.name === selectedLink) {
      selectedLink = ''
      setIsNodeClicked(false)
      changeGraphToOriginal()
    } else {
      selectedLink = d.data.name

      if (!isNodeClicked) makeGraphFadeOut()

      highlightSelectedNodes(d)

      d3.selectAll(d.incoming.map((d: any) => d.path))
        .attr('class', ([, node2]: any) => {
          return node2?.parent?.parent?.data?.name === 'races'
            ? 'sample_link chord-graph__links--' + node2?.data?.color || 'red'
            : 'sample_link chord-graph__links--highlighted'
        })
        .raise()

      d3.selectAll(d.incoming.map(([d]: any) => d.text)).attr(
        'class',
        'sample_text chord-graph__chart__labels--highlighted',
      )

      d3.selectAll(d.outgoing.map((d: any) => d.path))
        .attr('class', ([, node2]: any) => {
          return node2?.parent?.parent?.data?.name === 'races'
            ? 'sample_link chord-graph__links--' + node2?.data?.color || 'red'
            : 'sample_link chord-graph__links--highlighted'
        })
        .raise()

      d3.selectAll(d.outgoing.map(([, d]: any) => d.text))
        .attr('class', (d: any) => {
          return (
            'sample_text chord-graph__chart__title--highlighted' +
            (d?.parent?.parent?.data?.name === 'races' ? ' election__title' : '')
          )
        })
        .attr('font-weight', 'bold')
    }
  }

  const showNarrativeInfoPopup = (event: any, [node1, node2]: any) => {
    if (selectedLink.length > 0) {
      const { pageX: x, pageY: y } = event
      d3.select('#info')
        .style('position', 'absolute')
        .style('left', `${x}px`)
        .style('top', `${y}px`)
        .style('display', 'block')

      const isRacesAttentionFlagNodes =
        range.includes(node1?.parent?.data?.name) && range.includes(node2?.parent?.data?.name)

      if (!isRacesAttentionFlagNodes) {
        const isRaces = node2?.parent?.parent?.data?.name === 'races' ? true : false
        const issue = electionIssues[node1?.parent?.data?.name]
        const issueCategory = issue?.categories.find((cat) => cat.name === node1?.data?.name)
        const race = electionRaces[node2?.data?.name]
        const attentionFlag = attentionFlags[node2?.data?.name]
        const electionRaceFlag = isRaces
          ? electionRacesData[node1?.parent?.data?.name][node1?.data?.name]
          : electionAttentionFlagsData[node1?.parent?.data?.name][node1?.data?.name]
        const electionRaceFlagCategory: any = electionRaceFlag[node2?.data?.name]
        const category: INarrativeInfoCategory = isRaces
          ? {
              id: issueCategory?.id,
              label: issueCategory?.label,
              name: issueCategory?.name,
              subItemName: race?.label,
              noOfPosts: electionRaceFlagCategory.noOfPosts,
              metricValues: electionRaceFlagCategory.metricValues,
              conditions: electionRaceFlagCategory?.conditions || [],
              description: issueCategory?.description || '',
              narrative_number: electionRaceFlagCategory?.ids || -1,
              narrative_id: issueCategory?.narrative_id || '',
              flags: electionRaceFlagCategory.flags,
            }
          : {
              id: issueCategory?.id,
              label: issueCategory?.label,
              name: issueCategory?.name,
              subItemName: attentionFlag?.displayLabel,
              noOfPosts: electionRaceFlagCategory.noOfPosts,
              metricValues: electionRaceFlagCategory.metricValues,
              description: issueCategory?.description || '',
              narrative_number: issueCategory?.narrative_number || -1,
              narrative_id: issueCategory?.narrative_id || '',
              flags: electionRaceFlagCategory.flags,
              conditions: electionRaceFlagCategory.conditions,
            }
        const url = isRaces
          ? `tab=races&issue=${encodeURIComponent(node1.parent.data.name)}&race=${encodeURIComponent(
              node2.data.name,
            )}&category=${encodeURIComponent(category.name || '')}`
          : `tab=company&issue=${encodeURIComponent(node1.parent.data.name)}&company=${encodeURIComponent(
              node2.data.name,
            )}&category=${encodeURIComponent(category.name || '')}`

        const tab = isRaces ? 'races' : 'company'
        const info = (
          <Dropdown
            open
            overlayStyle={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
            overlay={
              <NarrativeInfo
                name={node1.parent.data.name}
                category={category}
                url={url}
                tab={tab}
                isChordView={true}
                sideDetailsInfo={{
                  topic: node1.parent.data.name,
                  category: category.name || '',
                  riskOrFlagName: node2.data.name,
                  tab: tab,
                  url: url,
                }}
                setNarrativeInfoDropdown={setNarrativeInfoDropdown}
              />
            }>
            <span></span>
          </Dropdown>
        )
        setNarrativeInfoDropdown(info)
      } else {
        const raceObj = electionRaces[node1?.data?.name]
        const attentionFlagObj = attentionFlags[node2?.data?.name]

        if (electionRacesAttentionFlagData[`${node2?.data?.name}`]) {
          const raceFlag = electionRacesAttentionFlagData[`${node2?.data?.name}`][`${node1?.data?.name}`]

          const category: INarrativeInfoCategory = {
            id: raceObj?.id,
            label: raceObj?.label,
            name: node1?.parent?.data?.name,
            subItemName: attentionFlagObj?.displayLabel,
            noOfPosts: raceFlag.noOfPosts,
            metricValues: raceFlag.metricValues,
            description: raceObj?.description || '',
            narrative_number: raceObj?.narrative_number || -1,
            narrative_id: '',
            flags: raceFlag.flags,
            conditions: raceFlag.conditions,
          }
          const url = `tab=race-company-range&race=${encodeURIComponent(node1.data.name)}&company=${encodeURIComponent(
            node2.data.name,
          )}`
          const tab = 'race-company-range'
          const info = (
            <Dropdown
              open
              overlayStyle={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
              overlay={
                <NarrativeInfo
                  name={node1.parent.data.name}
                  category={category}
                  url={url}
                  tab={tab}
                  isChordView={true}
                  sideDetailsInfo={{
                    topic: node1.parent.data.name,
                    category: category.name || '',
                    riskOrFlagName: `${node2.data.name},${node1.data.name}`,
                    tab: tab,
                    url: url,
                  }}
                  setNarrativeInfoDropdown={setNarrativeInfoDropdown}
                />
              }>
              <span></span>
            </Dropdown>
          )

          setNarrativeInfoDropdown(info)
        } else {
          const raceObj = electionRaces[node1?.data?.name]
          const attentionFlagObj = attentionFlags[node2?.data?.name]

          if (electionRacesAttentionFlagData[`${node2?.data?.name}`]) {
            const raceFlag = electionRacesAttentionFlagData[`${node2?.data?.name}`][`${node1?.data?.name}`]

            const category: INarrativeInfoCategory = {
              id: raceObj?.id,
              label: raceObj?.label,
              name: node1?.parent?.data?.name,
              subItemName: attentionFlagObj?.displayLabel,
              noOfPosts: raceFlag.noOfPosts,
              metricValues: raceFlag.metricValues,
              description: raceObj?.description || '',
              narrative_number: raceObj?.narrative_number || -1,
              narrative_id: '',
              flags: raceFlag.flags,
              conditions: raceFlag.conditions,
            }
            const url = `tab=race-company-range&race=${encodeURIComponent(
              node1.data.name,
            )}&company=${encodeURIComponent(node2.data.name)}`
            const tab = 'race-company-range'
            const info = (
              <Dropdown
                open
                overlayStyle={{ left: `${x}px`, top: `${y}px`, position: 'absolute' }}
                overlay={
                  <NarrativeInfo
                    name={node1.parent.data.name}
                    category={category}
                    url={url}
                    tab={tab}
                    isChordView={true}
                    sideDetailsInfo={{
                      topic: node1.parent.data.name,
                      category: category.name || '',
                      riskOrFlagName: `${node2.data.name},${node1.data.name}`,
                      tab: tab,
                      url: url,
                    }}
                    setNarrativeInfoDropdown={setNarrativeInfoDropdown}
                  />
                }>
                <span></span>
              </Dropdown>
            )

            setNarrativeInfoDropdown(info)
          }
        }
      }
    }
  }

  const makeGraphFadeOut = () => {
    setIsNodeClicked(true)
    d3.selectAll('.chord-graph__chart__labels').attr(
      'class',
      'chord-graph__chart__labels chord-graph__chart__labels__opacity',
    )
    d3.selectAll('.chord-graph__chart__title').attr(
      'class',
      'chord-graph__chart__title chord-graph__chart__title__opacity',
    )
    d3.selectAll('.chord-graph__elections__title').attr(
      'class',
      'chord-graph__chart__title chord-graph__chart__title__opacity election__title',
    )

    d3.selectAll('.chord-graph__chart__title__background').attr(
      'class',
      'chord-graph__chart__title__background chord-graph__chart__title__background__opacity',
    )

    d3.selectAll('.chord-graph__elections__races--red').attr(
      'class',
      'chord-graph__chart__title__background chord-graph__chart__title__background__opacity red_class',
    )

    d3.selectAll('.chord-graph__elections__races--blue').attr(
      'class',
      'chord-graph__chart__title__background chord-graph__chart__title__background__opacity blue_class',
    )

    d3.selectAll('.chord-graph__elections__races--purple').attr(
      'class',
      'chord-graph__chart__title__background chord-graph__chart__title__background__opacity purple_class',
    )

    d3.selectAll('.chord-graph__chart__arcs__general').attr('class', 'chord-graph__chart__arcs__opacity')
    d3.selectAll('.chord-graph__chart__arcs__races').attr('class', 'chord-graph__chart__arcs__opacity races')
    d3.selectAll('.chord-graph__chart__arcs__races--red').attr(
      'class',
      'chord-graph__chart__arcs__opacity arcs__races--red',
    )
    d3.selectAll('.chord-graph__chart__arcs__races--blue').attr(
      'class',
      'chord-graph__chart__arcs__opacity arcs__races--blue',
    )
    d3.selectAll('.chord-graph__chart__arcs__races--purple').attr(
      'class',
      'chord-graph__chart__arcs__opacity arcs__races--purple',
    )
    d3.selectAll('.chord-graph__chart__arcs__company').attr('class', 'chord-graph__chart__arcs__opacity company')
  }

  const highlightSelectedNodes = (d: any) => {
    if (d?.outgoing.length > 0) {
      const className =
        d?.parent?.parent?.data?.name === 'races'
          ? 'chord-graph__chart__title chord-graph__selected-title-node election__title'
          : 'chord-graph__chart__labels chord-graph__selected-node'
      d3.select(d.text).attr('class', className)
      d?.outgoing.forEach(([, raceFlag]: any) => {
        const className =
          raceFlag?.parent?.parent?.data?.name === 'races'
            ? `chord-graph__elections__races--${raceFlag.data?.color || 'red'}`
            : 'chord-graph__chart__rectangle--highlighted'
        d3.selectAll('#rect_' + raceFlag.data.name).attr('class', className)
      })
      d?.outgoing.forEach(([, raceFlag]: any) => {
        const className =
          raceFlag?.parent?.parent?.data?.name === 'races'
            ? `chord-graph__chart__arcs__races--${raceFlag.data?.color || 'red'}`
            : 'chord-graph__chart__arcs--highlighted'

        d3.selectAll('#arc_' + raceFlag.data.name).attr('class', className)
      })
    }
    if (d?.incoming.length > 0) {
      const className =
        d?.parent?.parent?.data?.name === 'races'
          ? 'chord-graph__chart__title chord-graph__selected-title-node election__title'
          : 'chord-graph__chart__labels chord-graph__selected-node'
      d3.select(d.text).attr('class', className)

      d?.incoming.forEach(([, raceFlag]: any) => {
        const className =
          raceFlag?.parent?.parent?.data?.name === 'races'
            ? `chord-graph__elections__races--${raceFlag.data?.color || 'red'}`
            : 'chord-graph__chart__rectangle--highlighted'
        d3.selectAll('#rect_' + raceFlag.data.name).attr('class', className)
      })
      d?.incoming.forEach(([, raceFlag]: any) => {
        const className =
          raceFlag?.parent?.parent?.data?.name === 'races'
            ? `chord-graph__chart__arcs__races--${raceFlag.data?.color || 'red'}`
            : 'chord-graph__chart__arcs--highlighted'

        d3.selectAll('#arc_' + raceFlag.data.name).attr('class', className)
      })
    }
  }

  const unSelectNodes = () => {
    d3.selectAll('.election__title').attr('class', 'chord-graph__elections__title')
    d3.selectAll('.chord-graph__selected-node').attr('class', 'chord-graph__chart__labels')
    d3.selectAll('.chord-graph__selected-title-node').attr('class', 'chord-graph__chart__title')
    d3.selectAll('.chord-graph__chart__rectangle--highlighted').attr('class', 'chord-graph__chart__title__background')

    d3.selectAll('.chord-graph__chart__title--highlighted').attr('class', 'chord-graph__chart__title')
    d3.selectAll('.chord-graph__chart__labels--highlighted').attr('class', 'chord-graph__chart__labels')

    d3.selectAll('.red_class').attr('class', 'chord-graph__elections__races--red')
    d3.selectAll('.blue_class').attr('class', 'chord-graph__elections__races--blue')
    d3.selectAll('.purple_class').attr('class', 'chord-graph__elections__races--purple')

    d3.selectAll('.arcs__races--red').attr('class', 'chord-graph__chart__arcs__races--red')
    d3.selectAll('.arcs__races--blue').attr('class', 'chord-graph__chart__arcs__races--blue')
    d3.selectAll('.arcs__races--purple').attr('class', 'chord-graph__chart__arcs__races--purple')
    d3.selectAll('.chord-graph__chart__arcs--highlighted').attr('class', 'chord-graph__chart__arcs__general')
    d3.selectAll('.races').attr('class', 'chord-graph__chart__arcs__races')
    d3.selectAll('.company').attr('class', 'chord-graph__chart__arcs__company')
  }

  const changeGraphToOriginal = () => {
    d3.selectAll('.election__title').attr('class', 'chord-graph__elections__title ')
    d3.selectAll('.chord-graph__chart__title chord-graph__selected-title-node').attr(
      'class',
      'chord-graph__elections__title',
    )
    d3.selectAll('.chord-graph__chart__labels__opacity').attr('class', 'chord-graph__chart__labels')
    d3.selectAll('.chord-graph__chart__title__opacity').attr('class', 'chord-graph__chart__title')

    d3.selectAll('.chord-graph__chart__title__background__opacity').attr(
      'class',
      'chord-graph__chart__title__background',
    )

    d3.selectAll('.arcs__races--red').attr('class', 'chord-graph__chart__arcs__races--red')
    d3.selectAll('.arcs__races--blue').attr('class', 'chord-graph__chart__arcs__races--blue')
    d3.selectAll('.arcs__races--purple').attr('class', 'chord-graph__chart__arcs__races--purple')

    d3.selectAll('.red_class').attr('class', 'chord-graph__elections__races--red')
    d3.selectAll('.blue_class').attr('class', 'chord-graph__elections__races--blue')
    d3.selectAll('.purple_class').attr('class', 'chord-graph__elections__races--purple')
  }

  return (
    <Spin spinning={isLoadingElectionRaces || isLoadingAttentionFlags || isLoadingNoOfPosts || isLoadingFlags}>
      <div>
        <div className='chord-graph__content-div'>
          <div className='chord-graph__description'>
            <span>{'Hover over any chord of the chart to see election details.'}</span>
          </div>
          <div id='chart'></div>
          <div className='chord-graph__election-logo'>
            <ElectionLogo />
          </div>
          <div id='info'>{narrativeInfoDropdown}</div>
        </div>
      </div>
    </Spin>
  )
})
