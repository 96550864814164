import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { notification } from 'antd'
import classNames from 'classnames'
import { UtilService } from 'services/Util/Util'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import CombineDetailsView from '../CombineDetailsView/CombineDetailsView'
import CreateNarrativeForm from '../CreateNarrative/CreateNarrativeForm/CreateNarrativeForm.page'
import CreateNarrativeAdvancedModals from '../CreateNarrative/CreateNarrativeAdvancedModals/CreateNarrativeAdvancedModals'
import { NarrativeEvaluator } from '../CreateNarrative/NarrativeEvaluator/NarrativeEvaluator'
import { ReactComponent as WarningIcon } from 'assets/images/icons/monitor/warning_icon.svg'
import { ReactComponent as NotificationIcon } from 'assets/images/icon-notification.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import './CreateNarrativeDetailsView.scss'

export const CancelBreadCrumb = () => {
  return (
    <div className='cbc_container'>
      <Link className='cbc_c_link' to='/vectors' replace={true}>
        <CloseIcon className='cbc_c_l_close_icon' />
        Cancel
      </Link>
      <Link className='cbc_c_create_text' to='/vectors/create' replace={true}>
        Create new
      </Link>
    </div>
  )
}

const LARGE_MATCHING_RESULTS = 500000

const CreateNarrativeDetailsView = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { defineNarrativeStore, navViewStore, breadcrumbStore, vectorsStore } = store
  const {
    formData,
    addFormData,
    getSnippetFilterPayload,
    publishNarrative,
    saveNarrative,
    normalUniqueContent,
    resetStore,
  } = defineNarrativeStore
  const { snippetsFilter, snippetsTotal, setSnippetsFilter } = vectorsStore
  const [showDetailScreen, setShowDetailScreen] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [updateConditions, setUpdateConditions] = useState<string>('')
  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState<null | 'article' | 'publish' | 'save'>(null)
  const [isEvaluatorModalOpen, setIsEvaluatorModalOpen] = useState<boolean>(false)

  const { isNavOpen } = navViewStore
  const { setBreadcrumbData } = breadcrumbStore

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [])

  useEffect(() => {
    const filter = {
      days: {
        custom: {
          isApplied: false,
          dateRange: [],
        },
        noOfDays: 90,
      },
    }
    setSnippetsFilter({ ...snippetsFilter, ...filter })
  }, [updateConditions])

  useEffect(() => {
    setBreadcrumbData([
      { id: UtilService.generateUuid(), title: <CancelBreadCrumb /> },
      { id: UtilService.generateUuid(), title: 'Create new Narrative' },
    ])
  }, [])

  const openNotification = () => {
    notification.info({
      icon: <NotificationIcon />,
      message: <span className='create-narrative__notification__title'>Narrative created successfully</span>,
      description: (
        <span className='create-narrative__notification__description'>
          Great! Pendulum is fetching data for this narrative - once done, you will be notified.
        </span>
      ),
      placement: 'bottomLeft',
      className: classNames({ 'create-narrative__notification__margin': isNavOpen }),
    })
  }

  const publish = async () => {
    try {
      if (isAdvancedModalOpen === 'publish') {
        setIsAdvancedModalOpen(null)
        const response = await publishNarrative()
        if (response) {
          openNotification()
          return navigate('/vectors')
        } else return
      }

      setIsAdvancedModalOpen('publish')
    } catch (e: any) {}
  }

  const save = async () => {
    try {
      if (isAdvancedModalOpen === 'save') {
        setIsAdvancedModalOpen(null)
        return saveNarrative()
      }

      setIsAdvancedModalOpen('save')
    } catch (e: any) {}
  }

  return (
    <div className='create_detail_view_container'>
      {showDetailScreen ? (
        <>
          <div className='cdvc_header'>
            <div className='cdvc_h_left'>
              <ButtonImage
                className='cdvc_h_l_button'
                onClick={() => {
                  setOpenEditModal(true)
                }}>
                Edit search
              </ButtonImage>
              <ButtonImage
                className='cdvc_h_l_button'
                onClick={() => {
                  setIsEvaluatorModalOpen(true)
                }}>
                Evaluate results
              </ButtonImage>
              {snippetsTotal > LARGE_MATCHING_RESULTS && (
                <div className='cdvc_h_l_info_container'>
                  <WarningIcon />
                  <p className='cdvc_h_l_ic_text'>
                    Your search results in too many items to be useful. Consider refining your keywords.
                  </p>
                </div>
              )}
            </div>
            <ButtonImage
              className='cdvc_h_l_button'
              onClick={() => {
                setIsAdvancedModalOpen('publish')
              }}>
              Publish Narrative
            </ButtonImage>
          </div>
          <CombineDetailsView
            className='cdvc_detail_view_container'
            pageType={'details'}
            page={'monitor'}
            subStore={'vectors'}
          />
          {openEditModal && (
            <div className='cdvc_narrative_form'>
              <CreateNarrativeForm
                onBackClick={() => {
                  setOpenEditModal(false)
                }}
                onSearchClick={async () => {
                  const conditions = await getSnippetFilterPayload()
                  const modifiedConditions: any = {}

                  conditions.conditions.forEach((item: any) => {
                    const key = Object.keys(item)[0]
                    modifiedConditions[key] = item[key]
                  })
                  if (conditions.query.language) {
                    const regex = /\[([^)]+)\]/
                    const matches = regex.exec(conditions.query.language)
                    if (matches && matches.length > 1) {
                      const contentInsideBrackets = matches[1]
                      const state = {
                        name: 'Power Insights',
                        conditions: modifiedConditions,
                        mode: 'narrative',
                        languageKey: contentInsideBrackets,
                      }

                      navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
                      setUpdateConditions(`${JSON.stringify(modifiedConditions)}_${contentInsideBrackets}`)
                    }
                  } else {
                    const state = {
                      name: 'Power Insights',
                      conditions: modifiedConditions,
                      mode: 'narrative',
                    }

                    navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
                    setUpdateConditions(JSON.stringify(modifiedConditions))
                  }
                }}
                formData={formData}
                addFormData={addFormData}
                onEditMode={true}
              />
            </div>
          )}
        </>
      ) : (
        <CreateNarrativeForm
          onSearchClick={async () => {
            const conditions = await getSnippetFilterPayload()
            const modifiedConditions: any = {}

            conditions.conditions.forEach((item: any) => {
              const key = Object.keys(item)[0]
              modifiedConditions[key] = item[key]
            })

            if (conditions.query.language) {
              const regex = /\[([^)]+)\]/
              const matches = regex.exec(conditions.query.language)
              if (matches && matches.length > 1) {
                const contentInsideBrackets = matches[1]
                const state = {
                  name: 'Power Insights',
                  conditions: modifiedConditions,
                  mode: 'narrative',
                  languageKey: contentInsideBrackets,
                }

                navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
              }
            } else {
              const state = {
                name: 'Power Insights',
                conditions: modifiedConditions,
                mode: 'narrative',
              }

              navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
            }

            setShowDetailScreen(true)
          }}
          onBackClick={() => {
            navigate('../', { replace: true })
          }}
          formData={formData}
          addFormData={addFormData}
        />
      )}
      <CreateNarrativeAdvancedModals
        openModal={isAdvancedModalOpen}
        setOpenModal={setIsAdvancedModalOpen}
        publishNarrative={publish}
        saveNarrative={save}
        uniqueContentList={normalUniqueContent}
      />
      <NarrativeEvaluator isModalOpen={isEvaluatorModalOpen} setIsModalOpen={setIsEvaluatorModalOpen} />
    </div>
  )
})

export default CreateNarrativeDetailsView
