import millify from 'millify'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'

import { store } from 'store'

import { ReactComponent as SummaryIcon } from 'assets/images/anticipatory-summary-icon.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as DislikeFilledIcon } from 'assets/images/dislike-thumb-solid-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as LikeFilledIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warning-triangle.svg'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'
import loaderGif from 'assets/gifs/loader.gif'
import { SubStore } from 'types/types'

import styles from './executiveSummary.module.scss'
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { openNotification } from 'services/Util/openNotification'
import { containsHTML } from 'version2/utils/helper'

interface ExecutiveSummaryProps {
  subStore: SubStore
  canCopySummary?: boolean
}

export const ExecutiveSummary: React.FC<ExecutiveSummaryProps> = observer(({ subStore, canCopySummary }) => {
  const { loaderStore } = store
  const { isLoadingSummary } = loaderStore
  const { powerInsightsData, setPowerInsightsData, fetchSummaryData, getSnippetFilterPayload } =
    store[`${subStore}Store`]

  const { summary: summaryData } = powerInsightsData

  const [copilotSummaryFeedback, setCopilotSummaryFeedback] = useState<null | 'like' | 'dislike'>(null)
  const [isExecutiveSummaryTooLong, setIsExecutiveSummaryTooLong] = useState(false)
  const [showFullExecutiveSummary, setShowFullExecutiveSummary] = useState(false)

  const toggleFullExecutiveSummaryHandler = useCallback(() => {
    if (!isExecutiveSummaryTooLong) {
      return
    }

    setShowFullExecutiveSummary((prevShow) => !prevShow)
  }, [isExecutiveSummaryTooLong])

  useEffect(() => {
    if (summaryData.executiveSummary.text.length > 500) {
      setIsExecutiveSummaryTooLong(true)
    } else {
      setIsExecutiveSummaryTooLong(false)
    }
  }, [summaryData.executiveSummary])

  const retrySummary = async () => {
    const payload = await getSnippetFilterPayload()
    fetchSummaryData(payload).then((summaryStatisticsData) => {
      setPowerInsightsData({
        ...powerInsightsData,
        summary: {
          executiveSummary: summaryStatisticsData.executiveSummary,
          statistics: [
            {
              label: 'Snippets',
              value: millify(summaryStatisticsData.statistics?.total_snippets),
            },
            {
              label: 'Unique Creators',
              value: millify(summaryStatisticsData.statistics?.creator_count),
            },
            {
              label: 'Companies',
              value: millify(summaryStatisticsData.statistics?.company_count),
            },
            {
              label: 'People',
              value: millify(summaryStatisticsData.statistics?.people_count),
            },
            {
              label: 'Organizations',
              value: millify(summaryStatisticsData.statistics?.country_count),
            },
            {
              label: 'Views',
              value: millify(summaryStatisticsData.statistics?.total_views),
            },
          ],
          platformsDistribution: summaryStatisticsData.platformsDistribution,
        },
      })
    })
  }
  const copySummaryToClipboard = () => {
    window.navigator.clipboard
      .writeText(summaryData.executiveSummary.text)
      .then(() => {
        openNotification({ type: 'info', message: 'Summary copied to clipboard', duration: 2 })
      })
      .catch(() => {
        openNotification({ type: 'error', message: 'Failed to copy summary', duration: 2 })
      })
  }

  const renderSummary = () => {
    const content =
      isExecutiveSummaryTooLong && !showFullExecutiveSummary
        ? summaryData.executiveSummary.text.slice(0, 500) + '...'
        : summaryData.executiveSummary.text

    return (
      <>
        {containsHTML(content) ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <ReactMarkdown>{content}</ReactMarkdown>
        )}
        {isExecutiveSummaryTooLong && (
          <span className={styles.readMoreBtn} onClick={toggleFullExecutiveSummaryHandler}>
            &nbsp;Read {showFullExecutiveSummary ? 'less' : 'more'}
          </span>
        )}
      </>
    )
  }

  const getSummaryChunk = () => {
    if (!summaryData.executiveSummary.response && summaryData.executiveSummary.text?.length > 0) {
      return <div className={classNames(styles.summaryText)}>{renderSummary()}</div>
    } else if (summaryData.executiveSummary.response === 'NO_DATA') {
      return (
        <div className={styles.noDataContainer}>
          <InfoIcon />
          <span className={styles.text}>No data. Try to widen your search to get an executive summary.</span>
        </div>
      )
    } else if (summaryData.executiveSummary.text?.length > 0) {
      return <div className={classNames(styles.summaryText)}>{renderSummary()}</div>
    } else if (summaryData.executiveSummary.response === 'ERROR') {
      return (
        <div className={styles.errorScreen}>
          <WarningIcon />
          <span className={styles.message}>{'Oops, Something went wrong'}</span>
          <span className={styles.description}>{'Failed to load the summary. Please try again now or later.'}</span>
          <button className={styles.retry} onClick={retrySummary}>
            Retry
          </button>
        </div>
      )
    } else return <></>
  }

  return (
    <div className={styles.executiveSummary}>
      <div className={styles.heading}>
        <h4>
          Executive Summary <SummaryIcon />
        </h4>

        <div className={styles.headingActions}>
          <span className={styles.feedbackIcons}>
            <Tooltip placement='bottom' overlayClassName='pendulum-themed-tooltip' title='Dislike'>
              {copilotSummaryFeedback === 'dislike' ? (
                <DislikeFilledIcon
                  className={classNames(styles.icon, {
                    [styles.iconFilledDisabled]: isLoadingSummary || !summaryData.executiveSummary.text.length,
                  })}
                  onClick={() => !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback(null)}
                />
              ) : (
                <DislikeIcon
                  className={classNames(styles.icon, {
                    [styles.iconDisabled]: isLoadingSummary || !summaryData.executiveSummary.text.length,
                  })}
                  onClick={() =>
                    !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback('dislike')
                  }
                />
              )}
            </Tooltip>

            <Tooltip placement='bottom' overlayClassName='pendulum-themed-tooltip' title='Like'>
              {copilotSummaryFeedback === 'like' ? (
                <LikeFilledIcon
                  className={classNames(styles.icon, {
                    [styles.iconFilledDisabled]: isLoadingSummary || !summaryData.executiveSummary,
                  })}
                  onClick={() => !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback(null)}
                />
              ) : (
                <LikeIcon
                  className={classNames(styles.icon, {
                    [styles.iconDisabled]: isLoadingSummary || !summaryData.executiveSummary,
                  })}
                  onClick={() => !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback('like')}
                />
              )}
            </Tooltip>
          </span>
          {canCopySummary && (
            <ButtonImage
              icon={CopyIcon}
              className={styles.copyBtn}
              onClick={copySummaryToClipboard}
              disabled={summaryData.executiveSummary.text.length === 0 || isLoadingSummary}>
              Copy
            </ButtonImage>
          )}
        </div>
      </div>

      {isLoadingSummary ? (
        <div className={styles.loadingScreen}>
          <div className={styles.waiting}>
            <div className={styles.gifWrapper}>
              <img src={loaderGif} alt='loading gif' />
            </div>

            <div className={styles.message}>Please Wait ...</div>
          </div>

          <div className={styles.message}>Pendulum Co-Pilot is generating your summary.</div>
        </div>
      ) : (
        getSummaryChunk()
      )}
    </div>
  )
})
