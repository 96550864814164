import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import '../dropdown/dropdown.css'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalenderIcon } from 'assets/images/icons/Icon.svg'
import dayjs from 'dayjs'

export default function CalendarDropdown({ snippetsFilter, daysList, openCalendar }) {
  const dayFilterDropdown = (
    <div>
      {daysList}
      <div
        style={{
          padding: '.5rem',
          border: '2px solid #EAECF0',
          backgroundColor: '#FCFCFD',
        }}>
        <Space direction='vertical'>
          <div style={{ cursor: 'pointer' }} key={0} onClick={openCalendar}>
            <button className={classNames('feed-filters__custom-btn')}>{`Custom`}</button>
          </div>
        </Space>
      </div>
    </div>
  )
  return (
    <Dropdown
      overlay={dayFilterDropdown}
      style={{
        padding: '.5rem',
        border: '2px solid #EAECF0',
        backgroundColor: '#FCFCFD',
        borderRadius: '5px',
      }}>
      <Button
        style={{
          border: '2px solid #EAECF0',
          color: '#344054',
          borderRadius: '5px',
          fontSize: '15px',
          fontWeight: '600',
          backgroundColor: '#FCFCFD',
          display: 'flex',
          alignItems: 'center',
          marginRight: '.5rem',
        }}>
        <Space>
          <CalenderIcon />
          {((snippetsFilter.days.noOfDays > -1 && snippetsFilter.days.noOfDays < 91) ||
            snippetsFilter.days.custom.isApplied) && (
            <span>
              {snippetsFilter.days.custom.isApplied
                ? `${dayjs(snippetsFilter.days.custom.dateRange[0]).format('MMM DD, YYYY')} - ${dayjs(
                    snippetsFilter.days.custom.dateRange[1],
                  ).format('MMM DD, YYYY')}`
                : snippetsFilter.days.noOfDays === 1
                ? 'Yesterday'
                : snippetsFilter.days.noOfDays === 7
                ? 'Last 7 days'
                : snippetsFilter.days.noOfDays === 30
                ? 'Last 30 days'
                : snippetsFilter.days.noOfDays === 90
                ? 'Last 90 days'
                : `Last ${snippetsFilter.days.noOfDays} days`}
            </span>
          )}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}
