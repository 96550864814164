import { observer } from 'mobx-react-lite'
import { Spin } from 'antd'

import { ExecutiveSummary } from './ExecutiveSummary/ExecutiveSummary'
import { Statistics } from './Statistics/Statistics'
import { PlatformDistribution } from './PlatformDistribution/PlatformDistribution'

import { store } from 'store'
import { monitorMode, monitorSnippetPage } from 'models/models'

import styles from './PowerInsightsSummary.module.scss'

interface PowerInsightsSummaryProps {
  mode: monitorMode
  page: monitorSnippetPage
}

export const PowerInsightsSummary: React.FC<PowerInsightsSummaryProps> = observer(({ mode, page }) => {
  const { isLoadingSummary, isLoadingPlatformChart } = store.loaderStore

  return (
    <Spin spinning={isLoadingSummary || isLoadingPlatformChart}>
      <div className={styles.powerInsightsSummaryWrapper}>
        <ExecutiveSummary mode={mode} page={page} />
        <Statistics mode={mode} page={page} />
        <PlatformDistribution mode={mode} page={page} />
      </div>
    </Spin>
  )
})
