import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { FeedsDataType } from 'store/monitorVersion2/types'
import { ReportFieldParams } from 'store/report/valueTypes'

export function getLineChartStats(params: ReportFieldParams, data: FeedsDataType) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsLineChart}`,
    params: params,
    data: data,
  })
}
