import { observer } from 'mobx-react-lite'

import { AnalyzeFrame } from 'components/Analyze/AnalyzeFrame'
import './Analyze.scss'
import { TopLevelNavigation } from 'version2/components/common/TopLevelNavigation/TopLevelNavigation'

export const AnalyzePage = observer(() => {
  return (
    <>
      <TopLevelNavigation page='Analyze' />
      <div className='c-analyze-page'>{<AnalyzeFrame />}</div>
    </>
  )
})
