import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { TopLevelNavigation } from 'version2/components/common/TopLevelNavigation/TopLevelNavigation'

export const Reports = observer(() => {
  return (
    <>
      {<TopLevelNavigation page='Reports' />}
      <Outlet />
    </>
  )
})
