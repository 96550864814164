import { getRandomColor } from './getRandomColor'
import { sourcePlatform } from 'models/models'

export const getPlatformColors = (platform: sourcePlatform | Lowercase<sourcePlatform>) => {
  let color = ''
  let backgroundColor = ''

  switch (platform) {
    case 'twitter':
    case 'Twitter':
      color = '#16191D'
      backgroundColor = '#F2DF6B'
      break

    case 'youtube':
    case 'YouTube':
      color = '#16191D'
      backgroundColor = '#F9A978'
      break

    case 'tiktok':
    case 'TikTok':
      color = '#FFFFFF'
      backgroundColor = '#5E9BAE'
      break

    case 'podcast':
    case 'Podcast':
      color = '#16191D'
      backgroundColor = '#87C3A1'
      break

    case 'news':
    case 'News':
      color = '#16191D'
      backgroundColor = '#FFAADD'
      break

    case 'bitchute':
    case 'BitChute':
      color = '#16191D'
      backgroundColor = '#BDCFB1'
      break

    case 'rumble':
    case 'Rumble':
      color = '#16191D'
      backgroundColor = '#FFD3CD'
      break

    case 'reddit':
    case 'Reddit':
      color = '#FFFFFF'
      backgroundColor = '#5F384C'
      break

    case '4chan':
      color = '#FFFFFF'
      backgroundColor = '#858F87'
      break

    case 'gab':
    case 'Gab':
      color = '#FFFFFF'
      backgroundColor = '#9E90A8'
      break

    case 'patriots.win':
    case 'Patriots.win':
      color = '#16191D'
      backgroundColor = '#D59DA0'
      break

    case 'truthsocial':
    case 'TruthSocial':
      color = '#16191D'
      backgroundColor = '#D8B08F'
      break

    case 'vk':
      color = '#16191D'
      backgroundColor = '#A9CED9'
      break

    case 'instagram':
    case 'Instagram':
      color = '#16191D'
      backgroundColor = '#FCC6B9'
      break

    case 'threads':
    case 'Threads':
      color = '#16191D'
      backgroundColor = '#A3C9E8'
      break

    case 'telegram':
    case 'Telegram':
      color = '#16191D'
      backgroundColor = '#C5DED5'
      break

    case 'c-span':
    case 'C-SPAN':
      color = '#16191D'
      backgroundColor = '#F1C27D'
      break

    case 'consilium':
    case 'Consilium':
      color = '#16191D'
      backgroundColor = '#BFD6A5'
      break

    case 'websearch':
    case 'WebSearch':
      color = '#16191D'
      backgroundColor = '#A9D6E5'
      break

    case 'snapchat':
    case 'Snapchat':
      color = '#FFFC00'
      backgroundColor = '#5F384C'
      break

    case 'Odysee':
    case 'odysee':
      color = '#FFFC00'
      backgroundColor = '#CC2C53'
      break

    default:
      color = '#16191D'
      backgroundColor = getRandomColor()
  }

  return { color, backgroundColor }
}
