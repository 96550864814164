import classNames from 'classnames'
import './FeedUserDetail.scss'

type Props = {
  heading: string
  description?: string
  info?: any
  tags?: string[]
  image?: string
}

export const FeedUserDetail = ({ heading, description, info, tags, image }: Props) => {
  return (
    <div className={classNames({ 'feed-user-detail--margin': heading === 'Description' })}>
      <h6 className='feed-user-detail__title'>{heading}</h6>
      <div className='feed-user-detail__user'>
        {image && <img className='feed-user-detail__user__img' src={image} alt='' />}
        {heading === 'Tags' ? (
          <div className='feed-user-detail__tag-div'>
            {tags?.map((element) => (
              <span
                key={element}
                className={'feed-user-detail__tags--color' + Math.floor(Math.random() * (0 - 4 + 1) + 4)}>
                {element}
              </span>
            ))}
          </div>
        ) : heading === 'Creator' ? (
          <div className='feed-user-detail__info-div'>
            <span className='feed-user-detail__user__name'>{info.user}</span>
            <span className='feed-user-detail__info-div__date'>{info.date}</span>
          </div>
        ) : (
          <span className='feed-user-detail__user__name'>{description}</span>
        )}{' '}
      </div>
    </div>
  )
}
