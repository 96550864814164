import { SetStateAction, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { store } from 'store'

import { IAttentionFlagsMenu, monitorMode, monitorSnippetPage } from 'models/models'
import {
  STORE_LOOKUP_DICTIONARY,
  DEFINE_STORES_LOOKUP_DICTIONARY,
  ATTENTION_FLAG_INSIGHT_TYPES,
} from 'settings/settings'

import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow.svg'

import './FeedActions.scss'

type Props = {
  mode: monitorMode
  page: monitorSnippetPage
}

export const FeedActions: React.FC<Props> = observer(({ mode, page }) => {
  const { userFlags: attentionFlags } = store.attentionFlagsStore
  const { snippetsFilter, setSnippetsFilter, fetchSnippets } =
    page === 'define' && (mode === 'narrative' || mode === 'watchlist')
      ? store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}Store`]
      : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const selectedFlagsIds = Object.keys(snippetsFilter?.attentionFlags || {})

  const [listRef, setListRef] = useState<HTMLDivElement | null>(null)
  const listRefCallback = useCallback((node: SetStateAction<HTMLDivElement | null>) => {
    setListRef(node)
  }, [])

  const scrollHandler = (scrollOffset: number) => {
    if (listRef) {
      listRef.scrollLeft += scrollOffset
    }
  }

  const filterData = (item: IAttentionFlagsMenu) => {
    const typeLabel = ATTENTION_FLAG_INSIGHT_TYPES[item.insightType]
    let ids: any = item.insightCondition.toString()
    if (snippetsFilter?.attentionFlags && snippetsFilter?.attentionFlags[`${item.id},${typeLabel}`]) {
      let flags = { ...snippetsFilter.attentionFlags }
      delete flags[`${item.id},${typeLabel}`]
      setSnippetsFilter({ ...snippetsFilter, attentionFlags: flags })
    } else {
      if (item.insightType !== 'SEARCH_TERM') {
        ids = ids.replace('{', '')
        ids = ids.replace('}', '')
        ids = ids.split(',')
      }
      setSnippetsFilter({ ...snippetsFilter, attentionFlags: { [`${item.id},${typeLabel}`]: ids } })
      const data: { [x: string]: string | string[] } = {}
      data[typeLabel] = ids
    }
    fetchSnippets({ isSorting: true })
  }

  return (
    <div className='feed-actions-container'>
      <div className='feed-actions-container--inner'>
        <div className='feed-actions-container__list' ref={listRefCallback}>
          {attentionFlags.map((item) => (
            <div
              className={classNames(
                'feed-actions-container__list__item',
                {
                  'feed-actions-container__list__item--define': page === 'define',
                },
                {
                  'feed-actions-container__list__item--selected': selectedFlagsIds.includes(
                    `${item.id},${ATTENTION_FLAG_INSIGHT_TYPES[item.insightType]}`,
                  ),
                },
              )}
              key={item.id}
              onClick={() => filterData(item)}>
              <span
                className={classNames(
                  'feed-actions-container__list__item__title',
                  {
                    'feed-actions-container__list__item__title--define': page === 'define',
                  },
                  {
                    'feed-actions-container__list__item__title--selected': selectedFlagsIds.includes(
                      `${item.id},${ATTENTION_FLAG_INSIGHT_TYPES[item.insightType]}`,
                    ),
                  },
                )}>
                {item.displayLabel}
              </span>
            </div>
          ))}
        </div>

        {listRef && listRef.scrollWidth > listRef.clientWidth && (
          <RightArrowIcon
            className='feed-actions-container__list__forward-btn'
            onClick={() => {
              scrollHandler(25)
            }}
          />
        )}
      </div>
    </div>
  )
})
