import CustomModal from 'version2/components/common/CustomModal/CustomModal'
import './ExecutiveSummaryModal.scss'
import { ExecutiveSummary } from 'version2/components/MonitorVersion2/PowerInsightsVersion2/Summary/ExecutiveSummary/ExecutiveSummary'
import { Avatar } from 'antd'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { DetailsObjType } from 'store/monitorVersion2/types'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type PropsType = {
  setOpenExecutiveSummaryModal: (status: boolean) => void
  selectedBrand: DetailsObjType | null
}
const ExecutiveSummaryModal = (props: PropsType) => {
  const navigate = useNavigate()
  const { fetchExecutiveSummary, getSnippetFilterPayload, setPowerInsightsData, powerInsightsData } = store.assetsStore
  const { setOpenExecutiveSummaryModal, selectedBrand } = props

  const getExecutiveSummary = async () => {
    const payload = await getSnippetFilterPayload()
    fetchExecutiveSummary(payload).then((executiveSummary) => {
      setPowerInsightsData({
        ...powerInsightsData,
        summary: {
          ...powerInsightsData.summary,
          executiveSummary: { text: executiveSummary?.summary_text ?? '', response: executiveSummary?.response_code },
        },
      })
    })
  }

  useEffect(() => {
    getExecutiveSummary()
  }, [])

  const onViewDetails = () => {
    if (!selectedBrand) return
    const state = {
      name: selectedBrand.name,
      conditions: selectedBrand.conditions,
      mode: selectedBrand.mode,
      id: selectedBrand.id,
      alert_id: selectedBrand.alert_id,
    }

    navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
  }

  return (
    <CustomModal
      title='Executive Summary'
      firstButtonText='More details'
      secondButtonText='Back to dashboard'
      onClose={() => setOpenExecutiveSummaryModal(false)}
      onCancel={() => onViewDetails()}
      onConfirm={() => setOpenExecutiveSummaryModal(false)}
      icon={null}
      bodyClassName='executive-summary-body'>
      <div>
        <div className='brand'>
          <div className='brand-image'>
            <Avatar src={profileImage} />
          </div>
          <div className='brand-name'>{selectedBrand?.name}</div>
        </div>
        <ExecutiveSummary subStore='assets' canCopySummary />
      </div>
    </CustomModal>
  )
}
export default observer(ExecutiveSummaryModal)
