import { Dropdown, Menu, MenuProps, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { ReactComponent as FilterIcon } from 'assets/images/filterby-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icon-trash.svg'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'

import { IAttentionFlagInfo } from 'models/models'

import './AttentionFlagsList.scss'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  flags: any
  flagsInfo: IAttentionFlagInfo
  openForm: (state: boolean) => void
  setIsDeleteModalVisible: (state: string) => void
}

export const AttentionFlagsList = observer(({ flags, flagsInfo, openForm, setIsDeleteModalVisible }: Props) => {
  const { id: flagId } = useParams()
  const isEditMode = !!flagId
  const navigate = useNavigate()
  const logosOverlay = (count: number, ids: string[]) => {
    const total = {
      key: 1,
      label: (
        <div>
          <span className='attention-flags-list__dropdown__total-txt'>{'Total: ' + count}</span>
        </div>
      ),
    }
    const items: MenuProps['items'] = []
    items.push(total)
    ids.forEach((id: string) => {
      items.push({
        key: id,
        label: (
          <div className='attention-flags-list__dropdown'>
            <div
              className='attention-flags-list__dropdown__logos'
              style={{ backgroundImage: `url(${flagsInfo[id]?.logo_url})` }}></div>
            <span className='attention-flags-list__dropdown__title'>{flagsInfo[id]?.name}</span>
          </div>
        ),
      })
    })

    return <Menu items={items}></Menu>
  }

  const columns = [
    {
      title: 'Flag name',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: 'Metadata Selected',
      dataIndex: 'metadata',
      key: 'metadata',
      width: '25%',
    },
    {
      title: 'Applied to',
      dataIndex: 'applied',
      key: 'applied',
      render: (col: any) => {
        if (col === 'All Companies') return 'All Companies'
        else {
          return (
            <div>
              <Dropdown
                overlay={logosOverlay(col.length, col)}
                className={'attention-flags-list'}
                overlayClassName={'attention-flags-list'}>
                <div className='attention-flags-list__tenants-div__container'>
                  {col.slice(0, 6)?.map((el: string, index: number) => {
                    if (index === 5 && col.length - 6 <= 0) {
                      return <></>
                    }
                    return (
                      <div
                        className={classNames(
                          'attention-flags-list__tenants-div__logos',
                          `attention-flags-list__tenants-div__logos__overlap__${index}`,
                        )}
                        style={{ backgroundImage: index < 5 ? `url(${flagsInfo[el]?.logo_url})` : '' }}>
                        {index === 5 ? (
                          <span className='attention-flags-list__dropdown__number'>
                            {col.length - 6 > 0 ? `+${col.length - 6}` : ''}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  })}
                </div>
              </Dropdown>
            </div>
          )
        }
      },
      width: '25%',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (col: any) => {
        return (
          <div>
            <EditIcon
              className='attention-flags-list__margin'
              onClick={() => {
                return navigate('/settings/power-insights/' + col.id)
              }}
            />
            <DeleteIcon onClick={() => setIsDeleteModalVisible(col.id)} />
          </div>
        )
      },
      key: 'actions',
      width: '10%',
    },
  ]

  return (
    <div className='attention-flags-list__padding'>
      <div className='attention-flags-list__container'>
        <div className='attention-flags-list__container__title-div'>
          <label className='attention-flags-list__container__title-div__title'>Configure Attention Flags</label>
          <label className='attention-flags-list__container__title-div__subtitle'>
            Add new flag or configure the existing attention flags.
          </label>
        </div>
        <div className='attention-flags-list__container__btn-div'>
          <FilterIcon />
          <button
            className='attention-flags-list__container__btn-div__add-btn'
            onClick={() => {
              if (isEditMode) navigate('/settings/power-insights')
              else openForm(true)
            }}>
            + Add Flag
          </button>
        </div>
      </div>

      <Table columns={columns} dataSource={flags} className={'attention-flags-list'} />
    </div>
  )
})
