import axios from 'axios'

interface Props {
  entity_type: string
  entity_id: string
  user_ids: string[]
}

export const saveAlert = async (alert: Props) => {
  const data = await axios.post(`/alerts/save`, alert)
  return data
}
