import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, Input, Menu, Spin } from 'antd'
import { IUsers, IUsersResponse, ShareUser, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import ProfileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'

import './Share.scss'
import TagManager from 'react-gtm-module'
import ShareUserDropdown, {
  ShareUserDataType,
} from 'version2/components/Investigate/Reports/ShareUserDropdown/ShareUserDropdown'
import { store } from 'store'

interface Props {
  mode: monitorMode
  isLoading: boolean
  itemData: { type: 'narrative' | 'community' | 'channel' | 'tag' | 'spotlight'; id: string; name: string }
  users: IUsersResponse
  shareUsers: ShareUser[]
  loggedInUser: { userID: string | null; email: string | null; name: string }
  shareItem: ({
    users,
    permission,
    action,
  }: {
    users: string[]
    permission: 'viewer' | 'editor'
    action: 'share' | 'unshare'
  }) => Promise<any>
  setShowSharePage: (state: boolean) => void
}

const dropdownData = [
  { id: '0', option: 'can view', value: 'VIEWER' },
  { id: '1', option: 'can edit', value: 'EDITOR' },
]

export const Share = observer(
  ({ mode, isLoading, itemData, users, shareUsers, loggedInUser, shareItem, setShowSharePage }: Props) => {
    const navigate = useNavigate()
    const { usersStore, userStore } = store
    const { shareOwner, activeItem, fetchSharedUsers } = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    const { fetchUsers } = usersStore
    const { userId, userName, tenantId, roleId } = userStore
    const [inputValue, updateInputValue] = useState('')
    const [selectedUsers, setSelectedUsers] = useState<ShareUser[]>([])
    const [inputDropdownState, setInputDropdownState] = useState(false)
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [usersPage, setUsersPage] = useState<number>(2)

    const currentUser = users.items.filter((user: IUsers) => user.id === loggedInUser.userID)

    const cleanedUsers = users.items.filter((user: IUsers) => user.id !== loggedInUser.userID)

    const usersWithRoleIDS = shareUsers.map((user) => user.user_id)

    const usersWithNoRoles = cleanedUsers.filter((user) => {
      const userID = user.id
      return !usersWithRoleIDS.includes(userID)
    })

    const filteredUsers = usersWithNoRoles
      .filter((user: IUsers) => {
        const username = user.username?.toLowerCase()
        const realName = `${user.given_name} 
        ${user.family_name}`?.toLowerCase()
        return username.includes(inputValue.toLowerCase()) || realName.includes(inputValue.toLowerCase())
      })
      .slice(0, 4)

    let usersFiltered = !inputValue ? [...currentUser, ...usersWithNoRoles] : filteredUsers
    usersFiltered = usersFiltered.filter((user) => {
      return user.id !== loggedInUser.userID
    })

    const scrollHandler = (e: React.UIEvent<HTMLUListElement>): void => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget

      if (scrollTop + clientHeight === scrollHeight) {
        if (users.total_count !== users.items.length && !isFetching) {
          setIsFetching(true)
          fetchUsers(usersPage, 10, true).then((res: IUsersResponse | 'error') => {
            setIsFetching(false)
            if (res !== 'error' && [...users.items, ...res.items].length !== res.total_count) {
              setUsersPage((prevPage) => prevPage + 1)
            }
          })
        }
      }
    }

    const inputDropdownMenu = (
      <Menu className='share__users__input--dropdown' onScroll={scrollHandler}>
        {usersFiltered?.map((user: IUsers, index) => {
          const realName = `${user.given_name} 
      ${user.family_name}`
          return (
            <div
              onClick={() => {
                setSelectedUsers((users: ShareUser[]) => {
                  const userEmail = user.username
                  if (!users.some((usr) => usr.username === userEmail)) {
                    return [
                      ...users,
                      {
                        user_id: user.id,
                        username: user.email,
                        given_name: user.given_name,
                        family_name: user.family_name,
                        permission_level: 'VIEWER',
                      },
                    ]
                  } else return users.filter((usr) => usr.username !== userEmail)
                })
                setInputDropdownState(false)
              }}
              className='share__users__info-container'
              key={uuidv4()}>
              <div>
                <div className='share__users__details--name'>{realName}</div>
                <div className='share__users__details--email'>{user.username}</div>
              </div>
            </div>
          )
        })}
      </Menu>
    )

    const clickedUser: { id: string | null; role: string | null } = { id: null, role: null }

    const clickedRoleDropdown = (dropdown: 'viewer' | 'editor' | 'owner' | 'remove') => {
      if (clickedUser.role?.toLowerCase() === dropdown || !clickedUser.id) return

      switch (dropdown) {
        case 'viewer':
          return shareItem({ users: [clickedUser.id], action: 'share', permission: dropdown }).then((res) => {
            if (res !== 'error') {
              fetchSharedUsers(activeItem?.id || '')
            }
          })
        case 'editor':
          return shareItem({ users: [clickedUser.id], action: 'share', permission: dropdown }).then((res) => {
            if (res !== 'error') {
              fetchSharedUsers(activeItem?.id || '')
            }
          })
        case 'owner':
          return
        case 'remove':
          return shareItem({ users: [clickedUser.id], action: 'unshare', permission: 'viewer' })
      }
    }

    const removeSelectedUser = (email: string) =>
      setSelectedUsers((users) => users.filter((user) => user.username !== email))

    const onBackClick = () => {
      setShowSharePage(false)
      if (mode === 'bookmark') navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      else navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
    }

    const updateSelectedUsersPermission = ({
      selectedValue,
      selectedUser,
    }: {
      selectedValue: ShareUserDataType
      selectedUser: ShareUser
    }) => {
      setSelectedUsers((prevState: ShareUser[]) =>
        prevState.map((user) =>
          user.user_id === selectedUser.user_id ? { ...user, permission_level: selectedValue.value } : user,
        ),
      )
    }

    const onSaveClick = async () => {
      if (!selectedUsers.length) return

      const viewerIds = selectedUsers.filter((res) => res.permission_level === 'VIEWER').map((user) => user.user_id!)
      const editorIds = selectedUsers.filter((res) => res.permission_level === 'EDITOR').map((user) => user.user_id!)
      const permissionLookup: { edit: 'editor'; view: 'viewer' } = { edit: 'editor', view: 'viewer' }
      if (viewerIds.length && editorIds.length) {
        Promise.all([
          shareItem({ users: viewerIds, action: 'share', permission: permissionLookup['view'] }),
          shareItem({ users: editorIds, action: 'share', permission: permissionLookup['edit'] }),
        ])
      } else if (viewerIds.length) {
        shareItem({ users: viewerIds, action: 'share', permission: permissionLookup['view'] })
      } else {
        shareItem({ users: editorIds, action: 'share', permission: permissionLookup['edit'] })
      }

      // google analytics share item
      TagManager.dataLayer({
        dataLayer: {
          event: 'share',
          entityType: mode,
          entityName: itemData.name,
          user_id: userId,
          tenantId: tenantId,
          user_name: userName,
          roleId: roleId,
          sharedUsers: viewerIds ? viewerIds : editorIds,
        },
      })

      setShowSharePage(false)

      if (mode === 'bookmark') navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
      else navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${itemData?.id}`)
    }

    return (
      <div className='share'>
        <div className='share__main-body'>
          <div className='share__header'>
            <div className='share__header__back-btn' onClick={onBackClick}>
              <span>
                <BackBtnIcon />
              </span>
              <span className='share__header__back-btn--title'>Back to {`${mode}`}</span>
            </div>
            <h3 className='share__header__heading'>
              {`Share 
          ${mode[0].toUpperCase() + mode.substring(1)}`}
            </h3>
          </div>

          <div className='share__subtitle'>
            <span>Invite team members</span>
          </div>

          <div className='share__body'>
            <Spin spinning={isLoading}>
              <Dropdown
                trigger={['click']}
                overlay={inputDropdownMenu}
                open={inputDropdownState}
                onOpenChange={() => {
                  setInputDropdownState((state) => !state)
                }}>
                <Input
                  className='share__users__search'
                  placeholder={'Search by name or email'}
                  onChange={(el) => updateInputValue(el.target.value)}
                  value={inputValue}
                />
              </Dropdown>
              <div className='share__users__list'>
                <div className='share__users__info-container share__users__margin' key={uuidv4()}>
                  <div className='share__users__info-container___details--img'>
                    <img src={ProfileImage} alt='' />
                  </div>
                  <div>
                    <div className='share__users__info-container__details--name'>{shareOwner.given_name}</div>
                    <div className='share__users__info-container__details--email'>{shareOwner.username}</div>
                  </div>
                  <div className='share__users__info-container__details--role--owner'>Owner</div>
                </div>
                {shareUsers &&
                  shareUsers?.map((user) => {
                    let role = user?.permission_level?.toLowerCase() || 'Owner'
                    const findObj = dropdownData.find((res) => res.value === user.permission_level) || dropdownData[0]
                    return (
                      <div className='share__users__info-container' key={uuidv4()}>
                        <div className='share__users__info-container___details--img'>
                          <img src={ProfileImage} alt='' />
                        </div>
                        <div>
                          <div className='share__users__info-container__details--name'>{user.given_name}</div>
                          <div className='share__users__info-container__details--email'>{user.username}</div>
                        </div>
                        <div className='share__users__info-container__details--role' style={{ marginRight: '20px' }}>
                          {role !== 'Owner' ? (
                            <ShareUserDropdown
                              data={dropdownData}
                              closeWhenClick={true}
                              value={findObj.option}
                              selectedValue={findObj}
                              onSelect={(val: ShareUserDataType) => {
                                clickedUser.id = user.user_id
                                clickedUser.role = role
                                clickedRoleDropdown(val.value.toLowerCase() as 'viewer' | 'editor')
                              }}
                              onRemove={() => {
                                clickedUser.id = user.user_id
                                clickedUser.role = role
                                clickedRoleDropdown('remove')
                              }}
                            />
                          ) : (
                            role
                          )}
                        </div>
                      </div>
                    )
                  })}
                {selectedUsers &&
                  selectedUsers?.map((user) => {
                    const findObj = dropdownData.find((res) => res.value === user.permission_level) || dropdownData[0]
                    return (
                      <div className='share__users__info-container' key={uuidv4()}>
                        <div className='share__users__info-container___details--img'>
                          <img src={ProfileImage} alt='' />
                        </div>
                        <div>
                          <div className='share__users__info-container__details--name'>{user.given_name}</div>
                          <div className='share__users__info-container__details--email'>{user.username}</div>
                        </div>
                        <div className='share__users__info-container__details--role' style={{ marginRight: '20px' }}>
                          <ShareUserDropdown
                            data={dropdownData}
                            closeWhenClick={true}
                            value={findObj.option}
                            selectedValue={findObj}
                            onSelect={(val: ShareUserDataType) => {
                              updateSelectedUsersPermission({ selectedValue: val, selectedUser: user })
                            }}
                            onRemove={() => {
                              removeSelectedUser(user.username)
                            }}
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </Spin>
          </div>
        </div>

        <div className={'watchlistSidebarFooter'}>
          <Button className={'share__cancel-btn'} onClick={onBackClick}>
            Cancel
          </Button>
          <Button
            disabled={!selectedUsers.length}
            className={classNames('share__save-btn', { 'share__save-btn--primary': selectedUsers.length > 0 })}
            onClick={onSaveClick}>
            {mode === 'watchlist' ? 'Share Watchlist' : 'Share'}
          </Button>
        </div>
      </div>
    )
  },
)
