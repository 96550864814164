import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { useCallback, useEffect, useState } from 'react'

import PendulumLoader from 'version2/components/PendulumLoader/PendulumLoader'

import { store } from 'store'

import { SubStore } from 'types/types'

import './MonitorHeatMap.scss'
import { DetailsObjType } from 'store/monitorVersion2/types'
interface Props {
  subStore: SubStore
  selectMode?: boolean
  size?: 'small' | 'large'
  name?: string
  dataLoading?: boolean
  fromBrandDashboard?: boolean
}

export const MonitorHeatMap = observer(
  ({
    subStore,
    selectMode = false,
    dataLoading = false,
    size = 'small',
    name = 'Communities',
    fromBrandDashboard = false,
  }: Props) => {
    const {
      snippetsFilter,
      filterChips,
      fetchPowerInsights,
      setSnippetsFilter,
      communities,
      powerInsightsData,
      setFilterChips,
      activeItem,
      selectedCommunityInfo,
      fetchSnippetsInfo,
      setSelectedCommunityInfo,
      fetchPowerInsightsHeatMapData,
    } = store[`${subStore}Store`]

    const data = powerInsightsData.heatMapData

    let [colorArr, setColorArr] = useState<string[]>([])
    let [processedData, setProcessedData] = useState({
      name: name || 'Communities',
      color: '#cca55a',
      children: data?.data_points?.map((el) => {
        const prEl: any = el
        prEl.name = el.community_id
        return prEl
      }),
    })

    useEffect(() => {
      // brand dashboard manages this call itself
      if (fromBrandDashboard) return
      fetchPowerInsightsHeatMapData()
    }, [fromBrandDashboard])

    const onSelect = useCallback((community?: number) => {
      setSnippetsFilter({ ...snippetsFilter, community })
      fetchPowerInsights({ ...activeItem, activeTab: 'communities' } as DetailsObjType)
      fetchSnippetsInfo(activeItem!)
    }, [])

    useEffect(() => {
      let profisoryData: { name: string; color: string; children: any[] } = {
        name: name || 'Communities',
        color: '#cca55a',
        children: [],
      }
      data?.data_points?.forEach((point) => {
        let communityName: any = communities.filter((comm) => comm.id === parseInt(point.community_id))

        communityName =
          communityName.length > 0 ? (communityName[0].name ? communityName[0].name : '') : point.community_id

        profisoryData.children.push({ ...point, name: communityName })
      })

      setProcessedData(profisoryData)
    }, [data, communities])

    useEffect(() => {
      let color = '#cca55a'
      let provColorArr = []

      for (let i = 255; i > 41; i -= Math.round((255 - 41) / (data?.data_points?.length || 1))) {
        provColorArr.push(`${color}${i.toString(16)}`)
      }
      if (selectedCommunityInfo) {
        provColorArr = provColorArr.map((el, index) => {
          if (index !== selectedCommunityInfo.index) {
            let element = el
            let opacity = element.slice(element.length - 2, element.length)
            element = element.slice(0, element.length - 2)

            let opacityNum = parseInt(opacity, 16) - 128

            if (opacityNum <= 41) opacityNum = 41

            opacity = opacityNum.toString(16).split('.')[0]
            return `${element}${opacity}`
          }
          return '#cca55a'
        })
      }
      setColorArr(provColorArr)
    }, [data, selectedCommunityInfo, snippetsFilter.community, communities])

    return (
      <div className={classNames('c-monitor-heat-map', { 'large-heat-map': size === 'large' })}>
        {dataLoading ? (
          <PendulumLoader text='Pendulum is gathering the data' />
        ) : (
          <>
            <div className='section-header'>
              <div className='section-description'>
                {' '}
                Represent groups of creators that share topics or demographic attributes
              </div>
            </div>
            <div style={{ width: '100%', height: '200px' }}>
              <ResponsiveTreeMap
                onClick={(tile) => {
                  if (!selectMode) return

                  let clickedIndex: number = -1
                  let id: number = -1

                  if (!processedData?.children) return
                  processedData.children.forEach((el, index) => {
                    if (el?.name === tile.id) clickedIndex = index
                  })
                  clickedIndex = processedData.children.findIndex((el) => el.name === tile.id)

                  if (onSelect) {
                    if (selectedCommunityInfo?.index !== clickedIndex && clickedIndex >= 0) {
                      const community = processedData.children.filter((el) => el.name === tile.id)[0]
                      id = community.community_id
                      onSelect(community.community_id)
                    } else {
                      if (clickedIndex < 0) {
                        setFilterChips({
                          ...filterChips,
                          community: {
                            ...filterChips.community,
                            value: '',
                          },
                        })
                        setSelectedCommunityInfo(undefined)
                        return setSnippetsFilter({ ...snippetsFilter, community: undefined })
                      }
                      onSelect()
                    }
                  }
                  if (selectedCommunityInfo?.index !== clickedIndex) {
                    setFilterChips({
                      ...filterChips,
                      community: {
                        ...filterChips.community,
                        value: tile.id,
                      },
                    })
                    setSelectedCommunityInfo({ index: clickedIndex, name: tile.id, id: id.toString() })
                    setSnippetsFilter({ ...snippetsFilter, community: id })
                  } else {
                    setFilterChips({
                      ...filterChips,
                      community: {
                        ...filterChips.community,
                        value: '',
                      },
                    })
                    setSelectedCommunityInfo(undefined)
                    setSnippetsFilter({ ...snippetsFilter, community: undefined })
                  }
                }}
                colors={colorArr}
                data={processedData}
                tile='binary'
                identity='name'
                value='metric_value'
                labelSkipSize={55}
                leavesOnly={true}
                innerPadding={5}
                label='id'
                orientLabel={true}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1000]],
                }}
                parentLabelPosition='left'
                nodeOpacity={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0]],
                }}
              />
            </div>
          </>
        )}
      </div>
    )
  },
)
