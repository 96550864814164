import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import dayjs from 'dayjs'
import classNames from 'classnames'

import { get } from 'api/get.api'

import { ReactComponent as RightArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import UserAvatar from 'assets/images/LogoiconMobile.svg'
import YoutubeIcon from 'assets/images/youtube-icon.svg'
import TwitterIcon from 'assets/images/twitter-x-icon-current-color.svg'
import PodcastIcon from 'assets/images/podcast-icon.svg'
import RumbleIcon from 'assets/images/rumble-icon.svg'
import BitchuteIcon from 'assets/images/bitchute-icon.svg'

import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'

import './NarrativeInfoModal.scss'

type Narrative = {
  alert_id: string
  boolean_query: string
  communities: any
  created: string
  description: string
  id: string
  is_pendulum_narrative: string
  last_update_time: string
  last_updated_by: string
  multi_lang_kwd_expression: any
  name: string
  narrative_number: number
  narrative_type: 'KEYWORD_ONLY' | 'TEXTUAL' | 'NLP'
  owner_id: string
  parent_narratives: string[]
  permission_level: string
  related_links: string[]
  tags: string[]
  tenant_id: string
}

type TagProps = { tags: string[] }

type CreatorProps = {
  name: string
  creationDate: string
  avatar: string
}

type NarrativeKeywordInfoProps = {
  type?: 'KEYWORD_ONLY' | 'TEXTUAL' | 'NLP'
  booleanQuery: string
  languages: { [x: string]: string }
}

interface NarrativeSavedLinksProps {
  url: string
}

interface Props {
  onClose: (state: boolean) => void
}

export const NarrativeInfoModal = observer(({ onClose }: Props) => {
  const [activeNarrative, setActiveNarrative] = useState<Narrative | null>(null)
  const { vectorsStore, usersStore } = store
  const { activeItem } = vectorsStore
  const { users, getUser, fetchUsers } = usersStore

  useEffectOnce(() => {
    get({ route: 'narrative', id: activeItem?.id })
      .then((response) => {
        setActiveNarrative(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  })

  useEffectOnce(() => {
    try {
      if (!users.items?.length) {
        fetchUsers()
      }
    } catch (error) {
      console.log(error)
    }
  })

  return (
    <div className='narrative-info-modal'>
      <div className='narrative-info-modal__header'>
        <span className='narrative-info-modal__title'>Narrative Details</span>
        <CloseIcon
          onClick={() => {
            onClose(false)
          }}
        />
      </div>
      <div className='narrative-info-modal__body'>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Narrative ID</span>
          <span className='narrative-info-modal__body__info-text'>{activeItem?.id || 'N/A'}</span>
        </div>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Narrative Number</span>
          <span className='narrative-info-modal__body__info-text'>{activeNarrative?.narrative_number || 'N/A'}</span>
        </div>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Description</span>
          <span className='narrative-info-modal__body__info-text'>{activeNarrative?.description || 'N/A'}</span>
        </div>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Tags</span>
          {(activeNarrative?.tags?.length ?? 0) > 0 ? <TagsTile tags={activeNarrative?.tags || []} /> : 'N/A'}
        </div>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Creator</span>
          <CreatorInfo
            name={getUser(activeItem?.ownerId || '')}
            creationDate={activeNarrative?.created || ''}
            avatar={UserAvatar}
          />
        </div>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Keyword Search</span>
          <NarrativeKeywordInfo
            type={activeNarrative?.narrative_type}
            booleanQuery={activeNarrative?.boolean_query || ''}
            languages={activeNarrative?.multi_lang_kwd_expression || {}}
          />
        </div>
        <div className='narrative-info-modal__body__inner-container'>
          <span className='narrative-info-modal__body__sub-title'>Saved links</span>
          {activeNarrative?.related_links?.length ? (
            activeNarrative?.related_links.map((item) => <NarrativeSavedLinks url={item} />)
          ) : (
            <span className='narrative-info-modal__body__info-text'>No saved links available</span>
          )}
        </div>
      </div>
    </div>
  )
})

const TagsTile = (props: TagProps) => {
  const { tags } = props

  return (
    <div className='narrative-info-modal__body__tags__container'>
      {tags?.map((element) => (
        <span
          key={element}
          className={'narrative-info-modal__body__tags__tile--color' + Math.floor(Math.random() * (0 - 4 + 1) + 4)}>
          {element}
        </span>
      ))}
    </div>
  )
}

const CreatorInfo = (props: CreatorProps) => {
  const { name, creationDate, avatar } = props
  return (
    <div className='narrative-creator-info'>
      {<img className='narrative-creator-info__avatar' src={avatar} alt='user-mg' />}
      <div className='narrative-creator-info__container'>
        <span className='narrative-creator-info__name'>{name || ''}</span>
        <span className='narrative-creator-info__date'>{dayjs(creationDate).format('DD/MM/YYYY') || ''}</span>
      </div>
    </div>
  )
}

const NarrativeKeywordInfo = (props: NarrativeKeywordInfoProps) => {
  const { type, booleanQuery, languages } = props

  const keys = Object.keys(languages || {})

  const [keywordSearchExpression, setKeywordSearchExpression] = useState(booleanQuery)
  const [selectedLanguage, setSelectedLanguage] = useState<string>('Default')
  const [languageRef, setLanguageRef] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setKeywordSearchExpression(booleanQuery)
  }, [booleanQuery])

  const scrollHandler = (scrollOffset: number) => {
    if (languageRef) {
      const currentScroll = languageRef.scrollLeft
      const maxScroll = languageRef.scrollWidth - languageRef.clientWidth

      if (currentScroll < maxScroll) {
        languageRef.scrollLeft += scrollOffset
      } else {
        languageRef.scrollLeft = 0
      }
    }
  }

  const selectLanguageTab = async (key: any) => {
    if (languages && keys.length > 0) {
      key === 'Default'
        ? setKeywordSearchExpression(booleanQuery.replace(/(AND|OR)/gi, '<b>$1</b>' || ''))
        : setKeywordSearchExpression(languages[key])
      setSelectedLanguage(key)
    }
  }

  const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
    setLanguageRef(node)
  }, [])

  return (
    <>
      {booleanQuery.length > 0 && keys.length > 0 ? (
        <div className='narrative-keyword-info'>
          <div className=' narrative-keyword-info__nav-container'>
            <nav className='narrative-keyword-info__languagesBar' ref={listRefCallback}>
              {['Default', ...keys].map((language) => {
                return (
                  <button
                    className={classNames('narrative-keyword-info__title', {
                      'narrative-keyword-info__title--active': selectedLanguage === language,
                    })}
                    key={language}
                    onClick={() => selectLanguageTab(language)}>
                    {language === 'Default' ? 'Default' : SUPPORTED_LANGUAGES_CODE[language]}
                  </button>
                )
              })}
            </nav>

            <RightArrowIcon
              onClick={() => {
                scrollHandler(25)
              }}
            />
          </div>
          {type === 'NLP' ? (
            <div className='narrative-keyword-info__text--ML'>
              <span>
                This Narrative uses a custom developed and trained Machine Learning model to detect the content that
                best represents this narrative. Machine Learning narratives eliminate the need to come up with and
                refine keyword searches, as well as dramatically reducing noise in search results.
              </span>
            </div>
          ) : (
            <div className='narrative-keyword-info__text--others'>
              <span
                dangerouslySetInnerHTML={{
                  __html: keywordSearchExpression,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        'N/A'
      )}
    </>
  )
}

const NarrativeSavedLinks = (props: NarrativeSavedLinksProps) => {
  const { url } = props
  const urlLink = url.length > 40 ? `${url.slice(0, 40)}...` : url

  const iconsObj = {
    YouTube: YoutubeIcon,
    Twitter: TwitterIcon,
    Podcast: PodcastIcon,
    Rumble: RumbleIcon,
    BitChute: BitchuteIcon,
  }

  //@ts-ignore
  const image = iconsObj['Twitter']

  return (
    <div className='narrative-saved-links'>
      <img className='narrative-saved-links__image' alt={'link-img'} src={image} />
      <a className='narrative-saved-links__text' href={url} target='_blank' rel='noreferrer'>
        <p className='narrative-saved-links__text-title'>{urlLink}</p>
        <p className='narrative-saved-links__text-website'>{urlLink}</p>
        <p className='narrative-saved-links__text-website-link'>{urlLink}</p>
      </a>
    </div>
  )
}
