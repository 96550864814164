import axios from 'axios'

interface Props {
  action: 'add' | 'remove'
  alertId?: string
  userIds: string[]
}

export const editAlert = async (alert: Props) => {
  const data = await axios.post(`/alerts/${alert.alertId}/update`, { action: alert.action, user_ids: alert.userIds })
  return data
}
