import Input from 'version2/components/common/Inputs/Input/Input'
import Dropdown, { DropdownOption } from 'version2/components/common/Inputs/Dropdown/Dropdown'
import TextArea from 'version2/components/common/Inputs/TextArea/TextArea'
import { ReactComponent as ImpactLevelIcon } from 'assets/images/icons/flag/impact_level_icon.svg'
import { FlagCategoryType, FlagImpactLevelType } from 'store/flags/types'
import './CreateFlagModalStep1.scss'
import { useMemo } from 'react'

export function transformToDropdownOption(data: FlagCategoryType[]): DropdownOption[] {
  return data.map((item) => ({
    id: item.category_id,
    label: item.name,
  }))
}

type ImpactLevelsObjType = {
  id: string
  title: string
  desc: string
  impact_level: FlagImpactLevelType
}

const impactLevels: ImpactLevelsObjType[] = [
  {
    id: '0',
    title: 'High impact',
    desc: 'Reserved for critical threats that demand immediate attention.',
    impact_level: 'HIGH',
  },
  {
    id: '1',
    title: 'Medium impact',
    desc: 'Applied to threats of moderate significance, like potential issues that could affect operations or reputation.',
    impact_level: 'MEDIUM',
  },
  {
    id: '2',
    title: 'Low impact',
    desc: 'For less urgent threats with minimal immediate consequences.',
    impact_level: 'LOW',
  },
]

type CreateFlagModalStep1Props = {
  flagsCategorties: FlagCategoryType[]
  flagName: string
  setFlagName: (name: string) => void
  flagCategory: DropdownOption
  setFlagCategory: (category: DropdownOption) => void
  flagDesc: string
  setFlagDesc: (name: string) => void
  selectedImpactLevel: FlagImpactLevelType
  setSelectedImpactLevel: (type: FlagImpactLevelType) => void
}

const CreateFlagModalStep1 = (props: CreateFlagModalStep1Props) => {
  const {
    flagsCategorties,
    flagName,
    setFlagName,
    flagDesc,
    setFlagDesc,
    flagCategory,
    setFlagCategory,
    selectedImpactLevel,
    setSelectedImpactLevel,
  } = props

  const categories = useMemo(() => {
    return transformToDropdownOption(flagsCategorties)
  }, [flagsCategorties])

  return (
    <div className='create_flag_modal_step1_container'>
      <Input
        title='Flag name'
        placeholder='Enter a flag name'
        value={flagName}
        onChange={(e) => setFlagName(e.target.value)}
      />
      <Dropdown
        data={categories}
        title='Select flag category'
        placeholder='Select flag category'
        value={flagCategory.label}
        onSelect={(option) => setFlagCategory(option)}
      />
      <TextArea
        title='Description'
        placeholder='Enter a description...'
        value={flagDesc}
        onChange={(e) => setFlagDesc(e.target.value)}
      />
      <div className='cfmsc_impact_container'>
        <p className='cfmsc_ic_text'>Select impact level:</p>
        <div className='cfmsc_ic_chunk_container'>
          {impactLevels.map((impact) => (
            <div
              key={impact.id}
              className={`cfmsc_ic_chunk${impact.impact_level === selectedImpactLevel ? ' cfmsc_ic_chunk_active' : ''}`}
              onClick={(e) => {
                e.stopPropagation()
                setSelectedImpactLevel(impact.impact_level)
              }}>
              <ImpactLevelIcon
                height={48}
                width={48}
                className={`cfmsc_ic_c_icon cfmsc_ic_c_${impact.impact_level.toLowerCase()}_icon`}
              />
              <div className='cfmsc_ic_c_text_container'>
                <p className='cfmsc_ic_c_tc_title'>{impact.title}</p>
                <p className='cfmsc_ic_c_tc_desc'>{impact.desc}</p>
              </div>
              <div className='cfmsc_ic_c_radio_button'></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CreateFlagModalStep1
