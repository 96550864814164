import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Tooltip,
  Label,
  Legend,
  CartesianGrid,
} from 'recharts'
import { useCallback, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { store } from 'store'
import millify from 'millify'
import { useEffectOnce } from 'react-use'
import { Spin } from 'antd'
import classNames from 'classnames'

import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/cross_black.svg'
import { ReactComponent as SummaryIcon } from 'assets/images/anticipatory-summary-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as SolidLikeIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as SolidDislikeIcon } from 'assets/images/dislike-thumb-solid-icon.svg'

import {
  IAttentionFlag,
  IElectionIssues,
  IElectionIssuesCategories,
  IElectionRaces,
  IRisks,
  IThreatTopicCategories,
} from 'models/models'

import './DetailsSection.scss'
import { containsHTML } from 'version2/utils/helper'

export const DetailsSection = observer(() => {
  const search = useLocation().search
  const COLORS = ['#EBDBBD', '#E0C99C', '#F5EDDE', '#D6B77B']
  const navigate = useNavigate()

  const [issueOrTopicCategory, setIssueOrTopicCategory] = useState<IThreatTopicCategories | IElectionIssuesCategories>()
  const [chartsActiveIndex, setChartsActiveIndex] = useState({ bar: -1, pie: -1 })
  const [chartsFillColor, setChartsFillColor] = useState('#CCA55A')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isExecutiveSummaryTooLong, setIsExecutiveSummaryTooLong] = useState(false)
  const [showFullExecutiveSummary, setShowFullExecutiveSummary] = useState(true)
  const [isSummaryLiked, setIsSummaryLiked] = useState<'like' | 'dislike' | 'none'>('none')
  const [likeDislikeTransition, setLikeDislikeTransition] = useState('alse')
  const [subItemName, setSubItemName] = useState('')

  const { anticipatoryIntelligenceStore, loaderStore, userStore } = store
  const {
    tenantInfo,
    threatTopics,
    risks,
    attentionFlags,
    electionIssues,
    electionRaces,
    riskAttentionFlagData,
    riskThreatDataListView,
    attentionFlagsThreatDataListView,
    electionRacesData,
    electionAttentionFlagsData,
    electionRacesAttentionFlagData,
    riskThreatDataListViewCheck,
    attentionFlagsThreatDataListViewCheck,
    electionRacesDataCheck,
    electionAttentionFlagsDataCheck,
    activeThreatNarrative,
    activeThreatNarrativeAdvancedData,
    activeThreatNarrativeConditions,
    isSideDetailsOpen,
    sideDetails,
    setUserTenant,
    setIsSideDetailsOpen,
    setSideDetails,
    setActiveThreatNarrative,
    setActiveThreatNarrativeConditions,
    fetchTenantInfo,
    fetchThreatTopics,
    fetchRisks,
    fetchAttentionFlags,
    fetchElectionIssues,
    fetchElectionRaces,
    fetchRisksDetailsListView,
    fetchAttentionFlagsDetailsListView,
    fetchRiskAttentionFlagsDetails,
    fetchIssuesRacesDetails,
    fetchIssuesAttentionFlagsDetails,
    fetchRacesAttentionFlagsDetails,
    fetchChartData,
    fetchSummary,
    resetAnticipatoryStore,
  } = anticipatoryIntelligenceStore

  const {
    isLoadingElection,
    isLoadingElectionRaces,
    isLoadingThreatTopics,
    isLoadingRisks,
    isLoadingAttentionFlags,
    isLoadingNoOfPosts,
    isLoadinFeed,
    isLoadingStats,
    isLoadingSummary,
    isLoadingPlatformChart,
    isLoadingTopicChart,
  } = loaderStore

  const { userInfo, getUserInfo } = userStore

  const { name } = useParams()
  let categoryName = ''
  let tabName = ''
  let issueOrTopicName = ''

  if (isSideDetailsOpen.length > 0) {
    categoryName = sideDetails?.category || ''
    tabName = sideDetails?.tab || ''
    issueOrTopicName = sideDetails?.topic
  } else {
    categoryName = new URLSearchParams(search).get('category') || ''
    tabName = new URLSearchParams(search).get('tab') || ''
    issueOrTopicName = name || ''
  }

  let subItem = ''
  if (isSideDetailsOpen.length > 0) {
    subItem = sideDetails?.riskOrFlagName
  } else {
    switch (tabName) {
      case 'close-range':
        subItem = decodeURIComponent(new URLSearchParams(search).get('attention-flag') || '')
        break
      case 'mid-range':
        subItem = decodeURIComponent(new URLSearchParams(search).get('risk') || '')
        break
      case 'company':
        subItem = decodeURIComponent(new URLSearchParams(search).get('company') || '')
        break
      case 'races':
        subItem = decodeURIComponent(new URLSearchParams(search).get('race') || '')
        break
    }
  }

  let midCloseItem = ''
  if (tabName === 'mid-close-range') {
    if (isSideDetailsOpen.length > 0) {
      midCloseItem = sideDetails?.riskOrFlagName
    } else
      midCloseItem =
        `${new URLSearchParams(search).get('attention-flag') || ''},` + new URLSearchParams(search).get('risk')
  } else if (tabName === 'race-company-range') {
    if (isSideDetailsOpen.length > 0) {
      midCloseItem = sideDetails?.riskOrFlagName
    } else
      midCloseItem = `${new URLSearchParams(search).get('company') || ''},` + new URLSearchParams(search).get('race')
  }

  useEffectOnce(() => {
    if (!userInfo.tenant_id.length) getUserInfo()
  })

  useEffect(() => {
    setUserTenant(userInfo.tenant_id || '')
    if (userInfo && userInfo?.tenant_id && !tenantInfo?.name?.length) fetchTenantInfo(userInfo.tenant_id)
  }, [userInfo.tenant_id])

  useEffectOnce(() => {
    if (isSideDetailsOpen.length === 0) {
      fetchDataIfNeeded(threatTopics, fetchThreatTopics)
      fetchDataIfNeeded(risks, fetchRisks)
      fetchDataIfNeeded(attentionFlags, fetchAttentionFlags)
      fetchDataIfNeeded(electionIssues, fetchElectionIssues)
      fetchDataIfNeeded(electionRaces, fetchElectionRaces)
    }
  })

  useEffect(() => {
    if (
      (tabName === 'close-range' &&
        Object.keys(attentionFlags).length > 0 &&
        Object.keys(attentionFlagsThreatDataListView)?.length === 0) ||
      (tabName === 'mid-range' && Object.keys(risks).length > 0 && Object.keys(riskThreatDataListView)?.length === 0) ||
      (tabName === 'mid-close-range' && Object.keys(riskAttentionFlagData)?.length === 0) ||
      (tabName === 'company' &&
        Object.keys(attentionFlags).length > 0 &&
        Object.keys(electionAttentionFlagsData)?.length === 0) ||
      (tabName === 'races' && Object.keys(electionRaces).length > 0 && Object.keys(electionRacesData)?.length === 0) ||
      (tabName === 'race-company-range' && Object.keys(electionRacesAttentionFlagData)?.length === 0)
    ) {
      switch (tabName) {
        case 'close-range':
          fetchAttentionFlagsDetailsListView(true)
          break
        case 'mid-range':
          fetchRisksDetailsListView(true)
          break
        case 'mid-close-range':
          fetchRiskAttentionFlagsDetails(true)
          break
        case 'company':
          fetchIssuesAttentionFlagsDetails(true)
          break
        case 'races':
          fetchIssuesRacesDetails(true)
          break
        case 'race-company-range':
          fetchRacesAttentionFlagsDetails(true)
          break
        default:
          break
      }
    }
  })

  useEffect(() => {
    const tabDataMapping: { [x: string]: any } = {
      general: threatTopics,
      'mid-range': riskThreatDataListView,
      'close-range': attentionFlagsThreatDataListView,
      'mid-close-range': riskAttentionFlagData,
      races: electionRacesData,
      company: electionAttentionFlagsData,
      'race-company-range': electionRacesAttentionFlagData,
    }

    const data = tabName === 'races' || tabName === 'company' ? electionIssues : threatTopics
    const selectedCategory = data[issueOrTopicName]?.categories.find((el) => el.name === categoryName)
    setIssueOrTopicCategory(selectedCategory)

    const tabData = tabDataMapping[tabName]

    if (tabData) {
      if (tabName === 'general' && Object.keys(threatTopics)?.length > 0) {
        let subCategory = threatTopics[issueOrTopicName]?.categories.find((el) => el.name === categoryName) || {
          description: '',
          narrative_number: 0,
          narrative_id: '',
          flags: {
            is_forcasting_active: false,
            is_language_risk_active: false,
            is_mainstream_coverage_active: false,
            is_narrative_risk_active: false,
            is_negative_sentiment_active: false,
          },
        }
        setActiveThreatNarrative(subCategory)
        setActiveThreatNarrativeConditions([{ narratives: [subCategory?.narrative_number] }])
      } else if (tabName === 'mid-close-range' || tabName === 'race-company-range') {
        const [attentionFlagName, riskName] = midCloseItem.split(',')
        const subCategory = tabData?.[attentionFlagName]?.[riskName]
        if (subCategory) {
          setActiveThreatNarrative(subCategory)
          setActiveThreatNarrativeConditions(subCategory?.conditions || [{ narratives: '' }])
        }
      } else {
        const subCategory = tabData?.[issueOrTopicName]?.[categoryName]?.[subItem]
        if (subCategory) {
          setActiveThreatNarrative(subCategory)
          setActiveThreatNarrativeConditions(subCategory?.conditions || [{ narratives: '' }])
        }
      }
    }
  }, [
    attentionFlagsThreatDataListViewCheck,
    riskThreatDataListViewCheck,
    threatTopics,
    isSideDetailsOpen,
    electionAttentionFlagsDataCheck,
    electionRacesDataCheck,
    electionIssues,
    Object.values(riskAttentionFlagData),
    Object.values(electionRacesAttentionFlagData),
  ])

  useEffect(() => {
    if (
      isSideDetailsOpen.length !== 0 &&
      activeThreatNarrativeConditions.length > 0 &&
      !isLoadingPlatformChart &&
      !isLoadingTopicChart
    ) {
      fetchChartData()
      fetchSummary()
    }
  }, [activeThreatNarrative, activeThreatNarrativeConditions])

  useEffect(() => {
    if (tabName === 'mid-close-range') {
      const attentionFlagName = midCloseItem.split(',')[0]
      const riskName = midCloseItem.split(',')[1]
      setSubItemName(`${risks[riskName]?.label},${attentionFlags[attentionFlagName]?.displayLabel}`)
    } else if (tabName === 'race-company-range') {
      const companyName = midCloseItem.split(',')[0]
      const raceName = midCloseItem.split(',')[1]
      setSubItemName(`${electionRaces[raceName]?.label},${attentionFlags[companyName]?.displayLabel}`)
    } else {
      if (subItem.length) {
        let name = ''
        switch (tabName) {
          case 'close-range':
            name =
              isSideDetailsOpen.length === 0
                ? attentionFlags[subItem]?.displayLabel
                : attentionFlags[sideDetails?.riskOrFlagName || '']?.displayLabel
            break
          case 'mid-range':
            name =
              isSideDetailsOpen.length === 0 ? risks[subItem]?.label : risks[sideDetails?.riskOrFlagName || '']?.label
            break
          case 'company':
            name =
              isSideDetailsOpen.length === 0
                ? attentionFlags[subItem]?.displayLabel
                : attentionFlags[sideDetails?.riskOrFlagName || '']?.displayLabel
            break
          case 'races':
            name =
              isSideDetailsOpen.length === 0
                ? electionRaces[subItem]?.label
                : electionRaces[sideDetails?.riskOrFlagName || '']?.label
            break
        }
        setSubItemName(name)
      }
    }
  }, [risks, attentionFlags, electionRaces, attentionFlags, activeThreatNarrative])

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='details-section__bar-chart__tooltip'>
          <span className='details-section__bar-chart__tooltip__label'>{`${millify(payload[0].value)} Posts`}</span>
        </div>
      )
    }
    return null
  }

  const fetchDataIfNeeded = (
    data: IRisks | IAttentionFlag | IElectionIssues | IElectionRaces,
    fetchDataFunction: (isDetailSection: boolean) => Promise<void>,
  ) => {
    if (!Object.keys(data)?.length) fetchDataFunction(true)
  }

  const renderLegend = ({ payload: item }: any) => {
    if (Object.keys(item).length > 0) {
      return (
        <ul className='details-section__pie-chart__list'>
          {item.map((entry: any, index: number) => (
            <li
              key={`item-${index}`}
              onMouseEnter={() => {
                onPieChartMouseEnter({}, index)
              }}
              onMouseOut={() => {
                onPieChartMouseOut({}, index)
              }}>
              <div className='details-section__pie-chart__legend'>
                <div
                  className='details-section__pie-chart__legend__list'
                  style={{ background: entry.payload.fill }}></div>
                <span className='details-section__pie-chart__legend__labels details-section__pie-chart__legend__margin'>
                  {entry.payload.platform_name}
                </span>
                <span className='details-section__pie-chart__legend__labels'>{entry.payload.metric_value}</span>
              </div>
            </li>
          ))}
        </ul>
      )
    } else return <ul></ul>
  }

  const onBarChartMouseEnter = (data: any, index: number) => {
    setChartsActiveIndex({ ...chartsActiveIndex, bar: index })

    setChartsFillColor(selectedIndex === index ? '#EBDBBD' : '#CCA55A')
  }
  const onBarChartMouseOut = (data: any, index: number) => {
    setChartsActiveIndex({ ...chartsActiveIndex, bar: -1 })
    setChartsFillColor('#EBDBBD')
  }

  const onPieChartMouseEnter = (data: any, index: number) => {
    setChartsActiveIndex({ ...chartsActiveIndex, pie: index })

    setChartsFillColor(selectedIndex === index ? '#EBDBBD' : '#CCA55A')
  }
  const onPieChartMouseOut = (data: any, index: number) => {
    setChartsActiveIndex({ ...chartsActiveIndex, pie: -1 })
    setChartsFillColor('#EBDBBD')
  }

  const filterSnippetData = (data: any, index: number) => {
    setChartsActiveIndex({ ...chartsActiveIndex, bar: index })
    setChartsFillColor('#F5EDDE')
    setSelectedIndex(index)
  }

  const onClickFeedView = (title: string) => {
    setIsSideDetailsOpen('')
    setSideDetails({
      topic: '',
      category: '',
      riskOrFlagName: '',
      tab: '',
      url: '',
    })
    const state = {
      name: title || '',
      conditions: activeThreatNarrative?.conditions,
      mode: '',
    }
    setActiveThreatNarrative({ ...activeThreatNarrative, conditions: activeThreatNarrative?.conditions })
    navigate('details', { state: JSON.parse(JSON.stringify(state)) })
  }

  const onClickClose = () => {
    setIsSideDetailsOpen('')
    setSideDetails({
      topic: '',
      category: '',
      riskOrFlagName: '',
      tab: '',
      url: '',
    })
  }

  const toggleFullExecutiveSummaryHandler = useCallback(() => {
    if (!isExecutiveSummaryTooLong) {
      return
    }

    setShowFullExecutiveSummary((prevShow) => !prevShow)
  }, [isExecutiveSummaryTooLong])

  useEffect(() => {
    if (activeThreatNarrativeAdvancedData.summaryHints.length > 500) {
      setIsExecutiveSummaryTooLong(true)
    } else {
      setIsExecutiveSummaryTooLong(false)
    }
  }, [activeThreatNarrativeAdvancedData.summaryHints])

  const renderSummary = () => {
    const content =
      isExecutiveSummaryTooLong && !showFullExecutiveSummary
        ? activeThreatNarrativeAdvancedData.summaryHints.slice(0, 500) + '...'
        : activeThreatNarrativeAdvancedData.summaryHints

    return (
      <div className='summary-text'>
        {containsHTML(content) ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <ReactMarkdown>{content}</ReactMarkdown>
        )}
        {isExecutiveSummaryTooLong && (
          <span className='details-section__description--more-btn' onClick={toggleFullExecutiveSummaryHandler}>
            &nbsp;Read {showFullExecutiveSummary ? 'less' : 'more'}
          </span>
        )}
      </div>
    )
  }

  const thumbsUpFeedback = (valueForFeedback: 'like' | 'dislike') => {
    setIsSummaryLiked(valueForFeedback)
    setLikeDislikeTransition('details-section__summary__right__like-dislike--active')
    setTimeout(() => {
      setLikeDislikeTransition('')
    }, 500)
  }

  return (
    <Spin
      spinning={
        isLoadingElection ||
        isLoadingElectionRaces ||
        isLoadingThreatTopics ||
        isLoadingRisks ||
        isLoadingAttentionFlags ||
        isLoadingNoOfPosts ||
        isLoadinFeed ||
        isLoadingStats
      }>
      <div
        className={classNames('details-section', {
          'details-section__fixed-position': isSideDetailsOpen.length > 0,
        })}>
        {isSideDetailsOpen.length === 0 && (
          <div
            className='details-section__back-btn'
            onClick={() => {
              resetAnticipatoryStore()
              navigate(`/anticipatory-intelligence/scoreboard`)
            }}>
            <span>
              <BackBtnIcon />
            </span>
            <span className='details-section__back-btn--title'>Back</span>
          </div>
        )}
        {isSideDetailsOpen.length > 0 && <CloseIcon className='details-section__close-icon' onClick={onClickClose} />}
        <div className='details-section__header-div'>
          <div className='details-section__title'>
            <span>
              {tabName === 'mid-close-range' || tabName === 'race-company-range'
                ? subItemName?.replace(',', ' ∞ ')
                : (issueOrTopicCategory?.label || '') + (subItemName?.length > 0 ? ` ∞ ${subItemName}` : '')}
            </span>
          </div>
          {isSideDetailsOpen.length > 0 && (
            <button
              className='details-section__view-feed-btn'
              onClick={() => {
                onClickFeedView(
                  tabName === 'mid-close-range' || tabName === 'race-company-range'
                    ? subItemName?.replace(',', ' ∞ ')
                    : (issueOrTopicCategory?.label || '') + (subItemName?.length > 0 ? ` ∞ ${subItemName}` : ''),
                )
              }}>
              View Feed
            </button>
          )}
        </div>

        {(activeThreatNarrativeAdvancedData.summaryHints?.length > 0 || isLoadingSummary) && (
          <>
            <div className='details-section__summary'>
              <div className='details-section__summary__title-div'>
                <span className='details-section__summary__title'>Executive Summary</span>
                <SummaryIcon className='details-section__summary__icon' />
              </div>
              <button className='details-section__summary__right__like-dislike-btn'>
                <div className={'details-section__summary__right__like-dislike'}>
                  {isSummaryLiked === 'like' ? (
                    <SolidLikeIcon
                      className={likeDislikeTransition}
                      onClick={() => {
                        thumbsUpFeedback('like')
                      }}
                    />
                  ) : (
                    <LikeIcon
                      className='ds_s_r_ld_icon'
                      onClick={() => {
                        thumbsUpFeedback('like')
                      }}
                    />
                  )}
                  {isSummaryLiked === 'dislike' ? (
                    <SolidDislikeIcon
                      className={likeDislikeTransition}
                      onClick={() => {
                        thumbsUpFeedback('dislike')
                      }}
                    />
                  ) : (
                    <DislikeIcon
                      className='ds_s_r_ld_icon'
                      onClick={() => {
                        thumbsUpFeedback('dislike')
                      }}
                    />
                  )}
                </div>
              </button>
            </div>
            {isLoadingSummary ? (
              <div className='details-section__loading'>
                <Spin spinning />
                <span className='details-section__loading__wait-text'>{'Please Wait ...'}</span>
                <span className='details-section__loading__description'>
                  {'Pendulum Co-Pilot is generating your summary.'}
                </span>
              </div>
            ) : (
              renderSummary()
            )}
          </>
        )}
        {isSideDetailsOpen.length === 0 && activeThreatNarrativeAdvancedData.topicChart.length > 0 && (
          <div>
            <div className='details-section__topics'>
              <span className='details-section__topics__title'>Emerging Topics</span>
            </div>

            <div className='bar-chart-wrapper'>
              <BarChart
                width={450}
                height={
                  (activeThreatNarrativeAdvancedData.topicChart.length || 1) * 30 > 200
                    ? (activeThreatNarrativeAdvancedData.topicChart.length || 1) * 30
                    : 200
                }
                data={activeThreatNarrativeAdvancedData.topicChart}
                layout={'vertical'}
                margin={{ right: 20 }}
                barGap={1}>
                <XAxis type='number' hide dataKey='metric_value' />
                <YAxis
                  type='category'
                  dataKey='term'
                  axisLine={false}
                  width={120}
                  tick={{
                    fontSize: 12,
                    fontWeight: 400,
                    textAnchor: 'start',
                    fill: '#00000',
                  }}
                  tickMargin={110}
                  tickLine={false}
                  onMouseEnter={(item: any) => onBarChartMouseEnter({}, item.index)}
                  onMouseOut={() => onBarChartMouseOut({}, -1)}
                  tickFormatter={(value: string) => {
                    return value?.length > 15 ? value.substring(0, 13) + '...' : value
                  }}
                />

                <Tooltip content={CustomTooltip} cursor={false} active />
                <Bar
                  dataKey='metric_value'
                  barSize={15}
                  onMouseEnter={onBarChartMouseEnter}
                  onMouseOut={onBarChartMouseOut}
                  onClick={filterSnippetData}>
                  {activeThreatNarrativeAdvancedData.topicChart.map((entry, index) => (
                    <Cell
                      cursor='pointer'
                      fill={
                        (selectedIndex === index ? '#F5EDDE' : index === chartsActiveIndex.bar && chartsFillColor) ||
                        '#EBDBBD'
                      }
                      key={`cell-${index}`}
                    />
                  ))}
                </Bar>
              </BarChart>
            </div>
          </div>
        )}

        {isSideDetailsOpen.length === 0 && activeThreatNarrativeAdvancedData.platformChart.length > 0 && (
          <div>
            <div className={classNames('details-section__topics', 'details-section__topics--margin')}>
              <span className='details-section__topics__title'>Platform Distribution</span>
            </div>
            <PieChart
              width={350}
              height={
                (activeThreatNarrativeAdvancedData.platformChart.length || 1) * 30 > 200
                  ? (activeThreatNarrativeAdvancedData.platformChart.length || 1) * 30
                  : 200
              }>
              <Legend
                layout='vertical'
                verticalAlign='middle'
                align='right'
                content={renderLegend}
                margin={{ top: 40 }}
              />
              <Pie
                data={activeThreatNarrativeAdvancedData.platformChart}
                innerRadius={60}
                outerRadius={70}
                fill='#8884d8'
                paddingAngle={5}
                dataKey='metric_value'
                labelLine={false}
                onMouseEnter={onPieChartMouseEnter}
                onMouseOut={onPieChartMouseOut}>
                {activeThreatNarrativeAdvancedData.platformChart.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index === chartsActiveIndex.pie ? '#CCA55A' : COLORS[index % COLORS.length]}
                    cursor={'pointer'}
                  />
                ))}
                <Label
                  value={activeThreatNarrativeAdvancedData.platformChartTotalCount}
                  position='centerBottom'
                  className='details-section__pie-chart__transform'
                  fontSize='27px'
                />
                <Label value='Total Posts' position='centerTop' className='details-section__pie-chart__label' />
              </Pie>
            </PieChart>
          </div>
        )}
        {isSideDetailsOpen.length === 0 && activeThreatNarrativeAdvancedData.lineChart.length > 0 && (
          <div>
            <div className='details-section__topics'>
              <span className='details-section__topics__title'>Post Timelines</span>
            </div>
            <LineChart
              width={400}
              height={
                (activeThreatNarrativeAdvancedData.lineChart.length || 1) * 30 > 200
                  ? (activeThreatNarrativeAdvancedData.lineChart.length || 1) * 30
                  : 200
              }
              data={activeThreatNarrativeAdvancedData.lineChart}
              margin={{ right: 30, bottom: 30 }}>
              <CartesianGrid strokeDasharray='4 4' />
              <XAxis
                tick={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
                tickLine={false}
              />
              <YAxis
                tick={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
                tickLine={false}
                dataKey='metric_value'
                tickFormatter={(value) => millify(value)}
              />
              <Tooltip content={CustomTooltip} cursor={false} active />
              <Line type='monotone' dataKey='metric_value' stroke='#CCA55A' activeDot={{ r: 8, fill: '#16191D' }} />
            </LineChart>
          </div>
        )}
      </div>
    </Spin>
  )
})
