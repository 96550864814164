import millify from 'millify'
import { action, makeObservable, observable } from 'mobx'
import { MonitorStore } from './monitor.store'
import {
  ICommunityDropdown,
  IMonitorWatchlist,
  ISnippet,
  ISnippetParams,
  ISingleExecutiveData,
  monitorMode,
} from 'models/models'
import { LANGUAGE_METRICS_DIMENSIONS } from 'settings/settings'
import { UtilService } from 'services/Util/Util'
import { API, monitorAPI, powerInsightsAPI, executiveIntelligenceAPI, watchlistAPIs } from 'api/api'
import { LogService } from 'services/Log/Log'

export class MonitorWatchlistStore extends MonitorStore {
  storeType: monitorMode = 'watchlist'

  avableFilters = {
    name: ['From A to Z', 'From Z to A'],
  }

  listFilter: {
    name: 'From A to Z' | 'From Z to A'
  } = { name: 'From A to Z' }

  activeWatchlist?: IMonitorWatchlist
  communityFilterData: ICommunityDropdown[] = []
  snippets: ISnippet[] = []
  tenant_id: string = ''

  constructor() {
    super()
    makeObservable(this, {
      activeWatchlist: observable,
      setActiveWatchlist: action.bound,
    })
  }

  get getModeConditions() {
    const channels = this.activeWatchlist!.channels.map((platformChannels) => {
      return platformChannels.channel_by_platform.map((channel) => {
        return channel.channel_url
      })
    }).flat()
    return [{ channel_urls: channels }]
  }

  setActiveWatchlist = (watchlist: IMonitorWatchlist) => {
    this.activeWatchlist = watchlist
  }

  fetchPowerInsights = async (id: string, listName: string) => {
    try {
      this.setActiveItem(null, '')
      this.setSnippets([])
      this.setSnippetsTotal(0)

      const { data } = await API.get({ route: 'watchlist', id })
      this.setActiveItem(data, listName)
      this.setActiveWatchlist(data)

      const payload = await this.snippetFilterPayload()

      const languageMetricsPromises: Promise<any>[] = []
      LANGUAGE_METRICS_DIMENSIONS.forEach((dimension) => {
        languageMetricsPromises.push(
          executiveIntelligenceAPI.getExecutiveMetrics({
            ...payload,
            dimension,
          }),
        )
      })
      const languageMetricsResolved = await Promise.allSettled(languageMetricsPromises)
      const languageMetricsProcessed: ISingleExecutiveData['languageMetrics'] = {}
      languageMetricsResolved.forEach((metric, index) => {
        if (metric.status === 'rejected') return

        const metricData = metric as PromiseFulfilledResult<any>

        let metricName = LANGUAGE_METRICS_DIMENSIONS[index]
        languageMetricsProcessed[metricName] = metricData.value
      })

      const [
        { data: postsLineChart },
        { data: postSentimentsLineChart },
        { data: postPlatformsLineChart },
        { data: impressionsLineChart },
        { data: impressionSentimentsLineChart },
        { data: impressionPlatformsLineChart },
        { data: dataHeatMap },
        { data: dataLineChart2 },
        peopleData,
        companiesData,
        countriesMapData,
        summaryStatisticsData,
        languagesData,
      ] = await Promise.all([
        powerInsightsAPI.lineChart.getLineChartData(payload),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          dimension: 'sentiment',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          dimension: 'platform',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
          dimension: 'sentiment',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
          dimension: 'platform',
        }),
        // Get the heat map communities data for last 24 hours only
        powerInsightsAPI.treeMap.getTreeMapData(payload),
        // Get the total_change_impression_count for last 24 hours only
        powerInsightsAPI.lineChart.getLineChartData(payload),
        this.fetchPeopleData(payload),
        this.fetchCompaniesData(payload),
        this.fetchCountriesData(payload),
        this.fetchSummaryData(payload),
        this.fetchLanguagesData(payload),
      ])

      this.updateListItem(id, '', {
        impresions: postsLineChart.total_change_count,
        trend: postsLineChart.change_direction,
        totalChangeImpressionCountLastDay: dataLineChart2.total_change_count,
      })

      // set the new Power Insights Data
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        summary: {
          executiveSummary: summaryStatisticsData.executiveSummary,
          statistics: [
            {
              label: 'Snippets',
              value: millify(summaryStatisticsData.statistics?.total_snippets),
            },
            {
              label: 'Unique Creators',
              value: millify(summaryStatisticsData.statistics?.creator_count),
            },
            {
              label: 'Companies',
              value: millify(summaryStatisticsData.statistics?.company_count),
            },
            {
              label: 'People',
              value: millify(summaryStatisticsData.statistics?.people_count),
            },
            {
              label: 'Organizations',
              value: millify(summaryStatisticsData.statistics?.country_count),
            },
            {
              label: 'Views',
              value: millify(summaryStatisticsData.statistics?.total_views),
            },
          ],
          platformsDistribution: summaryStatisticsData.platformsDistribution,
        },
        lineChartData: {
          posts: {
            all: { data_points: postsLineChart.data_points },
            sentiment: postSentimentsLineChart,
            platform: postPlatformsLineChart,
          },
          impressions: {
            all: { data_points: impressionsLineChart.data_points },
            sentiment: impressionSentimentsLineChart,
            platform: impressionPlatformsLineChart,
          },
        },
        heatMapData: {
          data_points: dataHeatMap.data_points,
        },
        beeSwarmNodesData: [],
        languageRisks: languageMetricsProcessed,
        peopleData: {
          completeData: peopleData.data_points,
          data: peopleData.total_count > 8 ? peopleData.data_points.slice(0, 8) : peopleData.data_points,
        },
        companyData: {
          completeData: companiesData.data_points,
          data: companiesData.total_count > 8 ? companiesData.data_points.slice(0, 8) : companiesData.data_points,
        },
        countriesMapData: countriesMapData.data_points,
        languagesData: { data: languagesData.data_points },
      })

      const itemCommunities: number[] = dataHeatMap.data_points.map(
        (point: { community_id: number }) => point.community_id,
      )
      const communitiesRequest: number[] = []
      itemCommunities.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
      this.getCommunities(communitiesRequest)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSnippetsInfo = async (id: string, listName: string) => {
    try {
      this.setActiveItem(null, '')
      this.setSnippets([])
      this.setSnippetsTotal(0)

      const { data: watchlist } = await API.get({ route: 'watchlist', getError: true, id })
      this.setActiveItem(watchlist, listName)
      this.setActiveWatchlist(watchlist)

      this.fetchSnippets()
    } catch (e: any) {
      this.setItemDetailsError(e?.response?.status)
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSnippets = async (
    { isSorting }: ISnippetParams = {
      isSorting: false,
    },
  ) => {
    try {
      if (this.snippets.length >= this.totalSnippets && this.snippets.length !== 0 && !isSorting) return

      const page = isSorting ? this.snippetsCurrentPage : Math.floor(this.snippets.length / 50) + 1
      const payload = await this.snippetFilterPayload()
      this.setSnippetsCurrentPage(page)
      payload.page = page
      payload.per_page = 50

      const { data } = await monitorAPI.loadFeed(payload)

      if (!data?.items.length) this.setIsFeedEmpty(false)
      this.setSnippetsTotal(data.total_count)

      let creators: string[] = []
      const snippets = data.items.map((snippet: any, index: number) => {
        if (snippet.platform !== 'Twitter' && snippet.platform !== 'News') creators.push(snippet?.channel_id)

        let author: { [key: string]: any } = {}
        let extras: { [key: string]: any } = {}

        if (snippet.platform === 'Twitter') {
          author.channel_title = snippet.post_metadata.author_name || ''
          author.channel_thumb_url = snippet.post_metadata.profile_image_url || ''

          extras.retweets = snippet.share_count || 0
          extras.likes = snippet.like_count || 0
          extras.comments = snippet.comment_count || 0
        }

        if (snippet.platform === 'News') {
          author.channel_title = snippet.post_metadata.post_source || ''

          extras.articleThumbImg = snippet.post_metadata?.post_thumb || ''
          extras.articleAuthor = snippet.post_metadata?.author_name || ''
        }

        let text = snippet.snippet_text
        if (this.snippetsFilter.days && snippet?.translated_snippet?.translated_text)
          text = snippet?.translated_snippet?.translated_text

        return {
          /* HACK: added the index so all the ids are unique */
          id: snippet.post_id + index,
          snippet_text: text,
          title: snippet.post_title,
          date: snippet.upload_date,
          riskScore: snippet?.risk_score?.toFixed(0),
          views: snippet.impression_count,
          viewsMilify: millify(snippet.impression_count || 0),
          views_diff: snippet.impression_diff_count,
          views_diff_milify: millify(snippet.impression_diff_count || 0),
          playSourceLink: snippet?.media_url,
          openSourceLink: snippet?.post_url,
          sourcePlatform: snippet.platform,
          channelId: snippet?.channel_id,
          snippetsFilter: this?.snippetsFilter,
          // narratives: [this?.activeWatchlist!.id],
          author,
          extras,
          duration: snippet.duration,
          offset: snippet.offset,
          sentiment: snippet.sentiment,
          postUrl: snippet.post_url,
          feedback: 'none',
          translatedSnippet: snippet.translated_snippet,
          documentId: snippet.document_id,
          source: snippet.snippet_source,
        }
      })

      if (isSorting) this.setSnippets(snippets)
      else this.updateSnippets(snippets)

      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
      await this.getCreators(creators)
    } catch (e) {
      LogService.error({ error: e, message: 'Error in fetch snippets method.' })
    }
  }

  republishWatchlist = async (watchlistId: string, watchlistData: any) => {
    try {
      await watchlistAPIs.EditWatchlist(watchlistData, watchlistId)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  saveWatchList = async (data: any) => {
    try {
      await watchlistAPIs.saveWatchlist(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
}
