import { makeAutoObservable, runInAction } from 'mobx'

import { ROUTES } from 'settings/settings'
import { route } from 'models/models'

export class LoaderStore {
  isLoadingCommunities = false
  isLoadingCreators = false
  isLoadingVideos = false
  isLoadingUsers = false
  isLabelingVideo = false
  isSendingDefineNarrrativeData = false
  isLoadingUserNarratives = false
  isLoadingNarratives = false
  isLoadingTags = false
  isLoadinFeed = false
  isLoadingStats = false
  landscape3DLoading = false
  //
  statsPeopleLoading = false
  statsCompanyLoading = false
  statsSummaryLoading = false
  statsLanguageLoading = false
  statsBeeSwarmLoading = false
  statsTreeDataLoading = false
  statsLineChartLoading = false
  statsPoliticianLoading = false

  //
  isLoadingExecutive = false
  isLoadingExecutiveStats = false
  isLoadingAlerts = false
  isLoadingSnippetSearch = false
  isLoadingTwitterPosts = false
  isLoadingCreatorTags = false
  isLoadingSharedData = false
  isLoadingThreatTopics = false
  isLoadingNoOfPosts = false
  isLoadingRisks = false
  isLoadingAttentionFlags = false
  isLoadingWatchlist = false
  isLoadingFlags = false
  isLoadingSummary = false
  isLoadingTopicChart = false
  isLoadingPlatformChart = false
  isLoadingCountryMapChart = false
  isLoadingBookmarks = false
  isLoadingUserBookmarks = false
  isLoadingTranslation = false
  isLoadingFeedDownloads = false
  isPublishingData = false
  isLoadingTenants = false
  isLoadingElectionRaces = false
  isLoadingElection = false
  isVerifyingTOS = false
  isTOS = false

  constructor() {
    makeAutoObservable(this)
  }

  set(param: any, isLoading: boolean) {
    if (!isLoading) {
      // give it a moment to make sure the data is added to the store
      setTimeout(() => {
        runInAction(() => {
          // @ts-ignore
          this[param] = isLoading
        })
      }, 750)
    } else {
      runInAction(() => {
        // @ts-ignore
        this[param] = isLoading
      })
    }
  }

  setIsLoading = ({ route, isLoading, query }: { route: route; isLoading: boolean; query?: string }) => {
    if (route.includes('user') || route.includes('users')) this.set('isLoadingUsers', isLoading)

    if (route.includes('dropdown')) this.set('isLoadingDropdownData', isLoading)

    if (route.includes('labelVideo')) this.set('isLabelingVideo', isLoading)

    if (route.includes(ROUTES.newNarrativeSave) || route.includes(ROUTES.newNarrativePublish))
      this.set('isSendingDefineNarrrativeData', isLoading)

    if (route.includes('community')) this.set('isLoadingCommunities', isLoading)
    if (route.includes('narrative')) this.set('isLoadingNarratives', isLoading)

    if (route.includes('tag')) this.set('isLoadingTags', isLoading)

    if (route.includes('feeds')) this.set('isLoadinFeed', isLoading)
    if (route.includes('channel')) this.set('isLoadingCreators', isLoading)

    if (route.includes('stats')) this.set('isLoadingStats', isLoading)
    if (route.includes('landscape')) this.set('landscape3DLoading', isLoading)

    // Power Insights Loader
    if (route.includes('stats/linechart')) this.set('statsLineChartLoading', isLoading)
    if (route.includes('stats/summary')) this.set('statsSummaryLoading', isLoading)
    if (route.includes('stats/treemap')) this.set('statsTreeDataLoading', isLoading)
    if (route.includes('stats/bee-swarm')) this.set('statsBeeSwarmLoading', isLoading)
    if (route.includes('stats/language')) this.set('statsLanguageLoading', isLoading)
    if (route.includes('stats/people')) this.set('statsPeopleLoading', isLoading)
    if (route.includes('stats/company')) this.set('statsCompanyLoading', isLoading)
    if (route.includes('stats/politician')) this.set('statsPoliticianLoading', isLoading)

    // Power Insight Loader

    if (route.includes('personnel')) this.set('isLoadingExecutive', isLoading)

    if (route.includes('insight')) this.set('isLoadingExecutiveStats', isLoading)

    if (route.includes('alert')) this.set('isLoadingAlerts', isLoading)

    if (route.includes('tag/summary')) this.set('isLoadingCreatorTags', isLoading)

    if (route.includes('snippets/search')) this.set('isLoadingSnippetSearch', isLoading)

    if (route.includes('twitter')) this.set('isLoadingTwitterPosts', isLoading)

    if (route.includes('narrative') && query?.includes('q=owner_id')) this.set('isLoadingUserNarratives', isLoading)

    if (route.includes('share')) this.set('isLoadingSharedData', isLoading)

    if (route.includes('threat-topics')) this.set('isLoadingThreatTopics', isLoading)

    if (route.includes('metrics')) this.set('isLoadingNoOfPosts', isLoading)

    if (route.includes('risks')) this.set('isLoadingRisks', isLoading)

    if (route.includes('custom-insights')) this.set('isLoadingAttentionFlags', isLoading)

    if (route.includes('watchlist')) this.set('isLoadingWatchlist', isLoading)

    if (route.includes('flags')) this.set('isLoadingFlags', isLoading)

    if (route.includes('summarize')) this.set('isLoadingSummary', isLoading)

    if (route.includes('stats/topic')) this.set('iisLoadingTopicCharts', isLoading)

    if (route.includes('stats/platform')) this.set('isLoadingPlatformChart', isLoading)

    if (route.includes('stats/country')) this.set('isLoadingCountryMapChart', isLoading)

    if (route.includes('bookmark')) this.set('isLoadingBookmarks', isLoading)

    if (route.includes('bookmark') && query?.includes('q=owner_id')) this.set('isLoadingUserBookmarks', isLoading)

    if (route.includes('translate')) this.set('isLoadingTranslation', isLoading)

    if (route.includes('export')) this.set('isLoadingFeedDownloads', isLoading)

    if (route.includes('publish')) this.set('isPublishingData', isLoading)

    if (route.includes('tenant')) this.set('isLoadingTenants', isLoading)

    if (route.includes('elections/races')) this.set('isLoadingElectionRaces', isLoading)

    if (route.includes('elections')) this.set('isLoadingElection', isLoading)

    if (route.includes('user/verify')) this.set('isVerifyingTOS', isLoading)

    if (route.includes('user/tos/accept')) this.set('isTOS', isLoading)
  }
}
