import ReactECharts from 'echarts-for-react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'

import { FlagGraphDataType } from 'store/flags/types'
import { SubStore } from 'types/types'

interface Props {
  subStore: SubStore
  flagCategoriesData: {
    [x: string]: FlagGraphDataType
  }
  narrativeStats: number
  selectedIndex: string
  selectedTopIndex: string
  setSelectedIndex: (value: string) => void
  setSelectedTopIndex: (value: string) => void
}
export const SankeyChart = observer(
  ({
    subStore,
    flagCategoriesData,
    narrativeStats,
    selectedIndex,
    selectedTopIndex,
    setSelectedIndex,
    setSelectedTopIndex,
  }: Props) => {
    const { flagsStore } = store
    const { flagCategoriesGraphData, setFlagCategoriesGraphData } = flagsStore
    const {
      activeItem,
      snippetsFilter,
      filterChips,
      setFilterChips,
      setSnippetsFilter,
      fetchPowerInsights,
      fetchSnippetsInfo,
    } = store[`${subStore}Store`]

    const getData = () => {
      return selectedTopIndex?.length > 0 ? getSubCategoriesData(selectedTopIndex) : getFlagCategoriesData()
    }

    const getLinks = () => {
      return selectedTopIndex?.length > 0 ? getSubCategoriesLinks(selectedTopIndex) : getFlagCategoriesLinks()
    }

    const getFlagCategoriesData = () => {
      return [
        { name: `Posts total`, depth: 0, value: narrativeStats || 0, emphasis: { disabled: true } },
        ...Object.values(flagCategoriesData)?.map((item) => {
          return { name: item.name, depth: 1, value: item.total_value || 0 }
        }),
      ]
    }

    const getFlagCategoriesLinks = () => {
      return Object.values(flagCategoriesData)?.map((item) => {
        return { source: `Posts total`, target: item.name, value: item.total_value || 0 }
      })
    }

    const getSubCategoriesData = (subCategory: string) => {
      const data = [
        { name: `Posts total`, depth: 0, value: narrativeStats || 0, emphasis: { disabled: true } },
        {
          name: subCategory,
          depth: 1,
          value: flagCategoriesData[subCategory].total_value || 0,
          emphasis: { disabled: true },
          itemStyle: {
            color: '#cca55a',
          },
          label: {
            position: 'bottom',
            color: '#cca55a',
          },
        },
        ...flagCategoriesData[subCategory].subCategories?.map((item: any) => {
          return {
            name: item.name,
            depth: 2,
            value: item.total_value || 0,
            emphasis: { disabled: true },
            itemStyle: {
              color: filterChips.flags && filterChips.flags.value === item.name ? '#cca55a' : '#F2F4F7',
            },
            label: {
              color: filterChips.flags && filterChips.flags.value === item.name ? '#cca55a' : '#667085',
            },
          }
        }),
      ]
      return data
    }

    const getSubCategoriesLinks = (subCategory: string) => {
      const links = [
        {
          source: `Posts total`,
          target: subCategory,
          value: flagCategoriesData[subCategory].total_value || 0,
          emphasis: { disabled: true },
          lineStyle: {
            color: selectedIndex?.length > 0 ? '#D0D5DD' : '#cca55a',
            opacity: 0.25,
          },
        },
        ...flagCategoriesData[subCategory].subCategories?.map((item: any) => {
          return {
            source: subCategory,
            target: item.name,
            value: item.total_value || 0,
            emphasis: { disabled: true },
            lineStyle: {
              color: filterChips.flags && filterChips.flags.value === item.name ? '#cca55a' : '#D0D5DD',
              opacity: 0.25,
            },
          }
        }),
      ]
      return links
    }

    const getDataLength = () => {
      if (selectedTopIndex?.length > 0) {
        return flagCategoriesData[selectedTopIndex].subCategories?.length || 0
      } else return Object.keys(flagCategoriesData)?.length || 0
    }

    const onChartClick = (params: any) => {
      let category = ''
      if (params.dataType === 'node') {
        category = params.name
      } else if (params.dataType === 'edge') {
        category = params.data.target
      }

      if (category.length > 0 && category !== 'Posts total') {
        let flag = false
        if (selectedTopIndex?.length > 0) {
          if (category === selectedTopIndex) {
            setFlagCategoriesGraphData(category, {
              expanded: !flagCategoriesGraphData[category]?.expanded || false,
            })
            setSelectedTopIndex('')
            setSelectedIndex('')
          } else {
            flag = true

            if (selectedIndex === category) {
              setSelectedIndex('')
              let { flags, ...filter } = snippetsFilter
              setSnippetsFilter(filter)
              setFilterChips({
                ...filterChips,
                flags: {
                  ...filterChips.flags,
                  value: '',
                },
              })
            } else {
              const conditions =
                flagCategoriesData[selectedTopIndex].subCategories.find((item: any) => item.name === category)
                  ?.conditions || []

              setSelectedIndex(category)
              setSnippetsFilter({
                ...snippetsFilter,
                flags: { name: category, conditions: conditions },
              })
              setFilterChips({
                ...filterChips,
                flags: {
                  ...filterChips.flags,
                  value: category,
                },
              })
            }
          }
          if (flag) {
            fetchPowerInsights(activeItem!)
            fetchSnippetsInfo(activeItem!)
          }
        } else {
          setSelectedTopIndex(category)
          setFlagCategoriesGraphData(category, { expanded: !flagCategoriesGraphData[category]?.expanded || false })
        }
      }
    }

    const option = {
      series: [
        {
          top: '10%',
          bottom: '20%',
          type: 'sankey',
          layout: 'none',
          nodeGap: getDataLength() > 8 ? 70 : 50,
          emphasis: {
            focus: 'none',
            lineStyle: {
              color: '#7839EE',
              opacity: 0.25,
            },
            itemStyle: {
              color: '#7839EE',
              opacity: 0.25,
            },
          },
          data: narrativeStats ? getData() : [],
          links: getLinks(),
          itemStyle: {
            color: '#F2F4F7',
            opacity: 1,
          },
          lineStyle: {
            color: '#D0D5DD',
            opacity: 0.25,
            curveness: 0.8,
          },
          label: {
            position: 'right',
            color: '#667085',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            lineHeight: 20,
            formatter: '{b}\n{c}',
            distance: 20,
          },
          levels: [
            {
              depth: 0,
              label: {
                position: 'bottom',
                nodeWidth: 30,
              },
            },
          ],
          draggable: false,
        },
      ],
    }

    const onEvents = {
      click: onChartClick,
    }

    return (
      <ReactECharts
        option={option}
        style={{ height: getDataLength() > 8 ? '1000px' : '600px', width: '100%' }}
        onEvents={onEvents}
      />
    )
  },
)
