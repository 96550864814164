import { API } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { IListItem, manageMode } from 'models/models'
import { action, makeObservable, observable } from 'mobx'
import { ManageStore2 } from './manage.store'

export class ManageNarrativesStore2 extends ManageStore2 {
  storeType: manageMode = 'narrative'
  listItemsCreatedByUser: IListItem[] = []
  listItemsSharedWithUser: IListItem[] = []
  listItemsCreatedByUserPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }
  listItemsSharedWithUserPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }

  constructor() {
    super()
    makeObservable(this, {
      listItemsCreatedByUser: observable,
      listItemsSharedWithUser: observable,
      listItemsCreatedByUserPagination: observable,
      listItemsSharedWithUserPagination: observable,
      setListItemsCreatedByUser: action.bound,
      setListItemsSharedWithUser: action.bound,
      setListItemsCreatedByUserPagination: action.bound,
      setListItemsSharedWithUserPagination: action.bound,
      updateListItemsCreatedByUser: action.bound,
      updateListItemsSharedWithUser: action.bound,
    })
  }

  setListItemsCreatedByUser = (items: IListItem[]) => {
    this.listItemsCreatedByUser = items
  }

  setListItemsSharedWithUser = (items: IListItem[]) => {
    this.listItemsSharedWithUser = items
  }

  setListItemsCreatedByUserPagination = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsCreatedByUserPagination = pagination
  }

  setListItemsSharedWithUserPagination = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsSharedWithUserPagination = pagination
  }

  updateListItemsCreatedByUser = (items: IListItem[]) => {
    this.listItemsCreatedByUser = [...this.listItemsCreatedByUser, ...items]
  }

  updateListItemsSharedWithUser = (items: IListItem[]) => {
    this.listItemsSharedWithUser = [...this.listItemsSharedWithUser, ...items]
  }

  fetchListItemsCreatedByUser = async ({ isRefreshing }: { isRefreshing?: boolean } = {}) => {
    try {
      if (
        !isRefreshing &&
        this.listItemsCreatedByUserPagination.total <= this.listItemsCreatedByUserPagination.locallyLoaded &&
        this.listItemsCreatedByUserPagination.total !== 0
      )
        return

      let page = Math.ceil(this.listItemsCreatedByUserPagination.locallyLoaded / this.pageSize) + 1
      let pageSize = this.pageSize

      if (isRefreshing) {
        page = 1
        pageSize = this.listItemsCreatedByUserPagination.locallyLoaded
      }

      let { data, total } = await API.get({
        page,
        pageSize,
        route: this.storeType,
        getError: true,
        sort: 'name:asc',
        filter: `owner_id:eq:${this.userID}`,
      })

      if (!isRefreshing) {
        this.setListItemsCreatedByUserPagination({
          total,
          locallyLoaded: this.listItemsCreatedByUserPagination.locallyLoaded + data.items.length,
        })
      }

      const items = data.items.map((item: any) => {
        const date = new Date(item.last_update_time.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
        }
      })

      if (isRefreshing) {
        this.setListItemsCreatedByUser(items)
      } else {
        this.updateListItemsCreatedByUser(items)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchListItemsSharedWithUser = async ({ isRefreshing }: { isRefreshing?: boolean } = {}) => {
    try {
      if (
        !isRefreshing &&
        this.listItemsSharedWithUserPagination.total <= this.listItemsSharedWithUserPagination.locallyLoaded &&
        this.listItemsSharedWithUserPagination.total !== 0
      )
        return

      let page = Math.ceil(this.listItemsSharedWithUserPagination.locallyLoaded / this.pageSize) + 1
      let pageSize = this.pageSize

      if (isRefreshing) {
        page = 1
        pageSize = this.listItemsSharedWithUserPagination.locallyLoaded
      }

      let { data, total } = await API.get({
        page,
        pageSize,
        route: this.storeType,
        getError: true,
        sort: 'name:asc',
        filter: 'is_pendulum_narrative:eq:true',
      })

      if (!isRefreshing) {
        this.setListItemsSharedWithUserPagination({
          total,
          locallyLoaded: this.listItemsSharedWithUserPagination.locallyLoaded + data.items.length,
        })
      }

      const updatedData = data.items.map((item: any) => {
        const date = new Date(item.last_update_time.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item.description,
          tags: item.tags,
        }
      })

      if (isRefreshing) {
        this.setListItemsSharedWithUser(updatedData)
      } else {
        this.updateListItemsSharedWithUser(updatedData)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
}
