import { observer } from 'mobx-react-lite'

import { store } from 'store'
import { monitorMode, monitorSnippetPage } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import styles from './statistics.module.scss'

interface StatisticsProps {
  mode: monitorMode
  page: monitorSnippetPage
}

export const Statistics: React.FC<StatisticsProps> = observer(({ mode, page }) => {
  const { powerInsightsData } =
    page === 'define' ? store['defineNarrativeStoreV1'] : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { summary: summaryData } = powerInsightsData

  return (
    <div className={styles.statistics}>
      <div className={styles.heading}>
        <h4>Statistics</h4>
      </div>

      <div className={styles.stats}>
        {summaryData.statistics.map((stat) => (
          <div className={styles.statCard} key={stat.label}>
            <span className={styles.statCount}>{stat.value}</span>
            <span className={styles.statName}>{stat.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
})
