/* eslint-disable prettier/prettier */
import { useUnmount } from 'react-use'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { List } from 'antd'

import { monitorMode, monitorSnippetPage } from 'models/models'

import { MonitorSnippetItem } from '../MonitorSnippetItem/SnippetItem'
import VirtualList from 'rc-virtual-list'
import { DataFetching } from 'components/common/DataFetching/DataFetching'

import './SnippetPage.scss'

// type modeProps={

// }
interface Props {
  page: monitorSnippetPage
  mode: monitorMode
}
export const DefineMonitorSnippetList = observer(({ page, mode }: Props) => {
  const ContainerHeight = page === 'edit' ? 500 : 650
  const itemHeight = 146

  const {
    monitorNarratives,
    defineNarrativeStoreV1: defineNarrativeStore,
    defineWatchlistStoreV1: defineWatchlistStore,
  } = store
  const { activeNarrativeTitle } = monitorNarratives
  // const { isFeedEmpty, twitterMode, liteCommunities, snippets, twitterPosts, fetchSnippets, setIsFeedEmpty } =
  //   defineNarrativeStore
  const { isFeedEmpty, twitterMode, liteCommunities, snippets, twitterPosts, fetchSnippets, setIsFeedEmpty } =
    mode === 'narrative' ? defineNarrativeStore : defineWatchlistStore

  useUnmount(() => {
    setIsFeedEmpty(true)
  })

  const checkFeed = () => {
    if (isFeedEmpty && !snippets.length && !twitterPosts.length) {
      return <></>
    } else {
      if (!snippets.length && !twitterPosts.length) {
        return <DataFetching title={activeNarrativeTitle} page={'notFound'} />
      } else {
        setIsFeedEmpty(false)
        if (twitterMode) {
          return (
            <VirtualList
              className='c-monitor-communities__items'
              data={twitterPosts}
              height={ContainerHeight}
              itemHeight={itemHeight}
              itemKey='id'
              onScroll={(e) => {
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight)
                  fetchSnippets({ appendMode: true })
              }}>
              {(post) => {
                return (
                  <MonitorSnippetItem
                    mode={mode}
                    page={page}
                    snippet={post}
                    allWatchlist={[]}
                    allCommunities={liteCommunities}
                    showSurprise={() => {}}
                  />
                )
              }}
            </VirtualList>
          )
        } else {
          return (
            <VirtualList
              className='c-monitor-communities__items'
              data={snippets.length > 0 ? snippets : []}
              height={ContainerHeight}
              itemHeight={itemHeight}
              itemKey='id'
              onScroll={(e) => {
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight)
                  fetchSnippets({ appendMode: true })
              }}>
              {(snippet) => {
                return (
                  <MonitorSnippetItem
                    mode={mode}
                    page={page}
                    snippet={snippet}
                    allWatchlist={[]}
                    allCommunities={liteCommunities}
                    showSurprise={() => {}}
                  />
                )
              }}
            </VirtualList>
          )
        }
      }
    }
  }

  return (
    <>
      <div className='snippet-list__wrapper'>
        <List>{checkFeed()}</List>
      </div>
    </>
  )
})
