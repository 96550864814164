import { store } from 'store'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffectOnce, useUnmount } from 'react-use'
import classNames from 'classnames'

import { IExportFeed } from 'models/models'
import { ListPageSection } from 'version2/components/common/ListPageSection/ListPageSection'
import { ExportDownloadBox } from './ExportDownloadBox'

import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow.svg'
import { ReactComponent as ProgressImage } from 'assets/images/export-success-image.svg'

import './ExportList.scss'
import dayjs from 'dayjs'
import { useState } from 'react'

export const ExportList = observer(() => {
  const { querry } = useParams()

  const { loaderStore } = store
  const { activeExport, feedExportData, setActiveExport, getAllFeedExports, resetExportStore } =
    store['monitorExportDownloadsStore']

  const [inProgressState, setInProgressState] = useState<boolean>(false)

  useEffectOnce(() => {
    getAllFeedExports()
  })

  const getList = (
    data: IExportFeed[],
    pagination: {
      total: number
      locallyLoaded: number
    },
    listName: 'new' | 'old',
    heading: string,
    loaders: boolean,
    fetch: ({
      id,
      querry,
      pageSize = 20,
      activeId = '',
      loadNew = false,
    }: {
      id?: string
      querry?: string
      pageSize?: number
      activeId?: string
      loadNew?: boolean
    }) => Promise<void>,
  ) => {
    return (
      <>
        <div className='export__header__sticky'>
          <h5 className='export__header__title'>{heading}</h5>
        </div>

        <Spin spinning={loaders}>
          <div className='export__items-div'>
            {(data.length ? data : []).map((item: IExportFeed) => {
              return (
                <div
                  className={classNames('export__item', { 'export__item--isSelected': item.id === activeExport?.id })}
                  onClick={() => {
                    setInProgressState(true)
                    setTimeout(() => setInProgressState(false), 1000)
                    setActiveExport(item)
                  }}>
                  <span className='export__item__name'>{item.entity_id}</span>
                  <span className='export__item__date'>
                    {listName === 'new' ? 'Today' : dayjs(item.created).format('YYYY/MM/DD hh:mm a')}
                  </span>
                </div>
              )
            })}
          </div>
        </Spin>

        {pagination.locallyLoaded !== pagination.total && (
          <div className='list__view-all-section'>
            <button className='list__view-all-section__btn' onClick={() => fetch({ querry, pageSize: 6 })}>
              Show more
              <RightArrowIcon height={12} width={12} className='list__view-all-section__btn--icon' />
            </button>
          </div>
        )}
      </>
    )
  }

  const inProgress = () => {
    return (
      <div className='export__info'>
        <div className='export__inProgress__container'>
          <div className='success-modal__body'>
            <ProgressImage />
            <span className='success-modal__title'>Export in progress</span>
            <span className='export__inProgress__description'>
              We're preparing your data for export. Once the export is ready, we'll notify you, and you'll be able to
              download the file.
            </span>
          </div>
        </div>
      </div>
    )
  }

  useUnmount(() => {
    resetExportStore()
  })

  return (
    <ListPageSection
      left={
        <div className='export'>
          <div className='export__wrapper'>
            {feedExportData.newData.length > 0 &&
              getList(
                feedExportData.newData,
                { locallyLoaded: 0, total: 0 },
                'new',
                'Available Exports',
                loaderStore[`isLoadingFeedDownloads`],
                getAllFeedExports,
              )}

            {feedExportData.oldData.length > 0 &&
              getList(
                feedExportData.oldData,
                { locallyLoaded: 0, total: 0 },
                'old',
                'Export history',
                loaderStore[`isLoadingFeedDownloads`],
                getAllFeedExports,
              )}
          </div>
        </div>
      }
      right={activeExport ? inProgressState ? inProgress() : <ExportDownloadBox /> : <></>}
      columns={{
        col1: {
          sm: 24,
          md: 8,
          lg: 8,
        },
        col2: {
          sm: 24,
          md: 16,
          lg: 16,
        },
      }}
    />
  )
})
