import React, { useState, useMemo, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { store } from 'store'
import './DetailPanelRightSection.scss'
import DetailPanelRightSectionHeader from '../DetailPanelRightSectionHeader/DetailPanelRightSectionHeader'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import classNames from 'classnames'
import DetailPanelFeeds from '../DetailPanelFeeds/DetailPanelFeeds'
import DetailPanleTrends from '../DetailPanleTrends/DetailPanleTrends'
import DetailViewEmptyData from '../DetailViewEmptyData/DetailViewEmptyData'
// import DetailPanelFeedsVideoVarient from '../DetailPanelFeedsVideoVarient/DetailPanelFeedsVideoVarient'
import { ReportFieldParams } from 'store/report/valueTypes'
import SearchInput from 'version2/components/common/Inputs/SearchInput/SearchInput'

const DATE_FORMAT = 'YYYY-MM-DD'

const FEED_TITLE = 'Feeds'
const TRENDS_TITLE = 'Trends'

type SelectedTabObj = {
  id: string
  title: string
}

const tabs = [
  { id: '0', title: 'Feeds' },
  { id: '1', title: 'Trends' },
]

const DetailPanelRightSection = observer(() => {
  const { reportMainViewStore } = store
  const {
    reportSectionFeeds,
    trendsDataPoints,
    reportDetailsEntities,
    feedsPayload,
    fetchReportFeedsHandler,
    fetchAllTrendsList,
    feedsFilters,
    currPage,
    setCurrPage,
    prevPage,
    setPrevPage,
    searchInputFeeds,
    setSearchInputFeeds,
    feedSearch,
    setFeedsSearch,
  } = reportMainViewStore
  const [selectedTab, setSelectedTab] = useState<SelectedTabObj>(tabs[0])
  const listInnerRef = useRef<HTMLDivElement>(null)
  const [lastList, setLastList] = useState<boolean>(false)
  const [rerenderFeeds, setRerenderFeeds] = useState<boolean>(false)

  useEffect(() => {
    setRerenderFeeds(true)
  }, [feedsFilters, feedSearch])

  const fetchReportFeedsMethod = (value: string) => {
    setRerenderFeeds(true)
    let sliderValues: { min: number; max: number } = { min: 0, max: 100 },
      selectedPlatform: string[] = [],
      selectedSentiment: string[] = []
    let qParam = `start_date:gte:${
      reportDetailsEntities.start_date ? dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT) : ''
    },end_date:lte:${reportDetailsEntities.end_date ? dayjs(reportDetailsEntities.end_date).format(DATE_FORMAT) : ''}`
    if ('platforms' in feedsFilters && feedsFilters.platforms) {
      selectedPlatform = feedsFilters.platforms.split(',')
      qParam = `${qParam},platform:in:${feedsFilters.platforms}`
    }
    if ('sentiments' in feedsFilters && feedsFilters.sentiments) {
      selectedSentiment = feedsFilters.sentiments.split(',')
      qParam = `${qParam},sentiment:in:${feedsFilters.sentiments}`
    }
    if (feedsFilters.risk_score.length) {
      const [min, max] = feedsFilters.risk_score
      sliderValues = { min, max }
      qParam = `${qParam},risk_score:gte:${min},risk_score:lte:${max}`
    } else {
      qParam = `${qParam},risk_score:gte:0,risk_score:lte:100`
    }

    const params: ReportFieldParams = {
      q: qParam,
      per_page: 50,
      page: 1,
      sort: 'upload_date:desc',
    }

    let requestPayload = JSON.parse(JSON.stringify(feedsPayload))
    if (value) {
      requestPayload.conditions = [
        ...JSON.parse(JSON.stringify(requestPayload.conditions)),
        {
          keywords_expression: value,
        },
      ]
    }

    fetchReportFeedsHandler(params, requestPayload)
    fetchAllTrendsList(
      dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT),
      dayjs(reportDetailsEntities.end_date).format(DATE_FORMAT),
      requestPayload,
      sliderValues,
      selectedPlatform,
      selectedSentiment,
    )
  }

  useEffect(() => {
    if (currPage > 1) {
      setRerenderFeeds(false)
      const fetchReportFeedsMethod = () => {
        let qParam = `start_date:gte:${
          reportDetailsEntities.start_date ? dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT) : ''
        },end_date:lte:${
          reportDetailsEntities.end_date ? dayjs(reportDetailsEntities.end_date).format(DATE_FORMAT) : ''
        }`
        if ('platforms' in feedsFilters && feedsFilters.platforms) {
          qParam = `${qParam},platform:in:${feedsFilters.platforms}`
        }
        if ('sentiments' in feedsFilters && feedsFilters.sentiments) {
          qParam = `${qParam},sentiment:in:${feedsFilters.sentiments}`
        }
        if (feedsFilters.risk_score.length) {
          const [min, max] = feedsFilters.risk_score
          qParam = `${qParam},risk_score:gte:${min},risk_score:lte:${max}`
        } else {
          qParam = `${qParam},risk_score:gte:0,risk_score:lte:100`
        }

        const params: ReportFieldParams = {
          q: qParam,
          per_page: 50,
          page: currPage,
          sort: 'upload_date:desc',
        }

        let requestPayload = JSON.parse(JSON.stringify(feedsPayload))
        if (feedSearch) {
          requestPayload.conditions = [
            ...JSON.parse(JSON.stringify(requestPayload.conditions)),
            {
              keywords_expression: feedSearch,
            },
          ]
        }

        fetchReportFeedsHandler(params, requestPayload, true).then((res) => {
          if (res !== 'error' && res.total_count === [...reportSectionFeeds.items, ...res.items].length) {
            setLastList(true)
          }
        })
        setPrevPage(currPage)
      }

      if (!lastList && prevPage !== currPage) {
        fetchReportFeedsMethod()
      }
    }
  }, [currPage, lastList, feedSearch, prevPage, reportSectionFeeds.items])

  const onScroll = () => {
    if (listInnerRef.current && selectedTab.title === FEED_TITLE) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (
        scrollTop + clientHeight === scrollHeight &&
        !lastList &&
        reportSectionFeeds.items.length !== reportSectionFeeds.total_count
      ) {
        setCurrPage(currPage + 1)
      }
    }
  }

  const renderTabContent = useMemo(() => {
    if (selectedTab.title === FEED_TITLE) {
      return (
        <div className='dprsc_tab_cc_feeds_container'>
          {reportSectionFeeds.items.length ? (
            reportSectionFeeds.items.map((feed) => (
              <DetailPanelFeeds key={feed.document_id} author={feed?.author} {...feed} data={feed} />
            ))
          ) : (
            <DetailViewEmptyData
              className='dprsc_tab_cc_feeds_empty_container'
              title='No Data available for this text chunk'
              desc='Try to select any other text chunk. For the moment there is no data available for this chunk.'
            />
          )}
          {/* <DetailPanelFeeds />
          <DetailPanelFeedsVideoVarient /> */}
        </div>
      )
    } else if (selectedTab.title === TRENDS_TITLE) {
      return <DetailPanleTrends data={JSON.parse(JSON.stringify(trendsDataPoints))} />
    }
  }, [
    selectedTab.title,
    reportSectionFeeds.items,
    trendsDataPoints.posts.all,
    trendsDataPoints.impressions.all,
    trendsDataPoints.posts.platform,
    trendsDataPoints.impressions.platform,
    trendsDataPoints.posts.sentiment,
    trendsDataPoints.impressions.sentiment,
  ])

  const searchReportOnChange = (e: any) => {
    setSearchInputFeeds(e.target.value)
  }

  const renderKey = useMemo(() => {
    if (rerenderFeeds) {
      return Math.random()
    } else {
      return '123450123'
    }
  }, [rerenderFeeds])

  return (
    <div className='detail_panel_right_section_container'>
      <DetailPanelRightSectionHeader className='dprsc_header' />
      <div className='dprsc_tab_container'>
        <div className='dprsc_tab_header'>
          {tabs.map((tab) => (
            <ButtonImage
              key={tab.id}
              onClick={() => {
                setSelectedTab(tab)
              }}
              className={classNames('dprsc_th_button', {
                dprsc_th_button_active: selectedTab.id === tab.id,
              })}>
              {tab.title}
            </ButtonImage>
          ))}
        </div>
        <div className='dprsc_search_filters_panel'>
          <div className='dprsc_sfp_inner_container'>
            <SearchInput
              className='dprsc_sfp_search_input'
              value={searchInputFeeds}
              onChange={searchReportOnChange}
              onPressEnter={() => {
                if (currPage > 1) {
                  setCurrPage(1)
                  setPrevPage(0)
                }
                if (lastList) {
                  setLastList(false)
                }
                setFeedsSearch(searchInputFeeds)
                fetchReportFeedsMethod(searchInputFeeds)
              }}
              onClear={() => {
                setSearchInputFeeds('')
                if (feedSearch) {
                  fetchReportFeedsMethod('')
                  setFeedsSearch('')
                }
              }}
            />
          </div>
        </div>
        <div key={renderKey} className='dprsc_tab_content_container' onScroll={onScroll} ref={listInnerRef}>
          {renderTabContent}
        </div>
      </div>
    </div>
  )
})

export default DetailPanelRightSection
