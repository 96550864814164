import { Col, Form, Input, Row, Select, Spin, Switch, Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuidv4 } from 'uuid'
import millify from 'millify'
import { useEffectOnce, useUnmount } from 'react-use'
import { store } from 'store'
import { DefaultOptionType } from 'antd/lib/select'
import { useParams } from 'react-router-dom'

import { ContactUs } from '../../common/ContactUs/ContactUs'
import { AttentionFlagsList } from './AttentionFlagsList/AttentionFlagsList'
import { SuccessModal } from './Modals/SuccessModal'

import { ReactComponent as FlagSampleIcon } from 'assets/images/add-flag-sample-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/cross_black.svg'
import { ReactComponent as TextAreaIcon } from 'assets/images/info-tooltip-icon.svg'
import { ReactComponent as SearchResultIcon } from 'assets/images/search-result.svg'

import { DeleteModal } from './Modals/DeleteModal'
import { AttentionFlagsSnippets } from './AttentionFlagsSnippets/AttentionFlagsSnippets'

import './AttentionFlags.scss'

export const AttentionFlags = observer(() => {
  const { id: flagId } = useParams()
  const isEditMode = !!flagId
  const wrapperRef = useRef<any>()
  const [form] = Form.useForm()

  const insightType: { [x: string]: string } = {
    NARRATIVES: 'Snippets belonging to predefined narratives',
    SEARCH_TERM: 'Snippets text matches a certain rule',
    COMMUNITIES: 'Creator belongs to an existing community',
  }

  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [openContactModal, setOpenContactModal] = useState(false)
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState('')
  const [showCompanies, setShowCompanies] = useState(false)
  const [showCompaniesDropdown, setShowCompaniesDropdown] = useState(true)
  const [selectedRule, setSelectedRule] = useState('NARRATIVES')
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [showSnippets, setShowSnippets] = useState(false)

  const { attentionFlagsStore, userStore, loaderStore, defineNarrativeStoreV1: defineNarrativeStore } = store
  const {
    tenant,
    narrativeData,
    communityData,
    showResults,
    flags,
    flagsInfo,
    tableTabData,
    formData,
    setTenantId,
    setShowResults,
    addFormData,
    fetchTenant,
    fetchNarrativesAndCommunities,
    fetchFlags,
    saveFlag,
    deleteFlag,
    resetSnippetData,
    editMode,
    resetStore,
  } = attentionFlagsStore
  const { isLoadingAttentionFlags } = loaderStore
  const { matchingResults, setFormData, fetchSnippets } = defineNarrativeStore

  useEffectOnce(() => {
    fetchFlags()
    fetchTenant()
    fetchNarrativesAndCommunities()
  })

  useEffect(() => {
    if (flagId) {
      const result = flags.find((flag) => flag.id === flagId) || null
      setIsFormOpen(true)
      editMode(result || null, form)
      setSelectedRule(result?.insightType || 'NARRATIVES')
    } else form.resetFields()
  }, [flagId])

  useEffect(() => {
    if (flags.length > 0 && !flagId) setIsFormOpen(false)
  }, [flags])

  useEffect(() => {
    if (userStore.tenantId) {
      setTenantId(userStore.tenantId)
      if (!process.env.REACT_APP_PILOT_FLAGS_TENANT_ID) return

      const rex = new RegExp(process.env.REACT_APP_PILOT_FLAGS_TENANT_ID)
      if (userStore.tenantId.match(rex)) setShowCompanies(true)
      else setShowCompanies(false)
    }
  }, [userStore.tenantId])

  useEffect(() => {
    function handleClickOutsideTooltip(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event?.target)) {
        setIsTooltipVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutsideTooltip)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTooltip)
    }
  })

  useUnmount(() => {
    resetStore()
  })

  const showFeedbackModal = () => {
    setIsTooltipVisible(false)
    setOpenContactModal(true)
  }

  const filterSelectOptions = (inputValue: string, option: DefaultOptionType | undefined) => {
    return option?.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
  }

  const onSearchTextChange = (event: any) => {
    setSearchText(event.target.value)
  }
  const onChangeRule = (value: string) => {
    setSearchText('')
    setSelectedRule(value)
    addFormData({ insightCondition: [] })
    form.setFieldValue('insightCondition', [])
    if (value === 'SEARCH_TERM') {
      setIsSaveDisabled(true)
    } else setIsSaveDisabled(false)
    resetSnippetData()
  }

  const toggleCompaniesDropdown = (value: boolean) => {
    setShowCompaniesDropdown(!value)
  }

  const searchExpression = () => {
    setShowSnippets(true)
    const formObject = { booleanSearch: formData.insightCondition, subset: [], focusCommunities: [] }
    setFormData(formObject)
    fetchSnippets({}).then(() => {
      setShowResults(true)
      setIsSaveDisabled(false)
    })
  }

  const saveData = () => {
    saveFlag(isEditMode, flagId || '').then((response) => {
      if (response !== 'error') {
        setIsFormOpen(false)
        setIsSuccessModalVisible(true)
        setShowSnippets(false)
        form.resetFields()
      }
    })
  }

  const openForm = (state: boolean) => setIsFormOpen(state)

  return (
    <Spin spinning={isLoadingAttentionFlags}>
      <div className='attention-flags'>
        <Row>
          {flags.length > 0 ? (
            <Col span={isFormOpen ? 16 : 24}>
              {showSnippets ? (
                <AttentionFlagsSnippets setShowSnippets={setShowSnippets} />
              ) : (
                <AttentionFlagsList
                  flags={tableTabData}
                  flagsInfo={flagsInfo}
                  openForm={openForm}
                  setIsDeleteModalVisible={setIsDeleteModalVisible}
                />
              )}
            </Col>
          ) : (
            <Col span={isFormOpen ? 16 : 24}>
              <div className='attention-flags__padding'>
                <div className='attention-flags__container'>
                  <span className='attention-flags__container__title'>
                    Create your own flags or customise the existing flags
                  </span>
                  <span className='attention-flags__container__description'>
                    Use attention flags as shortcuts into content you do not want to miss. Configure using flexible
                    rules based on original and ML-enhanced properties.
                  </span>
                  <button
                    className='attention-flags__container__add-btn'
                    disabled={isFormOpen}
                    onClick={() => openForm(true)}>
                    + Add Flag
                  </button>
                  <div className='attention-flags__container__sample-content'>
                    <FlagSampleIcon />
                    <FlagSampleIcon />
                    <FlagSampleIcon />
                    <FlagSampleIcon />
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Col className='attention-flags__form' span={isFormOpen ? 8 : 0}>
            <Form
              form={form}
              onValuesChange={(newData: any) => {
                addFormData(newData)
              }}>
              <div className='attention-flags__form__header'>
                <CloseIcon onClick={() => openForm(false)} />
                <span className='attention-flags__form__header__title'>Add new flag</span>
              </div>

              {showCompanies && (
                <div className='attention-flags__form__companies-container'>
                  <div className='attention-flags__form__companies-container__text-div'>
                    <label className='attention-flags__form__companies-container__text-div__text'>All Companies</label>
                    <Form.Item name='companiesSwitch' style={{ width: 'initial' }}>
                      <Switch size='small' onClick={toggleCompaniesDropdown} />
                    </Form.Item>
                  </div>
                  {showCompaniesDropdown && (
                    <div className='attention-flags__form__companies-container__select-div'>
                      <label className='attention-flags__form__companies-container__select-div__label'>
                        Specific Companies
                      </label>
                      <Form.Item name='tenantIds'>
                        <Select
                          className='attention-flags__form__companies-container__select-div__select'
                          mode='multiple'
                          allowClear
                          style={{ width: '100%' }}
                          filterOption={filterSelectOptions}
                          placeholder='Select companies'>
                          {tenant?.map((element: any) => (
                            <Select.Option key={uuidv4()} value={element.id}>
                              {element.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  )}
                </div>
              )}

              <label className='attention-flags__form__companies-container__text-div__text'>
                Select from a set of rules
              </label>
              <Form.Item name='insightType'>
                <Select
                  className='attention-flags__form__companies-container__select-div__select'
                  style={{ width: '100%' }}
                  placeholder='No metadata selected'
                  onChange={onChangeRule}>
                  {Object.keys(insightType)?.map((key) => (
                    <Select.Option key={uuidv4()} value={key}>
                      {insightType[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <label className='attention-flags__form__text'>Define the rule for flag</label>

              <div className='attention-flags__form__name-container'>
                <label className='attention-flags__form__name-container__title'>Name</label>
                <Form.Item noStyle name='name'>
                  <Input.TextArea rows={1} />
                </Form.Item>
              </div>

              <div className='attention-flags__form__name-container'>
                <label className='attention-flags__form__name-container__title'>Display Name</label>
                <Form.Item noStyle name='displayLabel'>
                  <Input.TextArea rows={1} />
                </Form.Item>
              </div>

              <div className='attention-flags__form__name-container'>
                <label className='attention-flags__form__name-container__title'>Flag Description</label>
                <Form.Item noStyle name='description'>
                  <Input.TextArea rows={1} />
                </Form.Item>
              </div>

              {selectedRule === 'SEARCH_TERM' && (
                <>
                  <div className='attention-flags__form__name-container'>
                    <div className='attention-flags__form__name-container__tooltip-div'>
                      <label className='attention-flags__form__name-container__title--black'>
                        Boolean Keyword Search
                      </label>
                      <Tooltip
                        overlayStyle={{ width: '600px' }}
                        overlayInnerStyle={{ height: '700px', width: '400px', overflowY: 'auto' }}
                        trigger={['click']}
                        open={isTooltipVisible}
                        title={
                          <>
                            <ul ref={wrapperRef} className='attention-flags__form__boolean-search-tooltip'>
                              <li>
                                <p>
                                  Pendulum uses boolean operators to allow you to define what you are looking for. If
                                  you have not used boolean operators before, you can watch this short
                                  <a
                                    href='https://www.youtube.com/watch?v=bCAULDuMcso'
                                    target='_blank'
                                    rel='noreferrer'>
                                    {' '}
                                    video
                                  </a>
                                  . Other useful resources are
                                  <a
                                    href='https://www.youtube.com/watch?v=Ed7EswsnEbM'
                                    target='_blank'
                                    rel='noreferrer'>
                                    {' '}
                                    boolean modifiers
                                  </a>{' '}
                                  and{' '}
                                  <a
                                    href='https://www.youtube.com/watch?v=BoujP8KoK7E'
                                    target='_blank'
                                    rel='noreferrer'>
                                    keyword selection
                                  </a>
                                  .
                                </p>
                                <p>
                                  Boolean searches are very useful for a quick access to the data but can be very
                                  challenging if the topic you are evaluating is very complex and nuanced. If you need
                                  any assistance{' '}
                                  <button className='here-button' onClick={showFeedbackModal}>
                                    click here
                                  </button>{' '}
                                  and tell us how we can help.
                                </p>
                                <p>
                                  The data in this screen is limited to the last 90 days and is intended to give you
                                  examples of what your search results will look like. You can also edit right away as
                                  you see things to remove or add. Once you click Publish, your narrative will run
                                  against all of Pendulum’s data and will return all matching results.
                                </p>
                                <p>
                                  Pendulum uses the following operators and it is important that they are all UPPERCASE
                                  when included.
                                </p>
                                <span>AND: search for the presence of all terms in your search.</span>
                                <br />
                                <span>OR: search for the presence of either term.</span>
                                <br />
                                <span>NOT: remove items that contain a specific term.</span>
                                <br />
                                <span>
                                  NEAR/N: search for terms that are within N words of each other. Value of N can be
                                  between 1 and 6.
                                </span>
                              </li>
                              <br />
                              <span>Some search examples:</span>
                              <ul className='attention-flags__form__boolean-search-example'>
                                <li>
                                  <span> Hans Niemann and Magnus Carlsen recent scandal:</span>
                                </li>
                                <ul>
                                  <li>
                                    <p>
                                      ((("Hans Demon" OR "Hans Niemann" OR ((Hans OR Niemann OR Neiman OR Nieman) AND
                                      Chess)) OR ("Magnus Carlsen" OR ((Magnus OR Carlsen OR Carlson OR Carlton) AND
                                      Chess))) AND (Cheat* OR Scandal OR Withdr* OR Drama OR Device OR Vibr*))
                                    </p>
                                  </li>
                                </ul>
                                <li>
                                  <span>
                                    Narrowing down to the Elite 8 men’s basketball game in 2022 march madness
                                    tournament:
                                  </span>
                                </li>
                                <ul>
                                  <li>
                                    <p>
                                      (Arkansas OR Razorbacks) AND (Duke OR “Blue Devils”) AND “march madness” NOT “1994
                                      final”
                                    </p>
                                  </li>
                                </ul>
                              </ul>
                            </ul>
                          </>
                        }>
                        <div
                          onClick={() => {
                            setIsTooltipVisible(true)
                          }}>
                          <TextAreaIcon />
                        </div>
                      </Tooltip>
                    </div>
                    <Form.Item
                      name='insightCondition'
                      rules={[
                        {
                          message: 'There is an issue with your syntax.',
                        },
                        () => ({
                          validator(_, value) {
                            if (value.length === 0) {
                              return Promise.reject('Please type an expression.')
                            }

                            const stack: string[] = []
                            const values = value.split('')
                            let extra: boolean = false

                            values.forEach((el: string) => {
                              if (extra) return
                              if (el === '(') return stack.push('(')
                              if (el === ')') {
                                if (stack.length === 0) {
                                  extra = true
                                } else return stack.pop()
                              }
                            })

                            if (extra) {
                              return Promise.reject(new Error('You’re missing a “(” from your expression.'))
                            }

                            if (value.match(/"/g)?.length % 2) {
                              return Promise.reject(new Error(`You have an extra ' " ' in your expression.`))
                            }

                            if (stack.length > 0) {
                              return Promise.reject(new Error('You’re missing a “)” from your expression.'))
                            }

                            return Promise.resolve()
                          },
                        }),
                      ]}>
                      <Input.TextArea rows={1} onChange={onSearchTextChange} />
                    </Form.Item>
                  </div>
                  <button
                    className='attention-flags__form__test-btn'
                    disabled={!searchText.length}
                    onClick={searchExpression}>
                    Test Rule
                  </button>

                  <div>
                    {showResults && (
                      <div className='attention-flags__result-found__container'>
                        <div className='attention-flags__result-found'>
                          <strong>{millify(matchingResults)}</strong>
                          <p>results found for {form.getFieldValue('insightCondition')}</p>
                        </div>
                        {matchingResults > 1000000 && (
                          <div className='attention-flags__result-found attention-flags__result-found--warning'>
                            <SearchResultIcon />
                            <p>Your search results in too many items to be useful. Consider refining your keywords.</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {selectedRule === 'NARRATIVES' && (
                <div className='attention-flags__form__companies-container'>
                  <label className='attention-flags__form__companies-container__select-div__label'>Narratives</label>
                  <Form.Item name='insightCondition'>
                    <Select
                      className='attention-flags__form__companies-container__select-div__select'
                      mode='multiple'
                      allowClear
                      style={{ width: '100%' }}
                      filterOption={filterSelectOptions}
                      placeholder='Choose an existing narrative'>
                      {narrativeData.map((narrative: any) => (
                        <Select.Option key={uuidv4()} value={narrative.id}>
                          {narrative.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}

              {selectedRule === 'COMMUNITIES' && (
                <div className='attention-flags__form__companies-container'>
                  <label className='attention-flags__form__companies-container__select-div__label'>Communities</label>
                  <Form.Item name='insightCondition'>
                    <Select
                      className='attention-flags__form__companies-container__select-div__select'
                      mode='multiple'
                      allowClear
                      style={{ width: '100%' }}
                      filterOption={filterSelectOptions}
                      placeholder='Choose Communities'>
                      {communityData.map((comm: any) => (
                        <Select.Option key={uuidv4()} value={comm.id}>
                          {comm.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}

              <div className='attention-flags__save-div'>
                <button
                  className='attention-flags__save-div__cancel-btn'
                  onClick={() => {
                    openForm(false)
                    fetchFlags()
                    setShowSnippets(false)
                    form.resetFields()
                    setSelectedRule('NARRATIVES')
                  }}>
                  Cancel
                </button>
                <button className='attention-flags__save-div__save-btn' onClick={saveData} disabled={isSaveDisabled}>
                  Save and deploy
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        <ContactUs openModal={openContactModal} setOpenModal={setOpenContactModal} />

        <SuccessModal
          isEditMode={isEditMode}
          isModalVisible={isSuccessModalVisible}
          setIsModalVisible={setIsSuccessModalVisible}
        />
        <DeleteModal
          isModalVisible={isDeleteModalVisible}
          setIsModalVisible={setIsDeleteModalVisible}
          deleteFlag={deleteFlag}
        />
      </div>
    </Spin>
  )
})
