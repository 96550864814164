import Modal from 'antd/lib/modal/Modal'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import deleteGif from 'assets/gifs/delete.gif'

import './DeleteModal.scss'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
  deleteFlag: () => Promise<void>
  mode: monitorMode | 'person'
  callback?: () => void
}

export const DeleteModal = ({ isModalVisible, setIsModalVisible, deleteFlag, mode, callback }: Props) => {
  const navigate = useNavigate()

  const [activeModal, setActiveModal] = useState<'Delete' | 'Success'>('Delete')
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)

  const onDelete = () => {
    deleteFlag()
      .then(() => {
        setIsConfirmationModalVisible(true)
        setActiveModal('Success')
        confirmationModal()
      })
      .finally(() => {
        setIsModalVisible(false)
      })
  }

  const confirmationModal = () => {
    return (
      <Modal
        centered
        closable={false}
        width={387}
        open={isConfirmationModalVisible}
        bodyStyle={{ overflow: 'auto' }}
        className='feed-delete-modal'
        footer={
          <div>
            <button
              className='feed-delete-modal__confirmation-close-btn'
              onClick={() => {
                setIsConfirmationModalVisible(false)
                setActiveModal('Delete')
                if (mode === 'person') {
                  callback?.()
                  return
                }
                if (mode === 'bookmark') {
                  navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`)
                } else {
                  navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`)
                }
              }}>
              Close
            </button>
          </div>
        }>
        <div className='feed-delete-modal'>
          <div className='delete-gif'>
            <img src={deleteGif} alt='delete gif' />
          </div>

          <span className='feed-delete-modal__title feed-delete-modal__margin'>
            {`${mode.charAt(0).toUpperCase()}${mode.slice(1)}`} deleted permanently
          </span>
        </div>
      </Modal>
    )
  }

  return activeModal === 'Delete' ? (
    <Modal
      centered
      closable={false}
      width={387}
      open={isModalVisible}
      bodyStyle={{ overflow: 'auto' }}
      className='feed-delete-modal'
      footer={
        <div>
          <button className='feed-delete-modal__cancel-btn' onClick={() => setIsModalVisible(false)}>
            Close
          </button>
          <button className='feed-delete-modal__delete-btn' onClick={onDelete}>
            Delete
          </button>
        </div>
      }>
      <div className='feed-delete-modal'>
        <span className='feed-delete-modal__title'>
          Are you sure you want to delete
          <br />
          this {mode} permanently
        </span>
        <span className='feed-delete-modal__description'>You can not recover this {mode} once its deleted</span>
      </div>
    </Modal>
  ) : (
    confirmationModal()
  )
}
