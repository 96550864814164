import { manageMode } from 'models/models'
import { makeObservable } from 'mobx'
import { ManageStore2 } from './manage.store'

export class ManageWatchlistStore2 extends ManageStore2 {
  storeType: manageMode = 'watchlist'

  constructor() {
    super()
    makeObservable(this, {})
  }
}
