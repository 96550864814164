import { useNavigate } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'

import ExpandListChunk from '../ExpandListChunk/ExpandListChunk'
import Table, { IColumnType } from 'version2/components/common/Table/Table'

import FlagPosts from '../FlagPosts/FlagPosts'
import AllPostSection from '../AllPostSection/AllPostSection'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as NotificationIcon } from 'assets/images/icons/monitor/notification_icon.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'

import {
  NarrativeListDataType,
  NarrativesResponseType,
  PaginationValuesType,
  SelectedTabType,
} from 'store/monitorVersion2/types'
import { Mode, SubStore } from 'types/types'
import { GenericDispatch } from 'version2/utils/commonTypes'
import { ListSelectedRowData } from 'models/models'

import './ListViewSection.scss'
import { Tooltip } from 'antd'
import { store } from 'store'

type ListViewSectionType = {
  selectedRows: string[]
  setSelectedRows: GenericDispatch<string[]>
  listData: NarrativesResponseType
  selectedTab: SelectedTabType
  page: PaginationValuesType
  subStore: SubStore
  setAlertId: GenericDispatch<string>
  setEntityId: GenericDispatch<string>
  setSelectedRowsData: GenericDispatch<ListSelectedRowData[]>
}

const ListViewSection = (props: ListViewSectionType) => {
  const navigate = useNavigate()
  const {
    selectedRows,
    setSelectedRows,
    listData,
    selectedTab,
    page,
    subStore,
    setAlertId,
    setEntityId,
    setSelectedRowsData,
  } = props
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([])
  const { tenantsStore } = store

  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

  const expandedRowsHandler = (item: NarrativeListDataType) => {
    setExpandedRows((prevState) =>
      prevState.includes(item.id) ? prevState.filter((res) => res !== item.id) : [...prevState, item.id],
    )
  }

  const expandedRowsCondition = (item: NarrativeListDataType) => {
    return expandedRows.includes(item.id)
  }

  useEffect(() => {
    setExpandedRows([])
    setSelectedRows([])
    setSelectedRowsData([])
  }, [selectedTab, page.page])

  const columns: IColumnType<NarrativeListDataType>[] = useMemo(() => {
    return [
      {
        key: 'checkbox',
        title: () => null,
        width: 44,
        render: (column, item) => {
          const { id } = item
          return (
            <Tooltip placement='bottom' title='Select'>
              <div
                className={`lvsc_th_checkbox_container${
                  selectedRows.includes(id) ? ' lvsc_th_checkbox_container_selected' : ''
                }`}
                aria-label='Select'
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedRows([id])
                  setSelectedRowsData([
                    {
                      name: item.name,
                      conditions: item.conditions,
                      mode: item.mode as Mode,
                      id: item.id,
                      permission_level: item.permission_level,
                      alert_id: item.alert_id,
                      ownerId: item.owner_id,
                    },
                  ])
                }}>
                {selectedRows.includes(id) && <CheckIcon aria-label='Select' />}
              </div>
            </Tooltip>
          )
        },
      },
      {
        key: 'name',
        title: 'Name',
        width: 485,
        render: (column, item) => {
          const { name } = item

          return (
            <div className='lvsc_name_container'>
              <span className='lvsc_nc_text'>{name}</span>
            </div>
          )
        },
      },
      {
        key: 'all_posts',
        title: 'All Posts',
        width: 150,
        render: (column, item) => {
          return <AllPostSection itemData={item} subStore={subStore} />
        },
      },
      {
        key: 'flag_posts',
        title: 'Flagged Posts',
        width: 150,
        render: (column, item) => {
          return <FlagPosts itemData={item} subStore={subStore} />
        },
      },
      {
        key: 'assets_posts',
        title: 'Assets Posts',
        width: 150,
        render: (column, item) => {
          return <FlagPosts itemData={item} isAssetsFlags={true} subStore={subStore} />
        },
      },
      {
        key: 'notification',
        title: '',
        width: 68,
        onCell: (record) => ({
          onClick: (e: any) => {
            e.stopPropagation()
            if (changeLabelTextBasedOnTheme(selectedTab, isCorporateCommunicationsTheme) === 'communities') return
            setAlertId(record?.alert_id || '')
            setEntityId(record?.id)
          },
          style: {
            textAlign: 'center',
            cursor:
              changeLabelTextBasedOnTheme(selectedTab, isCorporateCommunicationsTheme) === 'communities'
                ? 'not-allowed'
                : 'pointer',
          },
        }),
        render: (_, record) => (
          <Tooltip placement='bottom' title='Turn On/Off'>
            {record?.alert_id ? (
              <FilledNotificationIcon aria-label='Turn On/Off' />
            ) : (
              <NotificationIcon className='amc_bell_icon' aria-label='Turn On/Offs' />
            )}
          </Tooltip>
        ),
      },
    ]
  }, [listData.items, selectedRows, selectedTab, page.page, isCorporateCommunicationsTheme])

  const renderRowSubComponent = React.useCallback((records: NarrativeListDataType) => {
    return <ExpandListChunk data={records} subStore={subStore} />
  }, [])

  const rowClickHandler = (item: NarrativeListDataType) => {
    const state = {
      name: item.name,
      conditions: item.conditions,
      mode: item.mode,
      id: item?.id,
      permission_level: item.mode === 'watchlist' ? 'NOT_SPECIFIED' : item.permission_level,
      alert_id: item.alert_id,
      ownerId: item.owner_id,
    }

    navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
  }

  return (
    <div className='list_view_section_container'>
      <Table
        data={listData.items}
        columns={columns}
        className='lvsc_table'
        onRowClick={rowClickHandler}
        expandable={{
          expandedRowRender: renderRowSubComponent,
          setExpandedRows: expandedRowsHandler,
          expandedRowsCondition: expandedRowsCondition,
          // rowExpandable: (records) => records.id !== 'a32a42df-888e-4b30-af03-a8b0851a8f59',
        }}
        // disabledRow={disabledRowHandler}
      />
    </div>
  )
}

export default memo(ListViewSection)
