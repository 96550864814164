import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ApiMethodType, ShareReportEndpoint } from 'store/report/valueTypes'

const selectRoute: { [key: string]: string } = {
  reports: ROUTES['reports'],
  narrative: ROUTES['reportNarrative'],
  tag: ROUTES['reportTag'],
  watchlist: ROUTES['reportWatchlist'],
  folder: ROUTES['folder'],
}

export function shareReport(method: ApiMethodType, endpoint: ShareReportEndpoint, id: string, data?: any) {
  return axios({
    method: method,
    url: `${selectRoute[endpoint]}/${id}/${ROUTES.reportsShare}`,
    data,
  })
}
