import { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { sourcePlatform } from 'models/models'
import { UtilService } from 'services/Util/Util'

import { store } from 'store'

import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-new-icon-current-color.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-new-icon-current-color.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon-current-color.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-new-icon-current-color.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon-current-color.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-icon-current-color.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/youtube-new-icon-current-color.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-icon-current-color.svg'
import { ReactComponent as Chan4Icon } from 'assets/images/4chan-icon-white.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-icon-current-color.svg'
import { ReactComponent as TruthSocialIcon } from 'assets/images/truth-social-icon-dark.svg'
import { ReactComponent as PatriotsIcon } from 'assets/images/patriots-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-icon-current-color.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-icon-current-color.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-icon-current-color.svg'
import { ReactComponent as CSpanIcon } from 'assets/images/cspan-black-icon.svg'
import { ReactComponent as WebSearchIcon } from 'assets/images/web-search-black-icon.svg'
import { ReactComponent as ConsiliumIcon } from 'assets/images/consilium-black-icon.svg'
import { ReactComponent as BrighteonIcon } from 'assets/images/brighteon-grey-icon.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-black-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-grey-icon.svg'

import styles from './BeehivePlatformLegends.module.scss'
import { SubStore } from 'types/types'

// eslint-disable-next-line no-unused-vars
const PLATFORM_ICONS: { [K in Lowercase<sourcePlatform>]: JSX.Element } = {
  bitchute: <BitChuteIcon className={styles.icon} />,
  news: <NewsIcon className={styles.icon} />,
  podcast: <PodcastIcon className={styles.icon} />,
  rumble: <RumbleIcon className={styles.icon} />,
  tiktok: <TikTokIcon className={styles.icon} />,
  twitter: <TwitterIcon className={styles.icon} />,
  youtube: <YouTubeIcon className={styles.icon} />,
  reddit: <RedditIcon className={styles.icon} />,
  '4chan': <Chan4Icon className={styles.icon} />,
  gab: <GabIcon className={styles.icon} />,
  truthsocial: <TruthSocialIcon className={styles.icon} />,
  'patriots.win': <PatriotsIcon className={styles.icon} />,
  vk: <VkIcon className={classNames(styles.icon, styles.vkIcon)} />,
  instagram: <InstagramIcon className={styles.icon} />,
  threads: <ThreadsIcon className={styles.icon} />,
  telegram: <TelegramIcon className={classNames(styles.icon, styles.telegramIcon)} />,
  'c-span': <CSpanIcon className={classNames(styles.icon, styles.telegramIcon)} />,
  websearch: <WebSearchIcon className={classNames(styles.icon, styles.telegramIcon)} />,
  consilium: <ConsiliumIcon className={classNames(styles.icon, styles.telegramIcon)} />,
  brighteon: <BrighteonIcon className={styles.icon} />,
  snapchat: <SnapchatIcon className={classNames(styles.icon, styles.telegramIcon)} />,
  odysee: <OdyseeIcon className={styles.icon} />,
}

interface LegendProps {
  subStore: SubStore
  platforms: sourcePlatform[]
}

export const BeehivePlatformLegends: React.FC<LegendProps> = observer(function BeehivePlatformLegends({
  subStore,
  platforms,
}) {
  const { monitorNarratives: monitorNarrativesStore } = store
  const { updateBeeSwarmNodesSelection } = monitorNarrativesStore

  const [activePlatform, setActivePlatform] = useState<sourcePlatform | ''>('')

  const selectPlatformNodes = useCallback(
    (platform: sourcePlatform) => {
      const newPlatform = platform === activePlatform ? '' : platform
      updateBeeSwarmNodesSelection(newPlatform)
      setActivePlatform(newPlatform)
    },
    [activePlatform],
  )

  return (
    <>
      {platforms.map((platform) => {
        const { color, backgroundColor } = UtilService.getPlatformColors(
          platform.toLowerCase() as Lowercase<sourcePlatform>,
        )

        return (
          <div
            key={platform}
            onClick={selectPlatformNodes.bind(null, platform)}
            className={classNames(styles.legend, { [styles.opaque]: activePlatform && platform !== activePlatform })}
            style={{ color, backgroundColor }}>
            {PLATFORM_ICONS[platform.toLowerCase() as keyof typeof PLATFORM_ICONS]}
            {UtilService.getPlatformBrandName(platform.toLowerCase() as Lowercase<sourcePlatform>)}
          </div>
        )
      })}
    </>
  )
})
