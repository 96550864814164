import { observer } from 'mobx-react-lite'

import { LanguageRiskItem } from './LanguageRiskItem/LanguageRiskItem'
import { ExecutiveCategoryType } from 'models/models'
import { store } from 'store'
import { SubStore } from 'types/types'

import styles from './ExecutiveLanguageRisks.module.scss'
import { Spin } from 'antd'
import { useEffect } from 'react'

const RISK_NAME_LOOKUP: { [key: string]: string } = {
  toxic_language: 'Toxic',
  obscene_language: 'Obscene',
  hate_language: 'Identity Hate',
  negative_sentiment: 'Negative',
  threat_language: 'Threat',
  risk_score: 'Source',
}

interface ExecutiveLanguageRisksProps {
  isPowerInsightsMode?: boolean
  subStore: SubStore
  dataLoading: boolean
}

export const ExecutiveLanguageRisks = observer(function ExecutiveLanguageRisks({
  subStore,
  isPowerInsightsMode,
  dataLoading = false,
}: ExecutiveLanguageRisksProps) {
  const { singlePersonData } = store.executiveIntelligence
  let languageRisks = singlePersonData?.languageMetrics
  const { powerInsightsData, fetchPowerInsightsLanguageMetrics } = store[`${subStore}Store`]
  languageRisks = powerInsightsData?.languageRisks

  useEffect(() => {
    fetchPowerInsightsLanguageMetrics()
  }, [])

  if (!languageRisks) {
    return null
  }

  return (
    <div className={styles.languageRisksSection}>
      <Spin spinning={dataLoading}>
        {isPowerInsightsMode ? (
          <div className={styles.description}>
            Go beyond basic sentiment to identity more risky content with enriched language intent detection
          </div>
        ) : (
          <h3>Language Risk - Click to Interact</h3>
        )}

        <div className={styles.languageRisksWrapper}>
          {Object.entries(languageRisks).map(([risk, data]) => {
            return (
              <LanguageRiskItem
                key={risk}
                name={RISK_NAME_LOOKUP[risk] as ExecutiveCategoryType}
                data={data}
                isPowerInsightsMode={isPowerInsightsMode}
                subStore={subStore}
              />
            )
          })}
        </div>
      </Spin>
    </div>
  )
})
