import dayjs from 'dayjs'
import { API, tagAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { IEntityDetails, IListItem, IMonitorModeDetail, ITAG_DETAILS, monitorMode } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { MonitorStore } from './monitor.store'

export class MonitorTagsStore extends MonitorStore {
  storeType: monitorMode = 'tag'

  selectedTagFollowingStatus: boolean = false

  avableFilters = Object.freeze({
    name: ['From A to Z', 'From Z to A'],
    filter_status: ['All tags', 'Followed', 'Unfollowed'],
  })

  listFilter: {
    name: 'From A to Z' | 'From Z to A'
    filter_status: 'All tags' | 'Followed' | 'Unfollowed'
  } = { name: 'From A to Z', filter_status: 'Unfollowed' }

  newTags: IListItem[] = []
  tagsFollowedByUser: IListItem[] = []
  tagsFollowedByUserPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }

  narrative: IMonitorModeDetail[] = []
  community: IMonitorModeDetail[] = []
  creator: any[] = []

  entityDetails: IEntityDetails = {
    NARRATIVE: [],
    COMMUNITY: [],
    CHANNEL: [],
    POST: [],
  }

  constructor() {
    super()
    makeObservable(this, {
      selectedTagFollowingStatus: observable,
      tagsFollowedByUser: observable,
      tagsFollowedByUserPagination: observable,
      newTags: observable,
      narrative: observable,
      community: observable,
      setPaginationTagsFollowedByUser: action.bound,
      setTagsFollowedByUser: action.bound,
      updateTagsFollowedByUser: action.bound,
      updateTagStatus: action.bound,
      saveTagDetailsData: action.bound,
      fetchTagsFollowedByUser: action.bound,
    })
  }

  get getSelectedTagFollowingStatus() {
    return this.selectedTagFollowingStatus
  }

  get getModeConditions(): any {
    return [{ narratives: this.snippetsInfo.narratives }, { communities: this.snippetsInfo.communities }]
  }

  setPaginationTagsFollowedByUser = (pagination: { total: number; locallyLoaded: number }) => {
    this.tagsFollowedByUserPagination = pagination
  }

  saveTagDetailsData = (data: ITAG_DETAILS) => {
    this.narrative = data?.entity_details?.NARRATIVE
    this.creator = data?.entity_details?.CHANNEL
    this.community = data?.entity_details?.COMMUNITY
  }

  setTagsFollowedByUser = (items: IListItem[]) => {
    this.tagsFollowedByUser = items
  }

  setNewTags = (items: IListItem[]) => {
    this.newTags = items
  }

  setSelectTagFollowingStatus = (following: boolean) => {
    this.selectedTagFollowingStatus = following
  }

  updateTagsFollowedByUser = (items: IListItem[]) => {
    this.tagsFollowedByUser.push(...items)
  }

  updateTagStatus = async (tagId: string | number, followingStatus: boolean) => {
    try {
      await tagAPI.changeFollowingStatus(tagId, followingStatus)
      this.setPaginationTagsFollowedByUser({ total: 0, locallyLoaded: 0 })
      this.setIsFeedEmpty(true)
      this.setPaginationItems({ total: 0, locallyLoaded: 0 })
      this.setListItems([])
      this.setTagsFollowedByUser([])
      this.setListItemsCreatedByUser([])
      this.setListItemsSharedWithUser([])
      this.fetchPowerInsights('-1', '')
      this.fetchListItems({ pageSize: 6 })
      this.fetchTagsFollowedByUser({ pageSize: 6 })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  updateTagStatusDetails = async (tagId: string | number, followingStatus: boolean) => {
    try {
      let activeData = this.activeItem
      if (activeData) activeData.is_followed = followingStatus
      this.setActiveItem(activeData, activeData?.listName || '')
      await tagAPI.changeFollowingStatus(tagId, followingStatus)

      if (followingStatus) {
        const index = this.listItems.findIndex((el) => el.id === tagId)
        this.listItems[index].is_followed = followingStatus
        this.tagsFollowedByUser.unshift(this.listItems[index])
        this.listItems.splice(index, 1)
      } else {
        const index = this.tagsFollowedByUser.findIndex((el) => el.id === tagId)
        this.tagsFollowedByUser[index].is_followed = followingStatus
        this.listItems.unshift(this.tagsFollowedByUser[index])
        this.tagsFollowedByUser.splice(index, 1)
      }
    } catch (e: any) {
      let activeData = this.activeItem
      if (activeData) activeData.is_followed = !followingStatus
      this.setActiveItem(activeData, activeData?.listName || '')
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSnippetsInfo = async (id: string, listName: string) => {
    try {
      this.setActiveItem(null, '')
      this.setSnippets([])
      this.setSnippetsTotal(0)
      this.setSnippetsInfo([], [])

      const { data } = await API.get({ route: 'tag', id })
      this.setActiveItem(data, listName)

      const communities = data.entity_details.COMMUNITY.map((el: { id: string; name: string; number: string }) => {
        return el.number
      })
      const narratives = data.entity_details.NARRATIVE.map((el: { id: string; name: string; number: string }) => {
        return el.number
      })
      this.entityDetails = data.entity_details
      this.setSnippetsInfo(narratives, communities)
      this.fetchSnippets()
    } catch (e: any) {
      this.setItemDetailsError(e?.response?.status)
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchListItems = async ({
    id,
    querry,
    pageSize = 20,
    activeId = '',
    loadNew = false,
  }: {
    id?: string
    querry?: string
    pageSize?: number
    activeId?: string
    loadNew?: boolean
  }) => {
    try {
      let items

      if (id) {
        const { data: item } = await API.get({
          route: this.storeType,
          getError: true,
          id,
        })
        items = [item]
        this.setPaginationItems({ total: 1, locallyLoaded: 1 })
      } else {
        if (querry && this.recentSearch !== querry) {
          this.setPaginationItems({
            total: 0,
            locallyLoaded: 0,
          })
          this.setRecentSearch(querry)
          this.setListItems([])
        }
        if (
          this.listItemsPagination.total <= this.listItemsPagination.locallyLoaded &&
          this.listItemsPagination.total !== 0
        )
          return

        const page = Math.ceil(this.listItemsPagination.locallyLoaded / pageSize) + 1
        const sortLookup = { 'From A to Z': 'label:asc', 'From Z to A': 'label:desc' }
        const filterLookup = { Followed: 'is_followed:eq:true', Unfollowed: 'is_followed:eq:false', 'All tags': '' }
        const date = dayjs().subtract(30, 'days').toISOString()

        let { data, total } = await API.get({
          route: this.storeType,
          page: page,
          pageSize: pageSize,
          getError: true,
          sort: querry
            ? this.storeType === 'tag'
              ? 'label'
              : 'name'
            : this.listFilter
            ? sortLookup[this.listFilter.name]
            : '',
          filter: querry
            ? `${this.storeType === 'tag' ? 'label' : 'name'}:like:${querry}`
            : loadNew
            ? `create_time:gt:${date}`
            : filterLookup[this.listFilter.filter_status],
        })
        this.setPaginationItems({ total, locallyLoaded: this.listItemsPagination.locallyLoaded + data.items.length })

        items = data.items
      }

      const data = items.map((item: any) => {
        if (activeId && item.id === activeId) this.setActiveItem(item, 'All')

        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          is_followed: item.is_followed,
        }
      })

      if (loadNew) this.setNewTags(data)
      else this.updateListItems(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTagsFollowedByUser = async ({ pageSize = 20, activeId = '' }: { pageSize?: number; activeId?: string }) => {
    try {
      if (
        this.tagsFollowedByUserPagination.total <= this.tagsFollowedByUserPagination.locallyLoaded &&
        this.tagsFollowedByUserPagination.total !== 0
      )
        return

      const page = Math.ceil(this.tagsFollowedByUserPagination.locallyLoaded / pageSize) + 1
      const sortLookup = { 'From A to Z': 'label:asc', 'From Z to A': 'label:desc' }
      let { data, total } = await API.get({
        route: this.storeType,
        page: page,
        pageSize: pageSize,
        getError: true,
        sort: this.listFilter ? sortLookup[this.listFilter.name] : '',
        filter: 'is_followed:eq:true',
      })

      this.setPaginationTagsFollowedByUser({
        total,
        locallyLoaded: this.tagsFollowedByUserPagination.locallyLoaded + data.items.length,
      })

      const items = data.items.map((item: any) => {
        if (activeId && item.id === activeId) {
          this.setActiveItem(item, 'Followed')
        }
        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          is_followed: item.is_followed,
        }
      })

      this.updateTagsFollowedByUser(items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  resetTagStore = () => {
    this.selectedTagFollowingStatus = false
    this.tagsFollowedByUser = []
    this.tagsFollowedByUserPagination = { total: 0, locallyLoaded: 0 }
    this.newTags = []
    this.narrative = []
    this.community = []
  }
}
