import { observer } from 'mobx-react-lite'
import { PieChart, Pie, Cell, Label, Legend, Customized } from 'recharts'
import classNames from 'classnames'

import './PieChart.scss'
import { useEffect, useState } from 'react'
import millify from 'millify'
import { isEmpty } from 'lodash'

interface Props {
  cx?: string | number
  width?: number
  height?: number
  data?: any[] | undefined
  colorPalette: string[]
  totalCount: number
  narrativeTotal: number
  onClickCell: (data: any) => void
  showLabelsBasedOnSelection?: boolean
  millifyStats?: boolean
  customLegendWrapperClass?: string
  syncSentimentFilterWithChart?: boolean
  sentimentFilter?: string
}
export const CustomPieChart = observer((props: Props) => {
  const {
    data,
    cx,
    width,
    height,
    colorPalette,
    totalCount,
    narrativeTotal,
    onClickCell,
    showLabelsBasedOnSelection = false,
    millifyStats,
    customLegendWrapperClass,
    syncSentimentFilterWithChart = false,
    sentimentFilter = '',
  } = props
  const [hoveredCell, setHoveredCell] = useState('')
  const [selectedIndexLocal, setSelectedIndexLocal] = useState('')

  useEffect(() => {
    if (syncSentimentFilterWithChart && sentimentFilter !== selectedIndexLocal) {
      setSelectedIndexLocal(sentimentFilter)
    }
  }, [syncSentimentFilterWithChart, selectedIndexLocal, sentimentFilter])

  const renderLegend = ({ payload: item }: any) => {
    if (totalCount > 0 && Object.keys(item).length > 0) {
      return (
        <ul className={classNames('custom-pie-chart__list', customLegendWrapperClass)}>
          {item.map((entry: any, index: number) => (
            <li key={`item-${index}`}>
              <div className='custom-pie-chart__legend'>
                <div className='custom-pie-chart__legend__title'>
                  <div
                    className={classNames('custom-pie-chart__legend__list')}
                    style={{ background: entry.payload.fill }}></div>
                  <span className='custom-pie-chart__legend__labels custom-pie-chart__legend__margin'>
                    {entry.payload.name?.length > 15 ? entry.payload.name.substring(0, 13) + '...' : entry.payload.name}
                  </span>
                </div>
                <span className='custom-pie-chart__legend__labels'>
                  {millifyStats ? millify(entry.payload.total_value) : entry.payload.total_value}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )
    } else
      return (
        <div className='custom-pie-chart__legend__no-data-div'>
          <span className='custom-pie-chart__legend__no-data-div__title'>No Data Available</span>
          <span className='custom-pie-chart__legend__no-data-div__description'>
            There are no intersections for this category.
          </span>
        </div>
      )
  }

  const getPercentage = () => {
    const count = showLabelsBasedOnSelection ? getTotalCount() : totalCount
    const percentage = (count / narrativeTotal) * 100
    if (isNaN(percentage)) return '0%'
    else return `${percentage.toFixed(2).replace(/[.,]00$/, '')}%`
  }

  const getColor = (entry: any, index: any) => {
    try {
      if (totalCount === 0) return '#EAECF0'
      else {
        if (selectedIndexLocal?.length > 0) {
          return selectedIndexLocal === entry.name ? colorPalette[index % colorPalette?.length] : '#D0D5DD'
        } else if (hoveredCell?.length > 0) {
          return hoveredCell === entry.name ? '#CCA55A' : '#EAECF0'
        } else if (!isEmpty(colorPalette)) return colorPalette[index % colorPalette?.length]
      }
    } catch (error) {
      console.log('error in getColor', error)
    }
  }

  const getGraphComponent = () => {
    const dataValues = totalCount > 0 ? data : [{ name: 'any', total_value: 1 }]
    return dataValues?.map((entry, index) => (
      <Cell
        className='pie-cell'
        key={`cell-${index}`}
        fill={getColor(entry, index)}
        cursor={'pointer'}
        onClick={() => {
          if (totalCount > 0) {
            if (selectedIndexLocal === entry.name) {
              setSelectedIndexLocal('')
            } else {
              setSelectedIndexLocal(entry.name)
            }

            onClickCell(entry)
          }
        }}
        onMouseEnter={() => {
          if (totalCount > 0) {
            setHoveredCell(entry.name)
          }
        }}
        onMouseOut={() => {
          if (totalCount > 0) {
            setHoveredCell('')
          }
        }}
      />
    ))
  }

  const getTotalCount = () => {
    if (!showLabelsBasedOnSelection || !selectedIndexLocal) return totalCount
    const count = data?.find((entry) => entry.name === selectedIndexLocal)
    return count.total_value
  }

  return (
    <PieChart width={width} height={height}>
      <Legend
        layout='vertical'
        verticalAlign='middle'
        align='right'
        content={renderLegend}
        margin={{ top: 40 }}
        cx={0}
      />
      <Pie
        cx={cx}
        data={totalCount > 0 ? data : [{ name: 'any', total_value: 1 }]}
        innerRadius={50}
        outerRadius={68}
        fill='#8884d8'
        dataKey='total_value'
        labelLine={false}
        paddingAngle={2}>
        {getGraphComponent()}
        <Label
          value={millifyStats ? millify(getTotalCount()) : getTotalCount()}
          position='centerTop'
          className='custom-pie-chart__label'
          fill={totalCount > 0 ? colorPalette[0] || '#000' : '#98a2b3'}
        />
        <Label
          value={getPercentage()}
          position='centerBottom'
          className='custom-pie-chart__transform'
          fontSize='12px'
        />
      </Pie>
      {
        <Customized
          component={() => <line x1='100' y1='92' x2='40' y2='92' stroke='#EAECF0' stroke-width='0.8'></line>}
        />
      }
    </PieChart>
  )
})
