import { useEffect, useState } from 'react'
import { store } from 'store'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffectOnce, useUnmount } from 'react-use'

import { IListItem, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { CreateBookmarkModal } from 'version2/components/Investigate/Bookmarks/CreateBookmarkModal/CreateBookmarkModal'
import { RequestModal } from 'version2/components/common/RequestModal/RequestModal'
import { ListHeader } from '../ListHeader/ListHeader'
import { ListItem } from '../ListItem/ListItem'

import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow.svg'

import './List.scss'

type Props = {
  mode: monitorMode
}

export const List = observer(({ mode }: Props) => {
  const { querry } = useParams()

  const { manageCommunitiesStore, monitorTags, loaderStore, userStore, usersStore, tenantsStore } = store
  const {
    listItems,
    listItemsCreatedByUser,
    listItemsSharedWithUser,
    activeItem,
    listItemsPagination,
    listItemsByUserPagination,
    listItemsSharedWithUserPagination,
    fetchListItemsSharedWithUser,
    fetchListItemsByUser,
    fetchListItems,
    setUserID,
    listItemsNarrativeLibrary,
    paginationNarLib,
    fetchListItemsByNarrativeLibrary,
    resetStore,
  } = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { fetchUsers } = usersStore
  const { userId } = userStore
  const { isRequestModalOpen, setIsRequestModalOpen, submitCommunity } = manageCommunitiesStore
  const { tagsFollowedByUser, tagsFollowedByUserPagination, fetchTagsFollowedByUser } = monitorTags
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

  const narrativeText = changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)

  const [isBookmarksModalVisible, setIsBookmarksModalVisible] = useState(false)

  useEffect(() => {
    setUserID(userId)
  }, [userId])

  useEffectOnce(() => {
    fetchUsers(1, 10)
    if (!querry) {
      if (mode === 'narrative' || mode === 'bookmark') {
        fetchListItemsSharedWithUser({ pageSize: 6 })
        fetchListItemsByUser({ pageSize: 6 })
        if (mode === 'narrative') fetchListItemsByNarrativeLibrary({ pageSize: 6 })
      } else if (mode === 'tag') fetchTagsFollowedByUser({ pageSize: 6 })

      if (mode !== 'narrative' && mode !== 'bookmark') fetchListItems({ pageSize: 6 })
    }
  })

  useEffect(() => {
    if (querry?.length) {
      if (mode === 'narrative' || mode === 'bookmark') {
        fetchListItemsSharedWithUser({ querry, pageSize: 6 })
      } else fetchListItems({ querry, pageSize: 6 })
    }
  }, [querry])

  useUnmount(() => {
    resetStore()
  })

  const closeBookmarkModalHandler = (isBookmarkCreated?: boolean) => {
    setIsBookmarksModalVisible(false)

    // also, check if a new bookmark was created or not. If yes, then fetch the list items again
    if (isBookmarkCreated) {
      fetchListItemsSharedWithUser({ pageSize: 6 })
      fetchListItemsByUser({ pageSize: 6 })
    }
  }

  const getList = (
    data: IListItem[],
    pagination: {
      total: number
      locallyLoaded: number
    },
    heading: string,
    loaders: boolean,
    listName: 'User' | 'Suggestions' | 'Followed' | 'All' | 'NarrativeLibrary' | '',
    fetch: ({
      id,
      querry,
      pageSize = 20,
      activeId = '',
      loadNew = false,
    }: {
      id?: string
      querry?: string
      pageSize?: number
      activeId?: string
      loadNew?: boolean
    }) => Promise<void>,
  ) => {
    return (
      <>
        <div className='list__header__sticky'>
          <h5 className='list__header__title'>{heading}</h5>
        </div>

        <Spin spinning={loaders}>
          {mode === 'bookmark' && listName === 'User' && (
            <div className='list__view-all-section list__view-all-section__no-margin'>
              <button className='list__view-all-section__btn' onClick={() => setIsBookmarksModalVisible(true)}>
                + Create new list
              </button>
            </div>
          )}

          <div className='list__items-div'>
            {(data.length ? data : []).map((item: IListItem) => {
              const isSelected = item.id === activeItem?.id
              return (
                <ListItem
                  page={mode}
                  mode={STORE_LOOKUP_DICTIONARY[mode]}
                  listName={listName}
                  data={item}
                  isSelected={isSelected}
                />
              )
            })}
          </div>
        </Spin>

        {pagination.locallyLoaded !== pagination.total && (
          <div className='list__view-all-section'>
            <button className='list__view-all-section__btn' onClick={() => fetch({ querry, pageSize: 6 })}>
              Show more
              <RightArrowIcon height={12} width={12} className='list__view-all-section__btn--icon' />
            </button>
          </div>
        )}
      </>
    )
  }

  return (
    <div className='list'>
      <ListHeader mode={mode} />

      <div className='list__wrapper'>
        {mode === 'narrative' && (
          <>
            {!querry?.length &&
              getList(
                listItemsCreatedByUser,
                listItemsByUserPagination,
                'Created by You',
                loaderStore[`isLoadingUserNarratives`] && !loaderStore[`isLoadingTags`],
                'User',
                fetchListItemsByUser,
              )}
            {getList(
              listItemsSharedWithUser,
              listItemsSharedWithUserPagination,
              querry?.length ? 'Results' : 'Shared with You',
              loaderStore[`isLoadingNarratives`] && !loaderStore[`isLoadingTags`],
              'Suggestions',
              fetchListItemsSharedWithUser,
            )}
            {!querry?.length &&
              getList(
                listItemsNarrativeLibrary,
                paginationNarLib,
                `${narrativeText} Library`,
                loaderStore[`isLoadingUserNarratives`] && !loaderStore[`isLoadingTags`],
                'NarrativeLibrary',
                fetchListItemsByNarrativeLibrary,
              )}
          </>
        )}

        {mode === 'bookmark' && (
          <>
            {!querry?.length &&
              getList(
                listItemsCreatedByUser,
                listItemsByUserPagination,
                'Created by You',
                loaderStore[`isLoadingUserBookmarks`],
                'User',
                fetchListItemsByUser,
              )}
            {getList(
              listItemsSharedWithUser,
              listItemsSharedWithUserPagination,
              querry?.length ? 'Results' : 'Shared with You',
              loaderStore[`isLoadingBookmarks`],
              'Suggestions',
              fetchListItemsSharedWithUser,
            )}
          </>
        )}

        {mode === 'watchlist' && (
          <>
            {getList(
              listItems,
              listItemsPagination,
              querry?.length ? 'Results' : 'All Watchlists',
              loaderStore[`isLoadingWatchlist`],
              'Suggestions',
              fetchListItems,
            )}
          </>
        )}

        {mode === 'community' && (
          <>
            {getList(
              listItems,
              listItemsPagination,
              querry?.length ? 'Results' : 'All Communities',
              loaderStore[`isLoadingCommunities`],
              'Suggestions',
              fetchListItems,
            )}
          </>
        )}

        {mode === 'tag' && (
          <>
            {!querry?.length &&
              getList(
                tagsFollowedByUser,
                tagsFollowedByUserPagination,
                'Followed',
                loaderStore['isLoadingTags'],
                'Followed',
                fetchTagsFollowedByUser,
              )}

            {getList(
              listItems,
              listItemsPagination,
              querry?.length ? 'Results' : 'All',
              loaderStore['isLoadingTags'],
              'All',
              fetchListItems,
            )}
          </>
        )}
      </div>

      {isBookmarksModalVisible && <CreateBookmarkModal isModalVisible closeModal={closeBookmarkModalHandler} />}

      <RequestModal
        mode='community'
        isVisible={isRequestModalOpen}
        setIsModalVisible={setIsRequestModalOpen}
        submit={submitCommunity}
      />
    </div>
  )
})
