import { observer } from 'mobx-react-lite'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { InvestigateSecondaryNavigation } from 'version2/components/common/SecondaryNavigation/InvestigateSecondaryNavigation/InvestigateSecondaryNavigation'
import { TopLevelNavigation } from 'version2/components/common/TopLevelNavigation/TopLevelNavigation'

export const InvestigatePage = observer(() => {
  const location = useLocation()
  const subNavigation = ['bookmarks', 'whiteboards', 'downloads']

  const containsSubNav = subNavigation.some((el) => location.pathname.includes(el))

  return (
    <>
      {!containsSubNav && <Navigate to='bookmarks' />}
      {<TopLevelNavigation page='My Stuff' />}
      <InvestigateSecondaryNavigation />
      <Outlet />
    </>
  )
})
