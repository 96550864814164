import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { FeedsDataType, FeedsParamsType } from 'store/monitorVersion2/types'

export function getFeeds({ params, data }: { params: FeedsParamsType; data: FeedsDataType }) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SEARCH_API}${ROUTES.reportsFeeds}`,
    params,
    data,
  })
}
