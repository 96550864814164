import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { LandingPage as ThreeJsLandingPage } from 'version2/3js/pages/landing/Landing.page'
import { ReactComponent as ListViewIcon } from 'assets/images/listview-icon.svg'
import { ReactComponent as ChordChartIcon } from 'assets/images/chord-chart-icon.svg'
import { Space, Tooltip } from 'antd'
import { ReactComponent as ThreeDIcon } from 'assets/images/riskwand/3d_chart_icon.svg'
import { ReactComponent as ElectionChordChartIcon } from 'assets/images/elecion-icon.svg'
import dayjs from 'dayjs'
import img from 'assets/images/icons/colorIcon.svg'
import mapIcon from 'assets/images/icons/mapIcon.svg'
import BottomDropdown from 'components/common/dropdown/dropdown'
import ActionDropDown from 'components/common/dropdown/ActionDropDown'
import 'react-datepicker/dist/react-datepicker.css'
import './ThreeDChart.scss'
import 'antd/dist/antd.css'
import DatePicker from 'react-datepicker'
import {
  getLandscape,
  searchData,
  getExecutiveSummary,
  getTrendsPosts,
  getTrendsImpression,
  getPostSentiment,
  getImpressionsSentiment,
  getPostPlatforms,
  getImpressionsPlatforms,
  getPlatformDistribution,
  getAllMaps,
  getCreateAllMaps,
  getNarrativeCrud,
  getNarrativeSearchCrud,
  getFlagsCrud,
  getFlagsSearchCrud,
  getAssetsCrud,
  getAssetsSearchCrud,
  getDeleteCrud,
  getShareUserCrud,
  getShareUserData,
  getSharePostUserData,
  getShareUserSearch,
  getLandscapeMapsData,
  getLandscapeEditMaps,
  getWatchListCrud,
  getWatchListSearchCrud,
  getUpdateEditMap,
} from '../../../../api/pendulum3DAPI/3dLandscapeData'
import { SnippetsFilter } from 'models/models'
import CalendarDropdown from 'components/common/dropdown/CalendarDropdown'
import usePermissions from 'version2/hooks/usePermissions'
import useDelayedLoading from 'version2/utils/useDelayedLoading'
import PendulumLoader from 'version2/components/PendulumLoader/PendulumLoader'

const ThreeDChart = observer(() => {
  const { anticipatoryIntelligenceStore, loaderStore } = store
  const { chartName, setChartName } = anticipatoryIntelligenceStore
  const colorItem = [
    {
      key: '1',
      text: 'Sentiment',
      value: 'Sentiment',
    },
    {
      key: '2',
      text: 'Toxicity',
      value: 'Toxicity',
    },
    {
      key: '3',
      text: 'Risk Score',
      value: 'Risk Score',
    },
  ]

  const { snippetsFilter, setSnippetsFilter } = anticipatoryIntelligenceStore
  const { landscape3DLoading, statsSummaryLoading } = loaderStore

  const isLoading = useDelayedLoading([landscape3DLoading, statsSummaryLoading])

  const [selectedLocalFilter, setSelectedLocalFilter] = useState(snippetsFilter)
  const [mapValue, setMapValue] = useState('Default Maps')
  const [selectedValue, setSelectedValue] = useState('Default Maps')
  const canCreateNewMap = usePermissions('riskWand') === 'full_access'

  const daysList: any[] = []

  const days = [1, 7, 30, 90]
  days.forEach((el) => {
    daysList.push(
      <div
        style={{
          padding: '.5rem',
          border: '2px solid #EAECF0',
          backgroundColor: '#FCFCFD',
        }}>
        <Space direction='vertical'>
          <div
            style={{ cursor: 'pointer' }}
            key={el}
            onClick={() => {
              setStartDate(dayjs().subtract(Number(el), 'day').format('YYYY-MM-DD'))
              const filter = {
                custom: {
                  isApplied: false,
                  dateRange: [],
                },
                noOfDays: Number(el),
              }
              const modFilter = { ...selectedLocalFilter }
              modFilter['days'] = filter
              setSelectedLocalFilter(modFilter)
              setSnippetsFilter({ ...snippetsFilter, ...modFilter } as SnippetsFilter)
            }}>
            <span
              className={classNames('feed-filters__dropdown-item__text', {
                'feed-filters__dropdown-item--selected': snippetsFilter.days.noOfDays === el,
              })}>
              {el === 1
                ? `Yesterday`
                : el === 180
                ? 'Last 6 months'
                : el === 365
                ? 'Last 1 year'
                : el === 730
                ? 'Last 2 years'
                : `Last ${el} days`}
            </span>
          </div>
        </Space>
      </div>,
    )
  })
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const calenderRef = useRef<any>()
  const openCalendar = () => {
    setIsCalendarOpen((state) => !state)
    const filter = {
      days: {
        custom: {
          isApplied: snippetsFilter.days.custom.isApplied,
          dateRange: snippetsFilter.days.custom.dateRange,
        },
        noOfDays: 0,
      },
    }
    setSnippetsFilter({ ...snippetsFilter, ...filter })
  }

  const [date, setDate] = useState<any>({ start: dayjs().toDate(), end: dayjs().toDate() })
  const [startDate, setStartDate] = useState(dayjs().subtract(90, 'day').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [landscapeData, setLandscapeData] = useState<any[]>([])
  const [allMapsResponse, setAllMapsResponse] = useState<any[]>([])
  const [mapCrudView, setMapCrudView] = useState('')
  const [defaultMap, setDefaultMap] = useState<any[]>([])
  const [seconddefaultMap, setSecondDefaultMap] = useState<any[]>([])

  const [mapValues, setMapValues] = useState('')
  const [colorValue, setColorValue] = useState('')
  const fetchData = async () => {
    if (mapValue === 'Default Maps') {
      try {
        setMapValue('Default Maps')
        setMapValues('')
        const response = await getLandscape()
        const allMapResponse = await getAllMaps()
        setAllMapsResponse(allMapResponse)
        const promises = response?.nodes.map(async (node: any) => {
          const queryCondition = node?.query_condition || []
          const apiResponses = await Promise.all([searchData(queryCondition, startDate, endDate)])
          return apiResponses.map((apiResponse) => ({ ...node, ...apiResponse }))
        })
        const resultArrays = await Promise.all(promises)
        const reformattedData = resultArrays.flat()
        setLandscapeData(reformattedData)
        setDefaultMap(reformattedData)
        console.log(reformattedData)
      } catch (error) {
        console.error('Error fetching landscape data:', error)
      }
    } else {
      setLandscapeData(seconddefaultMap)
      const allMapResponse = await getAllMaps()
      setAllMapsResponse(allMapResponse)
      setMapValue(mapValue)
      setMapValues(mapValues)
    }
  }
  console.log(colorValue)
  useEffect(() => {
    if (mapCrudView === '') {
      fetchData()
      setMapValue('Default Maps')
    }
    setMapValues(mapValues)
  }, [mapCrudView])

  const MapClickHandler = async (e: any, id: any) => {
    if (id === 'default') {
      setLandscapeData(defaultMap)
      setMapValue('Default Maps')
    } else {
      setMapValue(e?.target?.value || selectedValue)
      setMapValues(id)
      try {
        const response = await getLandscapeMapsData(id)
        const promises = response?.nodes.map(async (node: any) => {
          const queryCondition = node?.query_condition || []
          const apiResponses = await Promise.all([searchData(queryCondition, startDate, endDate)])
          return apiResponses.map((apiResponse) => ({ ...node, ...apiResponse }))
        })
        const resultArrays = await Promise.all(promises)
        const reformattedData = resultArrays.flat()
        setLandscapeData(reformattedData)
        setSecondDefaultMap(reformattedData)
      } catch (error) {
        console.error('Error fetching landscape data:', error)
        setLandscapeData(defaultMap)
      }
    }
  }

  useEffect(() => {
    if (selectedValue === 'Default Maps') {
      fetchData()
    } else {
      MapClickHandler(selectedValue, mapValues)
    }
  }, [startDate && endDate && !isCalendarOpen, startDate, endDate])

  const onDateChange = (dates: any) => {
    const [start, end] = dates
    const startDate = dayjs(start).format('YYYY-MM-DD')
    const endDate = dayjs(end).format('YYYY-MM-DD')
    setStartDate(startDate)
    setDate({ start: start, end: null })
    if (end) {
      setDate({ start: start, end: end })
      setEndDate(dayjs(end).format('YYYY-MM-DD'))
      const filter = {
        days: {
          custom: {
            isApplied: true,
            dateRange: [`${startDate}`, `${endDate}`],
          },
          noOfDays: 0,
        },
      }
      setSnippetsFilter({ ...snippetsFilter, ...filter })
      setIsCalendarOpen(false)
    }
  }

  const DeleteApifunc = async () => {
    await getDeleteCrud(mapValues)
    const allMapResponse = await getAllMaps()
    setAllMapsResponse(allMapResponse)
    setSelectedValue('Default Maps')
    setMapValue('Default Value')
    setLandscapeData(defaultMap)
  }

  return (
    <div className='three_d_chart_container'>
      {mapCrudView !== 'edit' && mapCrudView !== 'create' && (
        <div className='tdcc_header'>
          <div className='tdcc_header_links_container'>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === 'list',
              })}
              onClick={() => setChartName('list')}>
              <Tooltip title='List View' placement='bottom'>
                <ListViewIcon aria-label='List View' className='tdcc_header_lc_l_icon' />
              </Tooltip>
              {chartName === 'list' && <span className={classNames('tdcc_header_lc_al_title')}>List View</span>}
            </div>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === 'chord',
              })}
              onClick={() => setChartName('chord')}>
              <Tooltip title='Chord Chart' placement='bottom'>
                <ChordChartIcon aria-label='Chord Chart' className='tdcc_header_lc_l_icon' />
              </Tooltip>
              {chartName === 'chord' && <span className={classNames('tdcc_header_lc_al_title')}>Chord Chart</span>}
            </div>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === '3d',
              })}
              onClick={() => setChartName('3d')}>
              <Tooltip title='3D Chart' placement='bottom'>
                <ThreeDIcon className='tdcc_header_lc_l_icon tdcc_header_lc_l_3d_icon' aria-label='3D Chart' />
              </Tooltip>

              {chartName === '3d' && <span className={classNames('tdcc_header_lc_al_title')}>3D Charts</span>}
            </div>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === 'election',
              })}
              onClick={() => setChartName('election')}>
              <Tooltip title='Election Chord Chart' placement='bottom'>
                <ElectionChordChartIcon className='tdcc_header_lc_l_icon' aria-label='Election Chord Chart' />
              </Tooltip>
              {chartName === 'election' && (
                <span className={classNames('tdcc_header_lc_al_title')}>Election Chord Chart</span>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '1' }}>
            <BottomDropdown
              icon={mapIcon}
              text={mapValue}
              items={allMapsResponse}
              setValues={MapClickHandler}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
            <BottomDropdown
              icon={img}
              text='Color'
              items={colorItem}
              setValues={setColorValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
            {isCalendarOpen ? (
              <div
                style={{
                  zIndex: 1000,
                  display: 'flex',
                  justifyContent: 'center',
                  background: 'white',
                  width: '125px',
                  height: '32px',
                  color: 'black',
                  overflow: 'hidden',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}>
                <div ref={calenderRef}>
                  <DatePicker
                    calendarClassName='feed-filters__date'
                    startDate={date.start}
                    endDate={date.end}
                    onChange={onDateChange}
                    selectsRange={true}
                    inline
                    maxDate={new Date()}
                  />
                </div>
              </div>
            ) : (
              <CalendarDropdown snippetsFilter={snippetsFilter} daysList={daysList} openCalendar={openCalendar} />
            )}
            {canCreateNewMap && (
              <ActionDropDown
                text='Actions'
                items={[
                  {
                    key: '1',
                    text: 'Create new map',
                    value: 'create',
                  },
                  {
                    key: '2',
                    text: 'Share map',
                    value: 'share',
                  },
                  {
                    key: '3',
                    text: 'Edit map',
                    value: 'edit',
                  },
                  {
                    key: '4',
                    text: 'Delete map',
                    value: 'delete',
                  },
                ]}
                DeleteApifunc={DeleteApifunc}
                setMapCrudView={setMapCrudView}
                mapValues={mapValues}
                mapValue={mapValue}
              />
            )}
          </div>
        </div>
      )}
      <div className='tdcc_3d_graph'>
        {isLoading && <PendulumLoader overLay height='100%' text='Pendulum is gathering the data' />}
        <ThreeJsLandingPage
          landscapeData={landscapeData}
          isLoadingSummary={isLoading}
          getExecutiveSummary={getExecutiveSummary}
          getTrendsPosts={getTrendsPosts}
          getTrendsImpression={getTrendsImpression}
          getPostSentiment={getPostSentiment}
          getImpressionsSentiment={getImpressionsSentiment}
          getPostPlatforms={getPostPlatforms}
          getImpressionsPlatforms={getImpressionsPlatforms}
          getPlatformDistribution={getPlatformDistribution}
          getAllMaps={getAllMaps}
          getCreateAllMaps={getCreateAllMaps}
          searchData={searchData}
          getNarrativeCrud={getNarrativeCrud}
          getNarrativeSearchCrud={getNarrativeSearchCrud}
          getFlagsCrud={getFlagsCrud}
          getFlagsSearchCrud={getFlagsSearchCrud}
          getAssetsCrud={getAssetsCrud}
          getAssetsSearchCrud={getAssetsSearchCrud}
          getDeleteCrud={getDeleteCrud}
          setMapCrudView={setMapCrudView}
          mapCrudView={mapCrudView}
          mapValues={mapValues}
          getShareUserCrud={getShareUserCrud}
          getShareUserData={getShareUserData}
          getSharePostUserData={getSharePostUserData}
          getLandscapeEditMaps={getLandscapeEditMaps}
          getWatchListCrud={getWatchListCrud}
          getWatchListSearchCrud={getWatchListSearchCrud}
          getUpdateEditMap={getUpdateEditMap}
          getShareUserSearch={getShareUserSearch}
          startDate={startDate}
          endDate={endDate}
          mapValue={mapValue}
        />
      </div>
    </div>
  )
})

export default ThreeDChart
