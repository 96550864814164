import { useEffect } from 'react'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as AddIcon } from 'assets/images/icons/flag/add_icon.svg'
import { FlagCategoryType, FlagListParamsType } from 'store/flags/types'
import './FlagsCategories.scss'
import TagManager from 'react-gtm-module'

type FlagsCategoriesProps = {
  searchValue: string
  selectedCategory: FlagCategoryType
  setSelectedCategory: (categoryId: FlagCategoryType) => void
}

const FlagsCategories = observer((props: FlagsCategoriesProps) => {
  const { flagsStore, tenantsStore, userStore } = store
  const { userId, userName, tenantId, roleId } = userStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { fetchCategories, fetchFlagsList, flagsCategorties, paginationValues, setPaginationValues } = flagsStore
  const { searchValue, selectedCategory, setSelectedCategory } = props

  useEffect(() => {
    fetchCategories()
  }, [])

  const categoryHandler = (categoryId: string) => {
    let requestParams: FlagListParamsType = {
      page: paginationValues.page,
      per_page: paginationValues.pageSize,
      sort: 'name:asc',
    }
    if (searchValue) {
      requestParams['q'] = requestParams['q']
        ? `${requestParams['q']},name:like:${searchValue}`
        : `name:like:${searchValue}`
    }
    if (categoryId !== '0') {
      requestParams['q'] = requestParams['q']
        ? `${requestParams['q']},category_id:eq:${categoryId}`
        : `category_id:eq:${categoryId}`
    }
    setPaginationValues({ page: 1, pageSize: 10 })
    fetchFlagsList({ params: requestParams })
    TagManager.dataLayer({
      dataLayer: {
        event: 'navigate_flags',
        //@ts-ignore
        user_id: userId,
        tenantId: tenantId,
        roleId: roleId,
        user_name: userName,
        categoryId: categoryId,
        searchText: searchValue,
      },
    })
  }

  return (
    <div className='flags_categories_container'>
      <div className='fcc_list'>
        {[
          {
            category_id: '0',
            name: `All ${changeLabelTextBasedOnTheme('Flags', isCorporateCommunicationsTheme)}`,
          },
          ...flagsCategorties,
        ].map((category) => (
          <ButtonImage
            key={category.category_id}
            onClick={() => {
              setSelectedCategory(category)
              categoryHandler(category.category_id)
            }}
            className={`fcc_l_button${
              category.category_id === selectedCategory.category_id ? ' fcc_l_button_active' : ''
            }`}>
            {category.name}
          </ButtonImage>
        ))}
      </div>
      <ButtonImage icon={AddIcon} onClick={() => {}} className='fcc_add_button' disabled={true}>
        Add new category
      </ButtonImage>
    </div>
  )
})

export default FlagsCategories
