import { makeObservable } from 'mobx'
import { manageMode } from 'models/models'

import { ManageStore2 } from './manage.store'

export class ManageCommunitiesStore2 extends ManageStore2 {
  storeType: manageMode = 'community'

  constructor() {
    super()
    makeObservable(this, {})
  }
}
