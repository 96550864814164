import { sourcePlatform } from 'models/models'

// eslint-disable-next-line no-unused-vars
const PLATFORM_STRING_TO_BRAND_NAME_MAP: { [K in Lowercase<sourcePlatform>]: sourcePlatform } = {
  youtube: 'YouTube',
  rumble: 'Rumble',
  bitchute: 'BitChute',
  podcast: 'Podcast',
  twitter: 'Twitter',
  news: 'News',
  tiktok: 'TikTok',
  reddit: 'Reddit',
  '4chan': '4chan',
  gab: 'Gab',
  truthsocial: 'TruthSocial',
  'patriots.win': 'Patriots.win',
  vk: 'vk',
  instagram: 'Instagram',
  threads: 'Threads',
  telegram: 'Telegram',
  'c-span': 'C-SPAN',
  consilium: 'Consilium',
  websearch: 'WebSearch',
  brighteon: 'Brighteon',
  snapchat: 'Snapchat',
  odysee: 'Odysee',
}

export const getPlatformBrandName = (platform: Lowercase<sourcePlatform>) => {
  return PLATFORM_STRING_TO_BRAND_NAME_MAP[platform]
}
