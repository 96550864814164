import { Dropdown, Input, Menu, Radio } from 'antd'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { store } from 'store'

import { monitorMode } from 'models/models'

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'
import { ReactComponent as SortIcon } from 'assets/images/sort-icon.svg'
import { ReactComponent as AscendingIcon } from 'assets/images/ascending-icon.svg'
import { ReactComponent as DescendingIcon } from 'assets/images/descending-icon.svg'

import './BarChartHeader.scss'

interface Props {
  mode: monitorMode
  tab: 'company' | 'people' | 'language' | 'watchlist'
  indexName: 'companyData' | 'peopleData' | 'languagesData' | 'watchlistData'
  searchCheck: (check: boolean) => void
}

export const BarChartHeader = observer(({ mode, tab, indexName, searchCheck }: Props) => {
  const { combineMonitorViewStore } = store
  const {
    powerInsightSortFilter,
    availablePowerInsightsSortFilters,
    filterPowerInsightBarChartData,
    setPowerInsightsSortFilter,
    fetchBarChartData,
  } = combineMonitorViewStore

  const sortKeys = Object.keys(availablePowerInsightsSortFilters) as Array<
    keyof typeof availablePowerInsightsSortFilters
  >

  const getSortMenu = () => {
    const menuArray: {
      label: string | JSX.Element
      key: string
      disabled?: boolean
      className: string
      onClick?: (args: any) => void
    }[] = []
    sortKeys.forEach((group) => {
      if (group === 'sort')
        menuArray.push({
          label: (
            <span className='bar-chart-header__filter__title disabled-item' key='sort_by'>
              Sort by
            </span>
          ),
          key: 'sort_by',
          disabled: true,
          className: 'bar-chart-header__filter__title disabled-item',
        })
      else if (group === 'sort_mode')
        menuArray.push({
          label: (
            <span className='bar-chart-header__filter__title--margin disabled-item' key='sort_by'>
              Order By
            </span>
          ),
          key: 'sort_mode',
          disabled: true,
          className: 'disabled-item bar-chart-header__filter__title--margin',
        })

      availablePowerInsightsSortFilters[group].forEach((el, index) => {
        const array = el.split(' ')
        const title = array.reduce((previous: string, current: string) => {
          return `${previous} ${current[0].toUpperCase()}${current.substring(1)}`
        })
        menuArray.push({
          label:
            group === 'sort' ? (
              <span className='bar-chart-header__filter__dropdown-item__text'>
                <Radio checked={powerInsightSortFilter[group] === el}>{title}</Radio>
              </span>
            ) : (
              <button
                className={classNames('bar-chart-header__filter__dropdown-item__mode-btn', {
                  'bar-chart-header__filter__dropdown-item__mode-btn--active': powerInsightSortFilter[group] === el,
                })}>
                <span className='bar-chart-header__filter__dropdown-item__mode-btn__title'>
                  {title === 'Ascending' ? <AscendingIcon /> : <DescendingIcon />}
                  {title}
                </span>
              </button>
            ),
          key: group + index,
          className: classNames('bar-chart-header__filter__dropdown-item', {
            'bar-chart-header__filter__dropdown-item--inline': group === 'sort_mode',
          }),
          onClick: ({ domEvent }: any) => {
            domEvent.preventDefault()
            domEvent.stopPropagation()
            return onSortClick(group, el)
          },
        })
      })
    })
    return menuArray
  }

  const onSortClick = (group: 'sort' | 'sort_mode', el: string) => {
    const modFilter: any = { ...powerInsightSortFilter }
    modFilter[group] = el
    setPowerInsightsSortFilter(modFilter)
    fetchBarChartData(tab)
  }

  const sortDropdown = <Menu className='bar-chart-header__filter__dropdown-item__menu' items={getSortMenu()} />

  const getHeaderText = () => {
    if (tab === 'company' || tab === 'people')
      return `Hover over any ${tab} to see details or select to apply it as filter.`
    else if (tab === 'language') return 'Click on any language bar to apply it as filter.'
    else if (tab === 'watchlist') return 'A bar chart to visualise the watchlist content'
  }
  return (
    <div className={'bar-chart-header'}>
      <span className='bar-chart-header__description'>{getHeaderText()}</span>
      {tab !== 'language' && (
        <div className='bar-chart-header__filter'>
          <Input
            onPressEnter={async (e: any) => {
              if (indexName !== 'languagesData') {
                const input = e.target.value
                input.length > 0 ? searchCheck(true) : searchCheck(false)
                filterPowerInsightBarChartData(indexName, input)
              }
            }}
            placeholder={`Search any ${tab}`}
            className={'bar-chart-header__input'}
            prefix={<SearchIcon />}
          />
          <Dropdown
            className='bar-chart-header'
            overlayClassName='bar-chart-header__filter__dropdown'
            trigger={['click']}
            placement={'bottomRight'}
            overlay={sortDropdown}>
            <div>
              <SortIcon className='bar-chart-header__filter__icon' />
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  )
})
