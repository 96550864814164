import { API, tagAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { ILightTag, IListItem, manageMode } from 'models/models'
import { UtilService } from 'services/Util/Util'

export class ManageStore2 {
  storeType: manageMode = 'tag'
  tabs = {
    tag: ['tags', 'narratives', 'communities', 'actions'],
    narrative: ['narratives', 'tags', 'alerts', 'actions'],
    watchlist: ['watchlist', 'platforms', 'alerts', 'actions'],
    community: ['community', 'tags', 'actions'],
  }
  pageSize: number = 20
  userID: string | null = null
  subMenuSelectedTab = {
    tag: 'all',
    narrative: 'created',
    watchlist: 'created',
    community: '',
  }
  listItems: IListItem[] = []
  listItemsPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }
  allTags: ILightTag[] = []

  constructor() {
    makeObservable(this, {
      userID: observable,
      subMenuSelectedTab: observable,
      listItems: observable,
      listItemsPagination: observable,
      allTags: observable,
      setUserID: action.bound,
      setSubMenuSelectedTab: action.bound,
      setListItems: action.bound,
      setListItemsPagination: action.bound,
      setAllTags: action.bound,
      updateListItems: action.bound,
    })
  }

  setUserID = (id: string | null) => {
    this.userID = id
  }

  setSubMenuSelectedTab = (key: 'tag' | 'narrative' | 'watchlist' | 'community', value: string) => {
    this.subMenuSelectedTab[key] = value
  }

  setListItemsPagination = (pagination: { total: number; locallyLoaded: number }) => {
    this.listItemsPagination = pagination
  }

  setAllTags = (lightTags: ILightTag[]) => {
    this.allTags = lightTags
  }

  setListItems = (items: IListItem[]) => {
    this.listItems = items
  }

  updateListItems = (items: IListItem[]) => {
    this.listItems = [...this.listItems, ...items]
  }

  fetchTags = async () => {
    try {
      const data = await tagAPI.getSummaryTags()
      this.setAllTags(data.tags)
    } catch (e) {}
  }

  createTag = async (tagLabel: string, id: string, type?: manageMode | 'channel') => {
    try {
      const { data } = await tagAPI.createTag(tagLabel)
      this.allTags.push({ label: data.label, id: data.id })

      await this.actionTag({ action: 'apply', tagId: data.id, id, type })
      return data.id
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  actionTag = async ({
    action,
    tagId,
    id,
    type,
    bulkAdd = false,
  }: {
    action: 'apply' | 'remove'
    tagId: string
    id: string
    type?: manageMode | 'channel'
    bulkAdd?: boolean
  }) => {
    try {
      await tagAPI.addTag({ tagId, action, type: type ? type : this.storeType, id })
    } catch (e: any) {
      if (!bulkAdd) {
        const response = e.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      } else console.log(e)
    }
  }

  fetchListItems = async ({ isRefreshing }: { isRefreshing?: boolean } = {}) => {
    try {
      let items

      if (
        !isRefreshing &&
        this.listItemsPagination.total <= this.listItemsPagination.locallyLoaded &&
        this.listItemsPagination.total !== 0
      )
        return

      let page = Math.ceil(this.listItemsPagination.locallyLoaded / this.pageSize) + 1
      let pageSize = this.pageSize

      if (isRefreshing) {
        page = 1
        pageSize = this.listItemsPagination.locallyLoaded
      }

      let { data, total } = await API.get({
        page,
        pageSize,
        route: this.storeType,
        getError: true,
        sort: `${this.storeType === 'tag' ? 'label:asc' : 'name:asc'}`,
      })

      if (!isRefreshing) {
        this.setListItemsPagination({
          total,
          locallyLoaded: this.listItemsPagination.locallyLoaded + data.items.length,
        })
      }

      items = Array.isArray(data) ? [...data] : data.items

      const updatedData = items?.map((item: any) => {
        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive' as const,
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
          channels: item?.channels,
          is_followed: item.is_followed,
          narrativeCount: item.narrative_count || undefined,
          communityCount: item.community_count || undefined,
        }
      })

      if (isRefreshing) {
        this.setListItems(updatedData)
      } else {
        this.updateListItems(updatedData)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchDetailedData = async (id: string) => {}

  resetStore = () => {}
}
