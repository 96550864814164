import { Alert, Spin } from 'antd'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import { observer } from 'mobx-react-lite'
import { useEffectOnce } from 'react-use'
import { useState } from 'react'
import classNames from 'classnames'
import { store } from 'store'

import { UtilService } from 'services/Util/Util'
import { analyzeAPI } from 'api/api'
import { Card } from 'components/common'
import './AnalyzeFrame.scss'

export const AnalyzeFrame = observer(() => {
  const { userStore } = store
  const { tenantId } = userStore
  const { userId } = userStore
  //for only thouse 2 , I feelt like it is useless to create a new store
  const [isLoading, setIsLoading] = useState(false)
  const [dashboardError, setDasboardError] = useState(false)

  const onError = (payload: any) => {
    UtilService.openNotification({ type: 'error', message: 'Error', description: payload.errorCode })
    setDasboardError(true)
  }

  useEffectOnce(() => {
    setIsLoading(true)
    ;(async () => {
      try {
        const url = await analyzeAPI.getDashboardUrl({})

        setDasboardError(false)

        const options = {
          url,
          container: document.getElementById('quicksight-workbench-container'),
          parameters: {
            tenantid: tenantId,
            userid: userId,
          },
          scrolling: 'no',
          height: '1600px',
        }

        const dashboard = embedDashboard(options)
        dashboard.on('error', onError)
        setIsLoading(false)
      } catch (error) {
        setDasboardError(true)
        setIsLoading(false)
      }
    })()
  })
  return (
    <section className='c-workbench-frame'>
      <Card variant='1'>
        <Spin size='large' spinning={isLoading}>
          <Alert
            className={classNames({ hidden: !dashboardError }, 'c-workbench-alert')}
            message='The workbench is not available now, please try again later.'
            type='error'
          />

          <div className={classNames({ hidden: dashboardError })} id='quicksight-workbench-container' />
        </Spin>
      </Card>
    </section>
  )
})
