import { ListPageSection } from 'version2/components/common/ListPageSection/ListPageSection'
import { SnippetPage } from 'version2/components/common/Snippets/SnippetList/SnippetPage'
import { FeedDetail } from 'version2/components/Monitor/common/Feed/FeedDetail/FeedDetail'
import { List } from 'version2/components/Monitor/common/List/List/List'

interface InvestigateBookmarksPageProps {
  page: 'list' | 'details'
}

export const InvestigateBookmarksPage = ({ page }: InvestigateBookmarksPageProps) => {
  let type

  switch (page) {
    case 'list':
      type = <List mode='bookmark' />
      break
    case 'details':
      type = <FeedDetail mode='bookmark' />
      break
    default:
      type = <List mode='bookmark' />
      break
  }

  return (
    <div>
      <ListPageSection
        left={type}
        right={<SnippetPage mode='bookmark' page='investigate-bookmark' />}
        columns={{
          col1: {
            sm: 24,
            md: 8,
            lg: 8,
          },
          col2: {
            sm: 24,
            md: 16,
            lg: 16,
          },
        }}
      />
    </div>
  )
}
