import downloadHtmlAsImage from 'version2/utils/downloadHtmlAsImage'
import { ReactComponent as DownloadIcon } from 'assets/images/icons/report/download-icon.svg'
import { Tooltip } from 'antd'

export const DownloadOption = ({ elementToCapture }: { elementToCapture: string }) => {
  return (
    <div className='download-icon' style={{ cursor: 'pointer' }}>
      <Tooltip title='Download this visual as image' placement='topLeft'>
        <DownloadIcon onClick={() => downloadHtmlAsImage(elementToCapture)} />
      </Tooltip>
    </div>
  )
}
