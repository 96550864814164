import dayjs from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import millify from 'millify'
import { minBy } from 'remeda'

import {
  ExecutiveCategoryType,
  IBarChartWatchlistData,
  ICommunityLight,
  ICreator,
  IPowerInsightSortFilter,
  IFilterChips,
  ISingleExecutiveData,
  ISnippet,
  IVideoBubble,
  LanguageRisk,
  SearchConditions,
  SnippetFilterPayload,
  SnippetsFilter,
  SnippetsFilterInfo,
  sourcePlatform,
  monitorMode,
  ILiteWatchlist,
  SurpriseData,
  ListSelectedRowData,
  IPowerInsightsDataV2,
  IPowerInsightsSummaryV2,
} from 'models/models'

import {
  LANGUAGE_METRICS_DIMENSIONS,
  MONITOR_POWER_INSIGHT_MODE_LABEL,
  MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL,
  MONITOR_POWER_INSIGHT_SORT_LABEL,
  MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL,
  MONITOR_SNIPPET_MODE_LABEL,
  MONITOR_SNIPPET_MODE_VALYE_BY_LABEL,
  MONITOR_SNIPPET_SORT_LABEL,
  MONITOR_SNIPPET_SORT_VALUE_BY_LABEL,
  SurpriseSnippet,
} from 'settings/settings'
import {
  API,
  anticipatoryIntelligence,
  bookmarksAPI,
  executiveIntelligenceAPI,
  exportAPI,
  flagsAPI,
  monitorAPI,
  monitorVersion2API,
  powerInsightsAPI,
} from 'api/api'
import { UtilService } from 'services/Util/Util'
import { LogService } from 'services/Log/Log'
import {
  ConditionsDataType,
  ConditionsPayloadParamsType,
  ConditionsPayloadType,
  DetailsObjType,
  FetchLineChartStatsType,
  SelectedTabType,
} from 'store/monitorVersion2/types'
import { BeehiveNode } from '@pendulumfn/pendulum-viz'
import { AssetListParamsType } from '../asset/types'
import TagManager from 'react-gtm-module'
import { Unknown } from 'version2/utils/commonTypes'
import { displayApiError } from 'version2/utils/helper'

export class MainStore {
  tenant: string = ''
  activeItem: DetailsObjType | null = null
  selectedTab: SelectedTabType = 'narratives'

  snippetsFilter: SnippetsFilter = {
    days: {
      custom: {
        isApplied: false,
        dateRange: [],
      },
      noOfDays: 7,
    },
    sort: 'Upload Date',
    sort_mode: 'Descending',
    filter_risk: [0, 100],
  }
  snippetsFilterInfo: SnippetsFilterInfo = {
    risk: 0,
    sentiment: 0,
    platform: 0,
    sort: '',
    mode: '',
    language: false,
    source: 0,
  }
  snippetsCurrentPage: number = 1
  snippetsTotal: number = 0
  snippets: ISnippet[] = []
  userBookmarkedSnippets: string[] = []

  surpriseVideos: SurpriseData[] = []
  surpriseModalData: SurpriseData | null = null

  filterChips: IFilterChips = {
    attentionFlags: {
      label: 'Attention flags',
      value: '',
    },
    watchlist: { label: 'Watchlist', value: '' },
    community: { label: 'Community', value: '' },
    languageRisk: { label: 'Language Risk', value: '' },
    people: { label: 'People', value: '' },
    politician: { label: 'Politician', value: '' },
    country: { label: 'Country', value: '' },
    company: { label: 'Company', value: '' },
    language: { label: 'Language', value: '' },
    assets: { label: 'Asset', value: '' },
    flags: { label: 'Flag', value: '' },
  }

  isFeedEmpty: boolean = true
  isTranslatingFeed: boolean = false

  availableSnippetsSortFilters = Object.freeze({
    sort: Object.values(MONITOR_SNIPPET_SORT_LABEL),
    sort_mode: Object.values(MONITOR_SNIPPET_MODE_LABEL),
  })
  availablePowerInsightsSortFilters = Object.freeze({
    sort: Object.values(MONITOR_POWER_INSIGHT_SORT_LABEL),
    sort_mode: Object.values(MONITOR_POWER_INSIGHT_MODE_LABEL),
  })

  selectedExecutiveCategory: ExecutiveCategoryType = 'All'
  selectedCommunityInfo: { name: string; id: string; index: number } | undefined = undefined

  creatorIds: string[] = []
  creators: ICreator[] = []
  communities: ICommunityLight[] = []
  watchlists: ILiteWatchlist[] = []

  powerInsightsData: IPowerInsightsDataV2 = {
    summary: {
      executiveSummary: {
        response: null,
        text: '',
      },
      statistics: [
        {
          label: 'Snippets',
          value: '0',
        },
        {
          label: 'Unique Creators',
          value: '0',
        },
        {
          label: 'Companies',
          value: '0',
        },
        {
          label: 'People',
          value: '0',
        },
        {
          label: 'Organizations',
          value: '0',
        },
        {
          label: 'Views',
          value: '0',
        },
      ],
      platformsDistribution: [],
    },
    lineChartData: {
      posts: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
        platform: { data_points: [] },
      },
      impressions: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
        platform: { data_points: [] },
      },
    },
    heatMapData: { data_points: [] },
    beeSwarmNodesData: [],
    languageRisks: {},
    watchlistData: { completeData: [], data: [] },
  }
  powerInsightsSortFilter: IPowerInsightSortFilter = { sort: 'Alphabetically', sort_mode: 'Ascending' }
  beeSwarmVideoNodes: BeehiveNode<IVideoBubble>[] = []

  lineChartStats = {
    posts: { all: [], sentiment: [], platform: [] },
    impressions: { all: [], sentiment: [], platform: [] },
  }

  conditionsPayload: ConditionsPayloadType = {}
  conditionsPayloadForAssets: ConditionsPayloadType = {}

  defaultPlatforms: string = ''

  topicRequestId: string = ''

  constructor() {
    makeObservable(this, {
      tenant: observable,
      activeItem: observable,
      selectedTab: observable,
      snippetsFilter: observable,
      snippetsFilterInfo: observable,
      snippetsCurrentPage: observable,
      snippetsTotal: observable,
      snippets: observable,
      userBookmarkedSnippets: observable,
      surpriseVideos: observable,
      surpriseModalData: observable,
      filterChips: observable,
      isFeedEmpty: observable,
      isTranslatingFeed: observable,
      selectedExecutiveCategory: observable,
      selectedCommunityInfo: observable,
      creatorIds: observable,
      creators: observable,
      communities: observable,
      watchlists: observable,
      powerInsightsData: observable,
      powerInsightsSortFilter: observable,
      beeSwarmVideoNodes: observable,
      lineChartStats: observable,
      conditionsPayload: observable,
      conditionsPayloadForAssets: observable,
      defaultPlatforms: observable,
      topicRequestId: observable,
      setTenant: action.bound,
      setActiveItem: action.bound,
      setSelectedTab: action.bound,
      setSnippetsFilter: action.bound,
      setSnippetsFilterInfo: action.bound,
      setSnippetsCurrentPage: action.bound,
      setSnippetsTotal: action.bound,
      setSnippets: action.bound,
      appendSnippets: action.bound,
      setUserBookmarkedSnippets: action.bound,
      setSurpriseVideos: action.bound,
      setSurpriseModalData: action.bound,
      setFilterChips: action.bound,
      setIsFeedEmpty: action.bound,
      setIsTranslatingFeed: action.bound,
      setSelectedExecutiveCategory: action.bound,
      setSelectedCommunityInfo: action.bound,
      setCreatorIds: action.bound,
      setCreators: action.bound,
      setCommunities: action.bound,
      setWatchlists: action.bound,
      setPowerInsightsData: action.bound,
      setPowerInsightsSortFilter: action.bound,
      updateBeeSwarmNodesSelection: action.bound,
      setLineChartStats: action.bound,
      setConditionsPayload: action.bound,
      setConditionsPayloadForAssets: action.bound,
      setDefaultPlatforms: action.bound,
      setTopicRequestId: action.bound,
      filterPowerInsightBarChartData: action.bound,
    })
  }

  get getModeConditions(): any {
    return [this.activeItem?.conditions]
  }

  get getDate(): { startDate: string; endDate: string } {
    const days = this.snippetsFilter.days.noOfDays
    const dateRange = this.snippetsFilter.days.custom.isApplied ? this.snippetsFilter.days.custom.dateRange : []

    let endDate = dayjs().format('YYYY-MM-DD')
    let startDate = dayjs(endDate).subtract(1, 'days').format('YYYY-MM-DD')
    if (dateRange.length) {
      ;[startDate, endDate] = dateRange
    } else {
      startDate = days ? dayjs(endDate).subtract(days, 'days').format('YYYY-MM-DD') : startDate
    }

    return {
      startDate,
      endDate,
    }
  }

  get snippetsWithSurprises() {
    const snippetsWithSurprisesArr: ISnippet[] = []
    this.snippets.forEach((el, index) => {
      snippetsWithSurprisesArr.push(el)
      if (index % 49 === 0 && index > 0) snippetsWithSurprisesArr.push(SurpriseSnippet)
    })

    return snippetsWithSurprisesArr
  }

  setTenant = (tenant: string) => {
    this.tenant = tenant
  }

  setActiveItem = (item: DetailsObjType | null) => {
    this.activeItem = item
  }

  setSelectedTab = (tabType: SelectedTabType) => {
    this.selectedTab = tabType
  }

  setSnippetsFilter = (filter: SnippetsFilter) => {
    this.snippetsFilter = filter
  }

  setSnippetsFilterInfo = (info: SnippetsFilterInfo) => {
    this.snippetsFilterInfo = info
  }

  setSnippetsCurrentPage = (page: number) => (this.snippetsCurrentPage = page)

  setSnippetsTotal = (number: number) => {
    this.snippetsTotal = number
  }

  setSnippets = (items: ISnippet[]) => {
    this.snippets = items
  }

  appendSnippets = (newSnippets: ISnippet[]) => {
    this.snippets.push(...newSnippets)
  }

  setUserBookmarkedSnippets = (items: string[]) => {
    this.userBookmarkedSnippets = items
  }

  setSurpriseVideos = (surpriseVideos: SurpriseData[]) => {
    this.surpriseVideos = surpriseVideos
  }

  setSurpriseModalData = (data: SurpriseData | null) => {
    this.surpriseModalData = data
  }

  setFilterChips = (filter: any) => {
    this.filterChips = filter
  }

  setIsFeedEmpty = (state: boolean) => {
    this.isFeedEmpty = state
  }

  setIsTranslatingFeed = (state: boolean) => {
    this.isTranslatingFeed = state
  }

  setSelectedExecutiveCategory = (category: ExecutiveCategoryType) => {
    this.selectedExecutiveCategory = category
  }

  setSelectedCommunityInfo = (info: { name: string; id: string; index: number } | undefined) => {
    this.selectedCommunityInfo = info
  }

  setCreatorIds = (creatorIds: string[]) => {
    this.creatorIds = creatorIds
  }

  setCreators = (creators: any[]) => {
    this.creators = creators
  }

  setCommunities = (communities: ICommunityLight[]) => {
    this.communities = communities
  }

  setWatchlists = (data: ILiteWatchlist[]) => {
    this.watchlists = data
  }

  setPowerInsightsData = (data: IPowerInsightsDataV2) => {
    this.powerInsightsData = data
  }

  resetPowerInsightsData = () => {
    this.powerInsightsData = {
      summary: {
        executiveSummary: {
          response: null,
          text: '',
        },
        statistics: [
          {
            label: 'Snippets',
            value: '0',
          },
          {
            label: 'Unique Creators',
            value: '0',
          },
          {
            label: 'Companies',
            value: '0',
          },
          {
            label: 'People',
            value: '0',
          },
          {
            label: 'Organizations',
            value: '0',
          },
          {
            label: 'Views',
            value: '0',
          },
        ],
        platformsDistribution: [],
      },
      lineChartData: {
        posts: {
          all: { data_points: [] },
          sentiment: { data_points: [] },
          platform: { data_points: [] },
        },
        impressions: {
          all: { data_points: [] },
          sentiment: { data_points: [] },
          platform: { data_points: [] },
        },
      },
      heatMapData: { data_points: [] },
      beeSwarmNodesData: [],
      languageRisks: {},
      watchlistData: { completeData: [], data: [] },
    }
    this.setActiveItem(null)
  }

  setPowerInsightsSortFilter = (filter: any) => {
    this.powerInsightsSortFilter = filter
  }

  updateBeeSwarmNodesSelection = (platform: sourcePlatform | '') => {
    this.beeSwarmVideoNodes = this.beeSwarmVideoNodes.map((node) => ({
      ...node,
      selected: node.data.platform === platform || platform === '',
    }))
  }

  setLineChartStats = (metric: 'posts' | 'impressions', pivot: 'all' | 'sentiment' | 'platform', data: any) => {
    this.lineChartStats[metric][pivot] = data
  }

  setConditionsPayload = (searchPayload: ConditionsPayloadType) => {
    this.conditionsPayload = searchPayload
  }

  setConditionsPayloadForAssets = (searchPayload: ConditionsPayloadType) => {
    this.conditionsPayloadForAssets = searchPayload
  }

  setDefaultPlatforms = (platforms: string) => {
    this.defaultPlatforms = platforms
  }

  setTopicRequestId = (id: string) => {
    this.topicRequestId = id
  }

  filterPowerInsightBarChartData = (
    indexName: 'peopleData' | 'companyData' | 'watchlistData' | 'politicianData',
    key: string,
  ) => {
    if (key.length > 0) {
      const filteredData =
        this.powerInsightsData[indexName]?.completeData?.filter((item) => item.entity_name?.includes(key)) || []
      const data = {
        [indexName]: {
          ...this.powerInsightsData[indexName],
          data: filteredData,
        },
      }
      this.setPowerInsightsData({ ...this.powerInsightsData, ...data })
    } else {
      const data = {
        [indexName]: {
          ...this.powerInsightsData[indexName],
          data: this.powerInsightsData[indexName]?.completeData.slice(0, 8),
        },
      }
      this.setPowerInsightsData({ ...this.powerInsightsData, ...data })
    }
  }

  getSnippetFilterPayload = async (skip?: string) => {
    const payload: SnippetFilterPayload = {
      page: 1,
      per_page: 1,
      query: {
        date: '',
        platform: '',
        sentiment: '',
        source: '',
        risk: '',
        translationLanguage: '',
        people: '',
        politician: '',
        company: '',
        country: '',
        language: '',
        community: '',
        languageRisk: '',
      },
      sort: '',
      powerInsightSort: '',
      conditions: [],
    }

    const TIME_FORMAT = 'YYYY-MM-DD'
    const categoryLookup = {
      All: 'a',
      Negative: 'a',
      Obscene: 'obscene_language',
      'Identity Hate': 'hate_language',
      Toxic: 'toxic_language',
      Threat: 'threat_language',
      Source: 'a',
      /** A graph cannot be one of the following categories */
      NarrativeAll: '',
      NarrativeMainstream: '',
      NarrativeRisk: '',
      NarrativeNegative: '',
    }
    const days = this.snippetsFilter.days.noOfDays
    const dateRange = this.snippetsFilter.days.custom.isApplied ? this.snippetsFilter.days.custom.dateRange : []

    if (dateRange.length) {
      payload.query.date = `start_date:gte:${dateRange[0]},end_date:lte:${dateRange[1]}`
    } else {
      const endDate = dayjs().format(TIME_FORMAT)
      const yesterday = dayjs(endDate).subtract(1, 'days').format(TIME_FORMAT)
      const startDate = days ? dayjs(endDate).subtract(days, 'days').format(TIME_FORMAT) : yesterday
      payload.query.date = `start_date:gte:${startDate},end_date:lte:${endDate}`
    }

    let languageRisk: LanguageRisk = categoryLookup[this.selectedExecutiveCategory] as LanguageRisk

    if (languageRisk === 'a') {
      languageRisk = null
    }

    payload.query.languageRisk = languageRisk ? `,lang_risk:in:[${languageRisk}]` : ''

    payload.query.platform = this.snippetsFilter?.filter_platform
      ? `,platform:in:${JSON.stringify(this.snippetsFilter?.filter_platform)}`.replaceAll('"', '')
      : ''
    payload.query.sentiment = this.snippetsFilter?.filter_sentiment?.length
      ? this.snippetsFilter?.filter_sentiment
        ? `,sentiment:in:${JSON.stringify(this.snippetsFilter?.filter_sentiment)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.source = this.snippetsFilter?.snippet_source?.length
      ? this.snippetsFilter?.snippet_source
        ? `,snippet_source:in:${JSON.stringify(this.snippetsFilter?.snippet_source)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.risk =
      this.snippetsFilter?.filter_risk?.length === 2
        ? `,risk_score:gte:${this.snippetsFilter?.filter_risk[0]},risk_score:lte:${this.snippetsFilter?.filter_risk[1]}`
        : ''
    payload.query.people = this.snippetsFilter?.people ? `,people:in:[${this.snippetsFilter?.people}]` : ''
    payload.query.politician = this.snippetsFilter?.politician
      ? `,politician:in:[${this.snippetsFilter?.politician}]`
      : ''
    payload.query.company = this.snippetsFilter?.company ? `,company:in:[${this.snippetsFilter?.company}]` : ''
    payload.query.country = this.snippetsFilter?.country ? `,country:in:[${this.snippetsFilter?.country}]` : ''
    payload.query.language = this.snippetsFilter?.filter_language?.length
      ? `,lang:in:[${this.snippetsFilter.filter_language}]`
      : ''
    payload.query.community = this.snippetsFilter?.community
      ? `,community_number:eq:${this.snippetsFilter?.community}`
      : ''
    payload.query.translationLanguage = this.snippetsFilter?.lang?.length ? `&lang=${this.snippetsFilter?.lang}` : ''

    payload.sort = this.snippetsFilter
      ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[this.snippetsFilter.sort]}:${
          MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[this.snippetsFilter.sort_mode]
        }`
      : ''

    payload.powerInsightSort = this.powerInsightsSortFilter
      ? `${MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL[this.powerInsightsSortFilter.sort]}:${
          MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL[this.powerInsightsSortFilter.sort_mode]
        }`
      : ''

    payload.conditions = [...payload.conditions, ...this.getModeConditions]

    if (this.snippetsFilter?.filter_keyword && this.snippetsFilter?.filter_keyword?.length > 0) {
      payload.conditions.push({
        keywords_expression: this.snippetsFilter?.filter_keyword,
      })
    }

    if (this.snippetsFilter?.watchlist && this.snippetsFilter?.watchlist?.length > 0) {
      payload.conditions.push({
        channel_urls: this.snippetsFilter?.watchlist,
      })
    }

    if (this.snippetsFilter.assets && Object.keys(this.snippetsFilter.assets).length > 0) {
      payload.conditions.push(this.snippetsFilter.assets.conditions as SearchConditions)
    }

    if (this.snippetsFilter.flags && Object.keys(this.snippetsFilter.flags).length > 0) {
      payload.conditions.push(this.snippetsFilter.flags.conditions as SearchConditions)
    }

    if (skip !== 'flags') {
      const filterData = this.snippetsFilter.attentionFlags
      if (filterData && Object.keys(filterData).length > 0) {
        const allFlags = Object.keys(filterData)
        const promises = []
        for (const element in allFlags) {
          const key = allFlags[element]
          const nameKey = allFlags[element].split(',')[1]
          const ids = filterData[key]

          if (nameKey !== 'keywords_expression' && Array.isArray(ids)) {
            const modeData: any =
              nameKey === 'narratives'
                ? { mode: 'narrative', key: 'narrative_number' }
                : { mode: 'community', key: 'community_number' }

            for (const idKey in ids) {
              const id = ids[idKey]
              const obj: any = {}
              obj[id] = ''
              promises.push(API.get({ route: modeData.mode, id }))
            }

            let responses = await Promise.all(promises)
            responses = responses.map(({ data: item }) => {
              if (item && item[modeData.key]) {
                const obj: any = {}
                const id = item.id
                obj[id] = item[modeData.key]
                return item[modeData.key]
              } else return ''
            })

            let obj: any = {}
            responses = responses.filter((item) => item && item !== '')
            obj[nameKey] = [...responses]
            if (responses.length > 0) payload.conditions.push(obj)
          } else {
            payload.conditions.push({
              keywords_expression: filterData[allFlags[element]] as string,
            })
          }
        }
      }
    }

    return payload
  }

  getCreators = async (idArray: string[]) => {
    const ids = idArray.filter((id) => !this.creatorIds.includes(id))
    this.setCreatorIds([...this.creatorIds, ...ids])
    const idString = ids.join(',')

    let data

    if (idString?.length)
      ({ data } = await API.get({ route: 'channel', customAPI: process.env.REACT_APP_SEARCH_API, ids: idString }))

    if (data?.items) this.setCreators([...this.creators, ...data.items])

    this.setSnippets(
      this.snippets.map((snippet) => {
        if (snippet?.author?.channel_title) return snippet
        const creator = this.creators.filter((creator: any) => {
          return creator.channel_id === snippet.channelId
        })
        return { ...snippet, author: creator.length ? creator[0] : {} } as ISnippet
      }),
    )

    const snippetCommunities = this.snippets.map((snippet) => {
      return snippet?.author?.communities
    })

    const communitiesRequest: number[] = []

    snippetCommunities?.forEach((communityArray) => {
      communityArray?.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
    })

    this.getCommunities(communitiesRequest)
  }

  getCommunities = async (communitiesRequest: number[]) => {
    try {
      const checkedCommunities = communitiesRequest.filter((community, index) => {
        return communitiesRequest.indexOf(community) === index
      })

      const alreadyHave = this.communities
        .filter((community) => checkedCommunities.includes(community.id))
        ?.map((community) => community.id)

      const filteredCommunities = checkedCommunities.filter((community) => !alreadyHave.includes(community))

      if (filteredCommunities?.length === 0) return

      const { data } = await API.get({
        route: 'community',
        filter: `community_number:in:${JSON.stringify(filteredCommunities)}`,
      })

      const communitiesData = data?.items?.map((community: any) => {
        return { id: community.community_number, name: community?.name }
      })

      if (communitiesData) {
        this.setCommunities([...this.communities, ...communitiesData])
      }
    } catch (error) {}
  }

  getWatchlists = async () => {
    try {
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })
      const watchlistsData = response?.data.items?.map((watchlist: any) => {
        let channels = []
        if (watchlist?.channels) {
          channels = watchlist?.channels.map((channel: any) => {
            const urls = channel?.channel_by_platform.map((item: any) => item.channel_url)
            return {
              platform: channel.platform,
              channel_urls: urls,
            }
          })
        }
        return { id: watchlist.id, name: watchlist?.name, channels: channels }
      })
      this.setWatchlists(watchlistsData)
    } catch (error) {}
  }

  getFormattedBeeSwarmVideoNodes = (items: IVideoBubble[]) => {
    const minViews = minBy(items, (post) => post.post_views)?.post_views ?? 1 // if none of the items have any views then assume that minimum value of `post_views` is 1
    const defaultBubbleValue = minViews / 2 // default value for video bubbles where `post_views` is NULL is half the value of minimum views of any post. This will improve their visibility in chart no matter how big the dataset is

    return items.map((video, index) => {
      const color = UtilService.getPlatformColor(video.platform)
      const date = new Date(video.post_upload_date)

      return {
        date,
        color,
        id: index.toString(),
        value: video.post_views ?? defaultBubbleValue,
        source: video.platform,
        data: {
          ...video,
          postViewsMillify: millify(video.post_views || 0),
          date: dayjs(date).format('MMM DD, YYYY'),
        },
        selected: true,
      }
    })
  }

  showSurprise = async (state: boolean) => {
    if (state) {
      const rand = UtilService.getRandomInt({ max: this.surpriseVideos.length })
      this.setSurpriseModalData(this.surpriseVideos[rand])
      return
    }
    this.setSurpriseModalData({ title: '', url: '' })
    await UtilService.delay(100)
    this.setSurpriseModalData(null)
  }

  exportFeed = async ({
    sort,
    sort_mode,
    time,
    total_snippets,
  }: {
    sort: string
    sort_mode: string
    time: string[]
    total_snippets: number
  }) => {
    try {
      const page = this.snippetsCurrentPage
      const payload = await this.getSnippetFilterPayload()
      payload.page = page
      payload.per_page = 50
      payload.sort =
        sort?.length && sort_mode?.length
          ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[sort]}:${MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[sort_mode]}`
          : ''
      const filters: {
        platform: string[]
        sentiment: string[]
        risk_score: string
      } = {
        platform: [],
        sentiment: [],
        risk_score:
          this.snippetsFilter?.filter_risk && this.snippetsFilter?.filter_risk?.length > 1
            ? `${this.snippetsFilter?.filter_risk[0]} to ${this.snippetsFilter?.filter_risk[1]}`
            : '',
      }

      if (Array.isArray(this.snippetsFilter.filter_platform)) {
        filters.platform = this.snippetsFilter.filter_platform
      } else if (this.snippetsFilter?.filter_platform && (this.snippetsFilter?.filter_platform?.length || 0) > 0) {
        filters.platform = this.snippetsFilter.filter_platform?.split(',')
      }

      if (Array.isArray(this.snippetsFilter.filter_sentiment)) {
        filters.sentiment = this.snippetsFilter.filter_sentiment
      } else if (this.snippetsFilter?.filter_sentiment && (this.snippetsFilter.filter_sentiment?.length || 0) > 0) {
        filters.sentiment = this.snippetsFilter?.filter_sentiment?.split(',')
      }

      payload.query.date = `start_date:gte:${time[0]},end_date:lte:${time[1]}`

      const date = { start_date: time[0], end_date: time[1] }

      const info = {
        name: this.activeItem?.name || '',
        entity_id: this.activeItem?.id || '',
        entity_type: this.activeItem?.mode as monitorMode,
        sort_by: sort,
        order_by: sort_mode,
        filters,
        total_snippets,
        ...date,
      }
      await exportAPI.exportFeed({ ...payload, ...info })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  deleteItem = async (item: ListSelectedRowData) => {
    try {
      if (item && item.id) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'delete_item',
            entityId: item.id,
            entityName: item.name,
            entityType: item.mode,
            tenantId: this.tenant,
            entityOwner: item.ownerId,
          },
        })
        const response = await API.genericDelete(item.id, item.mode)
        return response.data
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchPeopleData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.people.getPeopleData(payload)
  }

  fetchPoliticianData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.politician.getPoliticianData(payload)
  }

  fetchCompaniesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.companies.getCompaniesData(payload)
  }

  fetchCountriesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.countries.getCountriesMapData(payload)
  }

  fetchLanguagesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.languages.getLanguagesData(payload)
  }

  fetchWatchlistData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      this.setPowerInsightsData({ ...this.powerInsightsData, watchlistData: { completeData: [], data: [] } })
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })

      const watchlistPromises = response?.data.items?.map(async (watchlist: any) => {
        let channels: string[] = []
        if (watchlist?.channels) {
          watchlist?.channels.forEach((channel: any) =>
            channel?.channel_by_platform.forEach((item: any) => {
              channels.push(item.channel_url)
            }),
          )
        }
        const conditions = [
          {
            channel_urls: Array.from(new Set(channels)),
          },
        ]

        try {
          const data = await monitorAPI.loadFeed({
            ...payload,
            conditions: [...(payload.conditions || []), ...conditions],
          })
          return {
            entity_name: watchlist?.name,
            total_channels: watchlist?.total_channels,
            metric_value: data?.data?.total_count,
            urls: Array.from(new Set(channels)),
          }
        } catch (error) {
          console.error(`Error fetching data for watchlist ${watchlist?.name}:`, error)
        }
      })

      const results = await Promise.allSettled(watchlistPromises)

      const watchlistData: IBarChartWatchlistData[] = results
        .filter(
          (result): result is PromiseFulfilledResult<IBarChartWatchlistData> =>
            result.status === 'fulfilled' && result.value,
        )
        .map((result) => result.value)

      const watchlistPostCount = {
        ...this.powerInsightsData,
        watchlistData: {
          completeData: watchlistData,
          data: watchlistData.length > 8 ? watchlistData.slice(0, 8) : watchlistData,
        },
      }

      this.setPowerInsightsData(watchlistPostCount)
    } catch (error) {
      displayApiError(error)
    }
  }

  fetchSummaryData = async (payload: SnippetFilterPayload) => {
    const [executiveSummary, statistics, platformsDistribution] = await Promise.all([
      anticipatoryIntelligence.getSummary(payload),
      powerInsightsAPI.summary.getPowerInsightsSummaryStatistics(payload),
      anticipatoryIntelligence.getPlatformChartData(payload),
    ])

    return {
      executiveSummary: {
        text: executiveSummary?.summary_text ?? '',
        response: executiveSummary.response_code,
      },
      statistics,
      platformsDistribution: platformsDistribution?.data_points,
    }
  }

  fetchExecutiveSummary = async (payload: SnippetFilterPayload) => {
    const executiveSummary = await anticipatoryIntelligence.getSummary(payload)
    return executiveSummary
  }

  fetchBarChartData = async (tab: 'company' | 'people' | 'language' | 'watchlist' | 'politician') => {
    const payload = await this.getSnippetFilterPayload()
    if (tab === 'company') return this.fetchCompaniesData(payload)
    else if (tab === 'people') return this.fetchPeopleData(payload)
    else if (tab === 'politician') return this.fetchPoliticianData(payload)
    else if (tab === 'watchlist') return this.fetchWatchlistData()
    else if (tab === 'language') return this.fetchLanguagesData(payload)
  }

  fetchInsightsMetrics = async ({
    params,
    data,
    fullResponse = false,
  }: {
    params: ConditionsPayloadParamsType
    data: ConditionsDataType
    fullResponse?: boolean
  }) => {
    try {
      const response = await monitorVersion2API.getInsightsMetrics({ params, data })
      if (fullResponse) return response.data
      return response.data.total_value
    } catch (error: any) {
      return 'error'
    }
  }

  fetchSurpriseVideos = async () => {
    try {
      const { data } = await API.get({ route: 'takeABreak', customAPI: process.env.REACT_APP_SEARCH_API })
      this.setSurpriseVideos(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchSnippets = async () => {
    try {
      if (this.snippets.length >= this.snippetsTotal && this.snippets.length !== 0) return
      const page = Math.floor(this.snippets.length / 50) + 1
      this.setSnippetsCurrentPage(page)

      const payload = await this.getSnippetFilterPayload()
      payload.page = page
      payload.per_page = 50

      const data = await monitorAPI.loadFeed(payload)

      if (!data?.data?.items.length) this.setIsFeedEmpty(false)
      this.setSnippetsTotal(data?.data?.total_count)

      const { snippets, creators: creatorsList } = UtilService.snippetTranslator({
        rawSnippets: data?.data?.items,
        isFilterApplied: this.snippetsFilterInfo.language,
      })
      snippets['feedback'] = 'none'
      if (payload.page > 1) {
        this.appendSnippets(snippets)
      } else {
        this.snippets = snippets
      }

      let creators: string[] = creatorsList
      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
      await this.getCreators(creators)
    } catch (e) {
      LogService.error({ message: 'Error in monitor store in fetchSnippets api call', error: e })
    }
  }

  fetchUserBookmarkedSnippets = async () => {
    try {
      const response = (await bookmarksAPI.getUserBookmarkedSnippets()) as string[]
      this.setUserBookmarkedSnippets(response)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchPowerInsightsLanguageMetrics = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const languageMetricsPromises: Promise<Unknown>[] = []
      LANGUAGE_METRICS_DIMENSIONS.forEach((dimension) => {
        languageMetricsPromises.push(
          executiveIntelligenceAPI.getExecutiveMetrics({
            ...payload,
            dimension,
          }),
        )
      })
      const languageMetricsResolved = await Promise.allSettled(languageMetricsPromises)
      const languageMetricsProcessed: ISingleExecutiveData['languageMetrics'] = {}
      languageMetricsResolved.forEach((metric, index) => {
        if (metric.status === 'fulfilled') {
          const metricName = LANGUAGE_METRICS_DIMENSIONS[index]
          languageMetricsProcessed[metricName] = metric.value
        }
      })
      this.setPowerInsightsData({ ...this.powerInsightsData, languageRisks: languageMetricsProcessed })
      return languageMetricsProcessed
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsLineChartData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const [
        { data: postsLineChart },
        { data: postSentimentsLineChart },
        { data: postPlatformsLineChart },
        { data: impressionsLineChart },
        { data: impressionSentimentsLineChart },
        { data: impressionPlatformsLineChart },
      ] = await Promise.all([
        powerInsightsAPI.lineChart.getLineChartData(payload),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          dimension: 'sentiment',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          dimension: 'platform',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
          dimension: 'sentiment',
        }),
        powerInsightsAPI.lineChart.getLineChartData({
          ...payload,
          metric: 'impression',
          dimension: 'platform',
        }),
      ])
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        lineChartData: {
          posts: {
            all: { data_points: postsLineChart.data_points },
            sentiment: postSentimentsLineChart,
            platform: postPlatformsLineChart,
          },
          impressions: {
            all: { data_points: impressionsLineChart.data_points },
            sentiment: impressionSentimentsLineChart,
            platform: impressionPlatformsLineChart,
          },
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsHeatMapData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const { data: dataHeatMap } = await powerInsightsAPI.treeMap.getTreeMapData(payload)
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        heatMapData: {
          data_points: dataHeatMap.data_points,
        },
      })
      const itemCommunities: number[] = dataHeatMap.data_points?.map(
        (point: { community_id: number }) => point.community_id,
      )
      const communitiesRequest: number[] = []
      itemCommunities?.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
      this.getCommunities(communitiesRequest)
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsBeeSwarmData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const { items: beeSwarmData } = await powerInsightsAPI.beeSwarm.getBeeSwarmData(payload)
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        beeSwarmNodesData: this.getFormattedBeeSwarmVideoNodes(beeSwarmData),
      })
      this.beeSwarmVideoNodes = this.getFormattedBeeSwarmVideoNodes(beeSwarmData)
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsPeopleData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const peopleData = await this.fetchPeopleData(payload)
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        peopleData: {
          completeData: peopleData.data_points,
          data: peopleData.total_count > 8 ? peopleData.data_points.slice(0, 8) : peopleData.data_points,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsPoliticianData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const politicianData = await this.fetchPoliticianData(payload)
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        politicianData: {
          completeData: politicianData.data_points,
          data: politicianData.total_count > 8 ? politicianData.data_points.slice(0, 8) : politicianData.data_points,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsCompaniesData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const companiesData = await this.fetchCompaniesData(payload)
      this.setPowerInsightsData({
        ...this.powerInsightsData,
        companyData: {
          completeData: companiesData.data_points,
          data: companiesData.total_count > 8 ? companiesData.data_points.slice(0, 8) : companiesData.data_points,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsCountriesData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const countriesMapData = await this.fetchCountriesData(payload)
      this.setPowerInsightsData({ ...this.powerInsightsData, countriesMapData: countriesMapData.data_points })
      return countriesMapData.data_points
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsLanguagesData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const languagesData = await this.fetchLanguagesData(payload)
      this.setPowerInsightsData({ ...this.powerInsightsData, languagesData: { data: languagesData.data_points } })
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsSummaryData = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const executiveSummary = anticipatoryIntelligence.getSummary(payload)
      const statistics = powerInsightsAPI.summary.getPowerInsightsSummaryStatistics(payload)
      executiveSummary
        .then((data) => {
          this.setPowerInsightsData({
            ...this.powerInsightsData,
            summary: {
              ...this.powerInsightsData.summary,
              executiveSummary: {
                response: data.response_code,
                text: data?.summary_text || '',
              },
            } as IPowerInsightsSummaryV2,
          })
        })
        .catch((error) => {
          console.error('Error fetching executive summary:', error)
        })
      statistics.then((data) =>
        this.setPowerInsightsData({
          ...this.powerInsightsData,
          summary: {
            ...this.powerInsightsData.summary,
            statistics:
              [
                {
                  label: 'Snippets',
                  value: millify(data?.total_snippets || 0),
                },
                {
                  label: 'Unique Creators',
                  value: millify(data?.creator_count || 0),
                },
                {
                  label: 'Companies',
                  value: millify(data?.company_count || 0),
                },
                {
                  label: 'People',
                  value: millify(data?.people_count || 0),
                },
                {
                  label: 'Organizations',
                  value: millify(data?.country_count || 0),
                },
                {
                  label: 'Views',
                  value: millify(data?.total_views || 0),
                },
              ] || '',
          } as IPowerInsightsSummaryV2,
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsightsCommunities = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const { data: dataHeatMap } = await powerInsightsAPI.treeMap.getTreeMapData(payload)
      const itemCommunities: number[] = dataHeatMap.data_points.map(
        (point: { community_id: number }) => point.community_id,
      )
      const communitiesRequest: number[] = []
      itemCommunities.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
      return this.getCommunities(communitiesRequest)
    } catch (error) {
      console.log(error)
    }
  }

  fetchPowerInsights = async (item: DetailsObjType, showError: boolean = true) => {
    try {
      this.setSnippets([])
      this.setSnippetsTotal(0)
      this.setActiveItem(item)

      const payload = await this.getSnippetFilterPayload()

      const languageMetricsPromises: Promise<any>[] = []
      LANGUAGE_METRICS_DIMENSIONS.forEach((dimension) => {
        languageMetricsPromises.push(
          executiveIntelligenceAPI.getExecutiveMetrics({
            ...payload,
            dimension,
          }),
        )
      })
      const languageMetricsResolved = await Promise.allSettled(languageMetricsPromises)
      const languageMetricsProcessed: ISingleExecutiveData['languageMetrics'] = {}
      languageMetricsResolved.forEach((metric, index) => {
        if (metric.status === 'rejected') return

        const metricData = metric as PromiseFulfilledResult<any>

        let metricName = LANGUAGE_METRICS_DIMENSIONS[index]
        languageMetricsProcessed[metricName] = metricData.value
      })

      switch (item.activeTab) {
        case 'summary':
          this.fetchPowerInsightsSummaryData()
          break
        case 'trends-timeline':
          this.fetchPowerInsightsLineChartData()
          break
        case 'watchlist':
          this.fetchWatchlistData()
          break
        case 'language-risks':
          this.fetchPowerInsightsLanguageMetrics()
          break
        case 'communities':
          this.fetchPowerInsightsHeatMapData()
          break
        case 'creator-hive':
          this.fetchPowerInsightsBeeSwarmData()
          break
        case 'map':
          this.fetchPowerInsightsCountriesData()
          break
        case 'companies':
        case 'company':
          this.fetchPowerInsightsCompaniesData()
          break
        case 'people':
          this.fetchPowerInsightsPeopleData()
          break
        case 'politician':
          this.fetchPowerInsightsPoliticianData()
          break
        case 'languages':
        case 'language':
          this.fetchPowerInsightsLanguagesData()
          break

        default:
          break
      }
    } catch (error) {
      if (showError) {
        this.handleError(error)
      } else {
        UtilService.openNotification({
          type: 'info',
          message: 'No results found',
        })
      }
    }
  }

  handleError = (error: any) => {
    displayApiError(error)
  }

  fetchPowerInsightsForBrandDashboard = async (item: DetailsObjType) => {
    try {
      this.setActiveItem(item)
      const payload = await this.getSnippetFilterPayload()
      this.fetchPowerInsightsBeeSwarmData()
      this.fetchPowerInsightsHeatMapData()
      this.fetchPowerInsightsLineChartData()
      this.fetchWatchlistData()
      anticipatoryIntelligence.getPlatformChartData(payload).then((response) => {
        this.setPowerInsightsData({
          ...this.powerInsightsData,
          summary: {
            ...this.powerInsightsData.summary,
            platformsDistribution: response?.data_points,
          },
        })
      })
    } catch (error: any) {
      this.handleError(error)
    }
  }

  fetchConditionsPayload = async (params?: ConditionsPayloadParamsType | undefined, dontStore?: boolean) => {
    try {
      const response = await monitorVersion2API.getSearchPayload(params)
      if (!dontStore) {
        this.setConditionsPayload(response.data)
      }
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchConditionsPayloadForAssets = async (params?: ConditionsPayloadParamsType | undefined, dontStore?: boolean) => {
    try {
      const response = await monitorVersion2API.getSearchPayload(params)

      this.setConditionsPayloadForAssets(response.data)
      if (!dontStore) {
        this.setConditionsPayloadForAssets(response.data)
      }
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchTopicsRequestId = async () => {
    try {
      const payload = await this.getSnippetFilterPayload()
      const { data } = await monitorAPI.getTopicsRequestId(payload)
      this.setTopicRequestId(data.topic_request_id)
      return data.topic_request_id
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })

      return 'error'
    }
  }

  fetchSnippetsInfo = async (item: DetailsObjType, showError: boolean = true) => {
    try {
      this.setActiveItem(null)
      this.setSnippets([])
      this.setSnippetsTotal(0)

      this.setActiveItem(item)

      this.fetchSnippets()
    } catch (error: any) {
      const response = error.response
      if (showError) {
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      } else {
        UtilService.openNotification({
          type: 'info',
          message: 'No results found',
        })
      }
    }
  }

  fetchStats = async (searchConditions?: SearchConditions, name?: string, filter?: boolean) => {
    try {
      const payload = await this.getSnippetFilterPayload()
      let allConditions = payload.conditions
      if (searchConditions) allConditions.push(searchConditions)

      const response = await anticipatoryIntelligence.getPostsCount({
        ...payload,
        conditions: allConditions || [],
        name,
      })
      if (filter) response.conditions = searchConditions
      return response
    } catch (error: any) {
      console.log(error)
    }
  }

  fetchRiskFlags = async (params?: AssetListParamsType) => {
    try {
      const response = await flagsAPI.getCategories(params)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchLineChartStats = async ({
    startDate,
    endDate,
    data,
    riskScore = { min: 0, max: 100 },
    platform,
    sentiment,
  }: FetchLineChartStatsType) => {
    let qParam = `start_date:gte:${startDate},end_date:lte:${endDate}`
    if (platform && platform.length) {
      qParam = `${qParam},platform:in:${Array.isArray(platform) ? platform.join(',') : platform}`
    }
    if (sentiment && sentiment.length) {
      qParam = `${qParam},sentiment:in:${sentiment.join(',')}`
    }
    qParam = `${qParam},risk_score:gte:${riskScore.min},risk_score:lte:${riskScore.max}`

    try {
      const [
        allPosts,
        allPostsRisk,
        allImpressions,
        sentimentPosts,
        sentimentImpressions,
        platformPosts,
        platformImpressions,
      ] = await Promise.all([
        monitorVersion2API.getLineChartStats({ q: qParam }, data),
        monitorVersion2API.getLineChartStats(
          {
            q: qParam,
          },
          { conditions: [...data.conditions, this.conditionsPayload] },
        ),
        monitorVersion2API.getLineChartStats(
          {
            q: qParam,
            metric: 'impression',
          },
          data,
        ),
        monitorVersion2API.getLineChartStats(
          {
            q: qParam,
            dimension: 'sentiment',
          },
          data,
        ),
        monitorVersion2API.getLineChartStats(
          {
            q: qParam,
            metric: 'impression',
            dimension: 'sentiment',
          },
          data,
        ),
        monitorVersion2API.getLineChartStats(
          {
            q: qParam,
            dimension: 'platform',
          },
          data,
        ),
        monitorVersion2API.getLineChartStats(
          {
            q: qParam,
            metric: 'impression',
            dimension: 'platform',
          },
          data,
        ),
      ])
      const updatedData =
        allPostsRisk.data.data_points.length > 0
          ? allPostsRisk.data.data_points.map(
              (item: { date: string; metric_value: number; metric_running_average: number }, index: number) => {
                return {
                  date: item.date,
                  metric_value_risk: item.metric_value,
                  metric_value: allPosts?.data?.data_points?.[index]?.metric_value || 0,
                }
              },
            )
          : allPosts.data.data_points.map(
              (item: { date: string; metric_value: number; metric_running_average: number }, index: number) => {
                return {
                  date: item.date,
                  metric_value_risk: allPostsRisk?.data?.data_points?.[index]?.metric_value || 0,
                  metric_value: item.metric_value,
                }
              },
            )

      this.setLineChartStats('posts', 'all', updatedData)
      this.setLineChartStats('impressions', 'all', allImpressions.data.data_points)
      this.setLineChartStats('posts', 'sentiment', sentimentPosts.data.data_points)
      this.setLineChartStats('impressions', 'sentiment', sentimentImpressions.data.data_points)
      this.setLineChartStats('posts', 'platform', platformPosts.data.data_points)
      this.setLineChartStats('impressions', 'platform', platformImpressions.data.data_points)
      return this.lineChartStats
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  resetSnippetFilters = () => {
    this.setSnippetsFilterInfo({
      risk: 0,
      sentiment: 0,
      platform: 0,
      source: 0,
      sort: '',
      mode: '',
      language: false,
    })
    if (this.defaultPlatforms.length) {
      this.setSnippetsFilter({
        days: {
          custom: {
            isApplied: false,
            dateRange: [],
          },
          noOfDays: 7,
        },
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
        filter_platform: this.defaultPlatforms,
      })
    } else {
      this.setSnippetsFilter({
        days: {
          custom: {
            isApplied: false,
            dateRange: [],
          },
          noOfDays: 7,
        },
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
      })
    }
  }

  resetStore = () => {
    this.snippets = []
    this.surpriseVideos = []
    this.activeItem = null
    this.snippetsFilter = {
      days: this.snippetsFilter.days,
      sort: 'Upload Date',
      sort_mode: 'Descending',
      filter_risk: [0, 100],
    }
    this.snippetsFilterInfo = {
      risk: 0,
      sentiment: 0,
      platform: 0,
      source: 0,
      sort: '',
      mode: '',
      language: false,
    }
    this.powerInsightsSortFilter = { sort: 'Alphabetically', sort_mode: 'Ascending' }
    this.snippetsTotal = 0
    this.isFeedEmpty = true
    this.surpriseModalData = null
  }
}
