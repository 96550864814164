import { Dropdown, Input, Menu, Radio, Slider, Switch, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

import { SnippetsFilter } from 'models/models'

import { store } from 'store'

import { ReactComponent as SortIcon } from 'assets/images/sort-icon.svg'
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg'
import { ReactComponent as ResetIcon } from 'assets/images/icons/reset.svg'
import { ReactComponent as AscendingIcon } from 'assets/images/ascending-icon.svg'
import { ReactComponent as DescendingIcon } from 'assets/images/descending-icon.svg'

import { ReactComponent as PositiveSentimentIcon } from 'assets/images/positive-sentiment-icon.svg'
import { ReactComponent as NegativeIconSentimentIcon } from 'assets/images/negative-sentiments-icon.svg'
import { ReactComponent as NeutralIconSentimentIcon } from 'assets/images/neutral-sentiment-icon.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/language-icon.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as CloseIcon } from 'assets/images/close-with-background-icon.svg'
import { ReactComponent as ExportFeedIcon } from 'assets/images/export-feed-icon.svg'

import { SUPPORTED_LANGUAGES, PLATFORM_ICONS_GREY } from 'settings/settings'

import './SnippetFilterVersion2.scss'
import styles from './SnippetFilterVersion2.module.scss'
import TagManager from 'react-gtm-module'
import { FeedExport } from '../PowerInsightsVersion2/FeedExport/FeedExport'
import { DateFilter } from 'version2/components/common/DateFilter/DateFilter'
import { Mode, SubStore, PageType, Page } from 'types/types'

const SENTIMENT_ICONS = [
  { title: 'Positive', icon: <PositiveSentimentIcon /> },
  { title: 'Neutral', icon: <NeutralIconSentimentIcon height={20} /> },
  { title: 'Negative', icon: <NegativeIconSentimentIcon /> },
]
const CONTENT_SOURCES = [
  { key: 'caption', value: 'Caption' },
  { key: 'ocr', value: 'OCR' },
  { key: 'description', value: 'Description' },
  { key: 'transcript', value: 'Transcript' },
  { key: 'title', value: 'Title' },
]

const snippetDateFilter = sessionStorage.getItem('snippetDateFilter')
const DEFAULT_SNIPPET_FILTERS: SnippetsFilter = {
  days: JSON.parse(snippetDateFilter || ''),
  sort: 'Upload Date',
  sort_mode: 'Descending',
  filter_risk: [0, 100],
}

interface Props {
  mode: Mode
  subStore: SubStore
  pageType: PageType
  page: Page
  activeTab: string
}

export const SnippetFilterVersion2 = observer(({ mode, pageType, subStore, page, activeTab }: Props) => {
  const exportCheck = (pageType === 'details' || pageType === 'list') && mode !== 'community'

  const { userStore } = store

  const {
    snippetsFilter,
    snippetsFilterInfo,
    availableSnippetsSortFilters,
    fetchPowerInsights,
    resetSnippetFilters,
    setSnippetsFilter,
    setSnippetsFilterInfo,
    snippetsTotal,
    activeItem,
    setFilterChips,
    setIsTranslatingFeed,
    fetchSnippets,
  } = store[`${subStore}Store`]

  const combineDetailsViewDefaultFeedsFilters = {
    attentionFlags: {
      label: 'Attention flags',
      value: '',
    },
    watchlist: { label: 'Watchlist', value: '' },
    community: { label: 'Community', value: '' },
    languageRisk: { label: 'Language Risk', value: '' },
    people: { label: 'People', value: '' },
    politician: { label: 'Politician', value: '' },
    country: { label: 'Country', value: '' },
    company: { label: 'Company', value: '' },
    language: { label: 'Language', value: '' },
    asset: { label: 'Asset', value: '' },
    flags: { label: 'Flag', value: '' },
  }

  const { userId, userName, roleId, tenantId } = userStore

  const elementList: any[] = []
  const platformList: any[] = []
  const sentimentsList: any[] = []
  const contentSourcesList: any[] = []
  const languageList: any = []
  const days = [1, 7, 30, 90]
  const daysList: any[] = []
  const keys = Object.keys(availableSnippetsSortFilters) as Array<keyof typeof availableSnippetsSortFilters>
  const languages = Object.keys(SUPPORTED_LANGUAGES)

  const [searchInput, setSearchInput] = useState(snippetsFilter.filter_keyword)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [sliderValues, setSliderValues] = useState({ min: 0, max: 100 })
  const [selectedLocalFilter, setSelectedLocalFilter] = useState(snippetsFilter)
  const [previousSelectedLocalFilter, setPreviousSelectedLocalFilter] = useState(selectedLocalFilter)
  let hasFilterChanged = false
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false)
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false)
  const [isLanguageSwitchOpen, setIsLanguageSwitchOpen] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)

  useEffect(() => {
    // condition for the first time we set values of filter
    if (
      // the selectedLocalFilter will be empty and previousSelectedLocalFilter will be undefined
      ('filter_platform' in selectedLocalFilter &&
        selectedLocalFilter.filter_platform === '' &&
        !('filter_platform' in previousSelectedLocalFilter)) ||
      ('filter_sentiment' in selectedLocalFilter &&
        selectedLocalFilter.filter_sentiment === '' &&
        !('filter_sentiment' in previousSelectedLocalFilter)) ||
      ('snippet_source' in selectedLocalFilter &&
        selectedLocalFilter.snippet_source === '' &&
        !('snippet_source' in previousSelectedLocalFilter))
    ) {
      hasFilterChanged = false
    } else {
      // condition after the first time
      hasFilterChanged =
        selectedLocalFilter.filter_platform !== previousSelectedLocalFilter.filter_platform ||
        selectedLocalFilter.filter_sentiment !== previousSelectedLocalFilter.filter_sentiment ||
        selectedLocalFilter.snippet_source !== previousSelectedLocalFilter.snippet_source ||
        JSON.stringify(selectedLocalFilter.filter_risk) !== JSON.stringify(previousSelectedLocalFilter.filter_risk)
    }
  }, [selectedLocalFilter, previousSelectedLocalFilter])

  const getLanguage = (lang: string) => {
    return Object.keys(SUPPORTED_LANGUAGES).find((key) => SUPPORTED_LANGUAGES[key] === lang)
  }

  useEffect(() => {
    setSelectedLocalFilter(snippetsFilter)
  }, [snippetsFilter])

  const sliderInputHandler = (value: number, mode: 'min' | 'max') => {
    setSliderValues((oldVals) => {
      const results = { ...oldVals, [mode]: value }
      if (results.max < results.min) return { min: results.max, max: results.min }
      return results
    })
  }

  const onFilterClose = (open: boolean) => {
    if (!open) {
      if (hasFilterChanged) {
        setSnippetsFilterAndFetchFeed(selectedLocalFilter)
      }
      setIsFilterOpen(false)
    } else {
      setIsFilterOpen(true)
      setPreviousSelectedLocalFilter(selectedLocalFilter)
    }
  }

  const onLanguageSwitchClick = () => {
    const flag = !showLanguageDropdown
    setIsLanguageSwitchOpen(!isLanguageSwitchOpen)

    if (!flag) {
      const modFilter = { ...snippetsFilter }
      modFilter['lang'] = ''
      setSnippetsFilterAndFetchFeed(modFilter)
      setSelectedLocalFilter(modFilter)

      const filter = {
        language: false,
      }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
      setIsLanguageDropdownOpen(false)
      setIsLanguageSwitchOpen(false)
    }
    setShowLanguageDropdown(flag)
  }

  const onLanguageDropdownOpen = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
  }

  const fetchSnippetsHandler = () => {
    if (activeItem && activeItem.name) {
      fetchPowerInsights({ ...activeItem, activeTab })
    }
    fetchSnippets()
  }

  const setSnippetsFilterAndFetchFeed = (selectedLocalFilter: typeof snippetsFilter) => {
    const filterKeys = Object.keys(selectedLocalFilter) as Array<keyof typeof snippetsFilter>
    const cleanedFilter: { [key: string]: any } = {}

    filterKeys.forEach((el: keyof typeof snippetsFilter) => {
      if (!selectedLocalFilter[el]) {
        cleanedFilter[el] = selectedLocalFilter[el]
        return
      }

      if (Array.isArray(selectedLocalFilter[el])) {
        if ((selectedLocalFilter[el]?.toString()?.length || 0) > 0) {
          cleanedFilter[el] = selectedLocalFilter[el]
        }
        return
      }

      if (selectedLocalFilter[el] !== '') {
        cleanedFilter[el] = selectedLocalFilter[el]
        return
      }
    })
    setSnippetsFilter({ ...snippetsFilter, ...cleanedFilter } as SnippetsFilter)
    fetchSnippetsHandler()

    // google analyitcs filter feeds search
    TagManager.dataLayer({
      dataLayer: {
        event: 'filter_feeds_search',
        daysFilter: snippetsFilter.days.noOfDays,
        platformFilter: snippetsFilter.filter_platform,
        keywordFilter: searchInput,
        riskScoreFilter: [snippetsFilter.filter_risk?.[0], snippetsFilter.filter_risk?.[1]],
        languageFilter: snippetsFilter.lang,
        sentimentFilter: snippetsFilter.filter_sentiment,
        entityId: activeItem?.id,
        entityType: mode,
        user_id: userId,
        user_name: userName,
        roleId: roleId,
        tenantId: tenantId,
      },
    })
  }

  keys.forEach((group) => {
    if (group === 'sort')
      elementList.push(
        <Menu.Item className='feed-filters__title disabled-item' key='sort_by'>
          Sort by
        </Menu.Item>,
      )
    else if (group === 'sort_mode')
      elementList.push(
        <Menu.Item className='disabled-item feed-filters__title--margin' key='sort_mode'>
          Order By
        </Menu.Item>,
      )
    availableSnippetsSortFilters[group].forEach((el, index) => {
      const array = el.split(' ')
      const title = array.reduce((previous: string, current: string) => {
        return `${previous} ${current[0].toUpperCase()}${current.substring(1)}`
      })
      elementList.push(
        <Menu.Item
          key={group + index}
          className={classNames('feed-filters__dropdown-item', {
            'feed-filters__dropdown-item--inline': group === 'sort_mode',
          })}
          onClick={() => {
            const modFilter = { ...snippetsFilter }
            // @ts-ignore
            modFilter[group] = el
            setSnippetsFilterAndFetchFeed(modFilter)

            const filter: any = {}
            if (group === 'sort') filter['sort'] = el
            else filter['mode'] = el
            setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
          }}>
          {group === 'sort' ? (
            <span className='feed-filters__dropdown-item__text'>
              <Radio checked={snippetsFilter[group] === el}>{title}</Radio>
            </span>
          ) : (
            <button
              className={classNames('feed-filters__dropdown-item__mode-btn', {
                'feed-filters__dropdown-item__mode-btn--active': selectedLocalFilter[group] === el,
              })}>
              <span className='feed-filters__dropdown-item__mode-btn__title'>
                {title === 'Ascending' ? <AscendingIcon /> : <DescendingIcon />}
                {title}
              </span>
            </button>
          )}
        </Menu.Item>,
      )
    })
  })

  days.forEach((el) => {
    daysList.push(
      <Menu.Item
        key={el}
        className='feed-filters__dropdown-item'
        onClick={({ key }) => {
          const filter = {
            custom: {
              isApplied: false,
              dateRange: [],
            },
            noOfDays: Number(key),
          }

          const modFilter = { ...selectedLocalFilter }
          modFilter['days'] = filter
          setSelectedLocalFilter(modFilter)
          setSnippetsFilter({ ...snippetsFilter, ...modFilter } as SnippetsFilter)
          fetchSnippetsHandler()
        }}>
        <span
          className={classNames('feed-filters__dropdown-item__text', {
            'feed-filters__dropdown-item--selected': snippetsFilter.days.noOfDays === el,
          })}>
          {el === 1
            ? `Yesterday`
            : el === 180
            ? 'Last 6 months'
            : el === 365
            ? 'Last 1 year'
            : el === 730
            ? 'Last 2 years'
            : `Last ${el} days`}
        </span>
      </Menu.Item>,
    )
  })

  PLATFORM_ICONS_GREY.forEach((el, index) => {
    if (!el.exclude?.includes(mode)) {
      platformList.push(
        <Menu.Item
          key={'platform' + index}
          className='feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin'
          onClick={() => {
            let removeFlag = false
            const modFilter = { ...selectedLocalFilter }
            const appliedFilter = modFilter['filter_platform'] || ''

            if (appliedFilter) {
              if (appliedFilter.includes(el.title.toLowerCase())) {
                removeFlag = true
                // @ts-ignore
                modFilter['filter_platform'] = modFilter['filter_platform']!.replace(el.title.toLowerCase() + ',', '')
                  .replace(',' + el.title.toLowerCase(), '')
                  .replace(el.title.toLowerCase(), '')
              } else {
                // @ts-ignore
                modFilter['filter_platform'] += `,${el.title.toLowerCase()}`
              }
              // @ts-ignore
            } else modFilter['filter_platform'] = `${el.title.toLowerCase()}`
            setSelectedLocalFilter(modFilter)

            const filter = {
              platform: removeFlag ? snippetsFilterInfo.platform - 1 : snippetsFilterInfo.platform + 1,
            }
            setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
          }}>
          <button
            className={classNames('feed-filters__dropdown-item__mode-btn', {
              'feed-filters__dropdown-item__mode-btn--active': selectedLocalFilter['filter_platform']?.includes(
                el.title.toLowerCase(),
              ),
            })}>
            <span className='feed-filters__dropdown-item__mode-btn__title'>
              {el.icon}
              {el.title}
            </span>
          </button>
        </Menu.Item>,
      )
    }
  })

  SENTIMENT_ICONS.forEach((el, index) => {
    sentimentsList.push(
      <Menu.Item
        key={'sentiment' + index}
        className='feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin'
        onClick={() => {
          let removeFlag = false
          const modFilter = { ...selectedLocalFilter }
          const appliedFilter = modFilter['filter_sentiment'] || ''
          if (appliedFilter) {
            if (appliedFilter.includes(el.title)) {
              removeFlag = true
              // @ts-ignore
              modFilter['filter_sentiment'] = modFilter['filter_sentiment']!.replace(el.title + ',', '')
                .replace(',' + el.title, '')
                .replace(el.title, '')
            } else {
              // @ts-ignore
              modFilter['filter_sentiment'] += `,${el.title}`
            }
            // @ts-ignore
          } else modFilter['filter_sentiment'] = `${el.title}`
          setSelectedLocalFilter(modFilter)

          const filter = {
            sentiment: removeFlag ? snippetsFilterInfo.sentiment - 1 : snippetsFilterInfo.sentiment + 1,
          }
          setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
        }}>
        <button
          className={classNames('feed-filters__dropdown-item__mode-btn', {
            'feed-filters__dropdown-item__mode-btn--active': selectedLocalFilter['filter_sentiment']?.includes(
              el.title,
            ),
          })}>
          <span className='feed-filters__sentiment-span'>
            {el.icon}
            {el.title}
          </span>
        </button>
      </Menu.Item>,
    )
  })

  CONTENT_SOURCES.forEach((el, index) => {
    contentSourcesList.push(
      <Menu.Item
        key={'content-source' + index}
        className='feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin'
        onClick={() => {
          let removeFlag = false
          const modFilter = { ...selectedLocalFilter }
          const appliedFilter = modFilter['snippet_source'] || ''
          if (appliedFilter) {
            if (appliedFilter.includes(el.key)) {
              removeFlag = true
              // @ts-ignore
              modFilter['snippet_source'] = modFilter['snippet_source']!.replace(el.key + ',', '')
                .replace(',' + el.key, '')
                .replace(el.key, '')
            } else {
              // @ts-ignore
              modFilter['snippet_source'] += `,${el.key}`
            }
            // @ts-ignore
          } else modFilter['snippet_source'] = `${el.key}`
          setSelectedLocalFilter(modFilter)

          const filter = {
            source: removeFlag ? snippetsFilterInfo.source - 1 : snippetsFilterInfo.source + 1,
          }
          setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
        }}>
        <button
          className={classNames('feed-filters__dropdown-item__mode-btn', {
            'feed-filters__dropdown-item__mode-btn--active': selectedLocalFilter['snippet_source']?.includes(el.key),
          })}>
          <span className='feed-filters__sentiment-span'>{el.value}</span>
        </button>
      </Menu.Item>,
    )
  })

  languages.forEach((lang) => {
    languageList.push({
      key: SUPPORTED_LANGUAGES[lang],
      label: (
        <span
          className={classNames('feed-filters__dropdown-item__text', {
            'feed-filters__dropdown-item--selected': snippetsFilter.lang === SUPPORTED_LANGUAGES[lang],
          })}>
          {lang}
        </span>
      ),
      onClick: ({ key }: any) => {
        const modFilter = { ...snippetsFilter }
        modFilter['lang'] = key
        setSnippetsFilterAndFetchFeed(modFilter)
        setSelectedLocalFilter(modFilter)
        const filter = {
          language: true,
        }
        setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
        setIsLanguageDropdownOpen(false)
        setIsTranslatingFeed(true)
        setIsLanguageSwitchOpen(true)
      },
    })
  })

  const sortDropdown = <Menu className='feed-filters__menu'>{elementList}</Menu>

  const filterDropdown = (
    <Menu className='list-filter__large-container'>
      <Menu.Item className='feed-filters__title' key='platform'>
        Platform
      </Menu.Item>
      {platformList}
      <Menu.Item className='feed-filters__title--margin' key='sentiment'>
        Content Source
      </Menu.Item>
      {contentSourcesList}
      <Menu.Item className='feed-filters__title--margin' key='sentiment'>
        Sentiment
      </Menu.Item>
      {sentimentsList}
      <Menu.Item className='feed-filters__title--margin' key='risk'>
        {'Risk score (%)'}
      </Menu.Item>
      <Menu.Item className='feed-filters__dropdown-item__risk-slider' key={'riskSlider'}>
        <span>{sliderValues.min}</span>
        <Slider
          className='list-filter__item-slider'
          min={0}
          max={100}
          onChange={(val) => {
            setSliderValues({ min: val[0], max: val[1] })
          }}
          onAfterChange={(val) => {
            const modFilter = { ...selectedLocalFilter }
            modFilter['filter_risk'] = [val[0], val[1]]
            setSelectedLocalFilter(modFilter)
            const filter = { risk: 1 }
            setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
          }}
          range={true}
          value={[sliderValues.min, sliderValues.max]}
        />
        <span>{sliderValues.max}</span>
      </Menu.Item>
      <Input
        hidden
        type={'number'}
        max={100}
        min={0}
        className='list-filter__item-select__input-input'
        prefix='From'
        value={sliderValues.min}
        onChange={(el) => sliderInputHandler(parseInt(el.target.value), 'min')}
      />

      <Input
        hidden
        type={'number'}
        max={100}
        min={0}
        className='list-filter__item-select__input-input'
        prefix='To'
        value={sliderValues.max}
        onChange={(el) => sliderInputHandler(parseInt(el.target.value), 'max')}
      />
    </Menu>
  )

  return (
    <div className={styles.filtersSection}>
      <div className={styles.searchInputContainer}>
        <Input
          className={styles.searchInput}
          prefix={<SearchIcon />}
          placeholder={`Search from ${snippetsTotal || ''} results`.replace(/\s+/g, ' ')}
          onPressEnter={async (e) => {
            const modFilter = { ...snippetsFilter }
            modFilter['filter_keyword'] = searchInput
            setSelectedLocalFilter(modFilter)
            setSnippetsFilterAndFetchFeed(modFilter as any)
          }}
          onChange={(e) => {
            setSearchInput(e.target.value)
          }}
          value={searchInput}
        />
        {searchInput && (
          <CloseIcon
            onClick={() => {
              setSearchInput('')
              const filter = { ...snippetsFilter, filter_keyword: '' }
              setSnippetsFilterAndFetchFeed(filter as any)
              setSelectedLocalFilter(filter)
            }}
          />
        )}
      </div>

      <div className='feed-filters__container'>
        {((snippetsFilter.days.noOfDays > -1 && snippetsFilter.days.noOfDays !== 90) ||
          snippetsFilter.days.custom.isApplied ||
          snippetsFilterInfo.platform +
            snippetsFilterInfo.risk +
            snippetsFilterInfo.sentiment +
            snippetsFilterInfo.source >
            0 ||
          snippetsFilterInfo.mode.length > 0 ||
          snippetsFilterInfo.sort.length > 0) && (
          <button
            className='feed-filters__reset-btn'
            onClick={() => {
              setSearchInput('')
              setIsLanguageSwitchOpen(false)
              setIsLanguageDropdownOpen(false)
              setShowLanguageDropdown(false)
              resetSnippetFilters()
              fetchSnippetsHandler()
              setFilterChips(combineDetailsViewDefaultFeedsFilters)
              setSelectedLocalFilter(DEFAULT_SNIPPET_FILTERS)
            }}>
            <ResetIcon />
            Reset Filters
          </button>
        )}

        <div className='feed-filters__container__language-filters'>
          {showLanguageDropdown && (
            <Dropdown
              className='feed-filters'
              overlayClassName='feed-filters__dropdown'
              trigger={['click']}
              placement={'bottomRight'}
              menu={{ items: languageList }}
              onOpenChange={onLanguageDropdownOpen}
              overlayStyle={{ height: '192px', width: '144px' }}>
              <div
                className={classNames('feed-filters__language-dropdown', {
                  'feed-filters__language-dropdown--selected':
                    // @ts-ignore
                    snippetsFilter.lang?.length > 0 && !isLanguageDropdownOpen,
                })}>
                {isLanguageDropdownOpen ? (
                  <span className={'feed-filters__language-dropdown__title'}>{'Select Language'}</span>
                ) : (
                  <span
                    className={classNames('feed-filters__language-dropdown__title', {
                      // @ts-ignore
                      'feed-filters__language-dropdown--selected__title': snippetsFilter.lang?.length > 0,
                    })}>
                    {
                      // @ts-ignore
                      snippetsFilter.lang?.length > 0 ? getLanguage(snippetsFilter.lang) : 'Select Language'
                    }
                  </span>
                )}
                <DropdownIcon
                  className={classNames({ 'feed-filters__language-dropdown__arrow': isLanguageDropdownOpen })}
                />
              </div>
            </Dropdown>
          )}

          <div
            className={classNames(
              { 'feed-filters__language-div__selected': snippetsFilterInfo.language },
              'feed-filters__language-div',
            )}>
            <Tooltip placement='bottom' title='Translate'>
              <LanguageIcon aria-label='Translate' />
            </Tooltip>
            <Tooltip placement='bottom' title='Translate'>
              <Switch
                aria-label='Translate'
                size='small'
                onClick={onLanguageSwitchClick}
                checked={isLanguageSwitchOpen}
              />
            </Tooltip>
          </div>
        </div>

        <div className='feed-filters__container__other-filters'>
          <DateFilter days={[1, 7, 30, 90]} pageType={pageType} subStore={subStore} onClick={fetchSnippetsHandler} />
          <Dropdown
            className='feed-filters'
            overlayClassName='feed-filters__dropdown'
            trigger={['click']}
            placement={'bottomRight'}
            overlay={sortDropdown}>
            <div
              className={classNames(
                {
                  'feed-filters__filter-icon':
                    snippetsFilterInfo.mode?.length > 0 || snippetsFilterInfo.sort?.length > 0,
                },
                'feed-filters__filter-not-applied',
              )}>
              <Tooltip placement='bottom' title='Sort'>
                <SortIcon aria-label='Sort' />
              </Tooltip>
              {(snippetsFilterInfo.mode?.length > 0 || snippetsFilterInfo.sort?.length > 0) && (
                <span className='feed-filters__text'>
                  {`S` + `ort by ${snippetsFilter['sort']} (${snippetsFilter['sort_mode']})`.toLocaleLowerCase()}
                </span>
              )}
            </div>
          </Dropdown>
          <Dropdown
            className='feed-filters'
            overlayClassName='feed-filters__dropdown'
            trigger={['click']}
            placement={'bottomRight'}
            onOpenChange={onFilterClose}
            overlay={filterDropdown}
            open={isFilterOpen}>
            <div
              className={classNames(
                {
                  'feed-filters__filter-icon':
                    snippetsFilterInfo.platform +
                      snippetsFilterInfo.risk +
                      snippetsFilterInfo.sentiment +
                      snippetsFilterInfo.source >
                    0,
                },
                'feed-filters__filterby-icon',
              )}>
              <Tooltip placement='bottom' title='Filter'>
                <FilterIcon
                  className={classNames({
                    'feed-filters__filterby-icon__filter-opened':
                      isFilterOpen &&
                      snippetsFilterInfo.platform +
                        snippetsFilterInfo.risk +
                        snippetsFilterInfo.sentiment +
                        snippetsFilterInfo.source <=
                        0,
                  })}
                  aria-label='Filter'
                />
              </Tooltip>
              {snippetsFilterInfo.platform +
                snippetsFilterInfo.risk +
                snippetsFilterInfo.sentiment +
                snippetsFilterInfo.source >
                0 && (
                <span className='feed-filters__text' style={{ display: 'flex', marginLeft: 0, paddingRight: '12px' }}>
                  {snippetsFilterInfo.platform +
                    snippetsFilterInfo.risk +
                    snippetsFilterInfo.sentiment +
                    snippetsFilterInfo.source >
                  9
                    ? ''
                    : '0'}
                  {`${
                    snippetsFilterInfo.platform +
                    snippetsFilterInfo.risk +
                    snippetsFilterInfo.sentiment +
                    snippetsFilterInfo.source
                  } Filters`}
                </span>
              )}
            </div>
          </Dropdown>
        </div>
        {exportCheck && (
          <div className='feed-filters__export-feed' onClick={() => setOpenExportModal(true)}>
            <ExportFeedIcon />
            <span className='feed-filters__export-feed__label'>Export</span>
          </div>
        )}
      </div>
      <FeedExport openModal={openExportModal} setOpenModal={setOpenExportModal} subStore={subStore} />
    </div>
  )
})
