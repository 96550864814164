import { Modal } from 'antd'
import { SurpriseData } from 'models/models'

import './MonitorSurpriseModal.scss'

interface Props {
  isVisible: boolean
  close: () => void
  data: SurpriseData | null
}

export const MonitorSurpriseModal = ({ isVisible, close, data }: Props) => {
  let videoID: string[] | string | undefined = data?.url.split('youtu.be')
  if (videoID) videoID = videoID[videoID?.length - 1]
  return (
    <Modal
      width={800}
      bodyStyle={{ maxHeight: '500px', overflow: 'auto' }}
      title={data?.title}
      wrapClassName='c-monitor-surprise-modal'
      open={isVisible}
      closable
      onCancel={close}
      centered>
      {isVisible ? (
        <iframe
          className='c-monitor-surprise-modal__iframe'
          src={'https://www.youtube.com/embed/' + videoID}
          title={`YouTube video player`}
          frameBorder='0'
          allowFullScreen></iframe>
      ) : (
        <></>
      )}
    </Modal>
  )
}
