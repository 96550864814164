// This is the legacy whiteboard POC Store
import { makeAutoObservable } from 'mobx'

import { AppData, BINDING_PADDING, INITIAL_DATA, PERSIST_DATA } from 'components/Investigate/whiteboardConstants'
import { intersectLineSegmentBounds } from '@tldraw/intersect'
import {
  TLPointerInfo,
  TLPageState,
  TLBounds,
  TLBoundsHandle,
  TLBoundsWithCenter,
  Utils,
  TLBinding,
} from '@tldraw/core'

import Vec from '@tldraw/vec'

import { current } from 'immer'
import { ArrowShape, BoxShape, PencilShape, getShapeUtils } from 'components/Investigate/shapes'

export type Shape = BoxShape | ArrowShape | PencilShape

export function makeHistory(ID = '@tldraw/core_advanced_example') {
  let initialData = INITIAL_DATA

  const saved = localStorage.getItem(ID)

  if (PERSIST_DATA && saved !== null) {
    let restoredData = JSON.parse(saved)

    if (restoredData.version < INITIAL_DATA.version) {
      // Migrations would go here
      restoredData = INITIAL_DATA
    }

    initialData = restoredData
  }

  let stack: AppData[] = [initialData]
  let pointer = 0

  function persist(data: AppData) {
    delete data.pageState.hoveredId
    data.overlays.snapLines = []
    localStorage.setItem(ID, JSON.stringify(data))
  }

  function push(data: AppData) {
    if (pointer < stack.length - 1) {
      stack = stack.slice(0, pointer + 1)
    }
    const serialized = current(data)
    stack.push(serialized)
    pointer = stack.length - 1
    persist(serialized)
    return true
  }

  function undo() {
    if (pointer <= 0) return false
    pointer--
    const data = stack[pointer]
    persist(data)
    return data
  }

  function redo() {
    if (pointer >= stack.length - 1) return false
    pointer++
    const data = stack[pointer]
    persist(data)
    return data
  }

  function reset(data = INITIAL_DATA) {
    stack = [data]
    pointer = 0
    localStorage.setItem(ID, JSON.stringify(data))
    persist(data)
    return data
  }

  function restore() {
    return initialData
  }

  return { push, undo, redo, reset, restore }
}

export function getPagePoint(point: number[], pageState: TLPageState) {
  return Vec.sub(Vec.div(point, pageState.camera.zoom), pageState.camera.point)
}

function getBoundHandlePoint(
  data: AppData,
  fromShape: ArrowShape,
  toShape: Shape,
  handleId: keyof ArrowShape['handles'],
) {
  const utils = getShapeUtils(toShape)
  const toShapeBounds = utils.getBounds(toShape)
  const toShapeCenter = utils.getCenter(toShape)

  // Get the point of the shape's opposite handle

  const oppositeHandleId = handleId === 'start' ? 'end' : 'start'
  const oppositeHandle = fromShape.handles[oppositeHandleId]
  const oppositeBinding = Object.values(data.page.bindings).find(
    (binding) => binding.fromId === fromShape.id && binding.handleId === oppositeHandleId,
  )

  let oppositePoint: number[]

  if (oppositeBinding) {
    // If the other handle is also bound to a shape, use that other shape's center instead
    // of the handle's actual point
    const otherToShape = data.page.shapes[oppositeBinding.toId]

    if (!otherToShape) return

    oppositePoint = getShapeUtils(otherToShape).getCenter(otherToShape)
  } else {
    oppositePoint = Vec.add(fromShape.point, oppositeHandle.point)
  }

  // Find the intersection between the target shape's bounds and the two points as a line segment.

  const intersection =
    intersectLineSegmentBounds(oppositePoint, toShapeCenter, Utils.expandBounds(toShapeBounds, BINDING_PADDING))[0]
      ?.points[0] ?? toShapeCenter

  return intersection
}

interface Mutables {
  snapshot: AppData
  rendererBounds: TLBounds
  viewport: TLBounds
  history: ReturnType<typeof makeHistory>
  initialPoint: number[]
  currentPoint: number[]
  previousPoint: number[]
  initialShape?: Shape
  isCloning: boolean
  pointedShapeId?: string
  pointedHandleId?: keyof ArrowShape['handles']
  pointedBoundsHandleId?: TLBoundsHandle
  initialCommonBounds?: TLBounds
  rawPoints: number[][]
  snapInfo?: {
    initialBounds: TLBoundsWithCenter
    all: TLBoundsWithCenter[]
    others: TLBoundsWithCenter[]
  }
}
export class WhiteboardStore {
  data = INITIAL_DATA

  pageState = INITIAL_DATA.pageState

  hideBounds = false

  active: string[] = []

  log: string[] = []

  mutables: Mutables = {
    snapshot: INITIAL_DATA,
    initialPoint: [0, 0],
    currentPoint: [0, 0],
    previousPoint: [0, 0],
    history: makeHistory(),
    rendererBounds: Utils.getBoundsFromPoints([
      [0, 0],
      [100, 100],
    ]),
    viewport: Utils.getBoundsFromPoints([
      [0, 0],
      [100, 100],
    ]),
    rawPoints: [],
    isCloning: false,
    pointedShapeId: undefined,
    pointedHandleId: undefined,
    pointedBoundsHandleId: undefined,
    initialCommonBounds: undefined,
    snapInfo: undefined,
  }

  state: 'select' | 'eraser' | 'pencil' | 'box' | 'arrow' = 'select'

  currentState: {
    select: 'idle' | 'pointing' | 'translating' | 'transforming' | 'brushSelecting'
    eraser: 'idle' | 'pointing' | 'erasing'
    pencil: 'idle' | 'creating'
    box: 'idle' | 'pointing' | 'creating'
    arrow: 'idle' | 'pointing' | 'creating'
  } = {
    select: 'idle',
    eraser: 'idle',
    pencil: 'idle',
    box: 'idle',
    arrow: 'idle',
  }

  constructor() {
    makeAutoObservable(this)

    //should restore data
  }

  get lastLogEvent() {
    if (this.log.length === 0) return ''
    return this.log[0]
  }

  updateCurrentState = (state: 'select' | 'eraser' | 'pencil' | 'box' | 'arrow', value: any) => {
    //simulates the onExit events from state-designer
    switch (this.state) {
      case 'select':
        switch (this.currentState.select) {
          case 'translating':
            this.clearSnapInfo()
            this.clearSnapLines()
            this.clearIsCloning()
            break
          case 'transforming':
            this.clearSnapInfo()
            this.clearSnapLines()
            this.clearIsCloning()
            break
          case 'brushSelecting':
            this.clearBrush()
        }
    }

    this.currentState[state] = value

    //simulates the onEnter events from state-designer
    switch (state) {
      case 'select':
        switch (value) {
          case 'idle':
            this.clearPointedShape()
            break
          case 'translating':
            //  this.setSnapInfo()
            break
          case 'transforming':
            // this.setSnapInfo()
            this.setInitialCommonBounds()
            break
        }
        break
      case 'pencil':
        switch (value) {
          case 'creating':
            this.createPencilShape()
            this.setSnapshot()
            break
        }

        break

      case 'box':
        switch (value) {
          case 'creating':
            this.createBoxShape()
            this.setSnapshot()
            break
        }

        break

      case 'arrow': {
        switch (value) {
          case 'pointing':
            // this.setInitialPoint()
            break

          case 'creating':
            this.createArrowShape()
            this.updateBoundShapes()
            this.setSnapshot()
        }
      }
    }
  }

  createArrowShape = () => {}

  setInitialCommonBounds = () => {}

  createBoxShape = () => {}

  createPencilShape = () => {}

  clearPointedShape = () => {
    this.mutables = {
      ...this.mutables,
      pointedHandleId: undefined,
    }
  }

  setPointedBoundsHandle = (payload: TLPointerInfo<string>) => {
    this.mutables = {
      ...this.mutables,
      //@ts-ignore
      pointedBoundsHandleId: payload.target,
    }
  }

  handlePan = (payload: TLPointerInfo) => {
    const { point, zoom } = this.data.pageState.camera
    if (payload.shiftKey) {
      this.data = {
        ...this.data,
        pageState: {
          ...this.data.pageState,
          camera: {
            ...this.data.pageState.camera,
            point: Vec.sub(point, Vec.div([payload.delta[1], payload.delta[0]], zoom)),
          },
        },
      }
    } else
      this.data = {
        ...this.data,
        pageState: {
          ...this.data.pageState,
          camera: {
            ...this.data.pageState.camera,
            point: Vec.sub(point, Vec.div(payload.delta, zoom)),
          },
        },
      }
  }

  clearSnapInfo = () => {
    this.mutables = {
      ...this.mutables,
      snapInfo: undefined,
    }
  }

  clearSnapLines = () => {
    this.data = {
      ...this.data,
      overlays: {
        ...this.data.overlays,
        snapLines: [],
      },
    }
  }

  clearIsCloning = () => {
    this.mutables = {
      ...this.mutables,
      isCloning: false,
    }
  }

  clearBrush = () => {
    this.data = {
      ...this.data,
      pageState: {
        ...this.data.pageState,
        brush: undefined,
      },
    }
  }

  selectTool = (tool: typeof this.state) => {
    this.state = tool

    switch (tool) {
      case 'select':
        this.currentState.select = 'idle'
        break
      case 'eraser':
        this.currentState.eraser = 'idle'
        break
      case 'pencil':
        this.currentState.pencil = 'idle'
        break
      case 'box':
        this.currentState.box = 'idle'
        break
      case 'arrow':
        this.currentState.arrow = 'idle'
        break
    }
  }

  restoreSavedDocument = () => {
    const snapshot = this.mutables.history.restore()
    Object.assign(this.data, snapshot)
  }

  updateBoundShapes = () => {
    const bindings = Object.values(this.data.page.bindings)
    const bindingsToUpdate = [...bindings]
    const bindingsToDelete = new Set<TLBinding>()

    while (bindingsToUpdate.length > 0) {
      const binding = bindingsToUpdate.pop()

      if (!binding) break

      const toShape = this.data.page.shapes[binding.toId]
      const fromShape = this.data.page.shapes[binding.fromId] as ArrowShape

      // Did we delete one of the bindings shapes? If so, delete the binding too.
      if (!(toShape && fromShape)) {
        bindingsToDelete.add(binding)
        break
      }

      const boundHandle = fromShape.handles[binding.handleId]
      const intersection = getBoundHandlePoint(this.data, fromShape, toShape, boundHandle.id)

      if (intersection) {
        if (!Vec.isEqual(boundHandle.point, intersection)) {
          boundHandle.point = Vec.sub(intersection, fromShape.point)
          const handles = Object.values(fromShape.handles)
          const offset = Utils.getCommonTopLeft(handles.map((handle) => handle.point))
          fromShape.point = Vec.add(fromShape.point, offset)
          handles.forEach((handle) => (handle.point = Vec.sub(handle.point, offset)))
        }
      }
    }

    bindingsToDelete.forEach((binding) => delete this.data.page.bindings[binding.id])
  }

  updatePointer = (payload: TLPointerInfo) => {
    this.mutables = {
      ...this.mutables,
      previousPoint: [...this.mutables.currentPoint],
      currentPoint: getPagePoint(payload.point, this.data.pageState),
    }
  }

  zoomOut = () => {
    const { camera } = this.data.pageState
    const i = Math.round((this.data.pageState.camera.zoom * 100) / 25)
    const zoom = Math.max(0.25, (i - 1) * 0.25)
    const center = [this.mutables.rendererBounds.width / 2, this.mutables.rendererBounds.height / 2]
    const p0 = Vec.sub(Vec.div(center, camera.zoom), center)
    const p1 = Vec.sub(Vec.div(center, zoom), center)
    const point = Vec.toFixed(Vec.add(camera.point, Vec.sub(p1, p0)))

    this.data = {
      ...this.data,
      pageState: { ...this.data.pageState, camera: { ...this.data.pageState.camera, zoom, point } },
    }
  }

  zoomIn = () => {
    const { camera } = this.data.pageState
    const i = Math.round((this.data.pageState.camera.zoom * 100) / 25)
    const zoom = Math.min(5, (i + 1) * 0.25)
    const center = [this.mutables.rendererBounds.width / 2, this.mutables.rendererBounds.height / 2]
    const p0 = Vec.sub(Vec.div(center, camera.zoom), center)
    const p1 = Vec.sub(Vec.div(center, zoom), center)
    const point = Vec.toFixed(Vec.add(camera.point, Vec.sub(p1, p0)))

    // data.pageState.camera.zoom = zoom

    this.data = {
      ...this.data,
      pageState: { ...this.data.pageState, camera: { ...this.data.pageState.camera, zoom, point } },
    }
  }

  redo = () => {
    const snapshot = this.mutables.history.redo()

    this.data = {
      ...this.data,
      ...snapshot,
    }
  }

  undo = () => {
    const snapshot = this.mutables.history.undo()

    this.data = {
      ...this.data,
      ...snapshot,
    }
  }

  selectAllShapes = () => {
    this.data = {
      ...this.data,
      pageState: { ...this.data.pageState, selectedIds: Object.keys(this.data.page.shapes) },
    }
  }

  deselectAllShapes = () => {
    this.data = {
      ...this.data,
      pageState: { ...this.data.pageState, selectedIds: [] },
    }
  }

  setInitialPoint = (payload: TLPointerInfo) => {
    this.mutables = {
      ...this.mutables,
      initialPoint: getPagePoint(payload.origin, this.data.pageState),
      previousPoint: [...this.mutables.initialPoint],
    }
  }

  setSnapshot = () => {
    this.mutables = {
      ...this.mutables,
      snapshot: current(this.data),
    }
  }

  setHoveredShape = (payload: TLPointerInfo) => {
    if (this.state === 'select')
      this.data = {
        ...this.data,
        pageState: {
          ...this.data.pageState,
          hoveredId: payload.target,
        },
      }
  }

  clearHoveredShape = () => {
    this.data = {
      ...this.data,
      pageState: {
        ...this.data.pageState,
        hoveredId: undefined,
      },
    }
  }

  resizeBounds = ({ bounds }: { bounds: TLBounds }) => {
    this.mutables = {
      ...this.mutables,
      rendererBounds: bounds,
    }
  }

  setPointedHandle = (payload: any) => {
    switch (this.state) {
      case 'select':
        const newPayload = payload.target as keyof ArrowShape['handles']
        this.mutables.pointedHandleId = newPayload

        break
    }
  }
}
