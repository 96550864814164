import React, { useMemo, useState } from 'react'
import TopicsChart from './TopicsChart/TopicsChart'
import GenerateTopicsSection from './GenerateTopicsSection/GenerateTopicsSection'
import { DataFetching } from 'components/common/DataFetching/DataFetching'
import { ReactComponent as BackBtnIcon } from 'assets/images/icons/arrow-left.svg'
import './Topics.scss'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'

type TopicsProps = {
  fetchTopicsRequestId: () => Promise<string>
  authToken: string | null
}

function Topics(props: TopicsProps) {
  const { fetchTopicsRequestId } = props
  const [showChart, setShowChart] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [topicsChartData, setTopicsChartData] = useState<any>([])

  const initializeSocket = (requestId: string) => {
    if (requestId) {
      const newSocket = new WebSocket(`wss://${process.env.REACT_APP_WEB_SOCKET_URL}/?topic_request_id=${requestId}`)

      newSocket.addEventListener('open', (event) => {
        console.log('WebSocket connection opened:', event)
      })

      newSocket.addEventListener('message', (event) => {
        console.log('Message received:', { event })
        if (event.type === 'message') {
          setTopicsChartData(JSON.parse(event.data))
          setShowChart(true)
        }
        newSocket.close()
      })

      newSocket.addEventListener('error', (event) => {
        console.error('WebSocket error:', { event })
        setIsLoading(false)
      })

      newSocket.addEventListener('close', (event) => {
        if (event.wasClean) {
          console.log('WebSocket connection closed cleanly, code=', { event, code: event.code })
        } else {
          console.error('WebSocket connection abruptly closed', { event })
        }
        console.log('WebSocket connection closed, reason: ', { event, reason: event.reason })
        setIsLoading(false)
      })
    }
  }

  const generateTopicsHandler = () => {
    setIsLoading(true)
    fetchTopicsRequestId().then((res) => {
      if (res !== 'error') {
        initializeSocket(res)
      } else {
        setIsLoading(false)
      }
    })
  }

  const renderTopicsUI = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingOverlay
          className='tc_cc_loading_overlay'
          description='Topics is being generated. We appreciate your patience.'
        />
      )
    } else {
      if (showChart) {
        if (topicsChartData.length) {
          return <TopicsChart data={topicsChartData} />
        }
        return <DataFetching className='tc_df_container' page={'notFound'} showDescription={false} />
      }
      return <GenerateTopicsSection loading={isLoading} onClick={() => generateTopicsHandler()} />
    }
  }, [showChart, topicsChartData, isLoading])

  return (
    <div className='topics_container'>
      {showChart ? (
        <div className='tc_chart_heading_container'>
          <BackBtnIcon height={18} width={18} className='tc_chc_icon' onClick={() => setShowChart(false)} />
          <p className='tc_chc_text'>Back to Generate Topics</p>
        </div>
      ) : null}

      <div className='tc_content_container'>{renderTopicsUI}</div>
    </div>
  )
}

export default Topics
