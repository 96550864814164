import { useState } from 'react'
import CustomModal from 'version2/components/common/CustomModal/CustomModal'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { FolderListObjType } from 'store/monitorVersion2/types'
import { FolderListChunk } from '../FolderPanel/FolderPanel'
import Input from 'version2/components/common/Inputs/Input/Input'
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg'
import { ReactComponent as AddIcon } from 'assets/images/icons/flag/add_icon_20.svg'
// import { ReactComponent as FavoriteIcon } from 'assets/images/icons/monitor/favorite_icon.svg'
import { ReactComponent as CrossIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { ReactComponent as TickIcon } from 'assets/images/icons/monitor/check_icon.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import './MoveToFolderModal.scss'

type MoveToFolderModalProps = {
  data: FolderListObjType[]
  onConfirm: (val: string) => void
  onClose: () => void
  onCreate: (val: string) => void
}

function MoveToFolderModal(props: MoveToFolderModalProps) {
  const { data = [], onClose, onConfirm, onCreate } = props
  const [selectedId, setSelectedId] = useState<string>('')
  const [value, setValue] = useState<string>('')
  const [showCreateFolderField, setShowCreateFolderField] = useState<boolean>(false)

  return (
    <CustomModal
      title='Move to Folder'
      secondButtonText='Confirm'
      containerClassName='mtfm_container'
      boxContainerClassName='mtfm_box_container'
      bodyClassName='mtfm_body_container'>
      <div className='mtfm_modal_body_container'>
        <div className='mtfm_mbc_header'>
          <div className='mtfm_mbc_h_top_content'>
            <p className='mtfm_mbc_htc_title'>Move to Folder</p>
            <CloseIcon
              className='mtfm_mbc_htc_close_icon'
              height={14}
              width={14}
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}
            />
          </div>
          <p className='mtfm_mbc_h_desc'>Select the folder or create a new one</p>
        </div>
        <div className='mtfm_modal_bc_content'>
          {/* <div className='fpc_lc_chunk fpc_lc_chunk_has_checkbox mtfm_modal_bc_c_chunk'>
            <div className='fpc_lc_chunk_left'>
              <div
                className={`fpc_lc_cl_checkbox_container fpc_lc_cl_checkbox_container_disabled${
                  'abc' === selectedId ? ' fpc_lc_cl_checkbox_container_selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedId('abc')
                }}>
                {'abc' === selectedId && <CheckIcon />}
              </div>
              <FavoriteIcon className='fpc_lc_c_icon' />
              <p className='fpc_lc_c_text'>Favorites</p>
            </div>
          </div> */}
          {data.map((folder) => (
            <FolderListChunk
              key={folder.id}
              className='mtfm_modal_bc_c_chunk'
              data={folder}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              showCheckbox={true}
              showOptionIcon={false}
            />
          ))}
          {showCreateFolderField ? (
            <div className='fpc_lc_chunk fpc_lc_chunk_has_checkbox mtfm_modal_bc_c_chunk'>
              <div className='fpc_lc_chunk_left'>
                <div
                  className={`fpc_lc_cl_checkbox_container fpc_lc_cl_checkbox_container_disabled${
                    'check' === selectedId ? ' fpc_lc_cl_checkbox_container_selected' : ''
                  }`}
                  // onClick={(e) => {
                  //   e.stopPropagation()
                  //   setSelectedId('check')
                  // }}
                >
                  {'check' === selectedId && <CheckIcon />}
                </div>
                <Input
                  placeholder='Type new folder name'
                  value={value}
                  autoFocus={true}
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                />
                <CrossIcon
                  className='fpc_lc_chunk_dropdown_icon'
                  onClick={(e) => {
                    e.stopPropagation()
                    setValue('')
                    setShowCreateFolderField(false)
                  }}
                />
                {value ? (
                  <TickIcon
                    className={`fpc_lc_chunk_dropdown_icon`}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (value) {
                        onCreate(value)
                        setValue('')
                        setShowCreateFolderField(false)
                      }
                    }}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        <div className='mtfm_mbc_footer'>
          <ButtonImage
            icon={AddIcon}
            className='mtfm_mbc_f_button'
            onClick={(e) => {
              e.stopPropagation()
              setShowCreateFolderField(true)
            }}>
            Create new Folder
          </ButtonImage>
          <div className='mtfm_mbc_f_button_container'>
            <ButtonImage
              className='mtfm_mbc_f_bc_button'
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}>
              Cancel
            </ButtonImage>
            <ButtonImage className='mtfm_mbc_f_bc_button' disabled={!selectedId} onClick={() => onConfirm(selectedId)}>
              Confirm
            </ButtonImage>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default MoveToFolderModal
