import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { NarrativeParamsType } from 'store/monitorVersion2/types'
import { ReportRoutesType } from 'store/report/valueTypes'

export function getListsWithParams({ endpoint, params }: { endpoint: ReportRoutesType; params: NarrativeParamsType }) {
  return axios({
    method: 'get',
    url: ROUTES[endpoint] as ReportRoutesType,
    params,
  })
}
