/* eslint-disable prettier/prettier */
import { watchlistAPIs } from 'api/api'
import dayjs from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import { UtilService } from 'services/Util/Util'
import { ManageStore } from './manage.store'
import { WatchlistProps } from 'api/watchlist/submitWatchlist'

export class ManageWatchlistStore extends ManageStore {
  allDataTab = 3
  storeType: 'tag' | 'community' | 'narrative' | 'channel' | 'watchlist' = 'watchlist'

  avableShareUsers: { name: string; email: string; role: 'viewer' | 'owner'; canBeEdited: boolean }[] = [
    { name: 'Bessie Cooper', email: 'bessie.cooper@outlook.com', role: 'owner', canBeEdited: false },
    { name: 'Jane Cooper', email: 'jane.cooper@outlook.com', role: 'viewer', canBeEdited: true },
  ]

  isRequestModalOpen: boolean = false
  isPostRequestModalOpen: boolean = false

  get filterTableTab1() {
    return `owner_id:eq:${this.userID}`
  }
  get filterTableTab2() {
    const date = dayjs().subtract(30, 'days').toISOString()
    return `create_time:gt:${date}`
  }
  get filterTableTab3() {
    return ``
  }

  constructor() {
    super()
    makeObservable(this, {
      isRequestModalOpen: observable,
      isPostRequestModalOpen: observable,

      setIsRequestModalOpen: action.bound,
      setIsPostRequestModalOpen: action.bound,
      submitWatchlist: action.bound,
    })
  }

  setIsRequestModalOpen = (state: boolean) => {
    this.isRequestModalOpen = state
  }

  setIsPostRequestModalOpen = (state: boolean) => {
    this.isPostRequestModalOpen = state
  }

  submitWatchlist = async (watchlistDescription: WatchlistProps) => {
    try {
      await watchlistAPIs.saveWatchlist(watchlistDescription)
      this.setIsRequestModalOpen(false)
      this.setIsPostRequestModalOpen(true)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
}
