import Modal from 'antd/lib/modal/Modal'
import './FlagsModal.scss'
import { store } from 'store'
import { useNavigate } from 'react-router-dom'

interface Props {
  isEditMode: boolean
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
}

export const SuccessModal = ({ isEditMode, isModalVisible, setIsModalVisible }: Props) => {
  const navigate = useNavigate()
  const { fetchFlags } = store.attentionFlagsStore
  return (
    <Modal
      centered
      closable
      width={387}
      open={isModalVisible}
      bodyStyle={{ overflow: 'auto' }}
      className='flags-modal'
      footer={
        <div>
          <button
            className='flags-modal__close-btn'
            onClick={() => {
              setIsModalVisible(false)
              fetchFlags()
              if (isEditMode) navigate('/settings/power-insights')
            }}>
            Close
          </button>
        </div>
      }>
      <div className='flags-modal'>
        <span className='flags-modal__title'> Flag {isEditMode ? 'updated' : 'created'} successfully</span>
        <span className='flags-modal__description'>Your created flag will turned ON if your defined rule</span>
        <span className='flags-modal__description'>matches your search.</span>
      </div>
    </Modal>
  )
}
