import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { store } from 'store'
import { Tooltip } from 'antd'
import ReactCountryFlag from 'react-country-flag'
import millify from 'millify'
import * as d3 from 'd3'

import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { monitorMode, monitorSnippetPage } from 'models/models'
import { CountryDataCodes } from './CountryDataCodes'

import './MonitorMapChart.scss'
import { useParams } from 'react-router-dom'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'
interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const MonitorMapChart = observer(({ mode, page }: Props) => {
  let clickedRegion = ''
  const height = 700
  const { narrativeNumber: narrativeNumberParam } = useParams()

  let currentStore, monitorStore
  if (page === 'define' && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  }

  const { loaderStore } = store
  const { isLoadingCountryMapChart } = loaderStore
  const {
    powerInsightsData,
    snippetsFilter,
    powerInsightsFilterChips,
    setPowerInsightsFilterChips,
    setSnippetsFilter,
    fetchPowerInsights,
  } = currentStore
  const { activeItem } = monitorStore
  const { fetchPowerInsights: executivePowerInsights } = store.executiveIntelligence

  const [tooltipData, setTooltipData] = useState(<></>)
  const [currentWidth, setCurrentWidth] = useState(1000)
  const [mapDataJson, setMapDataJson] = useState<any>({})

  useEffect(() => {
    if (mapDataJson && Object.keys(mapDataJson)?.length > 0) {
      drawMap(mapDataJson)
    } else {
      d3.json('/data/worldCountriesData.json').then((mapData: any) => {
        setMapDataJson(mapData)
        drawMap(mapData)
      })
    }
  }, [powerInsightsData.countriesMapData, powerInsightsData.countriesMapData?.length, clickedRegion, currentWidth])

  const drawMap = (mapData: any) => {
    const div = d3.select('#chart')
    div.selectChildren('svg').remove()
    const svgGraphicsElement = d3.select(`#chart`).node() as SVGGraphicsElement
    const boundingRect = svgGraphicsElement?.getBoundingClientRect()
    setCurrentWidth(boundingRect?.width || currentWidth)
    const svg = d3
      .select('#chart')
      .append('svg')
      .attr('id', 'map')
      .attr('height', height)
      .attr('width', boundingRect?.width || currentWidth)
    const projection: any = d3.geoMercator().fitSize([currentWidth, height], mapData)
    const pathGenerator: any = d3.geoPath().projection(projection)

    svg
      .selectAll('path')
      .data(mapData.features)
      .enter()
      .append('path')
      .attr('d', pathGenerator)
      .attr('id', (d: any) => `region-${d.properties.ADM0_A3}`)
      .attr('class', 'map__world-chart')
      .on('mouseover', function (event, d: any) {
        if (!d3.select(`#${d.properties.ADM0_A3}`).empty()) {
          d3.select(`#${d.properties.ADM0_A3}`).attr('class', 'map__bubble--black')
          countryPopup(event, d.properties.NAME, d.properties.ADM0_A3, d.properties.ISO_A2)
        }
      })
      .on('mouseout', function (event, d: any) {
        if (clickedRegion !== d.properties.ADM0_A3 && !d3.select(`#${d.properties.ADM0_A3}`).empty()) {
          d3.select(`#${d.properties.ADM0_A3}`).attr('class', 'map__bubble')
          setTooltipData(<></>)
        }
        if (clickedRegion && !d3.select(`#${clickedRegion}`).empty()) {
          const data: any = CountryDataCodes
          d3.select(`#${clickedRegion}`).attr('class', 'map__bubble--black')
          countryPopup(event, data[clickedRegion].NAME, clickedRegion, data[clickedRegion].ISO_A2)
        }
      })
      .on('click', function (event, d: any) {
        selectCountry(d.properties.ADM0_A3)
      })

    svg
      .selectAll('circle')
      .data(powerInsightsData.countriesMapData || [])
      .enter()
      .append('circle')
      .attr('id', (d) => d.entity_name)
      .attr('class', 'map__bubble')
      .attr(
        'cx',
        (d) => projection(d3.geoCentroid(mapData.features.find((f: any) => f.properties.ADM0_A3 === d.entity_name)))[0],
      )
      .attr(
        'cy',
        (d) => projection(d3.geoCentroid(mapData.features.find((f: any) => f.properties.ADM0_A3 === d.entity_name)))[1],
      )
      .on('mouseover', function (event, d: any) {
        const data: any = CountryDataCodes
        d3.select(`#region-${d.entity_name}`).attr('class', 'map__world-chart--selected')
        if (!d3.select(`#${d.entity_name}`).empty()) {
          d3.select(`#${d.entity_name}`).attr('class', 'map__bubble--black')
          countryPopup(event, data[d.entity_name]?.NAME, d.entity_name, data[d.entity_name]?.ISO_A2)
        }
      })
      .on('mouseout', function (event, d: any) {
        d3.select(`#region-${d.entity_name}`).attr('class', 'map__world-chart')
        if (clickedRegion !== d.entity_name && !d3.select(`#${d.entity_name}`).empty()) {
          d3.select(`#${d.entity_name}`).attr('class', 'map__bubble')
          setTooltipData(<></>)
        }
      })
      .on('click', function (event, d: any) {
        selectCountry(d.entity_name)
      })

    if (snippetsFilter.country?.length) {
      d3.select(`#region-${snippetsFilter.country[0]}`).attr('class', 'map__world-chart--selected')
      d3.select(`#${snippetsFilter.country[0]}`).attr('class', 'map__bubble--black')
      clickedRegion = snippetsFilter.country[0]
    }
  }

  const countryPopup = (event: any, name: string, codeA3: string, codeA2: string) => {
    let count = 0
    const data = powerInsightsData.countriesMapData?.filter((item) => item.entity_name === codeA3)
    if (data?.length) count = data[0].metric_value || 0

    const customPopupContainer = document.getElementById('tooltip') || document.body

    const tooltip = (
      <Tooltip
        title={
          <div className='map__info-tooltip__content'>
            <div className='map__info-tooltip__content__name__container'>
              <ReactCountryFlag
                countryCode={codeA2}
                svg
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
              <span className='map__info-tooltip__content__name'>{name}</span>
            </div>
            <span className='map__info-tooltip__content__data'>{millify(count)}</span>
          </div>
        }
        open
        overlayClassName='map__info-tooltip'
        getPopupContainer={() => {
          return customPopupContainer
        }}
      />
    )
    setTooltipData(tooltip)
    const svgGraphicsElement = d3.select(`#${codeA3}`).node() as SVGGraphicsElement

    const boundingRect = svgGraphicsElement.getBBox()

    d3.select('#tooltip')
      .style('position', 'absolute')
      .style('left', `${boundingRect.x + 5}px`)
      .style('top', `${boundingRect.y + 5}px`)
      .style('display', 'block')
  }

  const selectCountry = (code: string) => {
    if (code === clickedRegion) {
      if (!d3.select(`#${clickedRegion}`).empty()) d3.select(`#${clickedRegion}`).attr('class', 'map__bubble')

      d3.select(`#region-${clickedRegion}`).attr('class', 'map__world-chart')
      let { country, ...filter } = snippetsFilter
      setSnippetsFilter(filter)
      clickedRegion = ''
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        country: {
          ...powerInsightsFilterChips.country,
          value: '',
        },
      })
    } else {
      const data: any = CountryDataCodes
      if (clickedRegion.length) {
        d3.select(`#region-${clickedRegion}`).attr('class', 'map__world-chart')
        if (!d3.select(`#${clickedRegion}`).empty()) d3.select(`#${clickedRegion}`).attr('class', 'map__bubble')
      }
      if (!d3.select(`#${code}`).empty()) d3.select(`#${code}`).attr('class', 'map__bubble--black')

      d3.select(`#region-${code}`).attr('class', 'map__world-chart--selected')
      setSnippetsFilter({ ...snippetsFilter, country: [code] })
      clickedRegion = code
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        country: {
          ...powerInsightsFilterChips.country,
          value: data[code].NAME,
        },
      })
    }

    if (page === 'executive-intelligence') return executivePowerInsights(narrativeNumberParam || '')

    fetchPowerInsights(activeItem?.id ?? '', '')
  }

  const handleResize = () => {
    const svgGraphicsElement = d3.select(`#chart`).node() as SVGGraphicsElement
    const boundingRect = svgGraphicsElement?.getBoundingClientRect()
    setCurrentWidth(boundingRect?.width || currentWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {isLoadingCountryMapChart ? (
        <LoadingOverlay
          className='loading-overlay-height'
          description='Map is being loaded. We appreciate your patience.'
        />
      ) : (
        <>
          <div id='chart'></div>
          <div id='tooltip'>{tooltipData}</div>
        </>
      )}
    </>
  )
})
