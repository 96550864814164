import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Input } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { monitorMode } from 'models/models'

import './SubnavSearch.scss'

interface Props {
  mode: monitorMode
  page: 'monitor' | 'manage' | 'investigate'
}

export const SubnavSearch = observer(({ mode, page }: Props) => {
  let location = useLocation()
  const navigate = useNavigate()

  const { userStore, subnavSearch } = store
  const { input, selectedItem, setInput, resetInput } = subnavSearch

  const createGTMSearchEvent = (selectedItem: string | null, searchText: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'search_input',
        selectedItem: selectedItem?.toLowerCase() ?? 'none',
        searchText,
        user_id: userStore.userId,
        user_name: userStore.userName,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
      },
    })
  }

  useEffect(() => {
    if (!input) return
  }, [input])

  useEffect(() => {
    if (!location.pathname.match(/((([A-z])*\/){3,4}[A-z0-9-]*)/g)?.length) resetInput()
  }, [location.key])

  return (
    <div className={'subnav-search'}>
      <Input
        onPressEnter={async () => {
          if (!input) {
            navigate(`/${page}/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`, { replace: true })
            return
          } else {
            createGTMSearchEvent(selectedItem, input)
            navigate(`/${page}/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}/querry/${input}`, { replace: true })
          }
        }}
        placeholder={'Search'}
        className={classNames('subnav-search__input')}
        prefix={<SearchIcon className='subnav-search__icon' />}
        onChange={(e) => {
          setInput(e.target.value)
        }}
        value={input}
      />
    </div>
  )
})
