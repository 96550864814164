import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { useUnmount } from 'react-use'
import { List } from 'antd'
import VirtualList from 'rc-virtual-list'

import { MonitorSnippetItem } from '../MonitorSnippetItem/SnippetItem'
import { monitorMode, monitorSnippetPage } from 'models/models'

import './SnippetPage.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const AttentionFlagsSnippetList: React.FC<Props> = observer(({ mode, page }) => {
  const ContainerHeight = 670
  const itemHeight = 146

  const { defineNarrativeStoreV1: defineNarrativeStore } = store
  const { liteCommunities, snippets, fetchSnippets, resetStore } = defineNarrativeStore

  useUnmount(() => {
    resetStore()
  })
  const checkFeed = () => {
    if (!snippets.length) {
      return <></>
    } else {
      return (
        <VirtualList
          className='c-monitor-communities__items'
          data={snippets.length > 0 ? snippets : []}
          height={ContainerHeight}
          itemHeight={itemHeight}
          itemKey='id'
          onScroll={(e) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight)
              fetchSnippets({ appendMode: true })
          }}>
          {(snippet) => {
            return (
              <MonitorSnippetItem
                mode={mode}
                page={page}
                snippet={snippet}
                allWatchlist={[]}
                allCommunities={liteCommunities}
                showSurprise={() => {}}
              />
            )
          }}
        </VirtualList>
      )
    }
  }
  return (
    <div className='snippet-list__wrapper'>
      <List>{checkFeed()}</List>
    </div>
  )
})
