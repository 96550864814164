import { getRandomColor } from './getRandomColor'
import { sourcePlatform } from 'models/models'

export const getPlatformBrandColor = (platform: sourcePlatform | Lowercase<sourcePlatform>) => {
  let color = ''

  switch (platform) {
    case 'twitter':
    case 'Twitter':
      color = '#00ACEE'
      break

    case 'youtube':
    case 'YouTube':
      color = '#C51313'
      break

    case 'tiktok':
    case 'TikTok':
      color = '#000000'
      break

    case 'podcast':
    case 'Podcast':
      color = '#BC3FE0'
      break

    case 'news':
    case 'News':
      color = '#040487'
      break

    case 'bitchute':
    case 'BitChute':
      color = '#EF4138'
      break

    case 'rumble':
    case 'Rumble':
      color = '#75CD1D'
      break

    case 'reddit':
    case 'Reddit':
      color = '#FF4500'
      break

    case '4chan':
      color = '#8A2DC5'
      break

    case 'gab':
    case 'Gab':
      color = '#62C886'
      break

    case 'patriots.win':
    case 'Patriots.win':
      color = '#8A2DC5'
      break

    case 'truthsocial':
    case 'TruthSocial':
      color = '#5448EE'
      break

    case 'vk':
      color = '#0077FF'
      break

    case 'instagram':
    case 'Instagram':
      color = '#D300C5'
      break

    case 'threads':
    case 'Threads':
      color = '#000000'
      break

    case 'telegram':
    case 'Telegram':
      color = '#0088CC'
      break

    case 'brighteon':
    case 'Brighteon':
      color = '#1B2E5A'
      break

    case 'Snapchat':
    case 'snapchat':
      color = '#FFFC00'
      break

    case 'Odysee':
    case 'odysee':
      color = '#CC2C53'
      break

    default:
      color = getRandomColor()
  }

  return color
}
