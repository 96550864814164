import { makeAutoObservable } from 'mobx'
import { ISpotlight, IRecipients } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { spotlightAPI } from 'api/api'

export class SpotlightStore {
  map(arg0: (user: { user_id: any }) => any) {
    throw new Error('Method not implemented.')
  }
  id: string | null = null
  tenantId: string | null = null
  createdBy: string | null = null
  createdOn: string | null = null
  brief: string | null = null
  actions: 'ADD_SNIPPET' | 'REMOVE_SNIPPET' | 'GENERATE_BRIEF' | 'EDIT_BRIEF' = 'ADD_SNIPPET'
  recipients: IRecipients[] | null = null
  snippetId: string | null = null
  narrativeId: string | null = null
  spotlights: ISpotlight[] = []
  spotlightInfo: {
    brief: string
    actions: string
    recipients: string
    snippetId: string
    narrativId: string
  } = {
    brief: '',
    actions: '',
    recipients: '',
    snippetId: '',
    narrativId: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  getSpotlight = async (spotlight: string) => {
    const { data, error } = await spotlightAPI.getSpotlight(spotlight)
    if (error) {
      const response = error.response
      return UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    this.setSpotlight(data)
  }

  listSpotlight = async () => {
    const { data, error } = await spotlightAPI.listSpotlights()
    if (error) {
      const response = error.response
      return UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    this.setSpotlight(data)
  }

  spotlightActions = async (spotlight: ISpotlight) => {
    const { data, error } = await spotlightAPI.spotlightActions(spotlight)

    if (error || !data) {
      const response = error.response
      return UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    UtilService.openNotification({
      type: 'info',
      message: 'Success',
      description: 'Spotlight action completed succesfully',
    })
  }

  sendSpotlight = async (spotlight: ISpotlight) => {
    const { data, error } = await spotlightAPI.sendSpotlight(spotlight)

    if (error || !data) {
      const response = error.response
      return UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    UtilService.openNotification({ type: 'info', message: 'Success', description: 'Spotlight sent successfully' })
  }
  setSpotlight = (spotlights: ISpotlight[]) => (this.spotlights = spotlights)
}
