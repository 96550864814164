import dayjs from 'dayjs'
import millify from 'millify'
import { makeAutoObservable } from 'mobx'
import { minBy } from 'remeda'
import axios from 'axios'
import { NarrativeParamsType } from 'store/monitorVersion2/types'
import { API, monitorAPI, tagAPI, powerInsightsAPI, exportAPI } from 'api/api'
import {
  ExecutiveCategoryNarrativeType,
  ExecutiveCategoryType,
  ISingleExecutiveData,
  IExecutiveData,
  TRangeStrings,
  IPagination,
  SnippetsFilterInfo,
  ILightTag,
  IPowerInsightsData,
  IVideoBubble,
  ISnippet,
  SnippetsFilter,
  sourcePlatform,
  ILiteWatchlist,
  IPowerInsightSortFilter,
  IFilterChips,
  SnippetFilterPayload,
  LanguageRisk,
  IBarChartWatchlistData,
  monitorMode,
  NarrativeListResponseType,
} from 'models/models'
import { LogService } from 'services/Log/Log'
import { getTableData } from 'services/Util/getTableData'
import { UtilService } from 'services/Util/Util'
import {
  LANGUAGE_METRICS_DIMENSIONS,
  MONITOR_SNIPPET_MODE_LABEL,
  MONITOR_SNIPPET_SORT_LABEL,
  MONITOR_SNIPPET_SORT_VALUE_BY_LABEL,
  MONITOR_SNIPPET_MODE_VALYE_BY_LABEL,
  MONITOR_POWER_INSIGHT_SORT_LABEL,
  MONITOR_POWER_INSIGHT_MODE_LABEL,
  MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL,
  MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL,
} from 'settings/settings'
import { anticipatoryIntelligence, defineNarrativeAPI, executiveIntelligenceAPI, bookmarksAPI } from 'api/api'

export class ExecutiveIntelligenceStore {
  tablePagination: IPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    totalMillify: '0',
  }

  dateFilterOptions = Object.freeze({
    'Last 7 days': 7,
    'Last 30 days': 30,
    'Last 90 days': 90,
    'Last 6 months': 180,
    'Last 1 year': 365,
    'Last 2 years': 730,
  } as { [key in TRangeStrings]: number }) // eslint-disable-line no-unused-vars

  selectedOption: TRangeStrings = 'Last 2 years'

  personsTableData: IExecutiveData[] = []
  filteredPersonsTableData: IExecutiveData[] = []
  isRequestExecutiveModalOpen = false
  isPostRequestModalOpen = false
  isFeedEmpty: boolean = true
  singlePersonData: ISingleExecutiveData | null = null
  isAddNarrativeModalOpen: boolean = false
  requestAccessData: { ownersIds: string[]; restrictedCount: number } | null = null
  executiveLanguageSnippets: ISnippet[] = []
  snippetsTotal: number = 0
  snippetsCurrentPage: number = 1
  userBookmarkedSnippets: string[] = []
  loadedCreatorIds: string[] = []
  loadedCreators: any[] = []
  liteCommunities: { id: number; name: string }[] = []
  lightTags: ILightTag[] = []
  liteWatchlists: ILiteWatchlist[] = []
  currenCategory: ExecutiveCategoryType = 'All'

  snippetsFilterInfo: SnippetsFilterInfo = {
    risk: 0,
    sentiment: 0,
    platform: 0,
    sort: '',
    mode: '',
    language: false,
    source: 0,
  }
  avableFilters = {}
  listFilter: { [key: string]: string } = {}
  availableSnippetsSortFilters = Object.freeze({
    sort: Object.values(MONITOR_SNIPPET_SORT_LABEL),
    sort_mode: Object.values(MONITOR_SNIPPET_MODE_LABEL),
  })
  snippetsFilter: SnippetsFilter = {
    days: {
      custom: {
        isApplied: false,
        dateRange: [],
      },
      noOfDays: 90,
    },
    sort: 'Upload Date',
    sort_mode: 'Descending',
    filter_risk: [0, 100],
  }
  availablePowerInsightsSortFilters = Object.freeze({
    sort: Object.values(MONITOR_POWER_INSIGHT_SORT_LABEL),
    sort_mode: Object.values(MONITOR_POWER_INSIGHT_MODE_LABEL),
  })
  powerInsightSortFilter: IPowerInsightSortFilter = { sort: 'Alphabetically', sort_mode: 'Ascending' }
  powerInsightsFilterChips: IFilterChips = {
    attentionFlags: {
      label: 'Attention flags',
      value: '',
    },
    watchlist: { label: 'Watchlist', value: '' },
    community: { label: 'Community', value: '' },
    languageRisk: { label: 'Language Risk', value: '' },
    people: { label: 'People', value: '' },
    politician: { label: 'Politician', value: '' },
    country: { label: 'Country', value: '' },
    company: { label: 'Company', value: '' },
    language: { label: 'Language', value: '' },
    assets: { label: 'Asset', value: '' },
    flags: { label: 'Flag', value: '' },
  }
  powerInsightsData: IPowerInsightsData = {
    summary: {
      executiveSummary: '',
      statistics: [
        {
          label: 'Snippets',
          value: '0',
        },
        {
          label: 'Unique Creators',
          value: '0',
        },
        {
          label: 'Companies',
          value: '0',
        },
        {
          label: 'People',
          value: '0',
        },
        {
          label: 'Organizations',
          value: '0',
        },
        {
          label: 'Views',
          value: '0',
        },
      ],
      platformsDistribution: [],
    },
    lineChartData: {
      posts: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
        platform: { data_points: [] },
      },
      impressions: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
        platform: { data_points: [] },
      },
    },
    heatMapData: { data_points: [] },
    beeSwarmNodesData: [],
    languageRisks: {},
    watchlistData: { completeData: [], data: [] },
  }
  narrativesListItems: NarrativeListResponseType = {
    total_count: 0,
    items: [],
  }
  isPowerInsightsOpen: boolean = false
  personnelId: string = ''
  defaultPlatforms: string = ''
  constructor() {
    makeAutoObservable(this)
  }

  get executiveTableData() {
    return getTableData({ source: this.personsTableData, table: 'executive' })
  }

  get filteredExecutiveTableData() {
    return getTableData({ source: this.filteredPersonsTableData, table: 'executive' })
  }

  getModeConditions = (narrativeNumber?: { operation: 'and' | 'or'; numbers: string[] | number[] }) => {
    const narrativeIds: any = []
    let conditions = []
    if (this.singlePersonData?.associatedNarrativeNumber)
      conditions.push({ narratives: [this.singlePersonData?.associatedNarrativeNumber] })

    if ((narrativeNumber?.numbers?.length || 0) > 0) {
      narrativeNumber?.numbers.forEach((value) => {
        if (!isNaN(parseInt(value.toString())) && narrativeNumber?.operation === 'or') {
          narrativeIds.push(parseInt(value.toString()))
        } else {
          narrativeIds.push({ narratives: [parseInt(value.toString())] })
        }
      })
      if (narrativeNumber?.operation === 'or') conditions.push({ narratives: narrativeIds })
      else conditions = [...narrativeIds, ...conditions]
    }
    return conditions
  }

  setSetCategory = (category: typeof this.currenCategory) => {
    this.currenCategory = category
  }

  setSnippetsFilterInfo = (info: SnippetsFilterInfo) => {
    this.snippetsFilterInfo = info
  }

  setIsPowerInsightsOpen = (state: boolean) => {
    this.isPowerInsightsOpen = state
  }

  setSnippetsFilterAndFetchFeed = (
    selectedLocalFilter: { [key: string]: string | [number, number] | string[] | Object },
    dontFetchFeed?: boolean,
  ) => {
    const filterKeys = Object.keys(selectedLocalFilter)

    const cleanedFilter: { [key: string]: any } = {}

    filterKeys.forEach((el) => {
      if (!selectedLocalFilter[el]) return

      if (typeof selectedLocalFilter[el] === 'object') {
        cleanedFilter[el] = selectedLocalFilter[el]

        return
      }

      if (selectedLocalFilter[el] !== '') {
        cleanedFilter[el] = selectedLocalFilter[el]
        return
      }
    })

    this.setSnippetsFilter(cleanedFilter)

    if (this.currenCategory && !dontFetchFeed)
      this.fetchLanguageSnippets({ categoryChange: true, category: this.currenCategory })
  }

  setSnippetsFilter = (filter: any) => {
    this.snippetsFilter = filter
  }

  setDefaultPlatforms = (platforms: string) => {
    this.defaultPlatforms = platforms
  }

  setPowerInsightsData = (data: IPowerInsightsData) => {
    this.powerInsightsData = data
  }

  setPowerInsightsFilterChips = (filter: any) => {
    this.powerInsightsFilterChips = filter
  }

  setIsFeedEmpty = (state: boolean) => {
    this.isFeedEmpty = state
  }

  submitExecutive = (info: string) => {
    //TODO: submit executive to API
    this.setIsPostRequestModalOpen(true)
  }

  getCreators = async (idArray: string[]) => {
    const ids = idArray.filter((id) => !this.loadedCreatorIds.includes(id))
    this.setCreatorIds([...this.loadedCreatorIds, ...ids])
    const idString = ids.join(',')

    let data

    if (idString?.length)
      ({ data } = await API.get({ route: 'channel', customAPI: process.env.REACT_APP_SEARCH_API, ids: idString }))

    if (data?.items) this.setCreators([...this.loadedCreators, ...data.items])

    this.setExecutiveLanguageSnippets(
      this.executiveLanguageSnippets.map((snippet) => {
        if (snippet.author.channel_title) return snippet
        const creator = this.loadedCreators.filter((creator: any) => {
          return creator.channel_id === snippet.channelId
        })
        return { ...snippet, author: creator.length ? creator[0] : '' }
      }),
    )

    const snippetCommunities = this.executiveLanguageSnippets.map((snippet) => {
      return snippet.author.communities
    })

    const communitiesRequest: number[] = []

    snippetCommunities?.forEach((communityArray) => {
      communityArray?.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
    })

    this.getCommunities(communitiesRequest)
  }

  fetchLightTags = async () => {
    try {
      const data = await tagAPI.getSummaryTags()
      this.setLightTags(data.tags)
    } catch (e) {}
  }

  fetchLiteWatchlists = async () => {
    try {
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })
      const watchlistsData = response?.data.items?.map((watchlist: any) => {
        let channels = []
        if (watchlist?.channels) {
          channels = watchlist?.channels.map((channel: any) => {
            const urls = channel?.channel_by_platform.map((item: any) => item.channel_url)
            return {
              platform: channel.platform,
              channel_urls: urls,
            }
          })
        }
        return { id: watchlist.id, name: watchlist?.name, channels: channels }
      })
      this.setLiteWatchlists(watchlistsData)
    } catch (error) {}
  }

  setLightTags = (lightTags: ILightTag[]) => {
    this.lightTags = lightTags
  }

  setLiteWatchlists = (data: ILiteWatchlist[]) => {
    this.liteWatchlists = data
  }

  getCommunities = async (communitiesRequest: number[]) => {
    const checkedCommunities = communitiesRequest.filter((community, index) => {
      return communitiesRequest.indexOf(community) === index
    })

    const allreadyHave = this.liteCommunities
      .filter((community) => checkedCommunities.includes(community.id))
      .map((community) => community.id)

    const filteredCommunities = checkedCommunities.filter((community) => !allreadyHave.includes(community))

    if (filteredCommunities?.length === 0) return

    const { data } = await API.get({
      route: 'community',
      filter: `community_number:in:${JSON.stringify(filteredCommunities)}`,
    })

    const communitiesData = data?.items?.map((community: any) => {
      return { id: community.community_number, name: community.name }
    })

    if (communitiesData) this.setCommunities([...this.liteCommunities, ...communitiesData])
  }

  updatePagination = (newPagination: IPagination) => {
    this.tablePagination = newPagination
  }

  setSnippetsCurrentPage = (page: number) => {
    this.snippetsCurrentPage = page
  }

  setSnippetsTotal = (total: number) => {
    this.snippetsTotal = total
  }

  setExecutiveLanguageSnippersPush = (snippets: ISnippet[]) => {
    this.executiveLanguageSnippets.push(...snippets)
  }

  setExecutiveLanguageSnippets = (snippets: ISnippet[]) => {
    this.executiveLanguageSnippets = snippets
  }

  setUserBookmarkedSnippets = (items: string[]) => {
    this.userBookmarkedSnippets = items
  }

  setSinglePersonData = (person: ISingleExecutiveData | null) => {
    this.singlePersonData = person
  }

  setPersonsTableData = (persons: IExecutiveData[]) => {
    this.personsTableData = persons
  }

  setFilteredPersonsTableData = (persons: IExecutiveData[]) => {
    this.filteredPersonsTableData = persons
  }

  setSelectedOption = (option: TRangeStrings) => {
    this.selectedOption = option
  }

  setIsRequestExecutiveModalOpen = (state: boolean) => {
    this.isRequestExecutiveModalOpen = state
  }

  setIsPostRequestModalOpen = (state: boolean) => {
    this.isPostRequestModalOpen = state
  }

  setIsAddNarrativeModalOpen = (state: boolean) => {
    this.isAddNarrativeModalOpen = state
  }

  setRequestAccessData = (data: { ownersIds: string[]; restrictedCount: number }) => {
    this.requestAccessData = data
  }

  setCreatorIds = (creatorIds: string[]) => {
    this.loadedCreatorIds = creatorIds
  }

  setCreators = (creators: any[]) => {
    this.loadedCreators = creators
  }

  setCommunities = (communities: { id: number; name: string }[]) => {
    this.liteCommunities = communities
  }

  setNarrativesListItems = (narrativeResponse: NarrativeListResponseType) => {
    this.narrativesListItems = narrativeResponse
  }

  setListsWithParams = (narrativeResponse: NarrativeListResponseType) => {
    this.narrativesListItems = {
      total_count: narrativeResponse.total_count,
      items: [...this.narrativesListItems.items, ...narrativeResponse.items],
    }
  }

  getFormattedBeeSwarmVideoNodes = (items: IVideoBubble[]) => {
    const minViews = minBy(items, (post) => post.post_views)?.post_views ?? 1 // if none of the items have any views then assume that minimum value of `post_views` is 1
    const defaultBubbleValue = minViews / 2 // default value for video bubbles where `post_views` is NULL is half the value of minimum views of any post. This will improve their visibility in chart no matter how big the dataset is

    return items.map((video, index) => {
      const color = UtilService.getPlatformColor(video.platform)
      const date = new Date(video.post_upload_date)

      return {
        date,
        color,
        id: index.toString(),
        value: video.post_views ?? defaultBubbleValue,
        source: video.platform,
        data: {
          ...video,
          postViewsMillify: millify(video.post_views || 0),
          date: dayjs(date).format('MMM DD, YYYY'),
        },
        selected: true,
      }
    })
  }

  updateBeeSwarmNodesSelection = (platform: sourcePlatform | '') => {
    const updatedNodes = this.powerInsightsData.beeSwarmNodesData.map((node) => ({
      ...node,
      selected: node.data.platform === platform || platform === '',
    }))

    this.setPowerInsightsData({
      ...this.powerInsightsData,
      beeSwarmNodesData: updatedNodes,
    })
  }

  getTimeSpan = (
    { rangeInDays = 1, shiftByDays = 0 }: { rangeInDays?: number; shiftByDays?: number } = {
      rangeInDays: 1,
      shiftByDays: 0,
    },
  ) => {
    const currentDate = dayjs().subtract(shiftByDays, 'days').format('YYYY-MM-DD')
    const pastDate = dayjs()
      .subtract(rangeInDays + shiftByDays, 'days')
      .format('YYYY-MM-DD')

    return {
      startDate: pastDate,
      endDate: currentDate,
    }
  }

  snippetFilterPayload = (
    narrativeNumber: { operation: 'and' | 'or'; numbers: string[] | number[] },
    category?: ExecutiveCategoryNarrativeType | ExecutiveCategoryType,
    skip?: string,
  ) => {
    const payload: SnippetFilterPayload = {
      page: 1,
      per_page: 1,
      query: {
        date: '',
        platform: '',
        sentiment: '',
        source: '',
        risk: '',
        translationLanguage: '',
        people: '',
        politician: '',
        company: '',
        country: '',
        language: '',
        community: '',
        languageRisk: '',
      },
      sort: '',
      powerInsightSort: '',
      conditions: [],
    }

    const TIME_FORMAT = 'YYYY-MM-DD'
    const categoryLookup = {
      All: 'a',
      Negative: 'a',
      Obscene: 'obscene_language',
      'Identity Hate': 'hate_language',
      Toxic: 'toxic_language',
      Threat: 'threat_language',
      Source: 'a',
      /** A graph cannot be one of the following categories */
      NarrativeAll: '',
      NarrativeMainstream: '',
      NarrativeRisk: '',
      NarrativeNegative: '',
    }
    const days = this.snippetsFilter.days.noOfDays
    const dateRange = this.snippetsFilter.days.custom.isApplied ? this.snippetsFilter.days.custom.dateRange : []

    if (dateRange.length) {
      payload.query.date = `start_date:gte:${dateRange[0]},end_date:lte:${dateRange[1]}`
    } else {
      const endDate = dayjs().format(TIME_FORMAT)
      const yesterday = dayjs(endDate).subtract(1, 'days').format(TIME_FORMAT)
      const startDate = days ? dayjs(endDate).subtract(days, 'days').format(TIME_FORMAT) : yesterday
      payload.query.date = `start_date:gte:${startDate},end_date:lte:${endDate}`
    }

    let languageRisk: LanguageRisk = categoryLookup[category || this.currenCategory] as LanguageRisk

    if (languageRisk === 'a') {
      languageRisk = null
      switch (this.currenCategory) {
        case 'Negative':
          this.setSnippetsFilter({ ...this.snippetsFilter, filter_sentiment: ['Negative'] })
          break
        case 'Source':
          this.setSnippetsFilter({ ...this.snippetsFilter, filter_risk: [50, 100] })
          break
      }
    }

    payload.query.languageRisk = languageRisk ? `,lang_risk:in:[${languageRisk}]` : ''

    payload.query.platform = this.snippetsFilter?.filter_platform
      ? `,platform:in:${JSON.stringify(this.snippetsFilter?.filter_platform)}`.replaceAll('"', '')
      : ''
    payload.query.sentiment = this.snippetsFilter?.filter_sentiment?.length
      ? this.snippetsFilter?.filter_sentiment
        ? `,sentiment:in:${JSON.stringify(this.snippetsFilter?.filter_sentiment)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.source = this.snippetsFilter?.snippet_source?.length
      ? this.snippetsFilter?.snippet_source
        ? `,snippet_source:in:${JSON.stringify(this.snippetsFilter?.snippet_source)}`.replaceAll('"', '')
        : ''
      : ''
    payload.query.risk =
      this.snippetsFilter?.filter_risk?.length === 2
        ? `,risk_score:gte:${this.snippetsFilter?.filter_risk[0]},risk_score:lte:${this.snippetsFilter?.filter_risk[1]}`
        : ''
    payload.query.people = this.snippetsFilter?.people ? `,people:in:[${this.snippetsFilter?.people}]` : ''
    payload.query.politician = this.snippetsFilter?.politician
      ? `,politician:in:[${this.snippetsFilter?.politician}]`
      : ''
    payload.query.company = this.snippetsFilter?.company ? `,company:in:[${this.snippetsFilter?.company}]` : ''
    payload.query.country = this.snippetsFilter?.country ? `,country:in:[${this.snippetsFilter?.country}]` : ''
    payload.query.language = this.snippetsFilter?.filter_language?.length
      ? `,lang:in:[${this.snippetsFilter.filter_language}]`
      : ''
    payload.query.community = this.snippetsFilter?.community
      ? `,community_number:eq:${this.snippetsFilter?.community}`
      : ''
    payload.query.translationLanguage = this.snippetsFilter?.lang?.length ? `&lang=${this.snippetsFilter?.lang}` : ''

    payload.sort = this.snippetsFilter
      ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[this.snippetsFilter.sort]}:${
          MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[this.snippetsFilter.sort_mode]
        }`
      : ''

    payload.powerInsightSort = this.powerInsightSortFilter
      ? `${MONITOR_POWER_INSIGHT_SORT_VALUE_BY_LABEL[this.powerInsightSortFilter.sort]}:${
          MONITOR_POWER_INSIGHT_MODE_VALUE_BY_LABEL[this.powerInsightSortFilter.sort_mode]
        }`
      : ''

    payload.conditions = [...payload.conditions, ...this.getModeConditions(narrativeNumber)]

    if (this.snippetsFilter?.filter_keyword && this.snippetsFilter?.filter_keyword?.length > 0) {
      payload.conditions.push({
        keywords_expression: this.snippetsFilter?.filter_keyword,
      })
    }

    if (this.snippetsFilter?.watchlist && this.snippetsFilter?.watchlist?.length > 0) {
      payload.conditions.push({
        channel_urls: this.snippetsFilter?.watchlist,
      })
    }

    return payload
  }

  fetchPowerInsights = async (narrativeNumber: string) => {
    if (!this.singlePersonData?.associatedNarrativeNumber) return

    const payload = this.snippetFilterPayload({ numbers: [narrativeNumber], operation: 'or' })

    const narrativeIds = [this.singlePersonData.associatedNarrativeNumber]
    if (narrativeNumber && !isNaN(parseInt(narrativeNumber))) {
      narrativeIds.push(parseInt(narrativeNumber))
    }

    const [
      { data: postsLineChart },
      { data: postSentimentsLineChart },
      { data: postPlatformsLineChart },
      { data: impressionsLineChart },
      { data: impressionSentimentsLineChart },
      { data: impressionPlatformsLineChart },
      { data: dataHeatMap },
      { items: beeSwarmData },
      peopleData,
      companiesData,
      countriesMapData,
      languagesData,
    ] = await Promise.all([
      powerInsightsAPI.lineChart.getLineChartData(payload),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        dimension: 'sentiment',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        dimension: 'platform',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'impression',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'impression',
        dimension: 'sentiment',
      }),
      powerInsightsAPI.lineChart.getLineChartData({
        ...payload,
        metric: 'impression',
        dimension: 'platform',
      }),
      // Get the heat map communities data for last 24 hours only
      powerInsightsAPI.treeMap.getTreeMapData(payload),
      // Get the Bee Swarm chart data
      powerInsightsAPI.beeSwarm.getBeeSwarmData(payload),
      this.fetchPeopleData(payload),
      this.fetchCompaniesData(payload),
      this.fetchCountriesData(payload),
      this.fetchLanguagesData(payload),
    ])

    this.setPowerInsightsData({
      ...this.powerInsightsData,
      summary: {
        executiveSummary: '',
        statistics: [
          {
            label: 'Snippets',
            value: '0',
          },
          {
            label: 'Unique Creators',
            value: '0',
          },
          {
            label: 'Companies',
            value: '0',
          },
          {
            label: 'People',
            value: '0',
          },
          {
            label: 'Organizations',
            value: '0',
          },
          {
            label: 'Views',
            value: '0',
          },
        ],
        platformsDistribution: [],
      },
      lineChartData: {
        posts: {
          all: { data_points: postsLineChart.data_points },
          sentiment: postSentimentsLineChart,
          platform: postPlatformsLineChart,
        },
        impressions: {
          all: { data_points: impressionsLineChart.data_points },
          sentiment: impressionSentimentsLineChart,
          platform: impressionPlatformsLineChart,
        },
      },
      heatMapData: {
        data_points: dataHeatMap.data_points,
      },
      beeSwarmNodesData: this.getFormattedBeeSwarmVideoNodes(beeSwarmData),
      peopleData: {
        completeData: peopleData.data_points,
        data: peopleData.total_count > 8 ? peopleData.data_points.slice(0, 8) : peopleData.data_points,
      },
      companyData: {
        completeData: companiesData.data_points,
        data: companiesData.total_count > 8 ? companiesData.data_points.slice(0, 8) : companiesData.data_points,
      },
      countriesMapData: countriesMapData.data_points,
      languagesData: { data: languagesData.data_points },
    })
  }

  fetchNarrativeSnippets = async ({
    categoryChange,
    narrativeNumber,
    category,
  }: {
    category: ExecutiveCategoryNarrativeType
    categoryChange?: boolean
    narrativeNumber: number
  }) => {
    if (
      this.snippetsTotal <= this.executiveLanguageSnippets?.length &&
      !(this.executiveLanguageSnippets.length === 0) &&
      !categoryChange
    )
      return

    if (categoryChange) {
      this.setSnippetsCurrentPage(1)
      this.setExecutiveLanguageSnippets([])
    }

    if (!this.singlePersonData?.associatedNarrativeNumber) return

    const categoryLookup = Object.freeze({
      NarrativeAll: 'a',
      NarrativeMainstream: { communitiesQuerry: 39 },
      NarrativeRisk: 'a',
      NarrativeNegative: 'a',
    })

    let extraFilter: 'a' | { communitiesQuerry: number } | null = categoryLookup[category]
    if (extraFilter === 'a') {
      extraFilter = null
      switch (category) {
        case 'NarrativeAll':
          break
        case 'NarrativeNegative':
          this.setSnippetsFilter({ ...this.snippetsFilter, filter_sentiment: ['Negative'] })
          break
        case 'NarrativeRisk':
          this.setSnippetsFilter({ ...this.snippetsFilter, filter_risk: [50, 100] })
          break
      }
    }

    const payload = this.snippetFilterPayload({ numbers: [narrativeNumber], operation: 'or' })
    payload.page = this.snippetsCurrentPage
    payload.per_page = 50

    const { data } = await monitorAPI.loadFeed(payload)
    if (!data?.items.length) this.setIsFeedEmpty(false)
    this.setSnippetsTotal(data.total_count)
    this.setSnippetsCurrentPage(this.snippetsCurrentPage + 1)

    const { snippets, creators: creatorsList } = UtilService.snippetTranslator({ rawSnippets: data.items })

    if (categoryChange) this.setExecutiveLanguageSnippets(snippets)
    else this.setExecutiveLanguageSnippersPush(snippets)

    let creators: string[] = creatorsList
    creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
    await this.getCreators(creators)
  }

  fetchLanguageSnippets = async ({
    category,
    categoryChange,
  }: {
    categoryChange?: boolean
    category: ExecutiveCategoryType
  }) => {
    if (
      this.snippetsTotal <= this.executiveLanguageSnippets?.length &&
      !(this.executiveLanguageSnippets.length === 0) &&
      !categoryChange
    )
      return

    if (categoryChange) {
      this.setSnippetsCurrentPage(1)
      this.setExecutiveLanguageSnippets([])
    }

    if (!this.singlePersonData?.associatedNarrativeNumber) return

    const payload = this.snippetFilterPayload({ numbers: [], operation: 'or' }, category)
    payload.page = this.snippetsCurrentPage
    payload.per_page = 50

    const { data } = await monitorAPI.loadFeed(payload)
    if (!data?.items.length) this.setIsFeedEmpty(false)

    this.setSnippetsTotal(data.total_count)
    this.setSnippetsCurrentPage(this.snippetsCurrentPage + 1)

    const { snippets, creators: creatorsList } = UtilService.snippetTranslator({ rawSnippets: data.items })

    if (categoryChange) this.setExecutiveLanguageSnippets(snippets)
    else this.setExecutiveLanguageSnippersPush(snippets)

    let creators: string[] = creatorsList
    creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
    await this.getCreators(creators)
  }

  fetchUserBookmarkedSnippets = async () => {
    try {
      const response = (await bookmarksAPI.getUserBookmarkedSnippets()) as string[]
      this.setUserBookmarkedSnippets(response)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchPersons = async () => {
    try {
      const TIME_FORMAT = 'YYYY-MM-DD'
      const today = dayjs().format(TIME_FORMAT)
      const startDate = this.snippetsFilter.days.custom.isApplied
        ? this.snippetsFilter.days.custom.dateRange[0]
        : dayjs(today).subtract(this.snippetsFilter.days.noOfDays, 'days').format(TIME_FORMAT)

      const { data } = await API.get({ route: 'personnel' })

      const executiveNarrativeNumbers: number[] = data.items.map((el: any) => {
        return el.associated_narrative_number
      })

      const personsMetricsPromises: Promise<any>[] = []

      executiveNarrativeNumbers.forEach((narrativeNumber) => {
        const payload = this.snippetFilterPayload({ numbers: [narrativeNumber], operation: 'or' })
        personsMetricsPromises.push(executiveIntelligenceAPI.getExecutiveMetrics(payload))
      })

      const metricsData = await Promise.allSettled(personsMetricsPromises)

      const personsFlagsInsights: Promise<any>[] = []

      executiveNarrativeNumbers.forEach((narrativeNumber) => {
        personsFlagsInsights.push(
          anticipatoryIntelligence.getAttentionFlags({
            startDate,
            endDate: today,
            snippetsFilter: this.snippetsFilter,
            conditions: [{ narratives: [narrativeNumber] }],
          }),
        )
      })

      const flagsData = await Promise.allSettled(personsFlagsInsights)

      const executive: IExecutiveData[] = []

      data.items.forEach((person: IExecutiveData, index: number) => {
        if (flagsData[index].status === 'rejected' || metricsData[index].status === 'rejected') return
        const personFlagsData = flagsData[index] as PromiseFulfilledResult<any>
        const personMetricsData = metricsData[index] as PromiseFulfilledResult<any>

        executive.push({
          id: person.id,
          name: person.name,
          avatar_url: person.avatar_url,
          warnings: personFlagsData.value,
          trendLine: personMetricsData.value.data_points,
          posts: personMetricsData.value.total_value,
          postsMilified: millify(personMetricsData.value.total_value || '0'),
        })
      })

      this.setPersonsTableData(executive)
      this.setFilteredPersonsTableData(executive)
    } catch (e) {}
  }

  loadSinglePerson = async (currentPersonID: string) => {
    try {
      this.setSinglePersonData(null)

      const TIME_FORMAT = 'YYYY-MM-DD'
      const today = dayjs().format(TIME_FORMAT)
      const startDate = this.snippetsFilter.days.custom.isApplied
        ? this.snippetsFilter.days.custom.dateRange[0]
        : dayjs(today).subtract(this.snippetsFilter.days.noOfDays, 'days').format(TIME_FORMAT)

      const { data } = await API.get({
        route: 'personnel',
        id: currentPersonID,
      })

      let restrictedCount = 0
      let ownersIds: string[] = []

      data?.related_narrative.forEach((element: any) => {
        if (!element.has_access) {
          restrictedCount++
          ownersIds.push(element.owner_id)
        }
      })

      const personNarrativeNumber = data.associated_narrative_number
      const payload = this.snippetFilterPayload({ numbers: [personNarrativeNumber], operation: 'or' })

      const associatedNarrativeData = await executiveIntelligenceAPI.getExecutiveMetrics(payload)
      ownersIds = ownersIds.filter((el, index) => !!el && ownersIds.indexOf(el) === index)
      this.setRequestAccessData({ ownersIds, restrictedCount })

      /**
       * This part is responsable with getting the data for the language risk charts
       */

      const languageMetricsPromises: Promise<any>[] = []
      LANGUAGE_METRICS_DIMENSIONS.forEach((dimension) => {
        languageMetricsPromises.push(executiveIntelligenceAPI.getExecutiveMetrics({ ...payload, dimension }))
      })

      const languageMetricsResolved = await Promise.allSettled(languageMetricsPromises)

      const languageMetricsProcessed: ISingleExecutiveData['languageMetrics'] = {}

      languageMetricsResolved.forEach((metric, index) => {
        if (metric.status === 'rejected') return

        const metricData = metric as PromiseFulfilledResult<any>

        let metricName = LANGUAGE_METRICS_DIMENSIONS[index]
        languageMetricsProcessed[metricName] = metricData.value
      })

      /**
       * This part is responsible with getting the metrics and flags data for the single person's narratives
       */

      const personNarrativeNumbers: number[] = []

      data.related_narrative.forEach((el: any) => {
        if (el.has_access) personNarrativeNumbers.push(el.narrative_number)
      })

      const personsMetricsPromises: Promise<any>[] = []

      personNarrativeNumbers.forEach((narrativeNumber: number) => {
        const payloadData = this.snippetFilterPayload({ numbers: [narrativeNumber], operation: 'or' })
        personsMetricsPromises.push(executiveIntelligenceAPI.getExecutiveMetrics(payloadData))
      })

      const metricsData = await Promise.allSettled(personsMetricsPromises)

      const executiveMetricsReferencePromises: Promise<any>[] = []

      personNarrativeNumbers.forEach((narrativeNumber: number) => {
        const payloadData = this.snippetFilterPayload({
          numbers: [data.associated_narrative_number, narrativeNumber],
          operation: 'and',
        })
        executiveMetricsReferencePromises.push(executiveIntelligenceAPI.getExecutiveMetrics(payloadData))
      })

      const metricsReferencesData = await Promise.allSettled(executiveMetricsReferencePromises)

      const personsMetricsFlags: Promise<any>[] = []

      personNarrativeNumbers.forEach((narrativeNumber: number) => {
        personsMetricsFlags.push(
          anticipatoryIntelligence.getAttentionFlags({
            startDate,
            endDate: today,
            snippetsFilter: this.snippetsFilter,
            conditions: [{ narratives: [narrativeNumber] }],
          }),
        )
      })

      const flagsData = await Promise.allSettled(personsMetricsFlags)
      const forecastingData = await defineNarrativeAPI.getGrowth({ narratives: personNarrativeNumbers })

      let dataIndex = 0
      const fullNarrativeData: ISingleExecutiveData['relatedNarratives'] = []
      data.related_narrative.forEach((narrative: any) => {
        if (narrative.has_access === false) return
        if (flagsData[dataIndex].status === 'rejected' || metricsData[dataIndex].status === 'rejected') {
          dataIndex++
          return
        }

        const narrativeForecast = forecastingData?.items.find(
          (item: { forecast_date: string; growth_forecasted: boolean; narrative_number: number }) =>
            item.narrative_number === narrative.narrative_number,
        )
        const narrativeFlagsData = flagsData[dataIndex] as PromiseFulfilledResult<any>
        const narrativeMetricsData = metricsData[dataIndex] as PromiseFulfilledResult<any>
        const narrativeMetricsRefrenceData = metricsReferencesData[dataIndex] as PromiseFulfilledResult<any>

        fullNarrativeData.push({
          ...narrative,
          refrences: narrativeMetricsRefrenceData.value?.total_value,
          posts: narrativeMetricsData.value?.total_value,
          warnings: narrativeFlagsData.value,
          metrics: narrativeMetricsData.value?.data_points,
          growthForecastDetected: narrativeForecast?.growth_forecasted ?? false,
        })
        dataIndex++
      })

      this.setSinglePersonData({
        id: data.id,
        name: data.name,
        avatar: data.avatar_url,
        posts: associatedNarrativeData.total_value,
        postsMilified: millify(associatedNarrativeData.total_value || 0),
        postsGraphData: associatedNarrativeData.data_points,
        relatedNarratives: fullNarrativeData,
        languageMetrics: languageMetricsProcessed,
        associatedNarrativeNumber: data.associated_narrative_number,
      })
    } catch (e) {
      LogService.error({ message: 'Error loading single person', error: e })
    }
  }

  fetchNarrativesList = async ({ pageSize = 20 }) => {
    try {
      let { data } = await API.get({
        getError: true,
        route: 'narrative',
        pageSize: pageSize,
        page: 1,
        sort: 'name:asc',
      })

      const items = data.items.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: dayjs(item.last_update_time).format('MM/DD/YYYY'),
          created: dayjs(item.created).format('MM/DD/YYYY'),
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
        }
      })

      this.setNarrativesListItems({ total_count: data.total_count, items })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchNarrativesListWithParams = async (params: NarrativeParamsType) => {
    try {
      let { data } = await axios({
        method: 'get',
        url: 'narrative',
        params,
      })

      const items = data.items.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: dayjs(item.last_update_time).format('MM/DD/YYYY'),
          created: dayjs(item.created).format('MM/DD/YYYY'),
          description: item.description,
          tags: item.tags,
          alertId: item?.alert_id,
        }
      })
      const newdata = { total_count: data.total_count, items }
      this.setListsWithParams(newdata)
      return newdata
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchPeopleData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.people.getPeopleData(payload)
  }

  fetchCompaniesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.companies.getCompaniesData(payload)
  }

  fetchCountriesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.countries.getCountriesMapData(payload)
  }

  fetchLanguagesData = async (payload: SnippetFilterPayload) => {
    return powerInsightsAPI.languages.getLanguagesData(payload)
  }

  fetchWatchlistData = async () => {
    try {
      const payload = this.snippetFilterPayload({ operation: 'and', numbers: [] }, undefined)
      this.setPowerInsightsData({ ...this.powerInsightsData, watchlistData: { completeData: [], data: [] } })
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })
      const watchlistData: IBarChartWatchlistData[] = []
      await Promise.all(
        response?.data.items?.map(async (watchlist: any) => {
          let channels: string[] = []
          if (watchlist?.channels) {
            watchlist?.channels.forEach((channel: any) =>
              channel?.channel_by_platform.forEach((item: any) => {
                channels.push(item.channel_url)
              }),
            )
          }
          const conditions = []
          conditions.push({
            channel_urls: Array.from(new Set(channels)),
          })

          const { data } = await monitorAPI.loadFeed({
            ...payload,
            conditions: [...(payload.conditions || []), ...conditions],
          })
          watchlistData.push({
            entity_name: watchlist?.name,
            metric_value: data.total_count,
            urls: Array.from(new Set(channels)),
          })
        }),
      )

      const watchlistPostCount = {
        ...this.powerInsightsData,
        watchlistData: {
          completeData: watchlistData,
          data: watchlistData.length > 8 ? watchlistData.slice(0, 8) : watchlistData,
        },
      }

      this.setPowerInsightsData(watchlistPostCount)
    } catch (error) {}
  }

  exportFeed = async ({
    sort,
    sort_mode,
    time,
    total_snippets,
  }: {
    sort: string
    sort_mode: string
    time: string[]
    total_snippets: number
  }) => {
    try {
      if (!this.singlePersonData?.associatedNarrativeNumber) return
      const page = this.snippetsCurrentPage
      const payload = this.snippetFilterPayload({
        numbers: [],
        operation: 'or',
      })
      payload.page = page
      payload.per_page = 50
      payload.sort =
        sort?.length && sort_mode?.length
          ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[sort]}:${MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[sort_mode]}`
          : ''
      const filters: {
        platform: string[]
        sentiment: string[]
        risk_score: string
      } = {
        platform: [],
        sentiment: [],
        risk_score:
          this.snippetsFilter?.filter_risk && this.snippetsFilter?.filter_risk?.length > 1
            ? `${this.snippetsFilter?.filter_risk[0]} to ${this.snippetsFilter?.filter_risk[1]}`
            : '',
      }

      if (Array.isArray(this.snippetsFilter.filter_platform)) {
        filters.platform = this.snippetsFilter.filter_platform
      } else if (this.snippetsFilter?.filter_platform && (this.snippetsFilter?.filter_platform?.length || 0) > 0) {
        filters.platform = this.snippetsFilter.filter_platform?.split(',')
      }

      if (Array.isArray(this.snippetsFilter.filter_sentiment)) {
        filters.sentiment = this.snippetsFilter.filter_sentiment
      } else if (this.snippetsFilter?.filter_sentiment && (this.snippetsFilter.filter_sentiment?.length || 0) > 0) {
        filters.sentiment = this.snippetsFilter?.filter_sentiment?.split(',')
      }

      payload.query.date = `start_date:gte:${time[0]},end_date:lte:${time[1]}`

      const date = { start_date: time[0], end_date: time[1] }

      const info = {
        name: this.singlePersonData?.name || '',
        entity_id: this.singlePersonData?.id || '',
        entity_type: 'narrative' as monitorMode,
        sort_by: sort,
        order_by: sort_mode,
        filters,
        total_snippets,
        ...date,
      }
      await exportAPI.exportFeed({ ...payload, ...info })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  // reset the snippetsFilterInfo and snippetsFilter to their default values
  resetSnippetFilters = () => {
    this.setSnippetsFilterInfo({
      risk: 0,
      sentiment: 0,
      platform: 0,
      sort: '',
      mode: '',
      language: false,
      source: 0,
    })
    if (this.defaultPlatforms.length) {
      this.setSnippetsFilter({
        days: {
          custom: {
            isApplied: false,
            dateRange: [],
          },
          noOfDays: 90,
        },
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
        filter_platform: this.defaultPlatforms,
      })
    } else {
      this.setSnippetsFilter({
        days: {
          custom: {
            isApplied: false,
            dateRange: [],
          },
          noOfDays: 90,
        },
        sort: 'Upload Date',
        sort_mode: 'Descending',
        filter_risk: [0, 100],
      })
    }
  }

  resetStore = () => {
    this.tablePagination = {
      current: 1,
      pageSize: 10,
      total: 0,
      totalMillify: '0',
    }
    this.selectedOption = 'Last 2 years'
    this.personsTableData = []
    this.filteredPersonsTableData = []
    this.isRequestExecutiveModalOpen = false
    this.isPostRequestModalOpen = false
    this.singlePersonData = null
    this.isAddNarrativeModalOpen = false
    this.requestAccessData = null
    this.executiveLanguageSnippets = []
    this.snippetsTotal = 0
    this.snippetsCurrentPage = 1
    this.userBookmarkedSnippets = []
    this.loadedCreatorIds = []
    this.loadedCreators = []
    this.liteCommunities = []
    this.lightTags = []
    this.liteWatchlists = []
    this.currenCategory = 'All'
    this.snippetsFilterInfo = {
      risk: 0,
      sentiment: 0,
      platform: 0,
      source: 0,
      sort: '',
      mode: '',
      language: false,
    }
    this.avableFilters = {}
    this.listFilter = {}
    this.snippetsFilter = {
      days: {
        custom: {
          isApplied: false,
          dateRange: [],
        },
        noOfDays: 90,
      },
      sort: 'Upload Date',
      sort_mode: 'Descending',
      filter_risk: [0, 100],
    }

    this.powerInsightSortFilter = { sort: 'Alphabetically', sort_mode: 'Ascending' }
    this.powerInsightsFilterChips = {
      attentionFlags: {
        label: 'Attention flags',
        value: '',
      },
      watchlist: { label: 'Watchlist', value: '' },
      community: { label: 'Community', value: '' },
      languageRisk: { label: 'Language Risk', value: '' },
      people: { label: 'People', value: '' },
      politician: { label: 'Politician', value: '' },
      country: { label: 'Country', value: '' },
      company: { label: 'Company', value: '' },
      language: { label: 'Language', value: '' },
      assets: { label: 'Asset', value: '' },
      flags: { label: 'Flag', value: '' },
    }
    this.powerInsightsData = {
      summary: {
        executiveSummary: '',
        statistics: [
          {
            label: 'Snippets',
            value: '0',
          },
          {
            label: 'Unique Creators',
            value: '0',
          },
          {
            label: 'Companies',
            value: '0',
          },
          {
            label: 'People',
            value: '0',
          },
          {
            label: 'Organizations',
            value: '0',
          },
          {
            label: 'Views',
            value: '0',
          },
        ],
        platformsDistribution: [],
      },
      lineChartData: {
        posts: {
          all: { data_points: [] },
          sentiment: { data_points: [] },
          platform: { data_points: [] },
        },
        impressions: {
          all: { data_points: [] },
          sentiment: { data_points: [] },
          platform: { data_points: [] },
        },
      },
      heatMapData: { data_points: [] },
      beeSwarmNodesData: [],
      languageRisks: {},
      watchlistData: { completeData: [], data: [] },
    }
    this.narrativesListItems = { total_count: 0, items: [] }
    this.isPowerInsightsOpen = false
    this.isFeedEmpty = true
  }

  deletePersonnelItem = async (personnelId: string) => {
    // google analytics delete narrative

    await executiveIntelligenceAPI.deletePersonnel(personnelId)
  }

  setPersonnelId = (id: string) => {
    this.personnelId = id
  }
}
