import { useState } from 'react'
import CollectionSection from 'version2/components/Vectors/CollectionSection/CollectionSection'
import VectorsListSection from 'version2/components/Vectors/VectorsListSection/VectorsListSection'
import VectorDetailSection from 'version2/components/Vectors/VectorDetailSection/VectorDetailSection'
import { NarrativeListDataType } from 'store/monitorVersion2/types'
import './VectorsLibrary.scss'

function VectorsLibrary() {
  const [selectedObj, setSelectedObj] = useState<NarrativeListDataType | null>(null)
  const [activeCollection, setActiveCollection] = useState<string>('')
  const [openMoveToModal, setOpenMoveToModal] = useState<boolean>(false)
  const [openMoveToModalForDetail, setOpenMoveToModalForDetail] = useState<boolean>(false)
  return (
    <div className='vectors_container'>
      <CollectionSection activeCollection={activeCollection} setActiveCollection={setActiveCollection} />
      <VectorsListSection
        selectedObj={selectedObj}
        setSelectedObj={setSelectedObj}
        activeCollection={activeCollection}
        openMoveToModal={openMoveToModal}
        setOpenMoveToModal={setOpenMoveToModal}
        openMoveToModalForDetail={openMoveToModalForDetail}
        setOpenMoveToModalForDetail={setOpenMoveToModalForDetail}
      />
      <VectorDetailSection selectedObj={selectedObj} setOpenMoveToModal={setOpenMoveToModalForDetail} />
    </div>
  )
}

export default VectorsLibrary
