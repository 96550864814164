import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button } from 'antd'
import classNames from 'classnames'
import millify from 'millify'
import dayjs from 'dayjs'
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, YAxis, Legend, ResponsiveContainer } from 'recharts'

import { ReactComponent as PostsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/posts.svg'
import { ReactComponent as ImpressionsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/impressions.svg'
import { ReactComponent as AllIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/all.svg'
import { ReactComponent as SentimentIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/sentiment.svg'
import { ReactComponent as PlatformIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/platform.svg'
import { ReactComponent as BellIcon } from 'assets/images/icons/bell.svg'
import { ReactComponent as PostsLegendIcon } from 'assets/images/icons/power-insights/trends-timeline/legends/posts.svg'
import { ReactComponent as AverageLegendIcon } from 'assets/images/icons/power-insights/trends-timeline/legends/average.svg'

import { store } from 'store'
import { monitorMode, monitorSnippetPage, sourcePlatform } from 'models/models'
import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { UtilService } from 'services/Util/Util'

import styles from './MonitorGraph.module.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const MonitorGraph = observer(({ mode, page }: Props) => {
  const { narrativeNumber: narrativeNumberParam } = useParams()

  let currentStore, monitorStore
  if (page === 'define' && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  }

  const { powerInsightsData, snippetsFilter, setSnippetsFilter, fetchPowerInsights, setSnippetsTotal } = currentStore
  const { activeItem, setSnippets } = monitorStore
  const { fetchPowerInsights: executivePowerInsights } = store.executiveIntelligence

  const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions'>('posts')
  const [activePivot, setActivePivot] = useState<'all' | 'sentiment' | 'platform'>('all')
  const [xAxisTicks, setXAxisTicks] = useState<string[]>([])
  const [disabledChartLines, setDisabledChartLines] = useState<
    Array<Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative'>
  >([])
  const [hoveredValued, setHoveredValue] = useState<string>('')

  const postsChartPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date'>() // Using a Set to ensure uniqueness

    powerInsightsData.lineChartData.posts.platform.data_points.forEach((dataPoint) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    // remove the "date" key from the list of platforms and then return the platforms as an array
    uniquePlatforms.delete('date')
    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [powerInsightsData.lineChartData])

  const impressionsChartPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date'>() // Using a Set to ensure uniqueness

    powerInsightsData.lineChartData.impressions.platform.data_points.forEach((dataPoint) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    // remove the "date" key from the list of platforms and then return the platforms as an array
    uniquePlatforms.delete('date')
    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [powerInsightsData.lineChartData])

  const platformsToRender = activeMetric === 'posts' ? postsChartPlatforms : impressionsChartPlatforms

  useEffect(() => {
    // clear the disabled lines when the active metric or pivot changes
    setDisabledChartLines([])
  }, [activeMetric, activePivot])

  useEffect(() => {
    const ticks: string[] = []

    powerInsightsData.lineChartData[activeMetric][activePivot].data_points.forEach((point) => {
      const date = dayjs(point.date)
      const day = date.get('date')

      if (day === 1) ticks.push(point.date)
    })

    if (
      ticks.length === 0 &&
      powerInsightsData.lineChartData[activeMetric][activePivot].data_points.length !== 0 &&
      powerInsightsData.lineChartData[activeMetric][activePivot].data_points[0]?.date
    ) {
      ticks.push(powerInsightsData.lineChartData[activeMetric][activePivot].data_points[0]?.date)
    }

    // set the date axis ticks
    setXAxisTicks(ticks)
  }, [powerInsightsData.lineChartData, activeMetric, activePivot])

  const CustomTooltip = ({ active, payload, label }: { active: boolean; payload: any; label: any }) => {
    if (!active || !payload || !payload.length) return null

    const data = payload.filter((item: any) => item.dataKey === hoveredValued)
    if (!data?.length) return null

    return (
      <div className={styles.tooltipLabelContainer}>
        <div className={styles.labelText}>{` ${millify(data[0]?.value)} | ${dayjs(label).format('MMM DD, YYYY')}`}</div>
      </div>
    )
  }

  const handleClick = (_event: any, payload: any) => {
    if (payload.index >= 0) {
      const index = payload.index
      const singleDate = powerInsightsData.lineChartData[activeMetric][activePivot].data_points[index]?.date
      const modFilter = { ...snippetsFilter }
      modFilter['days'] = {
        custom: {
          isApplied: true,
          dateRange: [singleDate || '', singleDate || ''] || [],
        },
        noOfDays: 0,
      }
      setSnippetsFilter(modFilter)
      if (page === 'executive-intelligence') executivePowerInsights(narrativeNumberParam || '')
      else {
        setSnippets([])
        setSnippetsTotal(0)
        fetchPowerInsights(activeItem?.id ?? '', '')
      }
    }
  }

  const CustomLegend = ({ disabledChartLines, onLegendClick, payload }: any) => {
    return (
      <ul className={styles.chartLegends}>
        {payload.map((entry: any, index: number) => {
          if (activePivot === 'all') {
            return (
              <li key={entry.dataKey} className={styles.legend}>
                {entry.dataKey === 'metric_value' ? (
                  <>
                    <PostsLegendIcon /> <span className={styles.legendLabel}>Posts</span>
                  </>
                ) : (
                  <>
                    <AverageLegendIcon /> <span className={styles.legendLabel}>Average</span>
                  </>
                )}
              </li>
            )
          } else if (activePivot === 'sentiment' || activePivot === 'platform') {
            let legendLabel = entry.dataKey
            if (activePivot === 'platform') {
              legendLabel = UtilService.getPlatformBrandName(entry.dataKey)
            }

            return (
              <li
                key={`item-${index}`}
                className={classNames(styles.legend, styles.clickable)}
                onClick={() => onLegendClick(entry.dataKey)}>
                <span className={styles.legendIcon} style={{ backgroundColor: entry.color }} />{' '}
                <span
                  className={classNames(styles.legendLabel, {
                    [styles.strikeThroughText]: disabledChartLines.includes(entry.dataKey),
                  })}>
                  {legendLabel}
                </span>
                {disabledChartLines.includes(entry.dataKey) && <span className={styles.strikeThroughLine}></span>}
              </li>
            )
          }

          return null
        })}
      </ul>
    )
  }

  const handleLegendClick = (dataKey: Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative') => {
    if (disabledChartLines.includes(dataKey)) {
      setDisabledChartLines(disabledChartLines.filter((key) => key !== dataKey))
    } else {
      setDisabledChartLines([...disabledChartLines, dataKey])
    }
  }
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.chartFilters}>
          <div className={styles.metric}>
            <span>Metric</span>
            <Button.Group>
              <Button
                onClick={() => setActiveMetric('posts')}
                className={classNames(styles.btnFilter, { [styles.active]: activeMetric === 'posts' })}>
                <PostsIcon /> Posts
              </Button>

              <Button
                onClick={() => setActiveMetric('impressions')}
                className={classNames(styles.btnFilter, { [styles.active]: activeMetric === 'impressions' })}>
                <ImpressionsIcon /> Impressions
              </Button>
            </Button.Group>
          </div>

          <div className={styles.pivot}>
            <span>Pivot</span>
            <Button.Group>
              <Button
                onClick={() => setActivePivot('all')}
                className={classNames(styles.btnFilter, { [styles.active]: activePivot === 'all' })}>
                <AllIcon /> All
              </Button>

              <Button
                onClick={() => setActivePivot('sentiment')}
                className={classNames(styles.btnFilter, { [styles.active]: activePivot === 'sentiment' })}>
                <SentimentIcon /> Sentiment
              </Button>

              <Button
                onClick={() => setActivePivot('platform')}
                className={classNames(styles.btnFilter, { [styles.active]: activePivot === 'platform' })}>
                <PlatformIcon /> Platform
              </Button>
            </Button.Group>
          </div>
        </div>

        <BellIcon className={styles.bellIcon} />
      </div>

      <ResponsiveContainer>
        <LineChart data={powerInsightsData.lineChartData[activeMetric][activePivot].data_points}>
          <CartesianGrid stroke='#E4E3E1' vertical={false} />

          <XAxis
            axisLine={{ stroke: '#E4E3E1', strokeWidth: 1 }}
            dataKey='date'
            ticks={xAxisTicks}
            tickFormatter={(value: any) => dayjs(value).format('MMM YYYY')}
            interval='preserveStartEnd'
            tick={{ fontSize: 10 }}
            tickLine={false}
          />

          <YAxis
            type='number'
            axisLine={{ stroke: '#E4E3E1', strokeWidth: 1 }}
            interval='preserveStartEnd'
            tickFormatter={(value) => {
              if (Number.isFinite(value)) return millify(value)
              else return '0'
            }}
            tick={{ fontSize: 10 }}
            tickLine={false}
            tickCount={11}
          />

          {/*@ts-ignore  the required values are passed internally by the lib*/}
          <Tooltip content={<CustomTooltip />} />

          <Legend
            iconSize={10}
            align='left'
            content={<CustomLegend disabledChartLines={disabledChartLines} onLegendClick={handleLegendClick} />}
          />

          {activePivot === 'all' && (
            <>
              <Line
                type='monotone'
                dataKey='metric_value'
                stroke='#CCA55A'
                dot={false}
                activeDot={{
                  stroke: 'transparent',
                  strokeWidth: 20,
                  r: 6,
                  cursor: 'pointer',
                  onMouseOver: () => {
                    setHoveredValue('metric_value')
                  },
                  onMouseOut: () => {
                    setHoveredValue('')
                  },
                  onClick: handleClick,
                }}
                strokeWidth={1.5}
                connectNulls
              />

              <Line
                strokeDasharray='8 8'
                dataKey='metric_running_average'
                stroke='#8598AD'
                strokeWidth={1.5}
                dot={false}
                activeDot={false}
                connectNulls
              />
            </>
          )}

          {activePivot === 'sentiment' && (
            <>
              <Line
                type='monotone'
                dataKey='Positive'
                stroke='#18B368'
                strokeWidth={1.5}
                dot={false}
                activeDot={{
                  strokeWidth: 0,
                  r: 6,
                  cursor: 'pointer',
                  onMouseOver: () => {
                    setHoveredValue('Positive')
                  },
                  onMouseOut: () => {
                    setHoveredValue('')
                  },
                }}
                hide={disabledChartLines.includes('Positive')}
                connectNulls
              />

              <Line
                type='monotone'
                dataKey='Neutral'
                stroke='#18A0FB'
                strokeWidth={1.5}
                dot={false}
                activeDot={{
                  strokeWidth: 0,
                  r: 6,
                  cursor: 'pointer',
                  onMouseOver: () => {
                    setHoveredValue('Neutral')
                  },
                  onMouseOut: () => {
                    setHoveredValue('')
                  },
                }}
                hide={disabledChartLines.includes('Neutral')}
                connectNulls
              />

              <Line
                type='monotone'
                dataKey='Negative'
                stroke='#DE4E4E'
                dot={false}
                activeDot={{
                  strokeWidth: 0,
                  r: 6,
                  cursor: 'pointer',
                  onMouseOver: () => {
                    setHoveredValue('Negative')
                  },
                  onMouseOut: () => {
                    setHoveredValue('')
                  },
                }}
                strokeWidth={1.5}
                hide={disabledChartLines.includes('Negative')}
                connectNulls
              />
            </>
          )}

          {activePivot === 'platform' &&
            platformsToRender.map((platform, index) => (
              <Line
                key={platform}
                type='monotone'
                dataKey={platform}
                stroke={UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform))}
                strokeWidth={1.5}
                dot={false}
                activeDot={{
                  strokeWidth: 0,
                  r: 6,
                  cursor: 'pointer',
                  onMouseOver: () => {
                    setHoveredValue(platform)
                  },
                  onMouseOut: () => {
                    setHoveredValue('')
                  },
                }}
                hide={disabledChartLines.includes(platform)}
                connectNulls
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
})
