import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { monitorMode } from 'models/models'

export const getMonitorSearchResults = async (querry: string, mode: monitorMode) => {
  let url =
    process.env.REACT_APP_API +
    '/' +
    ROUTES[mode].replace('/', '') +
    `?page=1&per_page=5&sort=${mode !== 'tag' ? 'name' : 'label'}&q=${mode !== 'tag' ? 'name' : 'label'}:like:` +
    querry

  const { data } = await axios.get(url)

  return data
}
