import { observer } from 'mobx-react-lite'

import { LanguageRiskItem } from './LanguageRiskItem/LanguageRiskItem'
import { ExecutiveCategoryType, monitorMode, monitorSnippetPage } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { store } from 'store'

import styles from './ExecutiveLanguageRisks.module.scss'

const RISK_NAME_LOOKUP: { [key: string]: string } = {
  toxic_language: 'Toxic',
  obscene_language: 'Obscene',
  hate_language: 'Identity hate',
  negative_sentiment: 'Negative',
  threat_language: 'Threat',
  risk_score: 'Source',
}

interface ExecutiveLanguageRisksProps {
  isPowerInsightsMode?: boolean
  mode?: monitorMode
  page?: monitorSnippetPage
}

export const ExecutiveLanguageRisks = observer(function ExecutiveLanguageRisks({
  mode,
  page,
  isPowerInsightsMode,
}: ExecutiveLanguageRisksProps) {
  const { singlePersonData } = store.executiveIntelligence
  let languageRisks = singlePersonData?.languageMetrics

  if (mode && page !== 'executive-intelligence') {
    const { powerInsightsData } =
      page === 'define' ? store['defineNarrativeStoreV1'] : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    languageRisks = powerInsightsData?.languageRisks
  }

  if (!languageRisks) {
    return null
  }

  return (
    <div className={styles.languageRisksSection}>
      {isPowerInsightsMode ? (
        <div className={styles.description}>Select any risk language to apply it as filter.</div>
      ) : (
        <h3>Language Risk - Click to Interact</h3>
      )}

      <div className={styles.languageRisksWrapper}>
        {Object.entries(languageRisks).map(([risk, data]) => {
          return (
            <LanguageRiskItem
              key={risk}
              name={RISK_NAME_LOOKUP[risk] as ExecutiveCategoryType}
              data={data}
              isPowerInsightsMode={isPowerInsightsMode}
              mode={mode}
              page={page}
            />
          )
        })}
      </div>
    </div>
  )
})
