import { useCallback, useEffect, useState } from 'react'
import { Tag } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { store } from 'store'

import { IFilterChips, monitorMode, monitorSnippetPage } from 'models/models'
import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY, SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { FeedActions } from '../../../Monitor/common/Feed/FeedActions/FeedActions'
import { SnippetFilter } from '../../Filters/Snippet/SnippetFilters'

import { ReactComponent as PowerInsightIcon } from 'assets/images/power-insight-icon.svg'
import { ReactComponent as StarIcon } from 'assets/images/star-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'

import styles from './snippetListHeader.module.scss'
import TagManager from 'react-gtm-module'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
  defineSearchTitle: string | null
}

export const SnippetListHeader = observer(({ mode, page, defineSearchTitle }: Props) => {
  const { narrativeNumber: narrativeNumberParam } = useParams()
  const pageCheck = page !== 'executive-intelligence' && page !== 'define' && page !== 'edit'
  let currentStore, monitorStore
  if (page === 'define' && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  }

  const {
    snippetsFilter,
    powerInsightsFilterChips,
    fetchPowerInsights,
    setIsPowerInsightsOpen,
    setPowerInsightsFilterChips,
    setSnippetsFilter,
    setSetCategory,
  } = currentStore
  const { fetchPowerInsights: executiveFetchPowerInsights } = store.executiveIntelligence

  const { userId, userName, tenantId, roleId } = store.userStore

  const { activeItem, setSelectedCommunityInfo, fetchSnippets } = monitorStore

  const { search } = useLocation()
  let listName = new URLSearchParams(search).get('list') || ''
  listName = listName && listName?.length > 0 ? listName[0].toUpperCase() + listName.slice(1) : 'User'

  const [filterCount, setFilterCount] = useState(0)

  const openPowerInsightsHandler = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'open_power_insights',
        entityType: mode,
        entityName: activeItem?.name,
        user_id: userId,
        user_name: userName,
        tenantId: tenantId,
        roleId: roleId,
      },
    })
    if (pageCheck && !activeItem?.id?.length) return
    if (page === 'executive-intelligence') executiveFetchPowerInsights(narrativeNumberParam || '')
    else fetchPowerInsights(activeItem?.id || '', listName)
    setIsPowerInsightsOpen(true)
  }, [activeItem, listName, narrativeNumberParam])

  useEffect(() => {
    setFilterCount((prev) => {
      let count = 0
      count = snippetsFilter.community && snippetsFilter.community > 0 ? 1 : 0
      count = snippetsFilter.days.custom.isApplied ? count + 1 : count
      count = count + (Object.keys(snippetsFilter?.attentionFlags || {}).length || 0)
      return count
    })
  }, [snippetsFilter])

  const onCloseFilter = (name: string) => {
    /* eslint-disable */
    let obj
    switch (name) {
      case 'attentionFlags':
        {
          obj = {
            attentionFlags: {
              ...powerInsightsFilterChips.attentionFlags,
              value: '',
            },
          }
          setSnippetsFilter({ ...snippetsFilter, attentionFlags: {} })
        }
        break
      case 'community':
        {
          obj = {
            community: {
              ...powerInsightsFilterChips.community,
              value: '',
            },
          }
          let { community, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
          setSelectedCommunityInfo(undefined)
        }
        break
      case 'languageRisk':
        {
          obj = {
            languageRisk: {
              ...powerInsightsFilterChips.languageRisk,
              value: '',
            },
          }
          setSetCategory('All')
        }
        break
      case 'people':
        {
          obj = {
            people: {
              ...powerInsightsFilterChips.people,
              value: '',
            },
          }
          let { people, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
      case 'country':
        {
          obj = {
            country: {
              ...powerInsightsFilterChips.country,
              value: '',
            },
          }
          let { country, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
      case 'company':
        {
          obj = {
            company: {
              ...powerInsightsFilterChips.company,
              value: '',
            },
          }
          let { company, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break

      case 'language':
        {
          obj = {
            language: {
              ...powerInsightsFilterChips.language,
              value: '',
            },
          }
          let { filter_language, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break

      case 'watchlist':
        {
          obj = {
            watchlist: {
              ...powerInsightsFilterChips.watchlist,
              value: '',
            },
          }
          let { watchlist, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
    }
    if (obj) {
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        ...obj,
      })
      fetchSnippets()
    }
    /* eslint-enable */
  }

  return (
    <div>
      {(page === 'edit' || page === 'flags') && (
        <div className={styles.interactText}>
          <StarIcon />
          <p>Make changes in the keyword search to visualize content changes</p>
        </div>
      )}
      {!['anticipatory-intelligence', 'flags'].includes(page) && (
        <div className={styles.titleSection}>
          <h3 className={styles.title}>{defineSearchTitle}</h3>
          <button
            className={classNames(styles.powerInsightsBtn, {
              [styles.disabled]: page !== 'define' && page !== 'executive-intelligence' && !activeItem?.id?.length,
            })}
            onClick={openPowerInsightsHandler}
            disabled={page !== 'define' && page !== 'executive-intelligence' && !activeItem?.id?.length}>
            <PowerInsightIcon className={styles.icon} />
            <span>Power Insights</span>
            {filterCount > 0 && <span className={styles.filtersCount}>{filterCount}</span>}
          </button>
        </div>
      )}
      {Object.values(powerInsightsFilterChips).filter((item) => item.value.length > 0)?.length > 0 && (
        <div className={styles.filtersDiv}>
          <div className={styles.filtersContainer}>
            {Object.keys(powerInsightsFilterChips).map((filter: any) => {
              return powerInsightsFilterChips[filter as keyof IFilterChips].value?.length > 0 ? (
                <div className={styles.appliedFilters}>
                  <span className={styles.filterLabel}>
                    {`${powerInsightsFilterChips[filter as keyof IFilterChips].label}:`}
                  </span>
                  <Tag closable closeIcon={<CloseIcon />} onClose={() => onCloseFilter(filter)} className={styles.tag}>
                    {filter !== 'language'
                      ? powerInsightsFilterChips[filter as keyof IFilterChips].value
                      : SUPPORTED_LANGUAGES_CODE[powerInsightsFilterChips[filter as keyof IFilterChips].value]}
                  </Tag>
                </div>
              ) : (
                <></>
              )
            })}
          </div>
        </div>
      )}

      {!['anticipatory-intelligence', 'flags', 'executive-intelligence'].includes(page) && (
        <div className={styles.feedActions}>
          <FeedActions mode={mode} page={page} />
        </div>
      )}

      {<SnippetFilter page={page} mode={mode} />}
    </div>
  )
})
