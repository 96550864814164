import { UtilService } from 'services/Util/Util'
import { Unknown } from './commonTypes'

export const displayApiError = (e: any) => {
  const response = e.response
  UtilService.openError({
    requestId: response?.data?.request_id || '',
    statusCode: response?.status || 400,
    message: response?.data?.err_msg || 'An error occurred',
  })
}

export const skipRoles = (skip: string[]) => {
  const roles = [
    'ADMIN',
    'PENDULUM_ADMIN',
    'USER',
    'VIEWER',
    'SCORECARD_USER',
    'DEPLOYMENT_STRATEGIST',
    'BUILDER',
    'ANALYST',
  ]

  return roles?.filter((role) => !skip?.includes(role))
}

export const convertMobXProxiesToJSObject = (proxies: Record<string, Unknown>): Record<string, Unknown> => {
  if (proxies) return JSON.parse(JSON.stringify(proxies))
  return {}
}

export const containsHTML = (text: string): boolean => {
  const htmlPattern = /<[^>]+>/
  return htmlPattern.test(text)
}

export const extractBodyContent = (htmlString: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const bodyContent = doc.body.innerHTML
  return bodyContent
}
