import { List } from 'antd'
import { useEffectOnce } from 'react-use'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import VirtualList from 'rc-virtual-list'

import { MonitorSnippetItem } from '../MonitorSnippetItem/SnippetItem'
import { ExecutiveCategoryType, ExecutiveCategoryNarrativeType, monitorMode, monitorSnippetPage } from 'models/models'
import { EXECUTIVE_CATEGORIES, EXECUTIVE_NARRATIVE_CATEGORIES } from 'settings/settings'
import { store } from 'store'

import './SnippetPage.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const ExecutiveDetailSnippets: React.FC<Props> = observer(({ mode, page }) => {
  const { id: currentPersonID, category, narrativeNumber: narrativeNumberParam } = useParams()
  const narrativeNumber = parseInt(narrativeNumberParam || '0')

  const { executiveIntelligence } = store
  const {
    liteCommunities,
    liteWatchlists,
    fetchLiteWatchlists,
    executiveLanguageSnippets,
    fetchLanguageSnippets,
    fetchNarrativeSnippets,
  } = executiveIntelligence

  const isNarrativeMode = !!narrativeNumber || narrativeNumberParam === '0' ? 'isNarrativeMode' : 'isNormalMode'
  const ContainerHeight = 670
  const itemHeight = 146

  useEffectOnce(() => {
    fetchLiteWatchlists()
  })

  const fetchSnippets = () => {
    switch (isNarrativeMode) {
      case 'isNarrativeMode':
        if (!narrativeNumber || !category) {
          return
        }

        if (!EXECUTIVE_NARRATIVE_CATEGORIES.includes(category)) {
          return
        }

        const narrativeCategoryFiltered = category as ExecutiveCategoryNarrativeType
        fetchNarrativeSnippets({ category: narrativeCategoryFiltered, narrativeNumber })
        break

      case 'isNormalMode':
        if (!currentPersonID || !category) {
          return
        }

        if (!EXECUTIVE_CATEGORIES.includes(category)) {
          return
        }

        const categoryFiltered = category as ExecutiveCategoryType
        fetchLanguageSnippets({ category: categoryFiltered })
        break
    }
  }

  const checkFeed = () => {
    if (!executiveLanguageSnippets.length) {
      return null
    }

    return (
      <VirtualList
        className='c-monitor-communities__items'
        data={executiveLanguageSnippets}
        height={ContainerHeight}
        itemHeight={itemHeight}
        itemKey='id'
        onScroll={(e) => {
          if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
            fetchSnippets()
          }
        }}>
        {(snippet) => {
          return (
            <MonitorSnippetItem
              mode={mode}
              page={page}
              snippet={snippet}
              allWatchlist={liteWatchlists}
              allCommunities={liteCommunities}
              showSurprise={() => {}}
            />
          )
        }}
      </VirtualList>
    )
  }

  return (
    <div className='snippet-list__wrapper'>
      <List>{checkFeed()}</List>
    </div>
  )
})
