import dayjs from 'dayjs'
import { Spin } from 'antd'
import { uniqBy } from 'remeda'
import { useMeasure } from 'react-use'
import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { BeeChart, ComponentGlobalStyle, Tip, themes, useTip } from '@pendulumfn/pendulum-viz'

import { BeehiveChartXAxis } from './BeehiveChartXAxis/BeehiveChartXAxis'
import { BeehiveChartPopup } from './BeehiveChartPopup/BeehiveChartPopup'
import { BeehivePlatformLegends } from './BeehivePlatformLegends/BeehivePlatformLegends'

import { IVideoBubble } from 'models/models'
import { SubStore } from 'types/types'

import { Unknown } from 'version2/utils/commonTypes'
import { convertMobXProxiesToJSObject } from 'version2/utils/helper'

import { store } from 'store'

import './MonitorBeehiveChart.scss'
interface beeSwarmProps {
  subStore: SubStore
  dataLoading: boolean
}

export const MonitorBeehiveChart: React.FC<beeSwarmProps> = observer(function MonitorBeehiveChart({
  subStore,
  dataLoading = false,
}) {
  const tip = useTip<IVideoBubble>()
  const [measureRef, { width: beeChartWidth }] = useMeasure<HTMLDivElement>()
  const { powerInsightsData, fetchPowerInsightsBeeSwarmData } = store[`${subStore}Store`]

  const xAxisData = useMemo(() => {
    return powerInsightsData.beeSwarmNodesData.map((node) => ({ date: dayjs(node.date).format('MMM DD, YYYY') }))
  }, [powerInsightsData.beeSwarmNodesData, dataLoading])

  const platforms = useMemo(() => {
    return uniqBy(powerInsightsData.beeSwarmNodesData, (node) => node.data.platform).map((node) => node.data.platform)
  }, [powerInsightsData.beeSwarmNodesData, dataLoading])

  const handleSelectAndOpen = (data: Record<string, Unknown>) => {
    const convertedData = convertMobXProxiesToJSObject(data)?.data
    const postUrl = convertedData?.post_url
    if (postUrl) {
      window.open(postUrl, '_blank')
    }
  }

  useEffect(() => {
    fetchPowerInsightsBeeSwarmData()
  }, [])

  return (
    <div ref={measureRef} className='c-bubble-chart'>
      <Spin spinning={dataLoading}>
        {/* Provides css variables that are used by all the components. Uses css variables that can switch light/dark according to browser settings */}
        <ComponentGlobalStyle theme={themes.pendulum} noDark />

        <div>
          <div className='c-bubble-chart__heading'>
            <span className='c-bubble-chart__heading-subtitle'>
              Visualize individual content impact to the overall trend
            </span>
          </div>
        </div>

        <div className='c-bubble-chart__platforms'>
          <BeehivePlatformLegends subStore={subStore} platforms={platforms} />
        </div>

        <div className='c-bubble-chart__bubbles'>
          <BeeChart
            className='c-bubble-chart__bee'
            nodes={powerInsightsData.beeSwarmNodesData}
            width={beeChartWidth}
            tip={tip}
            onSelect={handleSelectAndOpen}
            maxBubbles={1000}
            widthFactor={0.5}
            bubbleSize={0.5}
            showBar={false}
          />

          <BeehiveChartXAxis data={xAxisData} maxWidth={beeChartWidth} />
        </div>

        <Tip {...tip.tipProps}>{tip.data && <BeehiveChartPopup data={tip.data} />}</Tip>
      </Spin>
    </div>
  )
})
