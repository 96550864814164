import { useEffect, useMemo, useState } from 'react'
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts'
import { observer } from 'mobx-react-lite'
import { useMeasure } from 'react-use'
import { maxBy } from 'remeda'
import millify from 'millify'

import { store } from 'store'
import { monitorMode, sourcePlatform, IPowerInsightsSummary, monitorSnippetPage } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { UtilService } from 'services/Util/Util'

import styles from './platformDistribution.module.scss'

interface PlatformDistributionProps {
  mode: monitorMode
  page: monitorSnippetPage
}

export const PlatformDistribution: React.FC<PlatformDistributionProps> = observer(({ mode, page }) => {
  const [measureRef, { width: platformDistributionChartWidth }] = useMeasure<HTMLDivElement>()

  const { powerInsightsData } =
    page === 'define' ? store['defineNarrativeStoreV1'] : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { summary: summaryData } = powerInsightsData

  const maxPlatformValue = useMemo(() => {
    return maxBy(summaryData.platformsDistribution, (item) => Number(item.metric_value))?.metric_value || 0
  }, [summaryData.platformsDistribution])

  const [platformsGraphData, setPlatformsGraphData] = useState<IPowerInsightsSummary['platformsDistribution']>([])
  const [leftPlatformsGraphData, setLeftPlatformsGraphData] = useState<IPowerInsightsSummary['platformsDistribution']>(
    [],
  )
  const [rightPlatformsGraphData, setRightPlatformsGraphData] = useState<
    IPowerInsightsSummary['platformsDistribution']
  >([])

  useEffect(() => {
    setPlatformsGraphData(summaryData.platformsDistribution)

    setLeftPlatformsGraphData(
      summaryData.platformsDistribution.slice(0, Math.ceil(summaryData.platformsDistribution.length / 2)),
    )

    setRightPlatformsGraphData(
      summaryData.platformsDistribution.slice(Math.ceil(summaryData.platformsDistribution.length / 2)),
    )
  }, [summaryData.platformsDistribution])

  const CustomizedYAxisTick = (props: { x: number; y: number; index: number; payload: { value: string } }) => {
    const data = platformsGraphData.find((item) => item.platform_name === props.payload.value) || {
      metric_value: 0,
      platform_name: '',
    }

    return (
      <g>
        <text
          transform={`translate(${props.x - 112},${props.y + 5})`}
          fontSize='14px'
          fontWeight='400'
          textAnchor='start'
          fill='#242930'>
          {UtilService.getPlatformBrandName(data.platform_name.toLowerCase() as Lowercase<sourcePlatform>)}
        </text>

        <text
          transform={`translate(${props.x},${props.y + 5})`}
          fontSize='12px'
          fontWeight='400'
          textAnchor='end'
          fill='#242930'>
          {millify(Number(data.metric_value || '0'))}
        </text>
      </g>
    )
  }

  return (
    <div className={styles.platformDistribution}>
      <div className={styles.heading}>
        <h4>Platform Distribution</h4>
      </div>

      <div className={styles.platformsList}>
        <div className={styles.listColumn} ref={measureRef}>
          <BarChart
            width={platformDistributionChartWidth}
            height={31 * (leftPlatformsGraphData.length || 0)}
            data={leftPlatformsGraphData}
            layout={'vertical'}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <XAxis type='number' dataKey='metric_value' domain={['auto', maxPlatformValue]} hide />
            <YAxis
              type='category'
              dataKey='platform_name'
              axisLine={false}
              width={120}
              tickLine={false}
              tick={CustomizedYAxisTick}
            />

            <Bar dataKey='metric_value' barSize={13}>
              {leftPlatformsGraphData.map((entry, index) => {
                return <Cell cursor='pointer' fill='#c1d1f1' key={`cell-${index}`} />
              })}
            </Bar>
          </BarChart>
        </div>

        <div className={styles.listColumn}>
          <BarChart
            width={platformDistributionChartWidth}
            height={31 * (rightPlatformsGraphData.length || 0)}
            data={rightPlatformsGraphData}
            layout={'vertical'}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <XAxis type='number' dataKey='metric_value' domain={['auto', maxPlatformValue]} hide />
            <YAxis
              type='category'
              dataKey='platform_name'
              axisLine={false}
              width={120}
              tickLine={false}
              tick={CustomizedYAxisTick}
            />

            <Bar dataKey='metric_value' barSize={13}>
              {rightPlatformsGraphData.map((entry, index) => {
                return <Cell cursor='pointer' fill='#c1d1f1' key={`cell-${index}`} />
              })}
            </Bar>
          </BarChart>
        </div>
      </div>
    </div>
  )
})
