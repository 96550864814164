import axios from 'axios'
import { ROUTES } from 'settings/settings'

export const getLandscape = async () => {
  try {
    let url = process.env.REACT_APP_API + ROUTES.landscapeData
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    return error
  }
}

export const searchData = async (payload: any, startDate: any, endDate: any) => {
  try {
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsSummary
    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      is_landscape: 1,
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Executive Summary:
export const getExecutiveSummary = async (payload: any, startDate: any, endDate: any) => {
  try {
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.summarize
    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//TRENDS

//Posts
export const getTrendsPosts = async (payload: any, startDate: any, endDate: any) => {
  try {
    //stats/linechart?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19,risk_score:gte:0,risk_score:lte:100
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsLineChart
    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Impressions
export const getTrendsImpression = async (payload: any, startDate: any, endDate: any) => {
  try {
    //search.api.dev.pendulumintel.com/stats/linechart?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19&metric=impression
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsLineChart
    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      metric: 'impression',
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}
// ---------

//Post - Sentiment
export const getPostSentiment = async (payload: any, startDate: any, endDate: any) => {
  try {
    //search.api.dev.pendulumintel.com/stats/linechart?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19&metric=impression
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsLineChart
    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      dimension: 'sentiment',
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Impressions - Sentiment:
export const getImpressionsSentiment = async (payload: any, startDate: any, endDate: any) => {
  try {
    //search.api.dev.pendulumintel.com/stats/linechart?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19&metric=impression
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsLineChart
    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      metric: 'impression',
      dimension: 'sentiment',
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Post - Platforms:
export const getPostPlatforms = async (payload: any, startDate: any, endDate: any) => {
  try {
    //search.api.dev.pendulumintel.com/stats/linechart?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19&metric=impression
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsLineChart

    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      dimension: 'platform',
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Impressions - Platforms:
export const getImpressionsPlatforms = async (payload: any, startDate: any, endDate: any) => {
  try {
    //search.api.dev.pendulumintel.com/stats/linechart?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19&metric=impression
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.powerInsightsLineChart

    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      metric: 'impression',
      dimension: 'platform',
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Platform Distribution:
export const getPlatformDistribution = async (payload: any, startDate: any, endDate: any) => {
  try {
    //search.api.dev.pendulumintel.com/stats/platform?q=start_date:gte:2023-11-21,end_date:lte:2024-02-19,risk_score:gte:0,risk_score:lte:100&sort=alphabetically:asc
    let url = process.env.REACT_APP_SEARCH_API + ROUTES.platformChartData

    const queryParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      sort: 'alphabetically:asc',
    }
    const { data } = await axios.post(url, payload, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//get All Maps
export const getAllMaps = async () => {
  try {
    // https://api.dev.pendulumintel.com/scorecards/landscape/maps
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}`
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    return error
  }
}

export const getCreateAllMaps = async (payload: any, startDate: any, endDate: any) => {
  try {
    // https://api.dev.pendulumintel.com/scorecards/landscape/maps
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}`

    const { data } = await axios.post(url, payload)

    return data
  } catch (error) {
    return error
  }
}

//Narrative CRUD:
export const getNarrativeCrud = async (pageNumber: any) => {
  try {
    //https://api.dev.pendulumintel.com/narrative?page=1&per_page=1000&sort=name:asc&in_library=false
    let url = `${process.env.REACT_APP_API}/${ROUTES.narrative}`

    const queryParams = {
      page: pageNumber,
      per_page: 10,
      sort: 'name:asc',
      in_library: false,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Narrative Search CRUD:
export const getNarrativeSearchCrud = async (term: any) => {
  try {
    //https://api.dev.pendulumintel.com/narrative?page=1&per_page=10&sort=name:asc&in_library=false&q=name:like:microsoft
    let url = `${process.env.REACT_APP_API}/${ROUTES.narrative}`

    const queryParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      in_library: false,
      q: `name:like:${term}`,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Flags CRUD:
export const getFlagsCrud = async (pageNumber: any) => {
  try {
    //https://api.dev.pendulumintel.com/custom-insights?page=1&per_page=10&sort=name:asc
    let url = `${process.env.REACT_APP_API}/${ROUTES.flagsList}`

    const queryParams = {
      page: pageNumber,
      per_page: 10,
      sort: 'name:asc',
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Flags Search CRUD:
export const getFlagsSearchCrud = async (term: any) => {
  try {
    //https://api.dev.pendulumintel.com/custom-insights?page=1&per_page=10&sort=name:asc&q=name:like:multi
    let url = `${process.env.REACT_APP_API}/${ROUTES.flagsList}`

    const queryParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      q: `name:like:${term}`,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Assets CRUD:
export const getAssetsCrud = async (pageNumber: any) => {
  try {
    //https://api.dev.pendulumintel.com/custom-insights?page=1&per_page=10&sort=name:asc&is_asset=true
    let url = `${process.env.REACT_APP_API}/${ROUTES.flagsList}`

    const queryParams = {
      page: pageNumber,
      per_page: 10,
      sort: 'name:asc',
      is_asset: true,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Assets Search CRUD:
export const getAssetsSearchCrud = async (term: any) => {
  try {
    //https://api.dev.pendulumintel.com/custom-insights?page=1&per_page=10&sort=name:asc&is_asset=true&q=name:like:test
    let url = `${process.env.REACT_APP_API}/${ROUTES.flagsList}`

    const queryParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      is_asset: true,
      q: `name:like:${term}`,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//Delete CRUD:
export const getDeleteCrud = async (id: any) => {
  try {
    //https://api.dev.pendulumintel.com/scorecards/landscape/maps/0db39ed2-878d-41be-b46b-083dcea41cc9
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}/${id}`
    const { data } = await axios.delete(url)
    return data
  } catch (error) {
    return error
  }
}

export const getShareUserCrud = async () => {
  try {
    //https://api.dev.pendulumintel.com/user?page=1&per_page=50
    let url = `${process.env.REACT_APP_API}/${ROUTES.user}`

    const queryParams = {
      page: 1,
      per_page: 10000,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

export const getShareUserSearch = async (term: any) => {
  try {
    //https://api.dev.pendulumintel.com/user
    //?page=1&per_page=10&sort=email%3Aasc&q=email%3Alike%3Aael&tenant_id=6aadf226-afa0-45b9-b2f8-4f94cee438c5

    let url = `${process.env.REACT_APP_API}/${ROUTES.user}`

    const queryParams = {
      page: 1,
      per_page: 10,
      sort: 'email:asc',
      q: `email:like:${term}`,
      tenant_id: '6aadf226-afa0-45b9-b2f8-4f94cee438c5',
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

export const getShareUserData = async (id: any) => {
  try {
    //https://api.dev.pendulumintel.com/scorecards/landscape/maps/
    //1d7358d4-046a-4e51-b0d9-8c90ccefe6d0/share
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}/${id}/share`
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    return error
  }
}

export const getSharePostUserData = async (id: any, payload: any) => {
  try {
    //https://api.dev.pendulumintel.com/scorecards/landscape/maps/ad-as-aea41cc9/share
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}/${id}/share`
    const { data } = await axios.post(url, payload)
    return data
  } catch (error) {
    return error
  }
}

export const getLandscapeMapsData = async (id: any) => {
  try {
    //https://api.dev.pendulumintel.com/scorecards/landscape?map_id=49605f92-b867-4ca6-8100-552ed4322a73
    let url = process.env.REACT_APP_API + ROUTES.landscapeData

    const queryParams = {
      map_id: id,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

export const getLandscapeEditMaps = async (id: any) => {
  try {
    //https://api.dev.pendulumintel.com/scorecards/landscape?map_id=49605f92-b867-4ca6-8100-552ed4322a73
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}`

    const queryParams = {
      map_id: id,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//WishlistCrud CRUD:
export const getWatchListCrud = async (pageNumber: any) => {
  try {
    //https://api.dev.pendulumintel.com/watchlist?page=1&per_page=10&sort=name:asc
    let url = `${process.env.REACT_APP_API}${ROUTES.watchlist}`

    const queryParams = {
      page: pageNumber,
      per_page: 10,
      sort: 'name:asc',
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

//WishlistCrudSearch CRUD:
export const getWatchListSearchCrud = async (term: any) => {
  try {
    //https://api.dev.pendulumintel.com/narrative?page=1&per_page=10&sort=name:asc&in_library=false&q=name:like:microsoft
    let url = `${process.env.REACT_APP_API}${ROUTES.watchlist}`

    const queryParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      q: `name:like:${term}`,
    }
    const { data } = await axios.get(url, { params: queryParams })
    return data
  } catch (error) {
    return error
  }
}

export const getUpdateEditMap = async (id: any, payload: any) => {
  try {
    //https://api.dev.pendulumintel.com/scorecards/landscape/maps/ad-as-aea41cc9/share
    let url = `${process.env.REACT_APP_API}/${ROUTES.mapsLandscapeData}/${id}`
    const { data } = await axios.put(url, payload)
    return data
  } catch (error) {
    return error
  }
}
