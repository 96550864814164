import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Checkbox, Table, Tooltip, Input, Spin, Select } from 'antd'
import { store } from 'store'
import { useEffectOnce } from 'react-use'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as DeleteIcon } from 'assets/images/icon-trash.svg'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import { ReactComponent as FilterIcon } from 'assets/images/filterby-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import SampleIcon from 'assets/images/add-logo-sample.svg'

import { ITenant } from 'models/models'
import { DeleteModal } from '../TenantDetails/Modals/DeleteModal/DeleteModal'
import { AddTenantModal } from '../TenantDetails/Modals/AddTenantModal/AddTenantModal'
import { TablePagination } from 'version2/components/common/Pagination/Pagination'

import './TenantList.scss'

export const TenantList = observer(() => {
  const navigate = useNavigate()
  const filterRef = useRef<any>(null)
  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logo_url',
      key: 'logo',
      width: '20%',
      render: (logo_url: any) => {
        return (
          <div className='tenant-list__logo'>
            <img
              src={logo_url ?? SampleIcon}
              alt=''
              onError={(e: any) => {
                e.target.src = SampleIcon
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'Tenant',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (name: string) => {
        return name?.length > 0 ? name : '_'
      },
    },
    {
      title: 'Website',
      dataIndex: 'domain',
      key: 'domain',
      width: '20%',
      render: (domain: string) => {
        return domain?.length > 0 ? (
          <a
            className='tenant-list__link'
            target='_blank'
            rel='noopener noreferrer'
            href={domain}
            onClick={(e) => e.stopPropagation()}>
            {domain}
          </a>
        ) : (
          '_'
        )
      },
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      width: '20%',
      render: (industry: string) => {
        return industry?.length > 0 ? industry : '_'
      },
    },
    {
      title: 'Actions',
      render: (col: any) => {
        return (
          <div className='tenant-list__actions'>
            <Tooltip overlayClassName='tenant-list__tooltip' title={'Delete'} placement='bottom'>
              <div
                className='tenant-list__actions-div tenant-list__margin'
                onClick={(e) => {
                  e.stopPropagation()
                  setIsDeleteModalOpen(`${col.name},${col.id}`)
                }}>
                <DeleteIcon height={18} className='tenant-list__dropdown__icon tenant-list__icon' />
              </div>
            </Tooltip>

            <Tooltip overlayClassName='tenant-list__tooltip' title={'Edit'} placement='bottom'>
              <div
                className='tenant-list__actions-div'
                onClick={(e) => {
                  e.stopPropagation()
                  setEditModalId(col.id)
                  setIsAddFormModalOpen(true)
                }}>
                <EditIcon className='tenant-list__dropdown__icon tenant-list__icon' />
              </div>
            </Tooltip>
          </div>
        )
      },
      key: 'actions',
      width: '20%',
    },
  ]

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<string>('')
  const [isAddFormModalOpen, setIsAddFormModalOpen] = useState<boolean>(false)
  const [editModalId, setEditModalId] = useState<string>('')
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState('')

  const { tenantsStore, loaderStore } = store
  const { tenants, tenantsPagination, setActiveTenant, setBackURL, deleteTenant, fetchTenants } = tenantsStore
  const { isLoadingTenants } = loaderStore

  const [filteredData, setFilteredData] = useState<ITenant[]>([])

  useEffectOnce(() => {
    fetchTenants({ page: 1, pageSize: tenantsPagination.pageSize })
  })

  useEffect(() => {
    function handleClickOutsideTooltip(event: any) {
      if (
        typeof event?.target?.className === 'string' &&
        filterRef.current &&
        !filterRef?.current?.contains(event?.target)
      ) {
        const check =
          event?.target?.className?.includes('ant-select') ||
          event?.target?.className?.includes('tenant-list__filters-div') ||
          event?.target?.className?.includes('ant-checkbox')

        if (!check) setIsFilterOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutsideTooltip)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTooltip)
    }
  })

  const openAddForm = () => {
    return setIsAddFormModalOpen(true)
  }

  const filterOptions = () => {
    let items = []

    items = tenants.map((tenant) => {
      return (
        <Select.Option key={tenant.name} value={tenant.name}>
          {
            <div
              className='tenant-list__filters-div'
              onClick={(e) => {
                e.stopPropagation()
              }}>
              <Checkbox
                onClick={(e) => {
                  setFilteredData((prev) => {
                    const data = prev.filter((list) => list.name === tenant.name)
                    if (data?.length > 0) return prev.filter((list) => list.name !== tenant.name)
                    return [...prev, tenant]
                  })
                  e.stopPropagation()
                }}
              />
              <span className='tenant-list__filters-div__label'>{tenant.name}</span>
            </div>
          }
        </Select.Option>
      )
    })
    return items
  }

  return (
    <Spin spinning={isLoadingTenants}>
      <div className='tenant-list'>
        <div className='tenant-list__container'>
          <div className='tenant-list__container__left-div'>
            <span className='tenant-list__container__left-div__title'>
              Add or Remove Tenants for Hassle-Free Management
            </span>
          </div>

          <div className='tenant-list__container__right-div'>
            <Select
              open={isFilterOpen}
              className='tenant-list__dropdown'
              popupClassName={'tenant-list__dropdown__popup'}>
              {filterOptions()}
            </Select>
            <div ref={filterRef} onClick={() => setIsFilterOpen((prev) => !prev)}>
              {filteredData?.length > 0 ? (
                <div className='tenant-list__filters-div__applied'>
                  <FilterIcon />
                  <span>{`${filteredData?.length} Filters`}</span>
                </div>
              ) : (
                <Tooltip overlayClassName='tenant-list__tooltip' title={'Filters'} placement='bottom'>
                  <FilterIcon className='tenant-list__dropdown__icon' />
                </Tooltip>
              )}
            </div>
            <Input
              className='tenant-list__searchBox'
              placeholder='Search tenant'
              prefix={<SearchIcon />}
              onPressEnter={async (e) => {
                let obj: { pageSize: number; page: number; searchParam?: string } = {
                  page: 1,
                  pageSize: tenantsPagination.pageSize,
                }
                if (searchInput.length > 0) obj['searchParam'] = searchInput
                fetchTenants(obj)
              }}
              onChange={(e) => {
                setSearchInput(e.target.value)
              }}
              value={searchInput}
            />
            <button className='tenant-list__container__right-div__add-btn' onClick={openAddForm}>
              + Add tenant
            </button>
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={filteredData?.length > 0 ? filteredData : tenants}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setActiveTenant(record)
                  setBackURL('/settings/tenants/list')
                  navigate(`/settings/tenants/configuration/${record.id || ''}`)
                },
              }
            }}
            pagination={
              filteredData?.length > 0
                ? false
                : TablePagination({
                    totalCount: tenantsPagination.total,
                    currentPage: tenantsPagination.currentPageNumber,
                    pageSize: tenantsPagination.pageSize,
                    position: ['bottomCenter'],
                    showPageSizeChanger: true,
                    pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                    onChange(page, pageSize) {
                      let obj: { pageSize: number; page: number; searchParam?: string } = {
                        page,
                        pageSize,
                      }
                      if (searchInput.length > 0) obj['searchParam'] = searchInput
                      fetchTenants(obj)
                    },
                    locale: { items_per_page: '' },
                  })
            }
          />
        </div>
        <DeleteModal
          content={{
            title: `Are you sure to delete ${isDeleteModalOpen.split(',')[0]}?`,
            description:
              'Any existing users, narratives, reports, bookmarks & watchlists will also be deleted and this action cannot be undone.',
          }}
          isModalVisible={isDeleteModalOpen}
          setIsModalVisible={setIsDeleteModalOpen}
          onDelete={deleteTenant}
        />
        <AddTenantModal
          modalOpen={isAddFormModalOpen}
          setModalOpen={setIsAddFormModalOpen}
          id={editModalId}
          setEditFormId={setEditModalId}
        />
      </div>
    </Spin>
  )
})
