import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce, useUnmount } from 'react-use'
import { observer } from 'mobx-react-lite'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { List, Menu, Spin } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import millify from 'millify'
import dayjs from 'dayjs'

import { Links } from './Links/Links'
import { Tags } from '../../../../common/Tags/Tags'
import { AddAlerts } from '../../../../common/Alert/Alert'
import { FeedHeader } from './FeedHeader/FeedHeader'
import { FeedUserDetail } from '../FeedUserDetail/FeedUserDetail'
import { Share } from 'version2/components/common/Share/Share'
import { SelectPlatformDropdown } from 'version2/components/common/SelectPlatformDropdown/SelectPlatformDropdown'
import { TokenizedInput } from 'version2/components/common/TokenizedInput/TokenizedInput'
import { WatchlistLinksModal } from 'version2/components/Monitor/Watchlist/WatchlistLinksModal/WatchlistLinksModal'

import { ReactComponent as EnterIcon } from 'assets/images/icons/keyboard-return.svg'
import UserAvatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as RightArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'

import { STORE_LOOKUP_DICTIONARY, SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { monitorMode, WatchlistChannel } from 'models/models'
import { store } from 'store'

import styles from './FeedDetail.module.scss'
import './FeedDetail.scss'

interface Props {
  mode: monitorMode
}
export const FeedDetail = observer(({ mode }: Props) => {
  const { id } = useParams()
  const search = useLocation().search
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(search)
  let listName = searchParams.get('list') || ''
  listName =
    listName && listName?.length > 0 ? listName[0].toUpperCase() + listName.slice(1) : mode === 'community' ? '' : ''

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [showAddTagsPage, setShowAddTagsPage] = useState(false)
  const [showAddAlertPage, setShowAddAlertPage] = useState(false)
  const [showSharedPage, setShowSharedPage] = useState(false)
  const [keywordSearchExpression, setKeywordSearchExpression] = useState('')

  const [isLinksModalVisible, setIsLinksModalVisible] = useState(false)
  const [selectedPlatformForDetails, setSelectedPlatformForDetails] = useState<WatchlistChannel | null>(null)
  const [languageRef, setLanguageRef] = useState<HTMLElement | null>(null)

  const { monitorNarratives, monitorCommunities, userStore, usersStore, loaderStore } = store
  const {
    itemDetailsError,
    listItems,
    listItemsCreatedByUser,
    listItemsNarrativeLibrary,
    activeItem,
    lightTags,
    shareUsers,
    isPowerInsightsOpen,
    fetchPowerInsights,
    shareItem,
    actionTag,
    createTag,
    fetchLightTags,
    setUserID,
    fetchSharedUsers,
    fetchListItems,
    fetchListItemsByUser,
    fetchListItemsByNarrativeLibrary,
    fetchSnippetsInfo,
    setSelectedCommunityInfo,
    deleteItem,
    resetStore,
  } = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]

  const { alertSharedUsers, saveAlert, editAlert, deleteAlert, fetchAlerts } =
    store[`manage${STORE_LOOKUP_DICTIONARY[mode]}Store`]
  const { activeNarrative } = monitorNarratives
  const { activeCommunity } = monitorCommunities

  const { userId, userEmail, fullName } = userStore
  const { users, getUser, fetchUsers } = usersStore
  const {
    isLoadingNarratives,
    isLoadingCommunities,
    isLoadingTags,
    isLoadingAlerts,
    isLoadingSharedData,
    isLoadingBookmarks,
  } = loaderStore

  useEffectOnce(() => {
    if (!users.items?.length) {
      fetchUsers(1, 10)
    }
  })

  useEffect(() => {
    if (itemDetailsError) {
      navigate(`/monitor/${mode.toLowerCase()}`)
    }
  }, [itemDetailsError])

  useEffect(() => {
    if (searchParams.get('page') === 'share') {
      showSharePage()
    }
  }, [search])

  useEffect(() => {
    setUserID(userId)
  }, [userId])

  useEffectOnce(() => {
    if (listName === 'User') fetchListItemsByUser({ activeId: id, pageName: 'details' })
    else fetchListItems({ setActiveId: id, pageName: 'details' })
    fetchLightTags()
    if (listName === 'NarrativeLibrary') fetchListItemsByNarrativeLibrary({ pageName: 'details' })
  })

  useEffect(() => {
    fetchSnippetsInfo(id || '', listName)
  }, [showAddAlertPage, showAddTagsPage])

  useEffect(() => {
    if (showAddAlertPage && mode === 'narrative' && activeNarrative?.alert_id) fetchAlerts(activeNarrative.alert_id)
  }, [showAddAlertPage])

  useEffect(() => {
    if (!activeNarrative?.boolean_query) {
      setKeywordSearchExpression('')
    } else {
      setKeywordSearchExpression(activeNarrative.boolean_query.replace(/(AND|OR)/gi, '<b>$1</b>'))
    }
  }, [activeNarrative?.boolean_query])

  useUnmount(() => {
    resetStore()
  })

  const getRoute = (id: string) => {
    let path = `/monitor/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}/${id}?list=${listName}`
    if (mode === 'bookmark') {
      path = `/investigate/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}/${id}?list=${listName}`
    }

    return window.history.pushState({}, '', path)
  }

  const setNextData = () => {
    if (listName === 'User') {
      const index = selectedIndex === -1 ? listItemsCreatedByUser.findIndex((item) => item.id === id) : selectedIndex
      if (listItemsCreatedByUser?.length && listItemsCreatedByUser.length > index + 1) {
        setSelectedIndex(index + 1)
        setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(listItemsCreatedByUser[index + 1].id, listName)
        else fetchSnippetsInfo(listItemsCreatedByUser[index + 1].id, listName)
        getRoute(listItemsCreatedByUser[index + 1].id)
      }
    } else if (listName === 'Suggestions') {
      const index = selectedIndex === -1 ? listItems.findIndex((item) => item.id === id) : selectedIndex
      if (listItems?.length && listItems.length > index + 1) {
        setSelectedIndex(index + 1)
        setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(listItems[index + 1].id, listName)
        else fetchSnippetsInfo(listItems[index + 1].id, listName)
        getRoute(listItems[index + 1].id)
      }
    } else if (listName === 'NarrativeLibrary') {
      const index = selectedIndex === -1 ? listItemsNarrativeLibrary.findIndex((item) => item.id === id) : selectedIndex
      if (listItemsNarrativeLibrary?.length && listItemsNarrativeLibrary.length > index + 1) {
        setSelectedIndex(index + 1)
        setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(listItemsNarrativeLibrary[index + 1].id, listName)
        else fetchSnippetsInfo(listItemsNarrativeLibrary[index + 1].id, listName)
        getRoute(listItemsNarrativeLibrary[index + 1].id)
      }
    }
  }

  const setPreviousData = () => {
    if (listName === 'User') {
      const index = selectedIndex === -1 ? listItemsCreatedByUser.findIndex((item) => item.id === id) : selectedIndex
      if (listItemsCreatedByUser?.length && index - 1 >= 0) {
        setSelectedIndex(index - 1)
        setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(listItemsCreatedByUser[index - 1].id, listName)
        else fetchSnippetsInfo(listItemsCreatedByUser[index - 1].id, listName)
        getRoute(listItemsCreatedByUser[index - 1].id)
      }
    } else if (listName === 'Suggestions') {
      const index = selectedIndex === -1 ? listItems.findIndex((item) => item.id === id) : selectedIndex
      if (listItems?.length && index - 1 >= 0) {
        setSelectedIndex(index - 1)
        setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(listItems[index - 1].id, listName)
        else fetchSnippetsInfo(listItems[index - 1].id, listName)
        getRoute(listItems[index - 1].id)
      }
    } else if (listName === 'NarrativeLibrary') {
      const index = selectedIndex === -1 ? listItemsNarrativeLibrary.findIndex((item) => item.id === id) : selectedIndex
      if (listItemsNarrativeLibrary?.length && index - 1 >= 0) {
        setSelectedIndex(index - 1)
        setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(listItemsNarrativeLibrary[index - 1].id, listName)
        else fetchSnippetsInfo(listItemsNarrativeLibrary[index - 1].id, listName)
        getRoute(listItemsNarrativeLibrary[index - 1].id)
      }
    }
  }

  const showTagsPage = () => {
    setShowAddTagsPage(true)
    setShowSharedPage(false)
    setShowAddAlertPage(false)
  }

  const showAlertsPage = () => {
    setShowAddAlertPage(true)
    setShowAddTagsPage(false)
    setShowSharedPage(false)
  }

  const showSharePage = () => {
    setShowSharedPage(true)
    setShowAddTagsPage(false)
    setShowAddAlertPage(false)
    fetchSharedUsers(activeItem?.id || '')
  }

  const tags = mode === 'narrative' ? activeNarrative?.tags || [] : activeCommunity?.tags || []
  const itemMode = mode === 'narrative' ? 'narrative' : 'community'

  const openAddedChannelsDetails = (platform: WatchlistChannel) => {
    setSelectedPlatformForDetails(platform)
    setIsLinksModalVisible(true)
  }

  const closePlatformLinksModal = () => {
    setIsLinksModalVisible(false)
    setSelectedPlatformForDetails(null)
  }

  const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
    setLanguageRef(node)
  }, [])

  const scrollHandler = (scrollOffset: number) => {
    if (languageRef) {
      const currentScroll = languageRef.scrollLeft
      const maxScroll = languageRef.scrollWidth - languageRef.clientWidth

      if (currentScroll < maxScroll) {
        languageRef.scrollLeft += scrollOffset
      } else {
        languageRef.scrollLeft = 0
      }
    }
  }

  const selectLanguageTab = async ({ key }: any) => {
    if (
      activeNarrative &&
      activeItem?.translatedNarrativeLanguages &&
      Object.keys(activeItem?.translatedNarrativeLanguages)?.length > 0
    )
      key === 'Default'
        ? setKeywordSearchExpression(activeNarrative.boolean_query.replace(/(AND|OR)/gi, '<b>$1</b>' || ''))
        : setKeywordSearchExpression(activeItem?.translatedNarrativeLanguages[key])
  }

  const renderComponent = () => {
    if (showAddTagsPage) {
      return (
        <Tags
          mode={mode}
          itemData={{ type: itemMode, id: id || '', name: activeItem?.name || '' }}
          tags={tags}
          allTags={lightTags}
          actionTag={actionTag}
          createTag={createTag}
          setShowAddTagsPage={setShowAddTagsPage}
        />
      )
    } else if (showAddAlertPage) {
      return (
        <AddAlerts
          mode={mode}
          isLoading={isLoadingAlerts}
          data={activeNarrative}
          itemData={{ type: itemMode, id: id || '', name: activeItem?.name || '' }}
          users={users.items}
          shareUsers={alertSharedUsers}
          loggedInUser={{ userID: userId, email: userEmail, name: fullName }}
          saveAlert={saveAlert}
          editAlert={editAlert}
          deleteAlert={deleteAlert}
          setShowAddAlertPage={setShowAddAlertPage}
        />
      )
    } else if (showSharedPage) {
      return (
        <Share
          mode={mode}
          isLoading={isLoadingSharedData}
          itemData={{ type: itemMode, id: id || '', name: activeItem?.name || '' }}
          users={users}
          shareUsers={shareUsers}
          loggedInUser={{ userID: userId, email: userEmail, name: fullName }}
          shareItem={shareItem}
          setShowSharePage={setShowSharedPage}
        />
      )
    } else {
      return (
        <div className='feed-details'>
          <WatchlistLinksModal
            isModalVisible={isLinksModalVisible}
            closeModal={closePlatformLinksModal}
            channel={selectedPlatformForDetails}
          />

          <FeedHeader
            mode={mode}
            id={id}
            activeItem={activeItem}
            listName={listName}
            setNextData={setNextData}
            setPreviousData={setPreviousData}
            showTagsPage={showTagsPage}
            showAlertsPage={showAlertsPage}
            showSharePage={showSharePage}
            deleteItem={deleteItem}
          />
          <div className='feed-details__desc-section'>
            {!!activeItem && mode === 'bookmark' && (
              <div className='bookmark'>
                <div className='bookmark__created-on'>
                  <span className='bookmark-section-heading'>List created on: </span>
                  {dayjs(activeItem.created).format('DD MMMM, YYYY') || ''}
                </div>

                <div className='bookmark__snippets-count'>
                  <span className='bookmark-section-heading'>Snippets: </span>
                  {millify(activeItem.documentIds?.length || 0)}
                </div>

                <div className='bookmark__description'>
                  <div className='bookmark-section-heading'>Description:</div>
                  <p className='bookmark__description__text'>{activeItem.description || 'No description available'}</p>
                </div>
              </div>
            )}

            {mode !== 'bookmark' && (
              <FeedUserDetail
                heading='Description'
                description={activeItem?.description || 'No description available'}
              />
            )}
            <div>
              {activeItem?.channels
                ?.filter((channel) => typeof channel.platform === 'string')
                .map((channel: WatchlistChannel, i: number) => {
                  return (
                    <div key={uuidv4()} className={styles.platformChannelsListWrapper}>
                      <div className={styles.addPlatformSection}>
                        <div className={styles.addPlatformInputWrapper}>
                          <SelectPlatformDropdown channel={channel} isEditMode={false} />

                          <div className={styles.lineSeparator}></div>

                          <TokenizedInput
                            placeholder='Use comma for multiple URLs'
                            initialTokens={channel.channel_urls}
                            isEditMode={false}
                          />
                        </div>

                        <div className={styles.suffixIcon}>
                          <EnterIcon className={styles.icon} />
                          <span className={styles.text}>Enter</span>
                        </div>
                      </div>

                      {channel.channel_urls.length > 0 && (
                        <div className={styles.totalLinksCount} onClick={() => openAddedChannelsDetails(channel)}>
                          {channel.channel_urls.length} {channel.channel_urls.length === 1 ? 'Account' : 'Accounts'}{' '}
                          Added
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>

            {mode === 'narrative' && (
              <>
                <FeedUserDetail
                  heading='Tags'
                  description=''
                  tags={activeItem?.tags?.length ? activeItem?.tags : ['N/A']}
                />
                <FeedUserDetail
                  heading='Creator'
                  info={{
                    user: getUser(activeItem?.ownerId || ''),
                    date: `${activeItem?.created ? activeItem?.created : 'N/A'}`,
                  }}
                  image={UserAvatar}
                />
              </>
            )}
            {mode === 'narrative' && (
              <>
                <div className='feed-details__type_description'>
                  <h6>Keyword Search</h6>

                  {(Object.keys(activeItem?.translatedNarrativeLanguages || {})?.length || 0) > 0 && (
                    <div className='feed-details__languages-nav-container'>
                      <nav className='feed-details__languages-nav' ref={listRefCallback}>
                        <div>
                          <Menu
                            mode='horizontal'
                            defaultSelectedKeys={['Default']}
                            triggerSubMenuAction='click'
                            disabledOverflow>
                            {['Default', ...Object.keys(activeItem?.translatedNarrativeLanguages || {})].map(
                              (language) => {
                                return (
                                  <Menu.Item key={language} id={language} onClick={selectLanguageTab}>
                                    <Link to={''}>
                                      {language === 'Default' ? 'Default' : SUPPORTED_LANGUAGES_CODE[language]}
                                    </Link>
                                  </Menu.Item>
                                )
                              },
                            )}
                          </Menu>
                        </div>
                      </nav>

                      <RightArrowIcon
                        onClick={() => {
                          scrollHandler(25)
                        }}
                      />
                    </div>
                  )}
                  {activeItem?.narrativeType === 'NLP' ? (
                    <div className='feed-details__type_description--ML'>
                      <span>
                        This Narrative uses a custom developed and trained Machine Learning model to detect the content
                        that best represents this narrative. Machine Learning narratives eliminate the need to come up
                        with and refine keyword searches, as well as dramatically reducing noise in search results.
                      </span>
                    </div>
                  ) : (
                    <div className='feed-details__type_description--others'>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: keywordSearchExpression,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className='feed-details__saved-links-list'>
                  <h6>Parent Narrative</h6>
                  {activeNarrative?.parent_narratives?.map((narrative) => (
                    <List className='feed-details__ml-list'>
                      {narrative} <span className='feed-details__color-ml'>ML</span>
                    </List>
                  ))}
                </div>
                <div className='feed-details__saved-links-list'>
                  <h6>Saved links</h6>
                  {activeNarrative?.related_links?.length
                    ? activeNarrative.related_links.map((item) => <Links url={item} />)
                    : 'No saved links available'}
                </div>
              </>
            )}

            {mode === 'community' && (
              <div className='feed-details__saved-links-list'>
                <h6>Sample creators</h6>
                {activeCommunity?.sample_creators?.length
                  ? activeCommunity.sample_creators.map((item) => <Links url={item} />)
                  : 'N/A'}
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  return (
    <Spin spinning={isLoadingNarratives || isLoadingCommunities || isLoadingTags || isLoadingBookmarks}>
      {renderComponent()}
    </Spin>
  )
})
