import { monitorAPI } from 'api/api'
import { makeAutoObservable } from 'mobx'
import { UtilService } from 'services/Util/Util'
import { monitorMode } from 'models/models'

export class SubnavSearchStore {
  recentSearchesLoaded: { querry: string; type: 'Tags' | 'Narratives' | 'Communities' }[] = []
  input: string = ''
  selectedItem: 'Tags' | 'Narratives' | 'Communities' | null = null
  currentSearchQuerry: string = ''

  searchResultItems: { id: string; name: string; type: 'narrative' | 'tag' | 'community' }[] = []

  searchTimer: NodeJS.Timeout | null = null

  isLoadingResults = false

  preloadedData: {
    type: 'Tags' | 'Narratives' | 'Communities'
    querryies: {
      id: string
      name: string
      label?: string
      type: 'tag' | 'community' | 'narrative'
    }[]
  }[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get recentSearches() {
    try {
      if (this.recentSearchesLoaded.length) return this.recentSearchesLoaded

      const localStorageResult = localStorage.getItem('recentSearchesLoaded')

      if (!localStorageResult) return []
      const localStorageResultArr = JSON.parse(localStorageResult)

      if (localStorageResult) {
        this.setRecentSearchesLoaded(localStorageResultArr)
        return this.recentSearchesLoaded
      }

      return []
    } catch (e) {
      return []
    }
  }

  searchResults = async (mode: 'auto' | 'manual' = 'manual') => {
    try {
      this.setIsLoadingResults(true)
      if (this.input === this.currentSearchQuerry && mode !== 'manual') return false

      if (mode === 'manual' && this.selectedItem) this.addRecentSearch(this.input, this.selectedItem)

      const modeLookup: { Narratives: 'narrative'; Tags: 'tag'; Communities: 'community' } = {
        Narratives: 'narrative',
        Tags: 'tag',
        Communities: 'community',
      }

      if (mode !== 'manual' || !this.selectedItem) return false
      this.setCurrentSearchQuerry(this.input)
      const { items } = await monitorAPI.getMonitorSearchResults(this.input, modeLookup[this.selectedItem])

      this.setSearchResultItems(
        items.map((el: any) => {
          const newEl = el
          if (this.selectedItem) newEl.type = modeLookup[this.selectedItem]
          return newEl
        }),
      )

      //the loading happens too fast and the loader most of the time is off
      await setTimeout(() => this.setIsLoadingResults(false), 750)
      if (items.length > 0) return true
      return false
    } catch (e) {
      await setTimeout(() => this.setIsLoadingResults(false), 750)
      return false
    }
  }

  genericSearch = async () => {
    try {
      this.setIsLoadingResults(true)
      if (this.input === this.currentSearchQuerry) return

      this.setCurrentSearchQuerry(this.input)
      const [{ items: itemsTags }, { items: itemsNarratives }, { items: itemsCommunities }] = await Promise.all([
        monitorAPI.getMonitorSearchResults(this.input, 'tag'),
        monitorAPI.getMonitorSearchResults(this.input, 'narrative'),
        monitorAPI.getMonitorSearchResults(this.input, 'community'),
      ])

      let itemsArray = [
        ...itemsTags.map((el: any) => {
          const newEl = el
          newEl.type = 'tag'
          return newEl
        }),
        ...itemsNarratives.map((el: any) => {
          const newEl = el
          newEl.type = 'narrative'
          return newEl
        }),
        ...itemsCommunities.map((el: any) => {
          const newEl = el
          newEl.type = 'community'
          return newEl
        }),
      ]
      itemsArray = UtilService.shuffleArray(itemsArray).slice(0, 5)

      this.setSearchResultItems(itemsArray)
    } catch (e) {
    } finally {
      //the loading happens too fast and the loader most of the time is off
      await setTimeout(() => this.setIsLoadingResults(false), 750)
    }
  }

  search = async (mode: monitorMode) => {
    try {
      this.setIsLoadingResults(true)
      if (this.input === this.currentSearchQuerry) return

      this.setCurrentSearchQuerry(this.input)
      const { items } = await monitorAPI.getMonitorSearchResults(this.input, mode)

      let itemsArray = [
        ...items.map((el: any) => {
          const newEl = el
          newEl.type = mode
          return newEl
        }),
      ]
      itemsArray = UtilService.shuffleArray(itemsArray).slice(0, 5)

      this.setSearchResultItems(itemsArray)
    } catch (e) {
    } finally {
      //the loading happens too fast and the loader most of the time is off
      await setTimeout(() => this.setIsLoadingResults(false), 750)
    }
  }

  preloadIfNoRecent = async () => {
    const recentSearches = this.recentSearches
    let emptyRecent: ('Narratives' | 'Communities' | 'Tags')[] = ['Narratives', 'Communities', 'Tags']

    const modeLookup: { Narratives: 'narrative'; Tags: 'tag'; Communities: 'community' } = {
      Narratives: 'narrative',
      Tags: 'tag',
      Communities: 'community',
    }

    if (recentSearches.length > 0) {
      recentSearches.forEach((el) => {
        if (el.querry.length === 0) return
        emptyRecent = emptyRecent.filter((r) => r !== el.type)
      })
    }

    if (this.preloadedData.length > 0) {
      const preloaded = this.preloadedData.map((el) => el.type)
      emptyRecent = emptyRecent.filter((el) => !preloaded.includes(el))
    }

    emptyRecent.forEach(async (recent) => {
      const { items } = await monitorAPI.getMonitorSearchResults(this.input, modeLookup[recent])
      this.preloadedData.push({
        type: recent,
        querryies: items.map((el: any) => {
          const newEl = el
          newEl.type = modeLookup[recent]
          return newEl
        }),
      })
    })
  }

  setIsLoadingResults = (state: boolean) => {
    this.isLoadingResults = state
  }

  setSearchResultItems = (items: { id: string; name: string; type: 'narrative' | 'tag' | 'community' }[]) => {
    this.searchResultItems = items
  }

  setCurrentSearchQuerry = (querry: string) => {
    this.currentSearchQuerry = querry
  }

  setSelectedItem = (item: 'Tags' | 'Narratives' | 'Communities' | null) => {
    this.setSearchResultItems([])
    this.setCurrentSearchQuerry('')
    this.setInput('')
    this.selectedItem = item
  }

  setInput = (value: string) => {
    this.setSearchResultItems([])
    this.input = value
  }

  setRecentSearchesLoaded = (searches: { querry: string; type: 'Tags' | 'Narratives' | 'Communities' }[]) => {
    this.recentSearchesLoaded = searches
  }

  addRecentSearch = (querry: string, type: 'Tags' | 'Narratives' | 'Communities') => {
    if (this.recentSearchesLoaded.filter((el) => el.querry === querry && el.type === type).length) return

    this.recentSearchesLoaded.push({ querry, type })

    if (this.recentSearchesLoaded.filter((el) => el.type === type).length > 5) {
      let typeSearches = this.recentSearchesLoaded.filter((el) => el.type === type)
      const otherTypesSearches = this.recentSearchesLoaded.filter((el) => el.type !== type)
      typeSearches = typeSearches.slice(typeSearches.length - 5, typeSearches.length)

      this.setRecentSearchesLoaded([...typeSearches, ...otherTypesSearches])
    }

    localStorage.setItem('recentSearchesLoaded', JSON.stringify(this.recentSearchesLoaded))
  }

  pushRecentSearchesLoaded = (obj: { querry: string; type: 'Tags' | 'Narratives' | 'Communities' }) => {
    this.recentSearchesLoaded.push(obj)
  }

  resetInput = () => {
    this.input = ''
    this.selectedItem = null
    this.currentSearchQuerry = ''
    this.isLoadingResults = false
  }

  resetStore = () => {
    this.recentSearchesLoaded = []
    this.input = ''
    this.selectedItem = null
    this.currentSearchQuerry = ''
    this.searchResultItems = []
    this.searchTimer = null
    this.isLoadingResults = false
  }
}
