import { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'

import { IListItem, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import { DeleteModal } from '../DeleteModal/DeleteModal'
import { CreateBookmarkModal } from 'version2/components/Investigate/Bookmarks/CreateBookmarkModal/CreateBookmarkModal'

import { ReactComponent as LeftFeedIcon } from 'assets/images/icons/left-arrow.svg'
import { ReactComponent as RightFeedIcon } from 'assets/images/icons/right-arrow.svg'
import { ReactComponent as BackBtnIcon } from 'assets/images/icons/arrow-left.svg'
import { ReactComponent as AddTagIcon } from 'assets/images/icons/tag.svg'
import { ReactComponent as AnalyzeIcon } from 'assets/images/analyze.svg'
import { ReactComponent as AlertIcon } from 'assets/images/alert-icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/outline/pencil.svg'
import { ReactComponent as ShareIcon } from 'assets/images/share-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/outline/trash.svg'

import { store } from 'store'

import './FeedHeader.scss'

interface Props {
  mode: monitorMode
  id: string | undefined
  activeItem: IListItem | null
  listName: string
  setNextData: () => void
  setPreviousData: () => void
  showTagsPage?: () => void
  showAlertsPage?: () => void
  showSharePage?: () => void
  updateTagStatus?: (tagId: string, status: boolean) => void
  deleteItem: () => Promise<void>
}
export const FeedHeader = observer(
  ({
    mode,
    id,
    activeItem,
    listName,
    setNextData,
    setPreviousData,
    showTagsPage,
    showAlertsPage,
    showSharePage,
    updateTagStatus,
    deleteItem,
  }: Props) => {
    const navigate = useNavigate()
    const { fetchSnippetsInfo, resetSnippetFilters, snippetsFilter } = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]

    const [isBookmarksModalVisible, setIsBookmarksModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

    const onFollow = () => {
      try {
        updateTagStatus && updateTagStatus(activeItem?.id || '', !activeItem?.is_followed)
      } catch (e) {}
    }

    const onShare = () => {
      if (mode !== 'community') showSharePage && showSharePage()
    }

    const onAlert = () => {
      if (mode !== 'community') showAlertsPage && showAlertsPage()
    }

    const closeBookmarkModalHandler = (isBookmarkUpdated?: boolean) => {
      setIsBookmarksModalVisible(false)

      // also, check if the bookmark was updated or not. If yes, then fetch the bookmark details again
      if (isBookmarkUpdated) {
        fetchSnippetsInfo(id || '', listName)
      }
    }

    const dateFilters = {
      startDate: snippetsFilter.days.custom.dateRange[0],
      endDate: snippetsFilter.days.custom.dateRange[1],
      numberOfDays: snippetsFilter.days.noOfDays,
    }

    const resetSnippetFiltersHandler = () => {
      if (mode === 'bookmark') resetSnippetFilters(dateFilters)
      else {
        resetSnippetFilters()
      }
    }

    const editLink =
      mode !== 'community'
        ? mode === 'narrative'
          ? activeItem?.narrativeType !== 'NLP'
            ? `/monitor/narratives/edit-narrative/${id || ''}`
            : ''
          : mode === 'watchlist'
          ? `/monitor/watchlist/edit-watchlist/${id || ''}`
          : `/monitor/tags/edit/${id || ''}`
        : ''

    return (
      <div className='feed-header__container'>
        <div
          className='feed-header__back-btn'
          onClick={() => {
            resetSnippetFiltersHandler()
            if (mode === 'bookmark') {
              navigate(`/investigate/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`)
            } else {
              navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`)
            }
          }}>
          <BackBtnIcon />
          <span className='feed-header__back-btn--title'>Back to {mode === 'bookmark' ? 'list' : 'feed'}</span>
        </div>
        <div>
          <div className='feed-header__container__1'>
            <h3 className='feed-header__container__1__heading'>{activeItem?.name}</h3>
            {listName.length > 0 && (
              <div className='feed-header__container__1__feed-icons'>
                <LeftFeedIcon
                  onClick={() => {
                    resetSnippetFiltersHandler()
                    setPreviousData()
                  }}
                />
                <RightFeedIcon
                  onClick={() => {
                    resetSnippetFiltersHandler()
                    setNextData()
                  }}
                />
              </div>
            )}
          </div>

          <ul className={classNames('feed-header__menu', { 'feed-header__menu__centered': mode === 'bookmark' })}>
            {mode === 'tag' && (
              <li className='feed-header__item' onClick={onFollow}>
                <AddTagIcon />
                {activeItem?.is_followed ? 'Unfollow' : 'Follow'}
              </li>
            )}
            {mode !== 'tag' && mode !== 'bookmark' && (
              <>
                {mode !== 'watchlist' && (
                  <>
                    <li className='feed-header__item' onClick={showTagsPage}>
                      <AddTagIcon />
                      Add Tags
                    </li>

                    <li className='feed-header__item' onClick={() => navigate('/analyze')}>
                      <AnalyzeIcon />
                      Analyze
                    </li>
                  </>
                )}

                <li
                  className={classNames('feed-header__item', {
                    'feed-header__item--disabled': mode === 'community',
                  })}
                  onClick={onAlert}>
                  <AlertIcon />
                  Alerts
                </li>
              </>
            )}
            <li
              className={classNames('feed-header__item', {
                'feed-header__item--disabled': activeItem?.narrativeType === 'NLP' || mode === 'community',
              })}>
              {mode === 'bookmark' ? (
                <span className='feed-header__link' onClick={() => setIsBookmarksModalVisible(true)}>
                  <EditIcon />
                  <span className='feed-header__link__text'>Edit</span>
                </span>
              ) : activeItem?.permissionLevel === 'VIEWER' ? (
                <span
                  className='feed-header__link'
                  style={{
                    cursor: 'default',
                    opacity: 0.5,
                  }}>
                  <EditIcon />
                  <span className='feed-header__link__text'>Edit</span>
                </span>
              ) : (
                <Link className='feed-header__link' to={editLink}>
                  <EditIcon />
                  <span className='feed-header__link__text'>Edit</span>
                </Link>
              )}
            </li>
            <li
              className={classNames('feed-header__item', {
                'feed-header__item--disabled': mode === 'community',
              })}
              onClick={onShare}>
              <ShareIcon />
              Share
            </li>
            {(mode === 'watchlist' || mode === 'bookmark') && (
              <li className='feed-header__item' onClick={() => setIsDeleteModalVisible(true)}>
                <DeleteIcon className='feed-header__delete-icon' />
                Delete
              </li>
            )}

            {mode === 'narrative' &&
              (activeItem?.permissionLevel === 'VIEWER' ? (
                <li
                  className='feed-header__item'
                  style={{
                    cursor: 'default',
                    opacity: 0.5,
                  }}>
                  <DeleteIcon className='feed-header__delete-icon' />
                  Delete
                </li>
              ) : (
                <li className='feed-header__item' onClick={() => setIsDeleteModalVisible(true)}>
                  <DeleteIcon className='feed-header__delete-icon' />
                  Delete
                </li>
              ))}

            {mode === 'tag' && (
              <>
                <li className='feed-header__item--disabled'>
                  <AlertIcon />
                  Alerts
                </li>
                <li className='feed-header__item--disabled'>
                  <AnalyzeIcon />
                  Analyze
                </li>
              </>
            )}
          </ul>
        </div>

        {isBookmarksModalVisible && <CreateBookmarkModal isModalVisible closeModal={closeBookmarkModalHandler} />}

        <DeleteModal
          isModalVisible={isDeleteModalVisible}
          setIsModalVisible={setIsDeleteModalVisible}
          deleteFlag={deleteItem}
          mode={mode}
        />
      </div>
    )
  },
)
