import millify from 'millify'

export const snippetTranslator = ({
  rawSnippets,
  isFilterApplied = false,
}: {
  rawSnippets: any
  isFilterApplied?: boolean
}) => {
  const creators: string[] = []

  return {
    snippets: rawSnippets.map((snippet: any, index: number) => {
      if (snippet.platform !== 'Twitter' && snippet.platform !== 'News') creators.push(snippet?.channel_id)

      let author: { [key: string]: any } = {}
      let extras: { [key: string]: any } = {}

      if (snippet.platform === 'Twitter') {
        author.channel_title = snippet.post_metadata.author_name || ''
        author.channel_thumb_url = snippet.post_metadata.profile_image_url || ''

        extras.retweets = snippet.share_count || 0
        extras.likes = snippet.like_count || 0
        extras.comments = snippet.comment_count || 0
      }

      if (snippet.platform === 'News') {
        author.channel_title = snippet.post_metadata.post_source || ''

        extras.articleThumbImg = snippet.post_metadata?.post_thumb || ''
        extras.articleAuthor = snippet.post_metadata?.author_name || ''
      }
      let text = snippet.snippet_text
      if (isFilterApplied && snippet?.translated_snippet?.translated_text)
        text = snippet?.translated_snippet?.translated_text

      return {
        /* HACK: added the index so all the ids are unique */
        id: snippet.post_id + index,
        snippet_text: text,
        title: snippet.post_title,
        date: snippet.upload_date,
        riskScore: snippet.risk_score.toFixed(0) || 0,
        views: snippet.impression_count,
        viewsMilify: millify(snippet.impression_count || 0),
        views_diff: snippet.impression_diff_count,
        views_diff_milify: millify(snippet.impression_diff_count || 0),
        playSourceLink: snippet?.media_url,
        openSourceLink: snippet?.post_url,
        sourcePlatform: snippet.platform,
        channelId: snippet?.channel_id,
        author,
        duration: snippet.duration,
        offset: snippet.offset,
        sentiment: snippet.sentiment,
        translatedSnippet: snippet.translated_snippet,
        documentId: snippet.document_id,
        source: snippet.snippet_source,
      }
    }),
    creators,
  }
}
