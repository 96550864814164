import React, { useEffect, useState } from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { AxiosResponse } from 'axios'
import { fetchDefaultFile, fetchSelectedFile } from 'api/docs.api'
import { API_DOCS } from 'settings/settings'
import { UtilService } from 'services/Util/Util'

const SwaggerRenderer: React.FC = () => {
  const [swaggerYaml, setSwaggerYaml] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchSwaggerYaml = async () => {
      try {
        const response: AxiosResponse<string> = await fetchDefaultFile()

        if (!response || !response.data) {
          throw new Error('Failed to fetch YAML')
        }

        setSwaggerYaml(response.data)
        setLoading(false)
        setError(null)
      } catch (error) {
        setError(error as Error)
        setLoading(false)
      }
    }

    fetchSwaggerYaml()
  }, [])

  const handleFileChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFile = event.target.value

    try {
      const response: AxiosResponse<string> = await fetchSelectedFile(selectedFile)

      if (!response || !response.data) {
        throw new Error('Failed to fetch YAML')
      }

      setSwaggerYaml(response.data)
      setLoading(false)
      setError(null)
    } catch (error) {
      setError(error as Error)
      setLoading(false)
    }
  }

  const requestInterceptor = (req: any) => ({
    ...req,
    headers: {
      Authorization: `${UtilService.getAuthToken()}`,
    },
  })

  return (
    <div>
      <select onChange={handleFileChange}>
        {Object.keys(API_DOCS.FILES).map((key) => (
          <option key={key} value={API_DOCS.FILES[key]}>
            {key}
          </option>
        ))}
      </select>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <SwaggerUI spec={swaggerYaml} requestInterceptor={requestInterceptor} />
      )}
    </div>
  )
}

export { SwaggerRenderer }
