import { SwaggerRenderer } from 'version2/components/common/Docs/SwaggerRenderer'
import { ListPageSection } from 'version2/components/common/ListPageSection/ListPageSection'
import { NavigationSettings } from 'version2/components/common/NavigationSettings/NavigationSettings'

export const ApiDocsPage = () => {
  return (
    <div>
      <ListPageSection
        left={<NavigationSettings />}
        right={<SwaggerRenderer />}
        columns={{
          col1: {
            sm: 4,
            md: 4,
            lg: 4,
          },
          col2: {
            sm: 20,
            md: 20,
            lg: 20,
          },
        }}
      />
    </div>
  )
}
