/* eslint-disable prettier/prettier */
import { ExecutiveIntelligenceStore } from './monitorV1/executiveIntelligence.store'
import { MonitorCommunitiesStore } from './monitorV1/monitorCommunities.store'
import { MonitorNarrativesStore } from './monitorV1/monitorNarratives.store'
import { MonitorWatchlistStore } from './monitorV1/monitorWatchlist.store'
import { MonitorBookmarksStore } from './monitorV1/monitorBookmarks.store'
import { ManageStore } from './manage/manage.store'
import { ManageCommunitiesStore } from './manage/manageCommunities.store'
import { ManageNarrativesStore } from './manage/manageNarratives.store'
import { ManageWatchlistStore } from './manage/manageWatchlist.store'
import { MonitorTagsStore } from './monitorV1/monitorTags.store'
import { DefineNarrativeStoreV1 } from './defineNarrative.store'
import { DefineWatchlistStoreV1 } from './defineWatchlist.store'
import { ManageTagsStore } from './manage/manageTags.store'
import { ResetPasswordStore } from './resetPassword.store'
import { SubnavSearchStore } from './subnavSearch.store'
import { FeedbackStore } from './manage/feedback.store'
import { NavViewStore } from './navView.store'
import { LoaderStore } from './loader.store'
import { UsersStore } from './users.store'
import { UserStore } from './user.store'
import { WhiteboardStore } from './whiteboard.store'
import { AnticipatoryIntelligenceStore as AnticipatoryIntelligenceStoreV1 } from './monitorV1/anticipatoryIntelligence.store'
import { AttentionFlagsStore } from './attentionFlags/attentionFlags.store'
import { ManageTagsStore2 } from './manage2/manageTags.store'
import { ManageNarrativesStore2 } from './manage2/manageNarratives.store'
import { ManageWatchlistStore2 } from './manage2/manageWatchlist.store'
import { ManageCommunitiesStore2 } from './manage2/manageCommunities.store'
import { SpotlightStore } from './spotlight.store'
import { ReportMainViewStore } from './report/reportMainView.store'
import { MonitorStore as MonitorStoreV1 } from './monitorV1/monitor.store'
import { MonitorExportDownloadsStore } from './monitorV1/monitorDownloads.store'
import { WhiteboardV2Store } from './whiteboardV2.store'
import { TenantsStore } from './tenants/tenants.store'
import { MonitorVersion2Store } from './monitorVersion2/monitorVersion2.store'
import { FlagsStore } from './flags/flags.store'
import { AssetsStore } from './asset/asset.store'
import { CombineMonitorViewStore } from './monitorVersion2/combineMonitorView.store'
import { VectorsStore } from './vectors/vectors.store'
import { ToasterStore } from './toaster/toaster.store'
import { BreadcrumbStore } from './breadcrumb/breadcrumb.store'
import { MainStore } from './main/main.store'
import { AnticipatoryIntelligenceStore } from './anticipatoryIntelligence/anticipatoryIntelligence.store'
import { DefineWatchlistStore } from './define/defineWatchlist.store'
import { DefineNarrativeStore } from './define/defineNarrative.store'

class RootStore {
  userStore
  loaderStore

  defineNarrativeStoreV1

  defineWatchlistStoreV1

  resetPasswordStore

  manageCommunitiesStore
  manageNarrativesStore
  manageWatchlistStore
  manageTagsStore
  manageBookmarksStore

  feedbackStore

  monitorTags
  monitorNarratives
  monitorWatchlist
  monitorBookmarks
  monitorCommunities
  monitorExportDownloadsStore
  executiveIntelligence

  manageTagsStore2
  manageNarrativesStore2
  manageWatchlistStore2
  manageCommunitiesStore2

  usersStore

  subnavSearch

  navViewStore

  whiteboardStore

  anticipatoryIntelligenceStoreV1

  attentionFlagsStore
  tenantsStore

  spotlightStore

  reportMainViewStore
  monitorStorev1

  whiteboardV2Store
  monitorVersion2Store
  flagsStore
  assetsStore
  combineMonitorViewStore
  vectorsStore
  toasterStore
  breadcrumbStore

  mainStore
  anticipatoryIntelligenceStore
  defineNarrativeStore
  defineWatchlistStore

  constructor() {
    this.userStore = new UserStore()
    this.loaderStore = new LoaderStore()
    this.defineNarrativeStoreV1 = new DefineNarrativeStoreV1()
    this.defineWatchlistStoreV1 = new DefineWatchlistStoreV1()
    this.resetPasswordStore = new ResetPasswordStore()

    this.manageCommunitiesStore = new ManageCommunitiesStore()
    this.manageNarrativesStore = new ManageNarrativesStore()
    this.manageWatchlistStore = new ManageWatchlistStore()
    this.manageTagsStore = new ManageTagsStore()
    this.manageBookmarksStore = new ManageStore()

    this.feedbackStore = new FeedbackStore()

    this.monitorTags = new MonitorTagsStore()
    this.monitorNarratives = new MonitorNarrativesStore()
    this.monitorWatchlist = new MonitorWatchlistStore()
    this.monitorBookmarks = new MonitorBookmarksStore()
    this.monitorCommunities = new MonitorCommunitiesStore()
    this.executiveIntelligence = new ExecutiveIntelligenceStore()

    this.manageTagsStore2 = new ManageTagsStore2()
    this.manageNarrativesStore2 = new ManageNarrativesStore2()
    this.manageWatchlistStore2 = new ManageWatchlistStore2()
    this.manageCommunitiesStore2 = new ManageCommunitiesStore2()

    this.usersStore = new UsersStore()

    this.subnavSearch = new SubnavSearchStore()

    this.navViewStore = new NavViewStore()

    this.whiteboardStore = new WhiteboardStore()
    this.anticipatoryIntelligenceStoreV1 = new AnticipatoryIntelligenceStoreV1()

    this.attentionFlagsStore = new AttentionFlagsStore()
    this.tenantsStore = new TenantsStore()

    this.spotlightStore = new SpotlightStore()

    this.reportMainViewStore = new ReportMainViewStore()
    this.monitorStorev1 = new MonitorStoreV1()
    this.monitorExportDownloadsStore = new MonitorExportDownloadsStore()
    this.whiteboardV2Store = new WhiteboardV2Store()
    this.monitorVersion2Store = new MonitorVersion2Store()
    this.flagsStore = new FlagsStore()
    this.assetsStore = new AssetsStore()
    this.combineMonitorViewStore = new CombineMonitorViewStore()
    this.vectorsStore = new VectorsStore()
    this.toasterStore = new ToasterStore()
    this.breadcrumbStore = new BreadcrumbStore()

    this.mainStore = new MainStore()
    this.anticipatoryIntelligenceStore = new AnticipatoryIntelligenceStore()
    this.defineNarrativeStore = new DefineNarrativeStore()
    this.defineWatchlistStore = new DefineWatchlistStore()
  }
}

export const store = new RootStore()
