import { useEffect } from 'react'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import classNames from 'classnames'
import { useEffectOnce, useUnmount } from 'react-use'

import { monitorMode, monitorSnippetPage } from 'models/models'
import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { ExecutiveDetailSnippets } from './ExecutiveDetailSnippets'
import { MonitorSnippetList } from './SnippetList'
import { DefineMonitorSnippetList } from './DefineSnippetList'
import { DataFetching } from 'components/common/DataFetching/DataFetching'
import { NoResultsIcon } from 'components/common/NoResultsIcon/NoResultsIcon'
import { SnippetListHeader } from '../SnippetListHeader/SnippetListHeader'
import { MonitorSurpriseModal } from 'version2/components/Monitor/common/MonitorSurpriseModal/MonitorSurpriseModal'
import { PowerInsights } from '../PowerInsights/PowerInsights'
import { AnticipatoryIntelligenceSnippetList } from './AnticipatoryIntelligenceSnippetList'
import { DataTranslating } from 'components/common/DataFetching/DataTranslating'

import './SnippetPage.scss'
import { useNavigate } from 'react-router-dom'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const SnippetPage = observer(({ mode, page }: Props) => {
  const navigate = useNavigate()
  const pageCheck = page === 'define' || page === 'edit'
  const feedEmptyPageCheck = page !== 'define' && page !== 'edit' && page !== 'anticipatory-intelligence'

  let currentStore, monitorStore, defineStore
  if ((page === 'define' || page === 'edit') && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    defineStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}Store`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
    defineStore = store.defineNarrativeStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    defineStore = store.defineNarrativeStoreV1
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    defineStore = store.defineNarrativeStoreV1
  }
  const { isFeedEmpty, snippetsFilter, isPowerInsightsOpen, setIsFeedEmpty, resetStore } = currentStore

  const {
    surpriseModalData,
    totalSnippets,
    activeItem,
    isTranslatingFeed,
    fetchUserBookmarkedSnippets,
    setIsTranslatingFeed,
    showSurprise,
    fetchSurpriseVideos,
  } = monitorStore

  const { snippets, showResults, booleanSearchWord } = defineStore

  const { singlePersonData, snippetsTotal } = store.executiveIntelligence
  const { isLoadinFeed, isLoadingSnippetSearch, isLoadingTwitterPosts } = store.loaderStore
  const { attentionFlagsStore, userStore } = store
  const { tenantId } = userStore
  const { selectedTranslationTab, booleanSearchTranslation } = store.defineNarrativeStoreV1
  const { setUserTenantId, fetchFlags } = attentionFlagsStore

  const defineSearchTitle =
    booleanSearchTranslation[selectedTranslationTab] ||
    booleanSearchWord ||
    activeItem?.name ||
    singlePersonData?.name ||
    'Feed'
  const totalSnippetsCount = page === 'executive-intelligence' ? snippetsTotal : totalSnippets

  useEffect(() => {
    setUserTenantId(tenantId || '')
  }, [tenantId])

  useEffectOnce(() => {
    fetchFlags(true)
    fetchSurpriseVideos()
    fetchUserBookmarkedSnippets()
  })

  useEffect(() => {
    if (!isLoadinFeed && isTranslatingFeed) setIsTranslatingFeed(false)
  }, [isLoadinFeed])

  useUnmount(() => {
    setIsFeedEmpty(true)
    resetStore()
  })

  if (isPowerInsightsOpen) {
    return <PowerInsights mode={mode} page={page} />
  } else {
    if (currentStore.isFeedEmpty && !activeItem?.id?.length && !singlePersonData?.id?.length && feedEmptyPageCheck) {
      return mode === 'bookmark' ? null : <NoResultsIcon />
    } else {
      return (
        <div className={'snippet-list'}>
          {!isTranslatingFeed &&
            (isLoadinFeed ||
              isLoadingSnippetSearch ||
              isLoadingTwitterPosts ||
              (mode === 'watchlist' && ['define', 'edit'].includes(page) && showResults && snippets.length === 0)) && (
              <DataFetching
                title={defineSearchTitle}
                page={isLoadinFeed ? 'fetch' : 'searchNotFound'}
                daysFilters={snippetsFilter.days}
              />
            )}
          {isTranslatingFeed && isLoadinFeed && (
            <>
              <SnippetListHeader mode={mode} page={page} defineSearchTitle={defineSearchTitle} />
              <DataTranslating />
            </>
          )}
          <div
            className={classNames('snippet-list__display--block', {
              'snippet-list__display--none': isLoadinFeed || isLoadingSnippetSearch || isLoadingTwitterPosts,
            })}>
            {pageCheck && isFeedEmpty ? (
              <div></div>
            ) : (
              <>
                {page === 'anticipatory-intelligence' && (
                  <div className='sl_db_back_btn_container'>
                    <button
                      className='sl_db_back_btn'
                      onClick={() => {
                        navigate('/monitor/narratives')
                      }}>
                      Investigate
                    </button>
                  </div>
                )}
                <SnippetListHeader mode={mode} page={page} defineSearchTitle={defineSearchTitle} />

                <Spin spinning={isLoadinFeed || isLoadingSnippetSearch || isLoadingTwitterPosts}>
                  <div>
                    {(page === 'list' || page === 'details' || page === 'investigate-bookmark') && (
                      <MonitorSnippetList
                        mode={mode}
                        page={page}
                        showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
                        searchedDataResults={totalSnippetsCount}
                      />
                    )}

                    {pageCheck && <DefineMonitorSnippetList page={page} mode={mode} />}

                    {page === 'anticipatory-intelligence' && (
                      <AnticipatoryIntelligenceSnippetList mode={mode} page={page} />
                    )}

                    {page === 'executive-intelligence' && <ExecutiveDetailSnippets mode={mode} page={page} />}
                  </div>
                </Spin>
              </>
            )}
          </div>

          <MonitorSurpriseModal
            isVisible={!!surpriseModalData}
            close={() => {
              showSurprise(false)
            }}
            data={surpriseModalData}
          />
        </div>
      )
    }
  }
})
