import { observer } from 'mobx-react-lite'
import { SubStore } from 'types/types'

import SearchInput from 'version2/components/common/Inputs/SearchInput/SearchInput'
import { FilterAndSorterDropdown } from './FilterAndSorterDropdown/FilterAndSorterDropdown'

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'
import { CreatorsList } from './CreatorsList/CreatorsList'

import './Creators.scss'

interface Props {
  subStore: SubStore
}

export const Creators = observer((props: Props) => {
  return (
    <div className='power-insights-creators'>
      <div className='power-insights-creators__header'>
        <SearchInput
          // value={'sd'}
          className='power-insights-creators__search__container'
          // onPressEnter={() => {}}
          // onClear={() => {}}
          placeholder={'Search'}
          // inputClassName=''
          iconClassName={'power-insights-creators__search__icon'}
          prefix={SearchIcon}
          showIcon={true}
          // lastIcon={false}
          // contentClassName=''
          // showDrop={true}
          // children={<></>}
          // isOpen={true}
          // setIsOpen={() => {}}
          // onScroll={() => {}}
          // disabled={false}
          // loading={false}
          onChange={() => {
            // alert()
          }}
        />
        <FilterAndSorterDropdown />
      </div>
      <div className='power-insights-creators__count-div'>
        <span className='power-insights-creators__count-div__title'>Number of creators</span>
        <span className='power-insights-creators__count-div__text'>{`123`}</span>
      </div>
      <CreatorsList />
    </div>
  )
})
