import { observer } from 'mobx-react-lite'
import './BrandsList.scss'
import Table from 'version2/components/common/Table/Table'
import { useMemo } from 'react'
import { Avatar, Radio, Spin } from 'antd'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { DefaultPagination } from 'version2/components/common/Pagination/Pagination'
import { store } from 'store'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import AllPostSection from '../AllPostSection/AllPostSection'

type PropsType = {
  selectedBrand: AssetListObjType | undefined
  setSelectedBrand: (brand: AssetListObjType) => void
}
const BrandsList = observer((props: PropsType) => {
  const { setSelectedBrand, selectedBrand } = props
  const { assetsStore } = store
  const { assetsLoading, assetsList, paginationValues, fetchAssetsList, setPaginationValues, snippetsFilter } =
    assetsStore
  const selectedRows = selectedBrand ? [selectedBrand.id] : []

  const onChangeBrandSelection = (brand: AssetListObjType) => setSelectedBrand(brand)

  const columns: any = useMemo(() => {
    return [
      {
        key: 'name',
        title: '',
        render: (_: any, item: AssetListObjType) => {
          return (
            <div className='brand_info'>
              <Radio checked={item.id === selectedBrand?.id} onChange={() => onChangeBrandSelection(item)} />
              <div className='image'>
                <Avatar src={profileImage} />
              </div>
              <span className='brand_name'>{item.name}</span>
            </div>
          )
        },
      },

      {
        key: 'all_posts',
        title: '',
        width: 205,
        render: (_: any, item: AssetListObjType) => {
          return (
            <div className='mentions'>
              <span className='text'>Mentions</span>
              <AllPostSection itemData={item} subStore='assets' />
            </div>
          )
        },
      },
      {
        key: 'flag_posts',
        title: '',
        width: 205,
        render: (_: any, item: AssetListObjType) => {
          return (
            <div className='mentions'>
              <span className='text'>Views</span>
              <AllPostSection itemData={item} subStore='assets' measure='impression' />
            </div>
          )
        },
      },
    ]
  }, [selectedBrand, assetsList.items, snippetsFilter])

  return (
    <>
      <span className='monitor-assets__graph__title'>Brands</span>
      <Spin spinning={assetsLoading}>
        <Table data={assetsList.items} columns={columns} className='brands_table' selectedRows={selectedRows} />
        {!assetsLoading && assetsList.items.length === 0 && <span>No active brands to display</span>}
        <div className='am_dashboard_pagination_container'>
          {assetsList.total_count >= 10 && (
            <DefaultPagination
              className='amc_pagination'
              totalCount={assetsList.total_count}
              currentPage={paginationValues.page}
              pageSize={paginationValues.pageSize}
              showPageSizeChanger={false}
              nextIcon={ArrowNextIcon}
              prevIcon={ArrowNextIcon}
              iconHeight={20}
              iconWidth={20}
              onChange={(page, pageSize) => {
                let requestParams: AssetListParamsType = {
                  page,
                  per_page: pageSize,
                  sort: 'name:asc',
                  is_asset: true,
                  is_active: true,
                }
                setPaginationValues({ page, pageSize })
                fetchAssetsList({ params: requestParams })
              }}
              locale={{ items_per_page: '' }}
            />
          )}
        </div>
      </Spin>
    </>
  )
})

export default BrandsList
