import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { store } from 'store'

import { IMonitorModeDetail, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import { ReactComponent as MLIcon } from 'assets/images/icon_ml_narrative.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-narrative-icon.svg'
import { ReactComponent as RemoveIcon } from 'assets/images/delete-icon.svg'
import { ReactComponent as GrowthDetectedIcon } from 'assets/images/positive-growth-icon.svg'
import { ReactComponent as GrowthFetchingIcon } from 'assets/images/fetching-data-icon.svg'
import { ReactComponent as GrowthNotDetectedIcon } from 'assets/images/growth-not-detected.svg'
import { ReactComponent as FetchingIcon } from 'assets/images/fetching-page-icon.svg'

import './ListItem.scss'

interface Props {
  page: monitorMode
  mode: 'Narratives' | 'Watchlist' | 'Communities' | 'Tags' | 'TagDetail' | 'Bookmarks'
  tagEditMode?: boolean
  actionTagId?: string
  listName: 'User' | 'Suggestions' | 'Followed' | 'All' | 'NarrativeLibrary' | ''
  data: IMonitorModeDetail
  isSelected: boolean
}

export const ListItem = observer(({ page, mode, tagEditMode, actionTagId, listName, data, isSelected }: Props) => {
  const lookUpDictionary: {
    Tags: 'tag'
    Narratives: 'narrative'
    Watchlist: 'watchlist'
    Communities: 'community'
    Bookmarks: 'bookmark'
  } = {
    Tags: 'tag',
    Narratives: 'narrative',
    Watchlist: 'watchlist',
    Communities: 'community',
    Bookmarks: 'bookmark',
  }

  const {
    growthData,
    isPowerInsightsOpen,
    fetchPowerInsights,
    fetchSnippetsInfo,
    setSelectedCommunityInfo,
    actionTag,
    resetSnippetFilters,
  } = store[`monitor${STORE_LOOKUP_DICTIONARY[page]}`]
  const { updateTagStatus } = store['monitorTags']

  const navigate = useNavigate()
  const iconsObj = {
    NLP: <MLIcon className='monitor-snippet-item__footer__left__app-icon' />,
    KEYWORD_ONLY: <></>,
    TEXTUAL: <TwitterIcon className='monitor-snippet-item__footer__left__app-icon' />,
  }
  const growthInfo = {
    true: {
      icon: <GrowthDetectedIcon />,
      title: 'Growth detected',
    },
    fetching: {
      icon: <GrowthFetchingIcon />,
      title: 'Pendulum is collecting data for this narrative. You will receive an email notification when complete',
    },
    false: {
      icon: <GrowthNotDetectedIcon />,
      title: 'No growth detected',
    },
  }

  const iconsDescription = {
    NLP: 'Created Via ML',
    KEYWORD_ONLY: '',
    TEXTUAL: 'Created Via Text',
  }

  const onFollow = () => {
    try {
      updateTagStatus(data.id, !data.is_followed)
    } catch (e) {}
  }

  const removeTag = () => {
    const type = mode !== 'TagDetail' ? lookUpDictionary[mode] : 'tag'
    actionTag && actionTag({ action: 'remove', id: data.id, tagId: actionTagId || '', type: type })
  }

  const getDateRange = (): { startDate: string; endDate: string; numberOfDays: number } => {
    const TIME_FORMAT = 'YYYY-MM-DD'

    const endDate = dayjs()
    const startDate = endDate.subtract(3, 'year')

    const numberOfDays = endDate.diff(startDate, 'day')

    return {
      startDate: startDate.format(TIME_FORMAT),
      endDate: endDate.format(TIME_FORMAT),
      numberOfDays,
    }
  }

  const getGrowthInfo = () => {
    if (data.idNumber && growthData) {
      const growth = growthData[data.idNumber]
      let info
      switch (growth) {
        case 'fetch':
          info = growthInfo['fetching']
          break
        case true:
          info = growthInfo['true']
          break
        case false:
          info = growthInfo['false']
          break
        default:
          info = growthInfo['false']
          break
      }
      let title
      if (growth === 'fetch') {
        title = (
          <div className='list-item__left__tooltip--title'>
            <div className='list-item__left__tooltip--heading'>
              <FetchingIcon />
              <span className='list-item--margin'>Data collection in Progress</span>
            </div>
            <span>{info?.title}</span>
          </div>
        )
      } else {
        title = (
          <div className='list-item__left__tooltip--title'>
            <span>{info?.title}</span>
          </div>
        )
      }
      return (
        <Tooltip
          title={title}
          placement={'right'}
          color='#000'
          overlayClassName={classNames('list-item__left__tooltip', {
            'list-item__tooltip-fetch': growth === 'fetch',
          })}>
          {info?.icon}
        </Tooltip>
      )
    }
  }

  return (
    <div
      className={classNames('list-item', { 'list-item__list--active': isSelected })}
      onClick={() => {
        if (mode === 'Bookmarks') resetSnippetFilters(getDateRange())
        else resetSnippetFilters()
        setSelectedCommunityInfo && setSelectedCommunityInfo(undefined)
        if (isPowerInsightsOpen) fetchPowerInsights(isSelected ? '-1' : data.id, listName)
        else fetchSnippetsInfo(isSelected ? '-1' : data.id, listName)
      }}>
      <p className='list-item__title'>
        {tagEditMode && (
          <div className='list-item__title__remove-icon' onClick={removeTag}>
            <RemoveIcon />
          </div>
        )}
        {data?.name}
        {data?.narrativeType?.length && (
          <div>
            <Tooltip
              overlayClassName='list-item__tooltip'
              title={<span className='list-item__title__tooltip '>{iconsDescription[data.narrativeType]}</span>}
              className='list-item__title--color'
              color={'black'}
              placement='right'>
              {iconsObj[data.narrativeType]}
            </Tooltip>
          </div>
        )}
      </p>
      <div className='list-item__left'>
        {mode === 'Tags' && (
          <span className='list-item__details-tag' onClick={onFollow}>
            {!data.is_followed ? 'follow' : 'unfollow'}
          </span>
        )}
        <span
          className={classNames('list-item__details-tag', {
            'list-item__details-tag--selected': isSelected,
          })}
          onClick={(e) => {
            e.stopPropagation()
            if (mode === 'Bookmarks') resetSnippetFilters(getDateRange())
            else resetSnippetFilters()
            if (mode === 'Tags') {
              navigate(`/monitor/${mode.toLowerCase()}/${data.id}?list=${listName}`)
            } else if (mode === 'TagDetail') {
              navigate(`/monitor/${mode.toLowerCase()}/${data.id}`)
            } else if (mode === 'Bookmarks') {
              navigate(`/investigate/${mode.toLowerCase()}/${data.id}?list=${listName}`)
            } else navigate(`/monitor/${mode.toLowerCase()}/${data.id}?list=${listName}`)
          }}>
          {mode === 'Tags' ? 'details' : 'view'}
        </span>
        {mode === 'Narratives' && getGrowthInfo()}
      </div>
    </div>
  )
})
