import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { NarrativeListDataType } from 'store/monitorVersion2/types'
import profileImage from 'assets/images/LogoiconMobile.svg'
import './ExtraInfoPopup.scss'
import dayjs from 'dayjs'

type Props = { data: NarrativeListDataType; className?: string }

const DATE_FORMATE = 'DD/MM/YYYY'

const ExtraInfoPopup = observer((props: Props) => {
  const { data, className = '' } = props
  const { userStore } = store
  const { tenantInfo } = userStore

  const isPendulum = (data: NarrativeListDataType) => {
    if (data.is_pendulum_narrative === undefined) {
      return data.tenant_id !== tenantInfo.id
    }
    return data.is_pendulum_narrative === 'True'
  }

  return (
    <div className={`extra_info_popup_container ${className}`}>
      <p className='exipc_title'>{data.name}</p>
      <p className='exipc_desc_text'>Description</p>
      <p className='exipc_desc'>{data.description || '-'}</p>
      <p className='exipc_creator_text'>Creator</p>
      <div className='exipc_creator_info'>
        <div className='exipc_ci_img_container'>
          <img
            className='exipc_ci_ic_image'
            src={isPendulum(data) ? profileImage : tenantInfo.logo_url ?? profileImage}
            alt={data.name}
            onError={(e: any) => {
              e.target.src = profileImage
            }}
          />
        </div>
        <div className='exipc_ci_content'>
          <p className='exipc_ci_c_name'>{isPendulum(data) ? 'Pendulum' : tenantInfo.name}</p>
          <p className='exipc_ci_c_created_date'>{dayjs(data.created).format(DATE_FORMATE)}</p>
        </div>
      </div>
    </div>
  )
})

export default ExtraInfoPopup
