import { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import millify from 'millify'
import { Spin } from 'antd'
import { store } from 'store'

import { ReactComponent as ConfigureIcon } from 'assets/images/configure-icon.svg'

import { IAttentionFlagsMenu, monitorMode, monitorSnippetPage } from 'models/models'
import { STORE_LOOKUP_DICTIONARY, ATTENTION_FLAG_INSIGHT_TYPES } from 'settings/settings'

import styles from './powerInsightAttentionFlags.module.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const PowerInsightAttentionFlags: React.FC<Props> = observer(({ mode, page }) => {
  const { userFlagsAsObject: attentionFlags, flagPostCount, fetchFlagPostCount, fetchFlags } = store.attentionFlagsStore
  const {
    activeItem,
    snippetsFilter,
    powerInsightsFilterChips,
    setSnippetsFilter,
    snippetFilterPayload,
    fetchPowerInsights,
    setPowerInsightsFilterChips,
  } = page === 'define' ? store['defineNarrativeStoreV1'] : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]

  const { isLoadingNoOfPosts } = store.loaderStore

  useEffect(() => {
    fetchFlags().then(() => {
      snippetFilterPayload().then((response) => {
        fetchFlagPostCount(response)
      })
    })
  }, [snippetsFilter, activeItem])

  const isFlagSelected = useCallback(
    (flag: IAttentionFlagsMenu) => {
      if (flag)
        return Object.keys(snippetsFilter?.attentionFlags || {}).includes(
          `${flag.id},${ATTENTION_FLAG_INSIGHT_TYPES[flag.insightType]}`,
        )
      else return false
    },
    [snippetsFilter?.attentionFlags],
  )

  const filterData = (flag: IAttentionFlagsMenu) => {
    const typeLabel = ATTENTION_FLAG_INSIGHT_TYPES[flag.insightType]
    let ids: string | string[] = flag.insightCondition.toString()

    if (isFlagSelected(flag)) {
      let flags = { ...snippetsFilter.attentionFlags }
      delete flags[`${flag.id},${typeLabel}`]
      setSnippetsFilter({ ...snippetsFilter, attentionFlags: flags })
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        attentionFlags: {
          ...powerInsightsFilterChips.attentionFlags,
          value: '',
        },
      })
    } else {
      if (flag.insightType !== 'SEARCH_TERM') {
        ids = ids.replace('{', '').replace('}', '').split(',')
      }
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        attentionFlags: {
          ...powerInsightsFilterChips.attentionFlags,
          value: flag.displayLabel,
        },
      })
      setSnippetsFilter({ ...snippetsFilter, attentionFlags: { [`${flag.id},${typeLabel}`]: ids } })
    }
    fetchPowerInsights(activeItem?.id ?? '', '')
  }

  return (
    <Spin spinning={isLoadingNoOfPosts}>
      <div className={styles.attentionFlagsSectionWrapper}>
        <div className={styles.sectionHeader}>
          <div className={styles.status}>
            {Object.keys(flagPostCount.active)?.length > 0 ? 'Active Attention Flags' : ''}
          </div>
          <Link to='/settings/power-insights' className={styles.configure}>
            <ConfigureIcon />
            <span>Configure</span>
          </Link>
        </div>
        {Object.keys(flagPostCount.active)?.length > 0 && (
          <ul className={styles.attentionFlagsList}>
            {Object.keys(flagPostCount.active)?.map((key) => {
              return (
                <li
                  key={attentionFlags[key].displayLabel}
                  className={classNames(styles.singleFlag, {
                    [styles.flagSelected]: isFlagSelected(attentionFlags[key]),
                  })}
                  onClick={() => {
                    filterData(attentionFlags[key])
                  }}>
                  <div className={styles.content}>
                    <h2 className={styles.heading}>{attentionFlags[key].displayLabel}</h2>
                    <span className={styles.postsCount}>{millify(flagPostCount?.active[key])} posts</span>
                  </div>
                </li>
              )
            })}
          </ul>
        )}
        {Object.keys(flagPostCount.inactive)?.length > 0 && (
          <div className={classNames(styles.status, styles.inactiveHead)}>Inactive Attention Flags</div>
        )}
        <ul className={styles.attentionFlagsList}>
          {Object.keys(flagPostCount.inactive)?.map((key) => {
            return (
              <li
                key={attentionFlags[key].displayLabel}
                className={classNames(
                  styles.singleFlag,
                  {
                    [styles.flagSelected]: isFlagSelected(attentionFlags[key]),
                  },
                  styles.inactiveFlag,
                )}
                onClick={() => {
                  filterData(attentionFlags[key])
                }}>
                <div className={styles.content}>
                  <h2 className={styles.heading}>{attentionFlags[key].displayLabel}</h2>
                  <span className={styles.postsCount}>{millify(flagPostCount?.inactive[key])} posts</span>
                </div>
              </li>
            )
          })}

          <li className={styles.addFlagBtn}>
            <Link to='/settings/power-insights'>
              <div className={styles.content}>
                <h2 className={styles.heading}>+ Add New</h2>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </Spin>
  )
})
