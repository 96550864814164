import { Menu } from 'antd'
import { Auth } from 'aws-amplify'
import { observer } from 'mobx-react-lite'
import { useLocation, Link, useNavigate } from 'react-router-dom'

import { NavViewType } from 'models/models'

import { ReactComponent as IconDropdownMenu } from 'assets/images/icon-dropmenu.svg'
import { ReactComponent as IconPendulumLogo } from 'assets/images/pendulum.svg'

import { store } from 'store'

import './Navigation.scss'

export const Navigation = observer((): JSX.Element => {
  const { userStore, navViewStore } = store
  const { userName, currentRole } = userStore

  const location = useLocation()
  const navigate = useNavigate()
  let selectedKeys: string[] = ['1']

  if (location.pathname.includes('/dashboard')) selectedKeys = ['0']
  else if (location.pathname.includes('/v0/monitor')) selectedKeys = ['1']
  else if (location.pathname.includes('/manage')) selectedKeys = ['2']
  else if (location.pathname.includes('/analyze')) selectedKeys = ['3']
  else if (location.pathname.includes('/executive-intelligence')) selectedKeys = ['4']
  else if (location.pathname.includes('/investigate')) selectedKeys = ['5']

  async function signOut() {
    await Auth.signOut()

    userStore.setUser({
      given_name: null,
      family_name: null,
      userName: null,
      userEmail: null,
      tenantId: null,
      userId: null,
      roleId: null,
      subId: null,
    })

    navigate('/', { replace: true })
  }

  function changeNavView(viewType: NavViewType) {
    navViewStore.setViewType(viewType)
  }

  return (
    <nav className='c-navigation'>
      <div className='c-navigation__container'>
        <Link to='/dashboard' className='c-navigation__logo'>
          <IconPendulumLogo />
        </Link>

        <Menu
          mode='horizontal'
          selectedKeys={selectedKeys}
          triggerSubMenuAction='click'
          className='c-navigation__buttons'>
          <Menu.Item key='0'>
            <Link to='/dashboard'>Dashboard</Link>
          </Menu.Item>
          {currentRole !== 'VIEWER' && (
            <>
              <Menu.Item key='1'>
                <Link to='/monitor'>Monitor</Link>
              </Menu.Item>
              <Menu.Item key='2'>
                <Link to='/manage'>Manage</Link>
              </Menu.Item>
            </>
          )}

          <Menu.Item key='3'>
            <Link to='/analyze'>Analyze</Link>
          </Menu.Item>
          <>
            <Menu.Item key='4'>
              <Link to='/executive-intelligence'>Executive intelligence</Link>
            </Menu.Item>
          </>

          <Menu.Item key='5'>
            <Link to='/investigate'>Investigate</Link>
          </Menu.Item>
        </Menu>
        <Menu mode='horizontal' className='c-navigation__submenu' triggerSubMenuAction={'click'}>
          <Menu.SubMenu
            icon={<IconDropdownMenu />}
            key='SubMenu'
            title={userName}
            popupClassName='c-navigation__submenu-popup'
            popupOffset={[-60, 18]}>
            <Menu.Item key='SubMenu1'>
              <Link to='/settings/profile'>Settings</Link>
            </Menu.Item>

            <Menu.Item key='SubMenu3' onClick={signOut}>
              Sign out
            </Menu.Item>
            <Menu.Item key='SubMenu2' onClick={() => changeNavView('leftView')}>
              Switch to Left Nav view
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    </nav>
  )
})
