import { useEffect, RefObject } from 'react'

const useClickAway = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: (event: Event) => void) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current
      if (!el || el.contains((event?.target as Node) || null)) {
        return
      }

      handler(event) // Call the handler only if the click is outside of the element passed.
    }

    document.addEventListener('click', listener, true)

    return () => {
      document.removeEventListener('click', listener, true)
    }
  }, [ref, handler]) // Reload only if ref or handler changes
}

export default useClickAway
