import { Fragment, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import millify from 'millify'

import { store } from 'store'
import { monitorMode, monitorSnippetPage } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import { ReactComponent as SummaryIcon } from 'assets/images/anticipatory-summary-icon.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as DislikeFilledIcon } from 'assets/images/dislike-thumb-solid-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as LikeFilledIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warning-triangle.svg'

import styles from './executiveSummary.module.scss'
import PendulumLoader from 'version2/components/PendulumLoader/PendulumLoader'

interface ExecutiveSummaryProps {
  mode: monitorMode
  page: monitorSnippetPage
}

export const ExecutiveSummary: React.FC<ExecutiveSummaryProps> = observer(({ mode, page }) => {
  const { isLoadingSummary } = store.loaderStore
  const { powerInsightsData, setPowerInsightsData, fetchSummaryData, snippetFilterPayload } =
    page === 'define' ? store['defineNarrativeStoreV1'] : store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { summary: summaryData } = powerInsightsData

  const [copilotSummaryFeedback, setCopilotSummaryFeedback] = useState<null | 'like' | 'dislike'>(null)
  const [isExecutiveSummaryTooLong, setIsExecutiveSummaryTooLong] = useState(false)
  const [showFullExecutiveSummary, setShowFullExecutiveSummary] = useState(false)
  const [fullExecutiveSummaryParsed, setFullExecutiveSummaryParsed] = useState<React.ReactNode[]>([])
  const [partialExecutiveSummaryParsed, setPartialExecutiveSummaryParsed] = useState<React.ReactNode[]>([])

  const toggleFullExecutiveSummaryHandler = useCallback(() => {
    if (!isExecutiveSummaryTooLong) {
      return
    }

    setShowFullExecutiveSummary((prevShow) => !prevShow)
  }, [isExecutiveSummaryTooLong])

  useEffect(() => {
    setFullExecutiveSummaryParsed(
      summaryData.executiveSummary.split('\n').map((substring, index) => (
        <Fragment key={index}>
          {index > 0 && <br />} {/* Add <br /> after the first line */}
          {substring}
        </Fragment>
      )),
    )

    setPartialExecutiveSummaryParsed(
      summaryData.executiveSummary
        .slice(0, 500)
        .split('\n')
        .map((substring, index) => (
          <Fragment key={index}>
            {index > 0 && <br />} {/* Add <br /> after the first line */}
            {substring}
          </Fragment>
        )),
    )

    if (summaryData.executiveSummary.length > 500) {
      setIsExecutiveSummaryTooLong(true)
    } else {
      setIsExecutiveSummaryTooLong(false)
    }
  }, [summaryData.executiveSummary])

  const retrySummary = async () => {
    const payload = await snippetFilterPayload()
    fetchSummaryData(payload).then((summaryStatisticsData) => {
      setPowerInsightsData({
        ...powerInsightsData,
        summary: {
          executiveSummary: summaryStatisticsData.executiveSummary,
          statistics: [
            {
              label: 'Snippets',
              value: millify(summaryStatisticsData.statistics?.total_snippets),
            },
            {
              label: 'Unique Creators',
              value: millify(summaryStatisticsData.statistics?.creator_count),
            },
            {
              label: 'Companies',
              value: millify(summaryStatisticsData.statistics?.company_count),
            },
            {
              label: 'People',
              value: millify(summaryStatisticsData.statistics?.people_count),
            },
            {
              label: 'Organizations',
              value: millify(summaryStatisticsData.statistics?.country_count),
            },
            {
              label: 'Views',
              value: millify(summaryStatisticsData.statistics?.total_views),
            },
          ],
          platformsDistribution: summaryStatisticsData.platformsDistribution,
        },
      })
    })
  }

  return (
    <div className={styles.executiveSummary}>
      <div className={styles.heading}>
        <h4>
          Executive Summary <SummaryIcon />
        </h4>

        <span className={styles.feedbackIcons}>
          <Tooltip placement='bottom' overlayClassName='pendulum-themed-tooltip' title='Dislike'>
            {copilotSummaryFeedback === 'dislike' ? (
              <DislikeFilledIcon
                className={classNames(styles.icon, {
                  [styles.iconFilledDisabled]: isLoadingSummary || !summaryData.executiveSummary.length,
                })}
                onClick={() => !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback(null)}
              />
            ) : (
              <DislikeIcon
                className={classNames(styles.icon, {
                  [styles.iconDisabled]: isLoadingSummary || !summaryData.executiveSummary.length,
                })}
                onClick={() =>
                  !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback('dislike')
                }
              />
            )}
          </Tooltip>

          <Tooltip placement='bottom' overlayClassName='pendulum-themed-tooltip' title='Like'>
            {copilotSummaryFeedback === 'like' ? (
              <LikeFilledIcon
                className={classNames(styles.icon, {
                  [styles.iconFilledDisabled]: isLoadingSummary || !summaryData.executiveSummary,
                })}
                onClick={() => !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback(null)}
              />
            ) : (
              <LikeIcon
                className={classNames(styles.icon, {
                  [styles.iconDisabled]: isLoadingSummary || !summaryData.executiveSummary,
                })}
                onClick={() => !isLoadingSummary && summaryData.executiveSummary && setCopilotSummaryFeedback('like')}
              />
            )}
          </Tooltip>
        </span>
      </div>

      {isLoadingSummary ? (
        <PendulumLoader />
      ) : summaryData.executiveSummary.length ? (
        <div className={classNames(styles.summaryText)}>
          {isExecutiveSummaryTooLong
            ? showFullExecutiveSummary
              ? fullExecutiveSummaryParsed
              : partialExecutiveSummaryParsed.concat('...')
            : fullExecutiveSummaryParsed}

          {isExecutiveSummaryTooLong && (
            <span className={styles.readMoreBtn} onClick={toggleFullExecutiveSummaryHandler}>
              &nbsp;Read {showFullExecutiveSummary ? 'less' : 'more'}
            </span>
          )}
        </div>
      ) : (
        <div className={styles.errorScreen}>
          <WarningIcon />
          <span className={styles.message}>{'Oops, Something went wrong'}</span>
          <span className={styles.description}>{'Failed to load the summary. Please try again now or later.'}</span>
          <button className={styles.retry} onClick={retrySummary}>
            Retry
          </button>
        </div>
      )}
    </div>
  )
})
