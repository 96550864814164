import React, { useState, useRef } from 'react'
import useClickAway from 'hooks/useClickAway'
import { ReactComponent as DotsIcon } from 'assets/images/dots-vertical-small.svg'
import { ReactComponent as ClockIcon } from 'assets/images/clock.svg'
import styles from './NextUpdate.module.scss'
import Button from 'version2/components/common/Buttons/Button/Button'
import classNames from 'classnames'

type DropdownProps = {
  value?: string
  onClick?: (val: string) => void
  className?: string
  showIcon?: boolean
}

function NextUpdate(props: DropdownProps) {
  const { value = 'July 31, 2023', onClick, className, showIcon } = props
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClickOutside = () => {
    setIsOpen(false)
  }
  useClickAway(dropdownRef, onClickOutside)

  return (
    <div ref={dropdownRef} className={classNames(styles.dropdown_container, className)}>
      <div className={styles.dc_value_container}>
        <p className={styles.value_text}>{value}</p>
        {showIcon && (
          <DotsIcon
            className={styles.dropdown_icon}
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen((prevState) => !prevState)
            }}
          />
        )}
      </div>
      {isOpen && (
        <div className={styles.dc_content_container} onClick={(event) => event.stopPropagation()}>
          <div className={styles.lc_l_header_container}>
            <ClockIcon />
            <p className={styles.hc_text}>Run Now</p>
          </div>
          <p className={styles.cc_desc}>
            Pendulum lets you run your report anytime, providing content from the last update until the moment you click
            "Run Now."
          </p>
          <div className={styles.cc_button_container}>
            <Button onClick={() => onClick?.(value)} className={styles.cc_button}>
              Run Now
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default NextUpdate
