import { Dropdown } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import classNames from 'classnames'

import { IAttentionFlagsPostCount, INarrativeInfoCategory, IRiskPostCount } from 'models/models'
import { CLOSE_RANGE_INFO, MID_RANGE_INFO } from 'settings/settings'
import { NarrativeInfo } from '../../NarrativeInfo/NarrativeInfo'

import './CategoriesTable.scss'

interface Props {
  data: { attentionFlags: IAttentionFlagsPostCount; risks: IRiskPostCount; categoryName: string }
  topicName: string
}
export const FlagsSection = observer(({ data, topicName }: Props) => {
  const { attentionFlags: attentionFlagsData, risks: risksData, categoryName } = data
  let flags
  let risksFlags

  const { anticipatoryIntelligenceStore } = store
  const { sideDetails, threatTopics, attentionFlags, risks } = anticipatoryIntelligenceStore

  if (attentionFlagsData) {
    const attentionFlagsKeys = Object.keys(attentionFlagsData)

    flags = attentionFlagsKeys?.map((key) => {
      const attentionFlag = attentionFlagsData[key]
      if (attentionFlag?.noOfPosts === 0) return <></>
      const topic = threatTopics[topicName]
      const topicCategory = topic?.categories.find((cat) => cat.name === categoryName)

      const category: INarrativeInfoCategory = {
        id: topicCategory?.id,
        label: topicCategory?.label,
        name: topicCategory?.name,
        subItemName: attentionFlags[key]?.displayLabel || '',
        noOfPosts: attentionFlag?.noOfPosts,
        metricValues: attentionFlag?.metricValues,
        description: topicCategory?.description || '',
        narrative_number: topicCategory?.narrative_number || -1,
        narrative_id: topicCategory?.narrative_id || '',
        flags: attentionFlag?.flags,
        conditions: attentionFlag?.conditions,
      }
      return (
        <Dropdown
          trigger={['click']}
          overlay={
            <NarrativeInfo
              name={topicName}
              category={category}
              url={`tab=close-range&topic=${topicName}&attention-flag=${key}&category=${category.name}`}
              tab={'close-range'}
              isListView={true}
              sideDetailsInfo={{
                topic: topicName,
                category: category.name || '',
                riskOrFlagName: key,
                tab: 'close-range',
                url: `tab=close-range&topic=${topicName}&attention-flag=${key}&category=${category.name}`,
              }}
            />
          }>
          <div
            className={classNames('categories__attention-flag-div__container', {
              'categories__attention-flag-div--selected':
                sideDetails.riskOrFlagName === key && categoryName === sideDetails.category,
            })}
            key={key}>
            <div className='categories__attention-flag-div__icon'>
              {CLOSE_RANGE_INFO.find((item) => item.name === key)?.icon}
            </div>
            <span className='categories__attention-flag-div__count'>{attentionFlag?.noOfPosts}</span>
          </div>
        </Dropdown>
      )
    })
  }
  if (risksData) {
    const risksKeys = Object.keys(risksData)
    risksFlags = risksKeys?.map((key) => {
      const risk = risksData[key]

      if (risk?.noOfPosts === 0) return <></>

      const topic = threatTopics[topicName]
      const topicCategory = topic?.categories.find((cat) => cat.name === categoryName)

      const category: INarrativeInfoCategory = {
        id: topicCategory?.id,
        label: topicCategory?.label,
        name: topicCategory?.name,
        subItemName: risks[key]?.label || '',
        noOfPosts: risk?.noOfPosts,
        metricValues: risk?.metricValues,
        description: topicCategory?.description || '',
        narrative_number: topicCategory?.narrative_number || -1,
        narrative_id: topicCategory?.narrative_id || '',
        flags: risk?.flags,
        conditions: risk?.conditions,
      }

      return (
        <Dropdown
          trigger={['click']}
          overlay={
            <NarrativeInfo
              name={topicName}
              category={category}
              url={`tab=mid-range&topic=${topicName}&risk=${key}&category=${category.name}`}
              tab={'mid-range'}
              isListView={true}
              sideDetailsInfo={{
                topic: topicName,
                category: category.name || '',
                riskOrFlagName: key,
                tab: 'mid-range',
                url: `tab=mid-range&topic=${topicName}&risk=${key}&category=${category.name}`,
              }}
            />
          }>
          <div
            className={classNames('categories__attention-flag-div__container', {
              'categories__attention-flag-div--selected':
                sideDetails.riskOrFlagName === key && categoryName === sideDetails.category,
            })}
            key={key}>
            <div className='categories__attention-flag-div__icon'>
              {MID_RANGE_INFO.find((item) => item.name === key)?.icon}
            </div>
            <span className='categories__attention-flag-div__count'>{risk.noOfPosts}</span>
          </div>
        </Dropdown>
      )
    })
  }

  return (
    <div className='categories__attention-flag-div'>
      {flags}
      {risksFlags}
    </div>
  )
})
