import { observer } from 'mobx-react-lite'

import { ReactComponent as BackBtnIcon } from 'assets/images/back-btn-icon.svg'
import './AttentionFlagsSnippets.scss'
import { AttentionFlagsSnippetList } from '../../../common/Snippets/SnippetList/AttentionFlagsSnippetList'
import { SnippetListHeader } from '../../../common/Snippets/SnippetListHeader/SnippetListHeader'

interface Props {
  setShowSnippets: (state: boolean) => void
}
export const AttentionFlagsSnippets = observer(({ setShowSnippets }: Props) => {
  return (
    <div className='flags-snippets__padding'>
      <div className='flags-snippets__back-btn' onClick={() => setShowSnippets(false)}>
        <BackBtnIcon />
        <span className='flags-snippets__back-btn--title'>Back</span>
      </div>
      <SnippetListHeader mode={'narrative'} page={'flags'} defineSearchTitle={''} />
      <AttentionFlagsSnippetList mode={'narrative'} page={'flags'} />
    </div>
  )
})
