import CustomModal from 'version2/components/common/CustomModal/CustomModal'
import { ReactComponent as InfoIcon } from 'assets/images/icons/flag/info_round_icon.svg'
import './DeleteConfirmationModal.scss'

type DeleteConfirmationModalProps = {
  onContinue: () => void
  onClose: () => void
  title?: string
  desc?: string
  disabledSecondButton?: boolean
}

const DeleteConfirmationModal = (props: DeleteConfirmationModalProps) => {
  const {
    onClose,
    onContinue,
    title = 'Are you sure you want to delete selected flags?',
    desc = 'This action cannot be undone.',
    disabledSecondButton,
  } = props

  return (
    <CustomModal
      icon={InfoIcon}
      iconSize={54}
      title={title}
      secondButtonText='Delete'
      onClose={onClose}
      onConfirm={onContinue}
      disabledSecondButton={disabledSecondButton}
      containerClassName='dcm_containerClassName'
      boxContainerClassName='dcm_box_container'
      bodyClassName='dcm_body_container'>
      <div className='dcm_modal_body_container'>
        <p className='dcm_follow_text'>{desc}</p>
      </div>
    </CustomModal>
  )
}

export default DeleteConfirmationModal
