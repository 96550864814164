import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState, useCallback } from 'react'
import { store } from 'store'
import { AssetListObjType } from 'store/asset/types'
import { CustomPieChart } from 'version2/components/common/PieChart/PieChart'
import { DownloadOption } from '../DownloadOption'
import { SearchConditions, SnippetFilterPayload } from 'models/models'

const PortfolioShareChart = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const {
    assetsList,
    assetsLoading,
    graphColorPalette,
    getDate,
    fetchInsightsMetrics,
    snippetsFilter,
    getSnippetFilterPayload,
  } = store.assetsStore
  const [chartData, setChartData] = useState<{ name: string; total_value: number }[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const createDataForChart = useCallback(async () => {
    setLoading(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const assetsToFetch = assetsList.items.filter((asset: AssetListObjType) => Object.keys(asset.conditions).length > 0)

    //use community,platform,sentiment and flags filter,
    const requestParams = {
      q: `start_date:gte:${getDate.startDate},end_date:lte:${getDate.endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }

    try {
      const responses = await Promise.all(
        assetsToFetch.map((asset) => {
          const conditions = [asset.conditions]
          if (snippetsFilter.flags && Object.keys(snippetsFilter.flags).length > 0) {
            conditions.push(snippetsFilter.flags.conditions as SearchConditions)
          }
          return fetchInsightsMetrics({ params: requestParams, data: { conditions: conditions } })
        }),
      )
      const totalCount = responses.reduce((sum, response) => sum + (response === 'error' ? 0 : response), 0)
      setChartData(
        assetsToFetch.map((asset, index) => ({
          name: asset.name,
          total_value: responses[index] !== 'error' ? responses[index] : 0,
        })),
      )
      setTotalCount(totalCount)
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setLoading(false)
    }
  }, [assetsList.items, getDate])

  useEffect(() => {
    if (!assetsLoading && assetsList.total_count > 0) {
      createDataForChart()
    }
  }, [assetsLoading, assetsList, snippetsFilter])

  return (
    <div id='portfolioChart'>
      <Spin spinning={assetsLoading || loading}>
        <div className='chart-heading'>
          <span className='monitor-assets__graph__title'>Portfolio Share of Voice</span>
          <DownloadOption elementToCapture='#portfolioChart' />
        </div>

        <CustomPieChart
          height={192}
          width={350}
          cx={65}
          data={chartData}
          colorPalette={graphColorPalette.Executives}
          totalCount={totalCount}
          narrativeTotal={totalCount}
          onClickCell={() => {}}
          showLabelsBasedOnSelection
          millifyStats
          customLegendWrapperClass='custom-pie-chart__scrollable'
        />
      </Spin>
    </div>
  )
}

export default observer(PortfolioShareChart)
