import { useState } from 'react'
import { Form, Select, Spin, Tag } from 'antd'
import classNames from 'classnames'
import { useEffectOnce } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { ILightTag, monitorMode } from 'models/models'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import './AddTags.scss'
import { store } from 'store'

interface Props {
  mode: monitorMode
  allTags?: ILightTag[]
  usedTags: string[]
  contentId?: string
  createTag?: (tagLabel: string, id: string, type?: 'tag' | 'community' | 'narrative' | 'channel') => Promise<void>
  actionTag?: ({
    action,
    tagId,
    id,
    type,
  }: {
    action: 'apply' | 'remove'
    tagId: string
    id: string
    type?: 'tag' | 'community' | 'narrative' | 'channel'
  }) => Promise<void>
  setShowAddTagsPage: (state: boolean) => void
}

export const AddTags = observer(
  ({ mode, allTags, usedTags, contentId, createTag, actionTag, setShowAddTagsPage }: Props) => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const colors: any = {
      'c-add-tag__select-wrapper__options--color2': '#fef4d3',
      'c-add-tag__select-wrapper__options--color3': '#f1e1e9',
      'c-add-tag__select-wrapper__options--color4': '#f5dfcc',
      'c-add-tag__select-wrapper__options--color1': '#e7f3e0',
    }
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const [tagsSelected, setTagsSelected] = useState<ILightTag[]>()
    const [newTagsCreated, setNewTagsCreated] = useState<string[]>()

    const { loaderStore } = store
    const { isLoadingCreatorTags } = loaderStore

    useEffectOnce(() => {
      form.setFieldsValue({
        tags: usedTags,
      })
    })

    const onChange = (values: any, option: any) => {
      setSaveButtonDisabled(false)
      const oldTags = allTags?.filter((tag: ILightTag) => values?.some((value: string) => tag.label === value))
      setTagsSelected(oldTags)
      const newTags = values.filter((value: string) => !allTags?.some((tag) => tag.label === value))
      setNewTagsCreated(newTags)
    }

    const onSave = async () => {
      const removedTags = usedTags.filter((value: string) => {
        return tagsSelected?.findIndex((tag: ILightTag) => tag.label === value) === -1
      })
      const newTags = tagsSelected?.filter((tag: ILightTag) => !usedTags?.some((value: string) => tag.label === value))

      if (contentId && actionTag) {
        if (removedTags?.length) {
          for (const key in removedTags) {
            const tagId = allTags?.find((tag: ILightTag) => tag.label === removedTags[key])?.id || ''
            if (tagId) {
              await actionTag({
                action: 'remove',
                tagId: tagId,
                id: contentId,
              })
            }
          }
        }

        if (newTags?.length) {
          for (const key in newTags) {
            await actionTag({
              action: 'apply',
              tagId: newTags[key].id,
              id: contentId,
            })
          }
        }
      }
      if (newTagsCreated?.length && contentId && createTag) {
        for (const key in newTagsCreated) {
          await createTag(newTagsCreated[key], contentId)
        }
      }
      setTagsSelected([])
      setNewTagsCreated([])
      setShowAddTagsPage(false)
      navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode]?.toLowerCase()}/${contentId}`)
    }

    return (
      <div>
        <div className='c-add-tag__select-wrapper'>
          <Spin spinning={isLoadingCreatorTags}>
            <Form form={form}>
              <Form.Item name='tags'>
                <Select
                  className='c-add-tag__select'
                  defaultValue={usedTags}
                  mode={'tags'}
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Search'
                  onChange={onChange}
                  tagRender={(props) => {
                    const { label, closable, onClose }: any = props
                    const className: any = label.props
                    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                      event.preventDefault()
                      event.stopPropagation()
                    }
                    return (
                      <Tag
                        color={colors[className]}
                        onMouseDown={onPreventMouseDown}
                        closable={closable}
                        onClose={onClose}
                        style={{ marginRight: 3 }}
                        className={label?.props?.className || 'c-add-tag__select-wrapper__options--color2'}>
                        {label}
                      </Tag>
                    )
                  }}>
                  <Select.Option className='c-add-tag__select-wrapper__first-option' disabled>
                    Select an option or create one
                  </Select.Option>
                  {allTags?.map((tag, index) => {
                    return (
                      <Select.Option value={tag.label} key={tag.id}>
                        <span
                          className={
                            'c-add-tag__select-wrapper__options--color' + Math.floor(Math.random() * (0 - 4 + 1) + 4)
                          }>
                          {tag.label}
                        </span>
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Spin>
          <div>
            <button
              className={classNames(
                { 'c-add-tag__search-btn--primary': !saveButtonDisabled },
                'c-add-tag__search-btn--result-found',
                'c-add-tag__search-btn',
              )}
              disabled={saveButtonDisabled}
              onClick={() => {
                onSave()
              }}>
              {'Save Tags'}
            </button>
          </div>
        </div>
      </div>
    )
  },
)
