import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { useEffectOnce } from 'react-use'

import { UtilService } from 'services/Util/Util'
import { ContactUs } from '../ContactUs/ContactUs'
import ButtonImage from '../Buttons/ButtonImage/ButtonImage'

import { ReactComponent as ThemeIcon } from 'assets/images/icons/monitor/themes_icon.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/icons/left-navigation/breadcrumb_arrow.svg'
import DefaultUserAvatar from 'assets/images/LogoiconMobile.svg'

import './TopLevelNavigation.scss'

interface Props {
  page: string
}

export const TopLevelNavigation = observer(({ page }: Props) => {
  const [openContactModal, setOpenContactModal] = useState(false)
  const navigate = useNavigate()
  const {
    userStore,
    tenantsStore,
    executiveIntelligence,
    defineNarrativeStore,
    defineWatchlistStore,
    anticipatoryIntelligenceStore,
    monitorTags,
    monitorNarratives,
    monitorWatchlist,
    monitorCommunities,
    monitorBookmarks,
    reportMainViewStore,
    monitorStorev1,
    mainStore,
    breadcrumbStore,
    assetsStore,
    vectorsStore,
  } = store
  const { breadcrumbData, setBreadcrumbData, setRootPage } = breadcrumbStore
  const { fullName, userInfo, tenantInfo, getUserInfo, fetchTenantInfo } = userStore
  const {
    snippetsFilter: eiSnippetsFilter,
    setSnippetsFilter: setEiSnippetsFilter,
    setDefaultPlatforms: setEiDefaultPlatforms,
  } = executiveIntelligence
  const {
    snippetsFilter: dnsSnippetsFilter,
    setSnippetsFilter: setDnsSnippetsFilter,
    setDefaultPlatforms: setDnsDefaultPlatforms,
  } = defineNarrativeStore
  const {
    snippetsFilter: dwsSnippetsFilter,
    setSnippetsFilter: setDwsSnippetsFilter,
    setDefaultPlatforms: setDwsDefaultPlatforms,
  } = defineWatchlistStore
  const {
    snippetsFilter: aisSnippetsFilter,
    setSnippetsFilter: setAisSnippetsFilter,
    setDefaultPlatforms: setAisDefaultPlatforms,
  } = anticipatoryIntelligenceStore
  const {
    snippetsFilter: mtSnippetsFilter,
    setSnippetsFilter: setMtSnippetsFilter,
    setDefaultPlatforms: setMtDefaultPlatforms,
  } = monitorTags
  const {
    snippetsFilter: mnSnippetsFilter,
    setSnippetsFilter: setMnSnippetsFilter,
    setDefaultPlatforms: setMnDefaultPlatforms,
  } = monitorNarratives
  const {
    snippetsFilter: mwSnippetsFilter,
    setSnippetsFilter: setMwSnippetsFilter,
    setDefaultPlatforms: setMwDefaultPlatforms,
  } = monitorWatchlist
  const {
    snippetsFilter: mcSnippetsFilter,
    setSnippetsFilter: setMcSnippetsFilter,
    setDefaultPlatforms: setMcDefaultPlatforms,
  } = monitorCommunities
  const {
    snippetsFilter: mbSnippetsFilter,
    setSnippetsFilter: setMbSnippetsFilter,
    setDefaultPlatforms: setMbDefaultPlatforms,
  } = monitorBookmarks
  const {
    snippetsFilter: ms1SnippetsFilter,
    setSnippetsFilter: setMs1SnippetsFilter,
    setDefaultPlatforms: setMs1DefaultPlatforms,
  } = monitorStorev1
  const {
    snippetsFilter: msSnippetsFilter,
    setSnippetsFilter: setMsSnippetsFilter,
    setDefaultPlatforms: setMsDefaultPlatforms,
  } = mainStore
  const {
    snippetsFilter: asSnippetsFilter,
    setSnippetsFilter: setAsSnippetsFilter,
    setDefaultPlatforms: setAsDefaultPlatforms,
  } = assetsStore
  const {
    snippetsFilter: vsSnippetsFilter,
    setSnippetsFilter: setVsSnippetsFilter,
    setDefaultPlatforms: setVsDefaultPlatforms,
  } = vectorsStore

  const { getDefaultPlatformsList, changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { feedsFilters, setFeedsFilters } = reportMainViewStore

  const platformsListHandler = async () => {
    const response = await getDefaultPlatformsList()
    const defaultFilterPlatforms = response?.join(',')

    setEiSnippetsFilter({
      ...eiSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setDnsSnippetsFilter({
      ...dnsSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setDwsSnippetsFilter({
      ...dwsSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setAisSnippetsFilter({
      ...aisSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setMtSnippetsFilter({
      ...mtSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setMnSnippetsFilter({
      ...mnSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setMwSnippetsFilter({
      ...mwSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setMcSnippetsFilter({
      ...mcSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setMbSnippetsFilter({
      ...mbSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setMs1SnippetsFilter({
      ...ms1SnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setFeedsFilters({
      ...feedsFilters,
      platforms: defaultFilterPlatforms,
    })
    setMsSnippetsFilter({
      ...msSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setAsSnippetsFilter({
      ...asSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setVsSnippetsFilter({
      ...vsSnippetsFilter,
      filter_platform: defaultFilterPlatforms,
    })
    setEiDefaultPlatforms(defaultFilterPlatforms)
    setDnsDefaultPlatforms(defaultFilterPlatforms)
    setDwsDefaultPlatforms(defaultFilterPlatforms)
    setAisDefaultPlatforms(defaultFilterPlatforms)
    setMtDefaultPlatforms(defaultFilterPlatforms)
    setMnDefaultPlatforms(defaultFilterPlatforms)
    setMwDefaultPlatforms(defaultFilterPlatforms)
    setMcDefaultPlatforms(defaultFilterPlatforms)
    setMbDefaultPlatforms(defaultFilterPlatforms)
    setMs1DefaultPlatforms(defaultFilterPlatforms)
    setMsDefaultPlatforms(defaultFilterPlatforms)
    setAsDefaultPlatforms(defaultFilterPlatforms)
    setVsDefaultPlatforms(defaultFilterPlatforms)
  }

  useEffectOnce(() => {
    getUserInfo()
    platformsListHandler()
  })

  useEffect(() => {
    fetchTenantInfo()
  }, [userInfo.tenant_id])

  useEffect(() => {
    var s = document.createElement('script')
    s.setAttribute('data-account', 'OLkl4AVAwG')
    s.setAttribute('src', 'https://cdn.userway.org/widget.js')
    ;(document.body || document.head).appendChild(s)

    const custom_trigger = document.querySelector('#accessibility_menu')
    const tempWindow: any = window
    let UserWay = tempWindow.UserWay
    if (custom_trigger) {
      if (!custom_trigger.getAttribute('data-uw-trigger')) {
        if (UserWay) {
          UserWay.iconVisibilityOff()
          custom_trigger.addEventListener('click', (e) => {
            UserWay.widgetOpen()
          })
          custom_trigger.setAttribute('data-uw-trigger', 'true')
          custom_trigger.setAttribute('data-uw-ignore-s25', 'true')
          custom_trigger.setAttribute('aria-haspopup', 'dialog')
        }
      }
    }
  }, [])

  useEffect(() => {
    if (page) {
      setRootPage(page)
      setBreadcrumbData([{ id: UtilService.generateUuid(), title: page }])
    }
  }, [page])

  return (
    <>
      <div className='toplevel-navigation'>
        <div className='toplevel-navigation__logo-container'>
          <div className='toplevel-navigation__logo-container__left'>
            {breadcrumbData.length &&
              breadcrumbData.map((crumb, index) => (
                <div key={crumb.id} className='toplevel-navigation__logo-container__left__heading_container'>
                  {index > 0 ? (
                    <ArrowIcon className='toplevel-navigation__logo-container__left__heading_container--icon' />
                  ) : null}
                  {typeof crumb.title === 'string' ? (
                    <p className='toplevel-navigation__logo-container__left__heading_container--text'>{crumb.title}</p>
                  ) : (
                    crumb.title
                  )}
                </div>
              ))}
          </div>

          <div className='toplevel-navigation__logo-container__right'>
            <div className='toplevel-navigation__logo-container__right__container'>
              {page !== 'Vectors Library' ? (
                <ButtonImage
                  className='toplevel-navigation__logo-container__right__container__vector_button'
                  icon={ThemeIcon}
                  onClick={() => {
                    navigate('/library')
                  }}>
                  {changeLabelTextBasedOnTheme('Vectors Library', isCorporateCommunicationsTheme)}
                </ButtonImage>
              ) : null}
              <span
                id='accessibility_menu'
                tabIndex={0}
                className='toplevel-navigation__logo-container__right__container__contact__label'>
                Accessibility Menu
              </span>
              <div className='toplevel-navigation__logo-container__right__container__contact'>
                <span
                  className='toplevel-navigation__logo-container__right__container__contact__label'
                  onClick={() => setOpenContactModal(!openContactModal)}>
                  Contact Us
                </span>
              </div>
              <div className='toplevel-navigation__logo-container__right__container__tenant'>
                <img
                  src={tenantInfo.logo_url ?? DefaultUserAvatar}
                  alt=''
                  onError={(e: any) => {
                    e.target.src = DefaultUserAvatar
                  }}
                />
              </div>
            </div>

            <div className='toplevel-navigation__logo-container__right__user'>
              <img
                className='toplevel-navigation__logo-container__right__user__avatar-img'
                src={userInfo.avatar_url ?? DefaultUserAvatar}
                alt=''
                onError={(e: any) => {
                  e.target.src = DefaultUserAvatar
                }}
              />

              <div className='toplevel-navigation__logo-container__right__user__name-with-title'>
                <span className='toplevel-navigation__logo-container__right__user__name-with-title__name'>
                  {fullName}
                </span>
                <span className='toplevel-navigation__logo-container__right__user__name-with-title__title'>
                  {userInfo?.role?.length > 0
                    ? userInfo?.role[0].toLocaleUpperCase() + userInfo.role.substring(1).toLowerCase()
                    : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactUs openModal={openContactModal} setOpenModal={setOpenContactModal} />
    </>
  )
})
