import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import CustomModal from 'version2/components/common/CustomModal/CustomModal'
import ShareWith from '../ShareWith/ShareWith'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/deletesection.svg'
import { ReactComponent as DownArrowIcon } from 'assets/images/icons/downArrowGrey.svg'
import profileImage from 'assets/images/LogoiconMobile.svg'
import './DeleteReportModal.scss'
import { UtilService } from 'services/Util/Util'
import { SharedUsersObj } from 'store/report/valueTypes'
import DeleteSectionsforReport from 'version2/components/common/Modal/DeleteSectionsforReport/DeleteSectionsforReport'
import { Spin } from 'antd'
import TagManager from 'react-gtm-module'

type DeleteReportModalProps = {
  onDelete: () => void
  onClose: () => void
  reportId?: string
  link?: string
  shortLink?: string
}

type ShareUsersDetailProps = {
  data: SharedUsersObj[]
  isHidden?: boolean
  className?: string
}

function SharedUsersDetails(props: ShareUsersDetailProps) {
  const { data, isHidden, className } = props

  return (
    <div className='shared_users_details_container'>
      <div>
        <div className={`sudc_list_container${isHidden ? ' sudc_list_container_show' : ''}`}>
          {data.map((user: any) => (
            <div className={`sudc_list ${className}`} key={user.user_id}>
              <div className='sudc_list_image_container'>
                <img
                  src={UtilService.isImageUrl(user.image_url) ? user.image_url : profileImage}
                  alt='danger'
                  className='sudc_list_image'
                />
              </div>
              <div className='sudc_list_content'>
                <p className='sudc_list_c_name'>{user.given_name}</p>
                <p className='sudc_list_c_email'>{user.username || user.email}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const DeleteReportModal = observer((props: DeleteReportModalProps) => {
  const { onClose, reportId = '', onDelete } = props
  const { reportMainViewStore, userStore } = store
  const { userName, userId, roleId, tenantId } = userStore
  const { reportDetails, getOrDeleteReportHandler, isLoading, isConfirmLoading } = reportMainViewStore

  const [isShareWithVisible, setShareWithVisible] = useState(false)

  const showSharedUsers = () => {
    setShareWithVisible(!isShareWithVisible)
  }

  useEffect(() => {
    if (reportId) {
      getOrDeleteReportHandler('get', reportId)
    }
  }, [reportId])

  const deleteHandler = () => {
    if (!isConfirmLoading && !isLoading) {
      getOrDeleteReportHandler('delete', reportId).then((response) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'delete_report',
            entityName: reportDetails.name,
            entityType: 'report',
            user_id: userId,
            user_name: userName,
            tenantId: tenantId,
            roleId: roleId,
          },
        })
        if (response !== 'error') {
          onDelete()
        }
      })
    }
  }

  return (
    <CustomModal
      icon={DeleteIcon}
      title='Are you sure to delete this report?'
      secondButtonText={reportDetails.sections.length > 1 ? 'Delete All' : 'Delete'}
      onClose={onClose}
      onConfirm={deleteHandler}
      containerClassName='delete_report_modal_container'
      footerClassName='delete_report_modal_footer_container'>
      <div className='delete_report_modal_body_container'>
        <Spin spinning={isLoading || isConfirmLoading}>
          <div className='drmc_header'>
            <div className='drmc_shared_users_container' onClick={showSharedUsers}>
              <p className='drmc_suc_heading'>
                Shared with {'shared_users' in reportDetails && reportDetails.shared_users.length} members
              </p>

              <div className='drmc_suc_shared_users_list'>
                {'shared_users' in reportDetails && reportDetails.shared_users.length ? (
                  <ShareWith data={reportDetails.shared_users} className='drmc_suc_shared_users' maxShow={6} />
                ) : null}
                <DownArrowIcon className={`${isShareWithVisible ? 'drmc_suc_arrow_rotate' : ''}`} />
              </div>
            </div>
            {'shared_users' in reportDetails && reportDetails.shared_users.length ? (
              <SharedUsersDetails data={reportDetails.shared_users} isHidden={isShareWithVisible} />
            ) : null}
          </div>
          {'sections' in reportDetails && reportDetails.sections.length ? (
            <>
              <p className='drmc_instruction'>You can also delete any below sections of this report</p>
              <DeleteSectionsforReport sections={reportDetails.sections} />
            </>
          ) : null}
        </Spin>
      </div>
    </CustomModal>
  )
})

export default DeleteReportModal
