import { Table, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import millify from 'millify'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import YoutubeIcon from 'assets/images/youtube-new-icon.svg'
import Avatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/sort-arrow-icon.svg'
import { ReactComponent as ViewIcon } from 'assets/images/icon-eye-normal.svg'

import './CreatorsList.scss'

interface CreatorInfoProps {
  creator: any
}

interface CreatorData {
  id: string
  creator: any
  followers: number
  snippets: number
  impressions: number
}

type IColumn = any
type SortOrder = {
  name: 'ascend' | 'descend' | null
  followers: 'ascend' | 'descend' | null
}

export const CreatorsList = observer(() => {
  const navigate = useNavigate()

  const [sortStates, setSortStates] = useState<SortOrder>({
    name: 'descend',
    followers: 'descend',
  })
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const columns = useMemo(
    () => [
      {
        key: 'id',
        dataIndex: 'id',
        title: () => (
          <div
            className={classNames('creators-list__table__checkbox')}
            aria-label='Select'
            onClick={(e) => {
              e.stopPropagation()
              // selectedRows.includes(column) ? setSelectedRows([]) : setSelectedRows([column])
            }}>
            {<CheckIcon aria-label='Select' />}
          </div>
        ),
        width: '5%',
        render: (column: IColumn, item: CreatorData) => {
          return (
            <div
              className={classNames('creators-list__table__checkbox', {
                'creators-list__table__checkbox__selected': selectedRows.includes(column),
              })}
              aria-label='Select'
              onClick={(e) => {
                e.stopPropagation()
                selectedRows.includes(column) ? setSelectedRows([]) : setSelectedRows([column])
              }}>
              {selectedRows.includes(column) && <CheckIcon aria-label='Select' />}
            </div>
          )
        },
      },
      {
        title: (
          <span className='creators-list__table__title'>
            Creator
            <ArrowIcon className={classNames({ 'creators-list__table__sortIcon': sortStates.name === 'ascend' })} />
          </span>
        ),
        dataIndex: 'creator',
        key: 'creator',
        showSorterTooltip: false,
        width: '40%',
        sorter: (a: CreatorData, b: CreatorData) => a.creator.name.length - b.creator.name.length,
        sortOrder: sortStates.name,
        render: (column: IColumn, item: CreatorData) => {
          return <CreatorInfo creator={item.creator} />
        },
      },
      {
        title: (
          <span className='creators-list__table__title'>
            Followers
            <ArrowIcon
              className={classNames({ 'creators-list__table__sortIcon': sortStates.followers === 'ascend' })}
            />
          </span>
        ),
        dataIndex: 'followers',
        key: 'followers',
        sorter: (a: CreatorData, b: CreatorData) => a.followers - b.followers,
        sortOrder: sortStates.followers,
        showSorterTooltip: false,
        render: (column: IColumn) => {
          return <span className='creators-list__count'>{millify(column)}</span>
        },
      },
      {
        title: 'Snippets',
        dataIndex: 'snippets',
        key: 'snippets',
        render: (column: IColumn) => {
          return <span className='creators-list__count'>{millify(column)}</span>
        },
      },
      {
        title: 'Impressions',
        dataIndex: 'impressions',
        key: 'impressions',
        render: (column: IColumn) => {
          return <span className='creators-list__count'>{millify(column)}</span>
        },
      },
    ],
    [sortStates, selectedRows],
  )

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.columnKey === 'creator') {
      setSortStates({
        name: sortStates.name === 'ascend' ? 'descend' : 'ascend',
        followers: null,
      })
    } else if (sorter.columnKey === 'followers') {
      setSortStates({
        name: null,
        followers: sortStates.followers === 'ascend' ? 'descend' : 'ascend',
      })
    }
  }
  return (
    <div className='creators-list'>
      <Table
        className='creators-list__table'
        columns={columns}
        dataSource={[
          {
            id: '1',
            creator: { name: 'abc1', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 25545,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '2',
            creator: { name: 'abc2', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 8541,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '3',
            creator: { name: 'abc3', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 7852,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '4',
            creator: { name: 'abc8', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 5654,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '5',
            creator: { name: 'abc4', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 451,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '6',
            creator: { name: 'abc9', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 59523,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '7',
            creator: { name: 'abc10', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 53432,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '8',
            creator: { name: 'abc7', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 25545,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '9',
            creator: { name: 'abc', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 95256,
            snippets: 100000,
            impressions: 550000,
          },
          {
            id: '10',
            creator: { name: 'abc0', link: '@user url', avatar: Avatar, platform: YoutubeIcon },
            followers: 234,
            snippets: 100000,
            impressions: 550000,
          },
        ]}
        pagination={false}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate('/vectors/creator')
            },
          }
        }}
      />
    </div>
  )
})

const CreatorInfo = (props: CreatorInfoProps) => {
  const { creator } = props
  return (
    <div className='power-insights-creator-info'>
      <div className='power-insights-creator-info__container'>
        <img className='power-insights-creator-info__avatar' src={creator.avatar} alt='user-img'></img>
        <div className='power-insights-creator-info__container2'>
          <span className='power-insights-creator-info__name'>{creator.name}</span>
          <div className='power-insights-creator-info__container3'>
            <img className='power-insights-creator-info__platform' src={creator.platform} alt='platform-img'></img>
            <span className='power-insights-creator-info__link'>{creator.link}</span>
          </div>
        </div>
      </div>
      <Tooltip title={<div>This creator is on one or more active watchlists</div>}>
        <div className='power-insights-creator-info__view-icon'>
          <ViewIcon />
        </div>
      </Tooltip>
    </div>
  )
}
