import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { store } from 'store'
import { CustomPieChart } from 'version2/components/common/PieChart/PieChart'
import { DownloadOption } from '../DownloadOption'

const BrandSentiment = () => {
  const { graphColorPalette, powerInsightsData, snippetsFilter, setSnippetsFilter } = store.assetsStore
  const sentiments = powerInsightsData.lineChartData.posts.sentiment.data_points
  const { statsLineChartLoading } = store.loaderStore

  const selectedSentiment = useMemo(() => {
    if (snippetsFilter?.filter_sentiment?.length === 0) return []
    return typeof snippetsFilter.filter_sentiment === 'string'
      ? snippetsFilter?.filter_sentiment?.split(',')
      : snippetsFilter?.filter_sentiment || []
  }, [snippetsFilter?.filter_sentiment])

  const sentimentsBreakDown = useMemo(() => {
    let totalPositive = 0
    let totalNegative = 0
    let totalNeutral = 0

    sentiments.forEach((item) => {
      totalPositive += item.Positive ?? 0
      totalNegative += item.Negative ?? 0
      totalNeutral += item.Neutral ?? 0
    })
    return {
      data: [
        { name: 'Positive', total_value: totalPositive },
        { name: 'Negative', total_value: totalNegative },
        { name: 'Neutral', total_value: totalNeutral },
      ],
      totalCount: totalPositive + totalNegative + totalNeutral,
    }
  }, [sentiments])

  const onSentimentClicked = (sentiment: { name: string; total_value: number }) => {
    if (selectedSentiment.length === 1 && selectedSentiment[0] === sentiment.name) {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_sentiment: '',
      })
    } else {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_sentiment: sentiment.name,
      })
    }
  }

  return (
    <div id='brandSentiment'>
      <Spin spinning={statsLineChartLoading}>
        <div className='chart-heading'>
          <span className='monitor-assets__graph__title'>Sentiment</span>
          <DownloadOption elementToCapture='#brandSentiment' />
        </div>

        <CustomPieChart
          height={192}
          width={350}
          cx={65}
          data={sentimentsBreakDown.data}
          colorPalette={graphColorPalette.Sentiment}
          totalCount={sentimentsBreakDown.totalCount}
          narrativeTotal={sentimentsBreakDown.totalCount}
          onClickCell={onSentimentClicked}
          showLabelsBasedOnSelection
          millifyStats
          syncSentimentFilterWithChart
          sentimentFilter={selectedSentiment.length === 1 ? selectedSentiment[0] : ''}
        />
      </Spin>
    </div>
  )
}

export default observer(BrandSentiment)
