import { useState } from 'react'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import TextArea from 'version2/components/common/Inputs/TextArea/TextArea'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as InfoIcon } from 'assets/images/info-tooltip-icon.svg'
import { ReactComponent as SparkleIcon } from 'assets/images/icons/sparkle.svg'
import { ReactComponent as LoaderIcon } from 'assets/images/icons/flag/loader_icon.svg'
import './SearchTermSection.scss'
import { CopilotResponseType } from 'store/flags/types'

type SearchTermSectionProps = {
  booleanSearch: string
  setBooleanSearch: (val: string) => void
  setStep: (val: number) => void
}

const SearchTermSection = observer((props: SearchTermSectionProps) => {
  const { flagsStore } = store
  const { fetchBooleanSearchText, booleanSearchLoading } = flagsStore
  const { booleanSearch, setBooleanSearch, setStep } = props
  const [showCopilotSearch, setShowCopilotSearch] = useState<boolean>(false)
  const [copilotSearch, setCopilotSearch] = useState<string>('')

  const copilotSearchHandler = () => {
    setBooleanSearch('')
    const requestBody = {
      prompt_text: copilotSearch,
    }
    fetchBooleanSearchText({ data: requestBody }).then((res: CopilotResponseType | 'error') => {
      if (res !== 'error') {
        setBooleanSearch(res.prompt_response)
      }
    })
  }

  return (
    <div className='search_term_section_container'>
      <div className='stsc_copilot_text_container'>
        <div
          className='stsc_ctc_inner_container'
          onClick={() => {
            setCopilotSearch('')
            setShowCopilotSearch((prevState) => !prevState)
          }}>
          <SparkleIcon className='stsc_ctc_ic_spark_icon' />
          <p className='stsc_ctc_ic_text'>{showCopilotSearch && 'Close'} Pendulum Co-Pilot</p>
          <InfoIcon className='stsc_ctc_ic_info_icon' />
        </div>
      </div>
      {showCopilotSearch ? (
        <>
          <div className='stsc_text_area_container'>
            <div className='stsc_tac_heading'>
              <p className='stsc_tac_heading_text'>Pendulum Co-Pilot Search</p>
              <InfoIcon className='stsc_tac_ht_info_icon' height={12} width={12} />
            </div>
            <TextArea
              className='stsc_tac_ht_textarea'
              placeholder='Describe the topic you are looking for as you would to a junior analyst. E.g. Conversations around China and Taiwan political tensions'
              value={copilotSearch}
              onChange={(e) => setCopilotSearch(e.target.value)}
            />
          </div>
          <ButtonImage
            icon={LoaderIcon}
            className='stsc_button'
            loading={booleanSearchLoading}
            onClick={copilotSearchHandler}
            disabled={!copilotSearch || booleanSearchLoading}>
            Convert to boolean
          </ButtonImage>
        </>
      ) : null}
      <div className='stsc_text_area_container'>
        <div className='stsc_tac_heading'>
          <p className='stsc_tac_heading_text'>Boolean Keyword Search</p>
          <InfoIcon className='stsc_tac_ht_info_icon' height={12} width={12} />
        </div>
        <TextArea
          className='stsc_tac_ht_textarea'
          placeholder='("China" OR "Chinese" OR "PRC") AND ("Taiwan" OR "Taiwanese" OR "ROC") AND ("political tension*" OR "sovereignty dispute*")'
          value={booleanSearch}
          onChange={(e) => setBooleanSearch(e.target.value)}
        />
      </div>
      {booleanSearch ? (
        <ButtonImage
          className='stsc_test_button'
          loading={booleanSearchLoading}
          onClick={(e) => {
            e.stopPropagation()
            setStep(4)
          }}>
          Test the rule
        </ButtonImage>
      ) : null}
    </div>
  )
})

export default SearchTermSection
