import Modal from 'antd/lib/modal/Modal'
import './FlagsModal.scss'

interface Props {
  isModalVisible: string
  setIsModalVisible: (state: string) => void
  deleteFlag: (id: string) => void
}

export const DeleteModal = ({ isModalVisible, setIsModalVisible, deleteFlag }: Props) => {
  const onDelete = () => {
    deleteFlag(isModalVisible)
    setIsModalVisible('')
  }

  return (
    <Modal
      centered
      closable={false}
      width={387}
      open={isModalVisible.length > 0}
      bodyStyle={{ overflow: 'auto' }}
      className='flags-modal'
      footer={
        <div>
          <button className='flags-modal__cancel-btn' onClick={() => setIsModalVisible('')}>
            Close
          </button>
          <button className='flags-modal__delete-btn' onClick={onDelete}>
            Delete
          </button>
        </div>
      }>
      <div className='flags-modal'>
        <span className='flags-modal__title'>Are you sure you want to delete</span>
        <span className='flags-modal__title'>this attention flag ?</span>
        <span className='flags-modal__description'>You can not recover this attention flag once its deleted</span>
      </div>
    </Modal>
  )
}
