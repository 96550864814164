import { Dropdown, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'

import { ExecutiveIntelligenceGraph } from 'version2/components/common/ExecutiveIntelligence/ExecutiveIntelligenceGraph/ExecutiveIntelligenceGraph'
import { IAttentionFlagsPostCount, IRiskPostCount } from 'models/models'
import { FlagsSection } from './FlagsSection'

import { ReactComponent as CategoryIcon } from 'assets/images/topic-category-icon.svg'

import './CategoriesTable.scss'
import { NarrativeInfo } from '../../NarrativeInfo/NarrativeInfo'

interface Props {
  topic: string
}

export const CategoriesTable = observer(({ topic }: Props) => {
  const { anticipatoryIntelligenceStore } = store
  const { getCategoriesTableData } = anticipatoryIntelligenceStore
  const data = getCategoriesTableData(topic)

  const columns = [
    {
      dataIndex: 'category',
      key: 'topic',
      width: '16%',
      render: (col: any) => {
        return (
          <div className='categories__row_title-div'>
            <CategoryIcon />
            <span className='categories__row_title-div__title'>{col}</span>
          </div>
        )
      },
    },
    {
      dataIndex: 'line',
      key: 'line',
      width: '20%',
      render: (metricValues: any) => {
        return <ExecutiveIntelligenceGraph mode='4' data={metricValues || []} color={'#D6B77B'} />
      },
    },
    {
      dataIndex: 'posts',
      key: 'posts',
      width: '15%',
      render: (posts: any) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <NarrativeInfo
                name={posts.topic}
                category={posts.category}
                url={`tab=general&topic=${posts.topic}&category=${posts.category}`}
                tab={'general'}
                isListView={true}
                sideDetailsInfo={{
                  topic: posts.topic,
                  category: posts.category.name || '',
                  riskOrFlagName: '',
                  tab: 'general',
                  url: `tab=general&topic=${posts.topic}&category=${posts.category?.name}`,
                }}
              />
            }>
            <span className='categories__dropdown-text'>{posts.noOfPosts}</span>
          </Dropdown>
        )
      },
    },
    {
      dataIndex: 'flags',
      key: 'flags',
      width: '49%',
      render: (col: { attentionFlags: IAttentionFlagsPostCount; risks: IRiskPostCount; categoryName: string }) => {
        if (col && Object.keys(col).length > 0) return <FlagsSection data={col} topicName={topic} />
        return <></>
      },
    },
  ]

  return (
    <div className='categories__row'>
      <Table columns={columns} dataSource={data} showHeader={false} pagination={false} />
    </div>
  )
})
