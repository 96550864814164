import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { Tag } from 'antd'

import { ReactComponent as BackBtnIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as ClickIcon } from 'assets/images/icons/outline/click-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ReactComponent as ResetIcon } from 'assets/images/reset-icon.svg'

import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY, SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { IFilterChips, monitorMode, monitorSnippetPage } from 'models/models'
import { store } from 'store'

import styles from './powerInsightsHeader.module.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
  activePowerInsightTab: string
}

export const PowerInsightsHeader: React.FC<Props> = observer(({ mode, page, activePowerInsightTab }) => {
  let currentStore, monitorStore
  if (page === 'define' && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  }

  const {
    snippetsFilter,
    setSetCategory,
    powerInsightsFilterChips,
    setPowerInsightsFilterChips,
    fetchPowerInsights,
    setSnippetsTotal,
    setSnippetsCurrentPage,
    fetchWatchlistData,
    setSnippetsFilter,
    setIsPowerInsightsOpen,
  } = currentStore
  const { activeItem, fetchSnippets, setSelectedCommunityInfo, setSnippets } = monitorStore
  const goBackToFeed = useCallback(() => {
    setIsPowerInsightsOpen(false)
    setSnippetsTotal(0)
    setSnippets([])
    setSnippetsCurrentPage(1)
    if (page !== 'executive-intelligence') fetchSnippets({}, page === 'define')
  }, [])

  const onCloseFilter = async (name: string) => {
    /* eslint-disable */
    let obj
    switch (name) {
      case 'attentionFlags':
        {
          obj = {
            attentionFlags: {
              ...powerInsightsFilterChips.attentionFlags,
              value: '',
            },
          }
          setSnippetsFilter({ ...snippetsFilter, attentionFlags: {} })
        }
        break

      case 'community':
        {
          obj = {
            community: {
              ...powerInsightsFilterChips.community,
              value: '',
            },
          }
          let { community, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
          setSelectedCommunityInfo(undefined)
        }
        break

      case 'languageRisk':
        {
          switch (powerInsightsFilterChips.languageRisk.value) {
            case 'Negative': {
              const { filter_sentiment, ...rest } = snippetsFilter
              setSnippetsFilter(rest)
              break
            }
            case 'Source': {
              const { filter_risk, ...rest } = snippetsFilter
              setSnippetsFilter(rest)
              break
            }
          }
          obj = {
            languageRisk: {
              ...powerInsightsFilterChips.languageRisk,
              value: '',
            },
          }
          setSetCategory('All')
        }
        break
      case 'people':
        {
          obj = {
            people: {
              ...powerInsightsFilterChips.people,
              value: '',
            },
          }
          let { people, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
      case 'country':
        {
          obj = {
            country: {
              ...powerInsightsFilterChips.country,
              value: '',
            },
          }
          let { country, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
      case 'company':
        {
          obj = {
            company: {
              ...powerInsightsFilterChips.company,
              value: '',
            },
          }
          let { company, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
      case 'language':
        {
          obj = {
            language: {
              ...powerInsightsFilterChips.language,
              value: '',
            },
          }
          let { filter_language, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break

      case 'watchlist':
        {
          obj = {
            watchlist: {
              ...powerInsightsFilterChips.watchlist,
              value: '',
            },
          }
          let { watchlist, ...filter } = snippetsFilter
          setSnippetsFilter(filter)
        }
        break
    }
    if (obj) {
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        ...obj,
      })
      if (activePowerInsightTab === 'watchlist') await fetchWatchlistData()
      fetchPowerInsights(activeItem?.id ?? '', '')
    }
    /* eslint-enable */
  }

  const resetFilter = async () => {
    setPowerInsightsFilterChips({
      attentionFlags: {
        label: 'Attention flags',
        value: '',
      },
      community: { label: 'Community', value: '' },
      languageRisk: { label: 'Language Risk', value: '' },
      people: { label: 'People', value: '' },
      country: { label: 'Country', value: '' },
      company: { label: 'Company', value: '' },
      watchlist: { label: 'Watchlist', value: '' },
    })
    setSelectedCommunityInfo(undefined)
    setSnippetsFilter({ ...snippetsFilter, attentionFlags: {} })
    setSnippetsFilter({
      days: snippetsFilter.days,
      sort: 'Upload Date',
      sort_mode: 'Descending',
      filter_risk: [0, 100],
    })
    setSetCategory('All')
    if (activePowerInsightTab === 'watchlist') await fetchWatchlistData()
    fetchPowerInsights(activeItem?.id ?? '', '')
  }

  return (
    <div className={styles.powerInsightsHeader}>
      <div className={styles.backBtn} onClick={goBackToFeed}>
        <BackBtnIcon />
        <span className={styles.title}>Back to feed</span>
      </div>

      <div className={styles.header}>
        <h2>Power Insights{activeItem?.name ? ` - ${activeItem.name}` : ''}</h2>
        <p className={styles.description}>AI enabled summaries to help you accelerate your analysis</p>
      </div>

      <div className={styles.filtersDiv}>
        <div className={styles.filtersContainer}>
          {Object.keys(powerInsightsFilterChips).map((filter: any) => {
            return powerInsightsFilterChips[filter as keyof IFilterChips].value?.length > 0 ? (
              <div className={styles.appliedFilters}>
                <span className={styles.filterLabel}>
                  {`${powerInsightsFilterChips[filter as keyof IFilterChips].label}:`}
                </span>
                <Tag closable closeIcon={<CloseIcon />} onClose={() => onCloseFilter(filter)} className={styles.tag}>
                  {filter !== 'language'
                    ? powerInsightsFilterChips[filter as keyof IFilterChips].value
                    : SUPPORTED_LANGUAGES_CODE[powerInsightsFilterChips[filter as keyof IFilterChips].value]}{' '}
                </Tag>
              </div>
            ) : (
              <></>
            )
          })}
        </div>
        {Object.values(powerInsightsFilterChips).filter((item) => item.value.length > 0)?.length > 0 && (
          <div className={styles.resetContainer} onClick={resetFilter}>
            <ResetIcon />
            <span className={styles.reset}>{'Reset Filters'}</span>
          </div>
        )}
      </div>

      <div className={styles.interactText}>
        <ClickIcon />
        <p>Interact with visuals, click to see the relevant content.</p>
      </div>
    </div>
  )
})
