import { observer } from 'mobx-react-lite'
import { store } from 'store'

import { ReactComponent as ArrowIcon } from 'assets/images/icons/monitor/chevron_right_icon.svg'
import { ReactComponent as NoDataIcon } from 'assets/images/fetching-page-icon.svg'

import { CustomBarChart } from 'version2/components/common/BarChart/BarChart'
import classNames from 'classnames'
import { SubStore } from 'types/types'

interface Props {
  subStore: SubStore
  narrativeStats: number
  selectedIndex: string
  setSelectedIndex: (value: string) => void
  setSelectedTopIndex: (value: string) => void
}
export const BarChart = observer(
  ({ subStore, narrativeStats, selectedIndex, setSelectedIndex, setSelectedTopIndex }: Props) => {
    const { flagsStore } = store
    const { flagCategoriesGraphData, setFlagCategoriesGraphData } = flagsStore
    const {
      activeItem,
      snippetsFilter,
      filterChips,
      setFilterChips,
      setSnippetsFilter,
      fetchPowerInsights,
      fetchSnippetsInfo,
    } = store[`${subStore}Store`]

    const getPercentage = (totalCount: number) => {
      const percentage = (totalCount / narrativeStats) * 100
      if (isNaN(percentage)) return '(0%)'
      else return `(${percentage.toFixed(2).replace(/[.,]00$/, '')}%)`
    }

    const getChartHeight = (key: string) => {
      const dataLength: number = (flagCategoriesGraphData[key]?.subCategories || [])?.length || 0
      if (dataLength >= 8) return dataLength * 100
      else if (dataLength >= 5) return 600
      else if (dataLength >= 3) return 500
      else if (dataLength >= 1) return 200
      else return 100
    }

    const onBarClick = (data: any) => {
      if (selectedIndex === data.name) {
        setSelectedIndex('')
        let { flags, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
        setFilterChips({
          ...filterChips,
          flags: {
            ...filterChips.flags,
            value: '',
          },
        })
      } else {
        setSelectedIndex(data.name)
        setSnippetsFilter({ ...snippetsFilter, flags: { name: data.name, conditions: data?.conditions } })
        setFilterChips({
          ...filterChips,
          flags: {
            ...filterChips.flags,
            value: data.name,
          },
        })
      }

      fetchPowerInsights(activeItem!)
      fetchSnippetsInfo(activeItem!)
    }
    return (
      <div className='monitor-flags__body'>
        {Object.keys(flagCategoriesGraphData).map((key) => (
          <div>
            <div className='monitor-flags__category-div'>
              <div className='monitor-flags__category-div__info-div'>
                <span className='monitor-flags__category-div__title'>{key}</span>
                <span className='monitor-flags__category-div__percentage'>
                  {getPercentage(flagCategoriesGraphData[key]?.total_value || 0)}
                </span>
              </div>
              <ArrowIcon
                className={classNames({ 'monitor-flags__arrow': flagCategoriesGraphData[key]?.expanded || false })}
                onClick={() => {
                  setFlagCategoriesGraphData(key, { expanded: !flagCategoriesGraphData[key]?.expanded || false })
                  setSelectedTopIndex(flagCategoriesGraphData[key]?.expanded ? key : '')
                }}
              />
            </div>
            {flagCategoriesGraphData[key]?.expanded && (
              <div className='monitor-flags__category-div monitor-flags__empty-padding'>
                {flagCategoriesGraphData[key].total_value === 0 ? (
                  <div className='monitor-flags__no-data'>
                    <NoDataIcon />
                    <span className='monitor-flags__no-data__text'>No Data Found</span>
                  </div>
                ) : (
                  <CustomBarChart
                    width={800}
                    height={getChartHeight(key)}
                    data={
                      [
                        { name: '', total_value: 0 },
                        ...Object.values(flagCategoriesGraphData[key].subCategories || {}),
                      ] || []
                    }
                    selectedCell={selectedIndex}
                    onClickCell={onBarClick}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    )
  },
)
