import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { useEffect } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'
import { List } from 'antd'
import VirtualList from 'rc-virtual-list'

import { DataFetching } from 'components/common/DataFetching/DataFetching'
import { MonitorSnippetItem } from '../MonitorSnippetItem/SnippetItem'
import { monitorMode, monitorSnippetPage } from 'models/models'

import './SnippetPage.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
}

export const AnticipatoryIntelligenceSnippetList: React.FC<Props> = observer(({ mode, page }) => {
  const ContainerHeight = 670

  const { anticipatoryIntelligenceStoreV1 } = store
  const {
    activeThreatNarrativeTitle,
    activeThreatNarrative,
    snippetsFilter,
    isFeedEmpty,
    liteWatchlists,
    liteCommunities,
    snippetsWithSurprises,
    snippets,
    fetchLiteWatchlists,
    showSurprise,
    fetchSnippets,
    setIsFeedEmpty,
  } = anticipatoryIntelligenceStoreV1

  useEffectOnce(() => {
    fetchLiteWatchlists()
  })

  useEffect(() => {
    fetchSnippets({ isRisk: true })
  }, [activeThreatNarrative])

  useUnmount(() => {
    setIsFeedEmpty(true)
  })

  const checkFeed = () => {
    if (!isFeedEmpty && !snippets.length) {
      return <DataFetching title={activeThreatNarrativeTitle} page={'notFound'} daysFilters={snippetsFilter.days} />
    } else {
      setIsFeedEmpty(false)
      return (
        <VirtualList
          className='c-snippets-list__list'
          data={snippetsWithSurprises}
          height={ContainerHeight}
          itemHeight={220}
          itemKey='id'
          onScroll={(e) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight)
              fetchSnippets({ isRisk: true })
          }}>
          {(item) => {
            return (
              <div key={item.id}>
                <MonitorSnippetItem
                  mode={mode}
                  page={page}
                  snippet={item}
                  allWatchlist={liteWatchlists}
                  allCommunities={liteCommunities}
                  showSurprise={showSurprise}
                />
              </div>
            )
          }}
        </VirtualList>
      )
    }
  }
  return (
    <div className='snippet-list__wrapper'>
      <List>{checkFeed()}</List>
    </div>
  )
})
