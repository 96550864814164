import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts'
import { useEffect, useMemo, useState } from 'react'
import millify from 'millify'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { DEFINE_STORES_LOOKUP_DICTIONARY, STORE_LOOKUP_DICTIONARY, SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { IBarChartData, monitorMode, monitorSnippetPage } from 'models/models'
import { BarChartHeader } from 'version2/components/Monitor/PowerInsights/MonitorBarChart/BarChartHeader/BarChartHeader'

import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'

import './MonitorBarChart.scss'

interface Props {
  mode: monitorMode
  tab: 'company' | 'people' | 'language' | 'watchlist'
  page: monitorSnippetPage
  indexName: 'companyData' | 'peopleData' | 'languagesData' | 'watchlistData'
}

export const MonitorBarChart = observer(({ mode, tab, page, indexName }: Props) => {
  const { narrativeNumber: narrativeNumberParam } = useParams()

  let currentStore, monitorStore
  if (page === 'define' && (mode === 'narrative' || mode === 'watchlist')) {
    currentStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
    monitorStore = store[`define${DEFINE_STORES_LOOKUP_DICTIONARY[mode]}StoreV1`]
  } else if (page === 'anticipatory-intelligence') {
    currentStore = store.anticipatoryIntelligenceStoreV1
    monitorStore = store.anticipatoryIntelligenceStoreV1
  } else if (page === 'executive-intelligence') {
    currentStore = store.executiveIntelligence
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  } else {
    currentStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
    monitorStore = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  }

  const {
    powerInsightsData,
    snippetsFilter,
    powerInsightsFilterChips,
    setPowerInsightsFilterChips,
    setSnippetsFilter,
  } = currentStore
  const { activeItem, setPowerInsightsData, fetchPowerInsights, fetchWatchlistData } = monitorStore
  const { fetchPowerInsights: executiveFetchPowerInsights } = store['executiveIntelligence']

  const data = useMemo(() => {
    return powerInsightsData[indexName]?.data
  }, [tab, powerInsightsData[indexName]])

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [clickedItem, setClickedItem] = useState('')

  const [graphData, setGraphData] = useState(data)
  const [searchCheck, setSearchCheck] = useState(false)

  useEffectOnce(() => {
    if (tab === 'watchlist') fetchWatchlistData()
  })

  useEffect(() => {
    setGraphData(data)
  }, [tab, powerInsightsData[indexName]])

  const CustomizedYAxisTick = (props: { x: number; y: number; index: number; payload: { value: string } }) => {
    const data = graphData?.find((item) => item.entity_name === props.payload.value) || {
      metric_value: 0,
      entity_name: '',
    }

    return (
      <g onMouseEnter={() => onBarChartMouseEnter({}, props.index)} onMouseOut={() => onBarChartMouseOut({}, -1)}>
        <text
          transform={`translate(${props.x - 240},${props.y + 5})`}
          fontSize='14px'
          fontWeight={isSelected(data, props.index) ? '500' : '400'}
          textAnchor='start'
          fill={data?.metric_value === 0 ? '#8598AD' : '#16191D'}>
          {tab !== 'language'
            ? props.payload.value.length > 0 && props.payload.value[0].toUpperCase() + props.payload.value.substring(1)
            : props.payload.value.length > 0 && SUPPORTED_LANGUAGES_CODE[props.payload.value]}
        </text>
        <text
          transform={`translate(${props.x},${props.y + 5})`}
          fontSize='12px'
          fontWeight={isSelected(data, props.index) ? '500' : '400'}
          textAnchor='end'
          fill={data?.metric_value === 0 ? '#8598AD' : '#16191D'}>
          {millify(Number(data?.metric_value || '0'))}
        </text>
      </g>
    )
  }

  const onBarChartMouseEnter = (data: any, index: number) => {
    setSelectedIndex(index)
  }

  const onBarChartMouseOut = (data: any, index: number) => {
    if (clickedItem !== data?.payload?.entity_name) setSelectedIndex(-1)
  }

  const onBarChartMouseClick = (data: any, index: number) => {
    setSelectedIndex(-1)
    if (data?.payload?.entity_name === clickedItem) {
      setClickedItem('')
      if (tab === 'company') {
        let { company, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'people') {
        let { people, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'language') {
        let { filter_language, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'watchlist') {
        let { watchlist, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      }
      const tabData = powerInsightsFilterChips[tab]
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        [tab]: { ...tabData, value: '' },
      })
    } else {
      setClickedItem(data?.payload?.entity_name)
      if (tab === 'watchlist') setSnippetsFilter({ ...snippetsFilter, watchlist: data?.payload?.urls })
      else {
        tab !== 'language'
          ? setSnippetsFilter({ ...snippetsFilter, [tab]: [data?.payload?.entity_name] })
          : setSnippetsFilter({ ...snippetsFilter, filter_language: data?.payload?.entity_name })
      }
      const tabData = powerInsightsFilterChips[tab]
      setPowerInsightsFilterChips({
        ...powerInsightsFilterChips,
        [tab]: { ...tabData, value: data?.payload?.entity_name },
      })
    }
    if (page === 'executive-intelligence') return executiveFetchPowerInsights(narrativeNumberParam || '')

    fetchPowerInsights(activeItem?.id ?? '', '')
  }

  const checkList = () => {
    if (tab !== 'language' && indexName !== 'languagesData')
      return (
        powerInsightsData[indexName] &&
        (powerInsightsData[indexName]?.data?.length || 0) < (powerInsightsData[indexName]?.completeData?.length || 0)
      )
    return false
  }

  const loadMore = () => {
    if (tab !== 'language' && indexName !== 'languagesData') {
      if (powerInsightsData && powerInsightsData[indexName] && powerInsightsData[indexName]?.data) {
        const remaining =
          (powerInsightsData[indexName]?.completeData?.length || 0) - (powerInsightsData[indexName]?.data?.length || 0)
        const count =
          remaining > 8
            ? (powerInsightsData[indexName]?.data?.length || 0) + 8
            : (powerInsightsData[indexName]?.data?.length || 0) + remaining
        const data = powerInsightsData[indexName]?.completeData?.slice(0, count)
        setPowerInsightsData({ ...powerInsightsData, [indexName]: { ...powerInsightsData[indexName], data } })
        setGraphData(data)
      }
    }
  }

  const isSelected = (entry: IBarChartData, index: number) => {
    const snippetTabData = tab !== 'language' ? snippetsFilter[tab] || [''] : snippetsFilter['filter_language'] || ['']
    return (
      selectedIndex === index ||
      (tab !== 'language' ? snippetTabData[0] === entry.entity_name : snippetTabData === entry.entity_name)
    )
  }

  return (
    <div>
      <BarChartHeader mode={mode} tab={tab} searchCheck={setSearchCheck} indexName={indexName} />

      <div className='power-insights-bar-chart__chart-container'>
        <BarChart
          width={600}
          height={33.33 * (graphData?.length || 0)}
          data={graphData}
          layout={'vertical'}
          margin={{ right: 40 }}
          barGap={10}>
          <XAxis type='number' dataKey='metric_value' hide />
          <YAxis
            type='category'
            dataKey='entity_name'
            axisLine={false}
            width={250}
            tickLine={false}
            tick={CustomizedYAxisTick}
          />

          <Bar
            dataKey='metric_value'
            barSize={13}
            onMouseEnter={onBarChartMouseEnter}
            onMouseOut={onBarChartMouseOut}
            onClick={onBarChartMouseClick}>
            {graphData?.map((entry, index) => {
              return (
                <Cell
                  cursor='pointer'
                  fill={isSelected(entry, index) ? '#CCA55A' : entry.metric_value === 0 ? '#8598AD' : '#9BADFA'}
                  key={`cell-${index}`}
                />
              )
            })}
          </Bar>
        </BarChart>
        {!searchCheck && checkList() && (
          <div className='power-insights-bar-chart__see-more' onClick={loadMore}>
            <span className='power-insights-bar-chart__see-more__label'>See more</span>
            <DropdownIcon />
          </div>
        )}
      </div>
    </div>
  )
})
