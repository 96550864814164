import React from 'react'
import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as GenerateIcon } from 'assets/images/icons/report/generate-icon-size-16.svg'
import './GenerateTopicsSection.scss'

type GenerateTopicsSectionProps = {
  onClick: () => void
  loading?: boolean
}

function GenerateTopicsSection(props: GenerateTopicsSectionProps) {
  const { onClick, loading } = props

  return (
    <div className='gts_container'>
      <GenerateIcon className='gts_c_icon' height={80} width={80} />
      <p className='gts_c_desc'>
        In this section, you'll find the top trending topics from our data feed. But that's not all! For each topic, we
        also provide a sentiment analysis, so you can quickly gauge if the buzz is positive, negative, or neutral. Think
        of it as a quick pulse check on what's being talked about and how people feel about it.
      </p>
      <p className='gts_c_message'>Dive in, explore, and stay informed!</p>
      <ButtonImage
        disabled={loading}
        className='tc_ic_button'
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}>
        Generate Topics
      </ButtonImage>
    </div>
  )
}

export default GenerateTopicsSection
