import { useState } from 'react'
import { Modal, Tooltip } from 'antd'
import dayjs from 'dayjs'

import { ISnippet } from 'models/models'

import { ReactComponent as SafetyIcon } from 'assets/images/risk-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-icon.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-new-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon.svg'
import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-icon.svg'
import { ReactComponent as NormalSentiment } from 'assets/images/sentiment-normal-icon.svg'
import { ReactComponent as NegativeSentiment } from 'assets/images/sentiment-negative-icon.svg'
import { ReactComponent as PositiveSentiment } from 'assets/images/sentiment-positive-icon.svg'
import { ReactComponent as OpenIcon } from 'assets/images/open-icon.svg'
import { ReactComponent as DescriptionSourceIcon } from 'assets/images/snippet-source-description-icon.svg'
import { ReactComponent as TitleSourceIcon } from 'assets/images/snippet-source-title-icon.svg'
import { ReactComponent as ImageDescriptionSourceIcon } from 'assets/images/snippet-source-image-description-icon.svg'
import { ReactComponent as ImageSourceIcon } from 'assets/images/snippet-source-image-text-icon.svg'
import { ReactComponent as TranscriptSourceIcon } from 'assets/images/snippet-source-transcript-icon.svg'
import './PreviewModal.scss'

interface Props {
  openedMedia: ISnippet | null
  onModalClose: () => void
  onOpenSource: (obj: string) => void
}

export const PreviewModal = ({ openedMedia, onModalClose, onOpenSource }: Props) => {
  const maxVideoCharacters = 35
  const isNewsSnippet = openedMedia?.sourcePlatform === 'News'
  const isSnippetTooLong = openedMedia && openedMedia?.snippet_text?.length > 500
  const formattedDate = dayjs(openedMedia?.date).format('MMM DD, YYYY')
  const fullTextComponent: any[] = []
  const partTextComponent: any[] = []
  const sentimentIcons = {
    Positive: <PositiveSentiment height={20} width={20} className='c-preview-modal__sentiment' />,
    Neutral: <NormalSentiment height={20} width={20} className='c-preview-modal__sentiment' />,
    Negative: <NegativeSentiment height={20} width={20} className='c-preview-modal__sentiment' />,
  }
  const iconsObj = {
    YouTube: <YoutubeIcon className='c-preview-modal__footer__left__app-icon' />,
    Twitter: <TwitterIcon className='c-preview-modal__footer__left__app-icon' />,
    Podcast: <PodcastIcon className='c-preview-modal__footer__left__app-icon' />,
    News: <NewsIcon className='c-preview-modal__footer__left__app-icon' />,
    Facebook: <FacebookIcon className='c-preview-modal__footer__left__app-icon' />,
    Rumble: <RumbleIcon className='c-preview-modal__footer__left__app-icon' />,
    BitChute: <BitChuteIcon className='c-preview-modal__footer__left__app-icon' />,
    TikTok: <TikTokIcon className='snippet-item__footer__left__app-icon' />,
    Odysee: <OdyseeIcon className='snippet-item__footer__left__app-icon' />,
    Surprise: undefined,
  }
  const snippetSourceIcons = {
    description: { logo: <DescriptionSourceIcon />, name: 'description' },
    title: { logo: <TitleSourceIcon />, name: 'title' },
    image_caption: {
      logo: <ImageDescriptionSourceIcon />,
      name: 'image description',
    },
    ocr: { logo: <ImageSourceIcon />, name: 'image text' },
    transcript: { logo: <TranscriptSourceIcon />, name: 'transcript' },
  }
  let fullText = openedMedia?.snippet_text || ''
  let partText = openedMedia?.snippet_text?.slice(0, 500) || ''
  let url = ''
  let offsetReadable = ''

  const [openedSnippet, setOppenedSnippet] = useState(false)

  if (openedMedia) {
    if (openedMedia?.offset / 60 > 60) {
      const hours = openedMedia?.offset / 60 / 60
      const minutes = openedMedia?.offset / 60 - 60
      const sec = (minutes % 1) * 60
      offsetReadable = Math.floor(hours) + 'h ' + Math.floor(minutes) + 'm ' + Math.floor(sec) + 's'
    } else {
      const minutes = openedMedia?.offset / 60
      const sec = (minutes % 1) * 60
      offsetReadable = Math.floor(minutes) + 'm ' + Math.floor(sec) + 's'
    }
  }

  fullTextComponent.push(fullText)
  partTextComponent.push(partText)

  switch (openedMedia?.sourcePlatform) {
    case 'YouTube':
      url =
        'https://www.youtube.com/embed/' + openedMedia.playSourceLink.split('v=')[1] + '?start=' + openedMedia.offset
      break
    case 'TikTok':
      let id: any = openedMedia?.postUrl
      if (id?.length) {
        id = id.split('/')
        id = id[id.length - 1]
        url = 'https://www.tiktok.com/embed/' + id
      }
      break
    default:
      if (openedMedia?.playSourceLink) url = openedMedia?.playSourceLink
      break
  }

  return (
    <Modal
      className='c-preview-modal'
      zIndex={9999}
      title={
        <div className='c-preview-modal__title'>
          <div className='c-preview-modal__title__text'>
            {openedMedia?.title?.substring(0, maxVideoCharacters)}
            {(openedMedia?.title?.length || 0) > maxVideoCharacters ? '...' : ''}
          </div>

          <div className='c-preview-modal__title__right-div'>
            {openedMedia?.sourcePlatform !== 'Podcast' && (
              <span
                className='c-preview-modal__open-source-btn'
                onClick={() => {
                  onOpenSource(openedMedia?.openSourceLink!)
                  // onModalClose()
                }}>
                <OpenIcon height={15} />
                <span>Open source</span>
              </span>
            )}

            <div className='c-preview-modal__title__snippet-source'>
              {openedMedia?.source && snippetSourceIcons[openedMedia?.source].logo}
              <span>{openedMedia?.source}</span>
            </div>
          </div>
        </div>
      }
      open={!!openedMedia}
      onCancel={onModalClose}
      closable={false}>
      {openedMedia?.sourcePlatform === 'Podcast' && (
        <audio
          controlsList={'nodownload'}
          controls
          className='c-preview-modal__audio'
          id='player'
          src='https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_multimedia_alert_chime_short_musical_notification_cute_child_like_001_64918.mp3?_=1'></audio>
      )}
      {isNewsSnippet ? (
        <img className='c-preview-modal__news-cover-img' alt='cover-img' src={openedMedia?.extras?.articleThumbImg} />
      ) : (
        <>
          <iframe title={openedMedia?.snippet_text.slice(0, 20)} className='c-preview-modal__iframe' src={url} />
        </>
      )}

      {!isNewsSnippet && (
        <span className='c-preview-modal__play-at'>
          relevant content starts at <b className='c-preview-modal__play-at--primary'>{offsetReadable}</b>
        </span>
      )}

      <p className='c-preview-modal__description'>
        {isSnippetTooLong ? (openedSnippet ? fullTextComponent : partTextComponent) : fullTextComponent}
        {isSnippetTooLong && (
          <>
            ...
            {openedSnippet ? (
              <span onClick={() => setOppenedSnippet(false)} className='c-preview-modal__description--more'>
                less
              </span>
            ) : (
              <span onClick={() => setOppenedSnippet(true)} className='c-preview-modal__description--more'>
                more
              </span>
            )}
          </>
        )}
      </p>

      <div className='c-preview-modal__footer'>
        <div className='c-preview-modal__footer__left__list'>
          {openedMedia && iconsObj[openedMedia?.sourcePlatform]}
          <div className='c-preview-modal__dot' style={{ margin: '0 5px' }} />
          <img className='c-preview-modal__footer__left__user-img' src={openedMedia?.author.channel_thumb_url} alt='' />
          <span>{openedMedia?.author?.channel_title} </span>
          {!isNewsSnippet && (
            <>
              <div className='c-preview-modal__dot' />
              {openedMedia?.viewsMilify} view{parseInt(openedMedia?.viewsMilify || '0') === 1 || 's'}
            </>
          )}
          <div className='c-preview-modal__dot' />
          {formattedDate}
          {!isNewsSnippet && (
            <>
              <div className='c-preview-modal__dot' />
              <Tooltip
                overlayClassName='c-preview-modal__footer__left__tooltip'
                title={
                  <span>
                    Risk Score assigned based on the community and platform information associated with creator. Range
                    between 0% to 100%.
                  </span>
                }
                placement='bottom'>
                <div className='c-preview-modal__details'>
                  <SafetyIcon height={20} width={20} />
                  <span className='c-preview-modal__details'> {openedMedia?.riskScore} %</span>
                </div>
              </Tooltip>
            </>
          )}
          <div className='c-preview-modal__dot' />
          {openedMedia?.sentiment && (
            <Tooltip
              overlayClassName='c-preview-modal__footer__left__tooltip'
              title={
                <span>
                  This snippet expresses a {openedMedia?.sentiment} sentiment as identified by Pendulums ML model.
                </span>
              }
              placement='bottom'>
              <div className='c-preview-modal__details'>{sentimentIcons[openedMedia?.sentiment]}</div>
            </Tooltip>
          )}
        </div>
      </div>
    </Modal>
  )
}
