import html2canvas from 'html2canvas'

const downloadHtmlAsImage = (id: string) => {
  const element: HTMLElement | null = document.querySelector(id)
  if (!element) return
  html2canvas(element, {
    ignoreElements: (element) => {
      // Ignore elements with the class 'no-capture'
      return element.classList.contains('download-icon')
    },
  })
    .then((canvas) => {
      let imgData = canvas.toDataURL('image/png')

      let link = document.createElement('a')
      link.href = imgData
      link.download = 'captured-image.png' // Filename for the download

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    })
    .catch((error) => console.log('error', error))
}

export default downloadHtmlAsImage
