import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import AreaChartSection from '../../AreaChartSection/AreaChartSection'
import AllPostSection from '../../AllPostSection/AllPostSection'
import { ReactComponent as ChevronRightIcon } from 'assets/images/icons/monitor/chevron_right_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/monitor/close_icon_thin.svg'
import { ReactComponent as InfoIcon } from 'assets/images/icons/monitor/info_icon_24.svg'
import { NarrativeListDataType, SelectedTabType } from 'store/monitorVersion2/types'
import FlagPosts from '../../FlagPosts/FlagPosts'
import RiskFlags from '../../RiskFlags/RiskFlags'
import ExtraInfoPopup from '../../ExtraInfoPopup/ExtraInfoPopup'
import { SubStore } from 'types/types'

import './GridCard.scss'

type GridCardProps = {
  data: NarrativeListDataType
  className?: string
  selectedTab: SelectedTabType
  subStore: SubStore
}

function GridCard(props: GridCardProps) {
  const navigate = useNavigate()
  const { className = '', data, subStore } = props
  // const [selectedRisksFlag, setSelectedRisksFlag] = useState<string[]>([])
  const [showTrendline, setShowTrenline] = useState<boolean>(false)
  const [lineChartLoading, setLineChartLoading] = useState<boolean>(false)
  const [riskFlagsLoading, setRiskFlagsLoading] = useState<boolean>(false)

  // const risksHandler = (riskFlag: string) => {
  //   setSelectedRisksFlag((prevState) =>
  //     prevState.includes(riskFlag) ? prevState.filter((prevFlag) => prevFlag !== riskFlag) : [...prevState, riskFlag],
  //   )
  // }

  return (
    <div className={`grid_card_container${showTrendline ? ' grid_card_container_trendline' : ''} ${className}`}>
      <div className='gcc_header'>
        <div className='gcc_h_top'>
          <p
            className='gcc_ht_text'
            onClick={() => {
              const state = {
                name: data.name,
                conditions: data.conditions,
                mode: data.mode,
                id: data?.id,
                permission_level: data.mode === 'watchlist' ? 'NOT_SPECIFIED' : data.permission_level,
              }

              navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
            }}>
            {data.name}
          </p>
          <div className='gcc_ht_notification_container'>
            <InfoIcon className='gcc_ht_icon' />
            <ExtraInfoPopup data={data as NarrativeListDataType} className='gcc_ht_popup' />
          </div>
        </div>

        <div className='gcc_h_bottom'>
          <div className='gcc_hb_left_container'>
            <div className='gcc_hb_posts'>
              <div className='gcc_hb_p_text_container'>
                <p className='gcc_hb_p_text'>All posts</p>
                <AllPostSection className='gcc_hb_p_tc_apc' itemData={data} forGridView={true} subStore={subStore} />
              </div>
            </div>
            <div className='gcc_hb_posts'>
              <div className='gcc_hb_p_text_container'>
                <p className='gcc_hb_p_text'>Flagged posts</p>
                <FlagPosts className='gcc_hb_p_tc_fpc' itemData={data} forGridView={true} subStore={subStore} />
              </div>
            </div>
          </div>
          <ChevronRightIcon
            className='gcc_hb_right_icon'
            onClick={() => {
              setShowTrenline(true)
            }}
          />
        </div>
      </div>
      <Spin spinning={riskFlagsLoading} wrapperClassName='elcc_f_rf_spinner_wrapper' className='elcc_f_rf_spinner'>
        <div className='gcc_footer'>
          <RiskFlags
            title='Risk Flags'
            data={data}
            className='elcc_f_risk_flag_container'
            forGridView={true}
            setRiskFlagsLoading={setRiskFlagsLoading}
            subStore={subStore}
          />
          <RiskFlags
            title='Assets Flags'
            data={data}
            className='elcc_f_risk_flag_container'
            isAssetsFlags={true}
            forGridView={true}
            setRiskFlagsLoading={setRiskFlagsLoading}
            subStore={subStore}
          />
        </div>
      </Spin>
      {showTrendline && (
        <div className='gcc_trendline_container'>
          <Spin spinning={lineChartLoading} wrapperClassName='gcc_tc_spinner_wrapper' className='gcc_tc_spinner'>
            <div className='fcc_tc_header'>
              <p className='gcc_tc_h_text'>Trendline</p>
              <CloseIcon
                className='gcc_tc_h_icon'
                onClick={() => {
                  setShowTrenline(false)
                }}
              />
            </div>
            <p
              className='gcc_tc_title'
              onClick={() => {
                const state = {
                  name: data.name,
                  conditions: data.conditions,
                  mode: data.mode,
                  id: data?.id,
                  permission_level: data.permission_level,
                }

                navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
              }}>
              {' '}
              {data?.name || ''}
            </p>
            <AreaChartSection
              data={data}
              className='gcc_tc_area_chart_container'
              setLineChartLoading={setLineChartLoading}
              subStore={subStore}
            />
          </Spin>
        </div>
      )}
    </div>
  )
}

export default GridCard
