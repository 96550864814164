import { autorun, set, makeAutoObservable } from 'mobx'
import { NavViewType } from 'models/models'

export class NavViewStore {
  viewType: NavViewType = 'leftView'
  isNavOpen: boolean = false

  constructor() {
    makeAutoObservable(this)
    autoSave(this, 'navViewType')
  }
  get currentView() {
    return this.viewType
  }

  get navOpen() {
    return this.isNavOpen
  }

  get isClassicView() {
    return this.viewType === 'classicView'
  }

  setViewType = (view: NavViewType) => {
    this.viewType = view
  }

  setIsNavOpen = (state: boolean) => {
    this.isNavOpen = state
  }

  changeOpenNav = () => {
    this.setIsNavOpen(!this.isNavOpen)
  }
}

export function autoSave(_this: any, name: string) {
  const navTypeValue = localStorage.getItem(name)
  if (navTypeValue) {
    set(_this, 'viewType', navTypeValue)
  }
  autorun(() => {
    localStorage.setItem(name, _this.viewType)
  })
}
