import { communityAPI } from 'api/api'
import dayjs from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import { UtilService } from 'services/Util/Util'
import { ManageStore } from './manage.store'

export class ManageCommunitiesStore extends ManageStore {
  allDataTab = 3
  storeType: 'tag' | 'community' | 'narrative' = 'community'

  isRequestModalOpen: boolean = false
  isPostRequestModalOpen: boolean = false

  get filterTableTab1() {
    return `owner_id:eq:${this.userID}`
  }
  get filterTableTab2() {
    const date = dayjs().subtract(30, 'days').toISOString()
    return `created:gt:${date}`
  }
  get filterTableTab3() {
    return ``
  }

  get sortTableTab2() {
    return this[`sort`].fieldName?.length && this[`sort`].direction?.length
      ? `${this[`sort`].fieldName}:${this[`sort`].direction.replace('end', '')}`
      : 'created:desc'
  }

  constructor() {
    super()

    makeObservable(this, {
      isRequestModalOpen: observable,
      isPostRequestModalOpen: observable,

      setIsRequestModalOpen: action.bound,
      setIsPostRequestModalOpen: action.bound,
      submitCommunity: action.bound,
    })
  }

  setIsRequestModalOpen = (state: boolean) => {
    this.isRequestModalOpen = state
  }

  setIsPostRequestModalOpen = (state: boolean) => {
    this.isPostRequestModalOpen = state
  }

  submitCommunity = async (communityDescription: string) => {
    try {
      await communityAPI.submitCommunity(communityDescription)
      this.setIsRequestModalOpen(false)
      this.setIsPostRequestModalOpen(true)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
}
