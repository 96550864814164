import { API, tagAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { IListItem, IMonitorModeDetail, ITAG_DETAILS, manageMode } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { ManageStore2 } from './manage.store'

export class ManageTagsStore2 extends ManageStore2 {
  storeType: manageMode = 'tag'
  tagsFollowedByUser: IListItem[] = []
  tagsFollowedByUserPagination: { total: number; locallyLoaded: number } = { total: 0, locallyLoaded: 0 }
  narrative: IMonitorModeDetail[] = []
  community: IMonitorModeDetail[] = []
  creator: any[] = []

  constructor() {
    super()
    makeObservable(this, {
      tagsFollowedByUser: observable,
      tagsFollowedByUserPagination: observable,
      narrative: observable,
      community: observable,
      creator: observable,
      setTagsFollowedByUser: action.bound,
      setTagsFollowedByUserPagination: action.bound,
      saveTagDetailsData: action.bound,
      updateTagsFollowedByUser: action.bound,
    })
  }

  setTagsFollowedByUser = (items: IListItem[]) => {
    this.tagsFollowedByUser = items
  }

  setTagsFollowedByUserPagination = (pagination: { total: number; locallyLoaded: number }) => {
    this.tagsFollowedByUserPagination = pagination
  }

  saveTagDetailsData = (id: string, data: ITAG_DETAILS) => {
    const index = this.listItems.findIndex((item) => item.id === id)
    if (index > -1) {
      this.listItems[index].details = {
        narrative: data?.entity_details?.NARRATIVE,
        creator: data?.entity_details?.CHANNEL,
        community: data?.entity_details?.COMMUNITY,
      }
    }
  }

  updateTagsFollowedByUser = (items: IListItem[]) => {
    this.tagsFollowedByUser = [...this.tagsFollowedByUser, ...items]
  }

  fetchTagsFollowedByUser = async ({ isRefreshing }: { isRefreshing?: boolean } = {}) => {
    try {
      if (
        !isRefreshing &&
        this.tagsFollowedByUserPagination.total <= this.tagsFollowedByUserPagination.locallyLoaded &&
        this.tagsFollowedByUserPagination.total !== 0
      )
        return

      let page = Math.ceil(this.tagsFollowedByUserPagination.locallyLoaded / this.pageSize) + 1
      let pageSize = this.pageSize

      if (isRefreshing) {
        page = 1
        pageSize = this.tagsFollowedByUserPagination.locallyLoaded
      }

      let { data, total } = await API.get({
        page,
        pageSize,
        route: this.storeType,
        getError: true,
        sort: 'label:asc',
        filter: 'is_followed:eq:true',
      })

      if (!isRefreshing) {
        this.setTagsFollowedByUserPagination({
          total,
          locallyLoaded: this.tagsFollowedByUserPagination.locallyLoaded + data.items.length,
        })
      }

      const items = data.items.map((item: any) => {
        this.fetchDetailedData(item.id)

        return {
          id: item.id,
          name: this.storeType === 'tag' ? item.label : item.name,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          is_followed: item.is_followed,
        }
      })

      if (isRefreshing) {
        this.setTagsFollowedByUser(items)
      } else {
        this.updateTagsFollowedByUser(items)
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  updateTagStatus = async (tagId: string | number, followingStatus: boolean) => {
    try {
      await tagAPI.changeFollowingStatus(tagId, followingStatus)
      this.setTagsFollowedByUserPagination({ total: 0, locallyLoaded: 0 })
      this.setListItemsPagination({ total: 0, locallyLoaded: 0 })
      this.setListItems([])
      this.setTagsFollowedByUser([])
      this.fetchListItems()
      this.fetchTagsFollowedByUser()
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchDetailedData = async (id: string) => {
    const { data } = await API.get({ route: 'tag', id })
    this.saveTagDetailsData(id, data)
  }
}
