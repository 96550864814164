import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import Avatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/monitor/arrow-left-icon.svg'

import ButtonImage from 'version2/components/common/Buttons/ButtonImage/ButtonImage'

import './Creator.scss'

export const Creator = observer(() => {
  const navigate = useNavigate()

  return (
    <div className='power-insight-creator'>
      <ButtonImage
        className='power-insight-creator__back'
        icon={ArrowLeftIcon}
        iconClassName='power-insight-creator__back__icon'
        onClick={() => {
          navigate('/vectors/details')
        }}>
        Back
      </ButtonImage>
      <div className='power-insight-creator__profile'>
        <img className='power-insight-creator__profile__avatar' src={Avatar} alt='user-img'></img>
        <div className='power-insight-creator__profile__container'>
          <span className='power-insight-creator__profile__name'>{'Name'}</span>
          <span className='power-insight-creator__profile__link'>{'@Link'}</span>
        </div>
      </div>
    </div>
  )
})
