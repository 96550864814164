import { observer } from 'mobx-react-lite'
import '../BrandsList/BrandsList.scss'
import Table from 'version2/components/common/Table/Table'
import { useEffect, useMemo, useState } from 'react'
import { Avatar, Spin } from 'antd'
import flagIcon from 'assets/images/icons/flag/active_flag.svg'
import { store } from 'store'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { DefaultPagination } from 'version2/components/common/Pagination/Pagination'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'
import '../BrandsList/BrandsList.scss'
import FlagStats from './FlagStats'
import { AssetListObjType } from 'store/asset/types'

type PropsType = {
  selectedBrand: AssetListObjType | undefined
}

const ActiveFlags = observer(({ selectedBrand }: PropsType) => {
  const { flagsStore, assetsStore } = store
  const { fetchFlagsListWithConditions, flagsLoading, flagsList, paginationValues, setPaginationValues } = flagsStore
  const { filterChips, snippetsFilter, setSnippetsFilter, setFilterChips } = assetsStore
  const [hiddenRows, setHiddenRows] = useState<string[]>([])

  useEffect(() => {
    const requestParams: FlagListParamsType = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      is_active: true,
    }
    setPaginationValues({ page: 1, pageSize: 10 })
    fetchFlagsListWithConditions({ params: requestParams })
  }, [])

  useEffect(() => {
    if (hiddenRows.length > 0) {
      setHiddenRows([])
    }
  }, [selectedBrand, snippetsFilter.days])

  const hideThisRow = (flagId: string) => setHiddenRows([...hiddenRows, flagId])

  const columns: any = useMemo(() => {
    return [
      {
        key: 'name',
        title: '',
        render: (_: any, item: FlagListObjType) => {
          return (
            <div className='brand_info'>
              <Avatar src={flagIcon} size='large' />
              <span className='brand_name'>{item.name}</span>
            </div>
          )
        },
      },

      {
        key: 'all_posts',
        title: '',
        width: 500,
        render: (_: any, item: FlagListObjType) => {
          return <FlagStats selectedBrand={selectedBrand} flag={item} hideThisRow={hideThisRow} key={item.id} />
        },
      },
    ]
  }, [selectedBrand, flagsList, snippetsFilter.days, hiddenRows])

  const onRowClick = (flag: FlagListObjType) => {
    if (filterChips?.flags?.value === flag.name) {
      let { flags, ...filter } = snippetsFilter
      setSnippetsFilter(filter)
      setFilterChips({
        ...filterChips,
        flags: {
          ...filterChips.flags,
          value: '',
        },
      })
      return
    }
    setSnippetsFilter({ ...snippetsFilter, flags: { name: flag.name, conditions: flag?.conditions || {} } })
    setFilterChips({
      ...filterChips,
      flags: {
        ...filterChips.flags,
        value: flag.name,
      },
    })
  }

  const selectedFlagId =
    filterChips?.flags?.value && flagsList.items.find((flag) => flag.name === filterChips.flags.value)?.id

  return (
    <Spin spinning={flagsLoading}>
      <p className='monitor-assets__graph__title'>KPIs</p>
      {/* <MentionsViewsSwitch view='mentions' onUpdateView={() => {}} /> */}
      <Table
        data={flagsList.items.filter((flag) => !hiddenRows.includes(flag.id))}
        columns={columns}
        className='brands_table'
        onRowClick={onRowClick}
        selectedRows={selectedFlagId ? [selectedFlagId] : []}
      />
      <div className='am_dashboard_pagination_container'>
        <DefaultPagination
          className='amc_pagination'
          totalCount={flagsList.total_count}
          currentPage={paginationValues.page}
          pageSize={paginationValues.pageSize}
          showPageSizeChanger={false}
          nextIcon={ArrowNextIcon}
          prevIcon={ArrowNextIcon}
          iconHeight={20}
          iconWidth={20}
          onChange={(page, pageSize) => {
            let requestParams: FlagListParamsType = {
              page,
              per_page: pageSize,
              sort: 'name:asc',
              is_active: true,
            }
            setPaginationValues({ page, pageSize })
            fetchFlagsListWithConditions({ params: requestParams })
          }}
          locale={{ items_per_page: '' }}
        />
      </div>
    </Spin>
  )
})

export default ActiveFlags
