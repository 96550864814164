import dayjs from 'dayjs'
import { Spin, Switch, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import Table, { IColumnType } from 'version2/components/common/Table/Table'
import FlagsHeader from 'version2/components/Flags/FlagsHeader/FlagsHeader'
import ScuccessModal from 'version2/components/Flags/ScuccessModal/ScuccessModal'
import { DefaultPagination } from 'version2/components/common/Pagination/Pagination'
import CreateFlagModal from 'version2/components/Flags/CreateFlagModal/CreateFlagModal'
import ActionButtons from 'version2/components/Investigate/Reports/ActionButtons/ActionButtons'
import { TopLevelNavigation } from 'version2/components/common/TopLevelNavigation/TopLevelNavigation'

import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { ReactComponent as ImpactLevelIcon } from 'assets/images/icons/flag/impact_level_icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/flag/search_round_type_icon.svg'
import { ReactComponent as CommunityIcon } from 'assets/images/icons/flag/community_type_icon.svg'
import { ReactComponent as NarrativeIcon } from 'assets/images/icons/flag/narrative_type_icon.svg'

import { displayApiError } from 'version2/utils/helper'

import { store } from 'store'

import { flagsAPI } from 'api/api'

import { UtilService } from 'services/Util/Util'
import { FlagCategoryType, FlagListObjType, FlagListParamsType, FlagType } from 'store/flags/types'

import './Flags.scss'
import usePermissions from 'version2/hooks/usePermissions'
import TagManager from 'react-gtm-module'

const TABLE_DATE_FORMATE = 'MMMM DD, YYYY'

type TypeRenderProps = {
  type: FlagType
}

const TypeRender = (props: TypeRenderProps) => {
  const { type } = props
  if (type === 'SEARCH_TERM') {
    return (
      <div className='fmc_t_type_container'>
        <Tooltip title='Flag Creation Rule' placement='bottom'>
          <SearchIcon aria-label='Flag Creation Rule' />
        </Tooltip>
        <span className='fmc_t_type_text'>Search</span>
      </div>
    )
  }
  if (type === 'COMMUNITIES') {
    return (
      <div className='fmc_t_type_container'>
        <Tooltip title='Flag Creation Rule' placement='bottom'>
          <CommunityIcon aria-label='Flag Creation Rule' />
        </Tooltip>
        <span className='fmc_t_type_text'>Community</span>
      </div>
    )
  }
  return (
    <div className='fmc_t_type_container'>
      <Tooltip title='Flag Creation Rule' placement='bottom'>
        <NarrativeIcon aria-label='Flag Creation Rule' />
      </Tooltip>
      <span className='fmc_t_type_text'>Narrative</span>
    </div>
  )
}

const Flags = observer(() => {
  const { flagsStore, breadcrumbStore, tenantsStore, userStore } = store
  const {
    fetchFlagsList,
    flagsLoading,
    flagsList,
    paginationValues,
    setPaginationValues,
    isFlagCreated,
    setIsFlagCreated,
    setFlagsList,
  } = flagsStore
  const { userId, userName, tenantId, roleId } = userStore
  const { rootPage, setBreadcrumbData } = breadcrumbStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)

  const flagsText = changeLabelTextBasedOnTheme('Flags', isCorporateCommunicationsTheme)

  const [selectedCategory, setSelectedCategory] = useState<FlagCategoryType>({
    category_id: '0',
    name: `All ${flagsText}`,
  })
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [openEditSuccessModal, setOpenEditSuccessModal] = useState<boolean>(false)
  const [editFlagData, setEditFlagData] = useState<FlagListObjType | null>(null)
  const [loader, setLoader] = useState('')

  const canActiveDeActive = !(usePermissions('flags') === 'turn_on_off' || usePermissions('flags') === 'create')

  useEffect(() => {
    const requestParams = {
      page: 1,
      per_page: paginationValues.pageSize,
      sort: 'name:asc',
    }
    setPaginationValues({ page: 1, pageSize: 10 })
    fetchFlagsList({ params: requestParams })
  }, [])

  useEffect(() => {
    if (isFlagCreated) {
      setSelectedCategory({
        category_id: '0',
        name: `All ${flagsText}`,
      })
      setSelectedRows([])
      setPaginationValues({ page: 1, pageSize: 10 })
    }
  }, [isFlagCreated])

  useEffect(() => {
    if (selectedCategory.name && rootPage) {
      const newBreadcrumbData = [
        { id: UtilService.generateUuid(), title: rootPage },
        { id: UtilService.generateUuid(), title: selectedCategory.name },
      ]

      setBreadcrumbData(newBreadcrumbData)
    }
  }, [selectedCategory.name, rootPage])

  const activeHandler = async (id: string, checked: boolean) => {
    try {
      setLoader(id)
      await flagsAPI.toggleFlagStatus({ ids: [id], status: checked ? 'activate' : 'deactivate' })
      const newItems = [...flagsList.items]
      const index = newItems.findIndex((item) => item.id === id)
      if (index > -1) {
        newItems[index].is_active = checked
        setFlagsList({ items: newItems, total_count: flagsList.total_count })
      }
      setLoader('')
    } catch (error: any) {
      setLoader('')
      const newItems = [...flagsList.items]
      const index = newItems.findIndex((item) => item.id === id)
      if (index > -1) {
        newItems[index].is_active = !checked
        setFlagsList({ items: newItems, total_count: flagsList.total_count })
      }
      displayApiError(error)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'toggle_flag',
        //@ts-ignore
        user_id: userId,
        user_name: userName,
        tenantId: tenantId,
        roleId: roleId,
        entityId: id,
        staus: checked ? 'activate' : 'deactivate',
      },
    })
  }

  const columns: IColumnType<FlagListObjType>[] = useMemo(() => {
    return [
      {
        key: 'checkbox',
        title: () => {
          //   return (
          //     <div
          //       className={`fmc_th_checkbox_container${
          //         selectedRows.length > 0 ? ' fmc_th_checkbox_container_selected' : ''
          //       }`}
          //       onClick={(e) => {
          //         e.stopPropagation()
          //         if (selectedRows.length) {
          //           return setSelectedRows([])
          //         }
          //         const ids = flagsList.items.map((res) => res.id)
          //         return setSelectedRows(ids)
          //       }}>
          //       {selectedRows.length > 0 ? (
          //         selectedRows.length === flagsList.items.length ? (
          //           <CheckIcon />
          //         ) : (
          //           <div className='fmc_th_cc_line'></div>
          //         )
          //       ) : null}
          //     </div>
          //   )
        },
        width: 44,
        render: (column, item) => {
          const { id } = item
          return (
            <Tooltip title='Select' placement='bottom'>
              <div
                className={`fmc_th_checkbox_container${
                  selectedRows.includes(id) ? ' fmc_th_checkbox_container_selected' : ''
                }`}
                aria-label='Select'
                onClick={(e) => {
                  e.stopPropagation()
                  // setSelectedRows((prevState) =>
                  //   prevState.includes(id) ? prevState.filter((res) => res !== id) : [...prevState, id],
                  // )
                  setSelectedRows((prevState) =>
                    prevState.includes(id) ? prevState.filter((res) => res !== id) : [id],
                  )
                }}>
                {selectedRows.includes(id) && <CheckIcon aria-label='Select' />}
              </div>
            </Tooltip>
          )
        },
      },
      {
        key: 'flag_name',
        title: 'Flag name',
        //   width: 310,
        render: (column, item) => {
          const { display_label } = item
          return <span className='fmc_t_flag_name'>{display_label}</span>
        },
      },
      {
        key: 'actions',
        title: '',
        width: 150,
        render: (column, item) => {
          return (
            <ActionButtons
              showTooltip={true}
              className='action_button_container'
              hideExtraIcons={true}
              hideDeteleIcon={true}
              shareHandler={() => {}}
              editHandler={() => setEditFlagData(item)}
            />
          )
        },
      },
      {
        key: 'impact_level',
        title: 'Impact level',
        width: 117,
        align: 'center',
        elementTitle: 'Internal Level',
        render: (column, item) => {
          const { impact } = item
          return <ImpactLevelIcon className={`fmc_t_impact_${impact.toLowerCase()}_icon`} />
        },
      },
      {
        key: 'type',
        title: 'Type',
        width: 157,
        elementTitle: 'Flag Creation Rule',
        render: (column, item) => {
          const { insight_type } = item
          return <TypeRender type={insight_type} />
        },
      },
      {
        key: 'date_created',
        title: 'Date created',
        //   width: 310,
        render: (column, item) => {
          const { created } = item
          return <span className='fmc_t_created_date'>{dayjs(created).format(TABLE_DATE_FORMATE)}</span>
        },
      },
      {
        key: 'active',
        title: 'Active',
        width: 92,
        render: (column, item) => {
          return (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <Switch
                disabled={canActiveDeActive}
                className='fmc_switch'
                checked={item.is_active}
                aria-label='Turn On/Off'
                onChange={(value) => activeHandler(item.id, value)}
                loading={item.id === loader}
              />
            </Tooltip>
          )
        },
      },
    ]
  }, [selectedRows, flagsList.items, loader])

  return (
    <>
      <TopLevelNavigation page={flagsText} />
      <Spin spinning={flagsLoading} className='flags_main_container_loader'>
        <div className='flags_main_container'>
          <FlagsHeader
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            addFlagHandler={() => {
              setOpenModal(true)
            }}
          />
          <div className='fmc_table_container'>
            <Table data={flagsList.items} columns={columns} className='fmc_table' />
          </div>
          <div className='fmc_pagination_container'>
            <DefaultPagination
              className='fmc_pagination'
              totalCount={flagsList.total_count}
              currentPage={paginationValues.page}
              pageSize={paginationValues.pageSize}
              showPageSizeChanger={false}
              // pageSizeOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              nextIcon={ArrowNextIcon}
              prevIcon={ArrowNextIcon}
              iconHeight={20}
              iconWidth={20}
              onChange={(page, pageSize) => {
                let requestParams: FlagListParamsType = {
                  page,
                  per_page: pageSize,
                  sort: 'name:asc',
                }
                setPaginationValues({ page, pageSize })
                fetchFlagsList({ params: requestParams })
                setSelectedRows([])
              }}
              locale={{ items_per_page: '' }}
            />
          </div>
        </div>
        {openModal && (
          <CreateFlagModal onClose={() => setOpenModal(false)} setOpenSuccessModal={() => setOpenSuccessModal(true)} />
        )}
        {editFlagData && (
          <CreateFlagModal
            isEditMode={true}
            onClose={() => setEditFlagData(null)}
            editData={editFlagData}
            setOpenSuccessModal={() => setOpenEditSuccessModal(true)}
          />
        )}
        {openSuccessModal && (
          <ScuccessModal
            onClose={() => {
              setIsFlagCreated(false)
              setOpenSuccessModal(false)
            }}
          />
        )}
        {openEditSuccessModal && (
          <ScuccessModal
            title='All changes saved'
            desc='You successfully saved all the changes to the selected flag.'
            onClose={() => {
              setIsFlagCreated(false)
              setOpenEditSuccessModal(false)
            }}
          />
        )}
      </Spin>
    </>
  )
})

export default Flags
