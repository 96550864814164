import { action, makeObservable, observable } from 'mobx'
import { exportAPI } from 'api/api'
import { MonitorStore } from './monitor.store'
import { IExportFeed } from 'models/models'
import dayjs from 'dayjs'

export class MonitorExportDownloadsStore extends MonitorStore {
  feedExportData: { newData: IExportFeed[]; oldData: IExportFeed[] } = { newData: [], oldData: [] }
  activeExport: IExportFeed | null = null

  constructor() {
    super()
    makeObservable(this, {
      feedExportData: observable,
      activeExport: observable,
      setFeedExportData: action.bound,
      setActiveExport: action.bound,
    })
  }

  setFeedExportData = (data: IExportFeed[]) => {
    const newData: IExportFeed[] = []
    const oldData: IExportFeed[] = []
    data.forEach((item) => {
      const isToday = dayjs(item.created).isSame(dayjs(), 'day')
      if (isToday) newData.push(item)
      else oldData.push(item)
    })
    this.feedExportData = { newData, oldData }
  }

  setActiveExport = (data: IExportFeed) => {
    this.activeExport = data
  }

  getAllFeedExports = async () => {
    const { data } = await exportAPI.getAllExportFeed()
    this.setFeedExportData(data)
  }

  resetExportStore = () => {
    this.feedExportData = {
      newData: [],
      oldData: [],
    }
    this.activeExport = null
  }
}
