import dayjs from 'dayjs'
import millify from 'millify'
import { action, makeObservable, observable } from 'mobx'
import { FormInstance } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { API, defineNarrativeAPI, monitorAPI, tagAPI, watchlistAPIs } from 'api/api'
import {
  ICommunity,
  ICommunityDropdown,
  INarrativeDropdown,
  ISnippetParams,
  IUniqueContent,
  NewsAPIResponse,
  TwitterAPIResponse,
  WatchlistChannel,
  monitorMode,
} from 'models/models'
import { MainStore } from '../main/main.store'
import { Dispatch, SetStateAction } from 'react'
import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'

type Channel = {
  platform: string
  channel_urls: string[]
}

interface WatchlistProps {
  name: string
  description: string
  channels: Channel[]
}

export class DefineWatchlistStore extends MainStore {
  watchlistData: WatchlistProps = {
    name: '',
    description: '',
    channels: [],
  }
  formError: string = ''
  isLoadingNarrative: boolean = false
  modalData: 'notify' | 'workLater' | 'loseChanges' | undefined = undefined
  formData: any = {}
  narrativeDetailsData: any = {}
  booleanSearchWord: string | null = null
  twitterMode: boolean = false

  matchingResults: number = 0
  showResults: boolean = false

  narrativeFilterData: INarrativeDropdown[] = []
  communityFilterData: ICommunityDropdown[] = []

  tenant_id: string = ''

  contentLoader: boolean = false

  userSearched: boolean = false

  matchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' = 'noSearch'
  textualMatchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' | 'loading' = 'noSearch'

  keywords: string[] = []

  listFilter: {
    filter: 'Total impressions' | 'Risk score' | 'Date'
    mode: 'Ascending' | 'Descending'
  } = { filter: 'Total impressions', mode: 'Descending' }

  tableCommunities: ICommunity[] = []

  //TODO: give them a proper type
  twitterPosts: any[] = []
  newsArticles: any[] = []

  twitterMatchingResults: number = 0
  newsMatchingResults: number = 0

  constructor() {
    super()
    makeObservable(this, {
      watchlistData: observable,
      formError: observable,
      isLoadingNarrative: observable,
      modalData: observable,
      formData: observable,
      booleanSearchWord: observable,
      twitterMode: observable,
      matchingResults: observable,
      showResults: observable,
      narrativeFilterData: observable,
      communityFilterData: observable,
      tenant_id: observable,
      contentLoader: observable,
      userSearched: observable,
      matchingResultsState: observable,
      textualMatchingResultsState: observable,
      keywords: observable,
      tableCommunities: observable,
      twitterPosts: observable,
      newsArticles: observable,
      twitterMatchingResults: observable,
      newsMatchingResults: observable,
      pagination: observable,
      textualUniqueContentList: observable,
      normalUniqueContentList: observable,
      narrativeDetailsData: observable,

      setModalData: action.bound,
      setFormData: action.bound,
      setBooleanSearchWord: action.bound,
      setTwitterMode: action.bound,
      setMatchingResults: action.bound,
      setShowResults: action.bound,
      setTenantId: action.bound,
      setUserSearched: action.bound,
      setTextualMatchingResultsState: action.bound,
      setKeywords: action.bound,
      setListFilter: action.bound,
      setTableCommunities: action.bound,
      getData: action.bound,
      setNarrativeDetailsData: action.bound,
    })
  }

  pagination = {
    current: 1,
    pageSize: 50,
    showSizeChanger: false,
  }

  textualUniqueContentList: IUniqueContent[] = []
  normalUniqueContentList: IUniqueContent[] = []

  get totalTextualResults() {
    return this.twitterMatchingResults + this.newsMatchingResults || 0
  }

  get videosTablePagination() {
    return {
      ...this.pagination,
      total: this.matchingResults,
      totalMillify: millify(this.matchingResults),
    }
  }

  get contentTabData() {
    return UtilService.getTableData({ source: this.snippets, table: 'videos' })
  }

  get contentNumMillify() {
    return millify(this.matchingResults || 0)
  }

  get twitterMatchingNumMilified() {
    return millify(this.twitterMatchingResults || 0)
  }

  get newsMatchingNumMilified() {
    return millify(this.newsMatchingResults || 0)
  }

  get textualUniqueContent() {
    if (this.textualUniqueContentList.length === 0) {
      const contentArr = UtilService.shuffleArray([...this.twitterPosts, ...this.newsArticles])

      const contentList: IUniqueContent[] = []
      const postIDs: string[] = []

      contentArr.forEach((element) => {
        if (contentList.length >= 5) return
        if (postIDs.includes(element.id)) return

        postIDs.push(element.id)

        contentList.push({
          title: element.snippet_text,
          website: element.sourcePlatform,
          id: element.id,
          imgLink: element.sourcePlatform === 'News' ? element?.extras?.articleThumbImg : ``,
          link: element.openSourceLink,
        })
      })

      this.setTextualUniqueContent(contentList)
    }
    return this.textualUniqueContentList
  }

  get normalUniqueContent() {
    if (this.normalUniqueContentList.length === 0) {
      const contentList: IUniqueContent[] = []
      const postIDs: string[] = []
      this.snippets.forEach((element) => {
        if (contentList.length >= 5) return
        if (postIDs.includes(element.title)) return

        postIDs.push(element.title)
        contentList.push({
          title: element.title,
          website: element.sourcePlatform,
          id: element.id,
          imgLink: '',
          link: element.openSourceLink,
        })
      })

      this.setNormalUniqueContent(contentList)
    }
    return this.normalUniqueContentList
  }

  get getModeConditions() {
    const { name, channels: watchlistChannels } = this.formData
    this.setBooleanSearchWord(name)

    return [{ channel_urls: watchlistChannels.map((channel: WatchlistChannel) => channel.channel_urls).flat() }]
  }

  setPagination = ({ current }: { current: number }) => {
    this.pagination = { ...this.pagination, current }
  }

  removeUniqueContentList = (itemID: string, mode: 'textual' | 'normal') => {
    switch (mode) {
      case 'textual':
        this.setTextualUniqueContent(this.textualUniqueContentList.filter((el) => el.id !== itemID))
        break
      case 'normal':
        this.setNormalUniqueContent(this.normalUniqueContentList.filter((el) => el.id !== itemID))
        break
    }
  }

  setTextualUniqueContent = (list: IUniqueContent[]) => {
    if (list.length === 0) return
    this.textualUniqueContentList = list
  }

  setNormalUniqueContent = (list: IUniqueContent[]) => {
    if (list.length === 0) return
    this.normalUniqueContentList = list
  }

  setBooleanSearchWord = (word: string) => {
    this.booleanSearchWord = word
  }

  resetUniqueContentLists = () => {
    this.textualUniqueContentList = []
    this.normalUniqueContentList = []
  }

  actionTag = async ({
    action,
    tagId,
    id,
    type,
  }: {
    action: 'apply' | 'remove'
    tagId: string
    id: string
    type?: monitorMode | 'channel'
  }) => {
    try {
      await tagAPI.addTag({ tagId, action, type: 'channel', id })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  updateMatchingResultsState = () => {
    if (this.matchingResults === 0) {
      if (!this.userSearched) {
        this.matchingResultsState = 'noSearch'
        return
      }
      this.matchingResultsState = 'noResultsBack'
      return
    }
    if (this.matchingResults > 6000000) {
      this.matchingResultsState = 'tooMany'
      return
    }
    this.matchingResultsState = 'normal'
  }

  updateUserSearched = () => {
    this.userSearched = true
  }

  setTableLoader = (state: boolean) => {
    this.contentLoader = state
  }

  cancelNarrative = () => {
    this.modalData = 'loseChanges'
  }

  closeModal = () => {
    this.modalData = undefined
  }

  fetchDropdowns = async () => {
    try {
      const [narrativesDropdownData, communitiesDropdownData] = await Promise.all([
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'narrative',
        }),
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'community',
        }),
      ])
      this.setCreatorsFilter(communitiesDropdownData.data.items)
      this.setNarrativesFilter(narrativesDropdownData.data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  saveWatchList = async (data: any) => {
    try {
      return await watchlistAPIs.saveWatchlist(data)
    } catch (err: any) {
      const response = err.response

      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  getNumberArrayFromID = ({ array, mode }: { array: string[]; mode: 'narrative' | 'communities' }) => {
    if (!array || array?.length === 0) return undefined

    switch (mode) {
      case 'narrative':
        return this.narrativeFilterData
          .filter((el) => array.includes(el.id))
          .map((el) => {
            return el.narrative_number
          })
      case 'communities':
        return this.communityFilterData
          .filter((el) => array.includes(el.id))
          .map((el) => {
            return el.community_number
          })
    }
  }

  setCreatorsFilter = (data: ICommunityDropdown[]) => {
    this.communityFilterData = data
  }

  setNarrativesFilter = (data: INarrativeDropdown[]) => {
    this.narrativeFilterData = data
  }

  setFormEror = (newError: string) => {
    this.formError = newError
  }

  setIsLoadingNarratve = (isLoadingNarrative: boolean) => {
    this.isLoadingNarrative = isLoadingNarrative
  }

  setFormData = (formData: any) => {
    this.formData = formData
  }

  setMatchingResults = (matchingResults: number) => {
    this.matchingResults = matchingResults
    this.updateMatchingResultsState()
  }

  setShowResults = (state: boolean) => {
    this.showResults = state
  }

  setTwitterMode = (state: boolean) => {
    this.twitterMode = state
  }

  addFormData = (newFormData: any) => {
    this.formData = { ...this.formData, ...newFormData }
  }

  setNarrativeDetailsData = (newFormData: any) => {
    this.narrativeDetailsData = { ...this.formData, ...newFormData }
  }

  fetchNewsNTwitter = async ({
    appendMode,
    searchTwitter = true,
    searchNews = true,
  }: {
    appendMode?: boolean
    searchTwitter?: boolean
    searchNews?: boolean
  }) => {
    try {
      if (this.twitterPosts.length >= this.twitterMatchingResults && this.twitterPosts.length > 0 && searchTwitter)
        return

      if (this.newsArticles.length >= this.newsMatchingResults && this.newsArticles.length > 0 && searchNews) return

      this.setTableLoader(true)
      this.setTextualMatchingResultsState('loading')

      const filterLookup = {
        'Total impressions': 'impression_count',
        'Risk score': 'risk_score',
        Date: 'upload_date',
        Ascending: 'asc',
        Descending: 'desc',
      }

      const {
        booleanSearch: search_expression,
        enableRetweet: enable_retweet,
        verifiedOnly: verified_only,
        profileCountry: profile_country,
      } = this.formData

      this.setBooleanSearchWord(search_expression)

      const [twitterData, newsData] = await Promise.all([
        defineNarrativeAPI.getTwitterPosts({
          search_expression,
          enable_retweet,
          verified_only,
          profile_country: profile_country,
          sort: `${filterLookup[this.listFilter.filter]}:${filterLookup[this.listFilter.mode]}`,
        }),
        defineNarrativeAPI.getNewsArticles({
          search_expression,
          sort: `${filterLookup[this.listFilter.filter]}:${filterLookup[this.listFilter.mode]}`,
        }),
      ])

      if (twitterData?.posts.length) {
        this.setIsFeedEmpty(false)
      }

      this.setTwitterMatchingResults(twitterData?.total_count)
      this.setNewsMatchingResults(newsData?.total_count)

      if (twitterData.total_count + newsData.total_count > 100000) {
        this.setTextualMatchingResultsState('tooMany')
      } else {
        this.setTextualMatchingResultsState('normal')
      }

      this.resetUniqueContentLists()

      this.setTwitterPosts(
        twitterData?.posts.map((post: TwitterAPIResponse) => {
          return {
            id: post.post_id,
            type: 'define',
            snippet_text: post.post_text,
            date: post.post_created,
            sourcePlatform: 'Twitter',
            openSourceLink: post.post_url,
            author: {
              channel_title: post.author_name,
              channel_thumb_url: post.profile_image_url,
            },
            extras: {
              retweets: post.post_retweet_count,
              likes: post.post_favorite_count,
              comments: post.post_reply_count,
            },
          }
        }),
      )
      this.setNewsArticles(
        newsData?.posts.map((post: NewsAPIResponse) => {
          return {
            id: uuidv4(),
            type: 'define',
            snippet_text: post.post_text,
            date: post.post_uploaded,
            sourcePlatform: 'News',
            title: post.post_title,
            openSourceLink: post.post_url,
            author: {
              channel_title: post.post_source,
              channel_thumb_url: '',
            },
            extras: {
              articleThumbImg: post.post_thumb,
              articleAuthor: post.author_name,
            },
          }
        }),
      )

      this.updateUserSearched()
      return true
    } catch (e: any) {
      //get the error message from the response
      this.generateError(e)
      this.setUserSearched(false)
      this.setTextualMatchingResultsState('noResultsBack')
      LogService.error(e)
      return false
    } finally {
      this.setTableLoader(false)
    }
  }

  generateError = (e: any, defaultMessage?: string) => {
    const querryError = e.response?.data?.includes('KeywordQueryValidator')
    console.log(e)

    if (querryError) {
      return UtilService.openNotification({
        type: 'error',
        message: 'Error during fetching snippets for the watchlist search due to query validation',
        description: (
          <>
            <br />
            <b>Diagnostic Information:</b>
            <br />
            {e.response.data}
          </>
        ),
      })
    }

    return UtilService.openNotification({
      type: 'error',
      message: 'Error during fetching snippets for the watchlist.',
      description: (
        <>
          <br />
          <b>Diagnostic Information:</b>
          <br />
          {e.response?.data}
        </>
      ),
    })
  }

  setTextualMatchingResultsState(state: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' | 'loading') {
    this.textualMatchingResultsState = state
  }

  fetchSnippets = async ({ appendMode }: ISnippetParams = {}) => {
    try {
      if (this.snippets.length >= this.matchingResults && this.snippets.length > 0) return

      this.setTableLoader(true)

      const { name } = this.formData
      this.setBooleanSearchWord(name)

      const page = Math.floor(this.snippets.length / 50) + 1
      const payload = await this.getSnippetFilterPayload()
      payload.page = page
      payload.per_page = 50

      const { data: res } = await monitorAPI.loadFeed(payload)

      if (res?.items.length) this.setIsFeedEmpty(false)
      this.setSnippetsTotal(res.total_count)
      this.setMatchingResults(res.total_count || 0)
      this.setShowResults(true)

      let creators: string[] = []
      const processedSnippets = res.items.map((snippet: any, index: number) => {
        if (snippet?.channel_id) creators.push(snippet?.channel_id)

        return {
          /* HACK: added the index so all the ids are unique */
          id: snippet?.post_id + index,
          snippet_text: snippet?.snippet_text,
          title: snippet?.post_title,
          date: snippet?.upload_date,
          riskScore: (snippet?.risk_score || 0).toFixed(0),
          views: snippet?.impression_count,
          viewsMilify: millify(snippet?.impression_count || 0) || 0,
          playSourceLink: snippet?.media_url,
          openSourceLink: snippet?.post_url,
          sourcePlatform: snippet?.platform,
          channelId: snippet?.channel_id,
          author: {},
          duration: snippet?.duration,
          offset: snippet?.offset,
          type: 'define',
          sentiment: snippet.sentiment,
        }
      })

      if (!appendMode) {
        this.resetUniqueContentLists()
        this.setSnippets(processedSnippets)
      } else this.appendSnippets(processedSnippets)

      this.setPagination({ current: Math.floor(this.snippets.length / this.pagination.pageSize || 1) })

      creators = creators.filter((creator, index) => creators.indexOf(creator) === index)
      if (creators) await this.getCreators(creators)

      this.updateUserSearched()
      this.setTableLoader(false)
    } catch (e: any) {
      if (e?.response?.status === 404) {
        this.setIsFeedEmpty(true)
        this.setShowResults(true)
        UtilService.openNotification({
          type: 'info',
          message: 'No results found',
        })
      } else {
        this.setUserSearched(false)
        this.setTableLoader(false)
        LogService.error(e)
        const response = e.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      }
    }
  }

  publishNarrative = async () => {
    try {
      const {
        name,
        description,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
        subset: parent_narrative_ids,
        linkContent: related_contents,
        twitterSwitch,
        enableRetweet,
        verifiedOnly,
        profileCountry,
      } = this.formData

      const resp = await defineNarrativeAPI.publishNarrative({
        name,
        type: twitterSwitch ? 'textual' : 'keyword',
        description,
        communities,
        keywords_expression,
        parent_narrative_ids,
        related_contents: related_contents,
        tenant_id: this.tenant_id,
        enable_retweet: enableRetweet,
        verified_only: verifiedOnly,
        profile_country: profileCountry,
      })

      if (resp.id) {
        this.setModalData('notify')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  republishWatchlist = async (watchlistId: string, watchlistData: any) => {
    try {
      this.setFormData(watchlistData)

      const resp = await watchlistAPIs.EditWatchlist(this.formData, watchlistId)
      if (resp === 'Watchlist was successfully updated') {
        this.setModalData('notify')
      }
      return resp
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setModalData = (data: 'notify' | 'workLater' | 'loseChanges') => {
    this.modalData = data
  }

  saveNarrative = async () => {
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
        twitterSwitch,
      } = this.formData

      const resp = await defineNarrativeAPI.saveNarrative({
        name,
        description,
        related_contents: related_contents ? [related_contents] : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: twitterSwitch ? 'textual' : 'keyword',
      })
      if (resp.id) {
        this.setModalData('workLater')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  getTimeSpan = ({ rangeInDays = 1 }: { rangeInDays?: number } = { rangeInDays: 1 }) => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const pastDate = dayjs().subtract(rangeInDays, 'days').format('YYYY-MM-DD')

    return {
      startDate: pastDate,
      endDate: currentDate,
    }
  }

  fetchCommunities = async () => {
    try {
      const { data } = await API.get({
        route: 'community',
        page: 1,
        pageSize: 50,
        getError: true,
      })

      const ids = data.items.map((el: any) => el.community_number)
      this.getCommunities(ids)
      this.setTableCommunities(data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setTableCommunities = (communities: ICommunity[]) => {
    this.tableCommunities = communities
  }

  searchQuery = async () => {
    try {
      let response
      const isntTwitterMode = !this.formData?.twitterSwitch
      this.resetSearchData(isntTwitterMode)
      if (isntTwitterMode) {
        response = this.fetchSnippets({ appendMode: false })
      } else {
        response = this.fetchNewsNTwitter({ appendMode: false })
      }
      this.fetchCommunities()
      this.parseQuerry()
      return response
    } catch (error) {
      return false
    }
  }

  parseQuerry = () => {
    const { booleanSearch } = this.formData

    const filteredKeywords: string[] = []

    const booleanTerms = ['and', 'or', 'not']
    const specialCharacters = ['"', "'", '(', ')']

    let keywords = booleanSearch

    specialCharacters.forEach((char) => {
      keywords = keywords.replaceAll(char, '')
    })

    keywords = keywords.split(' ')

    keywords.forEach((keyword: string) => {
      if (!booleanTerms.includes(keyword.toLowerCase())) filteredKeywords.push(keyword)
    })

    this.setKeywords(filteredKeywords)
  }

  setKeywords = (keywords: string[]) => {
    this.keywords = keywords
  }

  setTenantId = (id: string) => {
    this.tenant_id = id
  }

  getChannels = (channels: any) => {
    let parsedChannels: any = []
    // eslint-disable-next-line array-callback-return
    channels.map((channel: any, i: number) => {
      parsedChannels.push({
        platform: channel.platform,
        channel_urls: channel.channel_by_platform.map((plt: any) => plt.channel_url),
      })
    })
    return parsedChannels
  }

  enterEditMode = async (
    id: string,
    setWatchlist: Dispatch<SetStateAction<WatchlistProps>>,
    form: FormInstance<any>,
  ) => {
    try {
      const { data: watchlistData } = await API.get({ route: 'watchlist', id })
      const channels = this.getChannels(watchlistData.channels)

      setWatchlist((prevState: any) => ({
        ...prevState,
        name: watchlistData.name,
        description: watchlistData.description,
        channels,
      }))
      this.addFormData({
        name: watchlistData.name,
        description: watchlistData.description,
        channels,
      })

      form.setFieldsValue({
        name: watchlistData.name,
        description: watchlistData.description,
      })
      // setWatchlist((prevSSstate))
      // const {
      //   name,
      //   description,
      //   related_links: linkContent,
      //   parent_narratives: subset,
      //   communities: focusCommunities,
      //   boolean_query: booleanSearch,
      //   boolean_query: booleanSearchEdit,
      //   narrative_type: type,
      //   enable_retweet: enableRetweet,
      //   verified_only: verifiedOnly,
      // } = watchlistData

      // const links = linkContent.length > 0 ? linkContent.join(',') : undefined
      // this.setTwitterMode(type === 'TEXTUAL')
      // setWatchlist({
      //   name,
      //   description,
      //   linkContent: links,
      //   subset,
      //   focusCommunities,
      //   booleanSearch,
      //   booleanSearchEdit,
      // })

      // this.setFormData({
      //   name,
      //   description,
      //   linkContent,
      //   subset,
      //   focusCommunities,
      //   booleanSearch,
      //   booleanSearchEdit,
      //   enableRetweet,
      //   verifiedOnly,
      // })
      // this.searchQuery()
    } catch (e: any) {
      LogService.error({ message: 'Error in defineWatchlist store', error: e })
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  getData = async (id: string) => {
    try {
      const { data: watchlistData } = await API.get({ route: 'watchlist', id })
      const channels = this.getChannels(watchlistData.channels)
      this.setNarrativeDetailsData(watchlistData)
      this.addFormData({
        name: watchlistData.name,
        description: watchlistData.description,
        channels,
      })
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editNarrative = async (narrativeID?: string) => {
    if (!narrativeID) return
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
      } = this.formData

      const resp = await defineNarrativeAPI.editNarrative({
        id: narrativeID,
        name,
        description,
        related_contents: related_contents ? related_contents.join(',') : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: 'keyword',
      })
      if (resp.id) {
        this.setModalData('workLater')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setListFilter = (filter: any) => {
    this.listFilter = filter
    this.setPagination({ current: 1 })
    this.setSnippets([])
    this.fetchSnippets({})
  }

  setTwitterPosts = (posts: any[]) => {
    this.twitterPosts = posts
  }

  appendTwitterPosts = (posts: any[]) => {
    this.twitterPosts.push(posts)
  }

  setNewsArticles = (articles: any[]) => {
    this.newsArticles = articles
  }

  appendNewsArticles = (articles: any[]) => {
    this.newsArticles.push(articles)
  }

  setTwitterMatchingResults = (results: number) => {
    this.twitterMatchingResults = results
  }

  setNewsMatchingResults = (results: number) => {
    this.newsMatchingResults = results
  }

  setUserSearched = (state: boolean) => {
    this.userSearched = state
  }

  resetSearchData = (isntTwitterMode: boolean) => {
    if (isntTwitterMode) {
      this.setMatchingResults(0)
      this.snippets = []
      this.keywords = []
      this.pagination = {
        current: 1,
        pageSize: 50,
        showSizeChanger: false,
      }
    } else {
      this.twitterMatchingResults = 0
      this.newsMatchingResults = 0
      this.twitterPosts = []
      this.newsArticles = []
    }
  }

  resetStore = () => {
    this.isFeedEmpty = true
    this.showResults = false
    this.twitterMode = false
    this.formError = ''
    this.isLoadingNarrative = false
    this.modalData = undefined
    this.formData = {}
    this.narrativeFilterData = []
    this.communityFilterData = []
    this.userSearched = false
    this.listFilter = { filter: 'Total impressions', mode: 'Descending' }
    this.matchingResultsState = 'noSearch'
    this.twitterMatchingResults = 0
    this.newsMatchingResults = 0
    this.pagination = {
      current: 1,
      pageSize: 50,
      showSizeChanger: false,
    }
    this.snippets = []
    this.twitterPosts = []
    this.newsArticles = []
    this.twitterMatchingResults = 0
    this.newsMatchingResults = 0
    this.booleanSearchWord = ''
  }
}
