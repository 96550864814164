import { useEffect, useRef, useState } from 'react'
import { Dropdown, Select, Spin, Tooltip, notification } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import millify from 'millify'
import dayjs from 'dayjs'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { ICommunityLight, ILiteWatchlist, ISnippet, sourcePlatform } from 'models/models'
import { PreviewModal } from 'version2/components/common/Snippets/PreviewModal/PreviewModal'
import { CreatorInfo } from './CreatorInfo/CreatorInfo'
import { SurpriseSnippet } from 'version2/components/common/Snippets/SurpriseSnippet/SurpriseSnippet'
import { AddSnippetToBookmarksListModal } from 'version2/components/Investigate/Bookmarks/AddSnippetToBookmarksListModal/AddSnippetToBookmarksListModal'
import { RemoveSnippetFromBookmarkListsModal } from 'version2/components/Investigate/Bookmarks/RemoveSnippetFromBookmarkListsModal/RemoveSnippetFromBookmarkLists'
import { SUPPORTED_LANGUAGES } from 'settings/settings'

import { API, snippetFeedbackAPI } from 'api/api'
import { spotlightAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { action } from 'mobx'
import TagManager from 'react-gtm-module'

import { ReactComponent as OpenIcon } from 'assets/images/open-icon.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-new-icon.svg'
import { ReactComponent as BitchuteIcon } from 'assets/images/bitchute-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-icon.svg'
import { ReactComponent as TruthSocialIcon } from 'assets/images/truth-social-grey-icon.svg'
import { ReactComponent as ChanIcon } from 'assets/images/4chan-icon.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-icon.svg'
import { ReactComponent as PatriotsIcon } from 'assets/images/patriots-grey-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-logo.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-logo.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-logo.svg'
import { ReactComponent as NormalSentiment } from 'assets/images/sentiment-normal-icon.svg'
import { ReactComponent as NegativeSentiment } from 'assets/images/sentiment-negative-icon.svg'
import { ReactComponent as PositiveSentiment } from 'assets/images/sentiment-positive-icon.svg'
import { ReactComponent as SafetyIcon } from 'assets/images/risk-icon.svg'
import { ReactComponent as EnterIcon } from 'assets/images/enter-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/close-with-background-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as SolidLikeIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as SolidDislikeIcon } from 'assets/images/dislike-thumb-solid-icon.svg'
import { ReactComponent as RetweetIcon } from 'assets/images/retweet-icon.svg'
import { ReactComponent as CommentIcon } from 'assets/images/twitter-comment-icon.svg'
import { ReactComponent as LikeTweetIcon } from 'assets/images/twitter-like-icon.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/language-icon.svg'
import { ReactComponent as BookmarkIcon } from 'assets/images/icons/bookmark.svg'
import { ReactComponent as BookmarkOutlinedIcon } from 'assets/images/icons/bookmark-outlined.svg'
import { ReactComponent as CSpanIcon } from 'assets/images/cspan-black-icon.svg'
import { ReactComponent as WebSearchIcon } from 'assets/images/web-search-black-icon.svg'
import { ReactComponent as ConsiliumIcon } from 'assets/images/consilium-black-icon.svg'
import { ReactComponent as TickIcon } from 'assets/images/icons/outline/check.svg'
import { ReactComponent as CrossIcon } from 'assets/images/icons/outline/x.svg'
import { ReactComponent as BrighteonIcon } from 'assets/images/brighteon-logo-icon.svg'
import UserAvatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-logo-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-icon.svg'
import { ReactComponent as DescriptionSourceIcon } from 'assets/images/snippet-source-description-icon.svg'
import { ReactComponent as TitleSourceIcon } from 'assets/images/snippet-source-title-icon.svg'
import { ReactComponent as ImageDescriptionSourceIcon } from 'assets/images/snippet-source-image-description-icon.svg'
import { ReactComponent as ImageSourceIcon } from 'assets/images/snippet-source-image-text-icon.svg'
import { ReactComponent as TranscriptSourceIcon } from 'assets/images/snippet-source-transcript-icon.svg'

import './SnippetItem.scss'
import classNames from 'classnames'
import WarningModal from 'version2/components/common/Snippets/WarningModal/WarningModal'
import { Mode, SubStore } from 'types/types'

interface Props {
  mode: Mode
  subStore: SubStore
  snippet: ISnippet
  keywords?: string[]
  allWatchlist: ILiteWatchlist[]
  allCommunities: ICommunityLight[]
  showSurprise: (val: boolean) => void
}

export const MonitorSnippetItem = observer(
  ({ mode, subStore, snippet, keywords, allWatchlist, allCommunities, showSurprise }: Props) => {
    const search = useLocation().search

    const searchParams = new URLSearchParams(search)
    let listName = searchParams.get('list') || ''
    listName =
      listName && listName?.length > 0 ? listName[0].toUpperCase() + listName.slice(1) : mode === 'community' ? '' : ''

    const formattedDate = dayjs(snippet.date).format('MMM DD, YYYY')
    const isSnippetTooLong = snippet.snippet_text?.length > 500
    const isTwitterOrNews = snippet.sourcePlatform === 'Twitter' || snippet.sourcePlatform === 'News'
    const fullTextComponent: any[] = []
    const partTextComponent: any[] = []
    const sentimentIcons = {
      Positive: <PositiveSentiment height={20} width={20} className='snippet-item__sentiment' />,
      Neutral: <NormalSentiment height={20} width={20} className='snippet-item__sentiment' />,
      Negative: <NegativeSentiment height={20} width={20} className='snippet-item__sentiment' />,
    }
    const wrapperRef = useRef<any>()

    // eslint-disable-next-line no-unused-vars
    const iconsObj: { [K in Lowercase<sourcePlatform>]: JSX.Element } = {
      youtube: <YoutubeIcon />,
      bitchute: <BitchuteIcon />,
      rumble: <RumbleIcon />,
      podcast: <PodcastIcon />,
      tiktok: <TikTokIcon />,
      news: <NewsIcon />,
      twitter: <TwitterIcon />,
      reddit: <RedditIcon />,
      '4chan': <ChanIcon />,
      gab: <GabIcon />,
      truthsocial: <TruthSocialIcon />,
      'patriots.win': <PatriotsIcon />,
      vk: <VkIcon />,
      instagram: <InstagramIcon />,
      threads: <ThreadsIcon />,
      telegram: <TelegramIcon />,
      'c-span': <CSpanIcon />,
      websearch: <WebSearchIcon />,
      consilium: <ConsiliumIcon />,
      brighteon: <BrighteonIcon />,
      snapchat: <SnapchatIcon />,
      odysee: <OdyseeIcon />,
    }
    const snippetSourceIcons = {
      description: { logo: <DescriptionSourceIcon className='snippet-item__source' />, name: 'description' },
      title: { logo: <TitleSourceIcon className='snippet-item__source' />, name: 'title' },
      image_caption: {
        logo: <ImageDescriptionSourceIcon className='snippet-item__source' />,
        name: 'image description',
      },
      ocr: { logo: <ImageSourceIcon className='snippet-item__source--image' />, name: 'image text' },
      transcript: { logo: <TranscriptSourceIcon className='snippet-item__source' />, name: 'transcript' },
    }
    let fullText = snippet.snippet_text || ''
    let partText = snippet.snippet_text?.slice(0, 500) || ''
    let dropdownValue = 'close'
    let watchlistsName: string[] = []

    const [searchText, setSearchText] = useState<string>('')
    const [isWatchlistDropdownOpen, setIsWatchlistDropdownOpen] = useState<'open' | 'close' | '*'>('close')
    const [openedSnippet, setOpenedSnippet] = useState(false)
    const [openedMedia, setOpenedMedia] = useState<ISnippet | null>(null)
    const [openedWarning, setOpenedWarning] = useState<string>('')
    const [watchlistsSelected, setWatchlistsSelected] = useState<ILiteWatchlist[]>()
    const [newWatchlistsCreated, setNewWatchlistsCreated] = useState<string[]>([])
    const [allSelectedWatchlists, setAllSelectedWatchlists] = useState<string[]>()
    const [usedWatchlists, setUsedWatchlists] = useState<string[]>([])
    const [openCreateWatchlist, setOpenCreateWatchlist] = useState(false)
    const [isCreatorInfoOpen, setIsCreatorInfoOpen] = useState(false)
    const [likeDislikeTransition, setLikeDislikeTransition] = useState('alse')
    const [isBookmarkModalVisible, setIsBookmarkModalVisible] = useState(false)
    const [isRemoveBookmarkModalVisible, setIsRemoveBookmarkModalVisible] = useState(false)
    const [watchlists, setWatchlists] = useState(allWatchlist)

    const { loaderStore, monitorBookmarks, userStore, monitorWatchlist, navViewStore } = store
    const { activeItem, fetchSnippetsInfo } = monitorBookmarks
    const { isLoadingCreatorTags, isLoadingBookmarks } = loaderStore
    const { userBookmarkedSnippets, fetchUserBookmarkedSnippets } = store[`${subStore}Store`]
    const { currentRole } = userStore
    const { saveWatchList, republishWatchlist } = monitorWatchlist
    const { isNavOpen } = navViewStore

    useEffect(() => {
      setWatchlists(allWatchlist)
    }, [allWatchlist])

    useEffect(() => {
      if (isCreatorInfoOpen) {
        API.get({
          route: 'watchlist',
          page: 1,
          pageSize: 100,
          getError: true,
          sort: 'name',
        }).then((res) => {
          const data: string[] = []
          res?.data.items?.forEach((watchlist: ILiteWatchlist) => {
            const channels = watchlist.channels
            if (channels?.length > 0) {
              channels?.forEach((channel: any) => {
                if (
                  typeof channel?.platform === 'string' &&
                  channel?.platform?.toLowerCase() === snippet.sourcePlatform.toLowerCase()
                ) {
                  if (
                    channel.channel_by_platform.some((urls: any) => urls.channel_url === snippet.author.channel_url)
                  ) {
                    data.push(watchlist.name)
                  }
                }
              })
            }
          })
          setUsedWatchlists(data)

          const data2 = res?.data.items?.map((watchlist: any) => {
            let channels = []
            if (watchlist?.channels) {
              channels = watchlist?.channels.map((channel: any) => {
                const urls = channel?.channel_by_platform.map((item: any) => item.channel_url)
                return {
                  platform: channel.platform,
                  channel_urls: urls,
                }
              })
            }
            return { id: watchlist.id, name: watchlist?.name, channels: channels }
          })
          setWatchlists(data2)
        })
      }
    }, [isCreatorInfoOpen])

    useEffect(() => {
      function handleClickOutsideTooltip(event: any) {
        try {
          if (
            wrapperRef.current &&
            !wrapperRef.current?.contains(event?.target) &&
            typeof event?.target?.className === 'string' &&
            !event?.target?.className?.includes('ant-select')
          ) {
            closeCreateWatchlist()
          }
        } catch (error) {
          closeCreateWatchlist()
        }
      }
      document.addEventListener('mousedown', handleClickOutsideTooltip)
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideTooltip)
      }
    })
    const onCreatorInfoOpen = (state: boolean) => {
      setIsCreatorInfoOpen(state)
    }

    const onChange = (values: string[]) => {
      if (values.length > 0 && values[values.length - 1] === '*') {
        values.pop()
        dropdownValue = '*'
      } else dropdownValue = 'open'

      setAllSelectedWatchlists(values)

      const oldWatchlists = watchlists?.filter((Watchlist: ILiteWatchlist) =>
        values?.some((value: string) => Watchlist.name === value),
      )
      setWatchlistsSelected(oldWatchlists)

      const newWatchlists = values.filter((value: string) => !watchlists?.some((Watchlist) => Watchlist.name === value))
      setNewWatchlistsCreated(newWatchlists)
    }

    const onSave = (e: any, keyName?: string) => {
      if (e.key === 'Enter' || keyName === 'click') {
        e.preventDefault()
        e.stopPropagation()
        setIsCreatorInfoOpen(false)
        const removedWatchlists = usedWatchlists?.filter((value: string) => {
          return watchlistsSelected?.findIndex((watchlist: ILiteWatchlist) => watchlist.name === value) === -1
        })
        const newWatchlists = watchlistsSelected?.filter(
          (watchlist: ILiteWatchlist) => !usedWatchlists?.some((value: string) => watchlist.name === value),
        )
        if (removedWatchlists?.length) {
          removedWatchlists.forEach((value) => {
            const watchlist = watchlists?.find((watchlist: ILiteWatchlist) => watchlist.name === value)
            if (watchlist) {
              const channels = watchlist.channels
              if (channels?.length > 0) {
                const otherPlatforms = channels?.filter((channel: any) => {
                  return (
                    typeof channel?.platform === 'string' &&
                    channel?.platform?.toLowerCase() !== snippet.sourcePlatform.toLowerCase()
                  )
                })
                const platforms = channels?.filter((channel: any) => {
                  return (
                    typeof channel?.platform === 'string' &&
                    channel?.platform?.toLowerCase() === snippet.sourcePlatform.toLowerCase()
                  )
                })

                if (platforms.length > 0) {
                  const urls = platforms[0]?.channel_urls?.filter((url: any) => {
                    return url !== snippet.author.channel_url
                  })
                  const channels = [...otherPlatforms]

                  if (urls.length > 0) {
                    channels.push({
                      platform: snippet.sourcePlatform.toLowerCase(),
                      channel_urls: urls,
                    })
                  }
                  republishWatchlist(watchlist.id, {
                    channels: channels,
                  })
                }
              }
            }
          })
        }
        if (newWatchlists?.length) {
          newWatchlists.forEach((watchlist) => {
            watchlistsName.push(watchlist.name)

            republishWatchlist(watchlist.id, {
              channels: [
                ...watchlist.channels,
                {
                  platform: snippet.sourcePlatform,
                  channel_urls: [snippet.author.channel_url],
                },
              ],
            })
          })
        }

        const promises: any = []
        newWatchlistsCreated?.forEach((name) => {
          watchlistsName.push(name)
          promises.push(
            saveWatchList({
              name: name,
              description: '',
              channels: [
                {
                  platform: snippet.sourcePlatform,
                  channel_urls: [snippet.author.channel_url],
                },
              ],
            }),
          )
        })
        setSearchText('')
        openNotification()
        setWatchlistsSelected([])
        setNewWatchlistsCreated([])
        setOpenCreateWatchlist(false)
      }
    }

    const onPreviewClick = () => {
      if (snippet.sourcePlatform === 'Twitter') setOpenedWarning(snippet.openSourceLink)
      // window.open(snippet.openSourceLink || '#', '_blank', 'noopener,noreferrer')
      else setOpenedMedia(snippet)
    }

    const feedbackGtm = (snippet: any) => {
      // google analytics snippet feedback rating
      TagManager.dataLayer({
        dataLayer: {
          event: 'feedback_rating',
          feedbackValue: snippet.feedback,
          channelId: snippet.channelId,
          postId: snippet.id,
          platform: snippet.sourcePlatform,
          narrativeId: snippet.narratives,
          snippetText: snippet.snippet_text,
          user_id: userStore.userId,
          user_name: userStore.userName,
          tenantId: userStore.tenantId,
          roleId: userStore.roleId,
        },
      })
    }

    const thumbsUpFeedback = action((valueForFeedback: any) => {
      snippetFeedbackAPI.recordSnippetFeedback({
        feedbackValue: valueForFeedback,
        channelId: snippet.channelId,
        postId: snippet.id,
        offset: snippet.offset,
        platform: snippet.sourcePlatform,
        narrativeId: snippet.narratives,
        snippetFilters: snippet.snippetsFilter,
        communities: snippet.communities,
      })
      snippet.feedback = valueForFeedback === 'THUMBS_UP' ? 'like' : 'dislike'
      setLikeDislikeTransition('snippet-item__footer__right__like-dislike--active')
      setTimeout(() => {
        setLikeDislikeTransition('')
      }, 500)
    })

    const addSnippetToSpotlight = async () => {
      try {
        const response = await spotlightAPI.spotlightActions({
          action: 'ADD_SNIPPET',
          snippet: {
            post_id: snippet.id,
            snippet_text: snippet.snippet_text,
            post_url: snippet.openSourceLink,
            impression_count: snippet.views,
            upload_date: snippet.date,
            platform: snippet.sourcePlatform,
            channel_name: snippet.author.channel_title ? snippet.author.channel_title : 'n/a',
            offset: snippet.offset,
          },
        })
        UtilService.openNotification({ type: 'info', message: 'Success', description: 'Snippet added successfully!' })
        return response
      } catch (error: any) {
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
        console.error('Error fetching data:', error)
      }
    }

    const getLanguage = (lang: string) => {
      return Object.keys(SUPPORTED_LANGUAGES).find((key) => SUPPORTED_LANGUAGES[key] === lang) || ''
    }

    const closeCreateWatchlist = () => {
      setIsWatchlistDropdownOpen('close')
      dropdownValue = 'close'
      setOpenCreateWatchlist(false)
    }

    const openNotification = () => {
      if (watchlistsName.length > 0) {
        notification.info({
          icon: (
            <img
              className='snippet-item__tag-input-container__left__user-img'
              src={snippet?.author.channel_thumb_url ?? UserAvatar}
              alt=''
              onError={(e: any) => {
                e.target.src = UserAvatar
              }}
            />
          ),
          message: <span>{` ${snippet?.author?.channel_title} added to following watchlists:`}</span>,
          description: (
            <div>
              {watchlistsName.map((name) => (
                <span className='snippet-item__notification__description'>{name}</span>
              ))}
            </div>
          ),
          placement: 'bottomLeft',
          className: classNames('snippet-item__notification', { 'snippet-item__notification-margin': isNavOpen }),
        })
      }
    }

    if (snippet.type === 'define') {
      keywords?.forEach((keyword) => {
        if (fullText.match(new RegExp(`\\b${keyword}\\b`, 'i'))) {
          partText = partText.replace(new RegExp(`\\b${keyword}\\b`, 'ig'), `||${keyword}||`)
          fullText = fullText.replace(new RegExp(`\\b${keyword}\\b`, 'ig'), `||${keyword}||`)
        }
      })

      fullText.split('||').forEach((shard: string, index) => {
        if (index % 2 === 0) {
          fullTextComponent.push(shard)
        } else {
          fullTextComponent.push(<span key={uuidv4()}>{shard}</span>)
        }
      })
      partText.split('||').forEach((shard: string, index) => {
        if (index % 2 === 0) {
          partTextComponent.push(shard)
        } else {
          partTextComponent.push(<span key={uuidv4()}>{shard}</span>)
        }
      })
    } else {
      fullTextComponent.push(fullText)
      partTextComponent.push(partText)
    }

    if (snippet.type === 'surprise') {
      return <SurpriseSnippet showSurprise={showSurprise} />
    }

    return (
      <div className='snippet-item'>
        <p className='snippet-item__description'>
          <span
            dangerouslySetInnerHTML={{
              __html: String(
                isSnippetTooLong ? (openedSnippet ? fullTextComponent : partTextComponent) : fullTextComponent,
              ),
            }}
          />
          {isSnippetTooLong && (
            <>
              ...
              {openedSnippet ? (
                <span onClick={() => setOpenedSnippet(false)} className='snippet-item__button'>
                  less
                </span>
              ) : (
                <span onClick={() => setOpenedSnippet(true)} className='snippet-item__button'>
                  more
                </span>
              )}
            </>
          )}
        </p>
        {!openCreateWatchlist ? (
          <div className='snippet-item__footer'>
            <div className='snippet-item__footer__left'>
              <div className='snippet-item__footer__left__list'>
                {iconsObj[snippet.sourcePlatform.toLowerCase() as keyof typeof iconsObj]}

                {Object.keys(snippet.author).length > 0 && (
                  <>
                    <div className='snippet-item__dot' />
                    <Dropdown
                      trigger={['click']}
                      onOpenChange={(open) => {
                        onCreatorInfoOpen(open)
                      }}
                      overlay={
                        <CreatorInfo
                          creator={snippet.author}
                          setOpenCreateWatchlist={setOpenCreateWatchlist}
                          setOpenUrl={(val) => setOpenedWarning(val)}
                          watchlists={usedWatchlists}
                          allCommunities={allCommunities}
                        />
                      }>
                      <div className='snippet-item__creator'>
                        <img
                          className='snippet-item__tag-input-container__left__user-img'
                          src={snippet?.author.channel_thumb_url ?? UserAvatar}
                          alt=''
                          onError={(e: any) => {
                            e.target.src = UserAvatar
                          }}
                        />
                        <span>{snippet?.author?.channel_title} </span>
                      </div>
                    </Dropdown>
                  </>
                )}

                {!isTwitterOrNews && (
                  <>
                    <div className='snippet-item__dot' />
                    <span className='snippet-item__footer__left__description'>
                      {snippet.viewsMilify} view{parseInt(snippet?.viewsMilify || '0') === 1 || 's'}
                    </span>
                  </>
                )}
                <div className='snippet-item__dot' />
                <span className='snippet-item__footer__left__description'>{formattedDate}</span>
                {snippet.sourcePlatform === 'Twitter' && (
                  <>
                    <div className='snippet-item__dot' />
                    <div className='snippet-item__footer__left__tweet'>
                      <CommentIcon className='snippet-item__footer__left__tweet-icon' />
                      <span className='snippet-item__footer__left__tweet-text'>
                        {millify(snippet?.extras?.comments || 0)}
                      </span>
                      <RetweetIcon className='snippet-item__footer__left__tweet-icon' />
                      <span className='snippet-item__footer__left__tweet-text'>
                        {millify(snippet?.extras?.retweets || 0)}
                      </span>
                      <LikeTweetIcon className='snippet-item__footer__left__tweet-icon' />
                      <span className='snippet-item__footer__left__tweet-text'>
                        {millify(snippet?.extras?.likes || 0)}
                      </span>
                    </div>
                  </>
                )}
                {!isTwitterOrNews && (
                  <>
                    <div className='snippet-item__dot' />
                    <Tooltip
                      overlayClassName='snippet-item__footer__left__tooltip'
                      title={
                        <span>
                          Risk Score assigned based on the community and platform information associated with creator.
                          Range between 0% to 100%.
                        </span>
                      }
                      placement='bottom'>
                      <div className='snippet-item__details'>
                        <SafetyIcon className='snippet-item__source--image' />
                        <span className='snippet-item__footer__left__percentage'>{snippet.riskScore}%</span>
                      </div>
                    </Tooltip>
                  </>
                )}
                {snippet.sentiment && (
                  <>
                    <div className='snippet-item__dot' />
                    <Tooltip
                      overlayClassName='snippet-item__footer__left__tooltip'
                      title={
                        <span>
                          This snippet expresses a {snippet.sentiment} sentiment as identified by Pendulums ML model.
                        </span>
                      }
                      placement='bottom'>
                      <div className='snippet-item__details'>{sentimentIcons[snippet.sentiment]}</div>
                    </Tooltip>
                  </>
                )}
                {snippet.source && (snippet.translatedSnippet?.translated_text?.length || 0) === 0 && (
                  <>
                    <div className='snippet-item__dot' />
                    <Tooltip
                      overlayClassName='snippet-item__footer__left__tooltip2'
                      title={<span>{snippetSourceIcons[snippet.source].name}</span>}
                      placement='bottom'>
                      <div className='snippet-item__details'>{snippetSourceIcons[snippet.source].logo}</div>
                    </Tooltip>
                  </>
                )}
              </div>
              {(snippet.translatedSnippet?.translated_text?.length || 0) > 0 && (
                <div className='snippet-item__footer__left__translation-div'>
                  <Tooltip placement='bottom' title='Translate'>
                    <LanguageIcon aria-label='Translate' />
                  </Tooltip>
                  <span className='snippet-item__footer__left__translation-div__text'>{`Translated from ${getLanguage(
                    snippet.translatedSnippet.original_lang,
                  )}`}</span>
                </div>
              )}
            </div>

            <div className='snippet-item__footer__right'>
              <div className='snippet-item__footer__right__div1'>
                {snippet.documentId && userBookmarkedSnippets.includes(snippet.documentId!) && (
                  <button
                    className='snippet-item__footer__right__bookmark-btn'
                    onClick={() => setIsRemoveBookmarkModalVisible(true)}>
                    <Tooltip placement='bottom' title='Bookmark'>
                      <BookmarkIcon aria-label='Bookmark' />
                    </Tooltip>
                    <span>Remove</span>
                  </button>
                )}

                {snippet.documentId && !userBookmarkedSnippets.includes(snippet.documentId!) && (
                  <button
                    className='snippet-item__footer__right__bookmark-btn'
                    onClick={() => setIsBookmarkModalVisible(true)}>
                    <Tooltip placement='bottom' title='Bookmark'>
                      <BookmarkOutlinedIcon aria-label='Bookmark' />
                    </Tooltip>
                  </button>
                )}
                {currentRole === 'DEPLOYMENT_STRATEGIST' && (
                  <button className='snippet-item__footer__right__bookmark-btn' onClick={addSnippetToSpotlight}>
                    <span>Spotlight</span>
                  </button>
                )}
              </div>
              <div className='snippet-item__footer__right__div2'>
                <button className='snippet-item__footer__right__like-dislike-btn'>
                  <div className={'snippet-item__footer__right__like-dislike'}>
                    {snippet.feedback === 'like' ? (
                      <SolidLikeIcon
                        className={likeDislikeTransition}
                        onClick={() => {
                          let valueForFeedback = 'THUMBS_UP'
                          thumbsUpFeedback(valueForFeedback)
                          feedbackGtm(snippet)
                        }}
                      />
                    ) : (
                      <LikeIcon
                        className='snippet-item__footer__right__like-dislike_like_icon'
                        onClick={() => {
                          let valueForFeedback = 'THUMBS_UP'
                          thumbsUpFeedback(valueForFeedback)
                          feedbackGtm(snippet)
                        }}
                      />
                    )}
                    <span>|</span>
                    {snippet.feedback === 'dislike' ? (
                      <SolidDislikeIcon
                        className={likeDislikeTransition}
                        onClick={() => {
                          let valueForFeedback = 'THUMBS_DOWN'
                          thumbsUpFeedback(valueForFeedback)
                          feedbackGtm(snippet)
                        }}
                      />
                    ) : (
                      <DislikeIcon
                        className='snippet-item__footer__right__like-dislike_like_icon'
                        onClick={() => {
                          let valueForFeedback = 'THUMBS_DOWN'
                          thumbsUpFeedback(valueForFeedback)
                          feedbackGtm(snippet)
                        }}
                      />
                    )}
                  </div>
                </button>
                <button className='snippet-item__footer__right__preview-btn' onClick={onPreviewClick}>
                  <OpenIcon height={18} />
                  <span>Preview</span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Spin spinning={isLoadingCreatorTags}>
            <div className='snippet-item__tag-input-container' ref={wrapperRef}>
              <Select
                popupClassName='snippet-item__popup-class'
                className={classNames({
                  'snippet-item__create-watchlist-placeholder':
                    isWatchlistDropdownOpen === '*' && searchText.length === 0,
                  'snippet-item__create-watchlist-placeholder2':
                    isWatchlistDropdownOpen === '*' && searchText.length > 0,
                })}
                defaultValue={usedWatchlists}
                value={allSelectedWatchlists}
                mode={'tags'}
                style={{ width: '100%' }}
                open={isWatchlistDropdownOpen === 'open'}
                placeholder={
                  isWatchlistDropdownOpen !== '*' ? (
                    <div className='snippet-item__tag-input-container__left'>
                      <div>
                        <img
                          className='snippet-item__tag-input-container__left__user-img'
                          src={snippet?.author.channel_thumb_url ?? UserAvatar}
                          alt=''
                          onError={(e: any) => {
                            e.target.src = UserAvatar
                          }}
                        />
                        <span className='snippet-item__tag-input-container__left__title'>Add to Watchlist</span>
                        <span className='snippet-item__tag-input-container__left__breaker'>|</span>
                        <span>Search</span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                }
                onInputKeyDown={onSave}
                onChange={onChange}
                onClick={() => {
                  if (dropdownValue !== '*') setIsWatchlistDropdownOpen('open')
                  else setIsWatchlistDropdownOpen('*')
                }}
                onSearch={(value) => setSearchText(value)}>
                <Select.Option className='snippet-item__add-watchlist__first-option' value={'*'}>
                  + Create new watchlist
                </Select.Option>

                {watchlists?.map((watchlist) => {
                  return (
                    <Select.Option
                      className={'aria-selected snippet-item__add-watchlist__options'}
                      value={watchlist.name}
                      key={watchlist.id}>
                      <span className='ant-select-span-option-'>{watchlist.name}</span>
                    </Select.Option>
                  )
                })}
              </Select>
              {isWatchlistDropdownOpen !== '*' ? (
                <>
                  <div className='snippet-item__tag-input-container__right' onClick={(e) => onSave(e, 'click')}>
                    <EnterIcon />
                    <span className='snippet-item__tag-input-container__right__enter'>Enter</span>
                  </div>

                  <CloseIcon
                    className='snippet-item__tag-input-container__right__close'
                    onClick={closeCreateWatchlist}
                  />
                </>
              ) : (
                <>
                  <CrossIcon
                    onClick={() => {
                      let array: any = allSelectedWatchlists
                      array?.pop()
                      setAllSelectedWatchlists(array)

                      array = newWatchlistsCreated
                      array?.pop()
                      setNewWatchlistsCreated(array)

                      setSearchText('')
                      dropdownValue = 'open'
                      setIsWatchlistDropdownOpen('open')
                    }}
                  />
                  <TickIcon
                    className='snippet-item__tag-input-container__right__close'
                    onClick={() => {
                      setSearchText('')
                      dropdownValue = 'open'
                      setIsWatchlistDropdownOpen('open')
                    }}
                  />
                </>
              )}
            </div>
          </Spin>
        )}

        {isBookmarkModalVisible && (
          <AddSnippetToBookmarksListModal
            snippet={snippet}
            isModalVisible
            closeModal={() => {
              setIsBookmarkModalVisible(false)
              fetchUserBookmarkedSnippets()
            }}
          />
        )}

        {isRemoveBookmarkModalVisible && (
          <RemoveSnippetFromBookmarkListsModal
            snippet={snippet}
            isModalVisible
            closeModal={(shouldRefresh: boolean = false) => {
              setIsRemoveBookmarkModalVisible(false)
              fetchUserBookmarkedSnippets()

              if (shouldRefresh && mode === 'bookmark') {
                fetchSnippetsInfo(activeItem!.id, listName)
              }
            }}
            isLoading={isLoadingBookmarks}
          />
        )}

        {openedMedia && (
          <PreviewModal
            openedMedia={openedMedia}
            onOpenSource={(val: string) => setOpenedWarning(val)}
            onModalClose={() => {
              setOpenedMedia(null)
            }}
          />
        )}
        {openedWarning && (
          <WarningModal
            link={openedWarning}
            onContinue={() => {
              setOpenedWarning('')
              window.open(openedWarning || '#', '_blank', 'noopener,noreferrer')
            }}
            onClose={() => {
              setOpenedWarning('')
            }}
          />
        )}
      </div>
    )
  },
)
