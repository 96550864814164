import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { ExecutiveAPIPayload } from 'models/models'

export const addPerson = async (person: ExecutiveAPIPayload) => {
  let url = `${process.env.REACT_APP_API}/${ROUTES.personnel}`

  const { data } = await axios.post(url, person)

  return data
}
