import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { ExecutiveAPIPayload } from 'models/models'

export const updatePerson = async (personId: string, personData: Partial<ExecutiveAPIPayload>) => {
  let url = `${process.env.REACT_APP_API}/${ROUTES.personnel}/${personId}`

  const { data } = await axios.put(url, personData)

  return data
}
