import { useEffectOnce, useUnmount } from 'react-use'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { List } from 'antd'
import VirtualList from 'rc-virtual-list'
import millify from 'millify'

import { DataFetching } from 'components/common/DataFetching/DataFetching'
import { MonitorSnippetItem } from '../MonitorSnippetItem/SnippetItem'
import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'
import { monitorMode, monitorSnippetPage } from 'models/models'

import './SnippetPage.scss'

interface Props {
  mode: monitorMode
  page: monitorSnippetPage
  showSearchChip: boolean
  searchedDataResults: number
}

export const MonitorSnippetList = observer(({ mode, page, showSearchChip, searchedDataResults }: Props) => {
  const ContainerHeight = 570

  const { monitorNarratives } = store
  const {
    snippetsFilter,
    isFeedEmpty,
    liteWatchlists,
    liteCommunities,
    snippetsWithSurprises,
    snippets,
    fetchLiteWatchlists,
    showSurprise,
    fetchSnippets,
    setIsFeedEmpty,
  } = store[`monitor${STORE_LOOKUP_DICTIONARY[mode]}`]
  const { activeNarrativeTitle } = monitorNarratives

  useEffectOnce(() => {
    fetchLiteWatchlists()
  })

  useUnmount(() => {
    setIsFeedEmpty(true)
  })

  const checkFeed = () => {
    if (!isFeedEmpty && !snippets.length) {
      return <DataFetching title={activeNarrativeTitle} page={'notFound'} daysFilters={snippetsFilter.days} />
    } else {
      return (
        <VirtualList
          className='c-snippets-list__list'
          data={snippetsWithSurprises}
          height={ContainerHeight}
          itemHeight={220}
          itemKey='id'
          onScroll={(e) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) fetchSnippets()
          }}>
          {(item) => {
            return (
              <div key={item.id}>
                <MonitorSnippetItem
                  mode={mode}
                  page={page}
                  snippet={item}
                  allWatchlist={liteWatchlists}
                  allCommunities={liteCommunities}
                  showSurprise={showSurprise}
                />
              </div>
            )
          }}
        </VirtualList>
      )
    }
  }
  return (
    <>
      <div className='snippet-list__wrapper'>
        <div className='snippet-list__container'>
          {showSearchChip && searchedDataResults > 0 && (
            <>
              <span className='snippet-list__search-result'>{millify(searchedDataResults) + ' results'}</span>
            </>
          )}
        </div>

        <List>{checkFeed()}</List>
      </div>
    </>
  )
})
