import { API, tagAPI } from 'api/api'
import { makeObservable, observable, action } from 'mobx'
import { ICOMMUNITY, INARRATIVE, ITAG_DETAILS } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { ManageStore } from './manage.store'

export class ManageTagsStore extends ManageStore {
  store: 'tag' | 'community' | 'narrative' = 'tag'
  selectedTagID: string | number = ''
  selectedTagName?: string
  selectedTagFollowingStatus: boolean = false

  narrative: INARRATIVE[] = []
  community: ICOMMUNITY[] = []
  creator: any[] = []
  post: any[] = []
  subjectedEntity: INARRATIVE | ICOMMUNITY = {} as INARRATIVE | ICOMMUNITY

  constructor() {
    super()

    makeObservable(this, {
      store: observable,
      selectedTagID: observable,
      selectedTagName: observable,
      selectedTagFollowingStatus: observable,
      narrative: observable,
      community: observable,
      creator: observable,
      post: observable,
      setSelectedTagID: action.bound,
      setSelectedTagName: action.bound,
      setSelectTagFollowingStatus: action.bound,
      updateTagStatusForDetail: action.bound,
      updateTagStatus: action.bound,
      saveTagDetailsData: action.bound,
      actionToAddOrRemoveTag: action.bound,
      handleEditCloseModal: action.bound,
    })
  }

  get sortTableTab1() {
    return this[`sort`].fieldName?.length && this[`sort`].direction?.length
      ? `${this[`sort`].fieldName}:${this[`sort`].direction.replace('end', '')}`
      : 'label'
  }

  get sortTableTab3() {
    return this[`sort`].fieldName?.length && this[`sort`].direction?.length
      ? `${this[`sort`].fieldName}:${this[`sort`].direction.replace('end', '')}`
      : 'label'
  }

  get getSelectedTagID() {
    return this.selectedTagID
  }

  get getSelectedTagFollowingStatus() {
    return this.selectedTagFollowingStatus
  }

  get tableTabDetailsData1() {
    return UtilService.getTableData({ source: this.dataTableTab1, table: this.storeType })
  }

  get tableTabDetailsData2() {
    return UtilService.getTableData({ source: this.dataTableTab2, table: this.storeType })
  }

  get tableTabDetailsData3() {
    return UtilService.getTableData({ source: this.dataTableTab3, table: this.storeType })
  }

  get tableTabDetailsData4() {
    return UtilService.getTableData({ source: this.dataTableTab3, table: this.storeType })
  }

  get narrativesTableData() {
    return UtilService.getTagDetailsData({ source: this.narrative, type: 'narrative' })
  }

  get communitiesTableData() {
    return UtilService.getTagDetailsData({ source: this.community, type: 'community' })
  }

  get postsTableData() {
    return UtilService.getTagDetailsData({ source: this.post, type: 'post' })
  }

  get creatorsTableData() {
    return UtilService.getTagDetailsData({ source: this.creator, type: 'creator' })
  }

  setSelectedTagID = (id: string | number) => {
    this.selectedTagID = id
  }

  setSelectedTagName = (name: string) => {
    this.selectedTagName = name
  }

  setSelectTagFollowingStatus = (following: boolean) => {
    this.selectedTagFollowingStatus = following
  }

  updateTagStatusForDetail = async (tagID: string | number, followingStatus: boolean) => {
    try {
      await tagAPI.changeFollowingStatus(tagID, followingStatus)
      this.selectedTagFollowingStatus = !this.selectedTagFollowingStatus
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  updateTagStatus = async (tagID: string | number, followingStatus: boolean) => {
    try {
      await tagAPI.changeFollowingStatus(tagID, followingStatus)

      for (let tab: 1 | 2 | 3 = 1; tab <= 3; tab++)
        if (tab === 1 || tab === 2 || tab === 3) {
          this.saveTableData({
            tab,
            data: this[`dataTableTab${tab}`].map((el: any) => {
              return el.id !== tagID ? el : { ...el, following: followingStatus }
            }),
          })
        }

      await this.fetchData({ tab: 2 })
      this.setSelectTagFollowingStatus(this.selectedTagFollowingStatus)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchTagDetails = async (id: number | string) => {
    try {
      const { data } = await API.get({
        route: this.storeType,
        id,
        getError: true,
      })

      this.saveTagDetailsData(data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  saveTagDetailsData = (data: ITAG_DETAILS) => {
    this.narrative = data?.entity_details?.NARRATIVE
    this.creator = data?.entity_details?.CHANNEL
    this.post = data?.entity_details?.POST
    this.community = data?.entity_details?.COMMUNITY
  }

  actionToAddOrRemoveTag = async ({
    action,
    tagId,
    id,
    type,
  }: {
    action: 'remove' | 'apply'
    tagId: string
    id: string
    type: 'narrative' | 'community' | 'post' | 'creator'
  }) => {
    try {
      await tagAPI.addOrRemoveTag({ tagId, action, id, type })
      if (action === 'remove') {
        this.subjectedEntity = {} as INARRATIVE | ICOMMUNITY

        this.subjectedEntity = this[type].filter((element) => element.id === id)[0]
        this[type] = this[type].filter((element) => element.id !== id)
      }
      if (action === 'apply') this[type].push(this.subjectedEntity)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  handleEditCloseModal = (label: string) => {
    this.selectedTagName = label
  }
}
