import { Spin, Table, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { useState } from 'react'

import { ReactComponent as ExpandIcon } from 'assets/images/expand-row-icon.svg'
import { ReactComponent as CollapseIcon } from 'assets/images/collapse-row-icon.svg'

import { CategoriesTable } from './CategoriesTable/CategoriesTable'
import { ExecutiveIntelligenceGraph } from 'version2/components/common/ExecutiveIntelligence/ExecutiveIntelligenceGraph/ExecutiveIntelligenceGraph'
import { RiskFlagsHeader } from '../RiskFlagsHeader/RiskFlagsHeader'

import './RiskWandTable.scss'

export const RiskWandTable = observer(() => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const { anticipatoryIntelligenceStore, loaderStore } = store
  const { threatTopics, tableData, setActiveThreatTopic } = anticipatoryIntelligenceStore
  const { isLoadingThreatTopics, isLoadingNoOfPosts } = loaderStore
  const columns = [
    {
      title: 'Threat topic',
      dataIndex: 'topic',
      key: 'topic',
      width: '15%',
      className: 'riskWand-table__table__topic_col',
    },
    {
      title: 'Trend line',
      dataIndex: 'line',
      key: 'line',
      width: '20%',
      render: (line: any) => {
        return expandedKeys.includes(line?.key) ? (
          <></>
        ) : (
          <ExecutiveIntelligenceGraph mode='4' data={line.metricValues || []} color={'#D6B77B'} />
        )
      },
    },
    {
      title: 'posts',
      dataIndex: 'posts',
      key: 'posts',
      width: '15%',
      render: (posts: any) => {
        return expandedKeys.includes(posts?.key) ? <></> : posts.noOfPosts
      },
    },
    {
      title: 'Risk flags',
      dataIndex: 'flags',
      key: 'flags',
      width: '50%',
      render: (flags: any) => {
        return expandedKeys.includes(flags?.key) ? <></> : flags.flagsCount || 0
      },
    },
  ]

  return (
    <Spin spinning={isLoadingThreatTopics || isLoadingNoOfPosts}>
      <div>
        <RiskFlagsHeader threatTopics={threatTopics} setExpandedKeys={setExpandedKeys} />
        <Table
          columns={columns}
          dataSource={tableData}
          className={'riskWand-table'}
          scroll={{ y: 'calc(100vh - 320px)' }}
          onRow={(record) => {
            return expandedKeys.includes(record?.key) ? { className: 'riskWand-table__expand-row' } : { className: '' }
          }}
          expandable={{
            expandedRowKeys: expandedKeys,
            onExpand: (expanded, { key }) => {
              const keys = expanded ? expandedKeys.concat(key) : expandedKeys.filter((k) => k !== key)
              setExpandedKeys(keys)
            },
            expandedRowRender: (record) => {
              setActiveThreatTopic([record.key])
              if (expandedKeys.includes(record.key)) return <CategoriesTable topic={record.key} />
            },
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Tooltip title='Collapse' placement='bottom'>
                  <CollapseIcon className='riskWand-table__expand-icon' onClick={(e: any) => onExpand(record, e)} />
                </Tooltip>
              ) : (
                <Tooltip title='Expand' placement='bottom'>
                  <ExpandIcon className='riskWand-table__expand-icon' onClick={(e: any) => onExpand(record, e)} />
                </Tooltip>
              ),
          }}
        />
      </div>
    </Spin>
  )
})
