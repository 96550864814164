import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ConditionsDataType, ConditionsPayloadParamsType } from 'store/monitorVersion2/types'

export function getInsightsMetrics({
  params,
  data,
}: {
  params: ConditionsPayloadParamsType
  data: ConditionsDataType
}) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SEARCH_API}${ROUTES.narrativeCount}`,
    params,
    data,
  })
}
